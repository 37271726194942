import { useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import LinkPharmacyStore from "../components/LinkPharmacyStore";
import Marker from "../components/Marker";
import stores from "../globals/Locations";
import Layout from "../components/Layout/Layout";
import { meta } from "../globals";
import { classNames } from "../utils/utils";
import useScrollUp from "../hooks/useScrollUp";
import MediclinicLogo from "../assets/images/mediclinic-logo.png";

const FindAMediClinic = () => {
  const [clinics] = useState(stores.filter((store) => store.isAMediclinic === true));
  const mapRef = useRef(null);
  const [state, setState] = useState({
    center: { lat: -15.39654, lng: 28.30602 },
    zoom: 12,
    stores: stores,
    stickyMap: false,
    counter: 5,
    disable: false,
    enable: true,
    color: "",
    id: -1,
    userLocation: {},
  });
  const handleMarker = (id) => {
    setState((prevState) => ({
      ...prevState,
      color: "red",
      id: id,
      markerColor: "red",
      markerId: id,
    }));
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    if (id >= 6) {
      setState((prevState) => ({
        ...prevState,
        counter: state.counter + 7,
        disable: true,
        enable: false,
      }));
    }
  };
  const visible = useScrollUp();

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      bgColor="bg-stone-100"
      header
      footer
    >
      <div className="container-lg container-md container-sm container mt-4">
        <div className="w-full flex flex-col-reverse lg:flex-row flex-1 justify-between">
          <div className="flex flex-col lg:pr-6 space-y-4 w-full lg:max-w-xs xl:max-w-sm 2xl:max-w-md">
            <div className="w-full p-3 bg-white rounded">
              <div className="h-[50px]  mb-2">
                <img src={MediclinicLogo} alt="mediclinic logo" className="h-full object-contain" />
              </div>
              <h1 className="w-11/12 self-center text-[green] font-opensans-medium text-xl m-0">Find Care Near You</h1>
            </div>
            <div className="w-full flex flex-col md:flex-row md:flex-wrap justify-between">
              {clinics.map((clinic, index) => {
                return (
                  <div key={index} className="w-full md:w-72 lg:w-full mb-4 flex">
                    <LinkPharmacyStore store={clinic} color={state.color} marker={state.id} index={index} />
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={classNames(
              "flex flex-1 w-full h-3/5 transition-all duration-300 ease-out mb-2 lg:mb-0 lg:rounded-xl border-b",
              visible
                ? "md:sticky top-[162px] md:top-28 -mt-3 lg:mt-0"
                : "md:sticky top-[90px] md:top-16 rounded-none lg:mt-3"
            )}
            ref={(el) => (mapRef.current = el)}
          >
            <div
              className={classNames(
                "w-full overflow-hidden bg-white border lg:rounded-xl transition-all duration-300 ease-out",
                visible ? "h-60 md:h-96 lg:h-[500px]" : "h-40 md:h-96 lg:h-[500px]"
              )}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_API_KEY,
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                defaultCenter={state.center}
                center={clinics[0].location}
                defaultZoom={state.zoom}
                draggable={true}
              >
                {clinics.map((clinic, index) => {
                  return (
                    <Marker
                      key={clinic.id}
                      text={index + 1}
                      lat={clinic.location.lat}
                      lng={clinic.location.lng}
                      name={clinic.name}
                      handleMarker={handleMarker}
                      id={clinic.id}
                      title={clinic.name}
                      markerColor="red"
                      markerId={state.id}
                      index={index + 1}
                    />
                  );
                })}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FindAMediClinic;
