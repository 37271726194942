import React from "react";
import { classNames } from "../../../utils/utils";
import { HiMinus, HiPlus } from "react-icons/hi";
import styles from "../styles.module.css";

interface Props {
  value: number;
  label?: string;
  onIncrement: () => void;
  onDecrement: () => void;
  incrementDisabled?: boolean;
  decrementDisabled?: boolean;
  min?: number;
  max?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const IncrementDecrement = ({
  value,
  label,
  onChange,
  onBlur,
  onKeyUp,
  onKeyDown,
  onIncrement,
  onDecrement,
  incrementDisabled,
  decrementDisabled,
  min,
  max,
}: Props) => {
  const id = "custom-input-number";
  return (
    <div className="w-full">
      {label && (
        <label
          htmlFor={id}
          id={`${id}-label`}
          className="block text-sm font-opensans-medium leading-6 text-gray-800 mb-1"
        >
          {label}
        </label>
      )}
      <div className="flex flex-row h-8 w-full rounded-lg relative bg-transparent mt-1">
        <button
          aria-label="decrement"
          className={classNames(
            "bg-link-green-1 d-flex items-center justify-center text-white hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none",
            decrementDisabled && "opacity-50 cursor-not-allowed"
          )}
          onClick={onDecrement}
          disabled={decrementDisabled}
          aria-disabled={decrementDisabled}
        >
          <HiMinus />
        </button>
        <input
          type="number"
          className={classNames(
            "border-0 focus:ring-0 text-center w-full bg-stone-200 font-opensans-semibold text-sm hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700",
            styles.customNumberInput
          )}
          id={id}
          value={value}
          readOnly={!onChange}
          aria-readonly={!onChange}
          aria-labelledby={`${id}-label`}
          min={min}
          max={max}
          onChange={onChange}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
        />
        <button
          aria-label="increment"
          className={classNames(
            "bg-link-green-1 d-flex items-center justify-center text-white hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer",
            incrementDisabled && "opacity-50 cursor-not-allowed"
          )}
          onClick={onIncrement}
          disabled={incrementDisabled}
          aria-disabled={incrementDisabled}
        >
          <HiPlus />
        </button>
      </div>
    </div>
  );
};

export default IncrementDecrement;
