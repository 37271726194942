import style from "../../styles/views/shop/Order.module.css";

const Order = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={style.order_header}>
              <h1>
                Thank you for trusting Link pharmacy! Follow your order below{" "}
              </h1>
            </div>
            <p className={style.order_number}>Order number: 12345</p>
            <div className={style.order_flex}>
              <div className={style.order_cont}>
                <h5>Order received</h5>
                <p>
                  Your order has been received and payment will be taken shortly
                  from your payment method selected.
                </p>
              </div>
              <div className={style.order_cont}>
                <h5>Order dispatched</h5>
                <p>
                  Your order has left the building and is on its way to you now!
                </p>
              </div>
              <div className={style.order_cont}>
                <h5>Order delivered</h5>
                <p>Your order is now complete. Thank you!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Order;
