import Carousel from "react-multi-carousel";
import Product from "./Product";
import { carouselResponsiveness } from "../../globals";
import styles from "../../styles/components/SimilarProducts.module.css";
import { useQuery } from "react-query";
import axios from "axios";

const SimilarProducts = ({ productTypeId }) => {
  const { data: products, status: productsStatus } = useQuery(["similar-products", productTypeId], async () => {
    const { data } = await axios.get(`/v1/website/products?product_type_id=${productTypeId}&limit=10`);
    return data;
  });

  return (
    <div className={styles.similar_products}>
      {productsStatus === "success" && (
        <Carousel
          responsive={carouselResponsiveness}
          infinite={false}
          autoPlay={false}
          showDots={false}
          swipeable={true}
          removeArrowOnDeviceType={["mobile"]}
        >
          {products?.data?.map((product) => (
            <Product key={product.id} product={product} productType="similar-products" />
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default SimilarProducts;
