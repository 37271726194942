import { useState } from "react";
import { useParams } from "react-router";
import { Skeleton } from "@mui/material";

import PrescriptionItem from "./PrescriptionItem";
import Spinner from "react-spinkit";
import styles from "../../styles/views/PrescriptionDetails.module.css";
import usePrescriptionDetails from "../../hooks/usePrescriptionDetails";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

const PrescriptionDetails = () => {
  const { id } = useParams();
  const {
    prescriptionItems,
    loading,
    prescriptionStatus,
    prescriptionNumber,
    prescriptionImage,
    isPrescriptionComplete,
  } = usePrescriptionDetails(Number(id));
  const [disabled, setDisabled] = useState(false);

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className={styles.prescription_details}>
          <h4 className="font-opensans-medium text-2xl mb-2">Prescription Details</h4>
          <div className="card" style={{ padding: 10 }}>
            <p className="font-opensans text-sm" style={{ display: "flex", flexWrap: "nowrap", gap: "10px" }}>
              <span className="font-opensans-bold">Prescription Number: </span>
              {loading ? <Skeleton width={100} /> : prescriptionNumber}
            </p>
          </div>
          <br />

          <div className="alert alert-warning font-opensans text-sm">
            This prescription will disappear 2 days after processing if no action is taken.
          </div>
          <br />

          <div className="row">
            <div className="col-md-7">
              <div style={{ width: "100%", height: "70vh", border: "none" }}>
                {loading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner name={"ball-pulse-sync"} color={"#c4d600"} fadeIn={"none"} />
                  </div>
                ) : (
                  (() => {
                    const isPdf = prescriptionImage?.includes(".pdf");

                    if (isPdf) {
                      return (
                        // eslint-disable-next-line jsx-a11y/iframe-has-title
                        <iframe style={{ width: "100%", height: "100%" }} src={prescriptionImage} frameBorder="0" />
                      );
                    }

                    if (!isPdf) {
                      return (
                        <img
                          src={prescriptionImage}
                          alt={prescriptionNumber}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      );
                    }
                  })()
                )}
              </div>
            </div>

            <div className="col-md-5">
              <h5 className="font-opensans-medium text-xl mb-2">
                Prescription Items{" "}
                {isPrescriptionComplete && <span className="text-base font-opensans text-[green]">- Processed</span>}
              </h5>

              {prescriptionStatus === "Processed" ? (
                prescriptionItems.map((item) => {
                  return (
                    <div key={item.name} className={styles.prescriptionItemContainer}>
                      <PrescriptionItem prescribedItem={item} disabled={disabled} setDisabled={setDisabled} id={id} />
                    </div>
                  );
                })
              ) : (
                <p>
                  Your prescription has not been processed yet. You will receive an email notification once it is
                  processed.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrescriptionDetails;
