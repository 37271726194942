import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "./AuthContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useNotifications } from "./NotificationsContext";

/**
 * This is a typescript .ts file. Please make sure to define
 * the types of any variables that you create.
 * @see {@link https://www.typescriptlang.org/docs/handbook/2/basic-types.html}
 * **/

// Type definitions
type cartItem = {
  id: number;
  quantity: number;
  name: string;
  price: number;
  thumbnail: string;
  prescription_number: string;
  prescriptionItem?: boolean;
  prescriptionId?: number;
  med_variation_id?: number;
  queryId?: number;
  quantityOnHand: number;
  prescriptionRequired: boolean;
};

type savedItem = {
  id: number;
  attributes: {
    id: number;
    quantity: number;
    price: number;
    name: string;
    thumbnail: string;
  };
};

type ICartContext = {
  state: ICart;
  setState: React.Dispatch<React.SetStateAction<any>>;
};

export interface ICart {
  cart: cartItem[];
  savedItems: savedItem[];
  count: number;
  total: number;
  isDropdownVisible: boolean;
  timerId: number | null;
}

const CartContext = React.createContext<ICartContext>({
  state: {
    cart: [],
    savedItems: [],
    count: 0,
    total: 0,
    isDropdownVisible: false,
    timerId: null,
  },
  setState: () => null,
});

const CartProvider: React.FC<{}> = ({ children }: any): any => {
  const [state, setState] = useState<ICart>({
    cart: [],
    savedItems: [],
    count: 0,
    total: 0,
    isDropdownVisible: false,
    timerId: null,
  });
  const value: ICartContext = { state, setState };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

const useCart = () => {
  const context = useContext(CartContext);
  const { state, setState } = context;
  const { signedIn, setRedirect } = useContext(AuthContext);
  const initialRender = useRef(true);
  const navigate = useNavigate();
  const [moveLoading, setMoveLoading] = useState<Array<number>>([]);
  const [saveLoading, setSaveLoading] = useState<Array<number>>([]);
  const [addLoading, setAddLoading] = useState<Array<number>>([]);
  const { pushNotification } = useNotifications();
  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  if (context === undefined) {
    throw new Error("useCart must be used within a Cart Provider");
  }

  /** This sets the initial render reference that's used by the cartQuery **/
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      // Do nothing
    }
  }, []);

  // Query to fetch cart
  useQuery(
    ["cartQuery", signedIn],
    async () => {
      // If user is signed in
      if (signedIn && !initialRender.current) {
        const res = await axios.get("/v1/website/cart");
        return {
          cartArray: await res.data.data.attributes.items.map((item: any) => {
            return {
              id: item.attributes.item.id,
              quantity: item.attributes.quantity,
              name: item.attributes.item.name,
              price: item.attributes.item.price,
              thumbnail: item.attributes.item.thumbnail,
              prescription_number: item.attributes.prescription_number,
              prescriptionItem: item.attributes.is_prescription_item,
              prescription_id: item.attributes.prescription_id,
              prescription_required: item.attributes.prescription_required,
            };
          }),

          count: res.data.data.attributes.count,
          total: res.data.data.attributes.amount,
        };
      }

      // If user is not signed in
      if (!signedIn && !initialRender.current) {
        const res = await Promise.resolve(JSON.parse(localStorage.getItem("linkpharmacy-cart") || "[]"));

        return await res;
      }
    },
    {
      onSuccess: (data) => {
        if (signedIn) {
          setState((prevState: ICart) => ({
            ...prevState,
            cart: data.cartArray,
            count: data.count,
            total: Number(data.total),
          }));
        }

        // If not signed in
        if (!signedIn) {
          let count = 0;
          let total = 0;

          data?.forEach((item: cartItem) => {
            count += item.quantity;
            total += item.price * item.quantity;
          });

          setState((prevState: ICart) => ({
            ...prevState,
            cart: data,
            count: count,
            total: total,
          }));
        }
      },
      enabled: !initialRender.current,
    }
  );

  // Saved items query
  useQuery(
    "savedItems",
    async () => {
      const res = await axios.get("/v1/website/saved-for-later");

      return await res.data;
    },
    {
      onSuccess: (data) => {
        const itemsArr = data.data.attributes.items.map((item: any) => {
          return {
            id: item.attributes.item.id,
            attributes: {
              id: item.attributes.item.id,
              quantity: item.attributes.quantity,
              name: item.attributes.item.name,
              price: item.attributes.item.price,
              thumbnail: item.attributes.item.thumbnail,
            },
          };
        });

        setState((prevState: any) => ({ ...prevState, savedItems: itemsArr }));
      },
      enabled: !!signedIn,
    }
  );

  // add to cart mutation
  const cartMutation = useMutation(
    "cartMutation",
    async ({ id, amount, quantity, med_variation_id, adding, incrementing, prescriptionItem, prescriptionId }: any) => {
      setAddLoading((prevState) => [...prevState, id]);
      const res = await axios.post(`/v1/website/cart/${adding || incrementing ? "add-item" : "remove-item"}`, {
        stock_item_id: id,
        quantity,
        amount,
        med_variation_id: med_variation_id,
        is_prescription_item: prescriptionItem,
        prescription_id: prescriptionId,
      });

      return await res.data;
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries("cartQuery");
        await queryClient.invalidateQueries("savedItems");
        setAddLoading((prevState) => prevState.filter((p) => p !== variables.id));

        if (variables.prescriptionItem) {
          await axios.get("/v1/website/prescription-added-to-cart/" + variables.prescriptionId);
          await queryClient.invalidateQueries(["prescriptionDetails", variables.queryId]);
        }

        if (variables.adding) {
          // Clear previous timeout
          clearTimeout(state.timerId || 0);

          // Set timout to hide dropDown
          const timer = setTimeout(() => {
            context.setState((prevState: ICart) => ({
              ...prevState,
              isDropdownVisible: false,
            }));
          }, 5000);

          // Set dropdown visibility to true
          context.setState((prevState: ICart) => ({
            ...prevState,
            isDropdownVisible: true,
            timerId: timer,
          }));
        }

        // noinspection ES6MissingAwait
        queryClient.invalidateQueries();

        variables.callback?.();
      },

      onMutate: async (variables) => {
        if (variables.adding === false) {
          setAddLoading((prevState) => [...prevState, variables.id]);

          // Pseudo sleep function
          await new Promise((r) => setTimeout(r, 1000));

          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries(["cartQuery", signedIn]);

          // Retrieve previous cart items
          const previousData = queryClient.getQueryData(["cartQuery", signedIn]);

          // Optimistically update to the new value
          queryClient.setQueryData(["cartQuery", signedIn], (old: any) => ({
            ...old,
            cartArray: old.cartArray.filter((item: cartItem) => item.id !== variables.id),
            count: old.count - variables.quantity,
            total: old.total - variables.amount * variables.quantity,
          }));

          setAddLoading((prevState) => prevState.filter((p) => p !== variables.id));

          return { previousData };
        }
      },
      onError: async () => {
        await queryClient.invalidateQueries();
      },
    }
  );

  // Saved for later mutation
  const saveMutation = useMutation(
    "saveMutation",
    async (data: any) => {
      setSaveLoading((prevState) => [...prevState, data.id]);
      const res = await axios.post(`/v1/website/saved-for-later/${data.adding ? "add-item" : "remove-item"}`, {
        stock_item_id: data.id,
        quantity: data.quantity,
        amount: data.amount,
      });

      return await res.data;
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries("cartQuery");
        await queryClient.invalidateQueries("savedItems");
        setSaveLoading((prevState) => prevState.filter((p) => p !== variables.id));
      },
    }
  );

  // Move to cart mutation
  const moveToCartMutation = useMutation(
    "moveToCart",
    async (data: any) => {
      setMoveLoading((prevState) => [...prevState, data.id]);
      const res = await axios.post("/v1/website/saved-for-later/move-to-cart", {
        stock_item_id: data.id,
        quantity: data.quantity,
        amount: data.amount,
      });

      return await res.data;
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries("cartQuery");
        await queryClient.invalidateQueries("savedItems");
        setMoveLoading((prevState) => prevState.filter((p) => p !== variables.id));
      },
    }
  );

  const addToCart = async (cartItem: cartItem, callback?: () => any) => {
    console.log(cartItem);

    // Check if quantity is more than available stock

    // Get quantity in cart
    const quantityInCart = state.cart.find((item) => item.id === cartItem.id)?.quantity || 0;

    if (cartItem.quantity + quantityInCart > cartItem.quantityOnHand) {
      pushNotification({
        id: cartItem.id + cartItem.quantity,
        type: "Warning",
        message: "Oops! You have exceeded the available stock for this item.",
      });

      return;
    }

    // See if user is signed in.
    if (signedIn) {
      // If user is signed in;
      // Run mutation to update database
      cartMutation.mutate({
        id: cartItem.id,
        prescriptionId: cartItem.prescriptionId,
        amount: cartItem.price * cartItem.quantity,
        med_variation_id: cartItem.med_variation_id,
        quantity: cartItem.quantity,
        prescriptionItem: cartItem.prescriptionItem || false,
        queryId: cartItem.queryId,
        adding: true,
        callback: callback,
      });
    }

    if (!signedIn) {
      console.log(cartItem);

      const localCart = JSON.parse(localStorage.getItem("linkpharmacy-cart") || "[]");
      setAddLoading((prevState) => [...prevState, cartItem.id]);

      // Check if cart exists
      if (localCart) {
        let itemExists = false;

        localCart.forEach((item: cartItem) => {
          if (item.id === cartItem.id) {
            itemExists = true;
            item.quantity += cartItem.quantity;
          }
        });

        if (itemExists) {
          // Set cart with updated quantity in localStorage when another instance is found
          localStorage.setItem("linkpharmacy-cart", JSON.stringify(localCart));
        } else {
          // Append new item to cart if a prior instance does not exist
          localCart.push(cartItem);
          localStorage.setItem("linkpharmacy-cart", JSON.stringify(localCart));
        }
      } else {
        // When no previous cart exists; create one and set in localStorage
        const newCart = [cartItem];
        localStorage.setItem("linkpharmacy-cart", JSON.stringify(newCart));
      }

      setAddLoading((prevState) => prevState.filter((p) => p !== cartItem.id));

      // Clear any previous timeout
      clearTimeout(state.timerId || 0);

      // Set timeout to close dropDown after 5 seconds
      const timer = setTimeout(
        () =>
          context.setState((prevState: ICart) => ({
            ...prevState,
            isDropdownVisible: false,
          })),
        5000
      );

      // Display dropdown
      context.setState((prevState: ICart) => ({
        ...prevState,
        isDropdownVisible: true,
        timerId: timer,
      }));

      // Show notification for mobile
      pushNotification({
        type: "Cart",
        id: cartItem.id,
        data: cartItem,
        message: "Item Added to cart",
      });
    }

    await queryClient.invalidateQueries("cartQuery");
  };

  const removeFromCart = async (cartItem: cartItem) => {
    // See if user is signed in
    if (signedIn) {
      // run mutation to delete item from database
      cartMutation.mutate({
        id: cartItem.id,
        quantity: cartItem.quantity,
        amount: cartItem.price,
        adding: false,
      });
    }

    if (!signedIn) {
      const localCart = JSON.parse(localStorage.getItem("linkpharmacy-cart") || "[]");

      // Ensure cart exists
      if (localCart) {
        // eslint-disable-next-line array-callback-return
        const newArr = localCart.filter((item: cartItem) => {
          if (item.id !== cartItem.id) {
            return item;
          }
        });
        localStorage.setItem("linkpharmacy-cart", JSON.stringify(newArr));
      }
    }

    await queryClient.invalidateQueries("cartQuery");
  };

  const incrementItem = async (cartItem: cartItem, quantity?: number) => {
    try {
      setAddLoading((prevState) => [...prevState, cartItem.id]);

      const { data } = await axios.get(`/v1/website/products/${cartItem.id}`);
      const { quantity_in_stock } = data.data.attributes;

      if (quantity_in_stock < cartItem.quantity + 1) {
        pushNotification({
          id: cartItem.id + cartItem.quantity,
          type: "Warning",
          message: "Oops! You have exceeded the available stock for this item.",
        });

        return;
      }

      if (signedIn) {
        cartMutation.mutate({
          id: cartItem.id,
          quantity: quantity ? quantity - cartItem.quantity : 1,
          amount: cartItem.price,
          incrementing: true,
        });
      }

      if (!signedIn) {
        const localCart = JSON.parse(localStorage.getItem("linkpharmacy-cart") || "[]");

        if (localCart) {
          localCart.forEach((item: cartItem) => {
            if (item.id === cartItem.id) {
              if (quantity === 0) return;

              item.quantity = quantity ? Number(quantity) : item.quantity + 1;
            }
          });

          localStorage.setItem("linkpharmacy-cart", JSON.stringify(localCart));
        }

        await queryClient.invalidateQueries("cartQuery");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAddLoading((prevState) => prevState.filter((p) => p !== cartItem.id));
    }
  };

  const decrementItem = async (cartItem: cartItem) => {
    if (signedIn) {
      if (cartItem.quantity === 1) {
        return;
      }

      cartMutation.mutate({
        id: cartItem.id,
        quantity: -1,
        amount: cartItem.price,
        incrementing: true,
      });
    } else {
      setAddLoading((prevState) => [...prevState, cartItem.id]);

      const localCart = JSON.parse(localStorage.getItem("linkpharmacy-cart") || "[]");
      if (localCart) {
        const updatedCart = localCart.map((item: cartItem) => {
          if (item.id === cartItem.id) {
            if (item.quantity === 1) {
              return item;
            }

            return {
              ...item,
              quantity: item.quantity - 1,
            };
          }

          return item;
        });

        localStorage.setItem("linkpharmacy-cart", JSON.stringify(updatedCart));
      }

      setAddLoading((prevState) => prevState.filter((p) => p !== cartItem.id));
    }

    await queryClient.invalidateQueries("cartQuery");
  };

  const saveForLater = (cartItem: cartItem) => {
    // redirect to login if user not logged in
    if (!signedIn) {
      setRedirect("/cart");
      navigate("/login", { replace: true });
      return;
    }

    // Run mutation to save item for later
    saveMutation.mutate({
      id: cartItem.id,
      quantity: cartItem.quantity,
      amount: cartItem.price,
      adding: true,
    });
  };

  const removeSavedItem = (cartItem: cartItem) => {
    saveMutation.mutate({
      id: cartItem.id,
      quantity: cartItem.quantity,
      amount: cartItem.price,
      adding: false,
    });
  };

  /**
   * Move saved items to cart;
   * @param cartItem
   * @return void
   * **/
  const moveToCart = (cartItem: cartItem) => {
    moveToCartMutation.mutate({
      id: cartItem.id,
      quantity: cartItem.quantity,
      amount: cartItem.price,
    });
  };

  const hideDropDown = () => {
    context.setState((prevState: ICart) => ({
      ...prevState,
      isDropdownVisible: false,
    }));
  };

  const showDropDown = () => {
    context.setState((prevState: ICart) => ({
      ...prevState,
      isDropdownVisible: true,
    }));
  };

  const refresh = () => {
    (async () => {
      await queryClient.invalidateQueries("cartQuery");
      await queryClient.invalidateQueries("savedItems");
    })();
  };

  return {
    cart: state,
    addToCart,
    removeFromCart,
    incrementItem,
    decrementItem,
    saveForLater,
    removeSavedItem,
    moveToCart,
    hideDropDown,
    showDropDown,
    refresh,
    isDropdownVisible: pathname === "/cart" || pathname === "/checkout" ? false : context.state.isDropdownVisible,
    timerId: context.state.timerId,
    moveLoading,
    saveLoading,
    addLoading,
  };
};

export { CartProvider, useCart };
