import { Form, Formik } from "formik";
import {
  IoCalendarNumber,
  IoCall,
  IoCode,
  IoMail,
  IoMale,
  IoPerson,
} from "react-icons/io5";
import { genderOptions } from "../../globals";
import { libertySchema } from "../../globals/schemas";
import styles from "../../styles/views/MyHealthInsurance.module.css";
import { FormField, SelectField } from "../forms";

const Liberty = () => {
  return (
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
        dob: "",
        membershipID: "",
        depCode: "",
        patientGender: "",
        employer: "",
        email: "",
        mobileNum: "",
        patientFullname: "",
        patientMobileNum: "",
        patientAge: "",
        facility: "",
        physician: "",
        providerNum: "",
        specialty: "",
        treatmentDate: "",
        admissionDate: "",
        dischargeDate: "",
        claimForm: "",
        insuranceCompany: "Liberty",
      }}
      onSubmit={(values) => console.log(values)}
      validationSchema={libertySchema}
    >
      {({ isSubmitting, values, handleChange, setFieldValue, errors }) => (
        <Form className="space-y-4 flex flex-col self-center w-full">
          <div className="w-full flex items-center space-x-2 h-11 bg-link-green px-3 rounded-md">
            <span className="bg-stone-900/30 text-white rounded-full w-7 h-7 flex flex-col items-center">
              2
            </span>
            <span>Patient Details</span>
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Firstname"}
              type="text"
              name="patientFirstname"
              placeholder="e.g.: John"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Lastname"}
              type="text"
              name="patientLastname"
              placeholder="e.g.: Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-5 md:px-12">
            <div className="w-full md:w-fit h-14">
              <FormField
                label={"Dep Code"}
                type="text"
                name="depCode"
                placeholder="e.g.: 0912 345 678"
                margin="mb-0"
                icon={<IoCode className="text-xl ml-2 text-link-green" />}
              />
            </div>
            <div className="w-full md:w-fit">
              <SelectField
                label={"Gender"}
                type="text"
                name="patientGender"
                placeholder="Gender"
                options={genderOptions}
                margin="mb-0"
                icon={<IoMale className="text-xl ml-2 text-link-green" />}
              />
            </div>
            <div className="w-full md:w-fit">
              <FormField
                label={"Date of Birth"}
                type="text"
                name="depCode"
                placeholder="e.g.: 0912 345 678"
                margin="mb-0"
                icon={
                  <IoCalendarNumber className="text-xl ml-2 text-link-green" />
                }
              />
            </div>
          </div>
          <div className="w-full md:w-full md:px-12">
            <FormField
              label={"Membership No."}
              type="text"
              name="membershipID"
              placeholder="e.g.: 345647-56"
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className="w-full flex items-center space-x-2 h-11 bg-link-green px-3 rounded-md">
            <span className="bg-stone-900/30 text-white rounded-full w-7 h-7 flex flex-col items-center">
              3
            </span>
            <span>Principal Member Details</span>
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Firstname"}
              type="text"
              name="firstname"
              placeholder="e.g.: John"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Lastname"}
              type="text"
              name="lastname"
              placeholder="e.g.: Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Employer"}
              type="text"
              name="employer"
              placeholder="e.g.: Linkpharmacy"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Email"}
              type="text"
              name="email"
              placeholder="e.g.: andy@linkpharmacy.co.zm"
              width={"w-full"}
              margin="mb-0"
              icon={<IoMail className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Contact Number (please include country and area code)"}
              type="text"
              name="mobileNum"
              placeholder="e.g.: XXXX-XXX-XXX"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCall className="text-xl ml-2 text-link-green" />}
            />
          </div>
          <div className="w-full flex items-center space-x-2 h-11 bg-link-green px-3 rounded-md">
            <span className="bg-stone-900/30 text-white rounded-full w-7 h-7 flex flex-col items-center">
              4
            </span>
            <span>Service Provider Details</span>
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Name of Facility"}
              type="text"
              name="facility"
              placeholder="e.g.: Mums's care Hospital"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Consulting Physician"}
              type="text"
              name="physician"
              placeholder="e.g.: Dr. Zara"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Provider Number"}
              type="text"
              name="providerNum"
              placeholder="e.g.: XXXX-XXX-XXX"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Specialty"}
              type="text"
              name="specialty"
              placeholder="e.g.: Specialty"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Treatment Date"}
              type="date"
              name="treatmentDate"
              placeholder=""
              width={"w-full"}
              margin="mb-0"
              icon={
                <IoCalendarNumber className="text-xl ml-2 text-link-green" />
              }
            />
            <FormField
              label={"Admission Date"}
              type="date"
              name="admissionDate"
              placeholder=""
              width={"w-full"}
              margin="mb-0"
              icon={
                <IoCalendarNumber className="text-xl ml-2 text-link-green" />
              }
            />
            <FormField
              label={"Discharge Date"}
              type="date"
              name="dischargeDate"
              placeholder=""
              width={"w-full"}
              margin="mb-0"
              icon={
                <IoCalendarNumber className="text-xl ml-2 text-link-green" />
              }
            />
          </div>

          <div className="w-full flex items-center space-x-2 h-11 bg-link-green px-3 rounded-md">
            <span className="bg-stone-900/30 text-white rounded-full w-7 h-7 flex flex-col items-center">
              5
            </span>
            <span> Claim Details</span>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={"mt-4"}>
                <div>
                  <h5 className={`font-weight-bold ${styles.label}`}>
                    Upload Copy of Claim Form
                  </h5>

                  <p>
                    Please ensure Patient's signature, Doctor's signature and
                    Medical Services are clearly visible.
                  </p>

                  <input
                    type="file"
                    name="fullNames"
                    className={"form-control"}
                    onChange={(event) => {
                      setFieldValue("claimForm", event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <input
              type={"submit"}
              value="Submit form"
              className="bg-link-green text-stone-900 rounded-full p-3"
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Liberty;
