import axios from "axios";

const getAllCategoriesApi = async () => {
  const res = await axios.get("v1/website/categories");
  return res.data;
};

const getFeaturedCategoriesApi = async () => {
  const res = await axios.get("v1/website/featured-categories");
  return res.data;
};

export { getAllCategoriesApi, getFeaturedCategoriesApi };
