import care_giver from "../../assets/images/care_giver.jpg";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import SpecialtySideBar from "../../components/SpecialtySideBar";
import style from "../../styles/views/FinancialAssistance.module.css";

const FinancialAssistance = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="row">
          <div className="clo-md-12">
            <div className={style.f_nav}>
              <a href="/">Home</a>
              <span>&#10095;</span>
              <a href="/pharmacy-services">Pharmacy & Health</a>
              <span>&#10095;</span>
              <a href="/specialty-pharmacy">Specialty Pharmacy</a>
              <h1>Financial Assistance Coordination</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <SpecialtySideBar />
            </div>
            <div className="col-md-9">
              <div className={style.f_flex}>
                <div className={style.f_flex_img}>
                  <img src={care_giver} alt="" />
                </div>
                <div className={style.f_flex_cont}>
                  <p>We secured</p>
                  <h2>$71 million</h2>
                  <p>in financial assistance for our patients</p>
                </div>
              </div>
              <div className={style.f_cont}>
                <h3>
                  Financial support coordination from the Link Pharmacy
                  Specialty Pharmacy Care Team
                </h3>
                <p>
                  The Link Pharmacy Specialty Pharmacy Care Team can help you
                  find ways to make your specialty medication more affordable.
                  We do everything we can to match you with manufacturer, copay
                  and discount programs that may help cover the cost of
                  medications not covered by insurance, or help with co-payments
                  that may still be too much to afford.
                </p>
                <p>
                  When you call the Link Pharmacy Specialty Pharmacy Care Team,
                  we can:
                </p>
                <ul>
                  <li>Discuss your financial needs</li>
                  <li>Identify programs you may be eligible for</li>
                  <li>Coordinate your application for assistance</li>
                </ul>
                <h5>How to get financial assistance</h5>
                <p>
                  The financial assistance you need is only a few steps away:
                  Call +260 211 255 556 to speak with a specialist about your
                  prescriptions and financial needs Answer questions to
                  determine your eligibility If we find a program to match your
                  needs, you will be enrolled by the specialist or provided a
                  website/phone number to self-enroll
                </p>
                <p>
                  The Care Team will follow up and contact you when you are
                  approved for financial assistance The Link Pharmacy Specialty
                  Pharmacy Care Team will notify you if additional information
                  is needed to gain assistance, or if you need to supply
                  supporting documentation or tax forms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default FinancialAssistance;
