import { Link } from "react-router-dom";
import { WarningAmber } from "@mui/icons-material";

import style from "../../styles/views/shop/FailedPayment.module.css";

const FailedPayment = () => {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className={style.fp_wrapper}>
            <div className={style.fp_cont}>
              <WarningAmber />
              <h1>!Ooops payment failed</h1>
              <p>
                Your transaction has failed due to some technical errors. Please
                try again
              </p>
              <Link to="#">Try Again</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailedPayment;
