import * as Yup from "yup";

const checkoutSchema = Yup.object().shape({
  deliveryMethod: Yup.string().required("Delivery method is required").oneOf(["deliver", "pickup"]),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\+?(26)?0\d{9}$/i, "Phone number is invalid, must be in the format +260xxxxxxxxx"),
  address1: Yup.string()
    .nullable()
    .matches(/^((?!,).)*$/, "A comma is not allowed as a separator, use a space instead")
    .when("deliveryMethod", {
      is: "deliver",
      then: Yup.string().required("Address line 1 is required"),
    }),
  address2: Yup.string()
    .matches(/^((?!,).)*$/, "A comma is not allowed as a separator, use a space instead")
    .nullable(),
  city: Yup.string().when("deliveryMethod", {
    is: "deliver",
    then: Yup.string().required("City is required"),
  }),
  residentialArea: Yup.string().when("deliveryMethod", {
    is: "deliver",
    then: Yup.string().required("Residential area is required"),
  }),
  postalCode: Yup.string().when("deliveryMethod", {
    is: "deliver",
    then: Yup.string().required("Postal code is required"),
  }),
});

export default checkoutSchema;
