/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import GoogleMapReact from "google-map-react";
import { getDistance } from "../helpers/getDistance";
import LinkPharmacyStore from "../components/LinkPharmacyStore";
import Marker from "../components/Marker";
import Layout from "../components/Layout/Layout";
import { meta } from "../globals";
import stores from "../globals/Locations";
import { classNames } from "../utils/utils";
import useScrollUp from "../hooks/useScrollUp";
import { MdMyLocation } from "react-icons/md";

const FindAStore = () => {
  const [state, setState] = useState({
    defaultCenter: { lat: -15.39654, lng: 28.30602 },
    center: { lat: -15.39654, lng: 28.30602 },
    zoom: 12,
    stores: stores,
    stickyMap: false,
    counter: 5,
    disable: false,
    enable: true,
    color: "",
    id: -1,
    userLocation: {},
    bounds: {
      north: -8.0214738479,
      south: -18.2766075679,
      west: 21.7481505871,
      east: 33.9210021496,
    },
  });
  const mapRef = useRef(null);
  const handleLoadMore = () => {
    setState((prevState) => ({
      ...prevState,
      counter: state.stores.length,
      disable: !prevState.disable,
      enable: false,
    }));
  };
  const handleLoadLess = () => {
    setState((prevState) => ({
      ...prevState,
      counter: 5,
      enable: !prevState.enable,
      disable: false,
    }));
  };
  const handleScroll = () => {
    let map = mapRef.current;
    let mapOffset = map.offsetTop;

    if (window.pageYOffset >= mapOffset) {
      setState((prevState) => ({ ...prevState, stickyMap: true }));
    } else {
      setState((prevState) => ({ ...prevState, stickyMap: false }));
    }
  };
  const handleMarker = (id, index) => {
    setState((prevState) => ({
      ...prevState,
      color: "bg-red-500",
      id: id,
      markerColor: "bg-red-500",
      markerId: id,
      index,
    }));

    if (index >= 6) {
      setState((prevState) => ({
        ...prevState,
        counter: prevState.counter + 7,
        disable: true,
        enable: false,
      }));
    }

    const el = document.getElementById(id);

    const scrollToView = () => {
      if (!el) {
        setTimeout(scrollToView, 50);
        return;
      }

      el.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    scrollToView();
  };
  const useMyLocation = () => {
    // Get users current location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setState((prevState) => ({
          ...prevState,
          userLocation: position.coords,
        }));
      });
    }
  };
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    useMyLocation();
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);
  // Get distance from user's location
  useEffect(() => {
    const sortedStores = state.stores
      .map((store) => {
        const { userLocation } = state;

        store.distance = getDistance(
          userLocation.latitude,
          userLocation.longitude,
          store.location.lat,
          store.location.lng
        );

        return store;
      })
      // eslint-disable-next-line array-callback-return
      .sort((a, b) => {
        if (a.distance < b.distance) {
          return -1;
        }
      });

    setState((prevState) => ({
      ...prevState,
      stores: sortedStores,
      center: sortedStores[0].location,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userLocation]);
  const visible = useScrollUp();

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      bgColor="bg-stone-100"
      header
      footer
    >
      <div className="container-lg container-md container-sm container mt-4">
        <div className="w-full flex flex-col-reverse lg:flex-row flex-1 justify-between">
          <div className="flex flex-col lg:pr-6 space-y-4 w-full lg:max-w-xs xl:max-w-sm">
            <div className="flex flex-col w-full bg-white p-4 rounded-lg">
              <h1 className="text-lg text-[green] font-opensans-medium mb-2.5 mt-0">Find stores and trading hours</h1>
              <Autocomplete
                className="w-full flex flex-1 self-center items-center mb-2.5 border border-gray-200 bg-white p-2"
                options={{
                  bounds: state.bounds,
                  strictBounds: true,
                  types: ["regions"],
                }}
                onPlaceSelected={(place) => {
                  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

                  Geocode.fromAddress(place.formatted_address).then(
                    (response) => {
                      const { lat, lng } = response.results[0].geometry.location;
                      setState((prevState) => ({
                        ...prevState,
                        userLocation: { latitude: lat, longitude: lng },
                      }));
                    },
                    (error) => {
                      console.error(error);
                    }
                  );
                }}
              />
              <div className="flex items-center m-0 cursor-pointer text-sm font-opensans-bold" onClick={useMyLocation}>
                <MdMyLocation className="mr-2" /> Use My Location
              </div>
            </div>

            <div className="w-full flex flex-1 flex-col md:flex-row md:flex-wrap justify-between">
              {state.stores.slice(0, state.counter).map((store, index) => {
                return (
                  <div key={index} className="w-full md:w-72 lg:w-full mb-4 flex">
                    <LinkPharmacyStore store={store} color={state.color} marker={state.id} index={index} />
                  </div>
                );
              })}
            </div>

            <div className="flex items-center justify-center">
              <button
                onClick={handleLoadMore}
                className={classNames(
                  state.disable ? "hidden" : "flex",
                  "w-fit m-0 py-2 px-3 cursor-pointer font-opensans-medium text-center my-3 rounded items-center bg-link-green text-white self-center"
                )}
              >
                {"Load More"}
              </button>
              <button
                onClick={handleLoadLess}
                className={classNames(
                  state.enable ? "hidden" : "flex",
                  "w-fit m-0 p-2 cursor-pointer font-opensans-medium text-center my-3 rounded items-center bg-link-green text-white self-center"
                )}
              >
                {"Load Less"}
              </button>
            </div>
          </div>

          <div
            className={classNames(
              "flex flex-1 w-full h-full mb-2 lg:mb-0 lg:rounded-xl border-b transition-all duration-200 ease-in",
              visible ? "md:sticky md:top-28 -mt-3 lg:mt-0" : "md:sticky md:top-16 rounded-none lg:mt-3"
            )}
            ref={(el) => (mapRef.current = el)}
          >
            <div
              className={classNames(
                "w-full overflow-hidden bg-white border lg:rounded-xl transition-all duration-300 ease-out",
                visible ? "h-60 md:h-96 lg:h-[500px]" : "h-40 md:h-96 lg:h-[500px]"
              )}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_API_KEY,
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                defaultCenter={state.defaultCenter}
                center={state.center}
                defaultZoom={state.zoom}
                draggable={true}
              >
                {state.stores.map((store, index) => {
                  return (
                    <Marker
                      key={store.id}
                      text={index + 1}
                      lat={store.location.lat}
                      lng={store.location.lng}
                      name={store.name}
                      handleMarker={handleMarker}
                      id={store.id}
                      title={store.name}
                      marker_color="red"
                      marker_id={state.id}
                      index={index + 1}
                    />
                  );
                })}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FindAStore;
