import Carousel from "react-multi-carousel";

import { useCart } from "../../context/cartContext";
import Product from "./Product";
import styles from "../../styles/components/CarouselProducts.module.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },

  smallerTablet: {
    breakpoint: { max: 979, min: 769 },
    items: 2,
  },

  largeMobile: {
    breakpoint: { max: 768, min: 465 },
    items: 2,
  },

  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const SavedForLater = ({ saveMutation }) => {
  const {
    cart: { savedItems },
  } = useCart();

  return (
    <div className={styles.products}>
      {savedItems && (
        <Carousel
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          showDots={false}
          swipeable={true}
          removeArrowOnDeviceType={["mobile"]}
        >
          {savedItems.map((product) => (
            <div className={`${styles.card_col}`} key={product.id}>
              <Product
                productType="products"
                product={product}
                savedForLater={true}
                saveMutation={saveMutation}
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default SavedForLater;
