import HelpCenterSidebar from "../../components/HelpCenterSidebar";
// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/NoticePrivacy.module.css";

const NoticePrivacy = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={style.n_nav}>
              <a href="/">Home</a>
              <h1>Notice of Privacy Practices</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
              <HelpCenterSidebar />
            </div>
            <div className="col-md-8">
              <div className={style.n_header}>
                <h2>
                  THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE
                  USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS
                  INFORMATION. PLEASE REVIEW IT CAREFULLY.
                </h2>
              </div>
              <div className={style.n_cont}>
                <p>
                  We at Link Pharmacy are required by law to maintain the
                  privacy of Protected Health Information ("PHI") and to provide
                  you with notice of our legal duties and privacy practices with
                  respect to PHI. References to "Link Pharmacy," "we," "us," and
                  "our" include Walgreen Co. and the members of its affiliated
                  covered entity. An affiliated covered entity is a group of
                  organizations under common ownership or control who designate
                  themselves as a single affiliated covered entity for purposes
                  of compliance with the Health Insurance Portability and
                  Accountability Act ("HIPAA"). Link Pharmacy, its employees,
                  workforce members and members of the Link Pharmacy' affiliated
                  covered entity who are involved in providing and coordinating
                  health care are all bound to follow the terms of this Notice
                  of Privacy Practices ("Notice"). The members of the Link
                  Pharmacy' affiliated covered entity will share PHI with each
                  other for the treatment, payment and health care operations of
                  the affiliated covered entity and as permitted by HIPAA and
                  this Notice. For a complete list of the members of Link
                  Pharmacy' affiliated covered entity, please contact the
                  Privacy Office.
                </p>

                <p>
                  PHI is information that may identify you and that relates to
                  your past, present, or future physical or mental health or
                  condition, the provision of health care products and services
                  to you or payment for such services. This Notice describes how
                  we may use and disclose PHI about you, as well as how you
                  obtain access to such PHI. This Notice also describes your
                  rights with respect to your PHI. We are required by HIPAA to
                  provide this Notice to you. Link Pharmacy is required to
                  follow the terms of this Notice or any change to it that is in
                  effect. We reserve the right to change our practices and this
                  Notice and to make the new Notice effective for all PHI we
                  maintain. If we do so, the updated Notice will be posted on
                  our website and will be available at our facilities and
                  locations where you receive health care products and services
                  from us. Upon request, we will provide any revised Notice to
                  you.
                </p>

                <p>
                  <b>How We May Use and Disclose Your PHI</b>
                </p>
                <br />
                <p>
                  The following categories describe different ways that we use
                  and disclose your PHI. We have provided you with examples in
                  certain categories; however, not every permissible use or
                  disclosure will be listed in this Notice. Note that some types
                  of PHI, such as HIV information, genetic information, alcohol
                  and/or substance abuse records, and mental health records may
                  be subject to special confidentiality protections under
                  applicable state or federal law and we will abide by these
                  special protections. If you would like additional information
                  about special state law protections, you may contact the
                  Privacy Office or visit www.linkpharmacy.co.zm.
                </p>

                <p className={style.n_line}>
                  <b>
                    I. Uses and Disclosures Of PHI That Do Not Require Your
                    Prior Authorization
                  </b>
                </p>
                <br />
                <p>
                  Except where prohibited by federal or state laws that require
                  special privacy protections, we may use and disclose your PHI
                  for treatment, payment and health care operations without your
                  prior authorization as follows:
                </p>

                <p>
                  <b>Treatment.</b> We may use and disclose your PHI to provide
                  and coordinate the treatment, medications and services you
                  receive. For example, we may disclose PHI to pharmacists,
                  doctors, nurses, technicians and other personnel involved in
                  your health care. We may also disclose your PHI with other
                  third parties, such as hospitals, other pharmacies and other
                  health care facilities and agencies to facilitate the
                  provision of health care services, medications, equipment and
                  supplies you may need. This helps to coordinate your care and
                  make sure that everyone who is involved in your care has the
                  information that they need about you to meet your health care
                  needs.
                </p>

                <p>
                  <b>Payment.</b> We may use and disclose your PHI in order to
                  obtain payment for the health care products and services that
                  we provide to you and for other payment activities related to
                  the services that we provide. For example, we may contact your
                  insurer, pharmacy benefit manager or other health care payor
                  to determine whether it will pay for health care products and
                  services you need and to determine the amount of your
                  co-payment. We will bill you or a third-party payor for the
                  cost of health care products and services we provide to you.
                  The information on or accompanying the bill may include
                  information that identifies you, as well as information about
                  the services that were provided to you or the medications you
                  are taking. We may also disclose your PHI to other health care
                  providers or HIPAA covered entities who may need it for their
                  payment activities.
                </p>

                <p>
                  <b>Health Care Operations.</b> We may use and disclose your
                  PHI for our health care operations. Health care operations are
                  activities necessary for us to operate our health care
                  businesses. For example, we may use your PHI to monitor the
                  performance of the staff and pharmacists providing treatment
                  to you. We may use your PHI as part of our efforts to
                  continually improve the quality and effectiveness of the
                  health care products and services we provide. We may also
                  analyze PHI to improve the quality and efficiency of health
                  care, for example, to assess and improve outcomes for health
                  care conditions. We may also disclose your PHI to other HIPAA
                  covered entities that have provided services to you so that
                  they can improve the quality and effectiveness of the health
                  care services that they provide. We may use your PHI to create
                  de-identified data, which is stripped of your identifiable
                  data and no longer identifies you.
                </p>
                <br />

                <p>
                  <b>
                    We may also use and disclose your PHI without your prior
                    authorization for the following purposes:
                  </b>
                </p>
                <br />
                <p>
                  <b>Business Associates.</b> We may contract with third parties
                  to perform certain services for us, such as billing services,
                  copy services or consulting services. These third party
                  service providers, referred to as Business Associates, may
                  need to access your PHI to perform services for us. They are
                  required by contract and law to protect your PHI and only use
                  and disclose it as necessary to perform their services for us.
                </p>

                <p>
                  <b>
                    To Communicate with Individuals Involved in Your Care or
                    Payment for Your Care.
                  </b>{" "}
                  We may disclose to a family member, other relative, close
                  personal friend, or any other person you identify, PHI
                  directly relevant to that person's involvement in your care or
                  payment related to your care. Additionally, we may disclose
                  PHI to your "personal representative." If a person has the
                  authority by law to make health care decisions for you, we
                  will generally regard that person as your "personal
                  representative" and treat him or her the same way we would
                  treat you with respect to your PHI.
                </p>

                <p>
                  <b>Food and Drug Administration ("FDA").</b> We may disclose
                  to persons under the jurisdiction of the FDA, PHI relative to
                  adverse events with respect to drugs, foods, supplements,
                  products and product defects, or post-marketing surveillance
                  information to enable product recalls, repairs, or
                  replacement.
                </p>

                <p>
                  <b>Worker's Compensation.</b> To the extent necessary to
                  comply with law, we may disclose your PHI to worker's
                  compensation or other similar programs established by law.
                </p>

                <p>
                  <b>Public Health.</b> We may disclose your PHI to public
                  health or legal authorities charged with preventing or
                  controlling disease, injury, or disability, including the FDA.
                  In certain circumstances, we may also report work-related
                  illnesses and injuries to employers for workplace safety
                  purposes.
                </p>

                <p>
                  <b>Law Enforcement.</b> We may disclose your PHI for law
                  enforcement purposes as required or permitted by law for
                  example, in response to a subpoena or court order, in response
                  to a request from law enforcement, and to report limited
                  information in certain circumstances.
                </p>

                <p>
                  <b>As Required by Law.</b> We will disclose your PHI when
                  required to do so by federal, state or local law.
                </p>

                <p>
                  <b>Health Oversight Activities.</b> We may disclose your PHI
                  to an oversight agency for activities authorized by law. These
                  oversight activities include audits, investigations,
                  inspections, and credentialing, as necessary for licensure and
                  for the government to monitor the health care system,
                  government programs and compliance with civil rights laws.
                </p>

                <p>
                  <b>Judicial and Administrative Proceedings.</b> If you are
                  involved in a lawsuit or a dispute, we may disclose your PHI
                  in response to a court or administrative order. We may also
                  disclose your PHI in response to a subpoena, discovery
                  request, or other lawful process instituted by someone else
                  involved in the dispute, but only if efforts have been made,
                  either by the requesting party or us, to first tell you about
                  the request or to obtain an order protecting the information
                  requested.
                </p>

                <p>
                  <b>Research.</b> We may use your PHI to conduct research and
                  we may disclose your PHI to researchers as authorized by law.
                  For example, we may use or disclose your PHI as part of a
                  research study when the research has been approved by an
                  institutional review board or privacy board that has reviewed
                  the research proposal and established protocols to ensure the
                  privacy of your information.
                </p>

                <p>
                  <b>Coroners, Medical Examiners and Funeral Directors.</b> We
                  may release your PHI to coroners or medical examiners so that
                  they can carry out their duties. This may be necessary, for
                  example, to identify a deceased person or determine the cause
                  of death. We may also disclose PHI to funeral directors
                  consistent with applicable law to enable them to carry out
                  their duties.
                </p>

                <p>
                  <b>Organ or Tissue Procurement Organizations.</b> Consistent
                  with applicable law, we may disclose your PHI to organ
                  procurement organizations or other entities engaged in the
                  procurement, banking, or transplantation of organs for the
                  purpose of tissue donation and transplant.
                </p>

                <p>
                  <b>Notification.</b> We may use or disclose your PHI to notify
                  or assist in notifying a family member, personal
                  representative, or another person responsible for your care,
                  regarding your location and general condition.
                </p>

                <p>
                  <b>Disaster Relief.</b> We may use and disclose your PHI to
                  organizations for purposes of disaster relief efforts.
                </p>

                <p>
                  Fund raising. As permitted by applicable law, we may contact
                  you to provide you with information about our fundraising
                  programs. You have the right to "opt out" of receiving these
                  communications and such fundraising materials will explain how
                  you may request to opt out of future communications if you do
                  not want us to contact you further for fundraising efforts.
                </p>

                <p>
                  <b>Correctional Institution.</b> If you are or become an
                  inmate of a correctional institution, we may disclose to the
                  institution, or its agents, PHI necessary for your health and
                  the health and safety of other individuals.
                </p>

                <p>
                  <b>To Avert a Serious Threat to Health or Safety.</b> We may
                  use and disclose your PHI when necessary to prevent a serious
                  threat to your health and safety or the health and safety of
                  the public or another person.
                </p>

                <p>
                  <b>Military and Veterans.</b> If you are a member of the armed
                  forces, we may release PHI about you as required by military
                  command authorities. We may also release PHI about foreign
                  military personnel to the appropriate foreign military
                  authority.
                </p>

                <p>
                  <b>
                    National Security, Intelligence Activities, and Protective
                    Services for the President and Others.
                  </b>{" "}
                  We may release PHI about you to federal officials for
                  intelligence, counterintelligence, protection of the
                  President, and other national security activities authorized
                  by law.
                </p>

                <p>
                  <b>Victims of Abuse or Neglect.</b> We may disclose PHI about
                  you to a government authority if we reasonably believe you are
                  a victim of abuse or neglect. We will only disclose this type
                  of information to the extent required by law, if you agree to
                  the disclosure, or if the disclosure is allowed by law and we
                  believe it is necessary to prevent serious harm to you or
                  someone else.
                </p>
                <br />

                <p className={style.n_line}>
                  <b>
                    II. Uses and Disclosures of PHI that Require Your Prior
                    Authorization
                  </b>
                </p>
                <br />

                <p>
                  <b>Specific Uses or Disclosures Requiring Authorization.</b>{" "}
                  We will obtain your written authorization for the use or
                  disclosure of psychotherapy notes, use or disclosure of PHI
                  for marketing, and for the sale of PHI, except in limited
                  circumstances where applicable law allows such uses or
                  disclosure without your authorization.
                </p>

                <p>
                  <b>Other Uses and Disclosures.</b> We will obtain your written
                  authorization before using or disclosing your PHI for purposes
                  other than those described in this Notice or otherwise
                  permitted by law. You may revoke an authorization in writing
                  at any time. Upon receipt of the written revocation, we will
                  stop using or disclosing your PHI, except to the extent that
                  we have already taken action in reliance on the authorization.
                </p>

                <p>
                  <b>Your Health Information Rights:</b>
                </p>

                <p>
                  <b>Obtain a paper copy of the Notice upon request.</b> You may
                  request a copy of our current Notice at any time. Even if you
                  have agreed to receive the Notice electronically, you are
                  still entitled to a paper copy. You may obtain a paper copy at
                  the site where you obtain health care services from us or by
                  contacting the Privacy Office.
                </p>

                <p>
                  <b>
                    Request a restriction on certain uses and disclosures of
                    PHI.
                  </b>{" "}
                  You have the right to request additional restrictions on our
                  use or disclosure of your PHI by sending a written request to
                  the Privacy Office. We are not required to agree to the
                  restrictions, except in the case where the disclosure is to a
                  health plan for purposes of carrying out payment or health
                  care operations, is not otherwise required by law, and the PHI
                  pertains solely to a health care item or service for which
                  you, or a person on your behalf, has paid in full.
                </p>

                <p>
                  <b>Inspect and obtain a copy of PHI.</b> With a few
                  exceptions, you have the right to access and obtain a copy of
                  the PHI that we maintain about you. If we maintain an
                  electronic health record containing your PHI, you have the
                  right to request to obtain the PHI in an electronic format. To
                  inspect or obtain a copy of your PHI, you must send a written
                  request to the Privacy Office. You may ask us to send a copy
                  of your PHI to other individuals or entities that you
                  designate. We may deny your request to inspect and copy in
                  certain limited circumstances. If you are denied access to
                  your PHI, you may request that the denial be reviewed.
                </p>

                <p>
                  <b>Request an amendment of PHI.</b> If you feel that PHI we
                  maintain about you is incomplete or incorrect, you may request
                  that we amend it. To request an amendment, you must send a
                  written request to the Privacy Office. You must include a
                  reason that supports your request. If we deny your request for
                  an amendment, we will provide you with a written explanation
                  of why we denied it.
                </p>

                <p>
                  <b>Receive an accounting of disclosures of PHI.</b> With the
                  exception of certain disclosures, you have a right to receive
                  a list of the disclosures we have made of your PHI, in the six
                  years prior to the date of your request, to entities or
                  individuals other than you. To request an accounting, you must
                  submit a request in writing to the Privacy Office. Your
                  request must specify a time period.
                </p>

                <p>
                  <b>
                    Request communications of PHI by alternative means or at
                    alternative locations.
                  </b>{" "}
                  You have the right to request that we communicate with you
                  about health matters in a certain way or at a certain
                  location. For instance, you may request that we contact you at
                  a different residence or post office box, or via e-mail or
                  other electronic means. Please note if you choose to receive
                  communications from us via e-mail or other electronic means,
                  those may not be a secure means of communication and your PHI
                  that may be contained in our e-mails to you will not be
                  encrypted. This means that there is risk that your PHI in the
                  e-mails may be intercepted and read by, or disclosed to,
                  unauthorized third parties. To request confidential
                  communication of your PHI, you must submit a request in
                  writing to the Privacy Office. Your request must tell us how
                  or where you would like to be contacted. We will accommodate
                  all reasonable requests. However, if we are unable to contact
                  you using the ways or locations you have requested, we may
                  contact you using the information we have.
                </p>

                <p>
                  <b>Notification of a Breach.</b> You have a right to be
                  notified following a breach of your unsecured PHI, and we will
                  notify you in accordance with applicable law.
                </p>

                <p>
                  <b>Where to obtain forms for submitting written requests.</b>{" "}
                  You may obtain forms for submitting written requests by
                  contacting the Privacy Officer at linkpharmacy zm. Privacy
                  Office, 200 Wilmot Road, Mail Stop 9000, Deerfield, Illinois
                  60015 or toll-free by telephone at ( +260 211 255 556. You can
                  also visit Link Pharmacy.com to obtain these forms.
                </p>

                <p>
                  <b>For More Information or to Report a Problem </b>If you have
                  questions or would like additional information about Link
                  Pharmacy' privacy practices, you may contact our Privacy
                  Officer at Walgreen Co. Privacy Office, 200 Wilmot Road, Mail
                  Stop 9000, Deerfield, Illinois 60015 or toll-free by telephone
                  at ( +260 211 255 556. If you believe your privacy rights have
                  been violated, you can file a complaint with the Privacy
                  Officer or with the Secretary of Health and Human Services.
                  You can also file a complaint through www.linkpharmacy.co.zm,
                  and we will route your complaint to the Privacy Office. There
                  will be no retaliation for filing a complaint.
                </p>

                <p>
                  <b>Effective Date </b> This Notice is effective as of
                  September 23, 2013.
                </p>
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default NoticePrivacy;
