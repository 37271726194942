import { useState, useEffect } from "react";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import { SmoothScroll } from "../helpers/SmoothScroll";

/**
 * `usePagination` is a custom hook that synchronizes a page state with a URL's `page` query parameter.
 * This is useful for components that require pagination and want to keep the page state in sync with the URL.
 *
 * @param initialPage - The initial page number.
 * @returns An array with the current page number and a function to change the page.
 */
export const usePagination = (initialPage: number): [number, (newPage: number) => void] => {
  const navigate = useNavigate();
  const location = useLocation();
  const action = useNavigationType();
  const [page, setPage] = useState<number>(initialPage);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get("page");
    if (pageParam) {
      setPage(parseInt(pageParam));
    }
  }, [location]);

  const onPageChange = (newPage: number): void => {
    setPage(newPage);
    const currentSearch = new URLSearchParams(location.search);
    currentSearch.set("page", newPage.toString());
    navigate(`${location.pathname}?${currentSearch.toString()}`);
  };

  // Effect for scroll listener
  useEffect(() => {
    if (action !== "POP") {
      SmoothScroll(0, "smooth");
    }
  }, [location, action]);

  return [page, onPageChange];
};
