import styles from "../../styles/views/FluShots.module.css";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import React from "react";

const FluShots = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl mb-1">Flu Shots</h1>
          <p className="text-white font-opensans">Our waiting list is now open</p>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <h2 className="font-opensans-semibold text-xl">Be the first to hear about appointments.</h2>
        <p className="mb-3">
          We'll help you and your family stay up-to-date on recommended vaccines. Walk-ins welcome, or schedule an
          appointment.
        </p>

        <div>
          <h2 className="font-opensans-semibold text-xl mb-3">What is seasonal flu?</h2>
          <p className="mb-3">
            Seasonal flu is a viral illness spread by coughs and sneezes. People can catch it all year round but it's
            more common in winter, which is why it's known as seasonal flu. It’s easily spread and can affect a large
            number of working adults of all ages.
          </p>
        </div>

        <div>
          <h2 className="font-opensans-semibold text-xl mb-0">Corporate Flu Vaccination Service</h2>
          <p className="mb-3 text-gray-500 text-sm mb-3">Convenient, Cost-effective, Care.</p>

          <h3 className="font-opensans-semibold text-lg mb-3">
            How we can help you protect your employees against flu?
          </h3>
          <p className="mb-3">
            Minor illness, like flu, is a common cause of short-term absence for the majority of organisations.
          </p>
          <p className="mb-3">
            Offering flu vaccinations is a simple and cost-effective way to care for your employees and cut down on
            absence.
          </p>
          <p className="mb-3">We can protect your workforce against flu from as little as K250.00 per person***.</p>
        </div>

        <div>
          <h2 className="font-opensans-semibold text-xl mb-0">Use our simple online system</h2>

          <p className="mb-3">
            Our online system makes it easy for you to order and distribute e-vouchers, while controlling your
            expenditure.
          </p>
          <p className="mb-3">
            Your employees simply take their e-voucher to Link mediclinic, Pinnacle mall and receive their flu
            vaccination at no cost to them.
          </p>

          <div className="flex flex-col md:flex-row my-10">
            <div className="flex flex-col items-center text-center">
              <h5 className="font-opensans-extrabold text-3xl text-link-green-1">1</h5>
              <p className="font-opensans-bold mb-2">Buy your e-vouchers online</p>
              <p>Sign up or log into our online system. Choose the number of e-vouchers you'd like and pay online</p>
            </div>

            <div className="flex flex-col items-center text-center">
              <h5 className="font-opensans-extrabold text-3xl text-link-green-1">2</h5>
              <p className="font-opensans-bold mb-2">Buy your e-vouchers online</p>
              <p>Using the information you provide, we'll send your employees their personalised e-vouchers</p>
            </div>

            <div className="flex flex-col items-center text-center">
              <h5 className="font-opensans-extrabold text-3xl text-link-green-1">3</h5>
              <p className="font-opensans-bold mb-2">Buy your e-vouchers online</p>
              <p>Employees book appointments online or in-store at Link Mediclinic</p>
            </div>
          </div>
        </div>

        <div>
          <h2 className="font-opensans-semibold text-xl mb-3">The benefits of using our service:</h2>

          <ul className="list-disc list-inside mb-3">
            <li className="mb-1">Helps reduce flu-related employee absence.</li>
            <li className="mb-1">
              Simple online ordering process with hassle-free e-vouchers sent to your employees by email or text
              message.
            </li>
            <li className="mb-1">Flexible appointments to suit your colleagues' schedules.</li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default FluShots;
