import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <CircularProgress
        style={{ color: "green", marginTop: "10%" }}
        disableShrink
      />
    </div>
  );
};

export default Loader;
