import { useEffect, useState } from "react";

// Define the type for our state
interface WindowSize {
  width: number;
  height: number;
}

/**
 * Custom hook to calculate and return the browser window size.
 * @returns An object representing the window size { width: number, height: number }
 */
const useWindowSize = (): WindowSize => {
  // Initial state
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Handler for window resize events
  const onResize = () => {
    // Update state with new window dimensions
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Call the handler right away so that we can populate the initial window dimensions
    onResize();

    // Listen for window resize events
    window.addEventListener("resize", onResize, { passive: true });

    // Clean up function
    return () => {
      // Remove resize listener when this component is unmounted
      window.removeEventListener("resize", onResize);
    };
  }, []); // Empty array ensures effect is only run on mount and unmount

  // Return current window size
  return windowSize;
};

export default useWindowSize;
