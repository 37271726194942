/* eslint-disable jsx-a11y/anchor-is-valid */

import style from "../../styles/views/PrivacyPolicy.module.css";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

const Disclaimer = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="privacy_wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className={style.p_policy}>
              <br />
              <h1>Disclaimer</h1>
              <br />
              <p>
                {" "}
                Welcome to Link pharmacy! Please take a few minutes to read
                these important disclaimer and conditions ("disclaimer").
              </p>
              <h2>I. Introduction</h2>
              <p>
                These privacy govern your use of the online services provided by
                linkpharmacy.co.zm, including websites, mobile applications,
                photo services, Auto-Reorder & Save, Rx reminders, in-store
                wifi, Link Pharmacy Content (as defined in Section 2), and other
                services ("Services"). The Services are intended for use by
                users that reside in the U.S. and are 18 or older (or 13 or
                older, with the consent and involvement of a parent or
                guardian). If you are not the intended audience or you object to
                these privacy, you may not use our Services. These privacy act
                as a binding agreement between you and us, and by accessing any
                Services in any way, you agree to be bound by all of them,
                including, but not limited to,{" "}
                <b>resolving disputes via binding arbitration</b>, conducting
                this transaction electronically, disclaiming warranties, damage
                and remedy exclusions and limitations, and a choice of{" "}
                <b>Illinois law</b>.
              </p>

              <p>
                From time to time we may update the Services and these privacy,
                and such changes shall be applied prospectively. Your use of the
                Services after we post any changes to these privacy constitutes
                your agreement to those changes. You agree to review these
                privacy periodically to ensure that you are familiar with the
                most recent version. We will notify you of any material updates
                to the privacy. We may, in our sole discretion, and at any time,
                discontinue the Services or any part thereof, with or without
                notice, or may prevent your use of the Services with or without
                notice to you. You agree that you do not have any rights in the
                Services and that we will have no liability to you if the
                Services are discontinued or your ability to access the Services
                (including any content you may have posted) is terminated.
              </p>

              <h2>II. Information We Display</h2>

              <p>
                We own or license all content contained within the Services,
                including without limitation, text, images, icons, data,
                software, trademarks, trade dress, and User Content (as defined
                in Section 3) (collectively, "Link Pharmacy Content"), and you
                may access and use it solely for your own personal,
                non-commercial shopping and information purposes. Link Pharmacy
                Content may be protected by domestic and international
                copyright, patent, trademark, and other rights, and all rights,
                titles, and interests are reserved by the respective owners.
                Copying, publishing, broadcasting, modifying, distributing or
                transmitting any Link Pharmacy Content in any way without our
                prior written consent is strictly prohibited.
              </p>

              <p>
                While we work hard to provide a superior customer experience, we
                cannot guarantee that all Link Pharmacy Content we display is
                correct, complete, or current, including product pricing,
                images, information, and availability.
              </p>

              <p>
                We may display or allow users to create User Content, or link to
                or from third party sites and material. We do not monitor,
                verify, or otherwise endorse User Content or third party sites
                or material, and hereby disclaim all liability with respect
                thereto. Your use of any third party site is subject to the
                privacy of use and privacy policies made applicable thereto by
                such third party. Any health-related information is provided to
                enhance your experience, but is not intended to constitute
                medical advice or diagnose, treat, cure, or prevent any medical
                condition. If you have questions about your health or treatment,
                please contact your pharmacist or other medical professional.
              </p>

              <h2>III. Your Actions</h2>

              <p>
                To ensure a positive experience for all users, you agree that
                you will not: (i) use or exploit the Services for any purpose
                that is illegal, tortuous, libelous, defamatory, false,
                misleading, harassing, abusive, obscene, vulgar, pornographic,
                intrusive on another's privacy, harmful to the interests of us
                or our users, or inappropriate with respect to race, gender,
                sexuality, ethnicity, or another intrinsic characteristic; (ii)
                upload, post, reproduce, distribute, transmit, transfer, or
                otherwise use or exploit in any way information, software, or
                other material protected by any intellectual property,
                publicity, or privacy right (including our trademarks and trade
                dress) without first obtaining the permission of the owner of
                such rights; (iii) collect or store personal data about other
                users; (iv) reproduce, distribute, duplicate, copy, sell,
                resell, decompile, modify, reverse engineer, disassemble,
                assign, create derivative works of, impersonate, or otherwise
                exploit any part of the Services (including any software we make
                available) for any purpose unless expressly approved by us in
                writing; (v) upload, post, email, or otherwise transmit any
                advertising or promotional materials or any other form of
                solicitation or unauthorized communication; (vi) undertake or
                facilitate any effort to interrupt, modify, limit, interfere
                with, commercially exploit, or negatively impact the
                functionality of any Services, nor will you use or exploit the
                Services in any way other than as expressly permitted by these
                privacy; (vii) remove, alter, obscure, or otherwise render
                unintelligible any proprietary notices; and (viii) deploy or
                utilize any automated method of collecting content from our
                site, such as robots, crawlers, and scraping mechanisms
                (Sections (i) through (viii) collectively, "Prohibited Use"). To
                protect the interests of us and our users, we may monitor,
                modify, terminate, and disclose to third parties (including law
                enforcement) your use of the Services (including User Content)
                at any time.
              </p>
              <p>
                You may be able to create or submit designs, photos, product
                reviews, written posts, and other content ("User Content"). You
                agree that: (i) you will not display or furnish any User Content
                that contains personal information about or the likeness of any
                individual, violates the privacy/publicity of any other
                individual or entity, or anything that you are under a
                contractual obligation to keep private or confidential; (ii) you
                will not impersonate any person or organization (including us),
                or misrepresent an affiliation with another person or
                organization; and (iii) you will not post or upload any User
                Content that constitutes a Prohibited Use. Notwithstanding the
                foregoing, these privacy in no way limit your rights under the
                Consumer Review Fairness Act of 2016 (H.R. 5111).
              </p>
              <p>
                By displaying, publishing, or otherwise posting any User Content
                on or through the Services, or by affirmatively agreeing to our
                online request to use your User Content (electronically or
                otherwise), you hereby grant us a perpetual, non-exclusive,
                sub-licensable (through multiple tiers), worldwide, fully-paid,
                royalty free license to use such User Content (including without
                limitation, to modify, publicly perform, publicly display,
                reproduce, create derivative works of, and distribute such User
                Content for any purpose, whether it contains a personal likeness
                or otherwise) in any and all media now known or hereinafter
                developed, without the requirement to make payment to any party
                or seek any third party permission or make any authorship
                attribution. You continue to retain all ownership rights in your
                User Content, and you continue to have the right to use your
                User Content in any way you choose, subject to these privacy and
                the license described herein, though for purposes of this
                Section, you waive all "moral rights" in the same. You represent
                and warrant that (i) you own the User Content submitted,
                displayed, published, provided, or posted by you on the
                Services, or otherwise have the right to grant the license set
                forth herein, and (ii) your displaying, publishing, providing,
                or posting of any User Content you submit, and our use thereof
                in accordance with these privacy, does not and will not violate
                applicable law or any privacy, publicity, copyright, trademark,
                patent, contract or any other rights of any person or entity.
                Notwithstanding the foregoing, you further understand and agree
                that you have no ownership rights to any account you may have
                with us, or other access to the Services or features therein. We
                may cancel and terminate any account or User Content at any time
                without notice. We assume no liability for any information
                removed from our Services, and reserve the right to permanently
                restrict access to the Services or a user account.
              </p>

              <p>
                If the Services require you to create an account or provide
                personal information, you agree to submit accurate, complete
                information, and to update it as appropriate. You are solely
                responsible for the activities and security of your account, and
                you must notify us if you suspect any unauthorized use. We are
                not liable for any loss you incur as a result of someone else
                using your password or account, either with or without your
                knowledge, and are not responsible for any delay in shutting
                down your account after you have reported a breach of security
                to us.
              </p>

              <h2>IV. Service-Specific privacy</h2>

              <p>
                The Services we provide may be subject to additional policies,
                such as a{" "}
                <a href="/help_returns" target="_blank">
                  return policy
                </a>
                ,
                <a href="/help_giftcards" target="_blank">
                  {" "}
                  gift card policy
                </a>
                ,
                <a href="/help_balance" target="_blank">
                  {" "}
                  Balance Rewards policy
                </a>
                , or other privacy. You can learn more about those specific
                services and policies
                <a href="/help" target="_blank">
                  {" "}
                  here.
                </a>
              </p>

              <p>
                If you make any purchases, you represent that you and we
                (including our service providers) are authorized to use the
                information you provide (including payment method information)
                to carry out the transaction. YOU REPRESENT AND WARRANT THAT YOU
                HAVE THE LEGAL RIGHT TO USE ANY CREDIT CARD(S) UTILIZED IN
                CONNECTION WITH ANY TRANSACTION. Our acknowledgement of an order
                means that your order request has been received, but not that
                your order has been accepted or shipped or that the price or
                availability of an item has been confirmed. We reserve the right
                to change the price of an item up until the time of shipment.
                Title and risk of loss transfer to you upon our delivery of the
                order to the carrier. Notwithstanding anything to the contrary,
                we reserve the right to cancel or limit any orders for any
                reason. Prices, promotions, offers, and product availability are
                all subject to change without notice.
              </p>

              <p>
                If you participate in our Balance Rewards program, that
                participation is governed by the Balance Rewardspolicy.
              </p>

              <p>
                If you choose to upload images and create products for purchase
                through our photo services, you agree that you are solely
                responsible for such User Content, and that you will notify us
                of any content you see that constitutes Prohibited Use. To
                facilitate use of the photo services, we may offer a reasonable
                amount of temporary storage space to active users, as we may
                determine from time to time in our sole discretion.
              </p>

              <p>
                If you create an automatically recurring order through our
                Auto-Reorder & Save program, the additional privacy of this
                paragraph apply. Your initial order will be delivered using the
                shipping method you select, and all auto-reorders will be
                delivered via free standard shipping. Your initial order will
                cost the amount displayed at checkout, and all auto-reorders
                will be charged either the sale price in effect at the time or
                the then-current Auto-Reorder & Save discount, whichever is
                lower. Your orders will continue to ship until you cancel or
                change your auto-reorder. Your payment method will continue to
                be charged until you cancel or change it. Your initial order
                price is not guaranteed for auto-reorders. If the price
                advertised for any of your items changes, this price change will
                also apply to your items scheduled for Auto-Reorder. You will
                not be notified of price changes before your auto-reorder items
                are processed and shipped. Balance Rewards points may be earned
                on any order, subject to the Balance Rewards policy, but cannot
                be redeemed on any auto-reorder. To view and manage your
                auto-reorders, sign into Your Account and click "Auto-Reorder
                Manager." You may cancel or skip an auto-reorder at any time
                prior to the deadline displayed in the Auto-Reorder Manager. For
                additional information, click here or contact us here or at +260
                211 255 556.
              </p>

              <p>
                If you choose to access in-store wifi services we may provide,
                you agree that you are responsible for all activities occurring
                on your device.
              </p>

              <p>
                If you download one of our mobile applications, these privacy
                constitute an end-user license agreement, and you agree to act
                in accordance with the end-user license agreements and policies
                made applicable by your wireless carrier and operating system
                software provider (including as listed within their respective
                app stores). You agree you are solely responsible for any costs
                incurred with accessing one of our mobile applications. You may
                be required to send and receive, at your cost, electronic
                communications related to the Services offered via our mobile
                applications, including without limitation, administrative
                messages, service announcements, diagnostic data reports, and
                updates. If you do not have an unlimited wireless mobile data
                plan, you may incur additional charges from your wireless
                service provider in connection with your use of these Services.
                You are solely responsible for obtaining any additional
                subscription or connectivity services or equipment necessary to
                access these Services, including but not limited to payment of
                all third party fees associated therewith, including fees for
                information sent to or through these Services. We may, at our
                discretion, automatically download updates to our mobile
                applications to your device from time to time. You agree to
                accept these updates, and to pay for any costs associated with
                receiving them. Our mobile applications may not work with all
                devices or all mobile carriers.
              </p>

              <p>
                If you download and use one of our mobile applications available
                through the Apple platform: You, the end-user of these Services,
                acknowledge that this agreement is entered into by and between
                Link Pharmacy and its subsidiaries and affiliates, and you, and
                not with Apple, Inc., and Apple, Inc. is not responsible for the
                Services. Notwithstanding the foregoing, you acknowledge that
                Apple, Inc. and its subsidiaries are third-party beneficiaries
                of this agreement and that Apple, Inc. has the right (and is
                deemed to have accepted the right) to enforce this agreement.
                You acknowledge that Apple, Inc. has no obligation whatsoever to
                maintain or support the Services. You acknowledge that you have
                reviewed and agree to the App Store privacy and Conditions
                (located online at{" "}
                <a href="https://www.apple.com/legal/itunes/us/privacy.html#APPS">
                  https://www.apple.com/legal/itunes/us/privacy.html#APPS)
                </a>
                , including the Usage Rules set forth therein. This agreement
                incorporates by reference the Licensed Application End User
                License Agreement (the "LAEULA") published by Apple, Inc.
                (located online at
                <a
                  href="https://www.apple.com/legal/itunes/us/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  https://www.apple.com/legal/itunes/us/privacy.html#APPS)
                </a>
                . For purposes of this Agreement, our mobile application-based
                Services are considered the "Licensed Application" as defined in
                the LAEULA and Link Pharmacy is considered the "Application
                Provider" as defined in the LAEULA. If any of the privacy herein
                conflict with the privacy of the LAEULA, these privacy shall
                control. In the event of any failure of the Services to conform
                to any applicable warranty set forth herein, you acknowledge
                that to the maximum extent permitted by applicable law, Apple,
                Inc. will have no other warranty obligation whatsoever with
                respect to the Services, and any other claims, losses,
                liabilities, damages, costs or expenses attributable to any
                failure to conform to any warranty. You further acknowledge and
                agree that in no event will Apple, Inc. be responsible for any
                claims relating to the Services (including, without limitation,
                a third party claim that the Services infringe that third
                party's intellectual property rights) or your use or possession
                of the Services, including but not limited to: (i) product
                liability claims; (ii) any claim that the Services fail to
                conform to any applicable legal or regulatory requirement; and
                (iii) claims arising under consumer protection or similar
                legislation.
              </p>

              <h2>V. Privacy</h2>

              <p>
                While providing the Services, you may provide, and we may
                automatically collect, information about you and your use of the
                Services, including without limitation, your real-time location,
                MAC address, and IP address. Our collection and use of such
                information is governed by our{" "}
                <a href="/online-policy" target="_blank">
                  {" "}
                  Online Privacy and Security Policy{" "}
                </a>
                and{" "}
                <a href="/notice-privacy" target="_blank">
                  {" "}
                  Notice of Privacy Practices
                </a>
                .
              </p>

              <h2>
                VI. Disclaimers, Limitation of Liability, and Indemnification
              </h2>

              <p>
                We provide the Services (including, without limitation, all Link
                Pharmacy Content, User Content, and other content whatsoever) as
                a service to you. THESE SERVICES ARE PROVIDED ON AN "AS IS" AND
                "AS AVAILABLE" BASIS WITHOUT WARRANTY OF ANY KIND, WHETHER
                EXPRESS, IMPLIED OR STATUTORY, AND TO THE EXTENT PERMISSIBLE BY
                APPLICABLE LAW, WE EXPRESSLY DISCLAIM ANY AND ALL SUCH
                WARRANTIES. THIS DISCLAIMER INCLUDES, BUT IS NOT LIMITED TO, ANY
                AND ALL REPRESENTATIONS OR WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE, RELIABILITY,
                SECURITY, AVAILABILITY, ACCURACY, QUALITY, APPROPRIATENESS,
                COMPLETENESS, NON-INFRINGEMENT, OR THAT THE SERVICES ARE ERROR
                OR DEFECT-FREE. BEFORE USING ANY PRODUCT, YOU SHOULD CONFIRM ANY
                INFORMATION OF IMPORTANCE TO YOU ON THE PRODUCT PACKAGING. WE
                ARE NOT RESPONSIBLE FOR, AND HEREBY DISCLAIM ANY LIABILITY WITH
                RESPECT TO, ALL USER CONTENT, ANY USER, AND ANY THIRD PARTY. YOU
                ASSUME RESPONSIBILITY FOR THE ACCURACY, APPROPRIATENESS AND
                LEGALITY OF ANY INFORMATION YOU SUPPLY US. YOU AGREE THAT YOU
                USE THESE SERVICES AT YOUR SOLE RISK.
              </p>

              <p>
                THESE SERVICES ARE MADE AVAILABLE TO USERS IN THE U.S., AND WE
                MAKE NO WARRANTY OR REPRESENTATION THAT ANY OF THE SERVICES ARE
                APPROPRIATE FOR USE IN OTHER LOCATIONS. NO PART OF THE SERVICES
                SHOULD BE CONSTRUED AS AN OFFER OR SOLICITATION BY OR TO ANYONE
                TO WHOM SUCH AN OFFER OR SOLICITATION CANNOT LEGALLY BE MADE.
              </p>

              <p>
                AS PARTIAL CONSIDERATION FOR YOUR ACCESS TO OUR SERVICES
                (INCLUDING Link Pharmacy CONTENT), YOU AGREE THAT WE ARE NOT
                LIABLE TO YOU IN ANY MANNER WHATSOEVER FOR DECISIONS YOU MAY
                MAKE OR YOUR ACTIONS OR NON-ACTIONS IN RELIANCE THEREUPON. YOU
                ALSO AGREE THAT OUR AGGREGATE LIABILITY ARISING FROM OR RELATED
                TO YOUR USE OF AND ACCESS TO THE SERVICES, REGARDLESS OF THE
                FORM OF ACTION OR CLAIM (FOR EXAMPLE, CONTRACT, WARRANTY, TORT,
                NEGLIGENCE, STRICT LIABILITY, PROFESSIONAL MALPRACTICE, FRAUD,
                INFRINGEMENT OR OTHER BASES FOR CLAIMS) IS LIMITED TO THE
                PURCHASE PRICE OF ANY ITEMS YOU PURCHASED FROM US IN THE
                APPLICABLE TRANSACTION, IF ANY. WE SHALL NOT IN ANY CASE BE
                LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                CONSEQUENTIAL, OR PUNITIVE DAMAGES ("DAMAGES"), NOR SHALL WE BE
                RESPONSIBLE FOR ANY DAMAGES WHATSOEVER THAT RESULT FROM
                MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES, ERRORS,
                DEFECTS, DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF
                PERFORMANCE, WHETHER OR NOT CAUSED BY EVENTS BEYOND OUR
                REASONABLE CONTROL, INCLUDING BUT NOT LIMITED TO ACTS OF GOD,
                COMMUNICATIONS LINE FAILURE, THEFT, DESTRUCTION, OR UNAUTHORIZED
                ACCESS TO OUR SITES, RECORDS, PROGRAMS, SERVICES, OR CONTENT,
                EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                IF YOU ARE DISSATISFIED WITH THESE privacy OR OUR SERVICES
                (INCLUDING Link Pharmacy CONTENT), YOUR SOLE AND EXCLUSIVE
                REMEDY IS TO DISCONTINUE USING THE SERVICES.
              </p>

              <p>
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                LIABILITY FOR INCIDENTAL CONSEQUENTIAL, OR OTHER DAMAGES; AS A
                RESULT, THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT BE
                APPLICABLE TO YOU, AND THE FOREGOING PARAGRAPH SHALL NOT APPLY
                TO A RESIDENT OF NEW JERSEY TO THE EXTENT DAMAGES TO SUCH NEW
                JERSEY RESIDENT ARE THE RESULT OF OUR NEGLIGENT, FRAUDULENT OR
                RECKLESS ACT(S) OR INTENTIONAL MISCONDUCT. YOU AGREE TO
                INDEMNIFY, DEFEND AND HOLD HARMLESS Link Pharmacy, INCLUDING ITS
                OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, LICENSORS,
                SUPPLIERS, INFORMATION PROVIDERS AND AGENTS, FROM AND AGAINST
                ALL LOSSES, EXPENSES, DAMAGES, FEES, FINES, AND COSTS, INCLUDING
                WITHOUT LIMITATION, REASONABLE ATTORNEYS' FEES, RESULTING FROM
                OR RELATING TO YOUR USE OF THE SERVICES OR VIOLATIONS OF THESE
                privacy.
              </p>

              <h2>VII. Dispute Resolution</h2>

              <p>
                If a dispute ever arises between us, please{" "}
                <a href="/contact" target="_blank">
                  {" "}
                  contact us
                </a>
                . If we cannot resolve the matter informally, you and we each
                agree that any and all disputes or claims that have arisen or
                may arise between you and us shall be resolved exclusively
                through final and binding arbitration, rather than in court,
                except that you may assert claims in small claims court, if your
                claims qualify ("Agreement to Arbitrate"). The Federal
                Arbitration Act governs the interpretation and enforcement of
                this Agreement to Arbitrate, which shall be conducted by the
                American Arbitration Association ("AAA") under its rules and
                procedures, including the AAA's Supplementary Procedures for
                Consumer-Related Disputes (as applicable), as modified by these
                privacy. A form for initiating arbitration proceedings is
                available on the AAA's website. The arbitration shall be held in
                the county in which you reside. If the value of the relief
                sought is $10,000 or less, you or we may elect to have the
                arbitration conducted by telephone or based solely on written
                submissions, which election shall be binding on you and us
                subject to the arbitrator's discretion to require an in-person
                hearing, if the circumstances warrant. Attendance at an
                in-person hearing may be made by telephone by you and/or us,
                unless the arbitrator requires otherwise.
              </p>

              <p>
                The arbitrator will decide the substance of all claims in
                accordance with the laws of the State of Illinois, including
                recognized principles of equity, and will honor all claims of
                privilege recognized by law. The arbitrator shall not be bound
                by rulings in prior arbitrations involving different Link
                Pharmacy users, but is bound by rulings in prior arbitrations
                involving the same Link Pharmacy user to the extent required by
                applicable law. The arbitrator's award shall be final and
                binding, and judgment on the award rendered by the arbitrator
                may be entered in any court having jurisdiction thereof.
              </p>

              <p>
                {" "}
                You and we agree that each of us may bring claims against the
                other only on an individual basis and not as a plaintiff or
                class member in any purported class or representative action or
                proceeding. Unless you and we agree otherwise, the arbitrator
                may not consolidate or join more than one person's or party's
                claims and may not otherwise preside over any form of a
                consolidated, representative, or class proceeding. The
                arbitrator may award relief (including monetary, injunctive, and
                declaratory relief) only in favor of the individual party
                seeking relief and only to the extent necessary to provide
                relief necessitated by that party's individual claim(s). Any
                relief awarded shall not affect our other users.
              </p>

              <p>
                You can choose to reject the Agreement to Arbitrate by mailing
                us a signed opt-out notice ("Opt-Out Notice") within 30 days
                after the date you first access the Services or accept any
                subsequently published version of these privacy. The Opt-Out
                Notice must include a statement that you do not agree to this
                Agreement to Arbitrate, your name, address, phone number, and
                any email address(es) used to log in to any applicable
                account(s) to which the opt-out applies. You must mail the
                Opt-Out Notice to Link Pharmacy, Attn: Litigation and Regulatory
                Law Department, 104 Wilmot Road, MS 1431, Deerfield, IL 60015.
                This procedure is the only way you can opt out of the Agreement
                to Arbitrate. If you opt out of the Agreement to Arbitrate, all
                other parts of these privacy, including all other provisions of
                this Section, will continue to apply. Opting out of this
                Agreement to Arbitrate has no effect on any previous, other, or
                future arbitration agreements that you may have with us.
              </p>

              <p>
                To the extent permitted by applicable law, any claims arising in
                connection with the use of these Services or these privacy must
                be filed within one (1) year of the date of the event giving
                rise to such action.
              </p>

              <h2>VIII. Digital Millennium Copyright Act</h2>

              <p>
                If you believe any Link Pharmacy Content infringes on your
                copyright, please <a href="#">click here</a> for instructions on
                how to notify us.
              </p>

              <h2>IX. Additional privacy</h2>

              <p>
                These privacy, including all other privacy and policies
                referenced herein, constitute the entire agreement between you
                and us with respect to the Service, and shall be construed in
                accordance with the laws of the State of Illinois, without
                respect to its conflict of laws rules. In the event of any
                inconsistencies between these privacy and the policies
                referenced therein, these privacy shall control. Our failure to
                exercise or enforce any privacy herein shall not constitute a
                waiver, and if we fail to act with respect to your breach or
                anyone else's breach on any occasion, we are not waiving our
                right to act with respect to future or similar breaches. If you
                breach any provision of these privacy, you may no longer use the
                Services. If these privacy or your permission to use the
                Services is terminated by us for any reason, the agreement
                formed by your acceptance of these privacy will nevertheless
                continue to apply and be binding upon you in respect of your
                prior use of the Services and anything relating to or arising
                from such use. If you are dissatisfied with the Services or with
                these privacy, your sole and exclusive remedy is to discontinue
                using the Services. These privacy, including any or all rights
                and obligations hereunder, may be freely assigned or transferred
                by us, but not by you. Section headings are included for
                convenience only, and shall not affect the interpretation of any
                privacy. You agree that any electronic notices we send you shall
                satisfy any requirement that such notices be made in writing. If
                any part of this these privacy is ruled to be unlawful, void, or
                for any reason unenforceable, then such part shall be deemed
                severable from these privacy, and shall be eliminated or limited
                to the minimum extent necessary. The remainder of these privacy,
                including any revised portion, shall remain and be in full force
                and effect.
              </p>

              <p>
                Our automated touch-tone prescription refill system is licensed
                under the following United States Patents: 5,128,984; 5,561,707;
                5,684,863; 5,815,551; 5,828,734; 5,898,762; 5,917,893;
                5,974,120; and others.
              </p>
              <p>
                Copyright {new Date().getFullYear()}, Link Pharmacy Co. All
                rights reserved.
              </p>
              <p>These disclaimer were last updated on August 20, 2018.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Disclaimer;
