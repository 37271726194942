import { Link } from "react-router-dom";

import styles from "../styles/components/SpecialtySideBar.module.css";

const SpecialtySideBar = () => {
  return (
    <div className={styles.aside_pharmacy_navbar}>
      <ul>
        <li>
          <Link to="/specialty-pharmacy">Specialty Pharmacy</Link>
        </li>
        <li>
          <Link to="/support-and-services">Our Support & Services</Link>
        </li>
        <li>
          <Link to="/financial-assistance">
            Financial Assistance Coordination
          </Link>
        </li>
        <li>
          <Link to="/specialty-faq">FAQ</Link>
        </li>
        <li>
          <Link to="/cancer">Cancer</Link>
        </li>
        <li>
          <Link to="/hiv">HIV</Link>
        </li>
        <li>
          <Link to="/fertility">Fertility</Link>
        </li>
      </ul>
    </div>
  );
};
export default SpecialtySideBar;
