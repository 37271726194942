/* eslint-disable jsx-a11y/anchor-is-valid */
import { Disclosure } from "@headlessui/react";
import { IoChevronUp } from "react-icons/io5";

import HelpCenterSidebar from "../../components/HelpCenterSidebar";
import Layout from "../../components/Layout/Layout";
import {
  helpPharmacyAutoRefils,
  helpPharmacyInsurancePayments,
  helpPharmacyParentInfo,
  helpPharmacyPrescriptionPolicy,
  helpPharmacyRecords,
  helpPharmacyRegistraion,
  meta,
} from "../../globals";
import style from "../../styles/views/HelpCenter.module.css";

const HelpPharmacy = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section>
        <div className={style.shipping_help_bg}>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className={style.shipping_help_header}>
                <span>Help center</span>
                <h1>Pharmacy</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-2">
            <HelpCenterSidebar />
          </div>
          <div className="col-md-6">
            <div className={style.shipping_help}>
              <p>
                Link Pharmacy is required by federal regulations to protect the
                privacy of your information. Your personal identifying
                information is used only to confirm your identity and to make
                sure that the person requesting access is really you. This
                process is similar to the authentication process used by banks,
                credit card companies and other organizations that require
                sensitive personal information to make sure that unauthorized
                individuals do not access your personal information.
              </p>
              <p>
                <b>
                  Attention: Current and past Link Pharmacy mail service or Link
                  pharmacy Specialty Pharmacy members.
                </b>
              </p>
              <p>
                On April 1, 2017, Prime Therapeutics joined Prime's mail and
                specialty pharmacies with those of Link Pharmacy. The new
                company is committed to providing you with the care and support
                you expect.
              </p>
              <p>
                <b>There are no changes to your service at this time.</b> As we
                join our pharmacies, we will let you know about any changes that
                may occur. Going forward, your personal information will be kept
                on file by the new company and continue to be subject to Link
                pharmacy privacy practices.
              </p>
              <p>
                Questions? Link Pharmacy Specialty Pharmacy members can call
                +260 211 255 556, 24/7; hearing Impaired (TTY) call +260 211 255
                556. Link pharmacy mail service members should call the number
                on their prescription label.
              </p>
              <p>
                Please <a href="#">click here</a> for more information.
              </p>
              <h4>{"Registration"}</h4>
              {helpPharmacyRegistraion.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
            <div className={style.shipping_help}>
              <h4>{"Prescription History and Records"}</h4>
              {helpPharmacyRecords.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
            <div className={style.shipping_help}>
              <h4>{"Parent/Guardian Information"}</h4>
              {helpPharmacyParentInfo.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
            <div className={style.shipping_help}>
              <h4>{"Pharmacy Insurance and Payments"}</h4>
              {helpPharmacyInsurancePayments.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
            <div className={style.shipping_help}>
              <h4>{"Prescription Fulfillment Policy"}</h4>
              {helpPharmacyPrescriptionPolicy.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
            <div className={style.shipping_help}>
              <h4>{"Auto Refills"}</h4>
              {helpPharmacyAutoRefils.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </section>
    </Layout>
  );
};
export default HelpPharmacy;
