import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { meta } from "../../globals";
import { IoLockClosed } from "react-icons/io5";
import { Formik } from "formik";
import { signupSchema } from "../../globals/schemas";
import LayoutAuth from "../../components/Layout/LayoutAuth";
import LinkForm from "../../components/forms/LinkForm";
import { useMutation } from "react-query";
import { useNotifications } from "../../context/NotificationsContext";
import { Button, Input } from "../../components/CustomComponents";
import { HiEye, HiEyeOff } from "react-icons/hi";

const Register = () => {
  const { login, isLoading, signedIn, redirect } = useContext(AuthContext);
  const { pushNotification } = useNotifications();
  const [passwordType, setPasswordType] = useState("password");
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleCheck = () => {
    setChecked(!checked);
    checked ? setPasswordType("text") : setPasswordType("password");
  };

  // eslint-disable-next-line no-undef
  const registerMutation = useMutation(
    ["register"],
    async (userInfo) => {
      const { data } = await axios.post("/auth/register", {
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        email: userInfo.email,
        phone_number: `${userInfo.phonePrefix}${userInfo.phone}`,
        password: userInfo.password,
      });

      return data;
    },
    {
      onSuccess: (data) => {
        login(data);
      },
      onError: (error) => {
        pushNotification({
          id: Math.random(),
          type: "Error",
          message: Object?.values(error?.response?.data?.errors)?.flat()?.[0] || "Something went wrong",
        });
      },
    }
  );

  useEffect(() => {
    const { state } = location;

    if (signedIn) {
      if (state?.from) {
        // navigate to the page the user was trying to access before signing in
        navigate(state.from, { replace: true });
      } else {
        // else navigate to the homepage
        navigate(redirect, { replace: true });
      }
    }
  }, [location, navigate, redirect, signedIn]);

  // Hide the login screen when loading.
  if (isLoading) return null;

  return (
    <LayoutAuth title={meta.title} description={meta.description} keywords={meta.keywords}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          phone: "",
          phonePrefix: "+260",
          email: "",
          password: "",
          confirmPassword: "",
        }}
        onSubmit={(values) => registerMutation.mutate(values)}
        validationSchema={signupSchema}
      >
        {({ isSubmitting, values, handleChange, handleBlur, touched, errors, setFieldValue, setFieldTouched }) => (
          <LinkForm authForm={true}>
            <div className="absolute -top-6 flex items-center justify-center border-b  bg-white/60 md:bg-white backdrop-blur-lg p-2 rounded-full">
              <IoLockClosed className="text-3xl text-link-green" />
            </div>

            <h1 className="font-opensans-semibold text-2xl text-stone-900 m-0">{"Register"}</h1>
            <h2 className="font-medium text-sm md:text-lg text-center text-stone-900">
              {"Create your Link Pharmacy account today"}
            </h2>
            <div className="w-full px-4 h-fit space-y-4 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-transparent scrollbar-thumb-stone-400 active:scrollbar-thumb-stone-400 dark:scrollbar-track-transparent dark:scrollbar-thumb-stone-200 dark:active:scrollbar-track-transparent dark:active:scrollbar-thumb-stone-300 transition-all duration-500">
              <Input
                name={"firstName"}
                label={"First Name"}
                placeholder={"Please enter your first name."}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstName}
                touched={touched.firstName}
              />

              <Input
                name={"lastName"}
                label={"Last Name"}
                placeholder={"Please enter your last name."}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastName}
                touched={touched.lastName}
              />

              <Input
                label={"Phone Number"}
                selectOptions={[{ value: "+260", label: "+260" }]}
                selectValue={values.phonePrefix}
                onSelectChange={(value) => {
                  setFieldValue("phonePrefix", value);
                  setFieldTouched("phonePrefix", true);
                }}
                placeholder={"Please enter your phone number."}
                name={"phone"}
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.phone}
                touched={touched.phone}
              />

              <Input
                name={"email"}
                label={"Email"}
                placeholder={"Please enter your email address."}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
              />

              <Input
                name={"password"}
                label={"Password"}
                type={passwordType}
                placeholder={"Please create a new password"}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                touched={touched.password}
                icon={
                  <label htmlFor="passwordType" className="text-lg text-app-blue-1 cursor-pointer">
                    {passwordType === "password" ? (
                      <HiEyeOff className="text-xl ml-2 text-link-green" />
                    ) : (
                      <HiEye className="text-xl ml-2 text-link-green" />
                    )}
                  </label>
                }
                onIconClick={handleCheck}
              />

              <Input
                name={"confirmPassword"}
                label={"Confirm Password"}
                type={passwordType}
                placeholder={"Please confirm your password"}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
                icon={
                  <label htmlFor="passwordType" className="text-lg text-app-blue-1 cursor-pointer">
                    {passwordType === "password" ? (
                      <HiEyeOff className="text-xl ml-2 text-link-green" />
                    ) : (
                      <HiEye className="text-xl ml-2 text-link-green" />
                    )}
                  </label>
                }
                onIconClick={handleCheck}
              />
            </div>
            <div className="w-full px-3 flex flex-1 justify-between items-center ">
              <button
                className="text-green-700 font-opensans text-sm"
                onClick={() => {
                  navigate("/", { replace: true });
                }}
              >
                Cancel
              </button>

              <Button type={"submit"} width={"[110px]"} disabled={registerMutation.isLoading}>
                {registerMutation.isLoading ? <Button.Loader label={"Loading..."} /> : "Register"}
              </Button>
            </div>
            <div className="font-opensans text-sm">
              Not New?&nbsp;
              <Link to="/login" className="text-green-700 font-opensans-semibold">
                Sign in.
              </Link>
            </div>
          </LinkForm>
        )}
      </Formik>
    </LayoutAuth>
  );
};

export default Register;
