import React from "react";
import { Helmet } from "react-helmet";
import { classNames } from "../../utils/utils";
import Footer from "./Footer";
import Header from "./Header";
import { meta as metaProps } from "../../globals";

interface Props {
  children: React.ReactNode;
  bgColor?: string;
  footer?: boolean;
  header?: boolean;
  meta?: {
    title?: string;
    description?: string;
    keywords?: string;
    url?: string;
    sitename?: string;
  };
  structredData?: any;
}

const Layout = ({ children, bgColor, footer, header, meta, structredData }: Props) => {
  return (
    <div className="w-full h-full flex flex-col justify-between ">
      <Helmet>
        <html lang="en" />
        <title>{meta?.title}</title>
        <meta name="description" content={meta?.description || metaProps.homeDescription} />
        <meta name="keywords" content={meta?.keywords || metaProps.homeKeywords} />
        <meta property="og:url" content={meta?.url} />
        <meta property="og:site_name" content={meta?.sitename} />

        {structredData && <script type="application/ld+json">{JSON.stringify(structredData)}</script>}
      </Helmet>
      <a
        href="#main-content"
        className="skip-link sr-only focus:not-sr-only focus:bg-blue-500 focus:text-white focus:absolute top-0 left-0 m-2 p-1 rounded-md"
      >
        Skip to main content
      </a>
      {header && <Header />}
      <main
        id="main-content" // id added to link from 'Skip to Content' link
        className={classNames(
          "font-opensans w-full h-full flex flex-1 flex-col text-stone-800 pb-7 main-container",
          bgColor ? bgColor : "bg-white dark:bg-stone-700"
        )}
        style={{
          minHeight: "calc(100vh - 7rem)",
        }}
        tabIndex={0}
        aria-label="Main content"
      >
        {children}
      </main>
      {footer && <Footer />}
    </div>
  );
};

export default Layout;
