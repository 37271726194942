import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DialogActions } from "@mui/material";
import { Button, Input } from "../../../../components/CustomComponents";

const ContactInfoDialog = ({ isOpen, onClose, values, handleChange, handleBlur, errors }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"xs"} fullWidth>
      <DialogTitle>
        <h3 className="m-0 font-opensans-bold">Edit Contact Information</h3>
      </DialogTitle>
      <DialogContent style={{ marginBottom: "5px" }}>
        <div className={`mb-3`}>
          <Input
            name={"email"}
            label={"Email Address"}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email}
            touched={true}
          />
        </div>
        <div>
          <Input
            name={"phoneNumber"}
            label={"Phone Number"}
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phoneNumber}
            touched={true}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant={"secondary"} onClick={onClose}>
          Cancel
        </Button>

        <Button
          onClick={() => {
            if (!errors.email && !errors.phoneNumber) {
              onClose();
            }
          }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactInfoDialog;
