/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { MdCancel, MdClose } from "react-icons/md";
import { useCart } from "../context/cartContext";
import { useNotifications } from "../context/NotificationsContext";
import useWindowSize from "../hooks/useWindowSize";
import styles from "../styles/components/Notifications.module.css";
import { HiChevronUp, HiOutlineTrash } from "react-icons/hi";
import { Button } from "./CustomComponents";
import { classNames } from "../utils/utils";

const Notifications = () => {
  const notificationsRoot = document.getElementById("notifications-root");
  const { notifications, clearNotification } = useNotifications();
  const [cartExpanded, setCartExpanded] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);
  const initialRender = useRef(true);

  const {
    cart: { cart, total, count },
    isDropdownVisible,
    removeFromCart,
    showDropDown,
    hideDropDown,
    timerId,
  } = useCart();

  const { pathname } = useLocation();

  const { width } = useWindowSize();

  useEffect(() => {
    setCartExpanded(false);
  }, [isDropdownVisible]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      hideDropDown();
    }
  }, [pathname]);

  // noinspection JSValidateTypes
  return ReactDOM.createPortal(
    <div className={styles.notificationsContainer}>
      <AnimatePresence initial={false}>
        {notifications.map((notification) => {
          if (notification.type === "Cart") return null;

          setTimeout(() => {
            clearNotification(notification.id);
          }, 5000);

          return (
            <motion.div
              positionTransition
              initial={{ opacity: 0, y: 50, scale: 0.3 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
              key={notification.id}
              className={`${styles.notification} shadow-sm`}
              style={{
                borderLeft: (() => {
                  switch (notification.type) {
                    case "Success":
                      return "5px solid #c4d600";
                    case "Error":
                      return "5px solid #ff0000";
                    case "Warning":
                      return "5px solid #ffc107";
                    default:
                      return "5px solid #c4d600";
                  }
                })(),
              }}
            >
              <span className={`${styles.notificationText} font-opensans leading-normal`}>{notification.message}</span>
              <span className={styles.cancelIcon} onClick={() => clearNotification(notification.id)}>
                <MdCancel className={"text-lg"} />
              </span>
            </motion.div>
          );
        })}

        {isDropdownVisible && width < 600 && (
          <motion.div
            positionTransition
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            style={{ width: "100%" }}
          >
            <AnimatePresence initial={false}>
              {cartExpanded && cart.length > 0 && (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  exit={{ height: 0 }}
                  transition={{ type: "tween", duration: 0.5 }}
                  className={styles.cartNotificationExpand}
                >
                  <div
                    className={styles.expandHeader}
                    onClick={() => {
                      setCartExpanded(false);
                      hideDropDown();
                    }}
                  >
                    <p className={"font-opensans"}>
                      <span className={"font-opensans-bold"}>My Cart,</span> {count} items
                    </p>
                    <MdClose />
                  </div>
                  <div className={styles.expandItemsContainer}>
                    <AnimatePresence initial={false}>
                      {cart.map((item) => {
                        return (
                          <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: "auto" }}
                            exit={{ height: 0 }}
                            className={styles.expandItem}
                            key={item.id}
                          >
                            {deletedItems.includes(item.id) && (
                              <motion.div
                                initial={{ width: 0 }}
                                animate={{ width: "auto" }}
                                exit={{ width: 0 }}
                                className={styles.deletedContainer}
                              >
                                <p>Item Deleted</p>
                              </motion.div>
                            )}
                            <div className={styles.expandImage}>
                              {item.thumbnail ? <img src={item.thumbnail} alt={item.name} /> : ""}
                            </div>
                            <div className={styles.expandItemDetails}>
                              <h6 className="font-opensans mb-1">{item.name}</h6>
                              <span>
                                <p>
                                  <strong>Qty:</strong> {item.quantity}
                                </p>
                              </span>
                              <span>
                                <p className="font-opensans text-base m-0">
                                  K
                                  <strong className="font-opensans-bold">
                                    {item.price.toLocaleString("en-GB", {
                                      minimumFractionDigits: 2,
                                    })}
                                  </strong>
                                </p>
                                <HiOutlineTrash
                                  className="text-xl cursor-pointer"
                                  onClick={async () => {
                                    setDeletedItems((prevState) => [...prevState, item.id]);
                                    await removeFromCart(item);
                                    setTimeout(
                                      () => setDeletedItems((prevState) => prevState.filter((p) => p !== item.id)),
                                      3000
                                    );
                                    if (cart.length === 1) hideDropDown();
                                  }}
                                />
                              </span>
                            </div>
                          </motion.div>
                        );
                      })}
                    </AnimatePresence>
                  </div>
                  <div className={styles.linkContainer}>
                    <Button width={"full"} variant={"secondary"}>
                      View Cart
                    </Button>
                    <Button width={"full"}>Checkout</Button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <div
              className={styles.cartNotification}
              onClick={() => {
                clearTimeout(timerId);
                showDropDown();
                setCartExpanded(true);
              }}
            >
              <div className={styles.cartNotificationDetails}>
                <span className="mb-1">
                  <p className="text-sm gray-500 font-opensans">Item added to cart</p>
                  <HiChevronUp className={classNames(cartExpanded ? "rotate-180" : "", "text-lg")} />
                </span>
                <span>
                  <p className="m-0 font-opensans-semibold">Subtotal</p>
                  <p className="m-0 font-opensan d-flex">
                    K
                    <div className="font-opensans-bold">
                      {total.toLocaleString("en-GB", {
                        minimumFractionDigits: 2,
                      })}
                    </div>
                  </p>
                </span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>,
    notificationsRoot
  );
};

export default Notifications;
