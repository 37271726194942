import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const PrescriptionHistory = () => {
  const { data: prescriptions, status: prescriptions_status } = useQuery("prescriptions", async () => {
    const res = await axios.get("/v1/website/prescriptions");
    return res.data;
  });

  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b px-4 py-3 hidden md:block">
        <h2 className="m-0 font-opensans-medium text-2xl">My Prescriptions</h2>
      </div>
      <div className="p-6">
        <div className="table-responsive">
          <Table className="table" mwidth="100%">
            <TableHead>
              <TableRow>
                <TableCell>RX No</TableCell>
                <TableCell>Prescription</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {prescriptions_status === "success" && (
                <>
                  {prescriptions.data.map((prescription) => (
                    <TableRow
                      key={prescription.id}
                      component={Link}
                      to={`/prescription/${prescription.id}`}
                      style={{ cursor: "pointer" }}
                      hover={true}
                    >
                      <TableCell>{prescription.attributes.number}</TableCell>
                      <TableCell>
                        {prescription.attributes.prescription?.indexOf(".pdf") === -1 ? (
                          <div style={{ width: "30px", height: "30px" }}>
                            <img
                              src={prescription.attributes.prescription}
                              alt={prescription.attributes.number}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        ) : (
                          <span>Pdf</span>
                        )}
                      </TableCell>

                      <TableCell>{new Date(prescription.attributes.uploaded_on).toLocaleDateString("en-GB")}</TableCell>
                      <TableCell>{prescription.attributes.status}</TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionHistory;
