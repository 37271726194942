import { useEffect, useRef, useState, createContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useNotifications } from "./NotificationsContext";
import * as Sentry from "@sentry/react";

export const AuthContext = createContext();

export const AuthValues = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const initialRender = useRef(true);
  const { pushNotification } = useNotifications();
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState({});
  const [redirect, setRedirect] = useState("/account");
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("linkpharmacy-access-token") ? localStorage.getItem("linkpharmacy-access-token") : ""
  );

  /**
   * Login user after successful authentication.
   * */
  const login = (response) => {
    if (response.data.error !== "blocked") {
      const roles = response.data.attributes.roles.map((role) => {
        return role.name;
      });

      if (roles.includes("Customer")) {
        // Add localStorage cart items to backend cart
        const localCart = JSON.parse(localStorage.getItem("linkpharmacy-cart"));

        if (localCart) {
          // eslint-disable-next-line array-callback-return
          localCart.map((item) => {
            axios
              .post(
                "/v1/website/cart/add-item",
                {
                  stock_item_id: item.id,
                  quantity: item.quantity,
                  amount: item.price * item.quantity,
                },
                {
                  headers: {
                    Authorization: "Bearer " + response.data.attributes.access_token,
                  },
                }
              )
              .then(() => {
                localStorage.removeItem("linkpharmacy-cart");
              });
          });
        }

        // Persist user data in localStorage
        localStorage.setItem("linkpharmacy-access-token", response.data.attributes.access_token);
        localStorage.setItem(
          "linkpharmacy-user",
          JSON.stringify({
            id: response.data.id,
            linkId: response.data.attributes.link_id,
            firstname: response.data.attributes.first_name,
            lastname: response.data.attributes.last_name,
            email: response.data.attributes.email,
            phoneNumber: response.data.attributes.phone_number,
            verified: response.data.attributes.verified,
          })
        );

        // Set signed in state to true
        setUser(JSON.parse(localStorage.getItem("linkpharmacy-user")));
        setSignedIn(true);
        Sentry.setUser({
          id: response.data.attributes.link_id,
          email: response.data.attributes.email,
          username: response.data.attributes.first_name + " " + response.data.attributes.last_name,
        });
      } else {
        pushNotification({
          id: Math.random(),
          type: "Error",
          message: "You do not have permission to access this page.",
        });
      }
    }
  };

  axios.defaults.headers.common.Authorization = "Bearer " + accessToken;

  useEffect(() => {
    setAccessToken(localStorage.getItem("linkpharmacy-access-token"));

    if (
      localStorage.getItem("linkpharmacy-user") !== "undefined" &&
      localStorage.getItem("linkpharmacy-user") &&
      localStorage.getItem("linkpharmacy-access-token")
    ) {
      setSignedIn(true);
      setUser(JSON.parse(localStorage.getItem("linkpharmacy-user")));
    } else {
      setSignedIn(false);
    }

    axios.defaults.headers.common.Authorization = "Bearer " + accessToken;

    if (accessToken) {
      setIsLoading(true);
      axios
        .get("/user", {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          // Construct user object
          const user = {
            id: response.data.data.id,
            linkId: response.data.data.attributes.link_id,
            firstname: response.data.data.attributes.first_name,
            lastname: response.data.data.attributes.last_name,
            email: response.data.data.attributes.email,
            phoneNumber: response.data.data.attributes.phone_number,
            verified: response.data.data.attributes.verified,
          };

          const roles = response.data.data.attributes.roles.map((role) => {
            return role.name;
          });

          if (roles.includes("Customer")) {
            // Persist user data in state
            setUser(user);
            setSignedIn(true);
            setIsLoading(false);

            // Persist User data in localStorage
            localStorage.setItem("linkpharmacy-user", JSON.stringify(user));
          }
        })
        .catch((error) => {
          if (error.response.data) {
            setSignedIn(false);
            setIsLoading(false);
            localStorage.removeItem("linkpharmacy-user");
            localStorage.removeItem("linkpharmacy-access-token");
          }
        });
    }
  }, [accessToken, signedIn]);

  // Effect to refetch the cart when signed in state changes
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      queryClient.invalidateQueries("cartQuery").then(() => null);
    }
  }, [queryClient, signedIn]);

  return (
    <AuthContext.Provider
      value={{
        signedIn: signedIn,
        setSignedIn,
        isLoading,
        user,
        setUser,
        redirect,
        setRedirect,
        login,
        logout: () => {
          axios.get("/auth/logout").then(async (data) => {
            localStorage.removeItem("linkpharmacy-cart");
            localStorage.removeItem("linkpharmacy-user");
            localStorage.removeItem("linkpharmacy-access-token");
            setSignedIn((prevState) => false);
            setUser({});
            await queryClient.resetQueries("cartQuery");
            navigate("/", { replace: true });
          });
        },
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
