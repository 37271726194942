import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo_1.png";
import SearchBar from "../SearchBar";
import ShoppingCart from "../ShoppingCart";
import HeaderRibbon from "./HeaderRibbon";
import { classNames } from "../../utils/utils";
import useScrollUp from "../../hooks/useScrollUp";

const Header = () => {
  const visible = useScrollUp();

  return (
    <header
      className={classNames(
        "font-opensans fixed flex flex-col w-full z-1001 inset-x-0 transition-all duration-300 ease-out",
        visible ? "top-0 left-0 right-0" : "-top-14 md:-top-12"
      )}
      role="banner" // ARIA attribute to identify this as a landmark for screen readers
    >
      <HeaderRibbon />
      <nav
        className={classNames(
          "w-full py-2 md:py-0 md:h-[60px] bg-link-green flex shadow-sm transition-all duration-300 ease-out",
          visible ? "md:px-10 lg:px-24 xl:px-40 2xl:px-80" : "md:px-2 lg:px-8 xl:px-14 2xl:px-40"
        )}
        role="navigation" // ARIA attribute to identify this as a landmark for screen readers
      >
        <ul className="hidden md:flex w-full items-center">
          <li className="w-52 lg:w-[300px] justify-self-start">
            <Link to="/" className="w-full">
              <img src={Logo} alt="Link Pharmacy logo" className="w-full object-contain" />
            </Link>
          </li>

          <li className="hidden md:flex w-full">
            <SearchBar />
          </li>

          <li className="block py-[0.5rem] px-[1rem]">
            <ShoppingCart visible={visible} />
          </li>
        </ul>

        <ul className="flex md:hidden w-[100%] flex-col px-2">
          <li className="flex items-center justify-between mb-2 space-x-10">
            <div className="w-[50%] h-12">
              <Link to="/">
                <img src={Logo} alt="Link Pharmacy logo" className="w-full h-full object-contain" />
              </Link>
            </div>

            <ShoppingCart visible={visible} />
          </li>

          <li className="flex w-full">
            <SearchBar />
          </li>
        </ul>
      </nav>
    </header>
  );
};
export default Header;
