import { AxiosResponse } from "axios";

interface SubCategoryProductType {
  product_type_id: number;
  id: number;
  name: string;
  type?: "divider";
  isBold?: boolean;
}

interface ProductSubcategory {
  subcategory_id: number;
  id: number;
  name: string;
  product_types: Array<SubCategoryProductType>;
  type?: "divider";
  isBold?: boolean;
}

interface ProductCategory {
  category_id: number;
  id: number;
  name: string;
  subcategories: Array<ProductSubcategory>;
}

const useLeftMenu = () => {
  /**
   * Utility function to determine if a product/category should be added.
   *
   * @param name Name of the product or category.
   * @returns Boolean indicating whether it should be added.
   */
  const shouldAdd = (name: string): boolean => {
    const lowerName = name.toLowerCase();
    return (
      lowerName.includes("shop by") ||
      lowerName.includes(`shop ${lowerName.replace("shop ", "")}`) ||
      lowerName.includes("sale")
    );
  };

  /**
   * Place "Shop by" & "Sale on" options into the appropriate location in the list.
   *
   * @param {AxiosResponse} res - response object from axios.
   * @returns {ProductCategory[]}
   * */
  const sortCategories = (res: AxiosResponse): ProductCategory[] => {
    const categories = res.data;

    return categories?.map((category: ProductCategory) => {
      category.subcategories = category.subcategories.map((subCategory: ProductSubcategory) => {
        const productTypes = subCategory.product_types;
        const regularProductTypes = productTypes.filter((type) => !shouldAdd(type.name));
        const specialProductTypes = productTypes.filter((type) => shouldAdd(type.name));

        if (specialProductTypes.length > 0) {
          regularProductTypes.push(
            {
              id: 0,
              name: "",
              product_type_id: 0,
              type: "divider",
            },
            ...specialProductTypes.map((type) => ({ ...type, isBold: true }))
          );
        }

        return {
          ...subCategory,
          product_types: regularProductTypes,
        };
      });

      const regularSubcategories = category.subcategories.filter((subCategory) => !shouldAdd(subCategory.name));
      const specialSubcategories = category.subcategories.filter((subCategory) => shouldAdd(subCategory.name));

      if (specialSubcategories.length > 0) {
        regularSubcategories.push(
          {
            id: 0,
            name: "",
            product_types: [],
            subcategory_id: 0,
            type: "divider",
          },
          ...specialSubcategories.map((subCategory) => ({ ...subCategory, isBold: true }))
        );
      }

      return {
        ...category,
        subcategories: regularSubcategories,
      };
    });
  };

  return { sortCategories };
};

export default useLeftMenu;
