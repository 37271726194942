import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/HelpCenter.module.css";
import HelpCenterSidebar from "../../components/HelpCenterSidebar";

const HelpReturns = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="shipping_help_main">
        <section>
          <div className={style.shipping_help_bg}>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className={style.shipping_help_header}>
                  <span>Help Center</span>
                  <h1>Returns & Exchange Policy</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <HelpCenterSidebar />
            </div>
            <div className="col-md-6">
              <div className={style.shipping_help}>
                <p className="font-opensans mb-3">Dear customers, this policy should be read as a whole.</p>

                <p className="font-opensans mb-3">
                  We strongly advise all our clients to thoroughly check their products before making their purchase and
                  before leaving the store to ensure that they have the right products.{" "}
                </p>

                <p className="font-opensans mb-3">
                  This return and exchange policy is to allow us to continually provide our customers with quality
                  service. This policy will help guide the company’s employees and customers on the management of
                  returned and exchanged products. This policy covers all items sold by Link pharmacy, in store and
                  online.
                </p>

                <p className="font-opensans-bold mb-3">TERMS AND CONDITIONS</p>

                <p className="font-opensans mb-3">The service is offered on the following conditions:</p>

                <ol className="list-decimal list-inside space-y-3 ml-6 md:max-w-[60%] mb-3 text-base">
                  <li>
                    All items subject to this policy must be returned in its original packaging, unless reasonably
                    justifiable, however, the items must be in a merchantable condition.
                  </li>

                  <li>
                    Original receipt must be produced as proof of purchase from the store of all items being returned.
                  </li>

                  <li>
                    If you change your mind on your purchase, you may submit a return and exchange request within 7 days
                    of purchase. Items need to be in an unused condition.
                  </li>

                  <li>
                    Customers are responsible for the cost of returning, refunding and exchanging products with us.
                    Returns, refunds and exchanges can also be processed in store.
                  </li>

                  <li>
                    Items that are defective, not fit for the purpose that they are intended for, or for the purpose
                    specified by the buyer, will be refunded, replaced or exchanged in line with the warranty
                    guidelines, where applicable or within 7 days of return.
                  </li>

                  <li>
                    Only regular priced items may be refunded, unfortunately sale items cannot be refunded. Items that
                    are unavailable for exchange will be automatically refunded.
                  </li>
                </ol>

                <p className="font-opensans-bold mb-3">Refunds & Exchanges</p>

                <p className="font-opensans mb-3">
                  Pursuant to condition number 3, once your return is received and inspected at our headquarters, it
                  will be determined if your refund has been approved or rejected, based on the Return and Exchange
                  policy. Returned items will undergo a strict process of assessment of its condition before a refund or
                  exchange will take place. If your refund is approved, it will be processed, and a credit will
                  automatically be applied to your credit card or original method of payment, within 5-10 business days.
                  Accepted returns will be processed and paid via the original payment platform or to the card
                  originally used for payment.
                </p>

                <p className="font-opensans mb-3">
                  Note that, if you encounter delay in receiving your refund within the above provided period, it may be
                  an issue from your card issuer or bank, over which we have no control.
                </p>

                <p className="font-opensans-bold mb-3">Claim of fake goods</p>

                <p className="font-opensans mb-3">
                  Items that are claimed to be fake will be sent to suppliers, where deemed necessary, to determine if
                  the supplier accepts that the product is theirs. Claims will be processed within a period of 30 days.
                </p>

                <p className="font-opensans-bold mb-3">Refund Exclusions & Exchanges</p>

                <p className="font-opensans mb-3">
                  Unfortunately, we do not under any condition accept refund and exchange requests on medication &
                  prescription items under condition 3 because there is no mechanism to confirm the items have been
                  handled or stored according to specified instructions and have not been tampered with.
                </p>

                <p className="font-opensans mb-3">
                  Several types of goods are also exempt from being returned under condition 3. Perishable goods such as
                  food, newspapers or magazines cannot be returned. We also do not accept sanitary or intimate products,
                  hazardous materials, or flammable liquids. Additional non-returnable items include gift cards along
                  with certain health and personal care items.
                </p>

                <p className="font-opensans-bold mb-3">Special medication orders </p>

                <p className="font-opensans mb-3">
                  If a customer would like to return a special order you are required to contact the procurement team in
                  the first instance to inform them of the request and be advised on how to proceed
                </p>

                <p className="font-opensans mb-3">
                  Requests for special orders made by customers will be deemed valid after 50% of the total cost has
                  been paid. Customers agree to bear any cost that may arise from the price change and quantity change
                  in accordance with regulation of Zambia. We will not be responsible for refunds of the 50% payment
                  made prior to your order in a situation where the customer cancels the order.
                </p>

                <p className="font-opensans-bold mb-3">Product Recalls</p>

                <p className="font-opensans mb-3">
                  A recall is defined as an action executed by a manufacturer at any time to remove a defective or
                  harmful product from the market when it is discovered to be in violation of laws and regulations
                  administered by our regulatory authorities
                </p>

                <p className="font-opensans mb-3">
                  Medication may be recalled for an assortment of reasons including safety, mislabeling, contamination,
                  and deviations in strength or potency. A product recall is the most effective means of protecting the
                  public from a defective or potentially harmful product.
                </p>

                <p className="font-opensans mb-3">
                  Product recalls happen routinely, and we aim to handle them properly and promptly, including alerting
                  the public and safely removing the affected product from the market as per legal requirement and
                  Zambian law.
                </p>

                <p className="font-opensans-bold mb-3">Cancellation of Orders</p>

                <p className="font-opensans mb-3">
                  If you would like to cancel an order placed online, please contact our customer care line on 0211 255
                  556 or WhatsApp line on 0976 255 556 to be advised on how to proceed with a refund request.
                </p>

                <p className="font-opensans-semibold mb-3">Approved by CCPC February 2023</p>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default HelpReturns;
