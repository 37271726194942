import { useContext } from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

// Components
import Layout from "../Layout/Layout";
import { meta } from "../../globals";

import { AuthContext } from "../../context/AuthContext";

import styles from "../../styles/views/PersonalInfo.module.css";

const PersonalInfo = () => {
  const { user } = useContext(AuthContext);

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div
        className={`container-lg container-md container-sm container p-4 ${styles.personalinfo}`}
      >
        <div className={"row-12"}>
          <p style={{ fontSize: "14px" }}>
            <Link to={"/home"}>Home</Link>
            <ChevronRight style={{ color: "gray", transform: "scale(1.1)" }} />
            <Link to={"/account"}>Your Account</Link>
          </p>

          <h3 style={{ marginTop: "-10px" }} className={styles.header}>
            Personal Information
          </h3>
        </div>

        <div className={"mt-2"}>
          <p>
            Update your password{" "}
            <Link
              to={"/security"}
              className={"font-weight-bold"}
              style={{ color: "#00259E" }}
            >
              <u>here</u>
            </Link>
            .
          </p>
        </div>

        <div className={"mt-4"}>
          <div>
            <h5 className={`font-weight-bold ${styles.label}`}>First Name</h5>
            <p>{user && `${user.first_name}`}</p>
          </div>
        </div>

        <div className={"mt-4"}>
          <div>
            <h5 className={`font-weight-bold ${styles.label}`}>Last Name</h5>
            <p>{user && `${user.last_name}`}</p>
          </div>
        </div>

        <div className={"mt-4"}>
          <div>
            <h5 className={`font-weight-bold ${styles.label}`}>Email</h5>
            <p>{user && `${user.email}`}</p>
          </div>
        </div>

        <div className={"mt-4"}>
          <div>
            <h5 className={`font-weight-bold ${styles.label}`}>Phone Number</h5>
            <p>{user && `${user.phone_number}`}</p>
          </div>
        </div>

        <div className={"mt-4"}>
          <Link
            to={"/personal-information/edit"}
            className={`btn btn-primary ${styles.button}`}
          >
            Edit
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default PersonalInfo;
