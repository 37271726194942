import React from "react";
import { Link } from "react-router-dom";
import { Grid, Container, Paper } from "@mui/material";
import { WhatsApp, Facebook } from "@mui/icons-material";
import styles from "../../styles/views/shop/Thankyou.module.css";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import Lottie from "react-lottie";
import ThankYouAnimation from "../../assets/animations/thank-you.json";

const ThankYou = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: ThankYouAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <Container sx={{ flexGrow: 1 }} maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sx={{ justifyContent: "space-evenly" }}>
            <Paper
              sx={{
                padding: 5,
                textAlign: "center",
                margin: 3,
              }}
            >
              <div>
                {/*<ShoppingBasketRounded style={{ fontSize: 200, color: "#c4d600" }} />*/}
                <Lottie options={defaultOptions} height={400} />
              </div>
              <div className="container">
                {/*<h1 className="font-opensans-medium" style={{ fontSize: "80px" }}>*/}
                {/*  Thank You*/}
                {/*</h1>*/}
                <p className={`mb-4 ${styles.title} font-opensans`}>
                  For shopping at Link pharmacy.
                  <br />
                  We hope to see you soon.
                </p>

                <div className="mb-4">
                  <p style={{ fontSize: 20 }}>
                    Checkout more products{" "}
                    <Link color="primary" to="/store/products" className="text-[green] font-opensans-medium">
                      here
                    </Link>{" "}
                  </p>
                </div>
                <div>
                  <h2 className="font-opensans text-lg">We are social. find us on...</h2>
                  <span className={styles.icons}>
                    <a href="https://www.facebook.com/linkpharmacyzm/" target="_blank" rel="noreferrer">
                      <Facebook fontSize="medium" />
                    </a>
                    <a href="https://wa.me/260976255556" target="_blank" rel="noreferrer">
                      <WhatsApp fontSize="medium" />
                    </a>
                  </span>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default ThankYou;
