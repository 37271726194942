import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useCheckout = () => {
  const updateContact = (user, email, phoneNumber) => {
    const id = user.id;
    axios.post(`/v1/website/update-contact/${id}`, {
      email: email,
      phone_number: phoneNumber,
      _method: "PUT",
    });
  };

  const navigate = useNavigate();

  /**
   * Submits an order and, based on the response, either redirects to an online payment gateway or
   * a thank-you page.
   *
   * @param {Object} user - The user object that contains user information. It must contain a property 'link_id'.
   * @param {string} email - The email of the user placing the order.
   * @param {string} phoneNumber - The phone number of the user placing the order.
   * @param {string} firstName - The first name of the user placing the order.
   * @param {string} lastName - The last name of the user placing the order.
   * @param {number} total - The total cost of the items in the cart.
   * @param {Object|string} address - If 'isAddress' is true, 'address' should be an object representing the user's address. If 'isAddress' is false, 'address' should be a string representing the branch_id.
   * @param {string} deliveryMethod - The method of delivery for the order.
   * @param {string} payment_method - The method of payment for the order.
   * @param {Array} cart - An array of items in the cart.
   * @param {boolean} isAddress - A boolean value indicating whether the address should be sent (true) or the branch_id (false).
   *
   * @return {Promise} - Returns a promise that resolves with the server response to the order request.
   *
   * @example
   * continueToPayment(user, email, phoneNumber, firstName, lastName, total, address, deliveryMethod, payment_method, cart, isAddress, prescription);
   */
  const continueToPayment = (
    user,
    email,
    phoneNumber,
    firstName,
    lastName,
    total,
    address,
    deliveryMethod,
    payment_method,
    cart,
    isAddress
  ) => {
    const formData = new FormData();

    formData.append("link_id", user.link_id);
    formData.append("email", email);
    formData.append("phone_number", phoneNumber);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("amount", total);
    formData.append("address", isAddress ? JSON.stringify(address) : null);
    formData.append("branch_id", !isAddress ? address : null);
    formData.append("delivery_method", deliveryMethod);
    formData.append("payment_method", payment_method);
    formData.append("items", JSON.stringify(cart));

    return axios.post("/v1/website/orders", formData).then((response) => {
      if (response.data.message !== "cod redirect") {
        window.location.href = `https://online.tingg.africa/v2/express/?params=${response.data.params}&accessKey=Fjt8ZMtkYYvZc5dv89vuqyXDb8VqqDyCd37MQGyWxeSVzdcBqdux8Mjn28Nx5&countryCode=ZM`;
      } else {
        navigate("/thank-you", { replace: true });
      }
    });
  };

  return { updateContact, continueToPayment };
};
