import { useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Spinner from "react-spinkit";
import { Stepper, StepLabel, Step, Box } from "@mui/material";
import { ChevronRight, KeyboardReturn, Add, Remove } from "@mui/icons-material";

// Components
import { getProductOrderApi } from "../../api/orders";
import styles from "../../styles/views/PurchaseHistoryOrderDetail.module.css";

import TransitionUpDialog from "../../components/TransitionUpDialog";

const PurchaseOrderDetail = ({ location }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: order, isLoading } = useQuery(
    ["orders", { id }],
    getProductOrderApi,
    {
      onError: (error) => {
        navigate("/errror-404" + location.pathname, { replace: true });
      },
    }
  );

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const [returned, setReturned] = useState(-1);

  const [dialogVisible, setDialogVisible] = useState(false);

  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [returning, setReturning] = useState(false);

  const reasons = {
    1: "Incorrect Product Ordered",
    2: "Incorrect Size Ordered",
    3: "Product Does Not Match Description On The Website",
    4: "Product Does Not Meet Expectations",
    5: "Incorrect Item Received",
    6: "Product Damaged On Arrival",
    7: "Other Specify",
  };

  const [reason, setReason] = useState(1);
  const [comment, setComment] = useState("");

  const onReturn = () => {
    setModified(false);
    setLoading(true);
    setReturning(true);

    const data = {
      reason: reasons[reason],
      comment: comment,
      quantity: quantity,
      order_detail_id: selectedOrder.id,
    };

    axios
      .post("v1/website/item-returns", data)
      .then((data) => {
        setReturned(1);
        setReturning(false);

        order.data.attributes.details = order.data.attributes.details.map(
          (detail) => {
            if (detail.id === selectedOrder.id) {
              detail.attributes.returned = true;
              detail.attributes.return_status = "requested";
            }
            return detail;
          }
        );

        setModified(true);
        setTimeout(() => setReturned(false), 5000);
        setSelectedOrder(null);
      })
      .catch((err) => {
        setReturned(0);
      });

    setQuantity(1);
    setReason(1);
    setComment("");
    setLoading(false);
    setDialogVisible(false);
  };

  const onCreateReturn = (detail) => {
    setQuantity(1);
    setSelectedOrder(detail);
    setDialogVisible(true);
  };

  const countTotalPrice = (details) => {
    if (details.length === 0) return 0;
    if (details.length === 1)
      return details[0].attributes.quantity * details[0].attributes.price;
    return details.reduce(
      (total, item) => item.attributes.quantity * item.attributes.price + total,
      0
    );
  };

  const onIncrement = () => {
    if (quantity < selectedOrder.attributes.quantity)
      setQuantity((prevState) => ++prevState);
  };

  const onDecrement = () => {
    if (quantity > 1) setQuantity((prevState) => --prevState);
  };

  const steps = ["Order Received", "Dispatched", "Delivered"];

  const [testOrder, setTestOrder] = useState(1);

  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b p-2">
        <h2 className="m-0 text-md text-stone-600">
          {"Purchase History Order Details"}
        </h2>
      </div>

      <div className="p-6 overflow-y-hidden overflow-x-auto whitespace-nowrap pb-4">
        {order && order.data && (
          <>
            <div className={"row-12 mb-4"}>
              <p style={{ fontSize: "14px" }}>
                <Link to={"/"}>Home</Link>
                <ChevronRight
                  style={{ color: "gray", transform: "scale(1.1)" }}
                />
                <Link to={"/account"}>Account</Link>
                <ChevronRight
                  style={{ color: "gray", transform: "scale(1.1)" }}
                />
                <Link to={"/account?t=my-orders"}>My Orders</Link>
              </p>
              <h3 style={{ marginTop: "-10px" }}>Order Details</h3>
            </div>

            {function () {
              if (
                order.data.attributes.status === order.data.attributes.Received
              ) {
                return <div>{setTestOrder(2)}</div>;
              }
              if (
                order.data.attributes.delivery_method ===
                order.data.attributes.deliver
              ) {
                return <div>{setTestOrder(3)}</div>;
              }
            }}

            <div style={{ paddingBottom: 30 }}>
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={testOrder} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>

            <div>
              <div className={"card-header"}>
                <p className={`font-weight-bold ${styles.id}`}>
                  Order # : {order.data.attributes.order_number}
                </p>
              </div>
              <div className={"card-body"}>
                <div className={"row mt-3 mb-3"}>
                  <div className={"col-md-5 col-lg-4 mt-md-0 mt-4"}>
                    <h5
                      className={"font-weight-bold text-dark mb-3"}
                      style={{ fontWeight: "600" }}
                    >
                      Customer Information
                    </h5>

                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        Name:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {order.data.attributes.user.first_name +
                          " " +
                          order.data.attributes.user.last_name}
                      </p>
                    </div>

                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        Mobile #:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {order.data.attributes.contact
                          ? order.data.attributes.contact.phone_number
                          : order.data.attributes.user.phone_number}
                      </p>
                    </div>

                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        Email:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {order.data.attributes.contact
                          ? order.data.attributes.contact.email
                          : order.data.attributes.user.email}
                      </p>
                    </div>
                  </div>
                  <div className={"col-md-3 col-lg-4"}>
                    <h5
                      className={"font-weight-bold text-dark mb-3"}
                      style={{ fontWeight: "600" }}
                    >
                      Order Management
                    </h5>
                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        Date:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {new Date(
                          order.data.attributes.date
                        ).toLocaleDateString()}
                      </p>
                    </div>

                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        Delivery Method:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {order.data.attributes.delivery_method}
                      </p>
                    </div>

                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        Payment Method:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {order.data.attributes.payment_method}
                      </p>
                    </div>

                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        Status:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {order.data.attributes.status}
                      </p>
                    </div>
                  </div>

                  <div className={"col-md-4 col-lg-4"}>
                    <h5
                      className={"font-weight-bold text-dark mb-3"}
                      style={{ fontWeight: "600" }}
                    >
                      Delivery Address
                    </h5>
                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        Address Line 1:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {order.data.address.attributes.address1}
                      </p>
                    </div>

                    {order.data.address.attributes.address2 && (
                      <div>
                        <h6
                          className={"font-weight-bold text-dark"}
                          style={{ fontWeight: "600" }}
                        >
                          Address Line 2:
                        </h6>
                        <p style={{ marginTop: "-8px" }}>
                          {order.data.address.attributes.address2}
                        </p>
                      </div>
                    )}

                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        City:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {order.data.address.attributes.city}
                      </p>
                    </div>
                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        Residential Area:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {order.data.address.attributes.residential_area}
                      </p>
                    </div>
                    <div>
                      <h6
                        className={"font-weight-bold text-dark"}
                        style={{ fontWeight: "600" }}
                      >
                        Postal Code:
                      </h6>
                      <p style={{ marginTop: "-8px" }}>
                        {order.data.address.attributes.postal_code}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {returned === 1 && (
              <div className="alert alert-success text-center rounded">
                Return request sent successfully. Thanks for contacting us, we
                will get back to you shortly.
              </div>
            )}

            {returned === 0 && (
              <div className="alert alert-danger text-center rounded">
                An error occurred while sending your return request. Try again!
              </div>
            )}

            <div className={` ${styles.table}`}>
              <table className={`table`}>
                <thead>
                  <th>Item Image</th>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Total Price</th>
                  <th>Return</th>
                </thead>
                <tbody>
                  {(modified || order.data.attributes.details) &&
                    order.data.attributes.details.map((detail) => (
                      <tr key={detail.id}>
                        <td>
                          <img
                            width="50px"
                            height="50px"
                            src={detail.attributes.item.thumbnail}
                            alt={detail.attributes.item.name}
                          />
                        </td>
                        <td>{detail.attributes.item.name}</td>
                        <td>{detail.attributes.quantity}</td>
                        <td>{detail.attributes.price}</td>
                        <td>
                          {detail.attributes.quantity * detail.attributes.price}
                        </td>
                        <td>
                          <span title="return item">
                            {returning && detail.id === selectedOrder.id && (
                              <div
                                style={{
                                  fontSize: "small",
                                  width: "max-content",
                                  backgroundColor: "rgba(0, 255, 0, 0.3)",
                                }}
                              >
                                requesting...
                              </div>
                            )}

                            {detail.attributes.returned &&
                              !(
                                returning && detail.id === selectedOrder.id
                              ) && (
                                <div
                                  style={{
                                    fontSize: "small",
                                    width: "max-content",
                                    backgroundColor: "rgba(255, 0, 0, 0.3)",
                                  }}
                                >
                                  {detail.attributes.return_status.toLowerCase()}
                                </div>
                              )}

                            {!detail.attributes.returned &&
                              !(
                                returning && detail.id === selectedOrder.id
                              ) && (
                                <KeyboardReturn
                                  onClick={() => onCreateReturn(detail)}
                                  sx={{
                                    cursor: "pointer",
                                    padding: "3px",
                                    "&:hover": {
                                      borderRadius: "100px",
                                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                                      color: "rgba(0, 0, 0, 0.5)",
                                    },
                                  }}
                                />
                              )}
                          </span>
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Total: </th>
                    <th>
                      K{" "}
                      {countTotalPrice(order.data.attributes.details).toFixed(
                        2
                      )}
                    </th>
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}

        {isLoading && (
          <div style={{ width: "100%", padding: "10px 0" }}>
            <Spinner name="ball-pulse-sync" color="#c4d600" fadeIn={"0"} />
          </div>
        )}

        <TransitionUpDialog
          visible={dialogVisible}
          title="Return Item"
          onClose={() => setDialogVisible(false)}
          closeText="Cancel"
          loading={loading}
          saveText="Submit"
          onSave={onReturn}
        >
          <div
            style={{
              textAlign: "center",
              marginBottom: "30px",
              fontFamily: "opensans",
            }}
          >
            {selectedOrder && selectedOrder.attributes.item && (
              <>
                <img
                  height="240px"
                  style={{ objectFit: "fill" }}
                  src={selectedOrder.attributes.item.thumbnail}
                  alt={selectedOrder.attributes.item.name}
                />
                <br />
                <div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #c4d600",
                    margin: "10px 0px",
                    borderRadius: "15px",
                    padding: "5px",
                    "& div": {
                      "& span": {
                        marginBottom: "-2px",
                      },
                      display: "flex",
                      padding: "0 20px",
                    },
                  }}
                >
                  <div>
                    <span
                      onClick={onDecrement}
                      style={{
                        color: `${
                          quantity === 1 ? "rgba(0, 0, 0, 0.3)" : "#c4d600"
                        }`,
                      }}
                    >
                      <Remove
                        sx={
                          quantity === 1
                            ? {
                                cursor: "not-allowed",
                                padding: "3px",
                                margin: "0",
                                "&:hover": {
                                  borderRadius: "100px",
                                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                                  color: "rgba(0, 0, 0, 0.5)",
                                },
                              }
                            : {
                                cursor: "pointer",
                                padding: "3px",
                                margin: "0",
                                "&:hover": {
                                  borderRadius: "100px",
                                  backgroundColor: "#c4d600",
                                  color: "rgba(0, 0, 0, 0.5)",
                                },
                              }
                        }
                      />
                    </span>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        padding: "0 5px",
                        marginTop: "-3px",
                      }}
                    >
                      {quantity}
                    </span>
                    <span
                      onClick={onIncrement}
                      style={{
                        color: `${
                          selectedOrder.attributes.quantity === quantity
                            ? "rgba(0, 0, 0, 0.3)"
                            : "#c4d600"
                        }`,
                      }}
                    >
                      <Add
                        sx={
                          selectedOrder.attributes.quantity === quantity
                            ? {
                                cursor: "not-allowed",
                                padding: "3px",
                                margin: "0",
                                "&:hover": {
                                  borderRadius: "100px",
                                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                                  color: "rgba(0, 0, 0, 0.5)",
                                },
                              }
                            : {
                                cursor: "pointer",
                                padding: "3px",
                                margin: "0",
                                "&:hover": {
                                  borderRadius: "100px",
                                  backgroundColor: "#c4d600",
                                  color: "rgba(0, 0, 0, 0.5)",
                                },
                              }
                        }
                      />
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>

          <div style={{ marginBottom: "18px" }}>
            Select Reason:
            <select
              value={reason}
              onChange={(e) => setReason(+e.target.value)}
              className={`form-control`}
              sx={{ width: "25em" }}
            >
              {Object.entries(reasons).map((pair) => (
                <option value={pair[0]} key={pair[0]}>
                  {pair[1]}
                </option>
              ))}
            </select>
          </div>

          <div>
            Additional Info:
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className={`form-control`}
              sx={{ width: "25em" }}
              style={{
                resize: "none",
              }}
              rows={7}
              placeholder="Tell us more about the problem..."
            ></textarea>
          </div>
        </TransitionUpDialog>
      </div>
    </div>
  );
};

export default PurchaseOrderDetail;
