import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/HelpCenter.module.css";
import HelpCenterSidebar from "../../components/HelpCenterSidebar";
import React from "react";

const ProductRecalls = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="shipping_help_main">
        <section>
          <div className={style.shipping_help_bg}>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className={style.shipping_help_header}>
                  <span>Help Center</span>
                  <h1>Product Recalls</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <HelpCenterSidebar />
            </div>
            <div className="col-md-6">
              <div className={style.shipping_help}>
                <p className="font-opensans-bold mb-3">
                  What is a product recall?
                </p>
                <p className="font-opensans text-sm mb-3">
                  A recall is defined as an action executed by a manufacturer at
                  any time to remove a defective or harmful product from the
                  market when it is discovered to be in violation of laws and
                  regulations administered by our regulatory authorities.{" "}
                </p>
                <p className="font-opensans text-sm mb-3">
                  Recalls may be conducted as a voluntary action by the
                  manufacturer or supplier, by request from regulatory
                  authorities, or by a legal mandate.
                </p>
                <p className="font-opensans-bold mb-3">
                  Why are product recalls important?{" "}
                </p>
                <p className="font-opensans text-sm mb-3">
                  Medication may be recalled for an assortment of reasons
                  including safety, mislabeling, contamination, and deviations
                  in strength or potency.
                </p>
                <p className="font-opensans text-sm mb-3">
                  A product recall is the most effective means of protecting the
                  public from a defective or potentially harmful product.
                </p>
                <p className="font-opensans-bold mb-3">
                  How are we working with regulatory authorities to facilitate
                  product recalls?
                </p>
                <p className="font-opensans text-sm mb-3">
                  Product recalls happen routinely, and we would like to play
                  our role in implementing measures to ensure that these recalls
                  are handled properly and promptly, including alerting the
                  public and safely removing the affected product from the
                  market.
                </p>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default ProductRecalls;
