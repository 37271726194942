import freeShipping from "../assets/images/free_shipping.png";
import payWithMobileMoney from "../assets/images/free_shipping_to_store.png";
import mobileMoney from "../assets/images/pay_with_mobile_money.png";

const StorePros = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center mb-3">
        <h5 className="text-center text-xs md:text-left md:text-lg font-opensans-semibold mb-4">
          Pay with Mobile Money
        </h5>
        <img src={mobileMoney} alt="pay with mobile money" className="w-12 h-14 object-contain" />
      </div>

      <div className="flex flex-col justify-center items-center mb-3">
        <h5 className="text-center text-xs md:text-left md:text-lg font-opensans-semibold mb-4">
          Free shipping at ZMW 500+
        </h5>
        <img src={freeShipping} alt="Free shipping" className="w-32 h-14 object-contain" />
      </div>

      <div className="flex flex-col justify-center items-center mb-3">
        <h5 className="text-center text-xs md:text-left md:text-lg font-opensans-semibold mb-4">FREE Ship to Store</h5>
        <img src={payWithMobileMoney} alt="Free ship to store" className="w-14 h-14 object-contain" />
      </div>
    </>
  );
};

export default StorePros;
