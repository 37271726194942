import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import styles from "../../styles/views/InStoreClinic.module.css";
import React from "react";

const InStoreClinic = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">In-Store Clinic</h1>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <div>
          <h2 className="font-opensans text-2xl md:text-3xl mb-3">Services</h2>

          <ul className="list-inside font-opensans mb-3">
            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Outpatient care of minor illnesses and injuries</h5>
              <p className="text-gray-500">
                We will assist you with any general health concerns and in room procedures including ear wax irrigation
                & ECGs.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Preventative care, health education & advice</h5>
              <p className="text-gray-500">Receive tailored advice on stopping smoking, weight management and more.</p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Chronic health condition monitoring & care</h5>
              <p className="text-gray-500">
                Receive advice on long-term management of your asthma, diabetes, high blood pressure, cholesterol, and
                more.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Medicals & physical health checks</h5>
              <p className="text-gray-500">
                Get a physical assessment for employment, insurance policy or other purposes.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Diabetic care & Dietary advice</h5>
              <p className="text-gray-500">
                Have your blood sugar tested to allow you to manage your diabetes. Find out what foods to eat to control
                high blood sugar levels.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Female health</h5>
              <p className="text-gray-500">
                We offer female health assessments including breast examinations, pregnancy tests, cervical smears and
                other health screening tests. We also offer administration of contraceptive injections.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Male health</h5>
              <p className="text-gray-500">
                We offer male health assessments including prostate examinations, and other health screening tests.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Blood pressure monitoring</h5>
              <p className="text-gray-500">
                You will be able to manage arterial blood pressure levels and understand your readings.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Wound care services</h5>
              <p className="text-gray-500">
                We will assist you in managing simple burns and wounds (including suture removal).
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Lung function tests and nebulisation</h5>
              <p className="text-gray-500">
                Monitor your lung function through spectrometry and peak flow assessments. Control asthma by having
                medication administered directly into your lungs.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Medication & vitamin injections and infusions</h5>
              <p className="text-gray-500">
                Feed all the nutrients or medication your body needs directly into your blood stream.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Disease Counselling & Health Education</h5>
              <p className="text-gray-500">
                Would you like to know more about a diagnosis you’ve received and what options are available to you?
              </p>
            </li>
          </ul>
        </div>

        <div>
          <h2 className="font-opensans text-2xl md:text-3xl mb-3">Clinics</h2>

          <ul className="list-inside font-opensans mb-3">
            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Under 5</h5>
              <p className="text-gray-500 mb-2">
                Protect your baby by following a recommended immunisation schedule and vaccinating them against viral
                diseases, measles, chickenpox, hepatitis, polio and more.
              </p>
              <p className="text-gray-500 mb-2">
                Monitor their development by having a developmental, height and weight assessment.
              </p>

              <p className="text-gray-500 mb-2">
                Receive advice on issues relating to pregnancy and the first few years of life such as maternal
                wellness, breast feeding, teething, colic, nutrition, potty training, normal sleep patterns and every
                day care for your baby.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Travel</h5>
              <p className="text-gray-500 mb-2">
                When you travel abroad, you may be exposed to diseases you haven’t come across before. Our travel clinic
                helps assess your risk of contracting these diseases and will advise on precautions to take as well as
                which vaccinations and medications will assist in protecting you.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Stop Smoking</h5>
              <p className="text-gray-500 mb-2">
                Get the support you need to help you stop smoking. By deciding to give up smoking, you’ve already taken
                the first step on a journey to living a healthier life.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Weight Counselling</h5>
              <p className="text-gray-500 mb-2">
                Get the support you need to help you reach a healthy weight. You will be screened for conditions which
                may be affecting your weight, have your body mass index measured and receive tailored lifestyle and
                dietary advice on how to manage your weight.
              </p>
            </li>

            <li className="mb-2">
              <h5 className="inline font-opensans-semibold">Mental Wellness</h5>
              <p className="text-gray-500 mb-2">
                Look after your mental as well as your physical well-being. Get the support you need to deal with
                symptoms of anxiety, depression, addiction and other disorders affecting the mind.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default InStoreClinic;
