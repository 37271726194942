import style from "../../styles/views/PrivacyPolicy.module.css";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

const CookiePolicy = () => {
  return (
    <Layout
      header
      footer
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
    >
      <div>
        <section id="privacy_wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className={style.p_policy}>
                <br />
                <h1>Cookie Policy</h1>
                <br />
                <h2>What are Cookies?</h2>
                <p>
                  A cookie is a small data file that is stored on your
                  computer's hard drive by your web browser when you access a
                  website. We use standard technology called 'cookies' on this
                  website to keep track of what you have in your basket and to
                  remember you when you return to our site.
                </p>

                <p>
                  Cookies enable us to understand who has seen which pages and
                  advertisements, to determine how frequently particular pages
                  are visited and to show us the most popular areas of our
                  website. They allow us to enrich your experience of using the
                  website and make it more user-friendly.
                </p>

                <p>
                  To order products on{" "}
                  <a href="https://linkpharmacy.co.zm">linkpharmacy.co.zm</a>{" "}
                  you need to have cookies enabled, you can choose to manage
                  your cookie settings at any time (see Managing Cookies). If
                  you choose to disable cookies you’ll be able to browse the
                  site and use it for research purchases, but you won’t be able
                  to add products to your basket.
                </p>

                <p>
                  Please note: cookies DO NOT contain confidential or personally
                  identifiable information such as your home address, telephone
                  number or credit card details..
                </p>

                <p>The types of Cookies we use</p>

                <h2>
                  We use the following types of cookies on
                  www.linkpharmacy.co.zm:
                </h2>

                <strong>Session Cookies</strong>
                <p>
                  Session cookies enable us to keep track of your movement
                  through the website and allow you to use the site quickly and
                  easily without having to re-enter information. For example,
                  they allow us to remember what you add to your shopping basket
                  to enable you to transact without having to re-enter all your
                  choices again at checkout. Without session cookies, if you
                  click 'checkout', the new page would not recognise your past
                  activities on all prior pages and your shopping basket would
                  always remain empty.
                </p>

                <strong>Persistent Cookies</strong>
                <p>
                  Persistent cookies help us remember your settings for each
                  time you visit. They enable us to authenticate who you are
                  once you register and to remember your preferences the next
                  time you visit the site. This gives you faster and more
                  convenient access each time you visit our site. Third Party
                  Cookies - We may use cookies that are set by a partner company
                  to enable us to track user browsing habits to help us to
                  improve how the site runs and to personalise it for a better
                  user experience. They also help facilitate campaign delivery
                  through advertisements and email activity as well as providing
                  analytical reporting for our website.
                </p>

                <strong>Third Party Cookies</strong>
                <p>
                  You can control the cookies on your machine by turning them on
                  or off. Please note: if you do turn cookies off this will
                  limit the service that we are able to provide to you and may
                  affect your experience of the linkpharmacy.co.zm website.
                  While you will still be able to browse around the site, the
                  functions that allow you to add items to your basket, set up a
                  new account or access an existing account will not be
                  available
                </p>

                <p>
                  Most browsers automatically accept cookies. If you don’t want
                  us to place cookies on your computer at all, you can set your
                  browser to disable or reject them. This will mean that no
                  website will be able to place cookies on your computer. You
                  can do this by adjusting the preferences within your browser.
                </p>

                <p>
                  When you visit linkpharmacy.co.zm you may notice that some of
                  the cookies are not related to linkpharmacy.co.zm. If you go
                  on a web page that has embedded content you might be sent
                  cookies from these websites. We do not control the setting of
                  these cookies, so please check the third-party websites for
                  information about managing their cookies.
                </p>

                <strong>Banner advertising on other websites</strong>
                <p>
                  This type of advertising is designed to provide you with a
                  selection of products based on what you're viewing on
                  linkpharmacy.co.zm, which are presented to you when you visit
                  other selected websites. The adverts may highlight alternative
                  styles and colours as well as products from other categories
                  deemed relevant to your browsing history. The technology
                  behind these adverts is based on cookies.
                </p>

                <strong>Your Privacy</strong>
                <p>
                  Please be assured that the data contained in the cookie used
                  in these adverts is completely anonymous and doesn't contain
                  any of your personal details. (Please note that we can't be
                  responsible for the content of external websites).
                </p>

                <strong>Disabling these adverts</strong>
                <p>
                  We would like to continue to display content that's relevant
                  to you; however, you can choose to opt out of this type of
                  advertising permanently.
                </p>

                <p>
                  You can also visit{" "}
                  <a
                    href="https://youronlinechoices.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    youronlinechoices.com
                  </a>{" "}
                  to find out more about the cookies on your device set by other
                  companies, and opt out of them if you wish (please note that
                  we can't be responsible for the content of external websites).
                </p>

                <strong>Managing Cookies</strong>
                <p>
                  You can control the cookies on your device by turning them on
                  or off. If you do turn cookies off this will limit the service
                  that we are able to provide to you and may affect your
                  experience of the linkpharmacy.co.zm website. While you will
                  still be able to browse around the site, the functions that
                  allow you to add items to your basket, set up a new account or
                  access an existing account will not be available.
                </p>

                <p>
                  Most browsers automatically accept cookies. If you don’t want
                  us to place cookies on your device at all, you can set your
                  browser to disable or reject them. This will mean that no
                  website will be able to place cookies on your computer. You
                  can do this by adjusting the preferences within your browser.
                  Each internet browser is different, so check the ‘help’ menu
                  on your browser to learn how to change your cookie preference
                  or visit{" "}
                  <a
                    href="www.allaboutcookies.org"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.allaboutcookies.org
                  </a>{" "}
                  for further information on cookies and how to switch them off.
                </p>

                <p>
                  See{" "}
                  <a
                    href="www.allaboutcookies.org"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.allaboutcookies.org
                  </a>{" "}
                  for further details on how to do this for most browsers. To
                  find out more about cookies and how we use your information,
                  see our Privacy Notice.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default CookiePolicy;
