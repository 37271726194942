import React, { Component } from "react";
import { Feedback } from "../CustomComponents";
// import * as Sentry from "@sentry/browser";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Handle error in sentry
    // Sentry.withScope((scope) => {
    //   scope.setExtras(info);
    //   const eventId = Sentry.captureException(error);
    //   this.setState({ eventId });
    // });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Feedback
          title={"Something went wrong. Please try again."}
          message={
            "If the problem persists, please contact us on +260 211 255 556 or email us at customercare@linkpharmacy.co.zm"
          }
        />
      );
    }

    return this.props.children;
  }
}

export default Index;
