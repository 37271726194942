import React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import animationLoading from "../../assets/animations/link-animation.json";
import Lottie from "react-lottie";
import Product from "../products/Product";
import Paginate from "../Pagination";
import { SmoothScroll } from "../../helpers/SmoothScroll";

const BuyAgain = () => {
  const [pageNumber, setPageNumber] = React.useState(1);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { data, isLoading, status } = useQuery(["previouslyPurchased", pageNumber], async () => {
    const res = await axios.get(`/v1/website/orders/previously-purchased?page=${pageNumber}`);
    return res.data;
  });

  const handlePageChange = (page) => {
    setPageNumber(page);
    SmoothScroll(0, "smooth");
  };

  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b px-4 py-3 hidden md:block">
        <h2 className="m-0 font-opensans-medium text-2xl">Buy Again</h2>
      </div>
      {isLoading && (
        <div className="w-full h-[70vh] flex items-center justify-center">
          <Lottie options={defaultOptions} height={100} width={100} />
        </div>
      )}

      <div className="h-full w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 place-content-center mb-3 p-3">
        {status === "success" &&
          data?.data?.map((product) => <Product key={product.id} product={product} productType={"similar-products"} />)}
      </div>

      {status === "success" && data?.meta.total > data?.meta.per_page && (
        <div className={"mb-3"}>
          <Paginate
            currentPage={data.meta.current_page}
            perPage={data.meta.per_page}
            total={data.meta.total}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default BuyAgain;
