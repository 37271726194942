import React from "react";
import style from "../../styles/views/PrivacyPolicy.module.css";
import { meta } from "../../globals";
import Layout from "../../components/Layout/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout
      header
      footer
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
    >
      <section id="privacy_wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className={style.p_policy}>
              <br />
              <h1>Privacy Policy</h1>
              <br />
              <h2>1. Introduction</h2>
              <p>
                At Link Pharmacy, we are committed to ensuring your personal
                data is appropriately protected and only used for the reasons we
                explain in this Privacy Policy.
              </p>

              <p>
                This Privacy Policy explains what information we collect about
                you, how we, and our Group, may use it, and the steps we take to
                ensure that it is kept secure. We also explain your rights and
                how to contact us.
              </p>

              <p>
                Changes to this Privacy Policy <br /> Link Pharmacy LTD (“we”,
                “us”, “our”) keeps its Privacy Policy under regular review and
                we may make changes to this notice at any time and post a copy
                of them on our website. Any changes will take effect immediately
                on the day it is posted on our website. Your continued use of
                our services means that you agree to be bound by the modified
                notice.
              </p>

              <p>
                This notice outlines the circumstances in which we will process
                your personal data to provide our services to you, from
                dispensing your medication to administering a flu vaccine, the
                measures we take to ensure your data is kept secure.
              </p>

              <h2>2. The personal information we collect and how is it used</h2>

              <p>
                The personal data we collect and how we use it depends on the
                services we provide you with.
              </p>

              <p>
                <strong>To dispense your prescription:</strong> we collect and
                process your name, address, date of birth, and details of the
                medication that has been prescribed (this includes the name of
                the medication and the dosage instructions). Capturing this
                information is necessary to provide the service to you, and we
                cannot provide you with the medication prescribed without this
                information. Additionally, we may also need to obtain or share
                information with your healthcare provider including your GP to
                provide the best care for you.
              </p>

              <p>
                <strong>Home delivery service:</strong> As part of our home
                delivery service, we use your address to deliver your medication
                to you, and to improve the efficiency of our delivery service,
                for example how many times a day/week we deliver to the same
                street, how many drivers we use, the efficiency of the route
                added.
              </p>

              <p>
                <strong>If you are an online customer:</strong> Using our Click
                and Collect service, we share your personal and prescription
                details with your selected Link pharmacy, so that they can
                supply or transfer your medication or purchase to you.
              </p>

              <p>
                <strong>Processing card details:</strong> If you pay by debit or
                with credit card, we process your payment card details and
                complete the payment transaction. We do not store these details.
                Payments are processed by a third-party company who securely
                holds your payment card details and provides us with a unique
                token that represents that particular card; this token is only
                valid for payment to us.
              </p>

              <p>
                <strong>To deliver additional healthcare services:</strong> Our
                multidisciplinary team may need to understand wider information
                about your health, including any family history or medical
                conditions. If someone books such an appointment on your behalf,
                then we will collect this information from them and verify it
                with you during the appointment. If you receive a service
                through health insurance, we may also need to share information
                with them as required.
              </p>

              <p>
                <strong>SMS notifications:</strong> If you sign-up for this, we
                will send you SMS messages as part of our prescription
                collection service to let you know when your medication is ready
                to collect. We may use your mobile phone number for carefully
                considered and specific purposes which are in our Legitimate
                Interests, and help us to enhance our products and services, but
                which we believe also beneﬁt our customers. For example, to send
                you an SMS message about our in-pharmacy services. Legitimate
                Interests means the interests of our company in conducting and
                managing our business. When we process your personal information
                for our Legitimate Interests, we thoroughly consider and balance
                any potential impacts on you, both positive and negative, and
                your rights under data protection laws. Our legitimate business
                interests do not automatically override your interests.
              </p>

              <p>
                <strong>Bankruptcy or Insolvency:</strong> If we merge or sell
                our company, then we will need to share your personal data with
                the new owner. We will place a notice, or notify you directly,
                to tell you that your personal information is being shared.
              </p>

              <p>
                <strong>Telephoning customer service:</strong> If you contact
                our customer services centre, we may record or monitor the call.
                We do this for regulatory purposes, for training, to ensure and
                improve quality of service delivery, to ensure safety of our
                staff and customers, and to resolve queries or issues. Doing so
                is a legal obligation.
              </p>

              <strong>
                Where we analyse calls to improve our service, we do so as a
                legitimate business interest.
              </strong>

              <p>
                <strong>CCTV monitoring:</strong> When in our pharmacies, we may
                capture you on CCTV. We use CCTV to ensure the safety and
                security of our staff and customers. The images captured by CCTV
                may be used to prevent and detect crime, and therefore may be
                shared with law enforcement agencies.
              </p>

              <p>
                If you become ill in one of our pharmacies, we will share your
                personal information, if we have it, with relevant medical
                professionals to allow them to deliver appropriate treatment to
                you. This will be done in your vital interests.
              </p>

              <h2>3. Disclosure of your personal information</h2>

              <p>
                There are a number of instances where we may need to share your
                data with third parties: This may include with; law enforcement
                to support investigations or for detection and prevention of
                crime including for public safety; to safeguard the vulnerable
                such as children. We thoroughly consider and balance any
                potential impacts on you, both positive and negative, and your
                rights under data protection laws.
              </p>

              <h2>4. International Processing of Personal Information</h2>

              <p>
                We may need to transfer your personal data outside of Zambia to
                service providers and subcontractors in countries where data
                protection laws may not provide the same level of protection as
                those in Zambia. The same applies to external service providers
                who work on behalf of us (for example IT service providers or
                data centres) or third parties, insofar as they come into
                contact with your personal data and are based in another
                country.
              </p>

              <h2>5. Retention of your Personal Information</h2>

              <p>
                We will retain your personal information for as long as we are
                legally or contractually required to do so, or for a period
                which is justifiable to meet our business needs. The exact
                retention period varies depending on the type of information and
                purpose for use, set out in this Privacy Policy. The retention
                of your personal data will be subject to periodic review. If you
                require any further information on retention periods, please
                contact us at{" "}
                <a href="mailto:customercare@linkpharmacy.co.zm">
                  customercare@linkpharmacy.co.zm
                </a>
                .
              </p>

              <h2>6. Marketing and Profiling</h2>

              <p>
                If you have given your consent, we will contact you about the
                products and services we offer. Legitimate Interests means the
                interests of our company in conducting and managing our
                business. When we process your personal information for our
                Legitimate Interests, we thoroughly consider and balance any
                potential impacts on you, both positive and negative, and your
                rights under data protection laws. Our legitimate business
                interests do not automatically override your interests.
              </p>

              <h2>7. How to exercise your rights</h2>

              <p>
                <strong>Your Rights:</strong> Data protection law provides data
                subjects with numerous rights, including the right to: access,
                rectify, erase, restrict, transport, and object to the
                processing of, their personal data. Data subjects also have the
                right to lodge a complaint with the relevant data protection
                authority if they believe that their personal data is not being
                processed in accordance with applicable data protection law.
              </p>

              <p>
                <strong>Right to make subject access request (SAR):</strong>{" "}
                Data subjects may, where permitted by applicable law, request
                copies of their personal data. If you would like to make a SAR,
                i.e., a request for copies of the personal data we hold about
                you, you may do so by writing to Data Protection Officer, Link
                Pharmacy Limited, 386 Copper Street, Roma Park Commercial,
                Lusaka, Zambia, or emailing at{" "}
                <a href="mailto:customercare@linkpharmacy.co.zm">
                  customercare@linkpharmacy.co.zm
                </a>
                . The request should make clear that a SAR is being made. You
                may also be required to submit a proof of your identity.
              </p>

              <p>
                <strong>Right to Rectification:</strong> You may request that we
                rectify any inaccurate and/or complete any incomplete personal
                data.
              </p>

              <p>
                <strong>Right to withdraw consent:</strong> You may, as
                permitted by applicable law, withdraw your consent to the
                processing of your personal data at any time. Such withdrawal
                will not affect the lawfulness of processing based on your
                previous consent. Please note that if you withdraw your consent,
                you may not be able to benefit from certain service features for
                which the processing of your personal data is essential.
              </p>

              <p>
                <strong>Right to object to processing:</strong> Including
                automated processing and profiling. You may, as permitted by
                applicable law, request that we stop processing your personal
                data. In relation to automated processing and profiling, you may
                object to the processing, and you will have the right to obtain
                human intervention.
              </p>

              <p>
                <strong>Right to erasure:</strong> You may request that we erase
                your personal data, and we will comply, unless there is a lawful
                reason for not doing so. For example, there may be an overriding
                legitimate ground for keeping your personal data, such as, a
                legal obligation that we have to comply with, or if retention is
                necessary for us to comply with our legal obligations.
              </p>

              <p>
                <strong>Right to data portability:</strong> In certain
                circumstances, you may request that we provide your personal
                data to you in a structured, commonly used, and machine-readable
                format and have it transferred to another provider of the same
                or similar services. We will comply with such a transfer as far
                as it is technically feasible. Please note that a transfer to
                another provider does not imply erasure of your personal data
                which may still be required for legitimate and lawful purposes.
              </p>

              <p>
                <strong>
                  Your right to lodge a complaint with the supervisory
                  authority:
                </strong>{" "}
                We suggest that you contact us about any questions or if you
                have a complaint in relation to how we process your personal
                data. However, you do have the right to contact the relevant
                supervisory authority directly.
              </p>

              <p>
                Exercising your rights is free and we will respond to any
                request as quickly as we can.
              </p>

              <h2>8. Contacting Us</h2>

              <p>
                If you have any questions or concerns about how we have used
                your personal information you can contact us for more
                information: <br /> By email at{" "}
                <a href="mailto:customercare@linkpharmacy.co.zm">
                  customercare@linkpharmacy.co.zm
                </a>
              </p>

              <p>
                By post to: <br />
                Customer Case Manager, <br /> Link Pharmacy LTD, <br /> 386
                Copper Street, <br /> Roma Park Commercial,
                <br /> Lusaka, Zambia, 10101.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
