/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { DeleteOutlined, Close } from "@mui/icons-material";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCart } from "../context/cartContext";
import style from "../styles/views/ShoppingCart.module.css";
import { Button } from "./CustomComponents";

const ShoppingCartDropdown = ({ visible }) => {
  const [deletedItems, setDeletedItems] = useState([]);
  const [exitTimerId, setExitTimerId] = useState(0);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const {
    cart: { cart, total, count },
    timerId,
    removeFromCart,
    hideDropDown,
    showDropDown,
  } = useCart();

  return (
    <motion.div
      className={`${style.dropDown} ${!visible ? "right-0" : ""}`}
      initial={{ height: 0 }}
      animate={{ height: "auto" }}
      exit={{ height: 0 }}
      transition={{ type: "tween", duration: 0.5 }}
      onMouseEnter={() => {
        clearTimeout(timerId);
        clearTimeout(exitTimerId);
        showDropDown();
      }}
      onMouseLeave={() => {
        const timer = setTimeout(hideDropDown, 600);
        setExitTimerId(timer);
      }}
      ref={(el) => {
        dropdownRef.current = el;
      }}
    >
      <div className={style.dropDownHeader}>
        <span className="font-opensans text-[14px]">
          <span className="font-opensans-bold">My Cart,</span> {count} {count > 1 ? "Items" : "Item"}
        </span>
        <span>
          <Close style={{ fontSize: "20px", cursor: "pointer" }} onClick={hideDropDown} />
        </span>
      </div>

      <div className={style.dropDownItems}>
        <AnimatePresence initial={false}>
          {cart.map((item) => {
            return (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ type: "tween", duration: 0.5 }}
                key={item.id}
                className={style.dropDownItem}
              >
                {deletedItems.includes(item.id) && (
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: "auto" }}
                    transition={{ type: "tween", duration: 0.5 }}
                    className={style.deletedIndicator}
                  >
                    <motion.span
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                      transition={{ delay: 0.2, duration: 0.5 }}
                    >
                      Item Deleted
                    </motion.span>
                  </motion.div>
                )}
                <div className={style.imageContainer}>
                  {item.thumbnail ? (
                    <img className={style.image} src={item.thumbnail} alt={item.name} />
                  ) : (
                    <FontAwesomeIcon icon={faImage} style={{ fontSize: 80 }} color={"#ccc"} />
                  )}
                </div>
                <div className={style.textContainer}>
                  <h4 className="font-opensans-bold mb-1">K {item.price.toLocaleString("en-GB")}</h4>
                  <Link
                    to={`/store/product/${item.id}`}
                    onClick={(e) => {
                      if (item.prescriptionItem) {
                        e.preventDefault();
                      }
                    }}
                    className="font-opensans text-[14px]"
                  >
                    <p>{item.name}</p>
                  </Link>
                  <span>
                    <p>Qty: {item.quantity}</p>

                    <DeleteOutlined
                      className={style.trashIcon}
                      onClick={async () => {
                        setDeletedItems((prevState) => [...prevState, item.id]);
                        await removeFromCart(item);

                        setTimeout(() => setDeletedItems((prevState) => prevState.filter((p) => p !== item.id)), 5000);
                      }}
                    />
                  </span>
                </div>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>

      <div className={style.dropDownSubtotal}>
        <span>
          <span className="font-opensans-bold text-[14px]">Sub-total</span>
        </span>
        <span className="font-opensans text-[14px]">
          K <span className="font-opensans-bold">{total.toLocaleString("en-GB")}</span>
        </span>
      </div>

      <div className={`${style.buttonsContainer}`}>
        <Button width={"full"} variant={"secondary"} onClick={() => navigate("/cart")}>
          View Cart
        </Button>
        <Button width={"full"} onClick={() => navigate("/checkout")}>
          Checkout
        </Button>

        {/*<Link to={"/checkout"}>Checkout</Link>*/}
      </div>

      <div className={`${style.freeTextContainer} font-opensans`}>
        <p className="text-[14px] mb-1">Free Shipping for Orders Over K500*</p>
        <small>
          More info <Link to={"/help-shipping"}>here</Link>
        </small>
      </div>
    </motion.div>
  );
};

export default ShoppingCartDropdown;
