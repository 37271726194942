import * as Yup from "yup";
import cities from "../../cities";

const addressSchema = Yup.object().shape({
  addressLine1: Yup.string()
    .required("Address Line 1 is required.")
    .matches(/^((?!,).)*$/, "A comma is not allowed as a separator, use a space instead."),
  addressLine2: Yup.string()
    .matches(/^((?!,).)*$/, "A comma is not allowed as a separator, use a space instead.")
    .nullable(),
  city: Yup.string().oneOf(cities, "Please select a valid city.").required("City is required."),
  residentialArea: Yup.string().required("Residential Area is required."),
  postalCode: Yup.string().required("Postal Code is required."),
});

export default addressSchema;
