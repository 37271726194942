const stores = [
  {
    id: 1,
    name: "Link Pharmacy Manda Hill",
    city: "Lusaka",
    location: { lat: -15.39654, lng: 28.30602 },
    address: "Shop 36, Manda Hill, Corner Great East; Manchichi Roads, Lusaka, Zambia",
    phone_number: "+260 211 255 556",
    mon_fri: "9am - 7pm",
    sat: "9am – 6pm",
    holidays: "10am – 3pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+Manda+Hill/@-15.396745,28.306023,15z/data=!4m5!3m4!1s0x0:0xd20d0a365867a6ea!8m2!3d-15.3967448!4d28.3060225?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15386.502768113589!2d28.306023!3d-15.396745!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd20d0a365867a6ea!2sLink%20Pharmacy%20Manda%20Hill!5e0!3m2!1sen!2szm!4v1626076866093!5m2!1sen!2szm" style="border: 1px solid lightGrey; borderRadius: 10px;"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+Manda+Hill,+Shop+36,+Manda+Hill,+Corner+Great+East+and+Manchichi+Roads+Lusaka+ZM,+10101/@-15.396745,28.306023,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x19408b4e5e62019d:0xd20d0a365867a6ea!2m2!1d28.3060225!2d-15.3967448?hl=en",
    email: "mandahill@linkpharmacy.co.zm",
  },
  {
    id: 2,
    name: "Link Pharmacy Arcades",
    city: "Lusaka",
    location: { lat: -15.39232, lng: 28.31625 },
    address: "Shop 5, Arcades Shopping Centre, Great East Road, Lusaka, Zambia",
    phone_number: "+260 211 256 606",
    mon_fri: "9am - 7pm",
    sat: "9am – 5pm",
    holidays: "10am – 2pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+Arcades/@-15.3927528,28.3155198,18.25z/data=!4m5!3m4!1s0x19408b3daa50f359:0xe5d48af7b5c48b54!8m2!3d-15.3925866!4d28.3162398?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1923.349745861108!2d28.3155198!3d-15.3927528!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19408b3daa50f359%3A0xe5d48af7b5c48b54!2sLink%20Pharmacy%20Arcades!5e0!3m2!1sen!2szm!4v1626081334204!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+Arcades,+Shop+5,+Arcades+Shopping+Centre,+Great+East+Road,+Lusaka,+Zambia+Lusaka+ZM,+10101/@-15.392587,28.31624,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x19408b3daa50f359:0xe5d48af7b5c48b54!2m2!1d28.3162398!2d-15.3925866?hl=en",
    email: "arcades@linkpharmacy.co.zm",
  },
  {
    id: 3,
    name: "Link Pharmacy East Park",
    city: "Lusaka",
    location: { lat: -15.39007, lng: 28.32194 },
    address: "Shop 34B, East Park Mall, Corner Great East Thabo Mbeki Roads, Lusaka, Zambia",
    phone_number: "+260 211 377 790",
    mon_fri: "9am - 7pm",
    sat: "9am – 5pm",
    holidays: "10am – 2pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+East+Park/@-15.3902821,28.31972,17z/data=!3m1!4b1!4m5!3m4!1s0x19408b4032d5e0b7:0x32694fedb737e454!8m2!3d-15.3902873!4d28.3219087?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.7450597535044!2d28.319720014849803!3d-15.390287289303465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19408b4032d5e0b7%3A0x32694fedb737e454!2sLink%20Pharmacy%20East%20Park!5e0!3m2!1sen!2szm!4v1626081499598!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+East+Park,+East+Park+Mall,,+Thabo+Mbeki+Rd,+Lusaka/@-15.390287,28.321909,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x19408b4032d5e0b7:0x32694fedb737e454!2m2!1d28.3219087!2d-15.3902873?hl=en",
    email: "eastpark@linkpharmacy.co.zm",
  },
  {
    id: 4,
    name: "Link Pharmacy Levy Junction",
    city: "Lusaka",
    location: { lat: -15.41322, lng: 28.28561 },
    address: "Shop 304, Levy Mall, Corner Church Kabelenga Roads, Lusaka, Zambia",
    phone_number: "+260 211 324 011",
    mon_fri: "9am - 7pm",
    sat: "9am – 5pm",
    holidays: "10am – 2pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+Levy+Junction/@-15.4134919,28.2833811,17z/data=!3m1!4b1!4m5!3m4!1s0x1940f5503e54f293:0xfe20fc56532f2eeb!8m2!3d-15.4134971!4d28.2855698?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.3158097967353!2d28.28338111485017!3d-15.41349708928895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f5503e54f293%3A0xfe20fc56532f2eeb!2sLink%20Pharmacy%20Levy%20Junction!5e0!3m2!1sen!2szm!4v1626081574513!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+Levy+Junction,+Shop+304,+Levy+Mall,+Corner+Church+%26+Kabelenga+Roads+Lusaka+ZM,+10101/@-15.413497,28.28557,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1940f5503e54f293:0xfe20fc56532f2eeb!2m2!1d28.2855698!2d-15.4134971?hl=en",
    email: "levy@linkpharmacy.co.zm",
  },

  {
    id: 5,
    name: "Link Pharmacy Woodlands Mall",
    city: "Lusaka",
    location: { lat: -15.43593, lng: 28.34139 },
    address: "Shop 15, Woodlands Mall, Chindo Road, Lusaka, Zambia.",
    phone_number: "+260 211 260 957",
    mon_fri: "9am - 7pm",
    sat: "9am – 5pm",
    holidays: "10am – 2pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+Woodlands+Mall/@-15.4361418,28.3391663,17z/data=!3m1!4b1!4m5!3m4!1s0x19408cf723e45707:0x9a3c0933da8e9965!8m2!3d-15.436147!4d28.341355?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3845.8963063117394!2d28.33916631485034!3d-15.436146989274754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19408cf723e45707%3A0x9a3c0933da8e9965!2sLink%20Pharmacy%20Woodlands%20Mall!5e0!3m2!1sen!2szm!4v1626081685278!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+Woodlands+Mall,+Shop+15,+Woodlands+Mall,+Chindo+Road+Lusaka+ZM,+10101/@-15.436147,28.3391663,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x19408cf723e45707:0x9a3c0933da8e9965!2m2!1d28.341355!2d-15.436147?hl=en",
    email: "woodlands@linkpharmacy.co.zm",
  },

  {
    id: 6,
    name: "Link Pharmacy Makeni Mall",
    city: "Lusaka",
    location: { lat: -15.45358, lng: 28.26617 },
    address: "Shop 4, Makeni Mall, Kafue Road, Lusaka, Zambia.",
    phone_number: "+260 211 272 417",
    mon_fri: "9am - 7pm",
    sat: "9am – 5pm",
    holidays: "10am – 2pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+Makeni/@-15.4537699,28.2639697,17z/data=!4m12!1m6!3m5!1s0x1940f316cafe5eed:0xdee3d3fc133f31cc!2sLink+Pharmacy+Makeni!8m2!3d-15.4537751!4d28.2661584!3m4!1s0x1940f316cafe5eed:0xdee3d3fc133f31cc!8m2!3d-15.4537751!4d28.2661584?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3845.569493001308!2d28.2639697!3d-15.4537699!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f316cafe5eed%3A0xdee3d3fc133f31cc!2sLink%20Pharmacy%20Makeni!5e0!3m2!1sen!2szm!4v1626081783070!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+Makeni,+Shop+4,+Makeni+Mall,+Kafue+Road,+Lusaka,+Zambia+Lusaka+ZM,+10101/@-15.4537699,28.2639697,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1940f316cafe5eed:0xdee3d3fc133f31cc!2m2!1d28.2661584!2d-15.4537751?hl=en",
    email: "makeni@linkpharmacy.co.zm",
  },

  {
    id: 7,
    name: "Link Pharmacy Cosmopolitan Mall",
    city: "Lusaka",
    location: { lat: -15.49094, lng: 28.28205 },
    address: "Shop 35, Cosmopolitan Mall, Corner Kafue Chawama Roads, Lusaka, Zambia",
    phone_number: "+260 211 273900",
    mon_fri: "9am - 7pm",
    sat: "9am – 5pm",
    holidays: "10am – 2pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+Cosmopolitan+Mall/@-15.4941478,28.279708,17z/data=!3m1!4b1!4m5!3m4!1s0x1940f3e3e3ed5e51:0xc8c99865018ed3ac!8m2!3d-15.494153!4d28.2818967?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3844.819224828135!2d28.279708014851067!3d-15.494152989238437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f3e3e3ed5e51%3A0xc8c99865018ed3ac!2sLink%20Pharmacy%20Cosmopolitan%20Mall!5e0!3m2!1sen!2szm!4v1626081823574!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+Cosmopolitan+Mall,+Shop+35,+Cosmopolitan+Mall,+Corner+Kafue,+Chawama+Roads+Lusaka+ZM,+10101/@-15.494153,28.279708,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1940f3e3e3ed5e51:0xc8c99865018ed3ac!2m2!1d28.2818967!2d-15.494153?hl=en",
    email: "cosmopolitan@linkpharmacy.co.zm",
  },

  {
    id: 8,
    name: "Link Pharmacy Garden City Mall",
    city: "Lusaka",
    location: { lat: -15.39339, lng: 28.31311 },
    address: "Shop 2A, Garden City Mall, Great East Road, Lusaka Zambia",
    phone_number: "+260 211 200 018",
    mon_fri: "9am - 7pm",
    sat: "9am – 5pm",
    holidays: "10am – 2pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+Garden+City/@-15.393615,28.3109223,17z/data=!3m1!4b1!4m5!3m4!1s0x19408b134cc7e2d3:0x8fcaac46bc9e347!8m2!3d-15.3936202!4d28.313111?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.6834587766493!2d28.310922314849886!3d-15.393620189301314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19408b134cc7e2d3%3A0x8fcaac46bc9e347!2sLink%20Pharmacy%20Garden%20City!5e0!3m2!1sen!2szm!4v1626081910888!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+Garden+City,+Shop+2A,+Garden+City+Mall,+Great+East+Road+Lusaka+ZM,+10101/@-15.3936202,28.3109223,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x19408b134cc7e2d3:0x8fcaac46bc9e347!2m2!1d28.313111!2d-15.3936202?hl=en",
    email: "gardencity@linkpharmacy.co.zm",
  },

  {
    id: 9,
    name: "Link Pharmacy Pinnacle Mall",
    city: "Lusaka",
    location: { lat: -15.42898, lng: 28.34248 },
    address: "Shop 28, Novare Pinnacle Mall, Corner Chindo Mutandwa Roads, Lusaka, Zambia",
    phone_number: "+260 211 261 026",
    mon_fri: "9am - 7pm",
    sat: "9am – 6pm",
    holidays: "10am – 3pm",
    isAMediclinic: true,
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+Pinnacle+Mall/@-15.4292677,28.3402957,17z/data=!3m1!4b1!4m5!3m4!1s0x19408d3d9560e54d:0xd22a85651cea904e!8m2!3d-15.4292729!4d28.3424844?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.0236864661433!2d28.3402957148503!3d-15.429272889279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19408d3d9560e54d%3A0xd22a85651cea904e!2sLink%20Pharmacy%20Pinnacle%20Mall!5e0!3m2!1sen!2szm!4v1626081983139!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+Pinnacle+Mall,+Shop+28,+Novare+Pinnacle+Mall,+Corner+Chindo,+Mutandwa+Roads+Lusaka+ZM,+10101/@-15.4292729,28.3402957,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x19408d3d9560e54d:0xd22a85651cea904e!2m2!1d28.3424844!2d-15.4292729?hl=en",
    email: "pinnacle@linkpharmacy.co.zm",
  },

  {
    id: 10,
    name: "Link Pharmacy Mukuba Mall",
    city: "Kitwe",
    location: { lat: -12.818022854813313, lng: 28.218249645483272 },
    address: "Shop 30, Mukuba Mall, Corner Freedom Way",
    phone_number: "+260 212 280 008",
    mon_fri: "9am - 7pm",
    sat: "9am – 5pm",
    holidays: "10am – 2pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+Mukuba+Mall/@-12.8231228,28.2153738,17z/data=!3m1!4b1!4m5!3m4!1s0x196ce91538692f83:0xc20bc2dc7c9585d7!8m2!3d-12.823128!4d28.2175625?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.311631759176!2d28.215373814820445!3d-12.823127990953404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x196ce91538692f83%3A0xc20bc2dc7c9585d7!2sLink%20Pharmacy%20Mukuba%20Mall!5e0!3m2!1sen!2szm!4v1626082052368!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+Mukuba+Mall,+Shop+30,+Mukuba+Mall,+Corner+Freedom+Way+%26+Chiwala+Road,+Copperbelt+Province+Kitwe+ZM,+10101/@-12.823128,28.2153738,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x196ce91538692f83:0xc20bc2dc7c9585d7!2m2!1d28.2175625!2d-12.8231278?hl=en",
    email: "mukuba@linkpharmacy.co.zm",
  },

  {
    id: 11,
    name: "Link Pharmacy Kafubu Mall",
    city: "Ndola",
    location: { lat: -12.980070804765857, lng: 28.640336746028115 },
    address: "Shop 13, Kafubu Mall, Corner President Avenue; T3 Highway, Copperbelt Province, Ndola, Zambia",
    phone_number: "+260 212 614 085",
    mon_fri: "9am - 7pm",
    sat: "9am – 5pm",
    holidays: "10am – 2pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy/@-12.9811741,28.6380197,17z/data=!3m1!4b1!4m5!3m4!1s0x196cb4e563119f17:0xf76f9878a14810fd!8m2!3d-12.9811793!4d28.6402084?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.8541438775396!2d28.638019714822146!3d-12.981179290849612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x196cb4e563119f17%3A0xf76f9878a14810fd!2sLink%20Pharmacy!5e0!3m2!1sen!2szm!4v1626082132046!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy,+Ndola/@-12.9811793,28.6380197,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x196cb4e563119f17:0xf76f9878a14810fd!2m2!1d28.6402084!2d-12.9811793?hl=en",
    email: "kafuba@linkpharmacy.com",
  },

  {
    id: 12,
    name: "Link Pharmacy Mosi-Oa-Tunya",
    city: "Livingstone",
    location: { lat: -17.85332265149247, lng: 25.855882646028114 },
    address: "Shop 6, Mosi Oa Tuniya Mall, Mosi Oa Tuniya Road, Southern Province, Livingstone, Zambia",
    phone_number: "+260 213 324 222",
    mon_fri: "9am - 6pm",
    sat: "9am – 5pm",
    holidays: "10am - 2pm",
    view_on_map:
      "https://www.google.com/maps/place/Link+Pharmacy+Mosi+Oa+Tunya/@-17.8798209,25.8578213,17z/data=!3m1!4b1!4m5!3m4!1s0x194ff1b3566f0725:0x55ec6a3968f7a0!8m2!3d-17.879826!4d25.86001?hl=en",
    map_iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3797.120333572669!2d25.857821314883356!3d-17.87982598778526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x194ff1b3566f0725%3A0x55ec6a3968f7a0!2sLink%20Pharmacy%20Mosi%20Oa%20Tunya!5e0!3m2!1sen!2szm!4v1626082191699!5m2!1sen!2szm"',
    directions:
      "https://www.google.com/maps/dir//Link+Pharmacy+Mosi+Oa+Tunya,+Shop+6,+Mosi+Oa+Tuniya+Mall,+Mosi+Oa+Tuniya+Road,+Southern+Province+Livingstone+ZM,+10101/@-17.879826,25.8578213,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x194ff1b3566f0725:0x55ec6a3968f7a0!2m2!1d25.86001!2d-17.879826?hl=en",
    email: "mosi-oa-tunya@linkpharmacy.com",
  },
];

export default stores;
