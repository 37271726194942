import { useField } from "formik";
import ErrorMessage from "./ErrorMessage";
import Input from "../Input";
import { classNames } from "../../utils/utils";

const FormField = ({ className, label, width, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <label className={classNames("flex flex-col items-center", width ? width : "w-full")}>
      {label && <span className="w-full text-left mb-1 md:text-base font-opensans-semibold mb-2">{label}</span>}
      <Input {...field} className={className} bgColor="bg-stone-100 dark:bg-stone-100" {...props} />
      <ErrorMessage>{meta.touched && meta.error && meta.error}</ErrorMessage>
    </label>
  );
};

export default FormField;
