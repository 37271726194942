import React from "react";

const useFormErrors = () => {
  /**
   * This function takes in an error object and recursively traverses it to extract and display the error messages.
   * The error object can be a string, an array of errors, or an object with errors as its values.
   *
   * @param {any} err - The error object. Can be a string, array, or object.
   * @returns {React.ReactNode} - A React Node containing the error messages to display.
   *
   * @example
   * // Given an error object:
   * const error = {
   *  key1: 'Error message 1',
   *  key2: ['Error message 2', 'Error message 3'],
   *  key3: { nestedKey: 'Error message 4' }
   * };
   * // This function would render the error messages:
   * displayErrors(error);
   */
  const displayErrors = (err: any): React.ReactNode => {
    if (typeof err === "string") {
      return <div className="mt-2 text-red-500 text-xs font-opensans">{err}</div>;
    }
    if (Array.isArray(err)) {
      return err.map((e, index) => (
        <div className="mt-2 text-red-500 text-xs font-opensans" key={index}>
          {displayErrors(e)}
        </div>
      ));
    }
    if (typeof err === "object" && err !== null) {
      return Object.values(err).map((e, index) => (
        <div className="mt-2 text-red-500 text-xs font-opensans" key={index}>
          {displayErrors(e)}
        </div>
      ));
    }
    return null;
  };

  return { displayErrors };
};

export default useFormErrors;
