import { classNames } from "../../utils/utils";

const SubmitButton = ({ className, children, type, ...props }) => {
  return (
    <button
      className={classNames(
        "flex items-center rounded-md cursor-pointer text-white font-opensans-semibold text-lg w-fit min-h-[36px] my-3 py-1",
        className ? `${className}` : "bg-link-green px-3"
      )}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

export default SubmitButton;
