import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import SpecialtySideBar from "../../components/SpecialtySideBar";
import style from "../../styles/views/SpecialtyFaq.module.css";

const SpecialtyFaq = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={style.sf_nav}>
              <a href="/">Home</a>
              <span>&#10095;</span>
              <a href="/pharmacy-services">Pharmacy & Health</a>
              <span>&#10095;</span>
              <a href="/specialty-pharmacy">Specialty Pharmacy</a>
              <h1>Specialty Pharmacy FAQs</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <SpecialtySideBar />
            </div>
            <div className="col-md-9">
              <div className={style.sf_header}>
                <h2>Here you'll find answers to many common questions about Specialty Pharmacy:</h2>
              </div>
              {/* <div className={style.sf_cont}>
                {help.map((item, index) => (
                  <Disclosure as="div" className="mt-2" key={index}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                          <span>{item.header}</span>
                          <IoChevronUp
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-green-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                          <div className={style.faq_answer}>{item.panel}</div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
                <Collapse accordion>
                  <Panel
                    header="Q: What types of financial assistance programs are available, and am I eligible?"
                    key="1"
                    defaultActiveKey={["1"]}
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: Link Pharmacy helps patients enroll in financial
                        assistance programs, typically through foundations and
                        organizations, when select criteria is met, including:
                      </p>
                      <p>Income</p>
                      <p>Dependencies</p>
                      <p>Medication type and compliance</p>
                      <p>Funds available</p>
                    </div>
                  </Panel>
                  <Panel
                    header="Q: Where can I get access to specialized pharmacy care?"
                    key="2"
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: Link Pharmacy has multiple points of care, from your
                        local retail location to centrally located fulfillment
                        pharmacies that can ship{" "}
                        <a href="/specialty-pharmacy">specialty medications</a>{" "}
                        anywhere in Zambia.
                      </p>
                      <p>
                        Once we receive your specialty prescription, our #1
                        priority is to help you get the support you need.
                      </p>
                      <p>
                        Call us today at +260 211 255 556 and our expert care
                        team will direct you to the location that best meets
                        your needs. Pharmacists are available to answer your
                        questions 24/7.
                      </p>
                    </div>
                  </Panel>
                  <Panel
                    header="Q: How can Link Pharmacy help manage my chronic condition?"
                    key="3"
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: Link Pharmacy works closely with individuals who are
                        managing chronic, life-threatening or rare conditions,
                        including cancer, HIV/AIDS, infertility, bleeding
                        disorders and more. Our services are designed to help
                        you better understand and manage your condition and
                        medication therapy to enhance your quality of life. Our
                        skilled pharmacists are a valuable part of your care
                        team. Not only can they provide your medications in a
                        timely and convenient manner, they can also support you
                        in managing your health condition and therapy for as
                        long as your treatment requires.
                      </p>
                    </div>
                  </Panel>
                  <Panel
                    header="Q: Will Link Pharmacy support services replace the medical care I'm receiving from my doctor?"
                    key="4"
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: No. We are an extension of your medical team, and we
                        work closely with your doctor to help ensure you get the
                        best results from your medication. As part of that
                        collaboration, we also alert your doctor of any
                        medication doses you may have missed throughout therapy.
                        Our top priority is to make sure your treatment is
                        successful.
                      </p>
                    </div>
                  </Panel>
                  <Panel
                    header="Q: My condition isn't on the list of supported conditions and therapies. Where do I go now?"
                    key="5"
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: Link Pharmacy may still offer support for your
                        chronic condition. Contact us by email or phone to
                        discuss your condition and the support we can provide.
                      </p>
                    </div>
                  </Panel>
                  <Panel
                    header="Q: How do I fill a prescription for a specialty medication for the first time?"
                    key="6"
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: In most cases, your doctor will contact us to request
                        your new specialty pharmacy prescription. We will then
                        contact you directly to gather any additional
                        information needed, including scheduling delivery to the
                        most convenient location for you, whether it is your
                        home, doctor's office or an alternate treatment site.
                        You may also be able to pick up your medications at any
                        of our community pharmacies nationwide (as your coverage
                        allows).
                      </p>
                    </div>
                  </Panel>
                  <Panel
                    header="Q: Do you have any educational materials in Spanish?"
                    key="7"
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: Yes, we offer educational materials in Spanish for
                        Crohn's disease, hepatitis C, multiple sclerosis,
                        psoriasis, psoriatic arthritis, and rheumatoid
                        arthritis. Call us toll free at 888-782-8443 if you
                        would like these materials to be mailed to your home.
                      </p>
                    </div>
                  </Panel>
                  <Panel
                    header="Q: Can I have my prescription labels printed in my preferred language?"
                    key="8"
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: For greater convenience, Link Pharmacy stores can
                        print your prescription labels in as many as 14
                        languages, including Chinese, French, Italian, Japanese,
                        Polish, Spanish, and Vietnamese. Ask our pharmacists for
                        details.
                      </p>
                    </div>
                  </Panel>
                  <Panel
                    header="Q: Do I need to inform Link Pharmacy of any changes in my therapy?"
                    key="9"
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: We would appreciate hearing from you regarding any
                        changes in your therapy to be sure we are providing the
                        best support possible. Please also notify us with any
                        changes to your name, home address, telephone number or
                        insurance provider.
                      </p>
                    </div>
                  </Panel>
                  <Panel
                    header="Q: Is information about my condition and medication confidential?"
                    key="10"
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: Absolutely. In accordance with the Health Insurance
                        Portability and Accountability Act (HIPAA), you can be
                        assured that Link Pharmacy will handle your medication
                        information in a discreet manner with your privacy and
                        confidentiality always in mind.
                      </p>
                    </div>
                  </Panel>
                  <Panel
                    header="Q: Where can I find insurance and payment information?"
                    key="11"
                  >
                    <div className={style.faq_answer}>
                      <p>
                        A: You can find insurance and payment-related answers on
                        our <a href="/help_pharmacy"> Pharmacy FAQs page</a>
                      </p>
                    </div>
                  </Panel>
                </Collapse>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default SpecialtyFaq;
