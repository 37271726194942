import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { meta } from "../globals";
import { AccountMachine } from "../utils/stateMachines/accountHomeMachine";
import AccountSideBar from "../components/accounts/AccountSideBar";
import AccountTopBar from "../components/accounts/AccountTopBar";
import AddressBook from "../components/accounts/AddressBook";
import AddressBookAdd from "../components/accounts/AddressBookAdd";
import AddressBookEdit from "../components/accounts/AddressBookEdit";
import BreadCrumbs from "../components/BreadCrumbs";
import ContactPreferences from "../components/ContactPreferences";
import GiftCards from "../components/GiftCards";
import Layout from "../components/Layout/Layout";
import MyHealthInsurance from "../components/accounts/MyHealthInsurance";
import PersonalInfoEdit from "../components/accounts/PersonalInfoEdit";
import PrescriptionHistory from "./prescriptions/PrescriptionHistory";
import PurchaseOrderDetail from "../components/accounts/PurchaseOrderDetail";
import PurchaseHistory from "../components/accounts/PurchaseHistory";
import Security from "../components/Security";
import shoppingBag from "../assets/images/shopping_1.png";
import BuyAgain from "../components/accounts/BuyAgain";
import PackMyMedsTab from "../components/accounts/PackMyMedsTab";
import MedicationReminders from "../components/accounts/MedicationReminders";
import RequestMedicalRecordsTab from "../components/accounts/RequestMedicalRecordsTab";
import { Button } from "../components/CustomComponents";

const useSectionChangeEvent = (section, id, send) => {
  useEffect(() => {
    if (section) {
      let event = (() => {
        const words = section.split("-");
        const upperCased = words.map((word) => word.toUpperCase());
        return upperCased.join("_");
      })();

      send({ type: event, id: id });
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    } else {
      send("BACK");
    }
  }, [section, id, send]);
};

const Account = () => {
  const [current, send] = useMachine(AccountMachine);
  const location = useLocation();
  const navigate = useNavigate();
  const section = new URLSearchParams(location.search).get("t");
  const id = new URLSearchParams(location.search).get("id");

  useSectionChangeEvent(section, id, send);

  const handleSectionChange = (sectionName) => {
    let newPath = sectionName ? location.pathname + `?t=${sectionName}` : location.pathname;
    navigate(newPath, { replace: true });
  };

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
      bgColor={"bg-stone-100"}
    >
      <div className="container-lg container-md container-sm container mt-4">
        <BreadCrumbs
          crumbs={[
            { name: "Home", link: "/" },
            { name: "My Account", link: "/#" },
          ]}
        />

        <div className="w-full sm:mt-6 flex flex-col md:flex-row">
          <AccountSideBar />

          <div className="w-full flex flex-col md:px-4">
            <AccountTopBar />
            {current.matches("idle") && (
              <div className="h-full flex flex-col justify-between">
                <div className="mb-3">
                  <h2 className="text-xl md:text-[32px] font-opensans-medium">Welcome to your Link account!</h2>
                </div>

                <div className="w-full shadow-sm bg-white mt-auto p-2.5 md:p-4 rounded h-fit flex flex-col-reverse md:flex-row justify-between">
                  <div>
                    <h1 className="text-3xl font-opensans-bold mb-3 mt-0">myHealth Rewards</h1>
                    <p className="text-sm font-opensans mb-2 text-gray-500">
                      An easier way to save, shop and stay well.
                    </p>
                    <ul className="mb-3 list-disc list-inside text-sm font-opensans">
                      <li>Save money by unlocking sale prices</li>
                      <li>Plus so much more!</li>
                    </ul>

                    <Button.Rounded size={"lg"}>Join Now!</Button.Rounded>
                  </div>
                  <img
                    src={shoppingBag}
                    alt="myHealth Shopping Rewards"
                    className="absolute md:static w-[100px] h-[100px] self-end object-contain"
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col rounded bg-white">
              {current.matches("myOrders") && <PurchaseHistory />}
              {current.matches("myDetails") && <PersonalInfoEdit handleSectionChange={handleSectionChange} />}
              {current.matches("changePassword") && <Security />}
              {current.matches("addressBook") && <AddressBook />}
              {current.matches("orderDetails") && <PurchaseOrderDetail />}
              {current.matches("addressBookEdit") && <AddressBookEdit current={current} />}
              {current.matches("addressBookAdd") && <AddressBookAdd current={current} />}
              {current.matches("giftCards") && <GiftCards />}
              {current.matches("myHealthInsurance") && <MyHealthInsurance />}
              {current.matches("contactPreferences") && <ContactPreferences />}
              {current.matches("prescriptionHistory") && <PrescriptionHistory />}
              {current.matches("buyAgain") && <BuyAgain />}
              {current.matches("requestMedicalRecords") && <RequestMedicalRecordsTab />}
              {current.matches("medicationReminders") && <MedicationReminders />}
              {current.matches("packMyMeds") && <PackMyMedsTab />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
