import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import styles from "../../styles/views/LancetLaboratories.module.css";

const LancetLaboratories = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">Lancet Laboratories</h1>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <h2 className="font-opensans-semibold mb-3">
          We offer a wide variety of tests to give you a better understanding of your health, at your convenience.
        </h2>

        <p className="mb-3">
          Have your blood taken or drop off specimens at our mediclinic and have them processed by our laboratory
          partners at Lancet labs
        </p>

        <p className={"font-opensans-semibold text-link-green-1"}>
          <a href="https://cerbalancetafrica.co.zm/">Find out more about Lancet labs.</a>
        </p>
      </div>
    </Layout>
  );
};

export default LancetLaboratories;
