import React, { useState } from "react";
import { useQuery } from "react-query";
import Lottie from "react-lottie";
import animationEmpty from "../../assets/animations/animation-two.json";
import animationLoading from "../../assets/animations/hms-loading.json";
import { getProductOrdersApi } from "../../api/orders";
import OrderItem from "../OrderItem";
import Paginate from "../Pagination";
import { SmoothScroll } from "../../helpers/SmoothScroll";

const PurchaseHistory = () => {
  const [pageNo, setPageNo] = useState(1);

  const { data: orders, isLoading } = useQuery(["orders", pageNo], () => getProductOrdersApi(pageNo));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationEmpty,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const onPageChange = (page) => {
    setPageNo(page);
    SmoothScroll(0, "smooth");
  };

  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b px-4 py-3 hidden md:block">
        <h2 className="m-0 font-opensans-medium text-2xl">My Orders</h2>
      </div>

      {!isLoading && orders?.data?.length > 0 && (
        <div className="p-6 pb-4">
          {orders?.data?.map((order) => (
            <OrderItem order={order} key={order.id} />
          ))}

          {orders.meta.total > orders.meta.per_page && (
            <Paginate
              currentPage={orders.meta.current_page}
              perPage={orders.meta.per_page}
              total={orders.meta.total}
              onPageChange={onPageChange}
            />
          )}
        </div>
      )}

      {!isLoading && orders && orders.data.length === 0 && (
        <div className="w-full flex flex-col justify-center items-center p-6 text-center">
          <div className="h-full w-full md:h-28 md:w-28">
            <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
          </div>

          <h5 className="m-0 text-xl text-stone-500">You have not made any orders yet, Shop now</h5>
        </div>
      )}

      {isLoading && (
        <div className="p-6 text-center">
          <div className="">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationLoading,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={100}
              width={100}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseHistory;
