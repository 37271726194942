import axios from "axios";

export const productOrdersApi = async (data) => {
  let res = await axios.post(`/v1/website/orders`, data);
  return res.data;
};

export const getProductOrdersApi = async (page) => {
  let res = await axios.get(`/v1/website/orders?page=${page || 1}`);
  return res.data;
};

export const getProductOrderApi = async ({ queryKey }) => {
  const id = queryKey[1].id;
  let res = await axios.get(`/v1/website/order-details/${id}`);
  return res.data;
};
