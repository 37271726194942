import { Form } from "formik";

const LinkForm = ({ className, children, authForm, ...props }) => {
  return (
    <Form
      className={
        authForm
          ? "relative flex flex-col items-center justify-between  space-y-4 min-h-max max-h-[80%] w-11/12 md:w-96 lg:w-[400px] bg-white/50 md:bg-white rounded-3xl px-1 md:px-3 pt-4 md:pt-5 pb-3"
          : className
      }
      {...props}
    >
      {children}
    </Form>
  );
};

export default LinkForm;
