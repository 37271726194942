const RequestMedicalRecordsTab = () => {
  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b px-4 py-3">
        <h2 className="m-0 font-opensans-medium text-2xl">Medical Records</h2>
      </div>

      <div className="p-6 pb-4">
        <p>Get in touch if you would like to have a copy of your records</p>
        <p>
          We are also able to send a copy of your medical records to a recipient
          of your choice
        </p>

        <small>
          <b>
            Patients may, where permitted by apllicable law, request copies of
            their personal data.
          </b>
          <span>
            If you would like to make a request for copies of the personal data
            we hold about you, you may do so by contacting us
          </span>
          <span>
            You may also be required to submit a proof of your identity
          </span>
        </small>
      </div>
    </div>
  );
};

export default RequestMedicalRecordsTab;
