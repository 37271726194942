import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import styles from "../../styles/views/Immunisation.module.css";
import React from "react";

const Immunisation = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">Immunisation</h1>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <h1 className="font-opensans-semibold text-lg mb-3">
          Receive individual advice and counselling on what vaccinations you need before travel, after a certain age and
          if you have certain health conditions.
        </h1>
        <p className="mb-3">We also provide the following under 5 and adult vaccinations:</p>

        <div className="mb-5">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 bg-stone-100 rounded-tl rounded-tr">
              <tr>
                <th colSpan={2} scope="col" className="px-6 py-3">
                  <p className="text-sm font-opensans-bold">Under 5 vaccinations</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Hep B</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">HiB</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">TDAP</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">IPV</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Rotavirus</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">PCV13</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Influenza</p>
                </td>
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Annual</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">MMR</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Varicella</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Hep A</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Men ACWY</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Men B</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">PPSV23</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mb-5">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 bg-stone-100 rounded-tl rounded-tr">
              <tr>
                <th colSpan={2} scope="col" className="px-6 py-3">
                  <p className="text-sm font-opensans-bold">Adolescents</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Influenza</p>
                </td>
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Annual</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">HPV</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">DTAP</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">MenACWY</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Men B</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">PPSV23</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Dengue</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mb-3">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 bg-stone-100 rounded-tl rounded-tr">
              <tr>
                <th colSpan={2} scope="col" className="px-6 py-3">
                  <p className="text-sm font-opensans-bold">Adults</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Influenza</p>
                </td>
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Annual</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">DTAP</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Varicella</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Zoster recombinant</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">PCV23</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">HPV</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Hep A</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Hep B</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">MenACWY</p>
                </td>
              </tr>
              <tr className="bg-white border-b">
                <td className="px-6 py-2">
                  <p className="text-sm font-opensans">Men B</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p>
          Walk-ins are welcome or{" "}
          <Link to="/schedule-an-appointment" className="text-link-green-1 font-opensans-semibold">
            Book an Appointment
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default Immunisation;
