export const holidays = {
  kind: "calendar#events",
  etag: '"p33da9c5psjov60g"',
  summary: "Holidays in Zambia",
  updated: "2021-10-29T01:13:20.000Z",
  timeZone: "UTC",
  accessRole: "reader",
  defaultReminders: [],
  nextSyncToken: "CICYkMO47vMCEAAYASDO9LXBAQ==",
  items: [
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20200312_3kepomimhk09npbt7vn030vldg",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDAzMTJfM2tlcG9taW1oazA5bnBidDd2bjAzMHZsZGcgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Youth Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-03-12",
      },
      end: {
        date: "2020-03-13",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200312_3kepomimhk09npbt7vn030vldg@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20200707_mk170svv579qv3sdsg3vore9os",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA3MDdfbWsxNzBzdnY1NzlxdjNzZHNnM3ZvcmU5b3MgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Unity Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-07-07",
      },
      end: {
        date: "2020-07-08",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200707_mk170svv579qv3sdsg3vore9os@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20210509_ca5846bormpfuhp123eetddh0s",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA1MDlfY2E1ODQ2Ym9ybXBmdWhwMTIzZWV0ZGRoMHMgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Mother's Day",
      description: "Observance\nTo hide observances, go to Google Calendar Settings \u003e Holidays in Zambia",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-05-09",
      },
      end: {
        date: "2021-05-10",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210509_ca5846bormpfuhp123eetddh0s@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20210706_5vjnhhncddcep34ij7t6ia5ujo",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA3MDZfNXZqbmhobmNkZGNlcDM0aWo3dDZpYTV1am8gZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Unity Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-07-06",
      },
      end: {
        date: "2021-07-07",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210706_5vjnhhncddcep34ij7t6ia5ujo@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20210812_rnvssf42sggt1esf4lb2r7o324",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA4MTJfcm52c3NmNDJzZ2d0MWVzZjRsYjJyN28zMjQgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Public Holiday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-08-12",
      },
      end: {
        date: "2021-08-13",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210812_rnvssf42sggt1esf4lb2r7o324@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20211225_qednep0g5i6g14k6bmjatvan6s",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTEyMjVfcWVkbmVwMGc1aTZnMTRrNmJtamF0dmFuNnMgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Christmas Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-12-25",
      },
      end: {
        date: "2021-12-26",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20211225_qednep0g5i6g14k6bmjatvan6s@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20220308_6s1na4gb9ifjj9mau1au5s84s4",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjAzMDhfNnMxbmE0Z2I5aWZqajltYXUxYXU1czg0czQgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "International Women's Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-03-08",
      },
      end: {
        date: "2022-03-09",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220308_6s1na4gb9ifjj9mau1au5s84s4@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20220416_h0vna0v9ej43k9vgb1ht7aqvb4",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA0MTZfaDB2bmEwdjllajQzazl2Z2IxaHQ3YXF2YjQgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Holy Saturday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-04-16",
      },
      end: {
        date: "2022-04-17",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220416_h0vna0v9ej43k9vgb1ht7aqvb4@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20220417_ji44p37p3j5dk38a9n04ksrrv4",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA0MTdfamk0NHAzN3AzajVkazM4YTluMDRrc3JydjQgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Easter Sunday",
      description: "Observance\nTo hide observances, go to Google Calendar Settings \u003e Holidays in Zambia",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-04-17",
      },
      end: {
        date: "2022-04-18",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220417_ji44p37p3j5dk38a9n04ksrrv4@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20220428_65u1ipa6nqtjh6ik0lcqvdjtmk",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA0MjhfNjV1MWlwYTZucXRqaDZpazBsY3F2ZGp0bWsgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Kenneth Kaunda Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-04-28",
      },
      end: {
        date: "2022-04-29",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220428_65u1ipa6nqtjh6ik0lcqvdjtmk@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20220502_khsfcq6r4fi0om2r4109g7kqh8",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA1MDJfa2hzZmNxNnI0Zmkwb20ycjQxMDlnN2txaDggZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Day off for Labour Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-05-02",
      },
      end: {
        date: "2022-05-03",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220502_khsfcq6r4fi0om2r4109g7kqh8@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20220508_f5ql64c3cdrercnto8s6mjf3hc",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA1MDhfZjVxbDY0YzNjZHJlcmNudG84czZtamYzaGMgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Mother's Day",
      description: "Observance\nTo hide observances, go to Google Calendar Settings \u003e Holidays in Zambia",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-05-08",
      },
      end: {
        date: "2022-05-09",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220508_f5ql64c3cdrercnto8s6mjf3hc@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20220619_5vd7da6bp5talk7b1hhp6v34f4",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA2MTlfNXZkN2RhNmJwNXRhbGs3YjFoaHA2djM0ZjQgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Father's Day",
      description: "Observance\nTo hide observances, go to Google Calendar Settings \u003e Holidays in Zambia",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-06-19",
      },
      end: {
        date: "2022-06-20",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220619_5vd7da6bp5talk7b1hhp6v34f4@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20220801_v2srnie0qdg7a3uuuu7cv81o0k",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA4MDFfdjJzcm5pZTBxZGc3YTN1dXV1N2N2ODFvMGsgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Farmers' Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-08-01",
      },
      end: {
        date: "2022-08-02",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220801_v2srnie0qdg7a3uuuu7cv81o0k@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20221024_6ieme6cgp5h1q749687eb137bg",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjEwMjRfNmllbWU2Y2dwNWgxcTc0OTY4N2ViMTM3YmcgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Independence Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-10-24",
      },
      end: {
        date: "2022-10-25",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20221024_6ieme6cgp5h1q749687eb137bg@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942034478000"',
      id: "20221226_ar1crc5lv34kt5agjbirmpfph0",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjEyMjZfYXIxY3JjNWx2MzRrdDVhZ2piaXJtcGZwaDAgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:37.000Z",
      updated: "2021-08-26T09:43:37.239Z",
      summary: "Day off for Christmas Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-12-26",
      },
      end: {
        date: "2022-12-27",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20221226_ar1crc5lv34kt5agjbirmpfph0@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20200309_9f2ib4jkc3jh883s65omqd0070",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDAzMDlfOWYyaWI0amtjM2poODgzczY1b21xZDAwNzAgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Day off for International Women's Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-03-09",
      },
      end: {
        date: "2020-03-10",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200309_9f2ib4jkc3jh883s65omqd0070@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20200413_ngmshcrh43jsoef3ndkikbtvvo",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA0MTNfbmdtc2hjcmg0M2pzb2VmM25ka2lrYnR2dm8gZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Easter Monday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-04-13",
      },
      end: {
        date: "2020-04-14",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200413_ngmshcrh43jsoef3ndkikbtvvo@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20200510_g1liqfd0vihbo4qibdsg60hc68",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA1MTBfZzFsaXFmZDB2aWhibzRxaWJkc2c2MGhjNjggZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Mother's Day",
      description: "Observance\nTo hide observances, go to Google Calendar Settings \u003e Holidays in Zambia",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-05-10",
      },
      end: {
        date: "2020-05-11",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200510_g1liqfd0vihbo4qibdsg60hc68@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20200621_8brqfqtuf22o4o0llpcmbu7j08",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA2MjFfOGJycWZxdHVmMjJvNG8wbGxwY21idTdqMDggZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Father's Day",
      description: "Observance\nTo hide observances, go to Google Calendar Settings \u003e Holidays in Zambia",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-06-21",
      },
      end: {
        date: "2020-06-22",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200621_8brqfqtuf22o4o0llpcmbu7j08@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20200803_kgqgvq4mv3erogkg3q3o38fl0g",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA4MDNfa2dxZ3ZxNG12M2Vyb2drZzNxM28zOGZsMGcgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Farmers' Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-08-03",
      },
      end: {
        date: "2020-08-04",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200803_kgqgvq4mv3erogkg3q3o38fl0g@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20201024_v1p5jdtgderv1dv3kt54djcqs8",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDEwMjRfdjFwNWpkdGdkZXJ2MWR2M2t0NTRkamNxczggZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Independence Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-10-24",
      },
      end: {
        date: "2020-10-25",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20201024_v1p5jdtgderv1dv3kt54djcqs8@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20210308_udgu4c5csk0dut0mcdp9rqmtts",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTAzMDhfdWRndTRjNWNzazBkdXQwbWNkcDlycW10dHMgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "International Women's Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-03-08",
      },
      end: {
        date: "2021-03-09",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210308_udgu4c5csk0dut0mcdp9rqmtts@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20210405_u8ctc1v8u83li13gj5hikb5sn8",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA0MDVfdThjdGMxdjh1ODNsaTEzZ2o1aGlrYjVzbjggZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Easter Monday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-04-05",
      },
      end: {
        date: "2021-04-06",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210405_u8ctc1v8u83li13gj5hikb5sn8@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20210501_lk7fmv90kfjv43ssh52ud9s1ac",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA1MDFfbGs3Zm12OTBrZmp2NDNzc2g1MnVkOXMxYWMgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Labour Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-05-01",
      },
      end: {
        date: "2021-05-02",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210501_lk7fmv90kfjv43ssh52ud9s1ac@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20210802_uc9ud039l62fln75q05221dnk8",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA4MDJfdWM5dWQwMzlsNjJmbG43NXEwNTIyMWRuazggZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Farmers' Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-08-02",
      },
      end: {
        date: "2021-08-03",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210802_uc9ud039l62fln75q05221dnk8@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20211018_3sbajpatjskf9jt0il0i1at2p8",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTEwMThfM3NiYWpwYXRqc2tmOWp0MGlsMGkxYXQycDggZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Prayer Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-10-18",
      },
      end: {
        date: "2021-10-19",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20211018_3sbajpatjskf9jt0il0i1at2p8@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20211025_h5k5nk0j2afgg9d1msri60et28",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTEwMjVfaDVrNW5rMGoyYWZnZzlkMW1zcmk2MGV0MjggZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Day off for Independence Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-10-25",
      },
      end: {
        date: "2021-10-26",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20211025_h5k5nk0j2afgg9d1msri60et28@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20220312_2m702h1rthmo46m3uaja2u8n0k",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjAzMTJfMm03MDJoMXJ0aG1vNDZtM3VhamEydThuMGsgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Youth Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-03-12",
      },
      end: {
        date: "2022-03-13",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220312_2m702h1rthmo46m3uaja2u8n0k@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20220415_j0gmtcg7c7tnk8abm16844c5l0",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA0MTVfajBnbXRjZzdjN3RuazhhYm0xNjg0NGM1bDAgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Good Friday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-04-15",
      },
      end: {
        date: "2022-04-16",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220415_j0gmtcg7c7tnk8abm16844c5l0@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20220418_4l3hhdic6gq7dm89qr6t92pn70",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA0MThfNGwzaGhkaWM2Z3E3ZG04OXFyNnQ5MnBuNzAgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Easter Monday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-04-18",
      },
      end: {
        date: "2022-04-19",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220418_4l3hhdic6gq7dm89qr6t92pn70@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20220525_j7qvu1mcvmem71okg6g8jetmc0",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA1MjVfajdxdnUxbWN2bWVtNzFva2c2ZzhqZXRtYzAgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Africa Freedom Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-05-25",
      },
      end: {
        date: "2022-05-26",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220525_j7qvu1mcvmem71okg6g8jetmc0@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20220705_lka5v29t9dmke4agjp51mjkhnc",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA3MDVfbGthNXYyOXQ5ZG1rZTRhZ2pwNTFtamtobmMgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Unity Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-07-05",
      },
      end: {
        date: "2022-07-06",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220705_lka5v29t9dmke4agjp51mjkhnc@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942037160000"',
      id: "20221225_1uqmv0bgej20n4rjoovis4jc10",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjEyMjVfMXVxbXYwYmdlajIwbjRyam9vdmlzNGpjMTAgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:38.000Z",
      updated: "2021-08-26T09:43:38.580Z",
      summary: "Christmas Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-12-25",
      },
      end: {
        date: "2022-12-26",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20221225_1uqmv0bgej20n4rjoovis4jc10@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20200101_d1db73letc0eg7m6cnlgqu0224",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDAxMDFfZDFkYjczbGV0YzBlZzdtNmNubGdxdTAyMjQgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "New Year",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-01-01",
      },
      end: {
        date: "2020-01-02",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200101_d1db73letc0eg7m6cnlgqu0224@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20200410_t2uc9avsqvg66r3sj6480en6os",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA0MTBfdDJ1YzlhdnNxdmc2NnIzc2o2NDgwZW42b3MgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "Good Friday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-04-10",
      },
      end: {
        date: "2020-04-11",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200410_t2uc9avsqvg66r3sj6480en6os@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20200525_39q7mb90o81i67um7d8gcnqsq4",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA1MjVfMzlxN21iOTBvODFpNjd1bTdkOGdjbnFzcTQgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "Africa Freedom Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-05-25",
      },
      end: {
        date: "2020-05-26",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200525_39q7mb90o81i67um7d8gcnqsq4@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20201225_havtgp9e7oqpurrq3ko88orm7c",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDEyMjVfaGF2dGdwOWU3b3FwdXJycTNrbzg4b3JtN2MgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "Christmas Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-12-25",
      },
      end: {
        date: "2020-12-26",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20201225_havtgp9e7oqpurrq3ko88orm7c@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20210402_g5o8r1trce3bm0lko38gcc4ta4",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA0MDJfZzVvOHIxdHJjZTNibTBsa28zOGdjYzR0YTQgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "Good Friday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-04-02",
      },
      end: {
        date: "2021-04-03",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210402_g5o8r1trce3bm0lko38gcc4ta4@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20210403_lhnopjv2sht37j8s314it6pnv0",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA0MDNfbGhub3BqdjJzaHQzN2o4czMxNGl0NnBudjAgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "Holy Saturday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-04-03",
      },
      end: {
        date: "2021-04-04",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210403_lhnopjv2sht37j8s314it6pnv0@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20210525_71q9euueiddappcs0av44101cs",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA1MjVfNzFxOWV1dWVpZGRhcHBjczBhdjQ0MTAxY3MgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "Africa Freedom Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-05-25",
      },
      end: {
        date: "2021-05-26",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210525_71q9euueiddappcs0av44101cs@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20210620_a72fiqvqlbgvgnji6ueon8r898",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA2MjBfYTcyZmlxdnFsYmd2Z25qaTZ1ZW9uOHI4OTggZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "Father's Day",
      description: "Observance\nTo hide observances, go to Google Calendar Settings \u003e Holidays in Zambia",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-06-20",
      },
      end: {
        date: "2021-06-21",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210620_a72fiqvqlbgvgnji6ueon8r898@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20210705_rlhuiqgfmv9g3mg4gojk9k0s1k",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA3MDVfcmxodWlxZ2ZtdjlnM21nNGdvams5azBzMWsgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "Heroes' Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-07-05",
      },
      end: {
        date: "2021-07-06",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210705_rlhuiqgfmv9g3mg4gojk9k0s1k@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20210813_s0f620ub7b0r0vgm0iaism98rg",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA4MTNfczBmNjIwdWI3YjByMHZnbTBpYWlzbTk4cmcgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "Public Holiday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-08-13",
      },
      end: {
        date: "2021-08-14",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210813_s0f620ub7b0r0vgm0iaism98rg@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942039438000"',
      id: "20211024_mf411edph07m2c1lbrisepoeoc",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTEwMjRfbWY0MTFlZHBoMDdtMmMxbGJyaXNlcG9lb2MgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:39.000Z",
      updated: "2021-08-26T09:43:39.719Z",
      summary: "Independence Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-10-24",
      },
      end: {
        date: "2021-10-25",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20211024_mf411edph07m2c1lbrisepoeoc@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20200308_2rcqrofj63v0oa9rqo0904bsq0",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDAzMDhfMnJjcXJvZmo2M3Ywb2E5cnFvMDkwNGJzcTAgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "International Women's Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-03-08",
      },
      end: {
        date: "2020-03-09",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200308_2rcqrofj63v0oa9rqo0904bsq0@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20200412_hr32al5knhsb5tcplr2rfl8glc",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA0MTJfaHIzMmFsNWtuaHNiNXRjcGxyMnJmbDhnbGMgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "Easter Sunday",
      description: "Observance\nTo hide observances, go to Google Calendar Settings \u003e Holidays in Zambia",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-04-12",
      },
      end: {
        date: "2020-04-13",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200412_hr32al5knhsb5tcplr2rfl8glc@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20200501_rj2jembn04q5crtsj62sl9on8k",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA1MDFfcmoyamVtYm4wNHE1Y3J0c2o2MnNsOW9uOGsgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "Labour Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-05-01",
      },
      end: {
        date: "2020-05-02",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200501_rj2jembn04q5crtsj62sl9on8k@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20200706_cna1t8irdmsk2u8aoah68dk1kk",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA3MDZfY25hMXQ4aXJkbXNrMnU4YW9haDY4ZGsxa2sgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "Heroes' Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-07-06",
      },
      end: {
        date: "2020-07-07",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200706_cna1t8irdmsk2u8aoah68dk1kk@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20201018_ch9fgln6dp31pdg2k7ouadjbuc",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDEwMThfY2g5ZmdsbjZkcDMxcGRnMms3b3VhZGpidWMgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "Prayer Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-10-18",
      },
      end: {
        date: "2020-10-19",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20201018_ch9fgln6dp31pdg2k7ouadjbuc@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20210101_enp7vpoa2imjauc5cc79ftok3k",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTAxMDFfZW5wN3Zwb2EyaW1qYXVjNWNjNzlmdG9rM2sgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "New Year",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-01-01",
      },
      end: {
        date: "2021-01-02",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210101_enp7vpoa2imjauc5cc79ftok3k@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20210404_o9sl0qm6em16iemv8dak9rg90k",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA0MDRfbzlzbDBxbTZlbTE2aWVtdjhkYWs5cmc5MGsgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "Easter Sunday",
      description: "Observance\nTo hide observances, go to Google Calendar Settings \u003e Holidays in Zambia",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-04-04",
      },
      end: {
        date: "2021-04-05",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210404_o9sl0qm6em16iemv8dak9rg90k@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20210707_pm7n38hrp8eoa80didaiiblkok",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA3MDdfcG03bjM4aHJwOGVvYTgwZGlkYWlpYmxrb2sgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "Public Holiday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-07-07",
      },
      end: {
        date: "2021-07-08",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210707_pm7n38hrp8eoa80didaiiblkok@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20220101_prf4mgabj66vocgapbdg1vf2rg",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjAxMDFfcHJmNG1nYWJqNjZ2b2NnYXBiZGcxdmYycmcgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "New Year",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-01-01",
      },
      end: {
        date: "2022-01-02",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220101_prf4mgabj66vocgapbdg1vf2rg@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20220501_eejebc48obdcltv4lm2r1ir2gg",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA1MDFfZWVqZWJjNDhvYmRjbHR2NGxtMnIxaXIyZ2cgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "Labour Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-05-01",
      },
      end: {
        date: "2022-05-02",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220501_eejebc48obdcltv4lm2r1ir2gg@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20220704_vrqeodgbi3cb92fv93g88gs36k",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjA3MDRfdnJxZW9kZ2JpM2NiOTJmdjkzZzg4Z3MzNmsgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "Heroes' Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-07-04",
      },
      end: {
        date: "2022-07-05",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20220704_vrqeodgbi3cb92fv93g88gs36k@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942042744000"',
      id: "20221018_2g52htrjgcmae64ad3mlbldsao",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMjEwMThfMmc1Mmh0cmpnY21hZTY0YWQzbWxibGRzYW8gZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:41.000Z",
      updated: "2021-08-26T09:43:41.372Z",
      summary: "Prayer Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2022-10-18",
      },
      end: {
        date: "2022-10-19",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20221018_2g52htrjgcmae64ad3mlbldsao@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942045838000"',
      id: "20200411_p3ftfdaf0r0ggagh1t9jn7mpm0",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMDA0MTFfcDNmdGZkYWYwcjBnZ2FnaDF0OWpuN21wbTAgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:42.000Z",
      updated: "2021-08-26T09:43:42.919Z",
      summary: "Holy Saturday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2020-04-11",
      },
      end: {
        date: "2020-04-12",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20200411_p3ftfdaf0r0ggagh1t9jn7mpm0@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942045838000"',
      id: "20210312_m5ff5e65uh0gkiopjtvni1b7n8",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTAzMTJfbTVmZjVlNjV1aDBna2lvcGp0dm5pMWI3bjggZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:42.000Z",
      updated: "2021-08-26T09:43:42.919Z",
      summary: "Youth Day",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-03-12",
      },
      end: {
        date: "2021-03-13",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210312_m5ff5e65uh0gkiopjtvni1b7n8@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3259942045838000"',
      id: "20210702_p2g5voi4d9pvonktrjkjqtg5is",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA3MDJfcDJnNXZvaTRkOXB2b25rdHJqa2pxdGc1aXMgZW4uem0jaG9saWRheUB2",
      created: "2021-08-26T09:43:42.000Z",
      updated: "2021-08-26T09:43:42.919Z",
      summary: "Public Holiday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-07-02",
      },
      end: {
        date: "2021-07-03",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210702_p2g5voi4d9pvonktrjkjqtg5is@google.com",
      sequence: 0,
      eventType: "default",
    },
    {
      kind: "calendar#event",
      etag: '"3260108027992000"',
      id: "20210824_t7j35vdkjeqgs7r5hc71s6g77c",
      status: "confirmed",
      htmlLink:
        "https://www.google.com/calendar/event?eid=MjAyMTA4MjRfdDdqMzV2ZGtqZXFnczdyNWhjNzFzNmc3N2MgZW4uem0jaG9saWRheUB2",
      created: "2021-08-27T08:46:53.000Z",
      updated: "2021-08-27T08:46:53.996Z",
      summary: "Public Holiday",
      description: "Public holiday",
      creator: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      organizer: {
        email: "en.zm#holiday@group.v.calendar.google.com",
        displayName: "Holidays in Zambia",
        self: true,
      },
      start: {
        date: "2021-08-24",
      },
      end: {
        date: "2021-08-25",
      },
      transparency: "transparent",
      visibility: "public",
      iCalUID: "20210824_t7j35vdkjeqgs7r5hc71s6g77c@google.com",
      sequence: 0,
      eventType: "default",
    },
  ],
};
