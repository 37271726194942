import React from "react";
import { useNavigate } from "react-router-dom";
import { IoImageOutline } from "react-icons/io5";
import { Button } from "../CustomComponents";

const Index = ({ order }) => {
  const { attributes } = order;
  const navigate = useNavigate();

  function getImageOrPlaceholder(detail) {
    return detail.attributes.thumbnail ? (
      <img src={detail.attributes.thumbnail} alt={detail.attributes.name} className="h-full w-full object-contain" />
    ) : (
      <IoImageOutline className="text-4xl" />
    );
  }

  function getOrderStatus(status) {
    switch (status) {
      case "Received":
        return "We've received your order.";
      case "Dispatched":
        return "We've sent your order for delivery.";
      case "Delivered":
        return "Your order was delivered.";
      case "Ready":
        return "Your order is ready for pickup.";
      case "Picked":
        return "Your order was picked up.";
      default:
        return "Your order is being processed.";
    }
  }

  return (
    <div className="p-3 bg-stone-100 mb-3 rounded">
      <div className="font-opensans-bold text-sm uppercase">
        <p>{getOrderStatus(attributes.status)}</p>
      </div>
      <hr className="my-3 text-stone-400" />
      <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-5">
        <div className="order-1 sm:order-2 p-2 w-full h-[400px] sm:w-[200px] sm:h-[100px] bg-white relative grid grid-cols-2 grid-rows-2 gap-1 overflow-hidden mb-3 sm:mb-0">
          <div className="absolute inset-0 grid grid-cols-2 grid-rows-2 gap-1 place-content-center">
            {attributes.details.length === 1 && (
              <div className="col-span-2 row-span-2 p-2">{getImageOrPlaceholder(attributes.details[0])}</div>
            )}
            {attributes.details.length === 2 &&
              attributes.details.map((detail, index) => (
                <div className={`col-span-2 row-span-1 p-2`} key={index}>
                  {getImageOrPlaceholder(detail)}
                </div>
              ))}
            {attributes.details.length === 3 &&
              attributes.details.map((detail, index) => (
                <div className={`col-span-1 row-span-1 ${index === 2 && "col-span-2"} p-2`} key={index}>
                  {getImageOrPlaceholder(detail)}
                </div>
              ))}
            {attributes.details.length >= 4 &&
              attributes.details.slice(0, 4).map((detail, index) => (
                <div className={`relative col-span-1 row-span-1 p-2`} key={index}>
                  {getImageOrPlaceholder(detail)}
                  {index === 3 && attributes.details.length > 4 && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-lg font-opensans-semibold">
                      +{attributes.details.length - 3}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>

        <div className="order-2 sm:order-1 flex flex-row items-start text-sm w-full">
          <div className="flex-1">
            <p className="font-opensans text-sm mb-3">
              <span className="font-opensans-semibold">Order Number:</span>{" "}
              <div className="text-gray-600">{attributes.order_number}</div>
            </p>
            <p className="font-opensans text-sm">
              <span className="font-opensans-semibold">Invoice Number:</span>{" "}
              <div className="text-gray-600">{attributes.invoice_number}</div>
            </p>
          </div>

          <div className="flex-1">
            <p className="font-opensans text-sm mb-3">
              <span className="font-opensans-semibold">Total Items:</span>{" "}
              <div className="text-gray-600">{attributes.details.length}</div>
            </p>
            <p className="font-opensans text-sm">
              <span className="font-opensans-semibold">Total Price:</span>
              <div className="text-gray-600">
                K
                {Number(attributes.total_price).toLocaleString("en-GB", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </p>
          </div>
        </div>
        <div className="order-3 flex flex-col justify-between sm:items-end w-full mt-3 sm:mt-0">
          <Button onClick={() => navigate(`/purchase-history-order-detail/${order.id}`)}>View Order</Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
