import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  DialogActions,
} from "@mui/material";
import shippingRates from "../../../../utils/shippingRates";

const RatesDialog = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>
        <h4 className={"text-bold"} style={{ marginBottom: 0 }}>
          Shipping Rates
        </h4>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Weight (Kg)</TableCell>
              <TableCell>Rate (ZMW)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shippingRates.map((row) => (
              <TableRow key={row.rate}>
                <TableCell size={"small"} textAlign={"center"}>
                  {row.weight}
                </TableCell>
                <TableCell size={"small"} textAlign={"center"}>
                  {row.rate}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell size={"small"} textAlign={"center"}>
                Additional 0.5kg
              </TableCell>
              <TableCell size={"small"} textAlign={"center"}>
                17.76
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions style={{ paddingRight: "23px", paddingBottom: "34px" }}>
        <Button onClick={onClose} color={"success"}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RatesDialog;
