/* eslint-disable jsx-a11y/anchor-is-valid */
import { Disclosure } from "@headlessui/react";
import { IoChevronUp } from "react-icons/io5";

import HelpCenterSidebar from "../../components/HelpCenterSidebar";
import Layout from "../../components/Layout/Layout";
import {
  helpMyHealthEligibility,
  helpOverview,
  helpProgramWorks,
  helpRedemption,
  helpYourAccountDisclosure,
  meta,
} from "../../globals";
import style from "../../styles/views/HelpCenter.module.css";

const HelpMyHealth = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section>
        <div className={style.shipping_help_bg}>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className={style.shipping_help_header}>
                <span>Help center</span>
                <h1>myHealth</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-2">
            <HelpCenterSidebar />
          </div>
          <div className="col-md-6">
            <div className={style.shipping_help}>
              <h4>{"Overview"}</h4>
              {helpOverview.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
              <br />
            </div>
            <div className="shipping_help">
              <h4>{"Eligibility & Joining"}</h4>
              {helpMyHealthEligibility.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
              <br />
            </div>
            <div className="shipping_help">
              <h4>{"Your Account"}</h4>
              {helpYourAccountDisclosure.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
              <br />
            </div>
            <div className="shipping_help">
              <h4>{"How the Program Works"}</h4>
              {helpProgramWorks.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
            <div className={style.shipping_help}>
              <h4>{"Redemption"}</h4>
              {helpRedemption.map((item, index) => (
                <Disclosure as="div" className="mt-2" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                        <span>{item.header}</span>
                        <IoChevronUp
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-green-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                        <div className={style.faq_answer}>{item.panel}</div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
              <br />
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </section>
    </Layout>
  );
};
export default HelpMyHealth;
