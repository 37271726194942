import { Link } from "react-router-dom";
import {
  PhoneAndroidOutlined,
  MarkunreadMailboxOutlined,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePrescription } from "@fortawesome/free-solid-svg-icons";
import { faPrescription } from "@fortawesome/free-solid-svg-icons";

// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

import styles from "../../styles/views/PharmacyServices.module.css";

const PharmacyServices = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="col-md-12">
          <div className={styles.pharmacy_services_bg}>
            <div className={styles.pharmacy_services_header}>
              <h1>Simplify your Rx routine</h1>
            </div>
          </div>
          <div className={styles.transfer_rx}>
            <div className={styles.transfer_rx_cont}>
              <p>New to Link pharmacy?</p>
              <Link to="#">Transfer Rx</Link>
            </div>
            <div className={styles.transfer_rx_cont}>
              <p>Existing Customer?</p>
              <Link to="#">Refill now</Link>
            </div>
          </div>
          <div className={styles.rx_refills}>
            <div className={styles.rx_refills_cont}>
              <div className={styles.rx_svg_wrapper}>
                <FontAwesomeIcon
                  icon={faFilePrescription}
                  style={{ fontSize: "60" }}
                />
              </div>
              <Link to="#">90-day Rx refills</Link>
              <p>Save time and trips, and get potential copay savings.</p>
            </div>
            <div className={styles.rx_refills_cont}>
              <div className={styles.rx_svg_wrapper}>
                <FontAwesomeIcon
                  icon={faPrescription}
                  style={{ fontSize: "60" }}
                />
              </div>
              <Link to="#">Save a Trip Refills</Link>
              <p>
                Sync up your prescriptions and schedule a recurring pickup date.
              </p>
            </div>
            <div className={styles.rx_refills_cont}>
              <div className={styles.rx_svg_wrapper}>
                <PhoneAndroidOutlined />
              </div>
              <Link to="#">Link Pharmacy App</Link>
              <p>Refill in seconds, track Rx orders and get pill reminders.</p>
            </div>
            <div className={styles.rx_refills_cont}>
              <div className={styles.rx_svg_wrapper}>
                <MarkunreadMailboxOutlined />
              </div>
              <Link to="#">Rx Delivery</Link>
              <p>
                Skip a trip to the pharmacy. Choose from our convenient options.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.turning_wrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className={styles.turning}>
                <div className={styles.turning_cont_1}>
                  <div className={styles.turning_cont_bg_1}>
                    <div className={styles.turning_cont}>
                      <h2>Turning 65 & Ready for medicare?</h2>
                      <p>
                        Save on Rx with copays As Low $0 on Select Medicare Part
                        D Plans
                      </p>
                      <Link to="#">Get started</Link>
                    </div>
                  </div>
                  <div className={styles.same_day_cont_bg}>
                    <div className={styles.same_day_cont}>
                      <h2>Same Day Rx Delivery</h2>
                      <p>from local pharmacy you trust. 7days a week</p>
                      <Link to="#">Learn more</Link>
                    </div>
                  </div>
                </div>
                <div className={styles.virtual_care_bg}>
                  <div className={styles.virtual_care_cont}>
                    <h2>24/7 Virtual Care</h2>
                    <p>
                      Treat allergies, UTIs, Summer Colds and more. We're here
                      for you.
                    </p>
                    <Link to="#">Find care</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PharmacyServices;
