import React, { useContext, useState } from "react";
import styles from "../../../styles/views/shop/Checkout.module.css";
import { AuthContext } from "../../../context/AuthContext";
import { useFormik } from "formik";
import { useQuery } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import { LocalShipping, DirectionsWalk } from "@mui/icons-material";
import DeliveryForm from "./comps/DeliveryForm";
import { useCart } from "../../../context/cartContext";
import NoImage from "../../../assets/images/no-image.png";
import { useCheckout } from "../../../hooks/useCheckout";
import RatesDialog from "./comps/RatesDialog";
import ContactInfoDialog from "./comps/ContactInfoDialog";
import { Paper } from "@mui/material";
import EFTDialog from "./comps/EFTDialog";
import Layout from "../../../components/Layout/Layout";
import { meta } from "../../../globals";
import PickupForm from "./comps/PickupForm";
import validationSchemas from "../../../utils/validationSchemas";
import { Button } from "../../../components/CustomComponents";

const Checkout = () => {
  const { user } = useContext(AuthContext);
  const { cart } = useCart();
  const { continueToPayment } = useCheckout();

  const [addresses, setAddresses] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [selectedBranch, setSelectedBranch] = React.useState(null);
  const [deliveryMethod, setDeliveryMethod] = React.useState("deliver");
  const [selectedAddressObj, setSelectedAddressObj] = React.useState(null);
  const [selectedBranchObj, setSelectedBranchObj] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRatesVisible, setIsRatesVisible] = React.useState(false);
  const [isContactVisible, setIsContactVisible] = React.useState(false);
  const [isEFTOpen, setIsEFTOpen] = useState(false);

  // Fetch branches from API
  const { data: branches, isSuccess } = useQuery(
    "branches",
    async () => {
      const res = await axios.get("/v1/website/branches");
      return res.data;
    },
    {
      onSuccess: (data) => {
        const branch = data.data[0];

        setSelectedBranch(branch.id);
        setSelectedBranchObj({
          id: branch.id,
          name: branch.attributes.name,
          address: branch.attributes.address,
          city: branch.attributes.city,
          directions: branch.attributes.directions,
        });
      },
      enabled: !selectedBranch,
    }
  );

  // Fetch all user addresses from API.
  useQuery(
    ["addresses", user.id],
    async () => {
      const res = await axios.get("/v1/website/address");
      return res.data;
    },
    {
      onSuccess: (data) => {
        // Set default address if user has one.
        setAddresses(data.data);
        const defaultAddress = data.data.find((address) => address.attributes.default);
        if (defaultAddress) {
          setSelectedAddress(defaultAddress.id);
          setSelectedAddressObj(defaultAddress.attributes);
        }
      },
      enabled: !selectedAddress, // Only fetch if user has not selected an address.
    }
  );

  // Initial form values.
  const initialValues = {
    firstName: user.firstname || "",
    lastName: user.lastname || "",
    email: user.email || "",
    phoneNumber: user.phoneNumber || "",
    address1: selectedAddressObj?.address1,
    address2: selectedAddressObj?.address2,
    city: selectedAddressObj?.city,
    residentialArea: selectedAddressObj?.residential_area,
    postalCode: selectedAddressObj?.postal_code,
    deliveryMethod,
  };

  // Initialize formik.
  const Formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchemas.checkout,
    validateOnMount: true,
  });

  // Handle Branch selection
  const onBranchChange = (value) => {
    setSelectedBranch(value);
    const branch = branches.data.find((branch) => branch.id === Number(value));
    setSelectedBranchObj({
      id: branch.id,
      name: branch.attributes.name,
      address: branch.attributes.address,
      city: branch.attributes.city,
      directions: branch.attributes.directions,
    });
  };

  // Handle address selection.
  const onChangeAddress = (value) => {
    setSelectedAddress(value);
    const address = addresses.find((address) => address.id === Number(value));
    setSelectedAddressObj(address?.attributes);
  };

  // Handle continue to payment.
  const onContinueToPayment = () => {
    const { values } = Formik;

    const addressObj = {
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      residential_area: values.residentialArea,
      postal_code: values.postalCode,
    };

    Formik.setSubmitting(true);
    if (!Formik.isValid) return;

    if (deliveryMethod === "deliver") {
      const totalPrice = (() => {
        if (addressObj.city === "Lusaka") {
          return Number(cart.total) + 50;
        } else return Number(cart.total);
      })();

      setIsLoading(true);
      continueToPayment(
        user,
        values.email,
        values.phoneNumber,
        values.firstName,
        values.lastName,
        totalPrice,
        addressObj,
        deliveryMethod,
        "online payment",
        cart.cart,
        true
      )
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      continueToPayment(
        user,
        values.email,
        values.phoneNumber,
        values.firstName,
        values.lastName,
        Number(cart.total),
        selectedBranch,
        deliveryMethod,
        "online payment",
        cart.cart,
        false
      )
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    Formik.setSubmitting(false);
  };

  return (
    <Layout
      meta={{
        title: "Link Pharmacy Checkout",
        keywords: meta.homeKeywords,
        description: meta.storeDescription,
      }}
      header
      footer
      bgColor={"bg-stone-100"}
    >
      <div className={`container ${styles.checkout}`}>
        <Link to="/cart" className="text-sm" style={{ color: "green", textDecoration: "none" }}>
          <i className="fa fa-angle-left" /> Back to cart
        </Link>

        <div>
          <h4 className="font-opensans-medium text-2xl">Checkout</h4>
        </div>

        <div className="row">
          <div className="col-md-6">
            <Paper elevation={2} className={`row ${styles.contactInformation}`}>
              <div className="col-9">
                <h4 className="font-opensans-medium text-xl">Contact Information</h4>
              </div>
              <div className={"col-3 text-right"}>
                <h6
                  onClick={() => setIsContactVisible(true)}
                  style={{
                    cursor: "pointer",
                    textAlign: "right",
                    color: "green",
                  }}
                  className="font-opensans-medium"
                >
                  <i className={"fa fa-pencil"} /> Edit
                </h6>
              </div>
              <div className="col-12 mt-2" style={{ fontSize: "15px" }}>
                <div className="mb-2">{Formik.values.email}</div>
                <div>{Formik.values.phoneNumber}</div>
              </div>
            </Paper>

            <Paper elevation={2} className={`row ${styles.deliveryMethod}`}>
              <h4
                className="align-items-center font-opensans-medium text-xl mb-2"
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
              >
                {deliveryMethod === "deliver" ? (
                  <>
                    <LocalShipping style={{ fontSize: "36px" }} /> <span>Deliver</span>
                  </>
                ) : (
                  <>
                    <DirectionsWalk style={{ fontSize: "36px" }} /> <span>Pickup</span>
                  </>
                )}
              </h4>

              <div className="d-flex flex-row mt-2 font-opensans text-sm m-0">
                <div className="form-check">
                  <input
                    style={{ cursor: "pointer" }}
                    className="form-check-input text-link-green"
                    value={"deliver"}
                    defaultChecked
                    onChange={(event) => {
                      setDeliveryMethod(event.target.value);
                    }}
                    type="radio"
                    name="method"
                    id="deliver"
                  />
                  <label style={{ cursor: "pointer" }} className="form-check-label" htmlFor="deliver">
                    Deliver
                  </label>
                </div>

                <div className="form-check" style={{ paddingLeft: "40px" }}>
                  <input
                    value={"pickup"}
                    style={{ cursor: "pointer" }}
                    className="form-check-input text-link-green"
                    onChange={(event) => {
                      setDeliveryMethod(event.target.value);
                    }}
                    type="radio"
                    name="method"
                    id="pickup"
                  />
                  <label style={{ cursor: "pointer" }} className="form-check-label" htmlFor="pickup">
                    Pickup
                  </label>
                </div>
              </div>

              {deliveryMethod === "pickup" && (
                <PickupForm
                  selectedBranchId={selectedBranch}
                  selectedBranch={selectedBranchObj}
                  onBranchChange={onBranchChange}
                  branches={branches}
                  isSuccess={isSuccess}
                />
              )}

              {deliveryMethod === "deliver" && (
                <DeliveryForm
                  addresses={addresses}
                  selectedAddress={selectedAddress}
                  onAddressChange={onChangeAddress}
                  values={Formik.values}
                  errors={Formik.errors}
                  touched={Formik.touched}
                  handleBlur={Formik.handleBlur}
                  handleChange={Formik.handleChange}
                  setTouched={Formik.setFieldTouched}
                />
              )}
            </Paper>
          </div>

          <div className={`col-md-6 ${styles.orderSummery} sm:p-2`} style={{ margin: "25px 0" }}>
            <div>
              <h4 className="font-opensans-medium text-2xl mb-2">Items ({cart.count})</h4>
              <hr />
              <div className="row py-2.5">
                {cart.cart.map((item, index) => (
                  <div key={index} className={`${styles.cartItemContainer} shadow-sm`}>
                    <img className={styles.cartItem} src={item.thumbnail || NoImage} alt={item.name} />
                    <div className={`${styles.quantity} text-sm font-opensans-semibold`}>{item.quantity}</div>
                  </div>
                ))}
              </div>
            </div>

            <hr className="mb-2.5" />

            <h4 className="font-opensans-medium text-2xl mb-2">Order Summery</h4>

            <div className={"d-flex flex-row justify-content-between"}>
              <span className="font-opensans text-[17px] mb-2">Items:</span>
              <span className="font-opensans text-[17px] mb-1.5">
                K{" "}
                {Number(cart.total)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </span>
            </div>

            <div className={"d-flex flex-row justify-content-between"}>
              <span className="font-opensans text-[17px] mb-3">Shipping:</span>

              {deliveryMethod === "deliver" ? (
                <>
                  {(!Formik.values.city || Formik.values.city === "Lusaka") && (
                    <span className="font-opensans text-[17px] mb-3">
                      {Number(cart.total) > 500 ? "Free" : "Flat Rate - K 50.00"}
                    </span>
                  )}
                  {Formik.values.city && Formik.values.city !== "Lusaka" && (
                    <span className="font-opensans text-[17px] mb-3">
                      <span
                        onClick={() => setIsRatesVisible(true)}
                        style={{
                          cursor: "pointer",
                        }}
                        className="font-opensans-semibold text-sm underline text-[#acba00] mb-3"
                      >
                        View Rates
                      </span>
                    </span>
                  )}
                </>
              ) : (
                <span className="font-opensans text-[17px] mb-3">K 0.00</span>
              )}
            </div>

            <div className={"d-flex flex-row justify-content-between"}>
              {deliveryMethod === "deliver" && Formik.values.city && Formik.values.city !== "Lusaka" && (
                <span className="font-opensans text-sm leading-normal mb-3 text-gray-500 md:max-w-[50%]">
                  Actual shipping rates will be calculated once delivery is ready for dispatch
                </span>
              )}
            </div>

            <hr />

            <div className={"d-flex flex-row justify-content-between mt-3"}>
              <h5 className="font-opensans-medium text-xl">Total:</h5>
              {deliveryMethod === "deliver" ? (
                <>
                  {(!Formik.values.city || Formik.values.city === "Lusaka") && (
                    <h5 className="font-opensans-medium text-xl">
                      K{" "}
                      <strong className="font-opensans-bold text-xl">
                        {Number(cart.total) > 500
                          ? Number(cart.total)?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : (Number(cart.total) + Number(50))?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                      </strong>
                    </h5>
                  )}
                  {Formik.values.city && Formik.values.city !== "Lusaka" && (
                    <h5 className="font-opensans-medium text-xl">
                      K{" "}
                      <strong className="font-opensans-bold text-xl">
                        {Number(cart.total).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </strong>
                    </h5>
                  )}
                </>
              ) : (
                <h5 className="font-opensans-medium text-xl">
                  K{" "}
                  <strong className="font-opensans-bold text-xl">
                    {Number(cart.total)?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </strong>
                </h5>
              )}
            </div>

            <div
              className={"text-center mt-4"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button.Rounded size={"lg"} onClick={onContinueToPayment} disabled={isLoading} width={"full"}>
                {isLoading ? <Button.Loader label={"Loading..."} /> : "Continue to Payment"}
              </Button.Rounded>

              <div className={"mt-2.5 w-full"}>
                <Button.Rounded
                  variant={"secondary"}
                  size={"lg"}
                  width={"full"}
                  onClick={() => setIsEFTOpen(true)}
                  disabled={isLoading}
                >
                  Pay with EFT / Bank Deposit
                </Button.Rounded>
              </div>
            </div>
          </div>
        </div>

        <ContactInfoDialog
          isOpen={isContactVisible}
          onClose={() => setIsContactVisible(false)}
          values={Formik.values}
          errors={Formik.errors}
          handleChange={Formik.handleChange}
          handleBlur={Formik.handleBlur}
        />
        <RatesDialog isOpen={isRatesVisible} onClose={() => setIsRatesVisible(false)} />
        <EFTDialog
          isOpen={isEFTOpen}
          onClose={() => setIsEFTOpen(false)}
          Formik={Formik}
          deliveryMethod={deliveryMethod}
          selectedBranch={selectedBranch}
        />
      </div>
    </Layout>
  );
};

export default Checkout;
