import React from 'react';
import Layout from "../components/Layout/Layout";
import linkpharmacyLogo from "../assets/images/link_footer_logo.png";

const Home = () => {
  return (
    <Layout
      meta={{
        title: "Website Under Construction",
        keywords: "pharmacy, health, under construction",
        description: "Our website is currently under construction. Please check back soon!",
      }}
      header
      footer
    >
      <div className="container flex flex-col items-center justify-center h-screen text-center">
        <img src={linkpharmacyLogo} className="w-60 md:w-80 object-contain mb-6" alt="Link Pharmacy Logo" />
        <h1 className="text-3xl md:text-5xl font-bold mb-4">Website Under Construction</h1>
        <p className="text-lg md:text-xl text-gray-600">Our website is currently under construction. Please check back soon!</p>
      </div>
    </Layout>
  );
};

export default Home;
