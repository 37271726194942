import styles from "../styles/views/Returns.module.css";

const ReturnsExclusions = () => {
  return (
    <div className={styles.returns}>
      <p>
        Unfortunately, we do not under any condition accept refund and exchange
        requests on medication & prescription items or any item that has a
        broken seal because there is no mechanism to confirm the items have been
        handled or stored according to specified instructions and have not been
        tampered with.
      </p>
      <p>
        Several types of goods are also exempt from being returned. Perishable
        goods such as food, newspapers or magazines cannot be returned. We also
        do not accept sanitary or intimate products, hazardous materials, or
        flammable liquids. Additional non-returnable items include gift cards
        and some health and personal care items.
      </p>
      <p>
        Only regular priced items may be refunded, unfortunately sale items
        cannot be refunded.
      </p>
      <p>
        We only replace items if they are defective or damaged at the time of
        purchase or if they were sent in error by staff. Items that are
        unavailable for replacement will be automatically refunded.
      </p>
    </div>
  );
};

export default ReturnsExclusions;
