import { useEffect, useState } from "react";
import styles from "../../styles/views/PrescriptionDetails.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import Tooltip from "@mui/material/Tooltip";
import animatedCheck from "../../assets/images/check.gif";
import unanimatedCheck from "../../assets/images/check_img.png";
import { useMutation, useQueryClient } from "react-query";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useCart } from "../../context/cartContext";
import { Button } from "@mui/material";
import { IoChevronUpCircle, IoEllipse, IoImage } from "react-icons/io5";

const PrescriptionItem = ({ prescribedItem, disabled, setDisabled, id }) => {
  const [expanded, setExpanded] = useState(false);
  const [loadedMedia, setLoadedMedia] = useState(animatedCheck);
  const [removing, setRemoving] = useState(false);
  const queryClient = useQueryClient();
  const { addToCart, addLoading } = useCart();

  useEffect(() => {
    setTimeout(() => {
      setLoadedMedia(unanimatedCheck);
    }, 2000);
  }, []);

  const removeFromCart = useMutation(
    async (data) => {
      await axios.post("/v1/website/cart/remove-item", data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },

      onSettled: () => {
        setRemoving(false);
      },
    }
  );

  return (
    <div>
      <div>
        <div
          className={styles.prescriptionItem}
          onClick={() => {
            if (prescribedItem.isComplete) return;
            setExpanded(!expanded);
            setLoadedMedia(animatedCheck);
            setTimeout(() => {
              setLoadedMedia(unanimatedCheck);
            }, 3000);
          }}
        >
          <div className={`${styles.prescriptionItemDetails}`}>
            <p className="font-opensans-medium">{prescribedItem.name}</p>
            {!prescribedItem.isComplete && (
              <IoChevronUpCircle className={`${expanded ? "" : "rotate-180"} text-2xl text-link-green`} />
            )}
          </div>
        </div>
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.ul
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              transition={{ duration: 0.5, type: "tween" }}
              className={styles.prescriptionItemsDropdown}
            >
              {prescribedItem.items.map((item) => (
                <li key={item.id}>
                  <div className={styles.dropdownItemImage}>
                    {item.thumbnail ? (
                      <img src={item.thumbnail} alt="" />
                    ) : (
                      <IoImage className="text-gray-300 text-lg" />
                    )}
                  </div>
                  <div className={styles.dropdownItemDetails}>
                    <span>
                      <div>
                        <p className={styles.dropdownItemTitle}>{item.name}</p>
                        <p className="font-opensans text-sm">Quantity: {item.quantity}</p>
                      </div>

                      {item.recommended === 1 && (
                        <Tooltip title="Recommended by pharmacist">
                          <img src={loadedMedia} style={{ width: 40 }} alt="" />
                        </Tooltip>
                      )}
                    </span>

                    <div className={styles.priceContainer}>
                      <p className={styles.dropdownItemPrice}>
                        K<strong className="font-opensans-bold">{item.price}</strong> <small>Each</small>
                      </p>

                      {item.status === "in_cart" && (
                        <p style={{ fontSize: 14, color: "green" }}>
                          <span style={{ fontWeight: "500" }}>Added to cart</span>
                          <span
                            style={{
                              border: "1px solid gray",
                              color: "#313131",
                              padding: "5px",
                              borderRadius: "5px",
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              removeFromCart.mutate({
                                stock_item_id: item.id,
                              });
                              setRemoving(true);
                            }}
                          >
                            {removing === true ? (
                              <>
                                <IoEllipse className="mr-3 mt-2" />
                                {"Removing"}
                              </>
                            ) : (
                              "Remove From Cart"
                            )}
                          </span>
                        </p>
                      )}

                      {!prescribedItem.beenAdded && (
                        <span onClick={() => setDisabled(true)} style={{ width: "fit-content" }}>
                          <Button
                            onClick={async () => {
                              await addToCart(
                                {
                                  id: item.id,
                                  med_variation_id: item.variationId,
                                  name: item.name,
                                  quantity: item.quantity,
                                  price: item.price,
                                  thumbnail: "",
                                  prescriptionId: prescribedItem.prescriptionId,
                                  queryId: id,
                                  prescriptionItem: true,
                                },
                                () => setDisabled(false)
                              );
                            }}
                            disabled={disabled}
                            sx={{
                              backgroundColor: "#c4d600",
                              color: "white",
                              border: "none",
                              outline: "none",
                              padding: "0.5rem 1rem",
                              borderRadius: "5px",
                              fontWeight: "500",
                              fontSize: "14px",
                              textTransform: "none",
                              fontFamily: "inherit",
                              "&:hover": {
                                backgroundColor: "#acba00",
                              },
                            }}
                          >
                            {addLoading.includes(item.id) ? (
                              <>
                                <FontAwesomeIcon
                                  icon={faCircleNotch}
                                  style={{ marginRight: "10px" }}
                                  className={"fa fa-spin"}
                                />{" "}
                                Adding
                              </>
                            ) : (
                              "Add To Cart"
                            )}
                          </Button>
                        </span>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default PrescriptionItem;
