/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { FaUserCog, FaSignOutAlt, FaHospitalUser, FaChevronRight } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useMachine } from "@xstate/react";
import AccountAvatar from "./AccountAvatar";
import { AuthContext } from "../../context/AuthContext";
import { AccountMachine } from "../../utils/stateMachines/accountHomeMachine";
import { accountSidebarItems, accountSidebarSubItems } from "../../globals";
import { classNames } from "../../utils/utils";
import useWindowSize from "../../hooks/useWindowSize";
import styles from "../../styles/views/Account.module.css";
import { IoClose, IoMenu } from "react-icons/io5";

const AccountSideBar = () => {
  const { user, logout } = useContext(AuthContext);
  const [current, send] = useMachine(AccountMachine);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [isSidebarOpen, setSideBarOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const section = new URLSearchParams(location.search).get("t");
  const id = new URLSearchParams(location.search).get("id");

  // If a query param in the form of ?t=my-details
  // send an event to state machine so that section is displayed on mount
  // and whenever the query param changes

  useEffect(() => {
    if (section) {
      let event = (() => {
        // Split kebab case string coming from query params e.g my-details
        // into array
        const words = section.split("-");

        // Capitalize array words
        const upperCased = words.map((word) => word.toUpperCase());

        // Return event name with correct casing
        // e.g MY_DETAILS
        return upperCased.join("_");
      })();

      send({ type: event, id: id });
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    } else {
      // send event to go to idle state if no section
      send("BACK");
    }
  }, [section]);

  // Change the visible section
  const handleSectionChange = (sectionName) => {
    setSideBarOpen(false);
    if (sectionName) {
      let newPath = location.pathname + `?t=${sectionName}`;
      navigate(newPath, { replace: true });
    } else {
      navigate(location.pathname, { replace: true });
    }
  };

  const { width } = useWindowSize();

  useEffect(() => {
    width >= 768 ? setSideBarOpen(true) : setSideBarOpen(false);
  }, []);

  return (
    <>
      {width <= 767 && (
        <div className="flex items-center mb-2 font-opensans-semibold" onClick={() => setSideBarOpen(!isSidebarOpen)}>
          <IoMenu className="text-3xl mr-1" /> Menu
        </div>
      )}
      <div
        className={classNames(
          width >= 768 && "flex flex-col w-[500px] h-full rounded",
          width <= 767 && isSidebarOpen && "fixed inset-0 z-[1002] bg-stone-900/60 flex flex-col w-screen p-3",
          width <= 767 && !isSidebarOpen && "d-none"
        )}
      >
        <div
          className={classNames(
            width >= 768 && "w-full h-full flex flex-col",
            width <= 767 &&
              isSidebarOpen &&
              "w-full h-full flex flex-col items-center overflow-x-hidden overflow-y-auto"
          )}
        >
          {width <= 767 && isSidebarOpen && (
            <div
              onClick={() => setSideBarOpen((prev) => !prev)}
              className="h-7 w-7 absolute right-0 mr-2 bg-red-500 rounded-full p-0"
            >
              <IoClose className="h-7 w-7 text-white rounded-full m-0" />
            </div>
          )}
          <AccountAvatar user={user} onClick={() => handleSectionChange("")} />
          <div className="w-full md:w-full m-0 mt-2 bg-white rounded">
            {accountSidebarItems.map((item, index) => (
              <Link
                key={index}
                to={`/account?t=${item.link}`}
                onClick={() => handleSectionChange(item.link)}
                className={classNames(
                  "flex items-center cursor-pointer text-[#5C7C6C] py-4 px-8 font-opensans-medium hover:bg-stone-100 hover:scale-110 hover:text-link-green hover:font-opensans-bold hover:whitespace-nowrap rounded-xl transition-all duration-300"
                )}
              >
                {item.icon}
                <p className="m-0">{item.name}</p>
              </Link>
            ))}

            <div
              className="flex items-center justify-between cursor-pointer text-[#5C7C6C] py-4 px-8 font-opensans-medium hover:bg-stone-100 hover:scale-110 hover:text-link-green hover:font-opensans-bold hover:whitespace-nowrap transition-all duration-300"
              onClick={() => setSubMenuOpen(!subMenuOpen)}
            >
              <span className="flex items-center">
                <FaHospitalUser className="text-link-green mr-4 text-xl" /> Patient portal
              </span>
              <FaChevronRight className="text-link-green mr-4 text-xl" />
            </div>

            <AnimatePresence initial={false}>
              {subMenuOpen && (
                <motion.div
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  transition={{
                    duration: 1,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                  className={styles.subMenu}
                >
                  {accountSidebarSubItems.map((subItem, index) => (
                    <Link
                      key={index}
                      to={`/account?t=${subItem.link}`}
                      onClick={() => handleSectionChange(subItem.link)}
                      className="flex items-center justify-between pl-24 cursor-pointer text-[#5C7C6C] py-4 px-8 font-opensans-medium hover:bg-stone-100 hover:scale-110 hover:text-link-green hover:font-opensans-bold hover:whitespace-nowrap transition-all duration-300"
                    >
                      {subItem.icon}
                      <p className="m-0">{subItem.name}</p>
                    </Link>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>

            <Link
              to={"/account?t=contact-preferences"}
              className="flex items-center cursor-pointer text-[#5C7C6C] py-4 px-8 font-opensans-medium hover:bg-stone-100 hover:scale-110 hover:text-link-green hover:font-opensans-bold hover:whitespace-nowrap transition-all duration-300"
            >
              <FaUserCog className="mr-4 text-xl text-link-green" />
              <p className="m-0">Contact preferences</p>
            </Link>

            <div
              className="flex items-center cursor-pointer text-[#5C7C6C] py-4 px-8 font-opensans-medium hover:bg-stone-100 hover:scale-110 hover:text-link-green hover:font-opensans-bold hover:whitespace-nowrap transition-all duration-300"
              onClick={logout}
            >
              <FaSignOutAlt className="mr-4 text-xl text-link-green" /> Sign out
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSideBar;
