import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import RouteProviders from "./providers/RouteProviders";
import { NotificationsProvider } from "./context/NotificationsContext";
import "./styles/styles.css";
import "normalize.css";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("linkpharmacy-access-token");

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <NotificationsProvider>
        <RouteProviders />
      </NotificationsProvider>
    </QueryClientProvider>
  );
};

export default App;
