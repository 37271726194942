import React from "react";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import styles from "../../styles/views/OnlineDoctor.module.css";
import Teladoc from "../../assets/images/webp/teladoc-logo-white.webp";
import { IoIosPhonePortrait } from "react-icons/io";
import { GiMedicinePills, GiNurseMale } from "react-icons/gi";
import { Button } from "../../components/CustomComponents";

const OnlineDoctor = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <div className="w-[250px]">
            <img src={Teladoc} alt="Teladoc Health Logo" className="w-full h-full object-contain" />
          </div>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <h1 className="text-lg">
          Life’s busy. Save time by getting the healthcare you need without a trip to the doctors'. Our friendly,
          experienced clinical team is just a few clicks away.
        </h1>

        <div>
          <h2 className="font-opensans-semibold text-xl mb-3">How it Works?</h2>

          <ul className="list-disc list-inside font-opensans mb-3">
            <li className="mb-1">
              Get an online GP/nurse practitioner appointment from work, home or on the go via your smartphones or
              tablet device.
            </li>
            <li className="mb-1">
              Get tailored medical advice and referrals, plus prescriptions sent to your chosen Link Pharmacy.
            </li>
          </ul>

          <div>
            <Button href="/contact-us">Book an Appointment</Button>
          </div>

          <div className="flex flex-col space-y-4 md:items-center md:flex-row justify-between mt-5">
            <div className="flex flex-row space-x-3 items-center">
              <IoIosPhonePortrait className="text-[60px] text-link-green" />
              <p className="font-opensans-semibold">Get care wherever you are</p>
            </div>
            <div className="flex flex-row space-x-3 items-center">
              <GiMedicinePills className="text-[60px] text-link-green" />
              <p className="font-opensans-semibold">Convenient, discreet treatment</p>
            </div>
            <div className="flex flex-row space-x-3 items-center">
              <GiNurseMale className="text-[60px] text-link-green" />
              <p className="font-opensans-semibold">Experienced clinical team</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OnlineDoctor;
