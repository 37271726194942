// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import care_giver from "../../assets/images/care_giver.jpg";
import SpecialtySideBar from "../../components/SpecialtySideBar";
import style from "../../styles/views/Cancer.module.css";

const Cancer = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="row">
          <div className="clo-md-12">
            <div className={style.c_nav}>
              <a href="/">Home</a>
              <span>&#10095;</span>
              <a href="/pharmacy-services">Pharmacy & Health</a>
              <span>&#10095;</span>
              <a href="/specialty-pharmacy">Specialty Pharmacy</a>
              <h1>Cancer Medication Support</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <SpecialtySideBar />
            </div>
            <div className="col-md-9">
              <div className={style.c_flex}>
                <div className={style.c_flex_img}>
                  <img src={care_giver} alt="" />
                </div>
                <div className={style.c_flex_cont}>
                  <h2>We're on your team</h2>
                </div>
              </div>
              <div className={style.c_cont}>
                <h3>
                  Link Pharmacy can connect you with answers beyond chemotherapy
                  medication.
                </h3>
                <p className={style.c_contact}>Contact us: +260 211 255 556</p>
                <h4>
                  We can help coordinate chemotherapy care for most cancers
                </h4>

                <p>
                  If you or someone you care for is receiving chemotherapy for
                  cancer, your Link Pharmacy Specialty Pharmacy Care Team can
                  support you beyond medication. We will:
                </p>
                <ul>
                  <li>Manage all your medications and supplements</li>
                  <li>Protect against unintended medication interactions</li>
                  <li>Communicate with your doctors</li>
                  <li>Help you manage side effects</li>
                  <li>
                    Give you tips to help you stay on your medication schedule
                  </li>
                  <li>Provide financial assistance coordination</li>
                  <li>
                    Fertility preservation medication, supplies and one-on-one
                    support
                  </li>
                  <li>
                    Whether you’re looking for medication convenience, ways to
                    better afford your medication, hoping to start a family
                    someday, or simply want to look and feel your best during
                    your chemotherapy treatment, Link Pharmacy offers tools and
                    resources to help
                  </li>
                </ul>
                <h5>To get started, call +260 211 255 556.</h5>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Cancer;
