import style from "../../styles/views/PharmacyQuestionnaire.module.css";
// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

const PharmacyQuestionnaire = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={style.q_header}>
              <h1>Pharmacy Questionnaire</h1>
              <p>
                The data submitted in this form is private and confidential and
                will only be used by the Walgreens M&A team
              </p>
            </div>
            <div className={style.q_form}>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="fname">
                      First Name{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="text" id="fname" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lname">
                      Last Name{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="text" id="lname" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="email">
                      Email{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="text" id="email" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="pnumber">
                      Phone Number{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="pnumber" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p className={style.q_owner}>
                      Are you the Pharmacy Owner?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </p>
                    <input type="radio" name="owner" id="yes" value="yes" />
                    <label htmlFor="yes">Yes</label>
                    <input
                      type="radio"
                      name="owner"
                      id="no"
                      value="no"
                      className={style.q_no}
                    />
                    <label htmlFor="no">No</label>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="nc">
                      NCPDP# (Not required, but enables us to respond faster){" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="text" id="nc" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="pharmacy">
                      Pharmacy Name{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="text" id="pharmacy" />
                    <label htmlFor="address">
                      Pharmacy Address{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="text" id="address" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="city">
                      City{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="text" id="city" />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="state">
                      State{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="text" id="state" />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="zip">
                      Zip Code{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="text" id="zip" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="date">
                      When did the Pharmacy Open?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="date" id="date" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="setting">
                      Pharmacy Setting{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="text" id="setting" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="rx">
                      What are your annual Rx Sales?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="rx" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="annual">
                      # of Total Rx/Month?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="annual" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="retail">
                      # of Retail Delivery Rx/Month?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="retail" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="dme">
                      # of HME/DME Rx/Month?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="dme" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="compound">
                      # of Compounded Rx/Month(Sterile & Non-sterile)?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="compound" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="discounted">
                      # of Discounted Generics($4 Rx or similar)/Discounted Cash
                      Rx/Month?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="discounted" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="assisted">
                      # of Assisted Living Rx/Month?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="assisted" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="nursing">
                      # of Nursing Home Rx/Month?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="nursing" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="hos">
                      # of Hospice Rx/Month?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="hos" />
                  </div>
                </div>
                <p>
                  Please indicate below what percentage of your Rx count is:
                </p>
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="cash">
                      Cash%{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="cash" />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="med">
                      Medicaid%(State & Managed combined){" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="med" />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="medicare">
                      Medicare%{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="medicare" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="controlled">
                      % of controlled substance Rxs?{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="controlled" />
                  </div>
                </div>
                <p>
                  Do you lease or own the property? If leased, what is your
                  monthly rent and when does the lease expire?
                </p>
                <label htmlFor="real" style={{ paddingRight: 10 }}>
                  Real Estate Type{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <select id="real">
                  <option value="">Choose</option>
                  <option value="">Owned</option>
                  <option value="">Leased</option>
                </select>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="lease">
                      Monthly Rent(if Leased){" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="number" id="lease" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="term">
                      Lease Term Date{" "}
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                    </label>
                    <input type="date" id="term" />
                  </div>
                </div>
                <label htmlFor="amount">
                  What is the approximate $ amount of pharmacy inventory valued
                  at your acquisition cost?
                </label>
                <input type="number" id="amount" />
                <label htmlFor="ask">
                  What is your asking price?{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <input type="text" id="ask" />
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="comment">Comments</label>
                    <textarea
                      name=""
                      id="comment"
                      cols="10"
                      rows="5"
                    ></textarea>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <span>
                      I Certify that I have permission to report the data in
                      this questionnaire, and that all data is reported
                      accurately to the best of my knowledge
                    </span>
                    <input type="checkbox" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <br />
                    <input type="submit" value="Submit" />
                    <input type="reset" value="cancel" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default PharmacyQuestionnaire;
