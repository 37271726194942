import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

const Faq = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <h1>I am the faq</h1>
    </Layout>
  );
};

export default Faq;
