import { forwardRef } from "react";
import { classNames } from "../utils/utils";

const Select = forwardRef(
  (
    {
      className,
      icon,
      placeholder = "Select a value",
      margin,
      width,
      bgColor,
      label,
      options,
      ...props
    },
    ref
  ) => {
    return (
      <label
        className={classNames(
          "flex flex-col items-center self-center",
          width ? width : "w-fit"
        )}
      >
        {label && (
          <span className="w-full text-left mb-1 md:text-lg">{label}</span>
        )}
        <p
          className={classNames(
            "w-full rounded-md flex items-center px-3 py-2 border border-gray-300 dark:border-slate-800",
            className ? className : "",
            bgColor ? bgColor : "bg-slate-50 dark:bg-slate-800",
            margin ? margin : ""
          )}
        >
          <select
            className={classNames(
              "w-full flex flex-1 text-base font-quicksand-light text-gray-700 border-none p-0 m-0 outline-none focus:border-none focus:outline-none active:outline-none",
              className ? className : "",
              bgColor ? bgColor : "bg-slate-50 dark:bg-slate-800"
            )}
            ref={ref}
            {...props}
          >
            <option value={null} label={placeholder}>
              {placeholder}
            </option>
            {options.map((option) => (
              <option
                value={option.value}
                label={option.label}
                key={option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
          {icon && icon}
        </p>
      </label>
    );
  }
);

export default Select;
