/* eslint-disable jsx-a11y/anchor-is-valid */
import style from "../styles/views/Marker.module.css";

const Marker = ({
  handleMarker,
  text,
  lat,
  lng,
  id,
  color,
  title,
  markerColor,
  markerId,
  index,
}) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleMarker(id, index);
      }}
    >
      <a title={title}>
        <p
          className={style.marker}
          style={{ backgroundColor: id === markerId ? markerColor : null }}
        >
          {text}
        </p>
      </a>
    </div>
  );
};
export default Marker;
