import { Link } from "react-router-dom";

import appStore from "../../assets/images/app-store.png";
import footerLogo from "../../assets/images/link_footer_logo.png";
import playStore from "../../assets/images/google-play.png";
import { IoCall, IoLogoFacebook, IoLogoWhatsapp } from "react-icons/io5";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="w-full min-h-fit flex flex-col font-opensans">
      <div className="bg-imgs-footer bg-cover bg-no-repeat pt-14 pb-8">
        <div className="flex flex-col md:flex-row justify-around container">
          <div className="flex flex-col items-center">
            <img
              src={footerLogo}
              alt="Link Pharmacy Zambia Logo"
              className="w-4/5 h-12 inline-block mb-3 object-contain"
            />
            <div className="flex flex-col space-y-2">
              <p className="text-white flex items-center text-sm font-opensans">
                <IoCall className="text-link-green mr-2 text-base " /> +260 211 255 556
              </p>
              <p className="text-white flex items-center text-sm mb-2 font-opensans">
                <IoLogoWhatsapp className="text-link-green mr-2 text-base" /> +260 976 255 556
              </p>
              <p className="text-white flex flex-col">
                <a href="https://www.facebook.com/linkpharmacyzm/" rel="noreferrer" target="_blank" className="mb-2">
                  <span className="text-sm m-0 font-opensans-semibold">Follow Us</span>
                </a>
                <IoLogoFacebook className="text-link-green text-base" />
              </p>
              <div className="py-3 flex flex-col">
                <h5 className="text-white text-sm mb-2 font-opensans-semibold">Get our FREE app</h5>
                <div className="flex flex-wrap items-center pt-2 space-x-2">
                  <a href="https://apps.apple.com/us/app/link-health-care/id1659449779?itsct=apps_box_badge&amp;itscg=30200">
                    <img src={appStore} alt="Download on the App Store" className="w-36 object-contain" />
                  </a>

                  <a href="https://play.google.com/store/apps/details?id=co.zm.link.linkpharmacy">
                    <img src={playStore} alt="Google Play Store" className="w-36 object-contain" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col p-3 space-y-0.5">
              <h5 className="text-white text-sm font-opensans-semibold mb-2">Customer Service</h5>
              <Link to="/help-shipping" className="text-stone-300 text-xs font-opensans">
                Shipping
              </Link>
              <Link to="/help-returns" className="text-stone-300 text-xs font-opensans">
                Returns
              </Link>
              <Link to="/help-special-orders" className="text-stone-300 text-xs font-opensans">
                Special Orders
              </Link>
              <Link to="/product-recalls" className="text-stone-300 text-xs font-opensans">
                {"Product recalls"}
              </Link>
              <Link to="/request-quotation" className="text-stone-300 text-xs font-opensans">
                {"Request quotation"}
              </Link>
              <Link to="/contact-us" className="text-stone-300 text-xs font-opensans">
                {"Contact us"}
              </Link>
              <Link to="/help" className="text-stone-300 text-xs font-opensans">
                {"Help"}
              </Link>
            </div>

            <div className="flex flex-col p-3 space-y-0.5">
              <h5 className="text-white text-sm font-opensans-semibold mb-2">Company Information</h5>
              <Link className="text-stone-300 text-xs font-opensans" to="/careers">
                {"Careers"}
              </Link>
              <Link className="text-stone-300 text-xs font-opensans" to="/company-info">
                {"Company info"}
              </Link>
              <Link className="text-stone-300 text-xs font-opensans" to="/social-responsibility">
                {"Social responsibility"}
              </Link>
              <Link className="text-stone-300 text-xs font-opensans" to="/become-a-supplier">
                {"Become a supplier"}
              </Link>
            </div>
          </div>

          <div className="flex flex-col p-3 space-y-1">
            <h5 className="text-white text-sm font-opensans-semibold mb-2">Link Pharmacies</h5>
            <Link className="text-stone-300 text-xs font-opensans" to="/find-a-store">
              {"Find a Store"}
            </Link>
            <Link className="text-stone-300 text-xs font-opensans" to="/savings-and-deals">
              {"Savings & Deals"}
            </Link>
            <Link className="text-stone-300 text-xs font-opensans" to="/flushots">
              {"Flu shots & Immunisation"}
            </Link>
            <Link className="text-stone-300 text-xs font-opensans" to="/insurance-coverage">
              {"Insurance Coverage"}
            </Link>
            <Link className="text-stone-300 text-xs font-opensans" to="/pack-my-meds">
              {"Pack my Meds"}
            </Link>
            <Link className="text-stone-300 text-xs font-opensans" to="/specialty-pharmacy">
              {"Specialty pharmacy"}
            </Link>
            <Link className="text-stone-300 text-xs font-opensans" to="/medication-compounding">
              {"Medication compounding"}
            </Link>
            <Link className="text-stone-300 text-xs font-opensans" to="/check-my-meds">
              Check My Meds
            </Link>
            <Link className="text-stone-300 text-xs font-opensans" to="/blood-pressure-screening">
              {"Blood Pressure Screening"}
            </Link>
            <Link className="text-stone-300 text-xs font-opensans" to="/ear-piercing">
              {"Ear Piercing"}
            </Link>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col p-3 space-y-0.5">
              <h5 className="text-white text-sm font-opensans-semibold mb-2 ">Link mediclinic</h5>
              <Link className="text-stone-300 text-xs" to="/mediclinic">
                {"Services"}
              </Link>
              <Link className="text-stone-300 text-xs font-opensans" to="/find-a-mediclinic" id="mediclinic">
                {"Find a mediclinic"}
              </Link>
              <Link className="text-stone-300 text-xs font-opensans" to="/schedule-an-appointment">
                {"Schedule an appointment"}
              </Link>
              <Link className="text-stone-300 text-xs font-opensans" to="/insurance-coverage">
                {"Insurance coverage"}
              </Link>
              <Link className="text-stone-300 text-xs font-opensans" to="/request-medical-records">
                {"Request medical records"}
              </Link>
            </div>
            <div className="flex flex-col p-3 space-y-0.5">
              <h5 className="text-white text-sm font-opensans-semibold mb-2">myHealth rewards</h5>

              <Link className="text-stone-300 text-xs font-opensans" to="/program-details">
                {"Program Details"}
              </Link>
              <Link className="text-stone-300 text-xs font-opensans" to="/faq">
                {"FAQs"}
              </Link>
              <Link className="text-stone-300 text-xs font-opensans" to="/terms-and-conditions">
                {"Terms and Conditions"}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#3c3c3c] md:py-2 p-2 lg:py-5 space-y-3 lg:space-y-0">
        <div className="w-full flex flex-col lg:flex-row container">
          <div className="flex flex-1 mb-2 lg:m-0 text-stone-400">
            <p className="m-0 font-opensans text-sm">
              &copy; Copyright {currentYear}
              <span className="text-white font-opensans-bold">&nbsp; Link Pharmacy Ltd.&nbsp;</span> All rights reserved
            </p>
          </div>
          <div className="flex flex-1 items-center space-x-2 md:justify-end text-stone-400 self-center whitespace-nowrap flex-wrap">
            <Link className="text-xs font-opensans" to="/cookie-policy">
              Cookie Policy
            </Link>{" "}
            <span>|</span>
            <Link className="text-xs font-opensans" to="/privacy-policy">
              Privacy Policy
            </Link>{" "}
            <span>|</span>
            <Link className="text-xs font-opensans" to="/terms-of-use">
              Terms of Use
            </Link>{" "}
            <span>|</span>
            <Link className="text-xs font-opensans" to="/disclaimer">
              Disclaimer
            </Link>{" "}
            <span>|</span>
            <Link className="text-xs font-opensans" to="/security-policy">
              Security
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
