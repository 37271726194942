import React from "react";
import { sendEmailApi } from "../api/sendEmail";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import Layout from "../components/Layout/Layout";
import { meta } from "../globals";
import styles from "../styles/views/RequestAQuotation.module.css";
import { Button, Input, TextArea } from "../components/CustomComponents";
import { Formik } from "formik";
import { useMutation } from "react-query";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { useNotifications } from "../context/NotificationsContext";

const RequestAQuotation = () => {
  const { pushNotification } = useNotifications();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    alternatives: false,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Please enter a valid email address").required("Email is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    message: Yup.string().required("Message is required"),
  });

  const { mutate, isLoading } = useMutation(
    ["request-quotation"],
    async (values: any) =>
      await sendEmailApi("v1/website/emails/request-quotation", {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: values.phoneNumber,
        message: values.message,
        alternatives: values.alternatives,
      }),
    {
      onSuccess: (data, variables) => {
        pushNotification({
          message: "Thank you for your request. We will be in touch with you shortly.",
          type: "Success",
          id: Math.random(),
        });

        variables.formikHelpers.resetForm();
      },
      onError: (error, variables) => {
        pushNotification({
          message: "Something went wrong. Please try again later.",
          type: "Error",
          id: Math.random(),
        });
      },
    }
  );

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className={styles.q_wrapper}>
        <div className={styles.q_nav}>
          <Link to={"/"}>Home</Link>
          <ChevronRight style={{ color: "gray", transform: "scale(0.9)", marginTop: "-3" }} />
        </div>
        <div className={`${styles.requestQuotation}`}>
          <div className={"row-12 mb-3"}>
            <div className={styles.q_header}>
              <h3 className="font-opensans-bold text-md mb-2">Request a Quotation</h3>
              <p className="font-opensans text-sm">
                Please allow our team 24hrs to revert back to you with a quotation. <br /> Fields with this{" "}
                <span className={"text-danger"}>*</span> are required.
              </p>
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers) => {
              mutate({ ...values, formikHelpers });
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
              <form
                className={"mt-4"}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="form-row">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <Input
                        label={"First Name *"}
                        name={"firstName"}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.firstName && touched.firstName ? errors.firstName : ""}
                        touched={touched.firstName}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <Input
                        label={"Last Name *"}
                        name={"lastName"}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.lastName && touched.lastName ? errors.lastName : ""}
                        touched={touched.lastName}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <Input
                        label={"Email Address *"}
                        name={"email"}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email && touched.email ? errors.email : ""}
                        touched={touched.email}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <Input
                        label={"Phone Number *"}
                        name={"phoneNumber"}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ""}
                        touched={touched.phoneNumber}
                      />
                    </div>
                  </div>
                </div>
                <div className={"mb-3"}>
                  <TextArea
                    label={"Message"}
                    rows={5}
                    name={"message"}
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.message && touched.message ? errors.message : ""}
                    touched={touched.message}
                  />
                </div>
                <div className="form-row">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="flex flex-row gap-2 cursor-pointer">
                        <input
                          id={"checkbox"}
                          type={"checkbox"}
                          name={"alternatives"}
                          checked={values.alternatives}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            "w-4 h-4 text-link-green border-gray-300 rounded focus:ring-link-green focus:ring-2"
                          }
                        />
                        <label htmlFor={"checkbox"}>Quote for alternatives if exact items are not available</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"mb-3"}>
                  <Button disabled={isLoading} type={"submit"}>
                    {isLoading ? (
                      <>
                        <FontAwesomeIcon
                          icon={faCircleNotch}
                          style={{ marginRight: "10px" }}
                          className={"fa fa-spin"}
                        />
                        Submitting
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default RequestAQuotation;
