import { Link } from "react-router-dom";

// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/BecomeASupplier.module.css";

const SupplierInclusion = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section>
        <section id="sup_know">
          <div className="row">
            <div className="col-md-2">
              <div className={style.supplier_home}>
                <Link to="/become-a-supplier">
                  <i className="fa fa-angle-left"></i> suppliers
                </Link>
              </div>
            </div>
            <div className="col-md-8">
              <div className={style.sup_know_header}>
                <h1>Supplier Inclusion</h1>
                <p>
                  Link Pharmacy strives to save people money so they can live
                  better. This mantra is at the core of every decision we make.
                  One way we fulfill this commitment is to embrace diversity in
                  all aspects of our organization; from our talented associates
                  to the supplier partners we work with to deliver the products
                  and services our customers want and need. If you would like
                  your diverse business to become part of Link pharmacy's supply
                  chain, you can find your path here.
                </p>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </section>
      </section>
    </Layout>
  );
};
export default SupplierInclusion;
