import { Form, Formik } from "formik";
import { IoCalendarNumber, IoCard, IoCash, IoPerson } from "react-icons/io5";
import { cignaSchema } from "../../globals/schemas";
import styles from "../../styles/views/MyHealthInsurance.module.css";
import { ErrorMessage, FormField } from "../forms";

const Cigna = () => {
  return (
    <Formik
      initialValues={{
        claimType: "",
        firstname: "",
        lastname: "",
        cignaCardID: "",
        currency: "",
        dob: "",
        totalInvoiceAmount: "",
        claimForm: "",
      }}
      onSubmit={(values) => console.log(values)}
      validationSchema={cignaSchema}
    >
      {({ isSubmitting, values, handleChange, setFieldValue, errors }) => (
        <Form className="space-y-4 flex flex-col self-center w-full">
          <div className="w-full h-12 flex items-center bg-link-green rounded-md text-stone-800 px-3">
            <span className="w-8 h-8 mr-3 flex items-center justify-center text-white bg-stone-900/40 rounded-full">
              2
            </span>
            <span>Patient Details</span>
          </div>

          <div className="w-full flex flex-col">
            <h3 className="mb-2 text-lg font-medium text-stone-900">
              Check one of the following
            </h3>
            <div className="w-full flex flex-col justify-between space-y-4 md:px-12">
              <div className="flex">
                <div className="flex items-center h-5">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="multiple-patients"
                    name="claimType"
                    aria-describedby="multiple-patients-text"
                    value="multiplePatients"
                  />
                </div>
                <div className="ml-2 text-sm lg:text-base">
                  <label htmlFor="multiple-patients" className="font-medium">
                    Multiple patients
                  </label>
                  <p
                    id="multiple-patients-text"
                    className="text-xs font-normal text-gray-500 dark:text-gray-300"
                  >
                    This Claim is for multiple patients.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex items-center h-5">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="single-patient"
                    aria-describedby="single-patient-text"
                    name="claimType"
                    value="singlePatient"
                  />
                </div>
                <div className="ml-2 text-sm lg:text-base">
                  <label htmlFor="single-patient" className="font-medium">
                    Single patient
                  </label>
                  <p
                    id="single-patient-text"
                    className="text-xs font-normal text-gray-500 dark:text-gray-300"
                  >
                    This Claim is for one patient.
                  </p>
                </div>
              </div>
            </div>
            <ErrorMessage error={errors.claimType} />
          </div>

          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full flex flex-col space-y-4 md:px-12">
              <FormField
                label={"Patient's Cigna ID"}
                type="text"
                name="cignaCardID"
                placeholder="e.g.: 23453245"
                width={"w-full"}
                margin="mb-0"
                icon={<IoCard className="text-xl ml-2 text-link-green" />}
              />

              <FormField
                label={"Patient's Firstname"}
                type="text"
                name="firstname"
                placeholder="e.g.: John"
                width={"w-full"}
                margin="mb-0"
                icon={<IoPerson className="text-xl ml-2 text-link-green" />}
              />
              <FormField
                label={"Patient's Lastname"}
                type="text"
                name="lastname"
                placeholder="e.g.: Smith"
                width={"w-full"}
                margin="mb-0"
                icon={<IoPerson className="text-xl ml-2 text-link-green" />}
              />
            </div>
            <div className="w-full flex flex-col space-y-4 md:px-12">
              <FormField
                label={"Patient's Date of birth"}
                type="date"
                name="dob"
                placeholder="e.g.: 29/09/2022"
                width={"w-full"}
                margin="mb-0"
                icon={
                  <IoCalendarNumber className="text-xl ml-2 text-link-green" />
                }
              />
              <FormField
                label={"Currency"}
                type="text"
                name="currency"
                placeholder="e.g.: Zambian Kwacha"
                margin="mb-0"
                icon={<IoCash className="text-xl ml-2 text-link-green" />}
              />
              <FormField
                label={"Total Invoice Amount"}
                type="text"
                name="totalInvoiceAmount"
                placeholder="e.g.: K234,000"
                margin="mb-0"
                icon={<IoCash className="text-xl ml-2 text-link-green" />}
              />
            </div>
          </div>

          <div className="w-full flex flex-col space-y-0">
            <div className="w-full h-12 flex items-center bg-link-green rounded-md text-stone-800 px-3">
              <span className="w-8 h-8 mr-3 flex items-center justify-center text-white bg-stone-900/40 rounded-full">
                3
              </span>
              <span>Claim Details</span>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className={"mt-4"}>
                  <div>
                    <h5 className={`font-weight-bold ${styles.label}`}>
                      Upload Copy of Claim Form
                    </h5>

                    <p>
                      Please ensure Patient's signature, Doctor's signature and
                      Medical Services are clearly visible.
                    </p>

                    <input
                      type="file"
                      name="fullNames"
                      className={"form-control"}
                      onChange={(event) => {
                        setFieldValue(
                          "claimForm",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <input
                type={"submit"}
                value="Submit form"
                className="bg-link-green text-stone-900 rounded-full p-3"
                disabled={isSubmitting}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Cigna;
