import { Link } from "react-router-dom";

// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import styles from "../../styles/views/Shipping.module.css";
import HelpCenterSidebar from "../../components/HelpCenterSidebar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const Shipping = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className={styles.shipping}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                <Link to="/">Home</Link> &gt;{" "}
                <Link to="/help">Help Center</Link>
              </p>
              <h4>Shipping</h4>
              <br />
            </div>

            <div className="col-md-12 col-lg-3 d-none d-md-block">
              <HelpCenterSidebar />
            </div>

            <div className="col-md-12 col-lg-9">
              <p>
                The postage and packaging charge you will pay will be displayed
                on screen before you confirm your order to us. Where multiple
                items are included in one order, we will attempt to deliver your
                items together. Medicines will need to be signed for by someone
                aged 18 or over. If there isn't anyone who can sign for your
                order, kindly contact us to arrange a suitable delivery time and
                date. Unfortunately, your order can't be left in a safe place or
                with a neighbour. The courier may attempt to deliver your parcel
                twice, before contacting you to arrange for collection from
                their depot or return it to us.
              </p>
              <p>
                Postage and packing costs will be added to the cost of your
                order and will depend on the total cost of the items you order
                and, where delivery options are provided, the option you choose.
                In providing payment card details, you confirm that you are
                authorised to use the card and authorise us to take payment in
                full for the applicable prescription charges, postage and
                packing charges and any other charges that become due to us
                under these terms. Refunds, if applicable, will only be using
                the payment gateway and/or card originally used for payment.
                Delivery of any order regardless of weight within Lusaka is K50.
                For delivery of your packages or orders outside of Lusaka, see
                the table below.
              </p>
              <div>
                Weight (KG) Rate (ZMW) 0.5 59.96 1 84.59 1.5 93.28 2 108.8 2.5
                126.54 3 142.11 3.5 157.64 4 175.4 4.5 190.94 5 208.7 5.5 224.24
                6 239.77 6.5 257.53 7 273.1 7.5 290.86 8 306.41 8.5 321.91 9
                337.49 9.5 335.23 10 370.79 Additional 0.5kg 17.76
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Weight (KG)</TableCell>
                      <TableCell>Rate (ZMW)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>0.5</TableCell>
                      <TableCell>59.96</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>1</TableCell>
                      <TableCell>84.59</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>1.5</TableCell>
                      <TableCell>93.28</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>2</TableCell>
                      <TableCell>108.8</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>2.5</TableCell>
                      <TableCell>126.54</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>3</TableCell>
                      <TableCell>142.11</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>3.5</TableCell>
                      <TableCell>157.64</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>4</TableCell>
                      <TableCell>175.4</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>4.5</TableCell>
                      <TableCell>190.94</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>5</TableCell>
                      <TableCell>208.7</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>5.5</TableCell>
                      <TableCell>224.24</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>6</TableCell>
                      <TableCell>239.77</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>6.5</TableCell>
                      <TableCell>257.53</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>7</TableCell>
                      <TableCell>273.1</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>7.5</TableCell>
                      <TableCell>290.86</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>8</TableCell>
                      <TableCell>306.41</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>8.5</TableCell>
                      <TableCell>321.91</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>0.5</TableCell>
                      <TableCell>335.91</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>9</TableCell>
                      <TableCell>337.49</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>9.5</TableCell>
                      <TableCell>335.23</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>10</TableCell>
                      <TableCell>370.79</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Additonal 0.5kg</TableCell>
                      <TableCell>17.76</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </Layout>
  );
};

export default Shipping;
