import Layout from "../components/Layout/Layout";
import { meta } from "../globals";
import style from "../styles/views/ScheduleAnAppointment.module.css";
import { Button, Input, Select, TextArea } from "../components/CustomComponents";
import { Label } from "../components/CustomComponents/components/input";
import { Formik } from "formik";
import { useMutation } from "react-query";
import { sendEmailApi } from "../api/sendEmail";
import { useNotifications } from "../context/NotificationsContext";
import validationSchemas from "../utils/validationSchemas";
import MediclinicLogo from "../assets/images/mediclinic-logo.png";

const ScheduleAnAppointment = () => {
  const { pushNotification } = useNotifications();

  const initialValues = {
    firstName: "",
    lastName: "",
    gender: "male",
    address: "",
    email: "",
    phoneNumber: "",
    dateOfVisit: "",
    dateOfAppointment: "",
    reason: "personal",
    preferredTime: "",
    leastPreferredTime: "",
    comments: "",
  };

  const { mutate, isLoading } = useMutation(
    ["schedule-an-appointment"],
    async (data: any) =>
      await sendEmailApi("v1/website/emails/mediclinic-appointment", {
        first_name: data.firstName,
        last_name: data.lastName,
        gender: data.gender,
        address: data.address,
        email: data.email,
        phone_number: data.phoneNumber,
        date_of_visit: data.dateOfVisit,
        date_of_appointment: data.dateOfAppointment,
        reason: data.reason,
        preferred_time: data.preferredTime,
        least_preferred_time: data.leastPreferredTime,
        comments: data.comments,
      }),
    {
      onSuccess: (data, variables) => {
        pushNotification({
          message: "Thank you for your request. We will be in touch with you shortly.",
          type: "Success",
          id: Math.random(),
        });

        variables.formikHelpers.resetForm();
      },
      onError: (error, variables) => {
        pushNotification({
          message: "Something went wrong. Please try again later.",
          type: "Error",
          id: Math.random(),
        });
      },
    }
  );

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="row">
        <div className="col-md-12">
          <div className={style.s_form_flex}>
            <div className={style.s_form_cont}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchemas.scheduleAnAppointment}
                onSubmit={(values, formikHelpers) => {
                  mutate({ ...values, formikHelpers });
                }}
              >
                {({ values, handleChange, handleBlur, setFieldValue, handleSubmit, errors, touched }) => (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="w-56">
                      <img className="w-full h-full object-contain" src={MediclinicLogo} alt="Link Mediclinic Logo" />
                    </div>
                    <h1 className="text-2xl font-opensans-bold mb-1">Book an Appointment</h1>
                    <p className={"font-opensans text-sm text-gray-600"}>
                      Fields marked with (
                      <span>
                        <sup>&#10033;</sup>
                      </span>
                      ) are required
                    </p>
                    <div className="row mt-3">
                      <div className="col-md-6 mb-3">
                        <Input
                          label={"First Name"}
                          name={"firstName"}
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.firstName}
                          touched={touched.firstName}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <Input
                          label={"Last Name"}
                          name={"lastName"}
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.lastName}
                          touched={touched.lastName}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Label label={"Gender"} />
                        <div className="flex items-center gap-2">
                          <input
                            type="radio"
                            className="text-link-green"
                            name={"gender"}
                            value="male"
                            id={"male-radio"}
                            checked={values.gender === "male"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Label label={"Male"} id={"male-radio"} />
                        </div>

                        <div className="flex items-center gap-2">
                          <input
                            type="radio"
                            className="text-link-green"
                            name={"gender"}
                            value="female"
                            id={"female-radio"}
                            checked={values.gender === "female"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Label label={"Female"} id={"female-radio"} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <Input
                          label="Address"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.address}
                          touched={touched.address}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Input
                          label="Email Address"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.email}
                          touched={touched.email}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <Input
                          label="Phone Number"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.phoneNumber}
                          touched={touched.phoneNumber}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Input
                          label="Date of Visit"
                          type={"date"}
                          name="dateOfVisit"
                          value={values.dateOfVisit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.dateOfVisit}
                          touched={touched.dateOfVisit}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <Input
                          label="Date of Appointment"
                          type={"date"}
                          name="dateOfAppointment"
                          value={values.dateOfAppointment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.dateOfAppointment}
                          touched={touched.dateOfAppointment}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <Select
                          value={values.reason}
                          label={"Reason for Visit"}
                          onChange={(value) => setFieldValue("reason", value)}
                          options={[
                            { value: "personal", label: "Personal" },
                            { value: "confidential", label: "Confidential" },
                            { value: "another", label: "Another Option" },
                          ]}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Input
                          label={"Most Preferred Appointment Time"}
                          type={"time"}
                          name="preferredTime"
                          value={values.preferredTime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.preferredTime}
                          touched={touched.preferredTime}
                        />
                        <p className="mt-2 font-opensans text-sm text-gray-600">
                          Please select a time between 10:00am - 2:00pm
                        </p>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Input
                          label={"Least Preferred Appointment Time"}
                          type={"time"}
                          name="leastPreferredTime"
                          value={values.leastPreferredTime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.leastPreferredTime}
                          touched={touched.leastPreferredTime}
                        />
                        <p className="mt-2 font-opensans text-sm text-gray-600">
                          Please select a time between 10:00am - 2:00pm
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <TextArea
                          label={"Comments"}
                          rows={5}
                          placeholder={"Please provide any additional information that will help us serve you better"}
                          name={"comments"}
                          value={values.comments}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.comments}
                          touched={touched.comments}
                        />
                      </div>
                    </div>

                    <div>
                      <Button type={"submit"}>
                        {isLoading ? <Button.Loader label={"Loading..."} /> : "Schedule Appointment"}
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScheduleAnAppointment;
