import Aetna from "../assets/images/insurance/aetna.svg";
import Bupa from "../assets/images/insurance/bupa.svg";
import Cigna from "../assets/images/insurance/cigna.png";
import Continuum from "../assets/images/insurance/continuum_2.png";
import Discovery from "../assets/images/insurance/discovery_health.svg";
import GGH from "../assets/images/insurance/generali-global-health.png";
import Liberty from "../assets/images/insurance/liberty.png";
import Metlife from "../assets/images/insurance/MetLife.png";
import MSH from "../assets/images/insurance/msh.png";
import MSO from "../assets/images/insurance/mso.jpg";
import OptimumGlobal from "../assets/images/insurance/optimum_global.png";
import Prudential from "../assets/images/insurance/prudential.png";
import SES from "../assets/images/insurance/ses.png";
import UHC from "../assets/images/insurance/united_health.svg";
import ZSIC from "../assets/images/insurance/zsic.png";
import BabyAndKids from "../assets/images/Baby-&-Kids.jpg";
import Beauty from "../assets/images/Beauty.jpg";
import ContactAndGlasses from "../assets/images/Contacts-&-Glasses.jpg";
import FitnessAndNutrition from "../assets/images/fitness_nutrition.jpg";
import Grocery from "../assets/images/Grocery.png";
import HomeHealthCareSolution from "../assets/images/home_health.jpg";
import HouseHold from "../assets/images/Household.jpg";
import MedicinesAndTreatment from "../assets/images/Medicines-&-Treatments.jpg";
import PersonalCare from "../assets/images/personal_care.jpg";
import Seasonal from "../assets/images/Seasonal.jpg";
import SexualWellness from "../assets/images/Sexual-Wellness.jpg";
import Veterinary from "../assets/images/Veterinary.jpg";
import VitaminsAndSupplements from "../assets/images/Vitamins-&-Supplements.jpg";
import { FaLock, FaUserAlt, FaAddressCard, FaAddressBook, FaList } from "react-icons/fa";

export const meta = {
  homeTitle: "Link Pharmacy Zambia",
  homeDescription:
    "Link Pharmacy, founded as a single drugstore in 1999, today is a provider of trusted care in Lusaka, Kitwe, Ndola and Livingstone in Zambia.",
  homeKeywords:
    "Link, Link Pharmacy, Pharmacy, LinkPharmacy, Pharmacy store Zambia, biggest Pharmacy store zambia, Link Pharmacy lusaka, Link Pharmacy Kitwe, Link Pharmacy Ndola, Link Pharmacy Livingstone, Link Pharmacy East Park Mall Lusaka,  Link Pharmacy Levy Mall Mall Lusaka, Link Pharmacy Makeni Mall Lusaka,  Link Pharmacy Cosmopolitan Mall Lusaka, Link Pharmacy Garden City Mall Lusaka,  Link Pharmacy Mukuba Mall Kitwe,  Link Pharmacy kafubu Mall Ndola,  Link Pharmacy Livingstone branch,  Link Pharmacy Woodlands Mall Lusaka, Link Pharmacy Arcades Mall Lusaka, Link Pharmacy Pinnacle Mall Lusaka.",
  storeDescription: "Link Pharmacy online store, order your medicines anywhere anytime in zambia.",
};
export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];
export const titleOptions = [
  { label: "Mr.", value: "Mrs." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Ms.", value: "Ms." },
  { label: "Dr.", value: "Dr." },
];
// CAROUSELS
export const carouselResponsiveness = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  smallerTablet: {
    breakpoint: { max: 979, min: 769 },
    items: 2,
  },

  largeMobile: {
    breakpoint: { max: 768, min: 465 },
    items: 2,
  },

  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export const insuranceCards = [
  {
    url: "https://www.aetna.com/",
    img: Aetna,
    imgAlt: "Aetna",
  },
  {
    url: "https://www.bupa.com/",
    img: Bupa,
    imgAlt: "Bupa",
  },
  {
    url: "https://public.cignaenvoy.com/ciebpublic/home.htm",
    img: Cigna,
    imgAlt: "Cigna",
  },
  {
    url: "http://www.continuumplan.com/",
    img: Continuum,
    imgAlt: "Continuum",
  },
  {
    url: "https://www.discovery.co.za/portal/index.jsp",
    img: Discovery,
    imgAlt: "Discovery",
  },
  {
    url: "https://www.generaliglobalhealth.com/",
    img: GGH,
    imgAlt: "GeneralGlobalHealth",
  },
  {
    url: "https://www.liberty.co.za/pages/default.aspx",
    img: Liberty,
    imgAlt: "Liberty",
  },
  {
    url: "https://www.metlife.com/",
    img: Metlife,
    imgAlt: "Metlife",
  },
  {
    url: "https://www.msh-intl.com/en/europe/individuals/individuals-healthcare-insurance.html",
    img: MSH,
    imgAlt: "MSH",
  },
  {
    url: "https://www.mso.co.za/",
    img: MSO,
    imgAlt: "MSO",
  },
  {
    url: "https://www.optimumglobal.com/",
    img: OptimumGlobal,
    imgAlt: "OptimumGlobal",
  },
  {
    url: "http://www.prudential.co.zm/",
    img: Prudential,
    imgAlt: "Prudential",
  },
  {
    url: "https://www.ses-unisure.com/",
    img: SES,
    imgAlt: "SES",
  },
  {
    url: "http://www.continuumplan.com/",
    img: UHC,
    imgAlt: "UnitedHealthCare",
  },
  {
    url: "https://www.zsiclife.co.zm/",
    img: ZSIC,
    imgAlt: "ZSIC",
  },
];
export const categoryCards = [
  { img: BabyAndKids, imgAlt: "Baby and Kids", desc: "Baby and Kids" },
  { img: Beauty, imgAlt: "Beauty", desc: "Beauty" },
  {
    img: ContactAndGlasses,
    imgAlt: "Contact And Glasses",
    desc: "Contact And Glasses",
  },
  {
    img: FitnessAndNutrition,
    imgAlt: "Fitness And Nutrition",
    desc: "Fitness And Nutrition",
  },
  { img: Grocery, imgAlt: "Grocery", desc: "Grocery" },
  {
    img: HomeHealthCareSolution,
    imgAlt: "Home Health Care Solutions",
    desc: "Home Health Care Solutions",
  },
  {
    img: HouseHold,
    imgAlt: "HouseHold",
    desc: "HouseHold",
  },
  {
    img: MedicinesAndTreatment,
    imgAlt: "Medicines & Treatments",
    desc: "Medicines & Treatments",
  },
  {
    img: PersonalCare,
    imgAlt: "Personal Care",
    desc: "Personal Care",
  },
  {
    img: Seasonal,
    imgAlt: "Seasonal",
    desc: "Seasonal",
  },
  {
    img: SexualWellness,
    imgAlt: "Sexuall Wellness",
    desc: "Sexuall Wellness",
  },
  {
    img: Veterinary,
    imgAlt: "Veterinary",
    desc: "Veterinary",
  },
  {
    img: VitaminsAndSupplements,
    imgAlt: "Vitamins & Supplements",
    desc: "Vitamins & Supplements",
  },
];
// SIDEBAR ITEMS
export const accountSidebarItems = [
  {
    name: "My Orders",
    icon: <FaUserAlt className="mr-4 text-xl text-link-green" />,
    link: "my-orders",
  },
  {
    name: "Buy Again",
    icon: <FaList className="mr-4 text-xl text-link-green" />,
    link: "buy-again",
  },
  {
    name: "Shopping List",
    icon: <FaList className="mr-4 text-xl text-link-green" />,
    link: "shopping-list",
  },
  {
    name: "My Details",
    icon: <FaAddressCard className="mr-4 text-xl text-link-green" />,
    link: "my-details",
  },
  {
    name: "Change Password",
    icon: <FaLock className="mr-4 text-xl text-link-green" />,
    link: "change-password",
  },
  {
    name: "Address book",
    icon: <FaAddressBook className="mr-4 text-xl text-link-green" />,
    link: "address-book",
  },
  // {
  //   name: "Payments methods",
  //   icon: <FaCreditCard className="mr-4 text-xl text-link-green" />,
  //   link: "payments-methods",
  // },
  // {
  //   name: "Gift Cards & Vouchers",
  //   icon: <MdRedeem className="mr-4 text-xl text-link-green" />,
  //   link: "gift-cards",
  // },
];
export const accountSidebarSubItems = [
  {
    name: "myHealth Insurance",
    link: "my-health-insurance",
  },
  {
    name: "My Prescriptions",
    link: "prescription-history",
  },
  {
    name: "Pack my Meds",
    link: "pack-my-meds",
  },
  {
    name: "Refills & Auto-refills",
    link: "refills-and-auto-refills",
  },
  {
    name: "Request medical records",
    link: "request-medical-records",
  },
];
// DISCLOSURES
export const medicationCompoundingDisclosure = [
  {
    header: "About Medication Compounding",
    panel: (
      <>
        <p>
          Medication compounding is the pharmacy practice of combining pure chemicals to create one-of-a-kind
          medications specific to doctor-prescribed dosages and formulations. Staff members at our compounding
          pharmacies have extensive training to mix, prepare and package customized medications. Pharmacists may
          compound prescription medications to:
        </p>
        <ul>
          <li>
            Create patient-specific dosages to accommodate an in-between dose or to match a particular patient's weight
            and size
          </li>
          <li>Combine some multiple medications into a single dose</li>
          <li>Prescription delivery upon request, in select areas.</li>
          <li>
            Change the dosage form to one that's easier to take. For example, we can create a liquid suspension for a
            child who has trouble swallowing capsules or tablets
          </li>
          <li>
            Develop allergy-free formulations, change or remove inactive ingredients such as preservatives, dyes or
            lactose
          </li>
        </ul>
      </>
    ),
  },
  {
    header: "Compounded Dosage Forms",
    panel: (
      <>
        <p>
          Each compounded prescription comes with instructions that will help you properly take your medication. Dosage
          forms can be compounded for pain management, hormone replacement therapy, pets and more. Common dosage forms
          include:
        </p>
        <ul>
          <li>Capsules</li>
          <li>Creams, gels, lotions and ointments</li>
          <li>Liquid formulations such as suspensions and solutions</li>
          <li>Lollipops</li>
          <li>Lozenges</li>
          <li>Mouthwashes</li>
          <li>Suppositories</li>
        </ul>
      </>
    ),
  },
  {
    header: "Hormone Replacement Therapy",
    panel: (
      <>
        <p>
          We provide compounding services for women taking hormone replacement therapy to treat the symptoms of
          menopause and other conditions. The therapy involves compounding various hormones - such as estrogen,
          progesterone and androgen - into a convenient dosage form for the patient. These forms of medication are
          candidates for compounding, including:
        </p>
        <ul>
          <li>Capsules (regular and slow-release)</li>
          <li>Creams</li>
          <li>Liquids absorbed under the tongue</li>
          <li>Lozenges</li>
          <li>Vaginal creams and suppositories</li>
        </ul>
      </>
    ),
  },
  {
    header: "Pet Medication Compounding",
    panel: (
      <p>
        Unsavory tastes and unpleasant textures can hinder pets from taking their medications. We offer several
        customizable flavors that will surely appeal to your pet's taste buds.
      </p>
    ),
  },
  {
    header: "Compounding FAQ",
    panel: (
      <>
        <h2>Q: Why would your doctor prescribe a compounded medication?</h2>
        <p>
          A: Based on your specific health needs, your doctor determined creating a customized medication for you or
          loved one is the best treatment plan available. Some reasons may include:
        </p>
        <ul>
          <li>The medication prescribed is no longer commercially available</li>
          <li>You may be able to take a single dose of certain combined medications</li>
          <li>One dosage form may be easier to take (e.g. converting a tablet to a liquid)</li>
          <li>Changing inactive ingredients could help you avoid allergy issues</li>
          <li>Creating pediatric dosages and formulations that may not be readily available</li>
        </ul>

        <h2>Q: What type of medication formulas can be compounded?</h2>
        <p>
          A: All Link pharmacy locations can fill prescriptions that are considered "simple compounds" such as those
          requiring the addition of flavorings, or a combination of two or more liquid dosage forms. For example, we can
          compound medication that's easier to swallow, or easily applied to the skin or combined with a medicated
          mouthwash. Some of the more complex dosage forms include:
        </p>
        <ul>
          <li>Regular and slow-release capsules</li>
          <li>Creams, gels, lotions and ointments</li>
          <li>Lollipops/lozenges</li>
          <li>Suppositories</li>
          <li>Suspensions</li>
        </ul>
        <h2>Q: Why compound my medication at Link pharmacy?</h2>
        <p>
          A: Each of our compounding pharmacy locations feature dedicated staff with extensive training in order to mix,
          prepare and package customized compounded medications. Guidance is provided, along with instructions that will
          help you properly take your medication. All of our compounding recipes are fully researched, standardized,
          referenced and stored in our central database. This means all Link pharmacy compounding locations have access
          to the same recipes, ensuring accurate and consistent medication compounding.
        </p>
      </>
    ),
  },
];
export const specialtyPharmacyDisclosure = [
  {
    header: "Cancer",
    panel: (
      <>
        <p>We can provide you:</p>
        <ul>
          <li>Access to a broad range of medications, including drugs that can be difficult to find</li>
          <li>
            Specially-trained pharmacists who are available to help you understand your medication and monitor potential
            side effects
          </li>
          <li>Financial assistance coordination to help reduce the costs of your medication</li>
          <li>Support on your fertility preservation journey with comprehensive pharmacy services.</li>
        </ul>
      </>
    ),
  },
  {
    header: "Crohn's Disease & Ulcerative Colitis",
    panel: (
      <>
        <p>We can provide you:</p>
        <ul>
          <li>Insurance verification and benefit coordination for peace of mind</li>
          <li>Personalized adherence counseling and side effect management</li>
          <li>Injection training at our community-based specialty pharmacies</li>
        </ul>
      </>
    ),
  },
  {
    header: "Cystic Fibrosis",
    panel: (
      <>
        <p>We can provide you:</p>
        <ul>
          <li>Immediate access to a full line of CF medications, including exclusive drugs and therapies</li>
          <li>Experienced pharmacists trained in helping those living with CF and their families</li>
          <li>Case managers who can assist with insurance issues and available patient assistance programs</li>
          <li>
            An extensive online resource library, including content approved by the CF Foundation Education Committee
          </li>
        </ul>
      </>
    ),
  },
  {
    header: "Hepatitis C",
    panel: (
      <>
        <p>We can provide you:</p>
        <ul>
          <li>Monitoring of your response to therapy and providing updates to your provider</li>
          <li>Financial assistance coordination to help reduce the costs of your medication</li>
          <li>Personalized adherence counseling and side effect management</li>
        </ul>
      </>
    ),
  },
  {
    header: "HIV",
    panel: (
      <>
        <p>We can provide you:</p>
        <ul>
          <li>Hundreds of HIV-specialized pharmacies offering face-to-face care for people living with HIV</li>
          <li>Refill reminders and medication adherence support</li>
          <li>Refill synchronization to make it easier for you to pick up all your medications at the same time</li>
          <li>Ways to help you make your specialty medication more affordable</li>
        </ul>
      </>
    ),
  },
  {
    header: "Fertility",
    panel: (
      <>
        <p>We can provide you:</p>
        <ul>
          <li>
            An initial call with a fertility nurse to help you understand your first order and the purpose of each item
          </li>
          <li>Free needles and syringes with each prescription</li>
          <li>Expert knowledge of fertility coverage and benefits</li>
          <li>
            Support for those with cancer on their fertility preservation journey with comprehensive pharmacy services.
          </li>
          <li>Same-day emergency prescriptions.</li>
        </ul>
      </>
    ),
  },
  {
    header: "Multiple Sclerosis (MS)",
    panel: (
      <>
        <p>We can provide you:</p>
        <ul>
          <li>Care Teams that will help make sure you understand both your condition and your medication</li>
          <li>Adherence support that you need to help stick with your treatment plan</li>
          <li>Side effect management and adherence support targeted to your needs</li>
        </ul>
      </>
    ),
  },
  {
    header: "Psoriasis & Psoriatic Arthritis",
    panel: (
      <>
        <p>We can provide you:</p>
        <ul>
          <li>Insurance verification and benefit coordination for peace of mind</li>
          <li>Personalized adherence counseling and side effect management</li>
          <li>Monitoring of potential drug interactions from other medication therapies.</li>
        </ul>
      </>
    ),
  },
  {
    header: "Rheumatoid Arthritis (RA)",
    panel: (
      <>
        <p>We can provide you:</p>
        <ul>
          <li>Coordination with your insurance company to finalize paperwork before you start treatment</li>
          <li>Personalized adherence counseling and side effect management</li>
          <li>Injection training at our community-based specialty pharmacies</li>
        </ul>
      </>
    ),
  },
  {
    header: "Transplant",
    panel: (
      <>
        <p>We can provide you:</p>
        <ul>
          <li>Personalized adherence counseling and side effect management</li>
          <li>Post-discharge outreach from one of our specially trained pharmacists</li>
          <li>Insurance and benefit coordination for peace of mind</li>
        </ul>
      </>
    ),
  },
  {
    header: "Vew all",
    panel: (
      <>
        <div>
          <ul>
            <li>Anemia/neutropenia</li>
            <li>Asthma</li>
            <li>Cancer</li>
            <li>Crohn's disease</li>
            <li>Cystic fibrosis</li>
            <li>Enzyme deficiencies</li>
            <li>Growth hormone deficiency</li>
            <li>Hemophilia</li>
            <li>Hepatitis C</li>
            <li>HIV</li>
            <li>Immune deficiency/IVG</li>
            <li>Infertility</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>Macular degeneration</li>
            <li>Multiple Sclerosis</li>
            <li>Osteoarthritis</li>
            <li>Psoriasis</li>
            <li>Psoriatic arthritis</li>
            <li>Pulmonary arterial hypertension</li>
            <li>Respiratory syncytial virus</li>
            <li>Rheumatoid arthritis</li>
            <li>Transplant</li>
            <li>Urology</li>
            <li>Ulcerative Colitis</li>
          </ul>
        </div>
      </>
    ),
  },
];
export const programDetailsDisclosure = [
  {
    header: "What is myHealth rewards",
    panel: (
      <p>
        You can now join myHealth by signing up at linkpharmacy.co.zm, in the Link pharmacy App, or in our stores at
        checkout. It’s fast and easy
      </p>
    ),
  },
  {
    header: "Will my Balance® Rewards membership automatically transfer to myHealth rewards or do I need to sign up?",
    panel: (
      <p>
        To help ensure your information is accurate and protect your privacy, your Balance Rewards membership does not
        automatically transfer, but signing up for myHealth is free and easy! Plus, you can keep your benefits and keep
        earning rewards as long as you sign up by January 31, 2021.
      </p>
    ),
  },
  {
    header: "How does myHealth rewards benefit customers and patients?",
    panel: (
      <>
        <p>myHealth makes saving, shopping and your well-being easier. Join free and enjoy the following benefits:</p>
        <ul>
          <li>
            Save money by unlocking sale prices and earn unlimited Link pharmacy Cash rewards storewide on eligible
            purchases, even at the pharmacy
          </li>
          <li>Get local health and environmental forecasts with the Link pharmacy App to help you stay safe</li>
          <li>Earn bonus rewards by achieving health goals</li>
          <li>Give back to communities you care about by donating your rewards</li>
        </ul>
      </>
    ),
  },
  {
    header: "Can you earn points with myHealth rewards?",
    panel: (
      <p>
        We heard your feedback and simplified our rewards from Balance® Rewards points to Link pharmacy Cash rewards, to
        make earning and tracking rewards easier for you.
      </p>
    ),
  },
  {
    header: "What’s the difference between myHealt rewards and Balance® Rewards?",
    panel: (
      <p>
        myHealth is more than a rewards program—it makes saving, shopping and your well-being easier. As a member,
        you’ll enjoy a truly customized experience when you join, including personalized deals, product recommendations,
        well-being benefits and more ways to get time back. From prescription refills to curbside pickup, our free app
        makes it faster and easier.
      </p>
    ),
  },
  {
    header: "How much does it cost to join myHealth rewards?",
    panel: <p>myHealth is free to join!</p>,
  },
  {
    header: "Can anyone sign up for myHealth rewards?",
    panel: (
      <p>
        myHealth is available residents at least 16 years old, and it’s free to join! Members between 16 and 18 years
        old (or between 16 and 21 years of age in Puerto Rico) must get permission from their parent or legal guardian
        first.
      </p>
    ),
  },
  {
    header: "What is required to become a myHealth rewards member?",
    panel: (
      <p>
        Your first name, last name, ZIP code, and phone number are needed to join myHealth. Providing this basic
        information allows you to unlock sale prices in store and start earning Link pharmacy cash rewards that are easy
        to redeem at checkout.
      </p>
    ),
  },
  {
    header: "What do I need to redeem my Link pharmacy Cash rewards?",
    panel: (
      <p>
        To access the full program benefits of myHealth rewards, including redeeming Link pharmacy Cash rewards and
        receiving personalized deals, an email address is required. You can easily add an email address to your online
        profile or in store at checkout.
      </p>
    ),
  },
  {
    header: "What happens if I’m a Balance® Rewards member and I don't sign up for myHealth rewards?",
    panel: (
      <p>
        You’ll lose access to storewide savings, only-for-you deals and product recommendations among other things if
        you don't sign up for myHealth by January 31, 2021; in addition, your Balance Rewards account will be
        deactivated and you'll lose your existing rewards balance.
      </p>
    ),
  },
  {
    header: "What if I don’t want to provide my email address?",
    panel: (
      <p>
        No problem! You can still unlock sale price and earn Link pharmacy Cash rewards by providing your phone number,
        ZIP code and name. Then if you decide to add your email address later, you can start redeeming the rewards
        you’ve been earning.
      </p>
    ),
  },
  {
    header: "Will there be a myHealth rewards card?",
    panel: (
      <p>
        myHealth does not require a membership card, so you can earn and redeem rewards with less contact. That makes
        checkout safer for you and our team members, to help everyone stay healthy. It’s also more environmentally
        friendly, because we care about our communities.
      </p>
    ),
  },
];
export const helpAccountRegistrationDisclosure = [
  {
    header: "Q: Why should I register?",
    panel: (
      <>
        <p>
          A: Registering on our site gives you access to a wide variety of Link pharmacy services that will simplify
          your shopping experience and make it more enjoyable. You can check your order history, take advantage of photo
          services and, of course, manage your prescriptions.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I register?",
    panel: (
      <>
        <p>
          A: Follow the <a href="/registration">Create Your Account link</a>. Fill in your first and last name, your
          email address that you will use to sign in to linkpharmacy.co.zm, and a password of your choosing. If you
          would like to receive emails from Link pharmacy about weekly sales and promotions, leave the box under{" "}
          <b>Email</b> Preferences checked. Click the blue Continue button to submit your information. You will receive
          a confirmation that says "Congratulations! Your account has been created."
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I pick a password?",
    panel: (
      <p>
        A: Passwords must be at least eight characters long (cAsE sEnSiTiVe) and must include one number. Passwords may
        also include letters, numbers and other symbols such as !, #, $, %, etc. The most secure passwords combine all
        three different character types.
      </p>
    ),
  },
  {
    header: "Q: Are there requirements for my linkpharmacy.co.zm password?",
    panel: (
      <>
        <p>A: Yes, when you create your account or reset your password it must meet the following requirements:</p>
        <ul>
          <li>Minimum of 8 characters.</li>
          <li>
            Hover over <b>Your Account</b> and select <b>Product Orders.</b>
          </li>
          <li>
            Must contain alphabetic and numeric characters. This requirement is waived if password is 16 or more
            characters.
          </li>
          <li>Must be different than your username, first name, last name, or email address.</li>
          <li>Cannot contain patterns or common keyboard combinations like abcd1234, qwertyuiop1, asdf1234, etc.</li>
          <li>
            Cannot contain a commonly used password like password, letmein, etc. Cannot be the same as any previous
            password.
          </li>
        </ul>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can you give me an example of a typical password?",
    panel: (
      <>
        <p>
          A: The most secure passwords consist of letters, numbers and symbols such as: !, #, $, %, etc. For example,
          SaveBig$10!
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Do I have to be a registered customer to shop linkpharmacy.co.zm?",
    panel: (
      <>
        <p>
          A: No, you don't have to be registered. You can skip registration and proceed directly to checkout, but you
          will not be able to track or save your order. To purchase items without registering, simply select{" "}
          <b>Continue</b> under <b>Guest Checkout</b> on the Checkout page.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header:
      "Q: Why do I need to provide personal information in order to register as a pharmacy customer on linkpharmacy.co.zm?",
    panel: (
      <>
        <p>
          A: When you register as a pharmacy customer, we ask you to provide us with limited personal and health-related
          information. This helps us process your order and check for interactions between your prescriptions and health
          history. Once saved, this information will be secure, accessed only by you and authorized Link pharmacy
          personnel.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why do you need my date of birth and gender?",
    panel: (
      <>
        <p>A: Your date of birth and gender is required to verify your account.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why do you ask for my phone number?",
    panel: (
      <>
        <p>A: Your phone number helps us further verify your account.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why do you ask for my security question?",
    panel: (
      <>
        <p>
          A: Your security question and answer are required so that we can verify your identity if you forget your user
          name and/or password.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What if I forget the answer to my security question?",
    panel: (
      <>
        <p>
          A: If you forget the answer to your security question, you will receive three opportunities to answer it
          correctly and retain your prescription information. After three unsuccessful attempts, you will be prompted to
          enter a new password and security question. Then, you will be asked to re-verify your account.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can others view my personal information?",
    panel: (
      <>
        <p>
          A: All your information, including prescription, health and credit card transactions, will be securely stored
          and can only be accessed by you and authorized Link pharmacy personnel.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I create an account for my entire family?",
    panel: (
      <>
        <p>
          A: Yes. A Family Prescriptions account features an administrator who manages the accounts of linked family
          members or others under your care. With Family Prescriptions, you can order new prescriptions, refill existing
          prescriptions or transfer prescriptions. For information on creating and managing a Family Prescriptions
          account, follow Help: Family Prescriptions.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpAccountSigninDisclosure = [
  {
    header: "Q: What if I forget my password, username or email address?",
    panel: (
      <>
        <p>A: If you have forgotten your password:</p>
        <ol>
          <li>
            After you enter your username you will be taken to the Enter Password page, click{" "}
            <b>Forgot your password?</b> under the Sign In box. You will be taken to the Reset Password page.
          </li>
          <li>
            Enter your username and click <b>Continue</b> to reset your password. You will see a confirmation on the
            screen that an email has been sent to you.
          </li>
          <li>
            Check your email. You should have received an email from linkparmacy.co.zm with the subject "Reset Password
            Request." If you do not receive the password reset email, check your email's Spam or Junk folder.
          </li>
          <li>Click the link in the email to Create a new password. This will bring you to the Reset Password page.</li>
          <li>
            <b>Create a new password</b> and type it into the boxes.
          </li>
          <li>
            Click <b>Confirm</b> to set your new password.
          </li>
        </ol>
        <p>
          <b>NOTE:</b> When updating your password, you may be asked to answer your security question or create a new
          security question and answer. If you are prompted to answer an existing security question, type the answer to
          your security question in the Security Answer box and click Continue. If you are prompted to choose a new
          security question, select a question from the drop-down menu, type your answer in the text box, and click
          "Update."
        </p>
        <p>
          <b>If you have forgotten your username or email address:</b>
        </p>
        <p>
          - If you registered for a linkpharmacy.co.zm account on July 15, 2010 or after, your email address is your
          username.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Can I return prescription",
    panel: (
      <>
        <p>
          By law, we cannot accept returns of prescription products for reuse or resale. However, if you feel we have
          made an error in filling the prescription, please <a href="/#">contact the store</a> where you received your
          prescription
        </p>
        <p>- If you were a Walgreens customer prior to July 15, 2010:</p>
        <ol>
          <li>
            On the Sign In or Register page, click Forgot your username? under the Username box. You will be taken to
            the Retrieve Username page.
          </li>
          <li>
            Enter your email address in the text box and click Submit. An email with your username will be sent to you
            with the subject "Retrieve Username."
          </li>
        </ol>
        <p>
          <b>NOTE:</b> If you do not receive the Retrieve Username email, please check your email's Spam or Junk folder.
        </p>
        <p>
          - If you have forgotten the email address associated with your account, please contact Customer Service
          toll-free at +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What happens if I try the wrong password too many times?",
    panel: (
      <>
        <p>
          A: If you enter an incorrect password more than six (6) times your account will be locked for 15 minutes as a
          security precaution. Once your account is locked, you will have the option to reset your password by either
          requesting a link to reset your password or calling Customer Care at +260 211 255 556
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I unlock my account?",
    panel: (
      <>
        <p>A: Once your account is locked, you have three options to gain access again:</p>
        <ol>
          <li>
            If you think you have remembered your password you can wait 15 minutes until it unlocks and try again.
          </li>
          <li>You can request a link to reset your password.</li>
          <li>You can call customer care at +260 211 255 556.</li>
        </ol>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Where can I sign out?",
    panel: (
      <>
        <p>
          A: Follow the link <a href="/#">Sign Out</a>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why does Link pharmacy want me to use my email address to sign in?",
    panel: (
      <>
        <p>
          A: Custom usernames are often easily forgotten and different for each website someone might use. Using an
          email address to sign in provides an easy-to-remember and unique identifier for website users. If you created
          your account before July 15, 2010 and registered using a username that is not a unique email address, you will
          be asked over the next few months to convert your username to an email address
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What does the Remember Username box do?",
    panel: (
      <>
        <p>
          A: Checking the Remember Username box will store your linkpharmacy.co.zm username so you do not have to enter
          it every time you want to sign in. For security purposes, the linkpharmacy.co.zm sign in page does not store
          your password.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: When I enter my username and password, I am prompted to enter them again. Why?",
    panel: (
      <>
        <p>
          A: Our site requires that the user's web browser accepts cookies. If your browser is set to refuse cookies, or
          if you have disabled them, you will be asked to sign in every time you click a link that requires registration
          on our site. To avoid this, you should enable cookies on your browser so that our site recognizes you
          throughout your visit.
        </p>
        <p>
          About Cookies: Cookies are small files that your browser places on your computer's hard drive. They enable our
          site to know whether you are a returning customer and if you found linkpharmacy.co.zm via another site or
          advertisement. The cookies help us personalize your shopping experience and save you from re-entering
          information each time you visit. Cookies do not enable us to determine the identity of any visitor to our web
          site. We cannot identify you unless you specifically tell us who you are. Most Internet browsers permit you to
          erase or block cookies.
        </p>
        <p>
          The instructions to change the cookie setting are different for every browser and browser version. Please
          refer to the Help menu of your browser for further instructions. If this doesn't resolve your issue, please
          call Customer Service at +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpYourAccountDisclosure = [
  {
    header: "Q: How can I update my email address and other personal information?",
    panel: (
      <>
        <p>A: To update your email address and other personal information:</p>
        <ol>
          <li>
            Follow the link <a href="/#">your Account</a> go to the <b>Account Home</b> page. If you are not signed in,
            you will be prompted to do so.
          </li>
          <li>
            Hover over the <b>Personal Settings</b> tab and select <b>Personal Information</b> from the drop-down menu.
          </li>
          <li>
            Click the blue <b>Edit</b> button in the gray box corresponding to the information you would like to change.
          </li>
          <li>
            When you have finished updating your information, click the blue Save Changes button. At the top of the
            module, you will see a message in a bright green box: Changes to your personal information were successfully
            saved. If you've updated your email address and/or username, you will also receive an email confirming the
            chang
          </li>
        </ol>
        <p>
          We will accept returns of any unopened, unexpired and undamaged boxes of in-stock contact lenses in the
          original packaging sold through linkpharmacy.co.zm.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I change my password?",
    panel: (
      <>
        <p>A: To change your account password:</p>
        <ol>
          <li>
            Follow the link Your <a href="sign_in">Account</a> to go to the <b>Account Home</b> page. If you are not
            signed in, you will be prompted to do so.
          </li>
          <li>
            Hover over the Personal Settings tab and select <p>Personal Information</p> from the drop down menu.
          </li>
          <li>
            Click the blue Edit button in the box labeled Sign In & Security Information. You will be prompted to
            confirm your password
          </li>
          <li>Enter your current password and click Confirm to go to the Sign In and Security Information page.</li>
          <li>
            Type the new password you would like to use in the field marked Password, then re-type the new password
            exactly the same in the field marked Re-type Password.
          </li>
          <li>
            When you have finished updating your information, click the blue Save Changes button. At the top of the
            module, you will see a message in a bright green box: You have successfully updated your Sign In and
            Security Information.
          </li>
        </ol>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: ">Q: My marital status has changed. How do I update my name?",
    panel: (
      <>
        <p>
          A: To update your name, please call Customer Service, toll-free, at +260 211 255 556. For security purposes, a
          name change cannot be done online.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I edit my communication preferences?",
    panel: (
      <>
        <p>A: To edit your communication preferences:</p>
        <ol>
          <li>
            Follow the link <a href="/sign_in">Your Account</a> go to your <b>Account Home</b> page.
          </li>
          <li>
            Hover over the Personal Settings tab and select Communication Preferences from the drop down menu. You will
            be directed to the Manage Communication Preferences page. Here, you can edit your text message alerts,
            automated phone calls, email reminders, email subscriptions, and your personal reminders.
          </li>
          <li>
            Next to each section on this page, you will see either a bright blue Edit, Sign Up or Add Personal Reminder
            button. Click this button to edit the information in that section.
          </li>
          <li>
            Click the blue Save Changes button when you have made your changes. You will see a confirmation message that
            you've updated your communication preferences.
          </li>
        </ol>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I edit my email subscriptions?",
    panel: (
      <>
        <p>A: To edit email subscription options:</p>
        <ol>
          <li>
            Follow the link <a href="/sign_in">Your Account</a> go to your <b>Account Home</b> page.
          </li>
          <li>
            Hover over the Personal Settings tab and select Communication Preferences from the drop down menu. You will
            be directed to the Manage Communication Preferences page.
          </li>
          <li>
            Click the blue Edit button in the Email Subscriptions section. You will be taken to the Edit Your Email
            Preferences page.
          </li>
          <li>
            To subscribe to an email, check the corresponding box. To stop receiving an email, uncheck the corresponding
            box.
          </li>
          <li>
            Click the blue Save Changes button when you have made your changes. You will see a confirmation message that
            you've successfully updated your email preferences
          </li>
        </ol>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why does my pharmacy email not include details about my prescriptions?",
    panel: (
      <>
        <p>
          A: For your security, by default, prescription refill and pharmacy transactional emails do not include
          prescription names, numbers, or dosage information.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I see my prescription information in my pharmacy emails?",
    panel: (
      <>
        <p>A: To opt-in to seeing this information in your pharmacy emails:</p>
        <ol>
          <li>
            Follow the link Your <a href="/sign_in">Account</a> to go to your Account Home page.
          </li>
          <li>
            Hover over the Personal Settings tab and select Communication Preferences from the drop down menu. You will
            be directed to the Manage Communication Preferences page
          </li>
          <li>
            Scroll down to the Your Pharmacy Preferences section and click the Update the way this information is shown
            link.
          </li>
          <li>
            Click the button next to Include my prescription name, number and dosage information in all pharmacy-related
            transactional emails link.
          </li>
          <li>
            Click the blue Save Changes button when you have made your changes. You will see a confirmation message that
            you've successfully updated your preferences.
          </li>
        </ol>
        <p>
          <b>NOTE: At this time, the opt-in function is not available for Duane Reade nor Mail Service.</b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I edit my text message preferences?",
    panel: (
      <>
        <p>A: To edit text message preferences:</p>
        <ol>
          <li>
            Follow the link Your <a href="/login">Account</a> to go to your Account Home page.
          </li>
          <li>
            Hover over the Personal Settings tab and select Communication Preferences from the drop down menu. You will
            be directed to the Manage Communication Preferences page.
          </li>
          <li>
            Click the blue Edit button in the Text Message section. You will be taken to the Edit Text Message
            Preferences page.
          </li>
          <p>
            <b>NOTE:</b> If you have not already confirmed your account registration by providing your address, date of
            birth, and phone number, you will be directed to do so before proceeding.
          </p>
          <li>
            To sign up for Rx alert text messages, select Yes under the question Would you like to receive text message
            Rx Alerts? If you would prefer not to receive text messages, select No.
          </li>
          <li>Click the blue Save Changes button when you have made your changes.</li>
          <li>You will see a confirmation message that you've successfully updated your text message preferences</li>
        </ol>
        <p>
          NOTE: If you have not made any changes, you will not receive a confirmation message. Click Cancel to return to
          the Communication Preferences page.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I add a prescription refill reminder to my account?",
    panel: (
      <>
        <p>A: To add a prescription refill reminder:</p>
        <ol>
          <li>
            Follow the link <a href="/#">Your Account</a> to go to your Account Home page.
          </li>
          <li>
            Hover over the Personal Settings tab and select Communication Preferences from the drop down menu. You will
            be directed to the Manage Communication Preferences page.
          </li>
          <li>
            Click the blue Sign Up button in the Prescription Refill Reminders section. You will be taken to theEmail
            Reminder Confirmation page.
          </li>
          <p>
            NOTE: If you have not already confirmed your account registration by providing your address, date of birth,
            and phone number, you will be directed to do so before proceeding.
          </p>
          <li>
            Review the information on the screen. If this is correct, click the blue Submit button to sign up for
            reminders.
          </li>
          <li>You will see a confirmation message that you will now receive prescription refill reminders.</li>
        </ol>
        <p>
          To stop receiving prescription refill reminder emails, click the Stop Prescription Refill Reminder Emails link
          on the Communication Preferences page.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I edit my automated phone alerts?",
    panel: (
      <>
        <p>A: To edit automated phone alert settings:</p>
        <ol>
          <li>
            Follow the link <a href="/#">Your Account</a> to go to your Account Home page.
          </li>
          <li>
            Hover over the Personal Settings tab and select Communication Preferences from the drop down menu. You will
            be directed to the Manage Communication Preferences page.
          </li>
          <li>
            Locate the Automated Phone Alerts section. To sign up for automated phone alerts, select Yes next to the
            question Would you like to receive automated phone call alerts? If you would prefer not to receive automated
            phone alerts, select No.
          </li>
          <p>
            NOTE: This applies to automated phone alerts only. Your Walgreens pharmacist may need to contact you with
            other prescription-related questions. To add or change the phone number associated with your account, click
            the Manage your phone number(s) link.
          </p>
          <li>Click the blue Save button when you have made your changes.</li>
          <li>You will see a confirmation message that you've successfully updated your phone alert preferences.</li>
        </ol>
        <p>NOTE: If you have not made any changes, you will not receive a confirmation message.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpBalanceRewardsDisclosure = [
  {
    header: "Q: Do I need a physical card to earn and redeem points?",
    panel: (
      <>
        <p>
          A: No. To earn points online, sign in to your linkpharmacy.co.zm account. In store, provide your phone number,
          the mobile card, or present your physical card. If you joined Balance Rewards online, you will not be mailed a
          physical card. If you would like a physical card, visit your local link pharmacy or Duane Reade and ask a
          sales associate to link a physical card to your membership.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: I joined online and want a physical card. How do I get one?",
    panel: (
      <>
        <p>
          A: If you joined Balance Rewards online, you will not be mailed a physical card. If you would like a physical
          card, visit your local Link Pharmacy or Duane Reade and ask a sales associate to link a physical card to your
          membership.
        </p>
        <p>
          NOTE: You do not need a physical card to participate in Balance Rewards. To earn points online, sign in to
          your linkpharmacy.co.zm account. In store, provide your phone number, the mobile card, or present your
          physical card.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Will a physical card be mailed to me?",
    panel: (
      <>
        <p>
          A: If you joined Balance Rewards online, you will not be mailed a physical card. If you would like a physical
          card, visit your local Link pharmacy or Duane Reade and ask a sales associate to link a physical card to your
          membership.
        </p>
        <p>
          NOTE: You do not need a physical card to participate in Balance Rewards. To earn points online, sign in to
          your linkpharmacy.co.zm account. In store, provide your phone number, the mobile card, or present your
          physical card.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I get the mobile card?",
    panel: (
      <>
        <p>
          A: If you want to get the mobile card, you can download the Link pharmacy mobile app and link your Balance
          Rewards member number to it. You will then be able to use your mobile card to earn points and keep track of
          your points. To download the mobile app, follow Link pharmacy <a href="/#">Mobile Apps</a>.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I get a replacement card?",
    panel: (
      <>
        <p>
          A: If you've misplaced your Balance Rewards card and want a new one, visit any Link pharmacy or Duane Reade
          store and ask a sales associate. The sales associate can provide you with a new card and link it to your
          existing Balance Rewards membership.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpBalanceEligibilityDisclosure = [
  {
    header: "Q: How can I join Balance Rewards?",
    panel: (
      <>
        <p>
          A: You can join at any Link pharmacy store at the checkout counter, pharmacy, and photo kiosk. You can also
          join online at linkpharmacy.co.zm on your mobile phone via the Link pharmacy mobile app, or your iPad.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How much does it cost to join Balance Rewards?",
    panel: (
      <>
        <p>A: Membership in Balance Rewards is free</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why does Balance Rewards require my date of birth to join the program?",
    panel: (
      <>
        <p>
          A: You must be 13 years of age (14 years of age in Puerto Rico) or older to participate in Balance Rewards;
          your date of birth is required to validate that you are eligible for the program.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCenterReturnsProducts = [
  {
    header: "What is the Linkpharmacy.co.zm return policy?",
    panel: (
      <>
        <p>
          Most products purchased from Linkpharmacy.co.zm may be returned either to a store or by mail within 30 days of
          purchase date and must be accompanied by the original receipt included with the shipment. Your satisfaction is
          100% guaranteed on all Walgreens brand products! If you are not completely satisfied, return the unused
          portion and we will refund the full price, including shipping.
        </p>
        <p>
          If you wish to return an item classNameified as Hazmat, contact customer care at +260 976 255 556 for return
          shipping instructions.{" "}
          <b>Do not ship an item classNameified as Shipping-Restricted using the return shipping label.</b>
        </p>
        <p>
          <b>
            NOTE: This policy does not apply to prescriptions or contact lenses see <a href="/#">Returns: Pharmacy</a>{" "}
            and <a href="/#">Returns: Contact Lenses</a> for more information.
          </b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "What is Link Pharmacy in-store return policy?",
    panel: (
      <>
        <p>
          Items purchased at Link pharmacy may be returned to any of our stores within 30 days of purchase for exchange
          or refund. With original receipt, items will be returned for the full purchase price and refunds will be
          issued in the original method of payment. Without original receipt, items will be returned at the lowest
          advertised price and refunds will be issued as store credit to a W card. For any return, you will be asked for
          valid photo identification. We reserve the right to limit or refuse a refund. Gift cards, pre-paid cards and
          phone cards cannot be returned or exchanged (except where required by law).
        </p>
        <p>
          NOTE: This policy does not apply to prescriptions or contact lenses. See <a href="/#">Returns: Pharmacy</a>{" "}
          and <a href="/#">Returns: Contact Lenses</a> for more information.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "How can I return my online product order?",
    panel: (
      <>
        <p>
          If a product is an exception to our standard return policy, a notification will display with the item when
          processing your return label at <a href="/#">www.linkpharmacy.co.zm/returnlabel..</a> If the receipt lists no
          exceptions, you may follow one of these two standard return methods:
        </p>
        <ol>
          <li>
            <b>Return Items to a Link pharmacy Store</b>
          </li>
          <p>
            Bring the product, receipt, and payment method (if applicable) to any Link pharmacy store within 30 days of
            purchase date for immediate credit to your credit card account or by cash if you paid via check or PayPal.
            Taxes will be refunded at your local rate. Products returned without a receipt are subject to the approval
            of store management and will be refunded as a gift card. If you do not have the original receipt, please
            contact Customer Service toll-free, at +260 211 255 556.
          </p>
          <li>
            <b>Ship Items to a Return Center</b>
          </li>
          <p>
            You may mail the product(s) in the original packaging and original receipt to our return center. Please
            visit our returns site at <a href="/#">www.linkpharmacy.com/returnlabel.</a> to print a prepaid return label
            for your order. You will be prompted for your address, order number and reason for the return. Submissions
            without an order number will not be accepted.
          </p>
          <p>
            After completing the form, click "Submit" to be directed to a page with a printable, prepaid return label.
            Print the label, attach it to your package per the label instructions and drop it off at your nearest Post
            Office, drop box or give it to your Postal Carrier. Please keep a record of the tracking number. You will
            need to provide the tracking number when contacting customer service regarding the return. Please include
            the original receipt and circle the item(s) you are returning. We will issue a full refund of the product's
            purchase price and applicable taxes as a credit to your method of payment once we have received and
            processed the returned product(s). Please allow 30 days.
          </p>
        </ol>
        <p>
          <b>
            NOTE: SHIPPING-RESTRICTED ITEMS CANNOT BE RETURNED WITH THE SELF SERVICE RETURN SHIPPING LABEL. PLEASE
            CONTACT CUSTOMER SERVICE AT +260 211 255 556 FOR SPECIAL RETURN SHIPPING INSTRUCTIONS.
          </b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "How can I print a copy of my receipt?",
    panel: (
      <>
        <p>
          Orders placed through your Linkpharmacy.co.zm account can be found online on your Account Home page. Here you
          can view and print order details including a copy of your receipt or invoice by following the steps below:
        </p>
        <ol>
          <li>
            Go to <a href="https://www.linkphrmacy.co.zm"> https://www.linkphrmacy.co.zm</a> and log in to your account.
          </li>
          <li>
            Hover over <b>Your Account</b> and select <b>Product Orders.</b>
          </li>
          <li>Click on the order number of the receipt you would like to print.</li>
          <li>
            On the order details page, click on either the <b>Print</b> or <b>PDF</b> icons to print your receipt or
            save it as a PDF file.
          </li>
        </ol>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "How do I return a Shipping-Restricted item purchased from Linkpharmacy.co.zm?",
    panel: (
      <>
        <p>
          Shipping-Restricted items need to be returned via a special process. If a product is an exception to our
          standard return policy, a notification will display with the item when processing your return label at{" "}
          <a href="/#"> www.linkpharmacy.co.zm/returnlabel.</a> Please call Customer Care at +260 211 255 556 for
          assistance.
        </p>
        <p>
          <b>
            NOTE: SHIPPING-RESTRICTED ITEMS CANNOT BE RETURNED WITH THE SELF SERVICE RETURN SHIPPING LABEL. PLEASE
            CONTACT CUSTOMER SERVICE AT +260 211 255 556 FOR SPECIAL RETURN SHIPPING INSTRUCTIONS.
          </b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "How do I return an item or product that includes lithium batteries?",
    panel: (
      <>
        <p>Please call Customer Care at +260 211 255 556 for assistance.</p>
        <p>
          <b>
            NOTE: SHIPPING-RESTRICTED ITEMS CANNOT BE RETURNED WITH THE SELF SERVICE RETURN SHIPPING LABEL. PLEASE
            CONTACT CUSTOMER SERVICE AT +260 211 255 556 FOR SPECIAL RETURN SHIPPING INSTRUCTIONS.
          </b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "How can I track my return?",
    panel: (
      <>
        <p>
          You can track your return online at Track return opens in <a href="/#">www.linkpharmacy.co.zm/trackreturn</a>.
          Please note that you will need your order number to track your return
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "What if I need to return cosmetics or electronics?",
    panel: (
      <>
        <p>
          Returns for cosmetics or electronics are handled at the discretion of the store manager, usually in the form
          of a Linkpharmacy gift card.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "What if I can't print my prepaid return shipping label?",
    panel: (
      <>
        <p>
          If you don't have access to a printer or cannot print your return shipping label, please call Customer Service
          toll-free, at +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header:
      "I lost my receipt. Can I still return a product? What if I want to return a product beyond the 30-day window?",
    panel: (
      <>
        <p>
          First, check the product page for detailed return information. In-store returns will be handled at the
          discretion of the individual store manager. A return without a receipt may be refunded as an in-store credit
          on a linkpharmacy gift card.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "How do I obtain an exchange or replacement for my damaged product?",
    panel: (
      <>
        <p>
          If a product arrives damaged, please contact Customer Service directly by calling toll-free, +260 211 255 556.
          The product(s) will be replaced at no additional charge.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Are there any products I cannot return?",
    panel: (
      <>
        <p>
          We cannot accept returns of Birchbox BYOB (Build Your Own Box), sexual wellness or seasonal products. If you
          have any questions, call Linkpharmacy.co.zm toll-free, at +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Should I insure my returns?",
    panel: (
      <>
        <p>
          No, packages should only be returned using a prepaid return label generated by{" "}
          <a href="/#">www.linkpharmacy.co.zm/returnlabel</a>. If you have any questions, call linkpharmacy.co.zm toll
          free, at +260 211 255 556
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "How do I know if you received my return?",
    panel: (
      <>
        <p>The best way to track your return is to contact Customer Service toll-free, at +260 211 255 556.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCenterReturnsPharmacy = [
  {
    header: "Can I return shipped prescriptions?",
    panel: (
      <>
        <p>
          By law, we cannot accept returns of prescription products for reuse or resale. However, if you feel we have
          made an error in filling the prescription, please call Customer Service toll-free, at +260 211 255 556 with
          details of the error
        </p>
        <p>We will request special authorization for a return of the prescription</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Can I return prescriptions I picked up in a Link Pharmarcy store?",
    panel: (
      <>
        <p>
          By law, we cannot accept returns of prescription products for reuse or resale. However, if you feel we have
          made an error in filling the prescription, please <a href="/#">contact the store</a> where you received your
          prescription
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCenterReturnsLenses = [
  {
    header: "Can I return contact lenses?",
    panel: (
      <>
        <p>You may return contact lenses if:</p>
        <ul>
          <li>We sent the wrong lenses</li>
          <li>The lenses are damaged or defective</li>
          <li>You ordered an incorrect prescription.</li>
        </ul>
        <p>
          We will accept returns of any unopened, unexpired and undamaged boxes of in-stock contact lenses in the
          original packaging sold through linkpharmacy.co.zm.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "How do I return contact lens solutions, accessories, etc.?",
    panel: (
      <>
        <p>
          For products other than lenses that are sold in the Contact Lens Center, you can take them, along with the
          original packaging and receipt, to any Link pharmacy store within 30 days of your purchase. Taxes will be
          refunded at the local rate.
        </p>
        <p>Products without a receipt will be exchanged or refunded by mail within 14 days.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCheckoutProcess = [
  {
    header: "Q: How do I checkout?",
    panel: (
      <>
        <p>
          A: Once everything is in your Shopping Cart, select the bright blue Proceed to Checkout button at either the
          bottom or top of the right navigation column to begin the checkout process
        </p>
        <ol>
          <li>Complete the Shipping information page and click Continue checkout.</li>
          <li>Complete the Payment information and click Continue checkout.</li>
          <li>
            Review your order summary on the Review Order page. If everything is correct, click the blue Submit Order
            button at the bottom of the page
          </li>
        </ol>
        <p>
          Once your order goes through, you will see your Order Confirmation. You can print your receipt by clicking the
          blue Print Receipt button. Also, a copy of your receipt will be emailed to you.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I redeem credits or coupon codes?",
    panel: (
      <>
        <p>
          A: To redeem a coupon code for a special offer or discount, it must be entered at the time of checkout. On the
          Checkout page, enter the coupon code in the Have a special promotion or coupon code? box at the bottom of the
          left navigation column of your Shopping Cart page. Make sure to check the Apply link (in bright blue) next to
          the box before you select the Proceed to Checkout button in bright blue, near the bottom of the right
          navigation column. The discounted product price will be displayed at checkout and in your Order Summary below
          Promotions applied to your order. From time to time, you may receive a credit to your Photo Center account. If
          you do, we will automatically place it in your Photo Cart for you.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why did I receive a credit card error message during my checkout?",
    panel: (
      <p>
        A: The reason for the credit card error is a credit authorization failure by your financial institution. This
        may be due to one of the following reasons: insufficient funds, credit card expiration or the billing address
        you provided does not match the company (i.e. Visa or Master Card) files. Please contact your bank for further
        assistance.
      </p>
    ),
  },
  {
    header: "Q: My buy one, get one FREE products are not showing up at checkout. Why?",
    panel: (
      <>
        <p>
          a if the coupon codes are for the same product (e.g. one code for $20 off contacts and one code for 15% off
          contacts) and/or stacked on top of a site-wide promo (e.g. one code for 15% off Walgreens brand vitamins and
          one code for $10 off a $50 purchase), the shopping cart will not accept multiple coupon codes.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCheckoutProcessTax = [
  {
    header: "Q: Why must I pay tax on an online order?",
    panel: (
      <>
        <p>
          A: Sales taxes will be charged at the applicable rate based on the ship-to address, except for orders shipped
          to Illinois. For orders shipped to Illinois, internet sales tax is charged based on the point of order
          acceptance, by law. The linkpharmacy.co.zm point of acceptance is Mount Prospect, IL. The tax amount will
          appear on the order confirmation page
        </p>
        <p>
          <b>Please note that stores cannot offer tax exempt purchases for online orders.</b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Do you process tax exempt orders?",
    panel: (
      <>
        <p>
          A: We're sorry, but we cannot process tax exempt transactions online. If you are eligible for tax exempt
          purchases, please visit your local Link pharmacy.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCompanyInfoCareers = [
  {
    header: "Q: How do I view available job openings online?",
    panel: (
      <>
        <p>
          A: Click the <a href="/careers">Careers Link</a> at the bottom of any page on Linkpharmacy.co.zm. Here, you
          can view all job categories by clicking on the Career Areas Link along the top menu or selecting any of the
          categories from the menu along the bottom of the page. Once you select a category, you can search by location
          or job category and view a list of all positions available in your selected category or location and click on
          any position to view a detailed job description. If you are applying for a local position you may be asked for
          your zip code and other information to narrow your search to your local area.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I apply for available job openings online?",
    panel: (
      <>
        <p>
          A: After visiting the <a href="/careers">Careers</a> Link at the bottom of any page on Linkpharmacy.co.zm and
          making your job selection(s), you will see a candidate profile area and a Link to Join Our Talent Community
          where you can upload your resume or connect your LinkedIn profile. This is where you can enter your personal
          information and upload and submit a Microsoft Word version of your resume. Each job description will display
          an Apply Now button that will walk you through the steps of applying for a position online.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCompanyInfoLinkPharmacy = [
  {
    header: "Q: What is the Link Pharmacy Vision Statement?",
    panel: (
      <>
        <p>A: Link Pharmacy Vision Statement</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What are Link Pharmacy company values?",
    panel: (
      <>
        <p>
          A: After visiting the <a href="/careers">Careers</a> Link at the bottom of any page on Linkpharmacy.co.zm and
          making your job selection(s), you will see a candidate profile area and a Link to Join Our Talent Community
          where you can upload your resume or connect your LinkedIn profile. This is where you can enter your personal
          information and upload and submit a Microsoft Word version of your resume. Each job description will display
          an Apply Now button that will walk you through the steps of applying for a position online.
        </p>
        <p>
          A: At Link Pharmacy, our values are based on the same principles that the company was founded on more than a
          century ago:
        </p>
        <p>
          <i>Honesty, trust,</i> and <i>integrity</i> with our customers, our shareholders, suppliers,the communities we
          serve, and among ourselves.
        </p>
        <p>
          <i>Quality</i> through consistent and reliable service, advice, and products across every touchpoint and
          channel.
        </p>
        <p>
          <i>Caring, compassionate</i> and <i>driven</i> to delivering a great customer and patient experience through
          outstanding service and a desire for healthy outcomes.
        </p>
        <p>
          <i>A strong community commitment</i> and presence built through service, expertise, and the personal
          engagement of every Link pharmacy team team member.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCompanyInfoInvestors = [
  {
    header: "Q: Where does Link Pharmacy rank in its industry?",
    panel: (
      <>
        <p>A: Link Pharmacy leads the chain drugstore industry in sales and profits.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What are recent sales and earnings?",
    panel: (
      <>
        <p>
          A: Browse <a href="/#">Financial and Other Numbers</a> for the latest figures.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: When is Link Pharmacy' Annual Shareholders' Meeting?",
    panel: (
      <>
        <p>
          A: Browse <a href="/#">A: Link Pharmacy'</a> Annual Shareholders' Meeting is held each year on the second
          Wednesday in January.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: When does Link Pharmacy' fiscal year end?",
    panel: (
      <>
        <p>
          A: Browse <a href="/#">A: Our fiscal year ends on August 31 every year</a>.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is Link Pharmacy' credit rating?",
    panel: (
      <>
        <p>
          A: Browse <a href="/#">A: Go to</a> <a href="/#">Credit Rating</a> for the latest information from Moody's and
          Standard and Poor's.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I find out the Link Pharmacy Stock Quote online?",
    panel: (
      <>
        <p>
          A: To check the latest Link pharmacy Stock Quote, browse <a href="/#">Investor Relations</a> for up-to-date
          financial information.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is Link pharmacy stock split history?",
    panel: (
      <>
        <p>
          A: Browse <a href="/#">A: Link Pharmacy</a> has declared seven two-for-one stock splits since 1999. Follow
          Link Pharmacy <a href="/#">Stock Split</a> History.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is the recent dividend history for Link Pharmacy stock?",
    panel: (
      <>
        <p>
          A: Visit <a href="/#">Dividend History</a>.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I get more information on purchasing or re-investing Link Pharmacy stock dividends?",
    panel: (
      <>
        <p>
          A: A direct stock plan is offered through Wells Fargo Shareowner Services. For more information call +260 211
          255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Where can shareholders get more information?",
    panel: (
      <>
        <p>
          A: For other questions concerning investments at Link Pharmacy, contact <a href="/#">Investor Relations</a>.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCompanyInfoVentureInquiries = [
  {
    header: "Q: How can I become a vendor for Link Pharmacy?",
    panel: (
      <>
        <p>A: If you are interested in becoming a vendor for the Link Pharmacy, visit Vendor Information.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Where can vendors direct questions?",
    panel: (
      <>
        <p>
          A: If you are an established vendor with Link Pharmacy, please direct your questions to{" "}
          <a href="/#">Suppliernet</a>.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "",
    panel: <></>,
  },
  {
    header: "",
    panel: <></>,
  },
  {
    header: "",
    panel: <></>,
  },
];
export const helpCompanyInfoRealEstate = [
  {
    header: "Q: How big are your stores?",
    panel: (
      <>
        <p>
          A: The average size for a typical Link Pharmacy is about 14,500 square feet and the sales floor averages about
          11,000 square feet..
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How are locations for new stores selected?",
    panel: (
      <>
        <p>
          A: There are several factors that Link pharmacy takes into account, such as major intersections, traffic
          patterns, demographics and locations near hospitals. We prepare more than 100 pages of research for every site
          under construction. Contact Real Estate for more information.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Are there any surplus properties available?",
    panel: (
      <>
        <p>
          A: Browse <a href="/#">Surplus Properties</a> for more information.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCompanyInfoEmployees = [
  {
    header: "Q: I am a former Link Pharmacy team member. How can I download my L2 form?",
    panel: (
      <>
        <p>
          A:Please email your request to customercare@linkpharmacy.co.zm. Include your name, employee ID number and
          which year L2 you are requesting.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Where can vendors direct questions?",
    panel: (
      <>
        <p>
          A: If you are an established vendor with Link Pharmacy, please direct your questions to{" "}
          <a href="/#">Suppliernet</a>.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCouponPrintables = [
  {
    header: "Q: What guidelines does Link Pharmacy have for coupon usage?",
    panel: (
      <>
        <p>
          A: As a customer-focused retailer, Link Pharmacy encourages the use of coupons by our customers in our retail
          stores, in accordance with our Coupon Policy Guidelines. These guidelines include information from Sale Items,
          Multiple Coupons, Buy One, Get One Free Coupons, Internet/Print at Home Coupons, Earning Register RewardsTM,
          and Redeeming Register RewardsTM.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I use manufacturer's coupons for online purchases?",
    panel: (
      <>
        <p>
          A: Yes. Manufacturer coupons available for a product will be available to clip directly from the product page.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I use the coupons from the online Weekly Ad?",
    panel: (
      <p>
        A: Yes, but only for in-store purchases. To print store coupons, click on Printable Coupons in the footer of the
        Home Page. This will take you to a page where you can view or print store coupons. You can also click on the
        Store Coupon symbol that appears on a product page when a coupon is available for that product. Print and redeem
        them at your nearby Link Pharmacy store.
      </p>
    ),
  },
  {
    header: "Q: Can I use multiple coupon codes in one order?",
    panel: (
      <>
        <p>
          A: It depends. If the coupon codes you're applying are for different products (e.g. one code for 15% off
          fragrances and one code for $20 off contact lenses), then the shopping cart will accept multiple coupon codes.
          However, if the coupon codes are for the same product (e.g. one code for $20 off contacts and one code for 15%
          off contacts) and/or stacked on top of a site-wide promo (e.g. one code for 15% off Walgreens brand vitamins
          and one code for $10 off a $50 purchase), the shopping cart will not accept multiple coupon codes.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCouponsPrintablesPaperless = [
  {
    header: "Q: What are Paperless Coupons?",
    panel: (
      <>
        <p>A: If you have forgotten your password:</p>
        <p>
          A: Link Pharmacy Paperless Coupons are electronic coupons that you can clip directly to your Balance Rewards
          account. You can save offers directly to your Balance Rewards card from your mobile phone using the Link
          Pharmacy app. These digital coupons are automatically applied in-store, at checkout, when you use your Balance
          Rewards card, enter your phone number, or have the sales associate scan your phone through the Link Pharmacy
          mobile app.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Do I have to be a Balance Rewards member to take advantage of Paperless Coupons?",
    panel: (
      <>
        <p>
          A: Yes, you must have a Balance Rewards account in order to use Paperless Coupons. If you do not have a
          Balance Rewards account, you can sign up here.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCustomerStore = [
  {
    header: "Q: Whom do I contact about the customer service I received at  a Link Pharmacy store?",
    panel: (
      <>
        <p>
          A: For Link Pharmacy Customer Service feedback at a specific store, follow Contact Us and select the
          appropriate category under <b>Customer Service &gt; Store Service.</b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCustomerLink = [
  {
    header: "Q: Whom do I contact regarding website issues?",
    panel: (
      <>
        <p>
          A: Follow <a href="/contact">Contact Us</a> select the appropriate link under Customer Service Website
          Service.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCustomerEmail = [
  {
    header: "Q. How can I email Customer Service?",
    panel: (
      <>
        <p>
          A: The best way to contact Customer Service by email is to fill out a Contact Us form. Find your selected
          topic and it will be routed to the appropriate department automatically.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why don't you list an email address for Customer Service?",
    panel: (
      <>
        <p>
          A: In order to ensure the privacy, safety, and accurate delivery of your message, linkpharmacy.co.zm uses
          electronic text submission forms. When you fill out and submit a <a href="/contact">Contact Us</a> form, it is
          the same as sending an email.
        </p>
        <p>
          Some issues cannot be resolved via email. If this is the case, the Contact Us category page will instruct you
          to call Customer Service at +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How will I know if you get my email?",
    panel: (
      <>
        <p>
          A: After submitting the contact form, a confirmation page will display that reads, "Thank you for contacting
          us. We have received your comment or question and will send it to the appropriate department to review. If
          you've provided your contact information, a Link pharmacy Customer Service Representative will provide a
          response. We welcome your comments and feedback. However, please know that any message or other communication
          sent to linkpharmacy.co.zm becomes the exclusive property of linkpharmacy.co.zm and does not entitle the
          author to any form of compensation."
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I unsubscribe from Link Pharmacy emails?",
    panel: (
      <>
        <p>
          A: Follow <a href="/#">How can I edit my email subscriptions?</a>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCustomerPostalMail = [
  {
    header: "Q: What is Link Pharmacy mailing address?",
    panel: (
      <>
        <p>
          We welcome your comments and feedback. However, please remember any message or other communication sent to
          Link pharmacy becomes the exclusive property of Link pharmacy and does not entitle the author to any form of
          compensation.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCustomerPhone = [
  {
    header: "Q: I would rather talk to a Customer Service Representative on the phone. Is there a number I can call?",
    panel: (
      <>
        <p>A: Choose one of the following phone numbers depending on the nature of your question:</p>
        <ul>
          <li>linkpharmacy.co.zm Inquiries: +260 211 255 556;</li>
          <li>Store Inquiries: LINK PHARMACY or +260 211 255 556;</li>
          <li>Corporate Inquiries: +260 211 255 556;</li>
          <li>Customer Relations: +260 211 255 556, option 4. </li>
        </ul>
        <p>
          We welcome your comments and feedback. However, please remember any message or other communication sent to
          linkpharmacy.co.zm. becomes the exclusive intellectual property of linkpharmacy.co.zm. and does not entitle
          the author to any form of compensation.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCustomerChat = [
  {
    header: "Q: Can I chat online with a customer service agent?",
    panel: (
      <>
        <p>
          A: Yes, you can. Follow Your <a href="/#">Account</a> and click on the red "Need Help? Live Chat" button near
          the top of the page. Agents are available from 7 A.M. - 1 A.M. CAT.
        </p>
        <p>
          NOTE: If you do not see the "Need Help? Live Chat" button and it is between 7 A.M. - 1 A.M. CAT, all agents
          are busy. Wait briefly, then refresh the page. You do not need to be logged in to your Link Pharmacy account
          to chat - the chat button appears on the sign in page during the same hours.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpGiftCardsBulk = [
  {
    header: "Q: What denominations are available for bulk Link Pharmacy gift card purchases?",
    panel: (
      <>
        <p>A: Bulk Link Pharmacy gift cards are available in any amount between $5 and $500.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpGiftCards = [
  {
    header: "Q: What types of Link Pharmacy cards are available?",
    panel: (
      <>
        <p>
          A: Follow Link pharmacy Gift Cards for Gift Card information. Link Pharmacy Gift Cards can be redeemed
          in-store only. You may have also received a Link pharmacy Card for store credit for returned items. These can
          be redeemed in-store only.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpCustomerService = [
  {
    header: "Q: Whom do I contact about the customer service I received?",
    panel: (
      <>
        <p>
          A: To contact the store directly, follow Store Locator or click <a href="locations">Store Locator </a> in
          bright blue near the top right of each every linkpharmacy.co.zm page. Use the search feature to locate your
          store's phone number
        </p>
        <p>
          Or to electronically submit a comment, follow <a href="/contact">Contact Us</a> and choose Store Management,
          Store Employees, or Pharmacy Staff under Customer Service &gt; Store Service.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I contact my local Link Pharmacy Manager?",
    panel: (
      <>
        <p>
          A: You can reach your local Link Pharmacy Stores or Pharmacy Department Manager by calling your store
          directly. See the <a href="locations">Store Locator</a> to find your store's contact information. If you
          prefer to contact someone in a district office, please use the Contact Us &gt; District Operations.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Where can I find the contact information for Customer Service?",
    panel: (
      <>
        <p>
          A: To reach Customer Service about online related inquiries, please dial +260 211 255 556 (available 24
          hours). To call Customer Service about store related inquiries, please dial LINK PHARMACY (+260 211 255 556).
        </p>
        <p>
          To contact Customer Service electronically (through an email form), follow Contact Us and select the
          appropriate category for your question or comment.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpStoreLocator = [
  {
    header: "Q: How can I find a Link Pharmacy near me?",
    panel: (
      <>
        <p>
          A: Click on the Store Locator link located towards the upper-right corner of every page. Enter your zip code,
          city, state, or intersection and click Search or click the Browse by state link to the right of the Search
          button. Once you click Search, you can then narrow your results based on criteria you select such as 24 hour
          stores, stores with a drive-thru pharmacy, immunizations**, Red Box DVD Rentals**, Healthcare Clinic**, or
          medication compounding**, among others by clicking on the Refine Search link. The five closest Link Pharmacy
          storess will display - click &gt; to see more stores.
        </p>
        <p>
          By clicking Details next to a Link pharmacy address, you can view the store's hours, phone number, and
          drive-thru pharmacy availability. When you click the Directions link, directions in Google Maps from your
          location to the store will open in a new tab or window. You have the option to view and print out door-to-door
          driving directions to any store you select from the list. You can also click the Meet the team link toward the
          bottom of the page to view profiles of your local Link pharmacists.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpMobileMessaging = [
  {
    header: "Q: What is Link Pharmacy mobile messaging?",
    panel: (
      <>
        <p>
          A: Link Pharmacy mobile messaging is a service for registered customers to receive text alerts* delivered to a
          mobile device. These messages include Pharmacy Alerts, which will send you prescription refill notifications,
          and Special Offers, which offers exclusive deals, coupons and more.
        </p>
        <p>
          <b>
            NOTE: Pharmacy Alerts and Special Offers are separate subscriptions. You can sign up for one, both, or
            neither.
          </b>
        </p>
        <p>
          *Standard text message and data rates from your carrier or plan may apply. Link Pharmacy does not charge a fee
          for this service.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I sign up for mobile messaging?",
    panel: (
      <>
        <p>
          A: New customers can sign-up for Link Pharmacy Mobile Messaging during their registration process. Follow Text
          Alerts to set up this service. To sign up for Prescription Text Alerts, click the blue Edit Text Message
          Preferences link in the left column. To sign up for Special Offers texts, click the blue Sign Up Now link in
          the right column.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I stop Link Pharmacy mobile messaging from coming to my cell phone?",
    panel: (
      <>
        <p>A: To opt out at any time, text the word STOP to 21525.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: I am having trouble receiving messages. How can I get help?",
    panel: (
      <>
        <p>
          A: You must respond to the Rx Alerts welcome text message within 48 hours to complete the sign up process and
          verify that you have signed up for the program. If you completed this step and are still unable to receive
          messages from a store that is equipped with messaging services, please contact linkpharmacy.co.zm Customer
          Service toll-free, at 1-877-250-5823.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I order prescriptions refills from my cell phone?",
    panel: (
      <>
        <p>A: To refill prescriptions from your phone:</p>
        <ul>
          <li>Go to linkpharmacy.co.zm on your phone's mobile browser.</li>
          <li>Select the Pharmacy tab.</li>
          <li>
            Choose either the Refill Prescriptions (for delivery or pickup) or Express Refills (for store pickup only)
            button to view prescriptions that are available for refill.
          </li>
        </ul>
        <p>
          <b>NOTE: If you are not already logged in, you will be prompted to enter your username and password.</b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I shop Link Pharmacy and check out with my mobile device?",
    panel: (
      <>
        <p>
          A: Yes! You can shop, check out, and ship products to your home with all of our mobile applications as well as
          our mobile optimized site.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPrescriptionTextNotifications = [
  {
    header: "Q: How can I get more information about my prescription I received a text notification about?",
    panel: (
      <>
        <p>
          A: If you have any questions about a prescription you received a text notification about, please call the
          number included in your text notification to contact your pharmacy where the prescription was filled.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: I want to sign up for prescriptions text alerts. What do I do?",
    panel: (
      <>
        <p>A:To sign up for prescription text alerts:</p>
        <ul>
          <li>Follow the link Your Account to go to your Account Home page.</li>
          <li>
            Hover over the Settings tab and select Communication Preferences from the dropdown menu. You will be
            directed to the Manage Communication Preferences page
          </li>
          <li>
            In the box labeled Text Message Alerts, you'll see the question "Do you want to receive Pharmacy
            Prescription Ready Alerts?" Select Yes.
          </li>
          <p>
            NOTE: If you have not saved a cell phone number in your Personal Information, click Add a number in the
            bottom left corner of the Text Message Alerts box.
          </p>
          <li>
            Click Save Changes. You will see a confirmation message that you've successfully updated your text message
            preferences.
          </li>
          <p>NOTE: If you have not made any changes, the Save Changes button will not be clickable.</p>
        </ul>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Which medications are eligible for 90 day refills?",
    panel: (
      <>
        <p>
          A: Most maintenance medications are eligible - excluding controlled substance prescriptions. If you have
          questions about prescription eligibility, please contact your local pharmacy.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What are the benefits of 90 day refills?",
    panel: (
      <>
        <p>
          A: You'll save time by making fewer trips to the pharmacy. Plus, you'll potentially save on co-pays depending
          on your insurance
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is Maintenance Medications?",
    panel: (
      <>
        <p>
          A: Most maintenance medications are eligible - excluding controlled substance prescriptions. If you have
          questions about prescription eligibility, please contact your local pharmacy.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpFacebook = [
  {
    header: "Q: How do I become a Link Pharmacy Facebook fan?",
    panel: (
      <>
        <p>A: Follow Your Account to become a Link Pharmacy fan.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I print my Facebook photos to my local Link Pharmacy?",
    panel: (
      <>
        <p>
          A: Yes, when you use the Link pharmacy printing application on Facebook. The Link Pharmacy Photo Printing
          Application allows Facebook users to print 4x6 prints as well as 4x6, 5x7, and 8x10 collage prints from
          Facebook photo albums. Prints are available for pick up at one of over 7,000 Link Pharmacy stores. For more
          details and instructions, follow Link pharmacy Photo Printing on Facebook.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpMobileApps = [
  {
    header: "Q: Does Link pharmacy have an iPhone application?",
    panel: (
      <>
        <p>
          A: Yes. The robust functionality of the free Link pharmacy iPhone App allows users to manage pharmacy
          accounts, upload and order photos, browse the weekly ad, create a shopping list, locate a Link pharmacy store
          via the GPS-enabled store locator, receive push notifications when prescriptions are ready, and even order
          prescription refills by scanning a barcode with your iPhone. The app is also compatible with iPod Touch
          devices.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Link Pharmacy have an iPad application?",
    panel: (
      <>
        <p>A: Yes</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Does Link Pharmacy have an app for Android phones?",
    panel: (
      <>
        <p>
          A: Yes. The free Link Pharmacy Android application allows users to manage pharmacy accounts, upload and order
          photos, browse the weekly ad, create a shopping list, locate a Link pharmacy store via the GPS-enabled store
          locator, scan Link pharmacy QR codes and UPC symbols, and even order prescription refills by scanning a
          barcode with your phone.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpOverview = [
  {
    header: "Q: Will there be a myHealth™  card?",
    panel: (
      <>
        <p>
          A: No, we’re not making a physical card for myHealth members. Going cardless makes it easier for you to earn
          rewards and redeem savings if you leave it behind, plus it makes checkout safer for you with less contact.
          It’s also more environmentally friendly, because we care about our communities. We think that’s a win-win!
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is myHealth™ ",
    panel: (
      <>
        <p>
          A: myHealth is a one-of-a-kind personalized experience that makes saving, shopping and your well-being easier.
          Designed for the one and only you.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. When does myHealth™ launch and how do I sign up?",
    panel: (
      <>
        <p>
          A. Be the first to sign up online at linkpharmacy.co.zm beginning November 5. We’ll be introducing myHealth in
          our stores throughout the month, and by November 19 you’ll be able to easily sign up in any store at checkout!
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpMyHealthEligibility = [
  {
    header: "Q: How much does it cost to join myHealth™ ?",
    panel: (
      <>
        <p>A: myHealth is free to join!</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can anyone sign up for myHealth™ ?",
    panel: (
      <>
        <p>
          A: myHealth is available to residents at least 16 years old, and it's free to join! Members between 16 and 18
          years old (or between 16 and 21 years of age in Puerto Rico) must get permission from their parent or legal
          guardian first.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is required to become a myHealth™ member?",
    panel: (
      <>
        <p>
          A: Your first name, last name, ZIP code, and phone number are needed to join myHealth. Providing this basic
          information allows you to unlock sale prices in store and start earning Link pharmacy cash rewards that are
          easy to redeem at checkout.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. What do I need to redeem my Link pharmacy Cash rewards?",
    panel: (
      <>
        <p>
          A. To access the full program benefits of myHealth™, including redeeming Link pharmacy Cash rewards and
          receiving personalized deals, an email address is required. You can easily add an email address to your online
          profile or in store at checkout.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpAccount = [
  {
    header: "Q. Where can I find out how many Link pharmacy Cash rewards I have?",
    panel: (
      <>
        <p>
          A. You can see your current Link pharmacy Cash rewards balance by logging in to your account at myHealth. or
          on the Link pharmacy mobile app. You can also view your current Link pharmacy Cash rewards balance on your
          last Link pharmacy receipt or by calling (855) 225-0400.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. When do Link pharmacy Cash rewards get posted to my myHealth account?",
    panel: (
      <>
        <p>
          A: myHealth is a one-of-a-kind personalized experience that makes saving, shopping and your well-being easier.
          Designed for the one and only you.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. Can I link my family's myHealth accounts?",
    panel: (
      <>
        <p>A. Yes, you can link up to four myHealth accounts by calling +260 211 255 556.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. If I have more than one myHealth account, can I transfer Link pharmacy Cash rewards between them?",
    panel: (
      <>
        <p>
          A. No, you cannot transfer Link pharmacy Cash rewards between accounts, but you may merge your accounts.
          Please call +260 211 255 556 and speak with a customer service representative to merge accounts.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpProgramWorks = [
  {
    header:
      "Q. I live in a state that cannot earn Link pharmacy Cash rewards for prescriptions; what else can I earn Link pharmacy Cash rewards on?",
    panel: (
      <>
        <p>A. You can earn 1% Link pharmacy Cash rewards storewide and 5% on Link pharmacy branded items.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. Are there items that cannot be purchased to earn or redeem Link pharmacy Cash rewards?",
    panel: (
      <>
        <p>
          A. With the exception of photo orders and prescriptions (which must be picked up in a Participating Store in
          order to earn Link pharmacy Cash rewards), items ordered online and delivered to Member’s home will earn Link
          Pharmacys they would if purchased in store. Items ordered online using the “store pickup” feature will only
          earn Link pharmacy Cash rewards after the items have been picked up from the store.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. Can I combine Link pharmacy Cash rewards offers with in-store discounts and coupons?",
    panel: (
      <>
        <p>
          A. Yes, you may use Link pharmacy Cash rewards along with other Link Pharmacy manufacturer discounts or
          promotions. For more information, view the complete <a href="/#">coupon policy</a>.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. Does my Link pharmacy Cash rewards expire?",
    panel: (
      <>
        <p>
          A. Yes, Link pharmacy Cash rewards expire one year after they are earned or if an account has been inactive
          for 6 months. Your account is deemed inactive if you have not used your membership in store or online.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpRedemption = [
  {
    header: "Q. How do I spend Link pharmacy Cash rewards?",
    panel: (
      <>
        <p>
          A. "It's easy! You can use your Link pharmacy Cash rewards by providing your phone number or the contactless
          checkout bar code associated with your account during your purchase—just make sure your email address has been
          added to your myHealth™ account. When you spend your Link pharmacy Cash rewards, the savings are deducted from
          the total price of your purchase. Link pharmacy Cash rewards can be used in addition to Link Pharmacyacturer
          discounts or promotions, unless otherwise stated in the promotional offer. Link pharmacy Cash rewards are
          applied to eligible purchases after tax and after all discounts, percent-off and manufacturer coupons.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. How long are my Link pharmacy Cash rewards valid?",
    panel: (
      <>
        <p>
          A. Link pharmacy Cash rewards expire one year after they are earned or if an account has been inactive for 6
          months. Your account is deemed inactive if you have not used your membership in-store or online
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. Why was I charged in full for an order that I redeemed Link pharmacy Cash rewards for?",
    panel: (
      <>
        <p>A. Your order may not have shipped in full.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. When will my Link pharmacy Cash rewards be redeemed for an online order?",
    panel: (
      <>
        <p>A. When the order ships in full.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpMyHealthGoalsOverview = [
  {
    header: "Q: What is myHealth health goals?",
    panel: (
      <>
        <p>
          A. myHealth health goals is a myHealth benefit that encourages users to set and meet health goals. Earn Link
          pharmacy Cash rewards for every 4-week physical activity and healthy lifestyle challenge week you complete at
          myHealth health goals. You can manually track progress or sync a health device or app to automatically track
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What do I need to use myHealth health goals?",
    panel: (
      <>
        <p>A: You must have a linkpharmacy.co.zm account and a myHealth membership to use the program.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Where can I find the myHealth Privacy Policy and Terms and Conditions?",
    panel: (
      <>
        <p>
          A. See the <a href="/privacy_policy">linkpharmacy.co.zm Privacy Policy</a> and{" "}
          <a href="/#">myHealth Terms and Conditions</a>.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpMyHealthGoalsEligibility = [
  {
    header: "Q. How do I start using myHealth health goals?",
    panel: (
      <>
        <p>
          A: Visit linkpharmacy.co.zm and click the Register Now button at the top right side of the page. You must be a
          myHealth member to join. If you already have a linkpharmacy.co.zm account, enter your username and password
          and click Sign In.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Do I need to be a myHealth member to use myHealth health goals?",
    panel: (
      <>
        <p>
          A: Yes. If you don't already have a linkpharmacy.co.zm account, you can create one while signing up for
          myHealth health goals.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Do I need a linkpharmacy.co.zm account to start using myHealth health goals?",
    panel: (
      <>
        <p>
          A: Yes. If you don't already have a linkpharmacy.co.zm account, you can create one while signing up for
          myHealth health goals.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What if I can't register because I've forgotten my username or password?",
    panel: (
      <>
        <p>
          A. Follow Forgot your Username or Password? for assistance on finding your username and resetting your
          password.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpMyhealthGoalsDeviceApp = [
  {
    header: ">Q: Can I set up my health or fitness device/app using myHealth health goals?",
    panel: (
      <>
        <p>
          A. No. You'll first need to activate your device or app by following its included instructions for use,
          including the set-up on the manufacturer website, if applicable. Then you're ready to link it to myHealth
          health goals and start automatically logging activity and earning Link pharmacy Cash rewards. (If you don't
          have an account yet, it's easy to register.)
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I connect/reconnect my device or app to auto-track my activity?",
    panel: (
      <>
        <p>A. Just follow these stepsto link a compatible device/app.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Which devices and apps can be linked to myHealth health goals?",
    panel: (
      <>
        <p>
          A. Link pharmacy supports a number of leading fitness and health devices & apps for auto-logging myHealth
          health goals. Please visit Health Apps & Devices to see all eligible devices and apps. NOTE: MyFitnessPal,
          MedHelp, myHealth Connect, Runkeeper and Jawbone are no longer supported by myHealth health goals. If you
          previously connected your myHealth health goals account to any of these devices or apps, your activity data
          will no longer be automatically entered.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why doesn’t my auto-tracked device data appear on my account?",
    panel: (
      <>
        <p>
          A. Your connected device or app may have been disconnected from your account or is no longer supported. Visit
          Health Apps & Devices to see all eligible devices, apps and connection instructions.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpMyHealthGoalsCashReward = [
  {
    header: "Q: How do I earn Link pharmacy Cash rewards for myHealth health goals?",
    panel: (
      <>
        <p>
          A. Earn Link pharmacy Cash rewards for every challenge week you complete at myHealth health goals. You can
          manually track progress or sync a health device/app to automatically track challenge activity. Your healthy
          activity can also be tracked automatically if you sync select health and fitness devices & apps. Earn Link
          pharmacy Cash rewards for every challenge week you complete at myHealth health goals. You can manually track
          progress or sync a health device/app to automatically track challenge activity.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How many Link pharmacy Cash rewards can I earn for each activity?",
    panel: (
      <>
        <p>
          A. You will earn $0.25 Link pharmacy Cash rewards each week you complete a healthy challenge (up to $1 Link
          pharmacy Cash rewards total). Successfully complete all 4 challenge weeks to earn a bonus prize wheel spin
          worth up to $2 Link pharmacy Cash rewards.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: When will my Link pharmacy Cash rewards be added to my account?",
    panel: (
      <>
        <p>
          A. Yes, you may use Link pharmacy Cash rewards along with other Walgreens or Duane Reade manufacturer
          discounts or promotions. For more information, view the complete <a href="/#">coupon policy</a>.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q. Does my Link pharmacy Cash rewards expire?",
    panel: (
      <>
        <p>
          A. In most cases, Link pharmacy Cash rewards earned by logging activities directly into myWalgreens health
          goals (or via the Walgreens mobile app) will be added to your account immediately, but it may take up to 48
          hours for Walgreens Cash rewards to be reflected in your account.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpMyHealthGoalsLoggingActivities = [
  {
    header: "Q: How do I start a myHealth health goal challenge?",
    panel: (
      <>
        <p>
          A. Sign in at myHealth then click "Get started" on a challenge card to view all available challenges. You are
          able to participate in two challenges (one physical activity challenge and one lifestyle challenge) at the
          same time.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: When do myHealth health goals challenges start and end?",
    panel: (
      <>
        <p>
          A. All challenges begin on Monday and expire in 7 days. Sync your device or app or manually track progress
          every Sunday by 11:59 pm CST to earn $0.25 Link pharmacy Cash rewards each week.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I earn a challenge prize wheel spin?",
    panel: (
      <>
        <p>
          A. You must complete all 4 weeks of a physical activity or lifestyle challenge to earn a bonus prize wheel
          spin worth up to $2 Link pharmacy Cash rewards.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I view my myHealth health goals challenges?",
    panel: (
      <>
        <p>
          A. Sign in at myHealth health goals to see all current and in-progress physical activity and lifestyle
          challenges
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPaymentMethodsInfo = [
  {
    header: "Q: Do you accept payment other than credit card?",
    panel: (
      <>
        <p>
          You can pay for linkpharmacy.co.zm orders with a debit card, Visa, MasterCard, Discover Network or American
          Express. You can also pay with PayPal, and PayPal Credit. Link Pharmacy stores accept cash, check, gift card,
          debit card, Visa, MasterCard, Discover Network, American Express, CareCredit or Apple Pay.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What are your accepted payment options?",
    panel: (
      <>
        <p>
          A: If you joined Balance Rewards online, you will not be mailed a physical card. If you would like a physical
          card, visit your local Link Pharmacy or Duane Reade and ask a sales associate to link a physical card to your
          membership.
        </p>
        <p>
          NOTE: You do not need a physical card to participate in Balance Rewards. To earn points online, sign in to
          your linkpharmacy.co.zm account. In store, provide your phone number, the mobile card, or present your
          physical card.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Does Link Pharmacy accept food stamps?",
    panel: (
      <>
        <p>Food stamps are accepted at select Link Pharmacy stores. Please contact your store for program details.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },

  {
    header: "Q: Does Link Pharmacy have contactless payment options?",
    panel: (
      <>
        <p>
          Yes, select contactless payments are accepted at Link Pharmacy stores, including tap-to-pay credit cards,
          Apple Pay, and Google Pay.
        </p>
        <p>
          It's easy, just wave your contactless card or mobile device over the payment terminal and follow prompts on
          the reader screen
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPaymentsGiftCard = [
  {
    header: "Q: What types of link Pharmacy cards are available?",
    panel: (
      <>
        <p>
          Follow Link Pharmacy Gift Cards for Gift Card information. Link Pharmacy Gift Cards can be redeemed in-store
          only. You may have also received a Link Pharmacy Card for store credit for returned items. These can be
          redeemed in-store only.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I check the balance on my Link Pharmacy Gift Card or Link Pharmacy Card?",
    panel: (
      <>
        <p>A: Please call the number on the back of the card and request your account balance.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I buy linkpharmacy Gift Cards online?",
    panel: (
      <>
        <p>
          A: Yes, you can purchase linkpharmacy Gift Cards online in select denominations. linkpharmacy Gift Cards in
          other amounts can be purchased at your local Link Pharmacy store. Follow linkpharmacy Cards for more
          information.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Can I redeem a Gift Card online",
    panel: (
      <>
        <p>
          A: At this time, you cannot use linkpharmacy Gift Cards or linkpharmacy Cards for online purchases. Your
          linkpharmacy Gift Card or linkpharmacy Card is accepted at any Link Pharmacy store nationwide
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpApplePay = [
  {
    header: "Q: What is Apple Pay?",
    panel: (
      <>
        <p>
          A: Apple Pay is a convenient and secure way to pay for your in-store purchases with your iPhone 6 or iPhone 6
          Plus.
        </p>
        <p>
          Once you connect a credit or debit card from a participating bank to your Apple Pay account, you'll be able to
          pay for your purchase at any Link Pharmacy location by simply holding your phone near the reader at checkout
          and placing your finger over the TouchID sensor on your phone to authorize the payment.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I use Apple Pay for orders on linkpharmacy.co.zm?",
    panel: (
      <>
        <p>
          A: Yes, we now accept Apple Pay on the web, in the app, and in our stores. Unfortunately, we do not accept
          Apple Pay for Photo purchases paid for online.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpVisaCheckout = [
  {
    header: "Q: What is Visa Checkout?",
    panel: (
      <>
        <p>
          A: Visa Checkout is a digital wallet that simplifies the on-line shopping process by storing your credit or
          debit card information and billing address. Visit Visa Checkout for more information.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What type of payment method can I use with Visa Checkout?<",
    panel: (
      <>
        <p>A: You can add any American Express, Discover, MasterCard, or Visa branded debit or credit card.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I use Visa Checkout for in-store purchases?",
    panel: (
      <>
        <p>A: No. Visa Checkout can only be used for on-line purchases.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I use Visa Checkout to make linkpharmacy.co.zm purchases from my mobile device?",
    panel: (
      <>
        <p>
          A: Yes. You can use Visa Checkout to make linkpharmacy.co.zm purchases from any web enabled mobile device.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPayPal = [
  {
    header: "Q: What is PayPal?",
    panel: (
      <>
        <p>
          A: PayPal is a secure way to send and receive money online with or without entering a credit card number. You
          can find complete information at <a href="https://www.paypal.com">PayPal.com</a>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: If I already have a PayPal account, do I have to sign up again to use it at linkpharmacy.co.zm?",
    panel: (
      <>
        <p>
          A: No, there's no need to register again. When you check out, choose Pay with your PayPal account on the
          Payment page. You'll be taken to the PayPal login page. Log in as usual, and then you'll be returned to
          linkparmacy.co.zm to finish checking out. For online photo orders, PayPal can only be used with the mail
          delivery option. Select the shipping option (instead of in-store pickup) and click on the PayPal button at
          checkout.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I use PayPal for all orders on linkpharmacy.co.zm?",
    panel: (
      <>
        <p>
          A: Sorry, no. We accept PayPal payments only for product and W Photo orders. For W Photo purchases, PayPal can
          only be used for orders that you choose to have mailed to you. It cannot be used toward photo orders that you
          schedule for in-store pickup.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I use PayPal at my local Link Pharmacy store?",
    panel: (
      <>
        <p>A: Sorry, no. PayPal is only available for online purchases.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header:
      "Q: If I have paid for a Link Pharmacy purchase online with PayPal and need to return the product, can I get a refund at my local Link Pharmacy?",
    panel: (
      <>
        <p>
          A: It depends. For online store orders, you may choose to have your refund applied to the credit card
          associated with your PayPal account, in cash, or on a linkpharmacy Gift Card. If you do not have a receipt,
          your credit will be applied to a WCard. See Returns for more information. For online photo refunds, please
          call Customer Service, at +260 211 255 556, 24 hours a day, 7 days a week.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPharmacyRecords = [
  {
    header: "Q: How can I get a printout of all of my prescription transactions for the year?",
    panel: (
      <>
        <p>A: To get a printout of all of your prescription transactions for the year:</p>
        <ul>
          <li>
            Follow Print <a href="/#">Prescription Records</a>.
          </li>
          <li>
            Choose the date range of the prescription history you'd like to print and click on the blue Get Records
            button. The next screen will take you to the printable version of your prescription transactions
          </li>
        </ul>
        <p>
          <b>
            NOTE: If you have already completed your full online Pharmacy registration, you will be prompted to sign in
            before viewing your prescription history. If you do not have an online Pharmacy registration, you will be
            prompted to Create a <a href="/#">Pharmacy Registration</a>.
          </b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I review my prescription history?",
    panel: (
      <>
        <p>A: To review your prescription history:</p>
        <ul>
          <li>
            Follow <a href="/#">Prescription History</a>. (This link can also be found under Prescription Services on
            the Pharmacy Home page.) You will be directed to a page where you can view all your active and inactive
            prescriptions from the last 18 months.
          </li>
          <li>
            Select the radio button for Yes next to Show Hidden Prescriptions? to display your entire prescription
            history. Your Prescription History tab will now show all your past prescriptions. You can sort by
            Prescription, Last Filled, Status and Price, or Refills by clicking the column header. For your convenience,
            there is a Print Your Prescription Records link at the top of the page.
          </li>
          <p>
            If you are managing a Family Prescriptions account, you will see a drop down menu that will allow you to
            select individual family member prescriptions.
          </p>
          <li>
            Click Save Changes. You will see a confirmation message that you've successfully updated your text message
            preferences.
          </li>
          <p>
            <b>
              NOTE: If you have already completed your full online Pharmacy registration, you will be prompted to sign
              in. If you do not have an online Pharmacy registration, you will be prompted to Create a Pharmacy
              Registration.
            </b>
          </p>
        </ul>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I retrieve my prescription records beyond 18 months ago?",
    panel: (
      <>
        <p>
          A: No, prescriptions cannot be deleted from your history. Prescriptions are viewable for 18 months from the
          last fill date. These records must be kept up to 18 months so that we have a history and medical record of
          your prescriptions. After 18 months from the last fill date, the prescription will be transferred to microfilm
          and deleted from your viewable prescription history.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can a prescription be deleted from my prescription history?",
    panel: (
      <>
        <p>
          A: Print the Request to Access, Inspect or Obtain Protected Health Information form. Complete and sign the
          form, then mail or fax it to the Privacy Office. The mailing information is at the top of the request form.
          Please allow up to 15 business days for receipt of a response. If you do not have access to a computer with a
          printer, you can call the Custodian of Records at 1-217-554-8949 to request a form to be mailed to you.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPharmacyParentInfo = [
  {
    header: "Q: How do I set up pharmacy accounts for my kids?",
    panel: (
      <>
        <p>
          A: There are different options for minors depending on their age and needs. Follow Family Prescriptions Chart
          to get started.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: My child is about to turn 18. What does that mean for prescription management?",
    panel: (
      <>
        <p>
          A: Parents or guardians may no longer access their child's records once the child turns 18. The new adult can
          allow permission for a Family administrator to manage their account by accepting a new invitation from the
          administrator. New adults whose parent or legal guardian have previously linked their name to a Family
          Prescriptions account and managed their account may, upon turning 18, elect to have the same parent or
          guardian continue to manage the account.
        </p>
        <p>
          If an 18-year-old denies access to a parent or guardian, access to his/her records will be denied. Please note
          that if a minor remains a beneficiary of a parent's or legal guardian's health insurance policy, he or she
          should check with their insurer before making any change. To link a new adult, follow the directions to invite
          an adult in the Family Prescriptions Section.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPharmacyInsurancePayments = [
  {
    header: "Q: How do I update my insurance information?",
    panel: (
      <>
        <p>A: To update and verify your insurance information, contact your local Link Pharmacy.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What insurance plans does Link Pharmacy accept?",
    panel: (
      <>
        <p>
          A: Link Pharmacy is a provider for most major prescription plans as well as hundreds of local, regional and
          specialty plans, including several Health Maintenance Organizations (HMOs) and Pharmacy Benefit Managers
          (PBMs). Our network of health plans is extensive, and we are constantly adding more plans.
        </p>
        <p>
          Please refer to your local <a href="/#">Link pharmacy</a> to check if your prescription insurance plan is
          accepted.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What if Link pharmacy does not recognize my health insurance?",
    panel: (
      <>
        <p>
          A: Please provide us with all prescription plan information. If for some reason we are unable to verify your
          coverage or unable to bill your insurance, we will contact you by email or phone. If you have chosen to have
          your prescription shipped to you, your credit card will not be charged until your order is shipped.
        </p>
        <p>
          If your insurance company does not cover your prescription, or if Link pharmacy does not participate in your
          plan at this time, you always have the option of paying the full price of the prescription. The full price of
          any prescription not covered by insurance will be displayed at checkout. You will be provided with a detailed
          receipt, including prescription information that you can forward to your insurance company for reimbursement.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPharmacyPrescriptionPolicy = [
  {
    header: "Q: What is Link Pharmacy Prescription Fulfillment Policy?",
    panel: (
      <>
        <p>
          A: Once you have registered online, Link pharmacy makes it simple and secure to order prescriptions, wherever
          you are, whatever the time. You can order a new prescription, refill an existing Link pharmacy prescription,
          or transfer a prescription from a different pharmacy. You may choose to have your order shipped FREE with
          standard shipping, or select the Link pharmacy location where you will pick it{" "}
        </p>
        <p>
          To check your order status: Follow the link Your Account to go to your Account Home page. Hover over the
          Orders & Activity tab and select Order Status & History from the drop down menu. If you have your order
          number, you can enter it at the top of the page and click on the bright blue Find Order button for your order
          details. You can also see the status of your Recent Shipped Prescriptions, your Recent Online Store order or
          Photo orders on the charts below. Under the Order Number column, click on the bright blue order number to go
          to the Shipped Prescriptions Details or Online Store Order Details page.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How does Link Pharmacy verify new prescriptions?",
    panel: (
      <>
        <p>A: Link Pharmacy will contact your physician:</p>
        <ul>
          <li>When filling a new prescription order</li>
          <li>If your records show you are out of refills</li>
          <li>If any additional information is needed.</li>
        </ul>
        <p>
          If your doctor requests that a pharmacist phone him/her for your new prescription, and has not provided you
          with a written prescription, please fill out this Prescription Form. Please do not use this form if your
          doctor has provided you with a written prescription. Your doctor may not authorize a prescription over the
          phone if he has already written one out. Instead, please bring the written prescription order to your local
          Walgreens to be filled if you'd like to pick it up. Our pharmacists will use the information you provide to
          contact your doctor for authorization to fill your prescription. You may have your prescription shipped to
          you, or you may pick it up at your local Link pharmacy.
        </p>
        <p>
          You also can have this prescription shipped to you by mailing us the written prescription from your doctor
          along with this printable linkpharmacy.co.zm Prescription Order Form. We will contact your physician if any
          additional information is needed.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How does Link Pharmacy verify transferred prescriptions?",
    panel: (
      <>
        <p>
          A: When you fill out the Transfer Prescription Form, we use the information you provide to contact your
          current non-Link pharmacy about transferring your prescription to Link pharmacy.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPharmacyAutoRefils = [
  {
    header: "Q: What is Auto Refill?",
    panel: (
      <>
        <p>
          A: With a one-time set up, your prescription(s) will refill automatically as often as you select, eliminating
          the need to place a refill order for your medication. After order(s) are sent, you will be notified by email
          twice: when the prescription is processing and when the prescription is ready.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I set up my prescriptions for Auto Refill?",
    panel: (
      <>
        <p>
          A: Once you have set up your Pharmacy Registration, you will be able to set up Auto Refills for your
          prescription(s):
        </p>
        <ol>
          <li>
            Select the Pharmacy & Health tab under the Walgreens logo at the top of any page. You will be taken to the
            Pharmacy Home page. Click the Auto Refills link in the left column. Then, click the blue Set Up Auto Refills
            button
          </li>
          <li>
            Next, you will see the Set Up Auto Refill Prescriptions page with a list of prescriptions Eligible for Auto
            Refill and Current Auto Refill Prescriptions. You can view them by Last Sold Date, Prescription,
            Prescription Number, Next Fill Date (as determined by your healthcare provider's instructions), Refills,
            Prescriber and Patient. Go to the Select column and click on the box to the left of the prescription(s) you
            want on auto refill. When you are done, click on the red Continue at the bottom of the page. You will then
            be taken to the Delivery Options for Auto Refill page. Here, you can choose your delivery options.
          </li>
          <li>
            Choose your delivery option:Ship to Me or Pick Up in Store. Store locations can be changed at any time with
            the bright blue Find a Store link displayed next to the Pick up in Store option. This link will take you to
            the Store Locator page where you can change your selected store. Moreover, standard shipping for
            prescriptions is always FREE. Click the red Continue button when your order is complete.
          </li>
          <li>
            Next, you will see your updated Prescription Auto Refills page with a message at the top of the page in a
            bright green box: Set Up Complete: You Have Successfully Set Up Auto Refills. You can print out this page
            for your records or select the red Set Up More Auto Refills button at the bottom of the right navigation
            column to add more prescriptions to your order.
          </li>
        </ol>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I change the frequency, date or time of my Auto Refills?",
    panel: (
      <>
        <p>
          A: Your prescription will refill based upon your healthcare provider's instructions. If you do not take your
          medication(s) as prescribed, or if your provider has changed your instructions without notifying Link
          pharmacy, your Next Fill Date may differ from the date you anticipated.
        </p>
        <p>
          Link Pharmacy is not responsible for insurance issues that may affect your prescriptions. Link pharmacy
          reserves the right to remove a prescription from the Auto Refill service due to repeated instances of refills
          not having been picked up, your insurance provider prohibiting participation in this service, the prescription
          having been changed or discontinued by the prescriber or other reasons.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPharmacyResourcesDrugsHealthInfo = [
  {
    header: "Q: Where can I get more information on the drugs I am/or will be taking?",
    panel: (
      <>
        <p>
          A: Browse our Drug Information page to search for information about a specific prescription drug and see an
          image of the drug. You can choose from a list of the most commonly prescribed medications, or you can search
          by drug name.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I chat with a pharmacist?",
    panel: (
      <>
        <p>
          A: Yes. Follow <a href="/#">Pharmacy Chat </a> and sign in with your linkpharmacy.co.zm account.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPharmacyResourcesControlledSubstances = [
  {
    header: "Q: Can I order a controlled substance?",
    panel: (
      <>
        <p>
          A: A medication that is listed as a Schedule II Controlled Substance by the U.S. Drug Enforcement Agency
          (DEA), such as a morphine-like or amphetamine-like drug, may not be ordered online or approved over the phone.
          To order a prescription of a Schedule II Controlled Substance at your local Walgreens pharmacy, the original
          prescription must be presented at the pharmacy. We're sorry, but Walgreens.com cannot process online orders of
          Schedule II Controlled Substances for in-store pickup - your prescription must be presented in-store. Certain
          restrictions apply. You must have valid prescription insurance and have had a physical exam by the prescribing
          practitioner within the last 24 months to fill a Schedule II Controlled Substance prescription. Please call a
          Customer Service Representative toll-free, at 1-877-250-5823 if you have any further questions.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why can't I refill my hydrocodone prescription anymore?",
    panel: (
      <>
        <p>
          A: Due to a change in federal regulations effective October 6th, 2014, all medications that contain
          hydrocodone will become Schedule II controlled substances.
        </p>
        <p>This means that if your prescription medication contains hydrocodone:</p>
        <ul>
          <li>Refills are no longer allowed.</li>
          <li>
            A new, written prescription or an electronic prescription (if allowed in your state) from your prescriber is
            required every time you need a hydrocodone prescription filled.
          </li>
        </ul>
        <p>Please ask your pharmacist or prescriber how this new regulation affects you.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPharmacyServicesFindCareNow = [
  {
    header: "Q: What is See a Doctor Virtually with MDLIVE?",
    panel: (
      <>
        <p>
          A: Virtual Doctor with MDLIVE gives you the ability to have real live consultations with U.S. board-certified
          physicians in the comfort of your own home. Virtual doctors can consult and treat common illnesses like sinus
          and ear infections, sore throats, and skin problems, 24/7. They can even write prescriptions, if necessary
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Is this service available country wide?",
    panel: (
      <>
        <p>
          A: No. Please <a href="/#">click here for MDLIVE availabilty.</a>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I speak to a doctor in a language other than English through the Link Pharmacy mobile app?",
    panel: (
      <>
        <p>
          A: Yes. In the process of selecting your doctor, there is a Filter option on the Choose Doctor screen that
          allows you to select the language in which you would like to speak to your doctor.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why do I have to pay for the cost of the service?",
    panel: (
      <>
        <p>
          A: Currently, the service is only billable to a credit card. The cost is eligible as an FSA/HAS expense, but
          you will need to check with your plan provider to see if it is covered under your plan.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPharmacyServicesInstore = [
  {
    header: "Q: What is Healthcare Clinic at Link Pharmacy",
    panel: (
      <>
        <p>
          A: Healthcare Clinic, formerly Take Care Clinic, offers a wide range of convenient healthcare services for the
          entire family. Walk in or schedule an appointment at Healthcare Clinic locations at select Link Pharmacy and
          employer wellness centers nationwide.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Does Link Pharmacy provide immunization services? What about other health tests?",
    panel: (
      <>
        <p>
          A: Yes. Most Link Pharmacy provide seasonal influenza vaccinations (flu shot) and pneumococcal (pneumonia)
          vaccinations. Visit linkPharmacy.co.zm/for available locations and hours.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I schedule an appointment for a flu shot?",
    panel: (
      <>
        <p>A: You can make your appointment online or call your local store.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I schedule a health test or Medicare plan review at my Link Pharmacy store?",
    panel: (
      <>
        <p>
          You can schedule a health test or Medicare plan review at your local Link Pharmacy store by following these
          steps
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPreOrderProgramDetails = [
  {
    header: "Q: How can I Pre-Order a product?",
    panel: (
      <>
        <p>
          A: If a product is available for Pre-Order, you will be able to add it to your cart like any other product and
          go through the checkout process. Your payment method will not be charged until we ship the product to you.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Are there limits on Pre-Order products?",
    panel: (
      <>
        <p>A: Yes. Limitations may include quantity available from manufacturer and quantity allowed per order.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Do I have to have a linkpharmacy.co.zm account to purchase a Pre-Order product?",
    panel: (
      <>
        <p>A: No. You can Pre-Order your product and check out as a linkpharmacy.co.zm guest.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I check my Pre-Order status?",
    panel: (
      <>
        <p>
          A: If you have a linkpharmacy.co.zm account, you can check your order history and status. If you are a
          linkpharmacy.co.zm guest, you can contact customer care with your order number.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPreOrderPaymentMethods = [
  {
    header: "Q: What payment options are available?",
    panel: (
      <>
        <p>A: You can pay with Visa, MasterCard, or Discover branded debit/credit card, Visa Checkout</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header:
      "Q: What happens to my Pre-Order if my debit/credit card is expired, declined, the account is closed, or there is a problem with my Bank account?",
    panel: (
      <>
        <p>
          A: If for any reason we are unable to process your order, your order will be canceled and you will be notified
          via email. You will need to place a new order with a valid payment method or you can contact Customer Care to
          place a new order.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I use Balance Rewards points to pay for a Pre-Order?",
    panel: (
      <>
        <p>A: Yes.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: When will I be charged or have my points deducted?",
    panel: (
      <>
        <p>
          A: 1-3 days before your Pre-Order ships, a payment will be authorized to your payment method on file for the
          full amount due. Once your product ships all qualifying Balance Rewards points and discounts will be applied
          and the authorized payment will be adjusted accordingly.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What if there is an issue with my payment?",
    panel: (
      <>
        <p>
          A: Your payment method will be validated 7 days before the Pre-Order ships. If your payment method cannot be
          validated or has expired, the Pre-Order will be cancelled and you will receive an email notification.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPreOrderNotifications = [
  {
    header: "Q: What email notifications will I get regarding my Pre-Order product",
    panel: (
      <>
        <p>A: You will receive the following notifications before your Pre-Order product ships.</p>
        <ul>
          <li>Order Confirmation</li>
          <li>Pre-Order shipping soon</li>
          <li>Pre-Order has shipped</li>
        </ul>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPromoCodes = [
  {
    header: "Q: How do I apply a promo code to my order?",
    panel: (
      <>
        <p>
          A: Note the code that you want to use. After adding your item(s) to your cart, proceed to the checkout page.
          On the left side of the page you will see a box labeled, Have a special promotion or coupon code? Enter your
          code and click Apply Code.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I use promo codes on store purchases?",
    panel: (
      <>
        <p>A: No, promo codes can only be used for online purchases.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I use a promo code on a purchase in which I use a gift card?",
    panel: (
      <>
        <p>A: Promo codes cannot be used toward gift card purchases.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPromoSpecials = [
  {
    header: "Q: Where can I find the Weekly Ad?",
    panel: (
      <>
        <p>
          A: Click the Weekly Ad link located near the upper right hand corner of every linkpharmacy.co.zm page. If you
          have previously registered your ZIP code, we will show the weekly ad for the store nearest that location. If
          not, we'll show you the Weekly Ad for the closest Link pharmacy that matches your computer's IP address. You
          can always change your store location by clicking on Change Store.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I preview or get a sneak peek of the Weekly Ad?",
    panel: (
      <>
        <p>
          A: You can sign up for the Weekly Ad Sneak Peek email so you won't forget to check out the latest values.
          Regularly scheduled weekly promotions go live each Sunday, but you can view the Weekly Ad Sneak Peek each
          Thursday at noon before the live date.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What if I can't see the Weekly Ad?",
    panel: (
      <>
        <p>
          A: If you're trying to view the Weekly Ad and are arriving at a blank page or a page with an error, check to
          see that you have Flash enabled on your computer.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why am I seeing a different zip code's Weekly Ad?",
    panel: (
      <>
        <p>
          A: The Weekly Ad and Sneak Peek automatically detect your area based on your IP address. Depending on your
          internet service provider, this may be different than your actual location. To display your Weekly Ad or Sneak
          Peek, click the Change Store link.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShippingOrderStatus = [
  {
    header: "Q: How do I track my product order?",
    panel: (
      <>
        <p>
          A: <a href="/#">Track your product order here</a> or follow the link in the email you receive when your order
          ships. You can also call Customer Care toll-free at +260 211 255 556.
        </p>
        <p>
          <b>Ordered:</b> This means we've received your order, and we're getting your product or products ready to
          ship.
        </p>
        <p>
          <b>Shipped or in transit:</b> Your order is on its way.
        </p>
        <p>
          <b>Delivered:</b> Your order has arrived at its destination.
        </p>
        <p>
          <b>Out of stock:</b> The product or products you ordered are no longer available. They won't be backordered,
          but you can place a new order when the item is available again.
        </p>
        <p>
          Delivery confirmation is not available for APO/FPO/DPO orders that are delivered by the military mail service,
          and package tracking is not available once the package is handed off to the military mail service.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I track my prescription order?",
    panel: (
      <>
        <p>
          <a href="/#">Track your prescription order here.</a> Your confidential information is displayed only if you've
          completed your full online registration. You can also call Customer Care toll-free at +260 211 255 556.
        </p>
        <p>
          <b>Order being processed:</b> This means we're getting your prescription or prescriptions ready to ship.
        </p>
        <p>
          <b>Shipped:</b> Your order is on its way.
        </p>
        <p>Doing in-store pickup instead?</p>
        <p>
          <b>In process:</b> This means your order is on its way to the store. Call the pharmacy for details.
        </p>
        <p>
          <b>Ready:</b> Your prescription is ready to be picked up at the store.
        </p>
        <p>
          <b>Picked up:</b> Your prescription has been picked up.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I track my contact lens order?",
    panel: (
      <>
        <p>
          <a href="/#">Track your contact lens order here</a> You can also call Customer Care toll-free at +260 211 255
          556.
        </p>
        <p>
          <b>Verifying prescriber:</b> This means we're trying to reach your optometrist to verify your prescription.
        </p>
        <p>
          <b>In process:</b> We're currently filling your order.
        </p>
        <p>
          <b>Shipped:</b> Your order is on its way.
        </p>
        <p>
          <b>Out of stock:</b> You'll soon receive an email notifying you of either a backorder or an order
          cancellation.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I check my order status if I don't have a linkpharmacy.co.zm account?",
    panel: (
      <>
        <p>
          <a href="/#">Track your prescription order here.</a> Your confidential information is displayed only if you've
          completed your full online registration. You can also call Customer Care toll-free at +260 211 255 556.
        </p>
        <p>A: Please contact Customer Care toll-free, at +260 211 255 556.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: My order contains products, prescriptions, and/or contact lenses. How will it be shipped?",
    panel: (
      <>
        <p>
          <a href="/#">Track your prescription order here.</a> Your confidential information is displayed only if you've
          completed your full online registration. You can also call Customer Care toll-free at +260 211 255 556.
        </p>
        <p>A: It will be shipped in more than one package, and the processing and shipping times of each may vary.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpFreeShipping = [
  {
    header: "Q: How do I get free shipping?",
    panel: (
      <>
        <p>A: There are a few different ways to get FREE standard shipping:</p>
        <ul>
          <li>
            Select Ship to Store at checkout. There's no minimum purchase requirement. (We'll send your order to the
            store of your choice, and email you when it's ready for you to pick it up.)
          </li>
          <li>
            Select Ship to Home at checkout and reach an order subtotal of $35 or more (excluding sales tax and/or
            applicable shipping charges).
          </li>
          <li>Include contact lenses or a prescription in your order (excludes flat fee products</li>
        </ul>
        <p>
          New prescription orders qualify for free 2-day shipping. (Note that Link Pharmacy reserves the right to roll
          back this offer at any time.)
        </p>
        <p>
          Note that orders are subject to a $0.049 shipping fee for each 1/10 of a pound over 20 pounds. This fee
          applies to product, prescription, and contact lens orders, even when all items are free-shipping eligible
          and/or the $35 minimum has been achieved.
        </p>
        <p>
          Gift cards ship free, but they do not contribute to the cart subtotal for shipping offers or thresholds. Photo
          orders and Prescription Savings Club cards are not eligible for free shipping
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header:
      "Q: All of the products in my cart are eligible for free standard shipping, so why am I being charged for shipping?",
    panel: (
      <>
        <p>A: There are a few reasons why this might be happening:</p>
        <ul>
          <li>Your subtotal, which excludes sales tax and applicable shipping charges, might not total $35.</li>
          <li>
            Some of the products in your cart may not be eligible for free shipping. Examples of ineligible products
            include Prescription Savings Club cards and photo orders..
          </li>
          <li>
            Your order might weigh over 20 pounds. Packages are subject to a $0.049 shipping fee for each 1/10 of a
            pound over 20 pounds. This fee applies to product, prescription and contact lens orders, even when all items
            are free-shipping eligible.
          </li>
        </ul>
        <p>Still not sure why you're being charged? Call Customer Care toll-free at +260 211 255 556.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header:
      "Q: I'm buying a gift card, which ships for free, so why am I not getting free shipping on my entire order?",
    panel: (
      <>
        <p>
          A: Gift cards ship for free, but they do not contribute to the cart subtotal for free shipping offers or
          thresholds.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShippingRates = [
  {
    header: "Q: How are shipping charges determined",
    panel: (
      <>
        <p>
          A: The shipping charge is based on the order's size, weight, shipping method, and shipping address. In your
          cart, you will see the estimated shipping charge based on standard shipping to the contiguous U.S. You will
          see your actual shipping cost at checkout.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What are the shipping rates on linkpharmacy.co.zm?",
    panel: (
      <>
        <p>
          A:<b> Shipping rates on linkpharmacy.co.zm</b>
        </p>
        <table>
          <tr>
            <td></td>
            <td>Standard</td>
            <td>Expedited</td>
            <td>Overnight</td>
          </tr>
          <tr>
            <td>Prescriptions</td>
            <td>FREE</td>
            <td>$12.95</td>
            <td>$12.95</td>
          </tr>
          <tr>
            <td>Contact Lenses</td>
            <td>FREE</td>
            <td>Not applicable</td>
            <td>$12.95</td>
          </tr>
          <tr>
            <td>Products — Ship to You (orders over $35)</td>
            <td>
              FREE <sup>1</sup>
            </td>
            <td>
              $8.99<sup>2</sup>
            </td>
            <td>Not applicable</td>
          </tr>
          <tr>
            <td>Products — Ship to You (orders under $35)</td>
            <td>
              $5.99 <sup>1</sup>
            </td>
            <td>
              $8.99<sup>2</sup>
            </td>
            <td>Not applicable</td>
          </tr>
          <tr>
            <td>Products — Ship to Store (no order minimum)</td>
            <td>
              FREE<sup>1</sup>
            </td>
            <td>Not applicable</td>
            <td>Not applicable</td>
          </tr>
        </table>
        <p>
          Photo Orders See <a href="/#">Photo Pricing</a>
        </p>
        <p>1 Up to 20 lbs. Add $.049 for each 1/10 lb. over 20 lbs.</p>
        <p>2 $8.99 for the first 20lbs., $0.99 per 1/10 lb. over 20 lbs.</p>
        <p>
          A signature is required upon delivery of Schedule II controlled substances. Other prescription shipments may
          also require a signature upon delivery.
        </p>
        <p>
          Portions of your order that qualify for free shipping offers or have flat shipping fees will not be included
          when calculating the weight of your order. You'll find each product's weight on its details page.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What are the shipping rates and delivery time estimates for APO, FPO, and DPO addresses?",
    panel: (
      <>
        <p>
          A: Standard shipping is available inside the 48 contiguous states. Shipping to some more remote areas of the
          48 contiguous states, and to areas outside of the 48 contiguous states, may take additional time
        </p>
        <p>
          Orders are handled by a variety of carriers. We select the carrier offering the best service at that time for
          that destination. Product shipping times may vary based on fulfillment center.
        </p>
        <p>
          Shipping may be delayed if an item isn't in stock at the time of order. There may be occasional discrepancies
          between the inventory levels shown on Link Pharmacy.com, and what is actually held in inventory. A delay may
          also be experienced if the order includes a shipping-restricted item, or if it's shipping to a PO Box.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do you handle shipping for photo products?",
    panel: (
      <>
        <p>
          A: See <a href="/#">Link pharmacy Photo Shipping Information</a> for available photo shipping options.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is standard shipping?",
    panel: (
      <>
        <p>
          A: All orders shipped to a US Military or diplomatic address (APO, FPO, DPO) are sent with no additional
          charge to our military customers. Unfortunately, expedited shipping is not available for orders shipped to
          these addresses.
        </p>
        <ul>
          <li>
            <b>Shipping rate:</b> $5.99, or free with eligible purchase
          </li>
          <li>
            <b>Order processing:</b> Up to 1 business day
          </li>
          <li>
            <b>Shipping time:</b> 5-11 business days (from when your order is shipped until it reaches its destination
            or is handed off to the military mail service)
          </li>
          <li>
            <b>Overall delivery time:</b> 6-12 business days
          </li>
        </ul>
        <p>There is an additional $0.99 shipping fee for each pound over 20 pounds.</p>
        <p>
          Our delivery partners have informed us that packages can take up to 8 weeks for delivery to certain APO, FPO,
          and DPO U.S. military addresses. Delivery confirmation is not available for APO, FPO, and DPO orders that are
          delivered by the military mail service, and package tracking is not available once the package is handed off
          to the military mail service.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is expedited shipping?",
    panel: (
      <>
        <p>
          A: Orders placed by the 3 p.m. ET are shipped overnight and normally arrive to customers in 1 business day.
          Orders received after 3 p.m. will be processed the next business day and shipped overnight. Certain items ship
          directly from vendors and will arrive 1-2 business days after processing. Orders cannot be shipped via
          Expedited outside the 48 contiguous states. They also cannot be shipped to P.O. Boxes, APO, FPO and DPO
          addresses.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is overnight shipping?",
    panel: (
      <>
        <p>A: Overnight shipping is guaranteed to take 1 business day.</p>
        <p>
          Orders are handled by a variety of carriers. We select the carrier offering the best service at that time for
          that destination. Product shipping times may vary based on fulfillment center.
        </p>
        <p>
          An order must be placed by 3 p.m. EST for guaranteed 1-day shipping. Orders placed after 3 p.m. EST are
          processed the following business day instead.
        </p>
        <p>
          Orders shipping to PO Boxes, APO, FPO, DPO addresses, or outside the 48 contiguous states, are ineligible for
          overnight shipping.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is Shipping-Restricted?",
    panel: (
      <>
        <p>
          A: The U.S. Department of Transportation (DOT) prohibits certain items from shipping via air, and the United
          States Postal Service (USPS) further prohibits certain quantities of these items from shipping via USPS ground
          services. These items are denoted as "Shipping-Restricted," and are classNameified as ORM-D (Other Regulated
          Materials for Domestic Transport Only) or Lithium Ion or Lithium Metal Batteries. Shipping-Restricted items
          include:
        </p>
        <ul>
          <li>
            Aerosol-type products in pressurized spray cans, such as hairspray, shaving cream, and spray sunscreen.
          </li>
          <li>
            Products that contain flammable, volatile or corrosive chemicals, such as: fragrances, nail polish and nail
            polish remover.
          </li>
          <li>
            Products that are, contain, or are packed with Lithium Metal or Lithium Ion batteries, such as rechargeable
            appliances or devices.
          </li>
        </ul>
        <p>
          We're committed to getting your order to you and your return from you as quickly as possible, but we also want
          to do it safely and responsibly, in compliance with DOT and USPS rules. That's why we require ground-only
          delivery, at our Standard Shipping rate, for orders that contain Shipping-Restricted products.
        </p>
        <p>
          We're unable to ship shipping-restricted items to Hawaii, Alaska, US territories, PO Boxes, and APO, FPO and,
          DPO military addresses.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is a flat shipping fee?",
    panel: (
      <>
        <p>
          A: A flat shipping fee is a shipping charge that applies only to certain products, and it applies regardless
          of additional shipping costs. Flat shipping fees are charged in addition to standard shipping fees when these
          products are shipped to the 48 contiguous states.
        </p>
        <p>
          If a flat shipping fee applies, you'll find it listed on a product's details page (for example, Flat shipping
          fee: $45). Shipping for other products in the same order will be calculated according to standard rates.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is overnight shipping?",
    panel: (
      <>
        <p>A: Overnight shipping is guaranteed to take 1 business day.</p>
        <p>
          Orders are handled by a variety of carriers. We select the carrier offering the best service at that time for
          that destination. Product shipping times may vary based on fulfillment center.
        </p>
        <p>
          An order must be placed by 3 p.m. EST for guaranteed 1-day shipping. Orders placed after 3 p.m. EST are
          processed the following business day instead.
        </p>
        <p>
          Orders shipping to PO Boxes, APO, FPO, DPO addresses, or outside the 48 contiguous states, are ineligible for
          overnight shipping.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How much is a flat shipping fee?",
    panel: (
      <>
        <p>
          A: The flat shipping fee varies by product. If the product has a flat shipping fee, it will be listed on the
          product description page (for example, Flat shipping fee: $45). This fee applies regardless of what else is in
          the order.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header:
      "Q: Is the product with a flat shipping fee in my cart included in my standard shipping order's total weight?",
    panel: (
      <>
        <p>
          A: No, products with flat shipping fees are not included in the standard shipping weight for your order. The
          flat shipping charge is additional.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I specify different shipping options in the same order?",
    panel: (
      <>
        <p>
          A: Yes, you can. Orders containing prescriptions, contact lenses, and/or retail products will ship separately.
          If you want your order to ship via expedited or overnight service, you will be charged the retail product,
          contact lens, and prescription shipping rates separately. Processing and shipping times for each will vary.
          However, some products are not eligible for expedited shipping options.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header:
      "Q: What if I place a product that is not eligible for expedited shipping in a cart with another product that is eligible for expedited shipping?",
    panel: (
      <>
        <p>
          A: If any items in your cart are not eligible for expedited shipping, the entire order will ship standard for
          free (up to 20 lbs., excluding flat fee products). You must place a separate order for any items you wish to
          ship via expedited shipping.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShippingProcessing = [
  {
    header: "Q: How will I know when my order has shipped?",
    panel: (
      <>
        <p>
          A: You will receive an email notification, including a tracking button you can use to check the progress of
          your shipment. If you have additional questions, please call Customer Care toll-free, at +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is the difference between processing time and delivery time?",
    panel: (
      <>
        <p>
          A: Processing time is the time from when you submit your order to when the product leaves the fulfillment
          center. Processing time is noted on the product description page. Delivery time is from when the product
          leaves the fulfillment center to when it arrives at its destination.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: When are orders processed?",
    panel: (
      <>
        <p>
          A: Orders start processing when you place your order and end when the product leaves the fulfillment center.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Do you process orders on weekends or holidays?",
    panel: (
      <>
        <p>
          Yes, orders are processed on weekends. Standard orders are shipped the next business day. Expedited orders may
          ship over the weekend if placed by 3 p.m. EST, but most likely will ship the following business day.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is the total delivery time for your order?",
    panel: (
      <>
        <p>
          A: The total delivery time for your order is the period of time from when you place your order until the time
          you receive it, which includes processing and shipping.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },

  {
    header: "Q: When are orders delivered?",
    panel: (
      <>
        <p>
          A: Orders shipped via USPS are delivered Monday through Saturday. Orders shipped via other carriers are
          typically delivered Monday through Friday. Saturday delivery is available via FedEx Home Delivery. Delivery is
          not available on Sundays.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How long does it take to receive a prescription by mail?",
    panel: (
      <>
        <p>
          A: Pharmacy orders are processed within 2 business days. After that, shipping typically takes 5 to 10 business
          days. Other variables may affect processing and shipping times. For instance, certain prescriptions may take
          longer if we need to contact your doctor or transferring pharmacy to verify the prescription. Often the Link
          Pharmacy pharmacist must wait to be called back. We do everything we can to make the fulfillment process as
          quick as possible. Please allow extra time for delivery to remote areas. If you have any questions, please
          call Link Pharmacy.com Customer Care toll-free, at +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShippingDestinations = [
  {
    header: "Q: Where do you ship?",
    panel: (
      <>
        <p>
          A: We ship to all 50 United States, APO/FPO/DPO addresses, as well as the following American territories:
          Puerto Rico, the US Virgin Islands, Guam, American Samoa, and the Northern Mariana Islands. You will be
          notified at checkout if a product in your order cannot be shipped to your selected location due to shipping
          restrictions.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Does Link pharmacy ship to PO Boxes?",
    panel: (
      <>
        <p>
          A: We ship to all 50 United States, APO/FPO/DPO addresses, as well as the following American territories:
          Puerto Rico, the US Virgin Islands, Guam, American Samoa, and the Northern Mariana Islands. You will be
          notified at checkout if a product in your order cannot be shipped to your selected location due to shipping
          restrictions.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },

  {
    header: "Q: Does Link Pharmacy ship to APO, FPO, and DPO addresses?",
    panel: (
      <>
        <p>
          A: Yes, Link pharmacy ships to APO, FPO, and DPO addresses. Enter your APO, FPO, or DPO address number in the
          street address field at checkout.
        </p>
        <p>
          We are proud to say all orders shipped to a US Military or Diplomatic address (APO, FPO, or DPO) are sent with
          no additional charge to our military customers. This is our way of thanking these customers for their service.
        </p>
        <p>
          Unfortunately, contact lenses and shipping-restricted items cannot be shipped to APO, FPO, and DPO addresses.
          If an item is shipping-restricted, you will be notified at checkout.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Does Link Pharmacy ship internationally?",
    panel: (
      <>
        <p>A: Not at this time.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Are there any products Link Pharmacy cannot ship to me?",
    panel: (
      <>
        <p>
          A:We cannot deliver shipping-restricted items outside the continental US, because they're shipped by air.
          According to federal laws, some products, such as flammable substances, cannot be shipped by air. In addition,
          some continental states have laws that may prevent one or more of your products from being delivered.
        </p>
        <p>
          In all instances, you will be notified at checkout if your order contains any of these restricted products.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },

  {
    header: "Q: Do I need to be home to sign for my home delivery?",
    panel: (
      <>
        <p>
          A: Only for select products or prescriptions. This information will be included with the product description.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I change the ship to address?",
    panel: (
      <>
        <p>A: The best time to do this is before you place an order.</p>
        <ol>
          <li>
            Go to <a href="/#">Your Account Home Page</a>. If you are not signed in, you will be prompted to do so.
          </li>
          <li>Select Settings & Payments, then Personal Information.</li>
          <li>Select Edit, update your address, and then use the blue Save Changes button.</li>
        </ol>
        <p>
          Due to automated processing, we are unable to alter any information on an online store order after it has been
          submitted. If you've already submitted your order, please contact Customer Care toll-free at +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },

  {
    header:
      "Q: Why am I getting an error message that my shipping address is invalid when I know I am entering it correctly?",
    panel: (
      <>
        <p>
          A: New homes or developments may not yet be incorporated into our address verification system. Please try your
          address again. If your second attempt fails, please call Customer Care toll-free, at +260 211 255 556
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why did my shipping address update automatically?",
    panel: (
      <>
        <p>
          A: Our address verification suggests changes in order to ensure your package is delivered as quickly as
          possible. Issues with an address could delay delivery. There are also smaller changes to the address where,
          for instance, "Drive" is changed to "DR". We are checking addresses against the United States Postal Service
          database.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShipToStoreProgramDetails = [
  {
    header: "Q: What is Ship to Store?",
    panel: (
      <>
        <p>
          A: When you place an order on linkpharmacy.co.zm, you can choose to have it delivered to your local store for
          FREE. This is a convenient option if you are unable to accept a delivery at your home or your workplace. Plus,
          we'll keep your order safe until you're ready to pick it up.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I ship my order to a store?",
    panel: (
      <>
        <p>A: When you start the checkout process, we'll check whether your order is eligible for Ship to Store</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Is there a minimum order amount for Ship to Store?",
    panel: (
      <>
        <p>A: No. There is no minimum order to have your item(s) Ship to Store for FREE.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShipToStorePickups = [
  {
    header: "Q: How long until my Ship to Store order is ready to pick up?",
    panel: (
      <>
        <p>
          A: Standard shipping takes 1 to 7 business days on most orders inside the 48 contiguous states. 1 to 7 day
          shipping is dependent on item being in stock at time of order. Please note that there may be delays in
          inventory levels shown on linkpharmacy.co.zm and what is actually held in inventory.
        </p>
        <p>
          <b>
            NOTE: Delivery times in some areas within the 48 contiguous states and all areas outside the 48 contiguous
            states depend on the remoteness of the area.
          </b>
        </p>
        <p>After your item has been delivered to the store, we'll email you that your package is ready to pick up.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I pick up my Ship to Store order?",
    panel: (
      <>
        <p>A: It's easy:</p>
        <ul>
          <li>Wait until you receive the 'Ready for Pickup' email.</li>
          <li>
            You or your designated Pickup Person will need to bring photo identification or the 'Ready for Pickup'
            email.
          </li>
          <li>Go to the photo counter at the Link pharmacy store you selected, and we'll go get your order!</li>
        </ul>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },

  {
    header: "Q: I haven't received my 'Ready for Pickup' email - what do I do?",
    panel: (
      <>
        <p>
          A: Use the tracking number from your shipment confirmation email to see if your order is still in transit. If
          you already referenced the tracking number and it appears as if your order was delivered, you can call
          Customer Service for confirmation: +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Is there a minimum order amount for Ship to Store?",
    panel: (
      <>
        <p>A: No. There is no minimum order to have your item(s) Ship to Store for FREE.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },

  {
    header: "Q: Can I have someone else pick up my order for me?",
    panel: (
      <>
        <p>
          A: Yes! You can pick up the order yourself, or designate a Pickup Person during checkout. This information
          cannot be changed in our system once an order is placed, but we understand plans can change fast! So for your
          convenience as long as you or a Pickup Person shows photo ID and the Ready for Pickup email, we can release
          the order.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I pay for a Ship to Store order?",
    panel: (
      <>
        <p>A: You can pay for Walgreens.com orders with a debit card, Visa, MasterCard, or PayPal.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What happens if I don't pick up my order?",
    panel: (
      <>
        <p>
          A: You will receive a reminder email 7 days after your order is ready to be picked up. If you haven't picked
          up your order after 21 days it will be sent back to us. We'll issue a full refund to you within 30 days of it
          arriving back at our warehouse.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShipToStoreCancelations = [
  {
    header: "Q: Can I make a change to my Ship to Store order?",
    panel: (
      <>
        <p>A: Once your Ship to Store order is placed, it cannot be modified.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I cancel my Ship to Store order?",
    panel: (
      <>
        <p>
          A: Yes. You can cancel your Ship to Store order if you contact Customer Service at +260 211 255 556 within 30
          minutes of when you place the order.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I return my order to a store for a refund?",
    panel: (
      <>
        <p>
          A: Yes, products purchased from linkpharmacy.co.zm may be returned either to a store or by mail within 30 days
          of purchase and must be accompanied by the original receipt. Please see our linkpharmacy.co.zm return policy
          for details.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: ">Q: Who do I contact if I need help with a Ship to Store order?",
    panel: (
      <>
        <p>A: Contact us right away at +260 211 255 556 with any questions.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },

  {
    header: "Q: How do I return Contact Lenses that shipped to a store?",
    panel: (
      <>
        <p>
          A: Contact lenses can be shipped back to us for a full refund. For your convenience, the instructions and
          return shipping label can be found in the same package as your contact lens order. Do not return contact lens
          orders to a store.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I return products that shipped to a store?",
    panel: (
      <>
        <p>
          A: Your products can be returned to a store or by mail within 30 days of purchase and must be accompanied by
          the original receipt. Please see our linkpharmacy.co.zm return policy for details.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShopOnlineAssistance = [
  {
    header: "Q: How do I shop linkpharmacy.co.zm?",
    panel: (
      <>
        <p>
          A: Use the toolbar at the top of every linkpharmacy.co.zm page to select and browse a product category. For
          prescription orders, click on the Pharmacy & Health tab at the top of any page under the Link pharmacy logo.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is the fastest way to find what I need?",
    panel: (
      <>
        <p>A: Click on the Search box on the Home Page and type in a keyword.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I search linkpharmacy.co.zm?",
    panel: (
      <>
        <p>
          A: In the search box at the top of linkpharmacy.co.zm, type a keyword and click Search. If you're looking for
          a specific item, you can also type the Item Number, WIC (product code), SKU or UPC (leaving the last digit
          off) into the search box.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: I used the Search feature, but I still can't find what I need. Can you help me find it?",
    panel: (
      <>
        <p>
          A: After you have tried the suggested key words, try these options: 1) Try something less specific, instead of
          deodorant soap, try soap. 2) Try a synonym for the product. For example, instead of enteric aspirin, try
          low-dose aspirin. 3) Try a different category. For example, cotton balls may not only be in the beauty area,
          they may also be in the first aid area
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Are product prices online the same as prices in my Link pharmacy store?",
    panel: (
      <>
        <p>
          A: No. Pricing may differ online and in-store. Upon request, Link pharmacy stores will price match to Link
          Pharmacy.com Regular price. Online sale/promotional offers, pricing resulting from a typographical error,
          previous days pricing, contact lenses, photo, pharmacy, and health services are excluded. Item must be
          identical in brand name, description, size, weight, color, quantity and model number. If item is out of stock
          at the store, no rain checks will to be issued for a price match. Link Pharmacy team member will validate
          price. Screen shots or printouts will not be accepted as proof of price. Price match is limited to one
          transaction per customer per day. Stores reserve the right to limit the amount of price adjustments made in a
          single transaction. Price Match requirements are subject to change.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is the difference between a Shopping Cart and a Shopping List?",
    panel: (
      <>
        <p>
          A: <b>Shopping List</b>
        </p>
        <p>
          Your Shopping List saves products you would like to order later. You must be registered and signed in to use
          your Shopping List. To add a product to your Shopping List, click the gray Add to Shopping List button below
          the item price and quantity. A popup confirmation will display where you can Keep Shopping or View Your List.
          You can also click the red X to continue shopping.
        </p>
        <p>
          If you'd like to buy a product from your Shopping List, click the blue Add to Cart button next to its
          individual listing. To select multiple items from your Shopping List to add to your cart, click the checkbox
          next to each item you'd like to add and click the blue Add Selected to Cart button at the top of your list. To
          remove a product from your list, click the blue Remove From List link next to that product.
        </p>
        <p>
          You can view your Shopping List at any time by selecting Shopping List from the drop down menu at the top
          right of any page.
        </p>
        <p>
          <b>Shopping Cart</b>
        </p>
        <p>
          Your Shopping Cart holds your selections until you check out. To choose a new product, click the blue Add to
          Cart button below the item price and quantity. To remove a product from your cart, click the blue Remove link
          next to that product on the right navigation column. To change the quantity, enter the new number in the Qty
          field and click Update. You can view the contents of your cart at any time by selecting the Cart icon at the
          top of any page.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How long will my Shopping Cart hold my selected products?",
    panel: (
      <>
        <p>
          A: Your <b>Shopping Cart</b> will hold your products for 30 days.
        </p>
        <p>
          <b>NOTE: Please remove out of stock products from your Shopping Cart before you check out.</b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I check my store's inventory for products?",
    panel: (
      <>
        <p>Q: Will Link pharmacy accept manufacturer's coupons printed from the Internet?</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Will Link pharmacy accept manufacturer's coupons printed from the Internet?",
    panel: (
      <>
        {" "}
        <p>
          A: Sure, but only for in-store purchases and as long as they are valid manufacturer's coupons. Browse Coupons
          to select and print coupons to use on your next trip to Link pharmacy.
        </p>
      </>
    ),
  },
  {
    header: "Q: How can I submit a product suggestion?",
    panel: (
      <>
        <p>
          A: Follow Product Suggestion to enter your idea(s) and your contact information. When you're done, click
          Submit at the bottom of the page.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShopOnlineProducts = [
  {
    header: "Q: Can I cancel my online product order?",
    panel: (
      <>
        <p>
          A: Product orders without contact lenses can be cancelled within 30 minutes of order placement. Please contact
          Customer Service, at +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I change or add more to my order after I have submitted it?",
    panel: (
      <>
        <p>A: After you have submitted your order, it is complete. You will need to submit another order.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Is there a limit on how much I can order?",
    panel: (
      <>
        <p>
          A: Link Pharmacy reserves the right to set quantity-per-order limits on any and all items sold on
          linkpharmacy.co.zm. Our current quantity limit for most linkpharmacy.co.zm items is 30. This limit is to
          prevent excessively large orders that could deplete our inventory and inconvenience other customers wanting to
          order the same item. Link pharmacy may also limit the item quantity available to any single person or
          household over a given period of time. These restrictions may be applied to orders using the same credit card,
          billing address, or shipping address.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I receive products or contact lens orders with my prescriptions?",
    panel: (
      <>
        <p>
          A: Sorry, no. We process prescriptions, contact lenses and product orders separately. You will receive
          separate shipments for each type of order and separate shipping charges will apply.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: I did not receive all of my products. Will Link pharmacy ship the balance later?",
    panel: (
      <>
        <p>
          A: We make every effort to complete your order as requested. If a product you requested is not available at
          the time of processing, we will not create a back order. We re-stock our inventory regularly, so please check
          linkpharmacy.co.zm within 5 to 7 business days; you may be able to order the product again.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I pre-order a product?",
    panel: (
      <>
        <p>
          A: Yes. A Pre-Order button appears next to the products that qualify. Select Pre-Order and the item will be
          placed in your shopping cart. Your credit card will not be charged until we ship the item to you.
        </p>
        <p>
          To pre-order multiple items, please place a separate order for each one. They will be shipped separately. The
          estimated release date (when the product will be available for sale) is listed below the Pre-Order button on
          the product page.
        </p>
        <p>
          Pre-orders cannot be added to a regular online order due to the future release date of the pre-order product.
        </p>
        <p>
          As soon as your pre-order ships, we will send an email to let you know it's on its way. Each pre-order will
          ship separately. Standard shipping charges apply to pre-orders. See Shipping for details.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I check my pre-order status?",
    panel: (
      <>
        <p>A: Go to the product details page. The estimated release date is located above the Pre-Order Now button.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShopOnlinePharmacy = [
  {
    header: "Q: Now that I am fully registered, how do I order prescriptions?",
    panel: (
      <>
        <p>
          A: You can manage Refill Prescriptions or order Express Refills from the bright blue Pharmacy box on the
          linkpharmacy.co.zm Home Page, or select the pharmacy tab to display all Pharmacy options. Select your
          prescription ordering method under the Manage Your Prescriptions header in the left column.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: I only want to see the prescriptions I need to order today. How can I edit my list?",
    panel: (
      <>
        <p>
          A: On the Manage Prescriptions page under the Prescription History tab, select the checkbox next to the
          prescription you'd like to hide from your active list. Click the Hide from List link to hide an expired or
          infrequently ordered prescription. Your custom list will now be displayed each time you order refills. You can
          always view this prescription again by selecting the Yes radio button next to Show Hidden Prescriptions?.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: I only want to see the prescriptions I need to order today. How can I edit my list?",
    panel: (
      <>
        <p>
          A: On the Manage Prescriptions page under the Prescription History tab, select the checkbox next to the
          prescription you'd like to hide from your active list. Click the Hide from List link to hide an expired or
          infrequently ordered prescription. Your custom list will now be displayed each time you order refills. You can
          always view this prescription again by selecting the Yes radio button next to Show Hidden Prescriptions?.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I view or hide prescriptions for family members?",
    panel: (
      <>
        <p>
          A: Yes, you can create a list of prescriptions for family members on a regular basis and hide those you do
          not. On the Order Refills page, select the bright blue Edit List button near the top of the right navigation
          column to see the current prescription views for your Family Prescriptions account. You can use the drop-down
          menu to View All family members or individual family members. Next to each prescription, you will see two
          radio buttons where you can select Show or Hide for each prescription. Select the bright blue Save button to
          continue. Your custom list will then be displayed each time you order refills.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How much will my shipped prescription cost?",
    panel: (
      <>
        <p>
          A: At this time, we are unable to provide the subtotal or your prescription order online. To find out if a
          specific medication or quantity is covered by your standard co-pay, please contact your prescription insurance
          provider directly. Any prescription plans or drug discount programs you are enrolled in may affect your
          prescription drug prices. For pricing on prescriptions to be picked up at Link pharmacy, follow{" "}
          <a href="/#">Store Locator</a>. (Hours vary by location.)
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How much will my mail prescription cost?",
    panel: (
      <>
        <p>
          A: At this time, we are unable to provide the subtotal of your prescription order online. To find out if a
          specific medication or quantity is covered by your standard co-pay, please contact your prescription insurance
          provider directly. Any prescription plans or drug discount programs you are enrolled in may affect your
          prescription drug prices. For pricing on prescriptions to be picked up at Link Pharmacy, visit the{" "}
          <a href="/#">Store Locator</a>.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpShopOnlineOrdering = [
  {
    header: "Q: What is In-Store Ordering?",
    panel: (
      <>
        <p>
          A: In-Store Ordering is another convenient shopping option. Our associates will order products for you from
          linkpharmacy.co.zm in the store and you can choose whether you want your order shipped to your selected Link
          pharmacy store or to your home (exclusions apply). Once your order is submitted, you will be issued an Order
          Request Form. To pay for your order, bring your Order Request Form to any register. Your order will start
          processing as soon as you pay for it. If you provided your email address, you will be contacted by email when
          your order is shipped/ready for pickup.
        </p>
        <p>
          <b>
            NOTE: Your order is not complete unless paid for in a Link pharmacy store before midnight the day the order
            is placed. This order request will be valid for payment until 12:00 A.M. the same day of your order.
          </b>
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I combine my product order with my contact lens order?",
    panel: (
      <>
        <p>
          A: Yes, you can, but they are shipped separately. If you want your order to ship via expedited or overnight
          service, you will be charged the product, contact lens and prescription shipping rates separately. Processing
          and shipping times for mixed orders will vary.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How will I know if my in-store order has been delivered?",
    panel: (
      <>
        <p>
          A: You'll get an email when your order is delivered and ready to pick up. In the meantime, you can check the
          status of your order online. If you don't have a linkpharmacy account, contact Customer Service toll-free at
          +260 211 255 556.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What are the payment options for in-store ordering?",
    panel: (
      <>
        <p>
          A: Link pharmacy stores accept cash, check, gift card, debit card, Visa, MasterCard, Discover Network or
          American Express.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpStorePickupOrder = [
  {
    header: "Q: Can I order online at Link pharmacy and get my Pickup order at the store?",
    panel: (
      <>
        <p>
          A: Yes! Our Pickup service makes it easy to shop your local store online at linkpharmacy.co.zm or with the
          Link pharmacy App, then pick up your order in as little as 30 minutes.* Prescriptions are separate
          transactions from retail Pickup orders and must be paid for separately. For your convenience, you can get both
          your Pickup order and your prescriptions at the pharmacy. Get your prescriptions even faster when you prepay
          with Link pharmacy Express. *For link pharmacy store locations that are not open 24 hours, orders must be
          placed at least one hour prior to store closing in order to be eligible. Otherwise, order will be ready the
          following business day. Customer will be notified via email when order is ready for pickup and will be
          provided instructions for a drive-up experience that complies with social distancing guidelines. Orders are
          not guaranteed to be ready within the 30-minute time window and may be subject to change or substitution
          depending on product availability at the time order is placed. Orders with age-restricted items may only be
          picked up in store. Prescription orders not eligible but may be ordered through Link pharmacy Express®. To
          find the location and hours of a Link pharmacy store near you, visit linkpharmacy.co.zm
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I place an order for someone else to pick up?",
    panel: (
      <>
        <p>A: Yes! You can add an alternate pickup person when placing your order.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I choose some items for Pickup and have others shipped?",
    panel: (
      <>
        <p>
          A: Yes, you can pick up items and ship others in the same transaction. Your Pickup order will be ready the
          same day or next day if your order is placed an hour before closing time, or if the store is already closed
          for the evening. Shipped orders will arrive several business days later.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How long will it take for my order to be ready?",
    panel: (
      <>
        <p>
          A: Your order will be ready in as little as 30 minutes, unless your order is placed an hour before closing
          time or the store is already closed for the evening—in that case your order will be ready the next day. We'll
          send an Order Is Ready for Pickup notification via email or text to let you know when it's ready.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I place an order for Pickup?",
    panel: (
      <>
        <p>
          A: It's easy! Shop online at linkpharmacy.co.zm or use the Link Pharmacy App. Make sure to select the right
          store to pick up your order before completing your purchase.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I purchase my Rx with Retail Products?",
    panel: (
      <>
        <p>
          A: Prescriptions are separate transactions from retail Pickup orders and must be paid for separately. For your
          convenience, you can get both your Pickup order and your prescriptions at the pharmacy.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpStorePickupProgramDetails = [
  {
    header: "Q: How does Pickup at Link Pharmacy work?",
    panel: (
      <>
        <p>
          A: Choose your store and shop products on linkpharmacy.co.zm or the Link Pharmacy App. Then choose to pick up
          curbside, at the drive-thru or in store. It's easy: Shop online, wait for your Order Is Ready for Pickup
          notification via email or text, then visit the store and tap the "I'm here" button in the email or text link
          to let us know. A team member will bring the order to you.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Which items are eligible for Pickup at Link Pharmacy?",
    panel: (
      <>
        <p>
          A: Most store items are eligible for our Pickup service. Excluded items include tobacco, non-denominated gift
          cards, items containing pseudoephedrine, select assortments, magazines, newspapers and any item sold
          exclusively online and is not available at stores.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Is there a fee for Pickup?",
    panel: (
      <>
        <p>A: No, our Pickup service is free!</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Which stores offer Pickup?",
    panel: (
      <>
        <p>
          A: Pickup service is available at most Link Pharmacy stores. However, our specialty pharmacies will not offer
          this service.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpStorePickupProcess = [
  {
    header: "Q: How will I know when my Pickup order is ready?",
    panel: (
      <>
        <p>
          A: You'll be notified via email or text when your order is ready for pickup with instructions on how to pick
          up your order. If you opted in to receive text messages you will also receive a text when your order is read
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What happens if I don't pick up my order in time?",
    panel: (
      <>
        <p>
          A: You have 72 hours to pick up your order from the time your Order Is Ready for Pickup notification email or
          text is sent. Don't worry, you'll receive two reminders first. If your order still isn't picked up after 72
          hours then it will be canceled.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can a family member or friend pick up my order?",
    panel: (
      <>
        <p>A: Yes! You can add an alternate person to get your Pickup order.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How can I get my Pickup order?",
    panel: (
      <>
        <p>
          A: Our pickup options include curbside, drive-thru and in store at the photo counter. We’ll let you know in
          your ready for pickup email or text what options are available for your order. Age restricted items (e.g.
          alcohol) must be picked up in store
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpStorePickupCancellations = [
  {
    header: "Q: Can I cancel my Pickup order at Link Pharmacy?",
    panel: (
      <>
        <p>A: Yes, please call the store to cancel your order.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What happens if an item in my order is out of stock?",
    panel: (
      <>
        <p>
          A: If you indicated that you're okay with substitutions during online checkout, the store will do its best to
          find an item from the same brand in a similar size. You may receive a call from the store to ask if an
          alternate brand or product meets your needs instead. If you choose not to accept substitutions, then the item
          will be marked out of stock. Any substituted or out-of-stock items will be indicated in your Order Is Ready
          for Pickup email
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpStorePickupPricing = [
  {
    header: "Q: Can I get in-store deals and promotional offers on my Pickup order?",
    panel: (
      <>
        <p>
          A: Yes, as long as your Balance® Rewards or mylink Pharmacy account is linked to your linkpharmacy.co.zm
          account. Most store promotions are automatically included with your order, but digital or paperless coupons
          must be clipped to your account to be applied to your Pickup order at online checkout.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I apply coupons, promotions and discounts to my Pickup order?",
    panel: (
      <>
        <p>A: Yes! You can apply coupons, promotions and discounts to your Pickup order at online checkout.</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPharmacyRegistraion = [
  {
    header: "Q: Why should I create a pharmacy registration?",
    panel: (
      <>
        <p>
          A: Once your online pharmacy account is active, you will be able to refill prescriptions as well as view
          and/or print your prescription history from the last 18 months
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I create a pharmacy registration on linkpharmacy.co.zm",
    panel: (
      <>
        <p>A: To create a Link Pharmacy account:</p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: What is an activation code?",
    panel: (
      <>
        <p>
          A: An activation code is a unique 8-digit computer-generated number for use in pharmacy account phone
          verification. It adds another layer of security to ensure that your prescription information is released to
          you, alone. If your activation code doesn't work, you will be automatically connected to a Customer Service
          agent. If you are not automatically connected, please call Customer Service toll-free, at 1-877-250-5823.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: How do I use the activation code to fully register my pharmacy account?",
    panel: (
      <>
        <p>
          A: During pharmacy registration, you will be directed to the Complete Your Verification page. You will see the
          Verify by Phone area which features a computer-generated activation code. Next, call 1-800-573-3586 and you
          will be prompted to enter your activation code on your phone keypad. This will establish your account and
          ensure that you, alone, have access to your personal information. If our automated phone system is unable to
          verify your account, you will be transferred to a Customer Service agent for verification assistance. If you
          are not automatically connected, please call Customer Service toll-free, at 1-877-250-5823.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Why do I need to provide personal information?",
    panel: (
      <>
        <p>
          A: Your personal identifying information, such as your date of birth, is only used to confirm your identity
          and to make sure that the person requesting access is really you. This process helps to ensure that
          unauthorized individuals do not have access to your pharmacy records online.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Where did Link pharmacy get my personal information?",
    panel: (
      <>
        <p>
          A: The information for the online verification process is obtained from the same institutions that banks,
          credit card companies and other organizations use for identity confirmation. The verification questions ensure
          that your information is released to you, alone. The information is used for verification purposes only and
          will not be saved or stored for reference on your account.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
export const helpPreOrderShipping = [
  {
    header: "Q: How will I know when my Pre-Order product has shipped?",
    panel: (
      <>
        <p>
          A: You will receive an email notification when your product ships that will include any available tracking
          information from the carrier.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
  {
    header: "Q: Can I get free shipping on a Pre-Order product?",
    panel: (
      <>
        <p>
          A: Yes. Pre-Order products are eligible for Free Shipping as long as your order total meets the Free Shipping
          requirements.
        </p>
        <a href="/#">Did this answer your question?</a>
      </>
    ),
  },
];
