import { useField } from "formik";

import ErrorMessage from "./ErrorMessage";
import Select from "../Select";

const SelectField = ({ className, label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <label className="w-full flex flex-col items-center self-center">
      <Select
        label={label}
        {...field}
        className={className}
        bgColor={props.bgColor ? props.bgColor : "bg-slate-100"}
        {...props}
      />
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </label>
  );
};
export default SelectField;
