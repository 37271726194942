// Zambian Cities
const cities = [
  "Chililabombwe",
  "Chingola",
  "Chipata",
  "Choma",
  "Kabwe",
  "Kasama",
  "Kitwe",
  "Livingstone",
  "Luanshya",
  "Lusaka",
  "Mansa",
  "Mazabuka",
  "Mongu",
  "Monze",
  "Mufulira",
  "Ndola",
  "Solwezi",
];

export default cities;
