import { Link } from "react-router-dom";
import style from "../../styles/views/BecomeASupplier.module.css";
// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

const SupplierSourcing = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="sup_know">
        <div className="row">
          <div className="col-md-2">
            <div className={style.supplier_home}>
              <Link to="/become-a-supplier">
                <i className="fa fa-angle-left"></i> suppliers
              </Link>
            </div>
          </div>
          <div className="col-md-8">
            <div className={style.sup_know_header}>
              <h1>Responsible Sourcing</h1>
              <p>
                We respect human rights. Link pharmacy's size and international
                footprint, we have an opportunity to improve supply chain
                conditions and bring about positive change at scale. From
                analyzing issues to find root-cause solutions to embedding
                responsible sourcing practices into buying decisions, we’re
                working with others to help prevent forced labor, address unsafe
                working conditions and promote the dignity of women. Link
                pharmacy is one actor among many, but together – with our
                suppliers, other companies, governments and nonprofit
                organizations – we can drive responsibility in our supply chain,
                and to lead and inspire others to do the same.
              </p>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </section>
    </Layout>
  );
};
export default SupplierSourcing;
