/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable array-callback-return */
import { Fragment, useState } from "react";
import {
  MdOutlineRoom,
  MdOutlineHearing,
  MdOutlineShoppingBasket,
  MdEnhancedEncryption,
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
  MdOutlineLocalHospital,
} from "react-icons/md";
import { FaPrescriptionBottle, FaHospitalUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import { useQuery } from "react-query";

import styles from "../styles/components/LeftMenuContent.module.css";
import useLeftMenu from "../hooks/useLeftMenu";
import Overlay from "./Overlay";

const MenuLeft = ({ toggleLeftMenu }) => {
  const [clicked, setClicked] = useState(0);
  const [catClicked, setCatClicked] = useState(0);
  const [subCatClicked, setSubcatClicked] = useState(0);
  const [firstClicked, setFirstClicked] = useState("");
  const [secondClicked, setSecondClicked] = useState("");
  const [isCatClicked, setIsCatClicked] = useState(false);
  const [isSubCatClicked, setIsSubcatClicked] = useState(false);
  const { sortCategories } = useLeftMenu();

  // Fetch menu categories from backend
  const { data: menu } = useQuery(
    "menu-categories",
    async () => {
      const res = await axios.get("/v1/website/menus");

      return sortCategories(res);
    },
    { placeholderData: [] }
  );

  const firstStepClicked = (name) => {
    setClicked(1);
    setFirstClicked(name);
  };

  const secondStepClicked = (name) => {
    setClicked(2);
    setSecondClicked(name);
  };

  const shopClicked = (id, type) => {
    switch (type) {
      case "cat":
        setCatClicked(id);
        setIsCatClicked(true);
        break;

      case "subcat":
        setSubcatClicked(id);
        setIsSubcatClicked(true);
        break;
    }
  };

  const backBtn = () => {
    if (firstClicked !== "shop_products") {
      setClicked(clicked > 0 ? clicked - 1 : clicked);
      setIsSubcatClicked(false);
      setIsCatClicked(false);
    } else {
      if (isCatClicked === true && isSubCatClicked === false) {
        setIsCatClicked(false);
      }

      if (isCatClicked === true && isSubCatClicked === true) {
        setIsSubcatClicked(false);
      }

      if (isCatClicked === false && isSubCatClicked === false) {
        setClicked(0);
        setIsSubcatClicked(false);
        setIsCatClicked(false);
        setFirstClicked("");
      }
    }
  };

  return (
    <Overlay handleClose={toggleLeftMenu}>
      <motion.div
        initial={{ height: "0px" }}
        animate={{ height: "auto" }}
        transition={{ duration: 0.3 }}
        exit={{ height: 0 }}
        className={`${styles.left_content} font-opensans text-sm scrollbar scrollbar-thumb-link-green scrollbar-track-scrollbar-bg scrollbar-thin`}
        onClick={(e) => e.stopPropagation()}
      >
        {clicked === 0 && (
          <ul className="list-group">
            <Link to="/find-a-store">
              <li className={`list-group-item ${styles.list_group_item}`} onClick={toggleLeftMenu}>
                <MdOutlineRoom
                  style={{
                    float: "left",
                    marginRight: "20px",
                    width: "20",
                    fontsize: "25px",
                    color: "#B9D305",
                  }}
                />{" "}
                Find a store
              </li>
            </Link>

            <li
              className={`list-group-item ${styles.list_group_item}`}
              onClick={() => firstStepClicked("prescriptions")}
            >
              <FaPrescriptionBottle
                style={{
                  float: "left",
                  marginRight: "24px",
                  width: "20px",
                  fontsize: "25px",
                  color: "#B9D305",
                }}
              />{" "}
              {"Prescriptions"}
              <MdOutlineArrowForwardIos style={{ float: "right", fontSize: 16 }} />
            </li>

            <li
              className={`list-group-item ${styles.list_group_item}`}
              onClick={() => firstStepClicked("health_services")}
            >
              <MdEnhancedEncryption
                style={{
                  float: "left",
                  marginRight: "20px",
                  width: "20",
                  fontsize: "25px",
                  color: "#B9D305",
                }}
              />{" "}
              Health services
              <MdOutlineArrowForwardIos style={{ float: "right", fontSize: 16 }} />
            </li>
            <Link to="/ear-piercing">
              <li className={`list-group-item ${styles.list_group_item}`}>
                <MdOutlineHearing
                  style={{
                    float: "left",
                    marginRight: "20px",
                    width: "20",
                    fontsize: "25px",
                    color: "#B9D305",
                  }}
                />{" "}
                {"Ear Piercings"}
              </li>
            </Link>

            <li className={`list-group-item ${styles.list_group_item}`} onClick={() => firstStepClicked("mediclinic")}>
              <MdOutlineLocalHospital
                style={{
                  float: "left",
                  marginRight: "20px",
                  width: "20",
                  fontsize: "25px",
                  color: "#B9D305",
                }}
              />{" "}
              Mediclinic
              <MdOutlineArrowForwardIos style={{ float: "right", fontSize: 16 }} />
            </li>

            <li
              className={`list-group-item ${styles.list_group_item}`}
              onClick={() => firstStepClicked("shop_products")}
            >
              <MdOutlineShoppingBasket
                style={{
                  float: "left",
                  marginRight: "20px",
                  width: "20",
                  fontsize: "25px",
                  color: "#B9D305",
                }}
              />{" "}
              Shop products
              <MdOutlineArrowForwardIos style={{ float: "right", fontSize: 16 }} />
            </li>

            <hr />

            <Link to="/account?t=prescription-history">
              <li className={`list-group-item ${styles.list_group_item}`} onClick={toggleLeftMenu}>
                <FaHospitalUser
                  style={{
                    float: "left",
                    marginRight: "20px",
                    width: "20",
                    fontsize: "25px",
                    color: "#B9D305",
                  }}
                />{" "}
                Patient Portal
              </li>
            </Link>
          </ul>
        )}

        {clicked === 1 && (
          <>
            <div
              style={{
                padding: "1rem",
                borderBottom: "1px solid black",
              }}
            >
              <h6 className={styles.back_btn} style={{ cursor: "pointer" }} onClick={backBtn}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  <MdOutlineArrowBackIos style={{ fontSize: 15, display: "inline-block" }} />
                  &nbsp;{" Back"}
                </span>
              </h6>
            </div>

            <ul className="list-group">
              {/*Prescriptions Links */}
              {firstClicked === "prescriptions" && (
                <>
                  <Link to="/upload-prescription">
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>{"Upload New"}</li>
                  </Link>
                  <Link to="/account?t=prescription-history">
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"My Prescriptions"}
                    </li>
                  </Link>
                  <Link to={"/pack-my-meds"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>{"Pack My Meds"}</li>
                  </Link>
                  <Link to={"/specialty-pharmacy"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Speciality Pharmacy"}
                    </li>
                  </Link>
                  <Link to={"/medication-compounding"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Medication Compounding"}
                    </li>
                  </Link>
                  {/*<Link to={"/refills-and-auto-refills"}>*/}
                  {/*  <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>*/}
                  {/*    {"Refills & Autorefills"}*/}
                  {/*  </li>*/}
                  {/*</Link>*/}
                  <Link to={"/insurance-coverage"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Insurance Coverage"}
                    </li>
                  </Link>
                </>
              )}
              {/* Health Services Links */}
              {firstClicked === "health_services" && (
                <>
                  <Link to={"/check-my-meds"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Check My Meds"}
                    </li>
                  </Link>
                  <Link to={"/blood-pressure-screening"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Blood Pressure Screening"}
                    </li>
                  </Link>
                </>
              )}
              {/* Mediclinic Links */}
              {firstClicked === "mediclinic" && (
                <>
                  <Link to={"/find-a-mediclinic"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Find Care Near You"}
                    </li>
                  </Link>
                  <Link to="/appointment-booking">
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Appointment Booking"}
                    </li>
                  </Link>{" "}
                  <Link to="/online-doctor">
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Online Doctor"}
                    </li>
                  </Link>
                  <Link to="/in-store-clinics">
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"In-Store Clinics"}
                    </li>
                  </Link>
                  <Link to={"/immunisation"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>{"Immunisation"}</li>
                  </Link>
                  <Link to={"/flushots"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>{"Flu Shots"}</li>
                  </Link>
                  <Link to={"/lancet-labs"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Lancet Laboratories"}
                    </li>
                  </Link>
                  <Link to={"/insurance-coverage"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Insurance Coverage"}
                    </li>
                  </Link>
                  <Link to={"/hospital-at-home"}>
                    <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                      {"Hospital at Home"}
                    </li>
                  </Link>
                </>
              )}
            </ul>
          </>
        )}

        {clicked === 2 && (
          <>
            <div
              style={{
                padding: "1rem",
                borderBottom: "1px solid black",
              }}
            >
              <h6 style={{ cursor: "pointer" }} onClick={backBtn}>
                <span>
                  <MdOutlineArrowBackIos style={{ fontSize: 15 }} />
                  &nbsp; Back
                </span>
              </h6>
            </div>

            <ul className="list-group">
              {/* Display after prescriptions is clicked */}

              {secondClicked === "account_prescription_settings" && (
                <>
                  <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>
                    Email & Notifications
                  </li>
                  <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>Insurance Coverage</li>
                  <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>Health History</li>
                  <li className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}>Your Account</li>
                </>
              )}
            </ul>
          </>
        )}

        {/* Clicks for the shop on menu */}
        {firstClicked === "shop_products" && (
          <>
            <ul className="list-group">
              {/* All the categories on the menu */}
              {menu?.map((men) => {
                if (isCatClicked === false && men.name.includes("Shop All")) {
                  return (
                    <>
                      <li
                        className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}
                        key={men.id}
                        onClick={() => shopClicked(men.category_id, "cat")}
                        style={{ fontFamily: '"OpenSans-Bold"' }}
                      >
                        {men.name}
                      </li>
                    </>
                  );
                }
              })}

              {menu?.map((men, index) => (
                <Fragment key={index}>
                  {isCatClicked === false && menu[index - 1]?.name?.includes("Seasonal") && <hr key={"main-divider"} />}

                  {isCatClicked === false &&
                    !men.name.includes("Dispensa") &&
                    !men.name.includes("Supervised") &&
                    !men.name.includes("Shop All") && (
                      <>
                        <li
                          className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}
                          key={men.id}
                          onClick={() => shopClicked(men.category_id, "cat")}
                        >
                          {men.name}
                        </li>
                      </>
                    )}

                  {isCatClicked === true && catClicked === men.category_id && (
                    <>
                      {men.subcategories.map((sub, index) => {
                        if (isCatClicked && sub.type === "divider") return <hr key={"subcategory-divider"} />;

                        return (
                          <>
                            {isSubCatClicked === false && (
                              <>
                                <li
                                  className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}
                                  key={sub.id}
                                  onClick={() => shopClicked(sub.subcategory_id, "subcat")}
                                  style={{
                                    fontFamily: sub.isBold ? "OpenSans-Bold" : "Open Sans",
                                  }}
                                >
                                  {sub.name}
                                </li>
                              </>
                            )}

                            {isSubCatClicked === true && subCatClicked === sub.subcategory_id && (
                              <>
                                {sub.product_types.map((prod_types, index) => {
                                  if (isSubCatClicked && prod_types.type === "divider")
                                    return <hr key={"product-type-divider"} />;

                                  return (
                                    <>
                                      {prod_types.name.indexOf("Shop") === -1 ? (
                                        <a
                                          href={`/store/catalog/${men.name.replace(" ", "-")}/${
                                            men.category_id
                                          }/${sub.name.replace(" ", "-")}/${
                                            sub.subcategory_id
                                          }/${prod_types.name.replace(" ", "-")}/${prod_types.product_type_id}`}
                                          key={prod_types.id}
                                        >
                                          <li
                                            className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}
                                            style={{
                                              fontFamily: prod_types.isBold ? "OpenSans-Bold" : "Open Sans",
                                            }}
                                          >
                                            {prod_types.name}
                                          </li>
                                        </a>
                                      ) : (
                                        <a
                                          href={`/store/${men.name.replace(" ", "-")}/${
                                            men.category_id
                                          }/${sub.name.replace(" ", "-")}/${sub.subcategory_id}/all`}
                                          key={prod_types.id}
                                        >
                                          <li
                                            className={`list-group-item ${styles.list_group_item} ${styles.sub_item}`}
                                            style={{
                                              fontFamily: prod_types.isBold ? "OpenSans-Bold" : "Open Sans",
                                            }}
                                          >
                                            {prod_types.name}
                                          </li>
                                        </a>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </>
                        );
                      })}
                    </>
                  )}
                </Fragment>
              ))}
            </ul>
          </>
        )}
      </motion.div>
    </Overlay>
  );
};

export default MenuLeft;
