import React from "react";
import DirectionsIcon from "@mui/icons-material/Directions";
import { Button, Typography } from "@mui/material";
import { Input, Select } from "../../../../components/CustomComponents";

const PickupForm = ({ selectedBranchId, selectedBranch, branches, isSuccess, onBranchChange }) => {
  return (
    <div className="mt-4">
      <p className="font-opensans text-gray-500 text-sm leading-normal mb-3">
        In-store pick up may take up to 2-3 working days to be fulfilled. You will receive an email update with regards
        to this order.
      </p>

      <div>
        <Select
          label={"Pickup Branch"}
          value={selectedBranchId}
          onChange={(value) => {
            onBranchChange(value);
          }}
          options={
            isSuccess
              ? branches?.data.map((branch) => {
                  return { value: branch.id, label: branch.attributes.name };
                })
              : []
          }
        />
      </div>

      <div className="col-md-12 mb-2 mt-3">
        <Input value={selectedBranch?.address} placeholder={"Address"} readOnly={true} />
      </div>

      <div className={`col-md-12 mb-2 mt-3`}>
        <Input value={selectedBranch?.city} placeholder={"City"} readOnly={true} />
      </div>

      <div className={`col-md-12 mb-2 mt-3`} style={{ textAlign: "right" }}>
        <Button
          variant={"outlined"}
          endIcon={<DirectionsIcon />}
          onClick={() => {
            window.open(selectedBranch?.directions);
          }}
        >
          <Typography style={{ textTransform: "none" }}>Get Directions</Typography>
        </Button>
      </div>
    </div>
  );
};

export default PickupForm;
