import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      networkDetailAllowUrls: [window.location.origin],
    }),
  ],
  replaysSessionSampleRate: parseInt(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || "0"),
  replaysOnErrorSampleRate: parseInt(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || "0"),
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
