import { Link } from "react-router-dom";

// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/BecomeASupplier.module.css";
import store from "../../assets/images/store.jpg";
import store_2 from "../../assets/images/store_2.jpg";
import supply_2 from "../../assets/images/supply_2.jpg";
import supply from "../../assets/images/supply.jpg";
import cargo from "../../assets/images/cargo.jpg";

const SupplierApply = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="supplier_main">
        <section id="sup_know">
          <div className="row">
            <div className="col-md-2">
              <div className={style.supplier_home}>
                <Link to="/become-a-supplier">
                  <i className="fa fa-angle-left"></i> suppliers
                </Link>
              </div>
            </div>
            <div className="col-md-8">
              <div className={style.sup_know_header}>
                <h1>Apply to be a Supplier</h1>
                <p>
                  Our suppliers fit into multiple categories, and together, they
                  make up a pool of over 100,000 businesses worldwide. They’re
                  how we provide the products our customers want and need. Check
                  out the different types of suppliers to see where you fit. If
                  your business is located in the United States, we ask you to
                  complete our application process to be considered to sell
                  products in stores or online.
                </p>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className={style.sup_apply_card}>
                <div className={style.sup_apply_card_img}>
                  <img src={supply_2} alt="Get started" />
                </div>
                <div className={style.sup_apply_card_cont}>
                  <h5>Getting Started</h5>
                  <p>
                    No matter which type of supplier you choose to apply for,
                    use this supplier checklist to assist you in expediting the
                    onboarding process for becoming a qualified supplier for
                    Link pharmacy.
                  </p>
                </div>
              </div>
              <div className={style.sup_apply_card}>
                <div className={style.sup_apply_card_img}>
                  <img src={store} alt="Store" />
                </div>
                <div className={style.sup_apply_card_cont}>
                  <h5>Store</h5>
                  <p>
                    Link pharmacy's suppliers offer the products we stock in our
                    stores across the nation. Some of our product suppliers are
                    multi-billion-dollar companies, and some are local
                    businesses with just a few employees.
                  </p>
                  <p>
                    To get started as a Store supplier, click{" "}
                    <Link to="/register">here</Link> then click on 'Create
                    Account' to begin the qualification process.
                  </p>
                </div>
              </div>
              <div className={style.sup_apply_card}>
                <div className={style.sup_apply_card_img}>
                  <img src={supply} alt="ecommerce" />
                </div>
                <div className={style.sup_apply_card_cont}>
                  <h5>eCommerce</h5>
                  <p>
                    Link pharmacy suppliers provide online products to our
                    customers. Online suppliers utilize the same onboarding
                    process as our Store Suppliers with a few differences.
                  </p>
                  <p>
                    Our online suppliers supply customers in one or both of the
                    followings ways:
                  </p>
                  <ol>
                    <li>
                      <b>Ship to Warehouse:</b> The supplier will ship products
                      to a Link pharmacy fulfillment center. We will house
                      inventory and ship to customers.
                    </li>
                    <li>
                      <b>Ship to Customer:</b> The supplier will ship the
                      products directly to the customer (or store), also known
                      as a Drop Ship Vendor (DSV.)
                    </li>
                  </ol>
                  <p>
                    To get started as an eCommerce Supplier, click{" "}
                    <Link to="/register">here</Link> to begin the qualification
                    process.
                  </p>
                </div>
              </div>
              <div className={style.sup_apply_card}>
                <div className={style.sup_apply_card_img}>
                  <img src={store_2} alt="Apply to be a supplier" />
                </div>
                <div className={style.sup_apply_card_cont}>
                  <h5>Marketplace</h5>
                  <p>
                    Link pharmacy Marketplace is available to select third party
                    retailers who would like to offer their products to more
                    than 90 million unique visitors who shop on
                    linkpharmacy.co.zm every month. Customers can find
                    third-party Marketplace products by browsing or searching
                    linkpharmacy.co.zm. Items sold by third-party sellers are
                    noted in the item information in search results, online
                    shelves and on item pages.
                  </p>
                  <p>
                    To get started as a Link pharmacy Marketplace seller, click{" "}
                    <Link to="/login">here</Link> to begin the qualification
                    process.
                  </p>
                </div>
              </div>
              <div className={style.sup_apply_card}>
                <div className={style.sup_apply_card_img}>
                  <img src={cargo} alt="Direct import" />
                </div>
                <div className={style.sup_apply_card_cont}>
                  <h5>Direct Import</h5>
                  <p>
                    To bring our customers the lowest prices and best products
                    possible, we work with suppliers from across the globe. If
                    you’re interested in becoming a Direct Import product
                    supplier, click <Link to="/login">here</Link> to begin the
                    qualification process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </section>
      </section>
    </Layout>
  );
};
export default SupplierApply;
