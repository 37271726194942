import styles from "../../styles/views/EarPiercing.module.css";
import Layout from "../../components/Layout/Layout";

const EarPiercing = () => {
  return (
    <Layout
      meta={{
        title: "Ear Piercing | Link Pharmacy",
        keywords:
          "Ear Piercing, Safe Piercing, Earlobe Piercing, Piercing Care, Link Pharmacy Zambia, Ear Piercing Zambia",
        description:
          "Get your earlobe pierced safely at any of our Link Pharmacy locations in Zambia. We also provide advice on the care of piercings as well as general installation and removal of piercings. Visit us today for a professional and comfortable ear piercing experience.",
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">Ear Piercing</h1>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <p className="mb-3">
          Have your earlobe pierced at any of our locations. We also provide advice on care of piercings as well as
          general install and removal of piercings.
        </p>
      </div>
    </Layout>
  );
};

export default EarPiercing;
