/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { meta } from "../../globals";
import LinkForm from "../../components/forms/LinkForm";
import { Formik } from "formik";
import { IoKey, IoLockClosed } from "react-icons/io5";
import LayoutAuth from "../../components/Layout/LayoutAuth";
import { FormField, SubmitButton } from "../../components/forms";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { useNotifications } from "../../context/NotificationsContext";

const ResetPassword = () => {
  const { email } = useParams();
  const [response, setResponse] = useState("not set");
  const { pushNotification } = useNotifications();

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/auth/forgotten-password-show/${email}`)
      .then((response) => {
        if (response.data.response === "Found") {
          setResponse("found");
        }
      })
      .catch((error) => {
        setResponse("error");
      });
  }, []);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required").min(8, "Minimum of 8 characters required."),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const passwordMutation = useMutation(
    ["password-reset"],
    async ({ password }) => {
      const res = await axios.post("/auth/forgotten-password-reset", {
        email,
        password,
      });

      return res.data;
    },
    {
      onSuccess: async (data) => {
        if (data.message === "Password Changed") {
          pushNotification({
            id: Math.random(),
            type: "Success",
            message: "Password changed successfully",
          });

          navigate("/login", { replace: true });
        }
      },
      onError: (error) => {
        setResponse("error");

        pushNotification({
          id: Math.random(),
          type: "Error",
          message: error?.response?.data?.error || "Something went wrong",
        });
      },
    }
  );

  return (
    <LayoutAuth
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
    >
      <Container component="main" maxWidth="xs">
        {response === "error" && response !== "Found" && <Navigate to="/" replace />}

        <div>
          <Formik
            validationSchema={validationSchema}
            initialValues={{ password: "", confirmPassword: "" }}
            onSubmit={(values) => passwordMutation.mutate({ password: values.password })}
          >
            {({ values, handleChange, handleBlur, errors, touched, isValid }) => (
              <LinkForm authForm={true}>
                <div className="absolute -top-6 flex items-center justify-center border-b bg-white backdrop-blur-lg p-2 rounded-full">
                  <IoLockClosed className="text-3xl text-link-green" />
                </div>

                <h1 className="font-opensans-semibold text-2xl text-stone-900">Reset Your Password</h1>
                <h2 className="font-medium text-lg text-center text-stone-900">Create a new password</h2>
                <div className="w-full px-4 h-fit">
                  <FormField
                    name="password"
                    type="password"
                    placeholder="Enter your new password"
                    label={"Password"}
                    value={values.password}
                    error={touched.password && errors.password ? errors.password : null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon={<IoKey className="text-xl ml-2 text-link-green" />}
                  />
                  <FormField
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm your new password"
                    label={"Confirm Password"}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : null}
                    icon={<IoKey className="text-xl ml-2 text-link-green" />}
                  />
                  <div className="w-full px-3 flex flex-1 justify-between items-center ">
                    <button
                      className="text-stone-800 font-opensans-semibold"
                      onClick={() => {
                        navigate("/", { replace: true });
                      }}
                    >
                      Cancel
                    </button>

                    <SubmitButton type={"submit"} disabled={passwordMutation.isLoading || !isValid}>
                      {passwordMutation.isLoading ? "Loading..." : "Proceed"}
                    </SubmitButton>
                  </div>
                </div>
              </LinkForm>
            )}
          </Formik>
        </div>
      </Container>
    </LayoutAuth>
  );
};

export default ResetPassword;
