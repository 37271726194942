import { Link } from "react-router-dom";
import { IoChevronForwardCircle } from "react-icons/io5";

const FeaturedCategory = ({ category, link }) => {
  return (
    <Link
      className="w-full md:w-52 h-40 md:h-32 p-4 mb-3 font-opensans flex flex-col jus border rounded-xl bg-gradient-to-br from-link-green to-green-400 hover:text-[#000]"
      to={link}
    >
      <h5 className={"mb-2 text-xl font-opensans-bold"}>{category.attributes.name}</h5>

      <div className={"m-0 font-weight-bold hover:text-green-800"}>
        More <IoChevronForwardCircle className={"inline-block text-xl"} />
      </div>
    </Link>
  );
};

export default FeaturedCategory;
