import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { useCart } from "../context/cartContext";
import ShoppingCartDropdown from "./ShoppingCartDropdown";
import style from "../styles/views/ShoppingCart.module.css";
import useWindowSize from "../hooks/useWindowSize";
import CartIcon from "./CartIcon";

const ShoppingCart = ({ visible }) => {
  const {
    cart: { total, count },
    isDropdownVisible,
    hideDropDown,
    showDropDown,
  } = useCart();

  const { width } = useWindowSize();

  return (
    <>
      <div
        className={style.logo}
        onMouseEnter={() => {
          if (count > 0 && width > 600) {
            showDropDown();
          }
        }}
        onMouseLeave={hideDropDown}
      >
        <Link
          to={"/cart"}
          onClick={(e) => {
            width > 600 && count > 0 && e.preventDefault();
          }}
        >
          <div>
            <CartIcon quantity={count} />
          </div>

          <div className="text-gray-900 flex flex-col justify-end justify-center md:whitespace-nowrap">
            <span className="text-xs md:text-[13px] text-black font-opensans font-light mb-0 whitespace-nowrap">
              Your Cart:
            </span>
            <span className="font-opensans-bold text-sm md:text-lg text-white whitespace-nowrap">
              K{" "}
              {Number(total)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </span>
          </div>
        </Link>
      </div>

      <AnimatePresence initial={false}>
        {isDropdownVisible && width > 600 && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className={style.dropDownArrow}
            />
            <ShoppingCartDropdown visible={visible} />
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default ShoppingCart;
