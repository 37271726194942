/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { productApi } from "../../api/products";
import { useNavigationType, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import ReactImageMagnify from "react-image-magnify";
import Lottie from "react-lottie";
import AddToCart from "../../components/AddToCart";
import Layout from "../../components/Layout/Layout";
import RecentlyViewed from "../../components/products/RecentlyViewed";
import animationLoading from "../../assets/animations/link-animation.json";
import BreadCrumbs from "../../components/BreadCrumbs";
import { SmoothScroll } from "../../helpers/SmoothScroll";
import { useLocation } from "react-router-dom";
import SimilarProducts from "../../components/products/SimilarProducts";
import { HiExclamationCircle } from "react-icons/hi";
import PrescriptionsGuidelinesDialog from "./components/PrescriptionsGuidelinesDialog";
import { Button, Feedback } from "../../components/CustomComponents";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ProductDetails = () => {
  const { id } = useParams();
  const { data: _product, status: productStatus } = useQuery(["product", { id }], () => productApi(id));
  const action = useNavigationType();
  const location = useLocation();
  const [isGuidelinesOpen, setIsGuidelinesOpen] = useState(false);

  const product = _product && _product.data;

  let metaUrl, sitename, pageTitle, metaDescription, metaKeywords, metaImage;

  if (productStatus === "success") {
    pageTitle = `${product?.attributes?.name} ${product?.subcategory?.name}`;
    metaDescription = `${product?.attributes?.name} ${product?.subcategory?.name}, ${product?.category?.name} Link pharmacy Zambia,  ${product.brand} Link pharmacy Zambia`;
    metaKeywords = `${product?.attributes?.name} ${product?.subcategory?.name}, ${product?.category?.name} Link pharmacy Zambia,  ${product.brand} Link pharmacy Zambia`;
    metaImage = product?.attributes?.thumbnail;

    metaUrl = `https://www.link.co.zm/product-details/${id}`;
    sitename = `${product?.attributes?.name} ${product?.subcategory?.name}`;
  }

  useEffect(() => {
    if (action !== "POP") {
      SmoothScroll(0, "auto");
    }
  }, [location]);

  useEffect(() => {
    if (productStatus !== "success") return;

    let recentProducts = JSON.parse(localStorage.getItem("linkpharmacy-recent-products")) || [];

    const productExistsInRecent = recentProducts.some((recentProduct) => Number(recentProduct.id) === Number(id));

    if (!productExistsInRecent) {
      const newProduct = {
        id: product.id,
        thumbnail: product.attributes.thumbnail,
        name: product.attributes.name,
        price: product.attributes.price,
      };

      recentProducts = [newProduct, ...recentProducts].slice(0, 8);
      localStorage.setItem("linkpharmacy-recent-products", JSON.stringify(recentProducts));
    }
  }, [id]);

  // Base structured data for your page as a JS object.
  let structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: product?.attributes?.name,
    image: [product?.attributes?.thumbnail],
    description: `${product?.attributes?.description} Shipping: Delivery within Lusaka is K50. For deliveries outside of Lusaka, shipping rates vary based on package weight. See our shipping policy for details.`,
    sku: product?.attributes?.link_id,
    offers: {
      "@type": "Offer",
      url: `https://www.link.co.zm/product-details/${id}`,
      priceCurrency: "ZMW",
      price: product?.attributes?.price,
      availability:
        product?.attributes?.quantity_in_stock > 0 ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
      shippingDetails: {
        "@type": "OfferShippingDetails",
        shippingRate: {
          "@type": "MonetaryAmount",
          value: "50", // fixed cost for local deliveries
          currency: "ZMW",
        },
        shippingDestination: {
          "@type": "DefinedRegion",
          addressCountry: "ZM", // Zambia
        },
        deliveryTime: {
          "@type": "ShippingDeliveryTime",
          businessDays: [
            {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
              opens: "09:00",
              closes: "19:00",
            },
            {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: "Saturday",
              opens: "09:00",
              closes: "18:00",
            },
            {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: "Sunday",
              opens: "10:00",
              closes: "15:00",
            },
          ],
          cutoffTime: "14:00",
        },
      },
    },
  };

  // Conditionally add the brand to the structured data.
  if (product?.attributes?.brand && product?.attributes?.brand !== "N\\A") {
    structuredData.brand = {
      "@type": "Brand",
      name: product?.attributes?.brand,
    };
  }

  return (
    <Layout
      meta={{
        title: pageTitle,
        keywords: metaKeywords,
        description: metaDescription,
        image: metaImage,
        url: metaUrl,
        sitename: sitename,
      }}
      header
      footer
      bgColor={"bg-stone-100"}
      structredData={structuredData}
    >
      <div className="container-lg container-md container-sm container mt-2">
        {productStatus === "success" ? (
          <div className="w-full flex flex-col">
            <BreadCrumbs
              crumbs={[
                { name: "Home", link: "/" },
                { name: `All Products`, link: "/store/products" },
                {
                  name: product.attributes.name,
                  link: "/#",
                },
              ]}
            />
            <div className="w-full self-center border bg-white p-4 rounded flex flex-col sm:flex-row ">
              <div className="w-[100%] md:w-[50%] h-[400px] relative">
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: `${product.attributes.name} product image`,
                      isFluidWidth: true,
                      src: product.attributes.thumbnail,
                      sizes: "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                    },
                    largeImage: {
                      src: product.attributes.thumbnail,
                      width: 1200,
                      height: 800,
                    },
                    lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                    enlargedImageContainerDimensions: {
                      width: "150%",
                      height: "150%",
                    },
                  }}
                  imageClassName="magnifier-image"
                  className="magnifier-container"
                  enlargedImageContainerStyle={{
                    backgroundColor: "#fff",
                  }}
                />
              </div>

              <div className="w-[100%] md:w-[50%] min-w-0">
                <h2>{product.attributes.brand === "N\\A" ? "" : product.attributes.brand}</h2>
                <h1 className="font-opensans text-2xl my-[0.67em]">{product.attributes.name}</h1>
                <h4 className="font-opensans text-2xl mb-[10px]">
                  K
                  <span className="font-opensans-bold">
                    {Number(product.attributes.price).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </span>
                </h4>
                <p className="font-opensans text-sm mb-[14px]">
                  You can pick up your order or we can have it delivered to you.
                </p>
                <hr className="mb-[14px]" />
                <div className="p-3 bg-stone-100 rounded font-opensans mb-3">
                  <p className="text-sm mb-[14px] text-green-600 font-opensans-semibold">Delivery</p>
                  <p className="text-sm mb-[14px]">
                    Free Delivery above K500
                    <br />
                    Free Shipping to Store
                  </p>

                  {product?.category?.name === "Dispensary" || product?.category?.name === "Supervised Sales" ? (
                    <>
                      <div className="mb-3">
                        <h6 className="font-opensans-semibold text-sm leading-normal text-[#FF4F00] mb-2 d-flex items-center">
                          <HiExclamationCircle className="text-lg mr-1" /> Prescription Only
                        </h6>
                        <p className="font-opensans text-sm leading-normal text-gray-900">
                          Upload your prescription, and our pharmacist will process it for you.
                        </p>
                      </div>

                      <div className="d-flex space-y-2 sm:space-x-2 sm:space-y-0 flex-col sm:flex-row">
                        <Button.Rounded href={"/upload-prescription"}>Upload Prescription</Button.Rounded>
                        <Button.Rounded href={"/schedule-an-appointment"} variant={"secondary"}>
                          Need a Prescription?
                        </Button.Rounded>
                      </div>
                    </>
                  ) : (
                    <AddToCart
                      rounded="split rounded"
                      cart={{
                        id: product.id,
                        name: product.attributes.name,
                        quantity: 1,
                        price: product.attributes.price,
                        thumbnail: product.attributes.thumbnail,
                        quantityOnHand: product.attributes.quantity_in_stock,
                      }}
                    />
                  )}
                </div>

                <div>
                  {product.attributes.quantity_in_stock <= 2 && (
                    <p>
                      <span className="text-red-500 text-sm font-opensans">
                        Only {product.attributes.quantity_in_stock.toLocaleString()} left in stock
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="py-8 w-full flex flex-col">
              <div className="bg-white rounded p-4 font-opensans border mb-6">
                <h5 className="mb-[10px] text-xl font-opensans-medium">Description</h5>
                <div className="text-sm" dangerouslySetInnerHTML={{__html: product.attributes.description}} />
              </div>

              <div className="rounded-xl mb-6">
                <h5 className="font-opensans-medium text-xl mb-3">Similar Products</h5>
                <SimilarProducts productTypeId={product?.product_type?.id} />
              </div>
              <div className="rounded-xl mb-6">
                <h5 className="font-opensans-medium text-xl mb-3">Recently Viewed Products</h5>
                <RecentlyViewed id={id} />
              </div>
            </div>
          </div>
        ) : productStatus === "loading" ? (
          <div className="w-full h-[70vh] flex justify-center items-center">
            <Lottie options={defaultOptions} height={100} width={100} />
          </div>
        ) : (
          <Feedback
            errorCode={"404"}
            title={"Product not found"}
            message={"The product you are looking for does not exist or has been removed."}
            actions={[<Button href={"/store/products"}>Go to Products</Button>]}
          />
        )}
      </div>

      <PrescriptionsGuidelinesDialog isOpen={isGuidelinesOpen} onClose={() => setIsGuidelinesOpen(false)} />
    </Layout>
  );
};

export default ProductDetails;
