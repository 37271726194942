import React, { useEffect, useState } from "react";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import useFormErrors from "../../../hooks/useFormErrors";
import { classNames } from "../../../utils/utils";
import Select, { Option } from "./select";
import { HiCloudUpload } from "react-icons/hi";
import { useNotifications } from "../../../context/NotificationsContext";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  adornment?: string | React.ReactNode;
  name?: string;
  placeholder?: string;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: HTMLInputElement["value"];
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  touched?: boolean | FormikTouched<FormikValues> | FormikTouched<FormikValues[string][number]>[];
  type?: HTMLInputElement["type"];
  readOnly?: boolean;
  width?: string;
  center?: boolean;
  icon?: React.ReactNode;
  onIconClick?: () => void;
  selectOptions?: Option[];
  selectValue?: string;
  onSelectChange?: (value: string) => void;
  required?: boolean;
  maxFileSize?: number;
}

export const Label = ({ label, id, required }: { label: string; id?: string; required?: boolean }) => {
  return (
    <label htmlFor={id || label} className="block text-sm font-opensans-medium leading-6 text-gray-800 mb-1">
      {label} {required && <span className="text-link-green-1 text-base leading-sm font-opensans-bold">*</span>}
    </label>
  );
};

const Input = ({
  label,
  adornment,
  name,
  placeholder,
  id,
  value,
  onBlur,
  onChange,
  error,
  touched,
  type = "text",
  readOnly,
  width = "100%",
  center = false,
  icon,
  onIconClick,
  selectOptions,
  selectValue,
  onSelectChange,
  required,
  onClear,
  maxFileSize,
  ...props
}: Props) => {
  const { displayErrors } = useFormErrors();
  const { pushNotification } = useNotifications();
  const selectRef = React.useRef<HTMLDivElement>(null);
  const [selectWidth, setSelectWidth] = React.useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const inputClass = classNames(
    "block",
    "rounded-md",
    "border-0",
    "md:py-1.5",
    "py-2.5",
    "text-gray-900",
    "ring-1",
    "ring-inset",
    "ring-gray-300",
    "placeholder:text-gray-400",
    "focus:ring-2",
    "focus:ring-inset",
    "focus:ring-link-green",
    "text-sm",
    "sm:leading-6",
    "z-5",
    readOnly && "bg-gray-200 cursor-not-allowed focus:ring-gray-300",
    center && "text-center"
  );

  useEffect(() => {
    if (selectRef.current) {
      setSelectWidth(selectRef.current.offsetWidth);
    }
  }, [selectOptions, selectValue]);

  const errorId = `error-${id}`; // unique id for the error message

  return (
    <div className={"w-full"}>
      {label && <Label label={label} id={id} required={required} />}
      <div className="relative rounded-sm shadow-xs">
        {adornment && (
          <div className="pointer-events-none inset-y-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">{adornment}</span>
          </div>
        )}
        {selectOptions && (
          <div ref={selectRef} className="absolute inset-y-0 left-0 flex items-center pr-3 cursor-pointer">
            <Select options={selectOptions} border={false} value={selectValue || ""} onChange={onSelectChange!} />
          </div>
        )}
        {type === "file" ? (
          <label
            htmlFor={id || label}
            className={`cursor-pointer flex items-center justify-center rounded-md py-2.5 bg-white text-gray-700 border border-gray-300 shadow-sm hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-link-green ${inputClass}`}
            style={{ width, paddingLeft: !!selectOptions ? selectWidth - 10 : undefined }}
          >
            <HiCloudUpload className="mr-2" />
            {selectedFile ? (
              <span className="mr-2">{selectedFile.name}</span>
            ) : (
              <span className="mr-2">Upload File</span>
            )}
            <input
              type={type}
              name={name}
              id={id || label}
              className="sr-only"
              onChange={async (e) => {
                const file = e.target.files?.[0];
                if (!file) {
                  return;
                }

                if (maxFileSize) {
                  const maximumFileSize = maxFileSize * 1024 * 1024;

                  if (file.size > maximumFileSize) {
                    pushNotification({
                      id: Math.random(),
                      message: `The file you have selected is too large. Please select a file smaller than ${maxFileSize}MB.`,
                      type: "Error",
                    });
                    return;
                  }
                }

                setSelectedFile(file);
                if (onChange) {
                  await onChange(e);
                }
              }}
              onBlur={onBlur}
              readOnly={readOnly}
              {...props}
            />
            {selectedFile && (
              <button
                type="button"
                className="text-gray-500 hover:text-link-green ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClear?.();
                  setSelectedFile(null);
                  const fileInput = document.getElementById(id || (label as string)) as HTMLInputElement;
                  if (fileInput) {
                    fileInput.value = "";
                  }
                }}
              >
                Clear
              </button>
            )}
          </label>
        ) : (
          <input
            type={type}
            name={name}
            id={id || label}
            className={inputClass}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            readOnly={readOnly}
            style={{ width, paddingLeft: !!selectOptions ? selectWidth - 10 : undefined }}
            aria-labelledby={id || label}
            aria-required={required}
            aria-invalid={!!error && touched ? "true" : "false"}
            aria-describedby={errorId}
            {...props}
          />
        )}
        {icon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={onIconClick}>
            {icon}
          </div>
        )}
      </div>
      {error && touched && <div id={errorId}>{displayErrors(error)}</div>}
    </div>
  );
};

export default Input;
