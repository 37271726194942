import { Link } from "react-router-dom";
// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import JobAlertForm from "../../components/JobAlertForm";
import JobSearchForm from "../../components/JobSearchForm";
import style from "../../styles/views/CareerInstore.module.css";

const CareerInStore = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="row">
        <div className="col-md-12">
          <div className={style.instore_bg}>
            <div className={style.instore_color}>
              <div className={style.instore_header}>
                <div className="container">
                  <h4>Great opportunity starts with you.</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className={style.instore_cont}>
              <div>
                <JobSearchForm />
              </div>
              <div className={style.instore_nav}>
                <Link to="/">
                  Home <span>&#10095;</span>
                </Link>
                <Link to="/careers">
                  Career areas <span>&#10095;</span>
                </Link>
                <Link to="/in_store">In-store</Link>
              </div>
              <h4>In-store</h4>
              <div>
                <p>
                  Our in-store team members happily serve our millions of
                  customers every day, and we truly believe that sets us apart
                  and makes us who we are. From the care they put into tasks
                  such as processing and preserving precious memories in our
                  Photo Center to filling a complex (and much needed)
                  prescription in our pharmacy to the genuine smile and warm
                  greeting they give to a customer who just entered the store,
                  they truly are the face, heart and soul of our Link Pharmacy
                  operation.
                </p>
                <br />
                <h5>Find out what’s in store for you</h5>
                <br />
                <p>
                  At Link Pharmacy, you’ll have the opportunity to show you care
                  across your career no matter what role you start in. Whether
                  you are in store management or a pharmacy technician, work in
                  photo finishing, or are one of our new beauty consultants,
                  you’ll find that every Link Pharmacy store represents an
                  incredibly diverse array of talent working together to create
                  a consistently positive customer experience. We depend on our
                  team members to deliver excellent customer care, and, in
                  return, we offer exciting growth, advancement, and fulfillment
                  opportunities for all.
                </p>
              </div>
              <div>
                <JobAlertForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CareerInStore;
