import React from "react";
import styles from "../styles/BecomeASupplier.module.css";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";

const BecomeASupplier = () => {
  return (
    <Layout
      meta={{
        title: "Become A Supplier | Link Pharmacy",
        keywords: "Become A Supplier, Link Pharmacy, Pharmacy, Pharmacist, Pharmacist in Zambia, Pharmacy in Zambia",
        description: "Become A Supplier",
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">Become A Supplier</h1>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <h2 className="text-xl md:text-2xl mb-3">Would you like to be a supplier?</h2>
        <p className="mb-3">
          <Link to={"/contact-us"} className="font-opensans-semibold text-link-green-1">
            Get in touch
          </Link>{" "}
          with us and a member of our procurement team will get back to you as soon as possible. Remember to include
          your full name, contact details and company profile.
        </p>
      </div>
    </Layout>
  );
};

export default BecomeASupplier;
