/* eslint-disable jsx-a11y/iframe-has-title */
import { useParams } from "react-router-dom";
import moment from "moment";
import Layout from "../../components/Layout/Layout";
import stores from "../../globals/Locations";
import { holidays } from "../../utils/holidays";
import { Button } from "../../components/CustomComponents";
import React from "react";
import { HiOutlinePhone, HiOutlineMail } from "react-icons/hi";

const StoreDetails = () => {
  const { id } = useParams();

  const timeToClose = () => {
    const currentDay = new Date();

    holidays.items.forEach((holiday) => {
      if (new Date(holiday.start.date) === currentDay) {
        return new moment("15:00:00", "HH:mm:ss");
      }
    });

    if (currentDay.getMonth() + 1 === 12 && currentDay.getDate() === 25) {
      return "Closed";
    }

    const closingTime = (() => {
      if (currentDay.getDay() <= 5) {
        return new moment("19:00:00", "HH:mm:ss");
      }

      if (currentDay.getDay() === 6) {
        return new moment("18:00:00", "HH:mm:ss");
      }

      if (currentDay.getDay() === 7) {
        return new moment("15:00:00", "HH:mm:ss");
      }
    })();

    const currentTime = new moment(new Date().toLocaleTimeString(undefined, { hour12: false }), "HH:mm:ss");

    return moment.duration(closingTime.diff(currentTime));
  };

  const store = stores.filter((_store) => _store.id === +id)[0];

  return (
    <Layout
      meta={{
        title: `${store.name} | Link Pharmacy`,
        keywords: `${store.name} ${store.city}, ${store.name} working hours ${store.mon_fri}, ${store.name} phone number ${store.phone_number}, ${store.address}, Link, Link pharmacy, Pharmacy Zambia, Linkpharmacy, Pharmacy store Zambia, biggest pharmacy store zambia.`,
        description: `${store.name} ${store.city}, ${store.name} working hours ${store.mon_fri}, ${store.name} phone number ${store.phone_number}, ${store.address}, Link, Link pharmacy, Pharmacy Zambia, Linkpharmacy, Pharmacy store Zambia, biggest pharmacy store zambia.`,
      }}
      header
      footer
      bgColor={"#f5f5f0"}
    >
      <div className="bg-white">
        <div className="container">
          <div className="h-[250px] bg-[#f8f8f8] my-[10px] rounded-md overflow-hidden border border-1 border-gray-200">
            <iframe src={store.map_iframe} width="100%" height="100%" frameBorder="0" />
          </div>
        </div>
      </div>

      <main className="font-opensans leading-normal bg-[#f5f5f0] py-4">
        <div className="container">
          <div className="flex flex-col-reverse md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-10">
            <div className="w-full md:w-3/4">
              <div className="bg-white p-4 rounded-md shadow-sm">
                <h2 className="font-opensans-semibold text-green-600 mb-3">
                  {(() => {
                    if (timeToClose().hours && timeToClose().minutes) {
                      if (timeToClose()?.hours() <= 0 && timeToClose()?.minutes() <= 0) {
                        return "This store is closed and will reopen tomorrow at 9AM";
                      }
                      if (timeToClose()?.hours() > 0 || timeToClose()?.minutes() > 0) {
                        return `This store will be closing in ${timeToClose().hours()} ${
                          timeToClose().hours() > 1 ? "Hours" : "Hour"
                        } ${timeToClose().minutes()} Minutes`;
                      }
                    }

                    if (timeToClose() === "Closed") {
                      return "This store is closed.";
                    }
                  })()}
                </h2>
                <hr className="text-gray-400 mb-3" />
                <div className="text-sm mb-3">
                  <div className="flex space-x-4 mb-1">
                    <div>
                      <p>Mon - Fri:</p>
                    </div>
                    <div>
                      <p className="text-gray-600">{store.mon_fri}</p>
                    </div>
                  </div>

                  <div className="flex space-x-4 mb-1">
                    <div>
                      <p>Saturday:</p>
                    </div>
                    <div>
                      <p className="text-gray-600">{store.sat}</p>
                    </div>
                  </div>

                  <div className="flex space-x-4 mb-1">
                    <div>
                      <p>Sunday & Holidays:</p>
                    </div>
                    <div>
                      <p className="text-gray-600">{store.holidays}</p>
                    </div>
                  </div>

                  <div className="flex space-x-4 mb-1">
                    <div>
                      <p>Christmas Day:</p>
                    </div>
                    <div>
                      <p className="text-gray-600">Closed</p>
                    </div>
                  </div>
                </div>

                <h2 className="font-opensans-semibold mb-3">Other Pharmacy Services</h2>
                <hr className="text-gray-400 mb-3" />

                <div className="flex space-x-2">
                  <div>
                    <div className="text-sm mb-2">
                      <div className="flex space-x-4 mb-1">
                        <div>
                          <p>Health Test:</p>
                        </div>
                        <div>
                          <p className="text-gray-600">Blood Pressure</p>
                        </div>
                      </div>
                    </div>

                    <h3 className="font-opensans-semibold mb-2">Prescriptions</h3>
                    <p className="text-sm mb-3">Would you like to send a prescription to this pharmacy?</p>
                    <Button.Rounded href={`mailto:prescriptions@linkpharmacy.co.zm?subject=${store.name}`} external>
                      Email Now
                    </Button.Rounded>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-2/4 p-2">
              <h1 className="font-opensans-bold m-0 text-2xl">{store.name}</h1>
              <p className="font-opensans-semibold text-sm md:text-base text-gray-500 mb-3">{store.city}</p>

              <p className="mb-3">{store.address}</p>

              <div>
                <div className="flex items-center mb-1">
                  <HiOutlinePhone className="text-xl text-link-green-1" />
                  <span className="ml-4">{store.phone_number}</span>
                </div>

                <a href={`mailto:${store.email}`}>
                  <div className="flex items-center mb-1">
                    <HiOutlineMail className="text-xl text-link-green-1" />
                    <span className="ml-4">{store.email}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};
export default StoreDetails;
