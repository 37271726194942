import { Link } from "react-router-dom";

import styles from "../styles/components/HelpCenterSidebar.module.css";

const HelpCenterSidebar = () => {
  return (
    <div>
      <div className={styles.shipping_left}>
        <ul>
          <li>
            <Link to="/help">Help Center</Link>
          </li>
          <br />
          <li>
            <Link to="/help-shipping">Shipping</Link>
          </li>
          <li>
            <Link to="/help-returns">Returns</Link>
          </li>
          <li>
            <Link to="/help-special-orders">Special Orders</Link>
          </li>
          <li>
            <Link to="/product-recalls">Product Recalls</Link>
          </li>
          <br />
        </ul>
      </div>
    </div>
  );
};
export default HelpCenterSidebar;
