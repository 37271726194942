import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import { Button } from "./CustomComponents";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionUpDialog = ({ loading, closeText, saveText, onClose, onSave, children, visible, title }) => {
  const dialogTitleId = "transition-up-dialog-title";
  const dialogDescriptionId = "transition-up-dialog-description";

  return (
    <Dialog
      open={visible}
      // @ts-ignore
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogDescriptionId}
    >
      <DialogTitle id={dialogTitleId}>
        <h3 className="m-0 font-opensans-bold">{title}</h3>
      </DialogTitle>

      <DialogContent>
        <div id={dialogDescriptionId}>{children}</div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="secondary">
          {closeText}
        </Button>

        <Button disabled={loading} onClick={onSave}>
          {loading ? <Button.Loader label={"Loading..."} /> : saveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransitionUpDialog;
