import React from "react";
import styles from "../../styles/views/CompanyInfo.module.css";
import Layout from "../../components/Layout/Layout";

const CompanyInfo = () => {
  return (
    <Layout
      meta={{
        title: "About Us | Link Pharmacy",
        keywords: "About Us, Link Pharmacy, Link Pharmacy Zambia, Link Pharmacy Lusaka, Link Pharmacy Kitwe",
        description:
          "Founded in 1999 as a single drugstore, Link Pharmacy today is a provider of trusted care in communities across Zambia.",
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">About Us</h1>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <p className="mb-3">
          Your local Link Pharmacy is proud to be part of a network of pharmacies providing the highest level of
          professional service and friendly expert advice to you and your family.
        </p>
        <p className="mb-3">
          Founded in 1999 as a single drugstore, Link Pharmacy today is a provider of trusted care in communities across
          Zambia.
        </p>
        <p className="mb-3">
          We aim to break new ground to meet our customers’ needs and improve their well-being with our comprehensive
          dispensaries, mediclinics and health services.
        </p>
        <p className="mb-3">
          Our team of experienced pharmacists are committed to providing you with the best customer care and quality
          products.
        </p>
        <p className="mb-3">We offer a wide range of health services to support you through all stages of life.</p>
      </div>
    </Layout>
  );
};

export default CompanyInfo;
