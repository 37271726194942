/* eslint-disable jsx-a11y/anchor-is-valid */
import care_giver from "../../assets/images/care_giver.jpg";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import SpecialtySideBar from "../../components/SpecialtySideBar";
import style from "../../styles/views/Fertility.module.css";

const Fertility = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="row">
          <div className="clo-md-12">
            <div className={style.ft_nav}>
              <a href="/">Home</a>
              <span>&#10095;</span>
              <a href="/pharmacy-services">Pharmacy & Health</a>
              <span>&#10095;</span>
              <a href="/specialty-pharmacy">Specialty Pharmacy</a>
              <h1>Fertility Services</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <SpecialtySideBar />
            </div>
            <div className="col-md-9">
              <div className={style.ft_flex}>
                <div className={style.ft_flex_cont}>
                  <h2>Expert care and specialized support</h2>
                </div>
                <div className={style.ft_flex_img}>
                  <img src={care_giver} alt="" />
                </div>
              </div>
              <div className={style.ft_cont}>
                <h3>Trusted fertility care for over 30 years</h3>
                <p>
                  Walgreens and AllianceRx Walgreens Prime have an expert
                  understanding of the fertility process, the importance of
                  timing and the need for personalized treatment. We are
                  committed to compassionate care and support throughout your
                  journey to having a family.1
                </p>
                <h4>We offer:</h4>
                <ul>
                  <li>Support from pharmacists 24/7, including holidays2</li>
                  <li>Same-day emergency prescriptions</li>
                  <li>Insurance verification</li>
                  <li>Overnight shipping3</li>
                  <li>Interactive education tools</li>
                  <li>Complimentary medication materials</li>
                </ul>
                <h5>Personalized therapy when the time is right</h5>
                <h6>Exceptional Fertility Pharmacy Service</h6>
                <p>
                  Your services start with a personal fertility benefits
                  investigation and coordination of insurance benefits. Once
                  your prescription has been processed, your medications can be
                  sent immediately, or at the start of your cycle. Plus, our
                  team of experts are available around-the-clock for support
                  whenever you need it.
                </p>
                <h5>Fertility Order Review</h5>
                <p>
                  Fertility Order Review (FOR) program, offering one-on-one
                  medication consultations, storage information, refill
                  instructions and injection training. FOR Video Connected Care
                  program, providing face-to-face HIPAA compliant web-based
                  video conferencing. <a href="#"> More info</a>
                </p>

                <h5>Trusted Fertility Expertise</h5>
                <p>
                  The results of our most recent satisfaction survey show a
                  consistent rating of excellent for our staff's knowledge of
                  fertility treatments and medications. We also scored a{" "}
                  <b> 96% overall satisfaction </b> rating in the most recent
                  satisfaction survey by IPSOS Loyalty.4 We are accredited by
                  the Accreditation Commission for Health Care, Inc. which
                  recognizes our commitment to a high level of excellence in
                  products and services.
                </p>
                <h5>Fertility Preservation</h5>
                <p>
                  Cancer treatment may affect your fertility. However, the best
                  time to learn about some of the options available to preserve
                  your fertility is before you start your cancer treatment.{" "}
                  <a href="#"> More info</a>
                </p>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Fertility;
