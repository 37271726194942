import pack_meds_logo from "../../assets/images/pack-my-meds-logo.png";
import styles from "../../styles/views/PackMyMeds.module.css";
import Layout from "../../components/Layout/Layout";
import { Button, Input } from "../../components/CustomComponents";
import { useNotifications } from "../../context/NotificationsContext";

const PackMyMeds = () => {
  const { pushNotification } = useNotifications();

  return (
    <Layout
      meta={{
        title: "Pack My Meds - Convenient Medication Orders | Link Pharmacy",
        keywords:
          "Pack My Meds, Link Pharmacy, Zambia, Lusaka, Pharmacy, Health, Wellness, Medication, Order, Delivery, Prepacked Medication, Time-Saving, Medication Schedule",
        description:
          "Experience the convenience of ordering your monthly medication with Pack My Meds service by Link Pharmacy. Ensure adherence to your medication schedule with our prepacked service, saving your time and enhancing your wellness. Subscribe today.",
      }}
      header
      footer
    >
      <main className={styles.pack_meds_header}>
        <div className={styles.pack_meds_header_flex}>
          <div className="d-flex flex-col items-center justify-center h-[370px]">
            <div className={styles.pack_meds_logo}>
              <img src={pack_meds_logo} alt="Pack my Meds logo" />
            </div>
            <div className="d-flex flex-col items-center">
              <p className="text-white font-opensans">Pre-Packed Meds, Ready When You Are</p>
              <h1 className="font-opensans-bold text-white md:text-3xl mt-1">PACK MY MEDS</h1>
            </div>
          </div>
        </div>
      </main>

      <div className="container">
        <div className={styles.order_meds_bg}>
          <div className={styles.order_meds_cont}>
            <h2 className="font-opensans-semibold mb-2">Order your monthly meds on your smart phone.</h2>
            <p className="font-opensans text-gray-600">Order when it suits YOU - 24/7</p>
          </div>
        </div>
        <article className={styles.pre_packed_meds_bg}>
          <div className={styles.pre_packed_meds_cont}>
            <h2 className="font-opensans-semibold mb-2">Your meds are pre-packed at the pharmacy</h2>
            <p className="font-opensans text-gray-600">YOUR MEDS WILL BE READY FOR YOU WHEN YOU GET THERE</p>
          </div>
        </article>
        <article className={styles.collect_packed_meds_bg}>
          <div className={styles.collect_packed_meds_cont}>
            <h2 className="font-opensans-semibold mb-2">Collect your meds.</h2>
            <p className="font-opensans text-gray-600">THE QUEUE MOVES QUICKER SO YOU SAVE TIME</p>
          </div>
        </article>
        <article className={styles.summary_of_how_it_works}>
          <h3 className={"font-opensans-bold text-2xl"}>Summary of How it works</h3>
          <div className={`${styles.summary_cont} font-opensans leading-normal`}>
            <ol>
              <li>Link pharmacy will send you an SMS to order your medication before you have run out.</li>
              <li>
                Click on the SMS link, order your medication, select the pharmacy where you would like to collect, or
                when you want us to deliver and it is done.
              </li>
              <li>This will enable you to stick to your medication schedule.</li>
              <li>Plus, Link Pharmacy will keep you up-to-date for script renewal.</li>
              <li>Your medication will be pre-packed and ready for collection before you arrive, saving you time.</li>
              <li>
                To opt in complete the form below or contact our customer care line on{" "}
                <a className="text-green-700 font-opensans-semibold" href="tel:+260211255556">
                  +260 211 255 556
                </a>
              </li>
              <li>
                Please note that all orders for delivery are strictly payable in advance by electronic means. Please
                expect electronic communication in this regard.
              </li>
            </ol>
          </div>
          <hr />
        </article>
        <article className={styles.subscribe_for_pack_meds}>
          <h3 className={"font-opensans-bold text-2xl mb-3"}>Subscribe for Pack My Meds Service</h3>
          <div className={styles.subscribe_cont}>
            <small className="font-opensans leading-normal">
              Order your medicines on your smartphone every month as and when it suits you. Your medicines will be
              prepacked and ready, waiting for you when you get to the pharmacy. Use Pack My Meds and skip the main
              dispensary queue, just go directly to the collections counter.
              <br />
              Pack My Meds for convenient medicine collections at Link pharmacy. Terms and Conditions Apply.
            </small>
          </div>
        </article>
        <article className={styles.pack_meds_form}>
          <h3 className="font-opensans-semibold text-xl mb-3">Register Here</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              pushNotification({
                id: Math.random(),
                message:
                  "Apologies for the inconvenience. Our service is currently on a pause. Please stay tuned for updates and thank you for your patience!",
                type: "Warning",
              });
            }}
            className="shadow-sm"
          >
            <em>* Required fields</em>

            <div className="mb-3">
              <Input label={"First Name"} required={true} name={"firstName"} />
            </div>

            <div className="mb-3">
              <Input label={"Last Name"} name={"lastName"} />
            </div>

            <div className="mb-3">
              <Input label={"Phone Number"} />
            </div>

            <div>
              <Button width="full">Subscribe</Button>
            </div>
          </form>
        </article>
      </div>
    </Layout>
  );
};

export default PackMyMeds;
