/* Form Field Validation Utilities v1.0.0 */

const FormUtils = {};

/** The field under validation must not be empty */
FormUtils.isEmpty = (str) => {
  return !str;
};

/** The field under validation must be a valid zambian mobile number. */
FormUtils.isValidZambianMobileNumber = (str) => {
  return !FormUtils.isEmpty(str) && /^\+260\d{9}$/.test(str);
};

/** The field under validation must be a valid email. */
FormUtils.isValidEmail = (str) => {
  return (
    !FormUtils.isEmpty(str) &&
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      str
    )
  );
};

/** The field under validation must be numeric. */
FormUtils.isNumeric = (str) => {
  return !FormUtils.isEmpty(str) && !isNaN(Number(str));
};

/** The field under validation must be a valid image. */
FormUtils.isValidImage = (str) => {
  return (
    !FormUtils.isEmpty(str) &&
    /\.(jpe?g|svg|png|gif|tiff?|bmp|eps|webp)$/i.test(str)
  );
};

/** The field under validation must be a valid excel file. */
FormUtils.isValidExcelFile = (str) => {
  return (
    !FormUtils.isEmpty(str) &&
    /\.(xl)?(sx|sm|sb|tx|tm|s|t|xml|am|a|w|r|csv)$/i.test(str)
  );
};

/** The field under validation must be a valid URL. */
FormUtils.isValidUrl = (str) => {
  return (
    !FormUtils.isEmpty(str) &&
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi.test(
      str
    )
  );
};

/** The field under validation must not exist within the given list. */
FormUtils.isUnique = (str, list) => {
  return !FormUtils.isEmpty(str) && Array.isArray(list) && !list.includes(str);
};

/** The field under validation must be an integer. */
FormUtils.isInteger = (str) => {
  return FormUtils.isNumeric(str) && Number.isInteger(Number(str));
};

/** The field under validation must have a minimum value. */
FormUtils.min = (str, value) => {
  return FormUtils.isNumeric(str) && +str >= value;
};

/** The field under validation must be less than or equal to a maximum value */
FormUtils.max = (str, value) => {
  return FormUtils.isNumeric(str) && +str <= value;
};

/** The field under validation must be entirely alphabetic characters. */
FormUtils.alpha = (str) => {
  return !FormUtils.isEmpty(str) && /^[a-zA-Z]+$/.test(str);
};

/** The field under validation must be entirely alpha-numeric characters. */
FormUtils.alphaNumeric = (str) => {
  return !FormUtils.isEmpty(str) && /^[a-zA-Z0-9]+$/.test(str);
};

/** The field under validation must have a size between the given min and max. */
FormUtils.between = (str, min, max) => {
  return !FormUtils.isEmpty(str) && str >= min && str <= max;
};

/** The field under validation must have a different value than value. */
FormUtils.different = (str, value) => {
  return !FormUtils.isEmpty(str) && str !== value;
};

/** The field under validation must start with one of the given values */
FormUtils.startsWith = (str, prefix) => {
  return !FormUtils.isEmpty(str) && str.substr(0, prefix.length) === prefix;
};

/** The field under validation must end with one of the given values. */
FormUtils.endsWith = (str, suffix) => {
  return !FormUtils.isEmpty(str) && str.substr(suffix.length) === suffix;
};

/** The field under validation must be a multiple of value. */
FormUtils.multipleOf = (str, value) => {
  return FormUtils.isNumeric(str) && Number(str) % +value === 0;
};

/** The field under validation must be an IP address. */
FormUtils.ip = (str) => {
  return FormUtils.ipv4(str) || FormUtils.ipv6(str);
};

/** The field under validation must be an IPV4 address. */
FormUtils.ipv4 = (str) => {
  return /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/.test(str);
};

/** The field under validation must be an IPV6 address. */
FormUtils.ipv6 = (str) => {
  return /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/.test(
    str
  );
};

/** The field under validation must be a valid JSON string. */
FormUtils.json = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

// scrolling utilities
export const on = (obj, ...args) => {
  obj.addEventListener(...args);
};

export const off = (obj, ...args) => {
  obj.removeEventListener(...args);
};

export default FormUtils;
