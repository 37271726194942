import { createMachine, assign } from "xstate";

// Action to assign the context id
const assignId = assign({
  id: (context, event) => event.id,
});

// Client Load Board State Machine
export const AccountMachine = createMachine({
  id: "accountHome",
  initial: "idle",
  context: {
    id: null,
  },
  actions: {
    assignId,
  },
  states: {
    idle: {
      on: {
        MY_DETAILS: "myDetails",
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
      },
    },
    myOrders: {
      on: {
        MY_DETAILS: "myDetails",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "idle",
      },
    },
    buyAgain: {
      on: {
        MY_DETAILS: "myDetails",
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "idle",
      },
    },
    orderDetails: {
      on: {
        MY_DETAILS: "myDetails",
        MY_ORDERS: "myOrders",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "idle",
      },
    },
    myDetails: {
      on: {
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "idle",
      },
    },
    changePassword: {
      on: {
        MY_ORDERS: "myOrders",
        MY_DETAILS: "myDetails",
        ORDER_DETAILS: "orderDetails",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "idle",
      },
    },
    addressBook: {
      on: {
        MY_ORDERS: "myOrders",
        CHANGE_PASSWORD: "changePassword",
        ORDER_DETAILS: "orderDetails",
        MY_DETAILS: "myDetails",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        ADDRESS_BOOK_EDIT: { target: "addressBookEdit", actions: [assignId] },
        ADDRESS_BOOK_ADD: "addressBookAdd",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "idle",
      },
    },
    addressBookEdit: {
      on: {
        MY_DETAILS: "myDetails",
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "addressBook",
      },
    },
    addressBookAdd: {
      on: {
        MY_DETAILS: "myDetails",
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "addressBook",
      },
    },
    paymentMethods: {
      on: {
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        MY_DETAILS: "myDetails",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "idle",
      },
    },
    giftCards: {
      on: {
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        MY_DETAILS: "myDetails",
        CONTACT_PREFERENCES: "contactPreferences",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        BACK: "idle",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
      },
    },
    prescriptionHistory: {
      on: {
        MY_DETAILS: "myDetails",
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "idle",
      },
    },
    myHealthInsurance: {
      on: {
        MY_DETAILS: "myDetails",
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        CONTACT_PREFERENCES: "contactPreferences",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
        BACK: "idle",
      },
    },
    contactPreferences: {
      on: {
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        MY_DETAILS: "myDetails",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        BACK: "idle",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
      },
    },
    packMyMeds: {
      on: {
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        MY_DETAILS: "myDetails",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        BACK: "idle",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
      },
    },
    refillsAndAutoRefills: {
      on: {
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        MY_DETAILS: "myDetails",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        BACK: "idle",
        PACK_MY_MEDS: "packMyMeds",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
        MEDICATION_REMINDERS: "medicationReminders",
      },
    },
    requestMedicalRecords: {
      on: {
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        MY_DETAILS: "myDetails",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        BACK: "idle",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        MEDICATION_REMINDERS: "medicationReminders",
      },
    },
    medicationReminders: {
      on: {
        MY_ORDERS: "myOrders",
        ORDER_DETAILS: "orderDetails",
        CHANGE_PASSWORD: "changePassword",
        ADDRESS_BOOK: "addressBook",
        PAYMENT_METHODS: "paymentMethods",
        GIFT_CARDS: "giftCards",
        MY_DETAILS: "myDetails",
        MY_HEALTH_INSURANCE: "myHealthInsurance",
        PRESCRIPTION_HISTORY: "prescriptionHistory",
        BUY_AGAIN: "buyAgain",
        BACK: "idle",
        PACK_MY_MEDS: "packMyMeds",
        REFILLS_AND_AUTO_REFILLS: "refillsAndAutoRefills",
        REQUEST_MEDICAL_RECORDS: "requestMedicalRecords",
      },
    },
  },
});
