import React, { createContext, useContext, useState } from "react";

interface Notification {
  id: number;
  type: "Success" | "Error" | "Message" | "Warning" | "Cart";
  message: string;
  data?: any;
}

interface NotificationContext {
  notifications: Notification[];
  pushNotification: (notification: Notification) => void;
  clearNotification: (id: number) => void;
}

const NotificationsContext = createContext<NotificationContext>({
  notifications: [],
  pushNotification: () => {},
  clearNotification: () => {},
});

const NotificationsProvider: React.FC = ({ children }: any) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const pushNotification = (notification: Notification) => {
    setNotifications((prevState) => [...prevState, notification]);
  };

  const clearNotification = (id: number) => {
    setNotifications((prevState) => prevState.filter((notification) => notification.id !== id));
  };

  return (
    <NotificationsContext.Provider value={{ notifications, pushNotification, clearNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotifications = (): NotificationContext => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error("useNotifications must be used within a NotificationsProvider");
  }

  return context;
};

export { NotificationsProvider, useNotifications };
