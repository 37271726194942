import { Link } from "react-router-dom";

// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/BecomeASupplier.module.css";

const SupplierKnowUs = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="sup_know">
        <div className="row">
          <div className="col-md-2">
            <div className={style.supplier_home}>
              <Link to="/become-a-supplier">
                <i className="fa fa-angle-left"></i> suppliers
              </Link>
            </div>
          </div>
          <div className="col-md-8">
            <div className={style.sup_know_header}>
              <h1>Get to know Link pharmacy</h1>
              <p>
                There's more to becoming a <b>Link pharmacy's</b> supplier than
                just filling out an application. Get to know us first.
              </p>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-md-12">
            <div className={style.sup_header_img}></div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className={style.sup_know_cont}>
              <p>
                As a potential supplier, one of the most important things you
                can do is get to know how Link pharmacy works. Whether you’re
                brand new to our stores or you’ve known us for years, here are a
                few ways to understand how we help our customers and members
                save money and live better – while consistently maintaining our
                commitment to quality.
              </p>
              <li>Visit a Link pharmacy near you.</li>
              <p>
                Get a sense of who we are, in our stores. Visiting a store gives
                you a better understanding of what they’re like. If you’re
                especially interested in becoming a local supplier, information
                about your local stores can be invaluable. Find a Link pharmacy
                store.
              </p>
              <li>Compare your product or service to existing ones.</li>
              <p>
                Look at the types of products and services we sell and use. Does
                what you have to offer seem like a good fit? Would your product
                be appropriate on Link pharmacy's shelves? Is your product or
                service the most innovative, best quality and at the lowest
                price?
              </p>
              <li>Make sure you understand our shoppers’ needs.</li>
              <p>
                When we choose new items to stock, we select products customers
                want. How would your product or service improve our customers’
                lives? Also, Link pharmacy serves customers and members nearly
                260 million times per week around the world. Know how large your
                capacity is for supplying.
                <Link to="/company-info">Learn more about Link pharmacy</Link>.
              </p>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </section>
    </Layout>
  );
};
export default SupplierKnowUs;
