import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import styles from "../../styles/views/Returns.module.css";

const Returns = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className={styles.returns}>
        <p>
          Unfortunately, we do not under any condition accept refund and exchange requests on medication & prescription
          items or any item that has a broken seal because there is no mechanism to confirm the items have been handled
          or stored according to specified instructions and have not been tampered with. Several types of goods are also
          exempt from being returned. Perishable goods such as food, newspapers or magazines cannot be returned. We also
          do not accept sanitary or intimate products, hazardous materials, or flammable liquids. Additional
          non-returnable items include gift cards and some health and personal care items. Only regular priced items may
          be refunded, unfortunately sale items cannot be refunded.
        </p>
        <p>
          We only replace items if they are defective or damaged at the time of purchase or if they were sent in error
          by staff. Items that are unavailable for replacement will be automatically refunded.
        </p>
      </div>
    </Layout>
  );
};

export default Returns;
