import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight, Phone, Facebook, WhatsApp, Web } from "@mui/icons-material";
import Layout from "../components/Layout/Layout";
import { meta } from "../globals";
import { sendEmailApi } from "../api/sendEmail";
import styles from "../styles/views/Contact.module.css";
import { Button, Input, TextArea } from "../components/CustomComponents";
import { Formik } from "formik";
import { useMutation } from "react-query";
import { useNotifications } from "../context/NotificationsContext";
import validationSchemas from "../utils/validationSchemas";

const ContactUs = () => {
  const { pushNotification } = useNotifications();

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const { mutate, isLoading } = useMutation(
    ["contact-us"],
    async (data: any) =>
      await sendEmailApi("v1/website/emails/contact-us", {
        name: data.name,
        email: data.email,
        message: data.message,
        phone_number: data.phoneNumber,
      }),
    {
      onSuccess: (data, variables) => {
        pushNotification({
          message: "Thank you for your request. We will be in touch with you shortly.",
          type: "Success",
          id: Math.random(),
        });

        variables.formikHelpers.resetForm();
      },
      onError: (error, variables) => {
        pushNotification({
          message: "Something went wrong. Please try again later.",
          type: "Error",
          id: Math.random(),
        });
      },
    }
  );

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className={`container-lg container-md container-sm container p-4 ${styles.contactUs}`}>
        <div className={"row-12"}>
          <p style={{ fontSize: "14px" }}>
            <Link to={"/home"}>Home</Link>
            <ChevronRight style={{ color: "gray", transform: "scale(1.1)" }} />
          </p>

          <h1 className="text-2xl font-opensans-bold">Contact Us</h1>
        </div>

        <div className={"row mt-5"}>
          <div className={"col-lg-4 col-md-5 col-sm-12"}>
            <div className={`mb-4`}>
              <h2 className="text-lg font-opensans-medium">Contact Information</h2>
            </div>
            <div className={styles.openingHours}>
              <h3 className="text-md font-opensans-medium mb-3">Link Customer Care Opening Hours</h3>
              <p className="font-opensans text-base">Monday to Friday: 9am - 7pm</p>
              <p className="font-opensans text-base">Saturday: 9am - 5pm</p>
              <p className="font-opensans text-base">Sundays &amp; Public Holidays: 9am - 7pm</p>
              <p className="font-opensans text-base">Christmas Day: Closed</p>

              <div className={styles.disclaimer}>
                <small className="font-opensans text-gray-600">
                  Please note all calls will be recorded or monitored for quality and training purposes. <br /> Charges
                  may vary. Please check with your provider for details.
                </small>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <a href={"tel:+260211255556"} className="font-opensans text-sm">
                <Phone className={styles.icons} /> +260 211 255 556
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={"https://wa.me/260976255556?text=Hello%2C%20I'd%20like%20to%20get%20more%20information%20on..."}
                className="font-opensans text-sm"
              >
                <WhatsApp className={styles.icons} /> +260 976 255 556
              </a>
              <a href={"https://www.link.co.zm"} className="font-opensans text-sm">
                <Web className={styles.icons} /> Link Pharmacy Website
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={"https://www.facebook.com/linkpharmacyzm/"}
                className="font-opensans text-sm"
              >
                <Facebook className={styles.icons} /> linkpharmacyzm
              </a>
            </div>
          </div>

          <div className={"col-lg-8 col-md-7 pr-md-5 col-sm-12 mt-3 m-md-0 m-lg-0 m-xl-0 m-xxl-0"}>
            <div className={"pr-md-5"}>
              <div className={`mb-4`}>
                <p className="text-lg font-opensans-medium">Send us a Message</p>
              </div>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchemas.contactUs}
                onSubmit={(values, formikHelpers) => {
                  mutate({ ...values, formikHelpers });
                }}
              >
                {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className={"mb-3"}>
                      <Input
                        label={"Full Name *"}
                        name={"name"}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.name}
                        touched={touched.name}
                      />
                    </div>

                    <div className={"mb-3"}>
                      <Input
                        label={"Email Address *"}
                        name={"email"}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email}
                        touched={touched.email}
                      />
                    </div>

                    <div className={"mb-3"}>
                      <Input
                        label={"Phone Number *"}
                        name={"phoneNumber"}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.phoneNumber}
                        touched={touched.phoneNumber}
                      />
                    </div>

                    <div className={"mb-3"}>
                      <TextArea
                        label={"Message *"}
                        rows={5}
                        name={"message"}
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.message}
                        touched={touched.message}
                      />
                    </div>

                    <div className={"mb-3"}>
                      <Button disabled={isLoading} type={"submit"}>
                        {isLoading ? <Button.Loader label={"Sending..."} /> : "Send Message"}
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
