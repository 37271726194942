import * as Yup from "yup";

const scheduleAnAppointmentSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  gender: Yup.string().required("Gender is required").oneOf(["male", "female"]),
  address: Yup.string().required("Address is required."),
  email: Yup.string().email("Please enter a valid email address.").required("Email is required."),
  phoneNumber: Yup.string().required("Phone Number is required."),
  dateOfVisit: Yup.string().required("Date of Visit is required."),
  dateOfAppointment: Yup.string().required("Date of Appointment is required."),
  reason: Yup.string().required("Reason is required."),
  preferredTime: Yup.string().required("Preferred Time is required."),
  leastPreferredTime: Yup.string().required("Least Preferred Time is required."),
  comments: Yup.string(),
});

export default scheduleAnAppointmentSchema;
