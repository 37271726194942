import { Form, Formik } from "formik";
import { IoCalendarNumber, IoCode, IoPerson } from "react-icons/io5";
import { ziscSchema } from "../../globals/schemas";
import styles from "../../styles/views/MyHealthInsurance.module.css";
import { FormField } from "../forms";

const Zisc = () => {
  return (
    <Formik
      initialValues={{
        providerName: "",
        providerNum: "",
        fullname: "",
        town: "",
        claimNum: "",
        mobileNum: "",
        schemeType: "",
        patientFullname: "",
        dob: "",
        admissionDate: "",
        dischargeDate: "",
        claimForm: "",
        insuranceCompany: "ZISC",
      }}
      onSubmit={(values) => console.log(values)}
      validationSchema={ziscSchema}
    >
      {({ isSubmitting, values, handleChange, setFieldValue, errors }) => (
        <Form className="space-y-4 flex flex-col self-center w-full">
          <div className={styles.stepCounter}>
            <div>2</div> Provider Details
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Provider Name"}
              type="text"
              name="providerName"
              placeholder="e.g.: John"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Town"}
              type="text"
              name="town"
              placeholder="e.g.: Lusaka"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Claim Number"}
              type="text"
              name="claimNum"
              placeholder="e.g.: XXXXX"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Provider Number / Code"}
              type="text"
              name="providerNum"
              placeholder="e.g.: XXXXXX"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Contact Number"}
              type="text"
              name="mobileNum"
              placeholder="e.g.: XXXX-XXX-XXX"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className={styles.stepCounter}>
            <div>3</div> Main Member Details
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Fullname"}
              type="text"
              name="fullname"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Scheme Type"}
              type="text"
              name="schemeType"
              placeholder="e.g.: Scheme type"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Employer"}
              type="text"
              name="employer"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Policy Number"}
              type="text"
              name="policyID"
              placeholder="e.g.: XXXXXXXX"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className={styles.stepCounter}>
            <div>4</div> Patient Details
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Patient Fullname"}
              type="text"
              name="patientFullname"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Date of Birth"}
              type="date"
              name="patientDOB"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Admission Date"}
              type="date"
              name="admissionDate"
              placeholder=""
              width={"w-full"}
              margin="mb-0"
              icon={<IoCalendarNumber className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Discharge Date"}
              type="date"
              name="dischargeDate"
              placeholder=""
              width={"w-full"}
              margin="mb-0"
              icon={<IoCalendarNumber className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className={styles.stepCounter}>
            <div>5</div> Claim Details
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={"mt-4"}>
                <div>
                  <h5 className={`font-weight-bold ${styles.label}`}>Upload Copy of Claim Form</h5>

                  <p>Please ensure Patient's signature, Doctor's signature and Medical Services are clearly visible.</p>

                  <input
                    type="file"
                    name="fullNames"
                    className={"form-control"}
                    onChange={(event) => {
                      setFieldValue("claimForm", event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <input
              type={"submit"}
              value="Submit form"
              className="bg-link-green text-stone-900 rounded-full p-3"
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Zisc;
