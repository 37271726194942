import { Link } from "react-router-dom";
// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/RequestMedicalRecords.module.css";
import father_n_daughter from "../../assets/images/father_n_daughter_1.jpg";

const RequestMedicalRecords = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className={style.r_header}>
              <h1>Request Your Medical Records</h1>
            </div>
            <div className={style.r_cont}>
              <p>
                Please complete the following information in its entirety.
                Contact us with any questions at +260 211 255 556 or +260 976
                255 556. Please note that there is a fee for transmission of
                your records. The chart below details the costs.
              </p>
            </div>
            <div>
              <a href="/#">
                Authorisation for disclosure of health information
              </a>
            </div>
            <div className={style.r_cont}>
              <h4>
                Patients may, where permitted by applicable law, request copies
                of their personal data.{" "}
              </h4>
              <p>
                To request that we send your information to another party, you
                must complete this form in its entirety, and send a{" "}
                <b>Release of Information Form</b>. You can download the form by{" "}
                <Link to="#">
                  clicking on this link and follow the instructions
                </Link>
                . You will see the download link again once you submit this
                online form.
              </p>
            </div>
            <div className={style.r_form}>
              <p>
                Fields marked with{" "}
                <span>
                  <sup>&#10033;</sup>
                </span>{" "}
                are required
              </p>
              <form>
                <label htmlFor="fname">
                  First Name{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <input type="text" id="fname" />
                <label htmlFor="lname">
                  Last name{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <input type="text" id="lname" />
                <label htmlFor="date">
                  Date of Birth{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <input type="date" />
                <label htmlFor="address">
                  Address{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <input type="text" id="address" />
                <label htmlFor="zip-code">
                  Zip Code{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <input type="text" id="zip-code" />
                <label htmlFor="email">
                  Email Address{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <input type="text" id="email" />
                <label htmlFor="pnumber">
                  Phone Number{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <input type="number" id="pnumber" />
                <label htmlFor="discloser">
                  What information are you requesting be disclosed?{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <textarea name="" id="discloser" cols="10" rows="5"></textarea>
                <div className={style.r_auth}>
                  <p>Authorisation</p>
                  <input type="checkbox" />
                  <span>
                    {" "}
                    I agree In the event that any of the foregoing information
                    contains mental health records, genetic information,
                    venereal disease-related records, tuberculosis-related
                    records, drug and alcohol treatment records, and/or
                    HIV/AIDS-related diagnosis and treatment information, I
                    specifically authorize release of such information (By
                    checking this box you agree)
                  </span>
                </div>
                <label htmlFor="reason">
                  Purpose or reason for request{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <textarea name="" id="reason" cols="10" rows="5"></textarea>
                <p className={style.r_knowingly}>
                  I knowingly and voluntarily authorize Linkmediclinic and its
                  employees and agents to use and/or disclose protected health
                  information (PHI) about me in the manner described in this
                  authorization. (If you are a patient, you may type “my
                  personal request” in the box below.)
                </p>
                <label htmlFor="date">
                  This authorization will expire on{" "}
                  <span>
                    <sup>&#10033;</sup>
                  </span>
                </label>
                <input type="date" /> <br />
                <input type="submit" value="SUBMIT REQUEST" />
              </form>
            </div>
          </div>
          <div className="col-md-4">
            <div className={style.r_img}>
              <img src={father_n_daughter} alt="Request medical record" />
              <h4>Love life</h4>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RequestMedicalRecords;
