import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useNotifications } from "../../../../context/NotificationsContext";
import { RemoveCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const EftDialog = ({ isOpen, onClose, Formik, deliveryMethod, selectedBranch }) => {
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const { pushNotification } = useNotifications();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const uploadMutation = useMutation(
    "eft-order",
    async () => {
      if (!file) return;

      const { values } = Formik;

      const addressObj = {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        residential_area: values.residentialArea,
        postal_code: values.postalCode,
      };

      const formData = new FormData();

      formData.append("delivery_method", deliveryMethod);

      if (deliveryMethod === "deliver") formData.append("address", JSON.stringify(addressObj));
      else formData.append("branch_id", selectedBranch);

      formData.append("email", values.email);
      formData.append("phone_number", values.phoneNumber);
      formData.append("proof_of_payment", file);

      const res = await axios.post("/v1/website/orders/eft", formData);
      return res.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
        navigate("/thank-you", { replace: true });
      },
    }
  );

  const onFilePicked = (event) => {
    if (event.target.files[0].size > 5242880) {
      pushNotification({
        id: Math.random(),
        type: "Warning",
        message: "File is too big",
      });
      return;
    }

    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (!file) {
      setPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>
        <h4 className="font-opensans-semibold">Pay via EFT / Bank Deposit</h4>
      </DialogTitle>
      <DialogContent>
        <p className="font-opensans text-base mb-3">
          Please make payment to the following account and upload proof of payment to complete your order.
        </p>
        <Grid container spacing={3} className="font-opensans">
          <Grid item md={6}>
            <div>
              <ul className="list-disc list-inside space-y-1 text-sm">
                <li>Bank Name: Absa Bank Zambia PLC</li>
                <li>Account Name: Link Client</li>
                <li>Account Number: 1092517</li>
                <li>Branch Code: 016</li>
                <li>Branch Name: Lusaka Business Centre</li>
                <li>Short Code: 020016</li>
                <li>Currency: ZMW</li>
                <li>Swift Code: BARCZMLX</li>
              </ul>
            </div>
          </Grid>
          <Grid item md={6}>
            {file && (
              <div style={{ maxHeight: "500px", position: "relative" }}>
                <div style={{ position: "absolute", top: "-35px", right: "0" }}>
                  <IconButton onClick={() => setFile(null)}>
                    <RemoveCircle />
                  </IconButton>
                </div>
                <img
                  src={preview}
                  alt={file.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <button
          onClick={() => {
            inputRef.current.click();
          }}
          disabled={uploadMutation.isLoading}
          className="bg-link-green px-3 py-2 text-white font-opensans-semibold rounded hover:opacity-90 disabled:opacity-50"
        >
          Upload Deposit Slip
        </button>

        <button
          onClick={uploadMutation.mutate}
          disabled={!file || uploadMutation.isLoading}
          className="bg-link-green px-3 py-2 text-white font-opensans-semibold rounded hover:opacity-90 disabled:opacity-50"
        >
          {uploadMutation.isLoading ? "Uploading..." : "Complete Order"}
        </button>
        <input
          type="file"
          name={"deposit-slip"}
          id={"deposit-slip"}
          hidden
          ref={inputRef}
          onChange={onFilePicked}
          accept="image/*"
        />
      </DialogActions>
    </Dialog>
  );
};

export default EftDialog;
