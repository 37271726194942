import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import StoreContent from "./StoreContent";
import StoreSideBar from "../../components/StoreSideBar";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import BreadCrumbs from "../../components/BreadCrumbs";
import { getMenusApi } from "../../api/menus";
import { getProductTypesApi } from "../../api/productTypes";
import { toHumanReadableString } from "../../helpers/toHumanReadableString";
import { usePagination } from "../../hooks/usePagination";

const ProductTypes = () => {
  const { catName, catID, subCatName, subCatID } = useParams();
  const [sidebarList, setSidebarList] = useState([]);
  const [pageTitle, setPageTitle] = useState("Link Pharmacy | Online Store Zambia");
  const [page, onPageChange] = usePagination(1);

  const { data: menu, status: menuStatus } = useQuery(["menu-categories"], getMenusApi);

  const {
    data: products,
    status: productsStatus,
    isLoading,
  } = useQuery(["subcat-data", subCatName, page], async () => getProductTypesApi(subCatID, page));

  // Effect to produce appropriate title
  useEffect(() => {
    catName && subCatName
      ? setPageTitle(`${toHumanReadableString(catName)} | ${toHumanReadableString(subCatName)} | Link Pharmacy`)
      : setPageTitle("Link Pharmacy | Online Store Zambia");
  }, [catName, subCatName]);

  useEffect(() => {
    if (menuStatus === "success") {
      const category = menu.filter((cat) => cat.category_id === Number(catID))[0];

      const subcategory = category.subcategories.filter((subcat) => subcat.subcategory_id === Number(subCatID))[0];

      setSidebarList(subcategory.product_types);
    }
  }, [catID, menu, menuStatus, subCatID]);

  return (
    <Layout
      meta={{
        title: pageTitle,
        keywords: catName && subCatName ? `${catName}, ${subCatName}, Link Pharmacy online store` : meta.homeKeywords,
        description:
          catName && subCatName
            ? `${catName} ${subCatName} | Link Pharmacy online store Zambia`
            : meta.storeDescription,
      }}
      header
      footer
      bgColor={"bg-stone-100"}
    >
      <div className="container mx-auto mt-2">
        <BreadCrumbs
          crumbs={[
            { name: "Home", link: "/" },
            { name: `Shop`, link: "/store/catalog" },
            { name: `All Products`, link: "/store/products" },
            catName && {
              name: toHumanReadableString(catName),
              link: `/store/catalog/${catName}/${catID}`,
            },
            subCatName && {
              name: toHumanReadableString(subCatName),
              link: `/store/catalog/${subCatName}/${subCatID}`,
            },
          ]}
        />

        <div className="flex flex-col md:flex-row mt-1 space-x-0 md:space-x-10">
          <div className="w-full md:w-3/12">
            <StoreSideBar type="subcategory" list={sidebarList} value={subCatName} valuesStatus={menuStatus} />
          </div>
          <div className="w-full md:w-9/12">
            <StoreContent
              type="subcategory"
              productsStatus={productsStatus}
              isLoading={isLoading}
              products={products}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductTypes;
