import { BrowserRouter as Router } from "react-router-dom";
import { AuthValues } from "../context/AuthContext";
import Routing from "../routes/Routing";
import Notifications from "../components/Notifications";
import { CartProvider } from "../context/cartContext";
import ErrorBoundary from "../components/ErrorBoundary";

const RouteProviders = () => {
  return (
    <Router>
      <AuthValues>
        <CartProvider>
          <ErrorBoundary>
            <Notifications />
            <Routing />
          </ErrorBoundary>
        </CartProvider>
      </AuthValues>
    </Router>
  );
};

export default RouteProviders;
