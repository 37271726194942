import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../Loader";
import styles from "../../styles/views/AddressBook.module.css";
import { Button, Input, Select } from "../CustomComponents";
import cities from "../../utils/cities";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Formik, FormikValues } from "formik";
import axios from "axios";
import validationSchemas from "../../utils/validationSchemas";
import { useNotifications } from "../../context/NotificationsContext";

const AddressBookEdit = ({ current: { context } }) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const { pushNotification } = useNotifications();
  const id = new URLSearchParams(location.search).get("id");
  const navigate = useNavigate();

  const { data: address, isLoading } = useQuery(["edit-address", id], async () => {
    const { data } = await axios.get(`/v1/website/address/${id}`);
    return data.data;
  });

  const initialValues = {
    addressLine1: address?.attributes.address1,
    addressLine2: address?.attributes.address2,
    city: address?.attributes.city,
    residentialArea: address?.attributes.residential_area,
    postalCode: address?.attributes.postal_code,
  };

  const queryClient = useQueryClient();
  const { mutate, isLoading: isMutationLoading } = useMutation(
    ["edit-address-mutation", id],
    async (values: FormikValues) => {
      const { data } = await axios.post(`/v1/website/address/${id}`, {
        address1: values.addressLine1,
        address2: values.addressLine2,
        city: values.city,
        residential_area: values.residentialArea,
        postal_code: values.postalCode,
        _method: "PUT",
      });
      return data;
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(["edit-address", id]);
        variables?.formikHelpers?.resetForm();
        pushNotification({
          message: "Your address has been updated successfully.",
          type: "Success",
          id: Math.random(),
        });
      },
      onError: (error, variables) => {
        pushNotification({
          message: "Something went wrong. Please try again or contact support for help.",
          type: "Error",
          id: Math.random(),
        });
      },
    }
  );

  return (
    <div className="w-full flex flex-col text-base">
      <div className="w-full flex items-center justify-between border-b px-4 py-3 hidden md:block">
        <h2 className="m-0 font-opensans-medium text-2xl">Edit Address</h2>
      </div>

      <div className="w-full flex flex-col p-3">
        {isLoading && <Loader />}

        {!isLoading && (
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikHelpers) => {
              mutate({ ...values, formikHelpers });
            }}
            validationSchema={validationSchemas.address}
            enableReinitialize={true}
          >
            {({ values, handleChange, handleBlur, setFieldValue, errors, touched, dirty, handleSubmit }) => (
              <form
                className={`${styles.form} text-sm md:text-base`}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className={"mb-3"}>
                  <div>
                    <Input
                      readOnly={true}
                      label={"Full Name"}
                      value={user ? `${user.firstname} ${user.lastname}` : ""}
                    />
                  </div>
                </div>

                <div className={"mb-3"}>
                  <div>
                    <Input
                      label={"Address line 1"}
                      name="addressLine1"
                      value={values.addressLine1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.addressLine1}
                      touched={!!touched.addressLine1}
                    />
                  </div>
                </div>

                <div className={"mb-3"}>
                  <div>
                    <Input
                      label={"Address line 2"}
                      name="addressLine2"
                      value={values.addressLine2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.addressLine2}
                      touched={!!touched.addressLine2}
                    />
                  </div>
                </div>

                <div className={"mb-3"}>
                  <Select
                    label={"Town/City"}
                    value={values.city}
                    onChange={(value) => {
                      setFieldValue("city", value);
                    }}
                    options={cities.map((city) => {
                      return { value: city, label: city };
                    })}
                  />
                </div>

                <div className={"mb-3"}>
                  <div>
                    <Input
                      label={"Residential Area"}
                      name="residentialArea"
                      value={values.residentialArea}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.residentialArea}
                      touched={!!touched.residentialArea}
                    />
                  </div>
                </div>

                <div className={"mb-3"}>
                  <div>
                    <Input
                      label={"Postal Code"}
                      name="postalCode"
                      value={values.postalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.postalCode}
                      touched={!!touched.postalCode}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex flex-row items-center space-x-4">
                    <Button variant={"secondary"} onClick={() => navigate("/account?t=address-book")}>
                      Cancel
                    </Button>
                    <Button disabled={isMutationLoading || !dirty} type={"submit"}>
                      {isMutationLoading ? <Button.Loader label={"Saving..."} /> : "Save Changes"}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default AddressBookEdit;
