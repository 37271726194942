import { Link } from "react-router-dom";
// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import JobAlertForm from "../../components/JobAlertForm";
import JobSearchForm from "../../components/JobSearchForm";
import style from "../../styles/views/CareerPharmacy.module.css";

const CareerPharmacy = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="row">
        <div className="col-md-12">
          <div className={style.header_bg}>
            <div className={style.header_color}>
              <div className={style.header_cont}>
                <div className="container">
                  <h1>
                    Greater care for our patients. And greater things for your
                    career.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <JobSearchForm />
            <div className={style.pharmacy_cont}>
              <div className={style.pharmacy_nav}>
                <Link to="/">
                  Home <span>&#10095;</span>
                </Link>
                <Link to="/careers">
                  Career areas <span>&#10095;</span>
                </Link>
                <Link to="/career/">Pharmacy</Link>
              </div>
              <h4>Pharmacy</h4>
              <p>
                Link Pharmacy has been evolving along with the pharmacy
                profession for over 20 years, so we know what it takes to stay
                ahead of current trends and help our patients be well every day.
                Over the past few years, Link Pharmacy has used its many years
                of success as an anchor as we continue to expand, to innovate
                and to put pharmacy at the forefront of our growth.
              </p>
              <p>
                Pharmacy practice at Link Pharmacy embraces an unmatched
                standard of quality care, and encourages pharmacists to take on
                new clinical roles in the community. As a pharmacist, this means
                Link Pharmacy can offer unparalleled opportunities to advance
                your career and impact your patients’ health and daily living.
                Through focused efforts to allow greater access to patient care
                and provide additional services such as immunizations,
                medication therapy management, and health testing, Link Pharmacy
                pharmacists can look forward to practicing at the highest level
                at over 9,500 locations in all 50 states and Puerto Rico. In
                addition to retail pharmacy, Link Pharmacy offers opportunities
                in clinics, specialty pharmacy, infusion pharmacy, and in
                various corporate settings.
              </p>
              <p>
                As a Link Pharmacy pharmacist or pharmacy technician, you’ll
                have numerous opportunities for career growth and a wide range
                of locations from which to choose. You can work as a community
                pharmacist in one of our retail locations, as an onsite
                pharmacist or within a hospital or a business – just to name a
                few of the possibilities. It’s the chance to hone your skills
                and advance in a company where our pharmacists are uniquely
                valued. Click the links below to learn more.
              </p>
            </div>
            <JobAlertForm />
            <br />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CareerPharmacy;
