// Components
import styles from "../styles/views/ContactPreferences.module.css";

const ContactPreferences = () => {
  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b p-2">
        <h2 className="m-0 text-md text-stone-600">{"Contact Preferences"}</h2>
      </div>

      <div className="p-6 overflow-y-hidden overflow-x-auto whitespace-nowrap pb-4">
        <div className={"row-12"}>
          <p>
            What would you like to hear about? Select your options below and
            we'll keep you in the loop
          </p>
        </div>

        <div className={`my-4 ${styles.section}`}>
          <span>
            <h4>DISCOUNTS AND SALES</h4>
            <p>Be first in line to nab the stuff you love for less.</p>
          </span>
          <div>
            <span>
              <input type="checkbox" className={"form-check-input"} checked />
              <label className="form-check-label" htmlFor="text">
                Email
              </label>
            </span>

            <span>
              <input
                type="checkbox"
                className={"form-check-input"}
                name="text"
                checked
              />
              <label className="form-check-label" htmlFor="text">
                Text
              </label>
            </span>
          </div>
        </div>

        <div className={`my-4 ${styles.section}`}>
          <span>
            <h4>NEW STUFF</h4>
            <p>The latest drops, news and advice.</p>
          </span>
          <div>
            <span>
              <input type="checkbox" className={"form-check-input"} checked />
              <label className="form-check-label" htmlFor="text">
                Email
              </label>
            </span>

            <span>
              <input
                type="checkbox"
                className={"form-check-input"}
                name="text"
                checked
              />
              <label className="form-check-label" htmlFor="text">
                Text
              </label>
            </span>
          </div>
        </div>

        <div className={`my-4 ${styles.section}`}>
          <span>
            <h4>LINK PARTNERS</h4>
            <p>
              Stay in the know with exclusive collabs and handpicked offers.
            </p>
          </span>
          <div>
            <span>
              <input type="checkbox" className={"form-check-input"} checked />
              <label className="form-check-label" htmlFor="text">
                Email
              </label>
            </span>

            <span>
              <input
                type="checkbox"
                className={"form-check-input"}
                name="text"
                checked
              />
              <label className="form-check-label" htmlFor="text">
                Text
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPreferences;
