import HelpCenterSidebar from "../../components/HelpCenterSidebar";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/HelpCenter.module.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import shippingRates from "../../utils/shippingRates";

const HelpShipping = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="shipping_help_main">
        <section>
          <div className={style.shipping_help_bg}>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className={style.shipping_help_header}>
                  <span>Help Center</span>
                  <h1>Shipping</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <HelpCenterSidebar />
            </div>
            <div className="col-md-6">
              <div className={style.shipping_help}>
                <p className="font-opensans-medium mb-3">
                  The postage and packaging charge you will pay will be
                  displayed on screen before you confirm your order to us. Where
                  multiple items are included in one order, we will attempt to
                  deliver your items together.
                </p>
                <p className="font-opensans-medium mb-3">
                  Medicines will need to be signed for by someone aged 18 or
                  over. If there isn't anyone who can sign for your order,
                  kindly contact us to arrange a suitable delivery time and
                  date. Unfortunately, your order can't be left in a safe place
                  or with a neighbour.
                </p>
                <p className="font-opensans-medium mb-3">
                  The courier may attempt to deliver your parcel twice, before
                  contacting you to arrange for collection from their depot or
                  return it to us.
                </p>
                <p className="mb-3" />

                <p className="font-opensans-medium mb-3">
                  Postage and packing costs will be added to the cost of your
                  order and will depend on the total cost of the items you order
                  and, where delivery options are provided, the option you
                  choose.
                </p>

                <p className="font-opensans-medium mb-3">
                  In providing payment card details, you confirm that you are
                  authorised to use the card and authorise us to take payment in
                  full for the applicable prescription charges, postage and
                  packing charges and any other charges that become due to us
                  under these terms. Refunds, if applicable, will only be using
                  the payment gateway and/or card originally used for payment.
                </p>

                <p className="font-opensans-medium">
                  Delivery of any order regardless of weight within Lusaka is{" "}
                  <span className="font-opensans-bold">K50</span>. For delivery
                  of your packages or orders outside of Lusaka, see the table
                  below.
                </p>

                <div className="md:max-w-[50%]">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Weight (KG)</TableCell>
                        <TableCell>Rate (ZMW)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shippingRates.map((rate) => (
                        <TableRow key={rate.weight}>
                          <TableCell>{rate.weight}</TableCell>
                          <TableCell>{rate.rate}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell>Additional 0.5Kg</TableCell>
                        <TableCell>17.76</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </section>
      </section>
    </Layout>
  );
};
export default HelpShipping;
