import axios from "axios";

export const productsApi = async (
  sort,
  productTypeId,
  searchValue,
  valuesToGet,
  subCatId,
  pageNo
) => {
  let res = await axios.get(
    `/v1/website/products?sort=${sort || ""}&product_type_id=${
      productTypeId || ""
    }&search=${searchValue || ""}&subcategory_id=${subCatId}&values_to_get=${
      valuesToGet ? valuesToGet : ""
    }&page=${pageNo}`
  );
  return res.data;
};

export const productApi = async (id) => {
  let res = await axios.get(`/v1/website/products/${id}`);
  return res.data;
};
