import { Link } from "react-router-dom";
// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import JobAlertForm from "../../components/JobAlertForm";
import JobSearchForm from "../../components/JobSearchForm";
import style from "../../styles/views/CareerCorporate.module.css";

const CareerCorporate = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="corp_wrapper">
        <section>
          <div className="row">
            <div className="col-md-12">
              <div className={style.corp_header_bg}>
                <div className={style.corp_header_color}>
                  <div className={style.corp_header}>
                    <div className="container">
                      <h1>
                        Connect to a company that believes in your ability to
                        make a difference{" "}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-md-12">
              <div className="container">
                <div className={style.corp_cont}>
                  <div>
                    <JobSearchForm />
                  </div>
                  <div className={style.corp_nav}>
                    <Link to="/">
                      Home <span>&#10095;</span>
                    </Link>
                    <Link to="/careers">
                      Career areas <span>&#10095;</span>
                    </Link>
                    <Link to="/corporate">Corporate support</Link>
                  </div>
                  <h4>Corporate Support Office</h4>
                  <p>
                    Working behind the scenes, you’ll find talented and driven
                    team members at our Corporate Support Office locations who
                    devise the strategies and initiatives for our stores and
                    help lead us into new health and beauty categories. From
                    Finance to Marketing, Human Resources to IT, our corporate
                    support professionals help provide the framework for helping
                    make health and well-being more accessible to more of our
                    patients and customer’s every day.
                  </p>
                  <p>
                    There are opportunities for people of many talents and
                    backgrounds – whether you’re interested in scouting
                    locations for new stores, increasing market share or
                    creating system platforms to keep us thriving alongside our
                    communities
                  </p>
                  <div>
                    <JobAlertForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};
export default CareerCorporate;
