import Layout from "../../components/Layout/Layout";
import styles from "../../styles/views/BloodPressure.module.css";
import React from "react";

const BloodPressure = () => {
  return (
    <Layout
      meta={{
        title: "Blood Pressure Screening | Link Pharmacy",
        keywords:
          "Blood Pressure Screening, Hypertension Detection, High Blood Pressure, Heart Health, Pharmacy Services",
        description:
          "Our pharmacies are equipped to screen for hypertension, commonly known as high blood pressure. Hypertension is a serious condition where your heart has to consistently work harder to pump blood around your body. Get screened today for your peace of mind.",
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center leading-normal text-center bg-[#10101061] h-60 md:h-96 p-3">
          <h1 className="text-white font-opensans-bold md:text-3xl mb-2">Blood Pressure Screening</h1>
          <p className="text-white font-opensans">All of our pharmacies are equipped to screen for hypertension</p>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <h2 className="text-2xl md:text-3xl mb-3">What is hypertension?</h2>
        <p className="mb-3">
          More commonly known as high blood pressure, hypertension is a serious condition where your heart has to
          consistently work harder to pump blood around your body.
        </p>

        <p className="mb-3">
          If untreated, the arteries can stiffen and narrow, making it easier for fatty material to clog them up and
          cause heart and circulatory diseases or heart attack and stroke.
        </p>

        <p>Speak to a member of our in store team if you are concerned about your blood pressure</p>
      </div>
    </Layout>
  );
};

export default BloodPressure;
