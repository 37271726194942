import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../../context/AuthContext";
import { Button, Input } from "../CustomComponents";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useNotifications } from "../../context/NotificationsContext";
import * as Yup from "yup";

const PersonalInfoEdit = () => {
  const { pushNotification } = useNotifications();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const initialValues = {
    firstName: user?.firstname,
    lastName: user?.lastname,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
  };

  const queryClient = useQueryClient();
  const updateUserMutation = useMutation(
    ["user-update", user.id],
    async (data) => {
      const res = await axios.put(`/users/${user.id}`, {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone_number: data.phoneNumber,
        _method: "put",
      });
      return await res.data;
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries("user");

        pushNotification({
          message: "Your details have been updated successfully.",
          type: "Success",
          id: Math.random(),
        });

        variables.formikHelpers.resetForm();
      },
      onError: (error) => {
        console.log(error);
        pushNotification({
          message: "An error occurred while updating your details.",
          type: "Danger",
          id: Math.random(),
        });
      },
    }
  );

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string().email("Please enter a email address.").required("Email address is required."),
    phoneNumber: Yup.string().required("Phone number is required."),
  });

  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b px-4 py-3 hidden md:block">
        <h2 className="m-0 font-opensans-medium text-lg md:text-2xl">My Details</h2>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          updateUserMutation.mutate({ ...values, formikHelpers });
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="p-6">
              <div className="mb-3">
                <Input
                  label={"First Name"}
                  name={"firstName"}
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.firstName ? errors.firstName : ""}
                  touched={touched.firstName}
                />
              </div>

              <div className="mb-3">
                <Input
                  label={"Last Name"}
                  name={"lastName"}
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.lastName ? errors.lastName : ""}
                  touched={touched.lastName}
                />
              </div>

              <div className="mb-3">
                <Input
                  label={"Email Address"}
                  name={"email"}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.email ? errors.email : ""}
                  touched={touched.email}
                />
              </div>

              <div className="mb-3">
                <Input
                  label={"Phone Number"}
                  name={"phoneNumber"}
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.phoneNumber ? errors.phoneNumber : ""}
                  touched={touched.phoneNumber}
                />
              </div>

              <div className="flex flex-row items-center space-x-4">
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    navigate("/account", { replace: true });
                  }}
                >
                  Cancel
                </Button>

                <Button type="submit" disabled={updateUserMutation.isLoading}>
                  {updateUserMutation.isLoading ? (
                    <>
                      <FontAwesomeIcon icon={faCircleNotch} style={{ marginRight: "10px" }} className={"fa fa-spin"} />{" "}
                      Loading
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfoEdit;
