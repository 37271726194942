import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getAllCategoriesApi } from "../api/categories";
import { Skeleton } from "@mui/material";
import { classNames } from "../utils/utils";
import useWindowSize from "../hooks/useWindowSize";

const StoreSideBar = ({ list, value, valuesStatus, type }) => {
  const { catName, catID, subCatName, subCatID } = useParams();
  const { data: categories, isSuccess: categoriesLoaded } = useQuery(["categories"], getAllCategoriesApi);
  const { width } = useWindowSize();

  const LinkItem = ({ name, link }) => {
    return (
      <Link
        className={classNames(
          "text-sm transition-all duration-200 ease-out cursor-pointer hover:underline",
          "w-full md:w-auto",
          "mr-0 md:mr-2",
          "whitespace-nowrap md:whitespace-normal",
          "rounded-md px-4 py-2 md:px-0 md:py-0 bg-stone-500 hover:bg-link-green-2 md:bg-transparent md:hover:bg-transparent",
          "text-[#fff] lg:text-green-700 font-opensans-semibold md:font-opensans",
          "focus:outline-offset-2", // Add a focus ring for accessibility
          "first:ml-3 md:first:ml-0",
          "mr-2"
        )}
        to={link}
        aria-label={`Go to ${name}`} // Added informative aria-label
      >
        {name}
      </Link>
    );
  };

  return (
    <div className="w-full py-3 rounded-xl mb-4 flex flex-col bg-white">
      <h6 className="font-opensans-bold px-3 mb-3 md:text-lg md:mb-4 text-gray-500 md:text-[#000]">
        {list && type ? (catName && !subCatName ? "Subcategories" : "Product Types") : "Categories"}
      </h6>

      <div
        className={classNames(
          (list && list.length > 0) || (!list && !valuesStatus && !value && categoriesLoaded)
            ? "flex md:flex-col overflow-x-scroll md:overflow-x-visible no-scrollbar"
            : "",
          "md:mb-4" // bottom margin
        )}
      >
        {list &&
          list.length > 0 &&
          list.map((listItem) => {
            if (listItem.name === "Mistakes") return null;

            return (
              <LinkItem
                name={listItem.name}
                link={
                  type === "category"
                    ? `/store/catalog/${catName && catName.replace(" ", "-")}/${catID}/${
                        listItem.name && listItem.name.replace(" ", "-")
                      }/${listItem.subcategory_id}`
                    : `/store/catalog/${catName && catName.replace(" ", "-")}/${catID}/${
                        subCatName && subCatName.replace("-", " ")
                      }/${subCatID}/${listItem.name && listItem.name.replace(" ", "-")}/${listItem.product_type_id}`
                }
              />
            );
          })}

        {!list &&
          !valuesStatus &&
          !value &&
          categoriesLoaded &&
          categories.data.map((category) => {
            if (category.attributes.name === "Mistakes") return null;

            return (
              <LinkItem
                name={category.attributes.name}
                link={`/store/catalog/${category.attributes.name && category.attributes.name.replace(" ", "-")}/${
                  category.id
                }`}
              />
            );
          })}
      </div>

      <div className="flex w-full space-x-2 md:flex-col md:space-x-0 items-center px-3">
        {(valuesStatus === "loading" || !categoriesLoaded) &&
          Array(width > 768 ? 10 : 3)
            .fill({})
            .map((_, i) => <Skeleton height={50} sx={{ width: "100%" }} />)}
      </div>
    </div>
  );
};

export default StoreSideBar;
