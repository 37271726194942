import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoLockClosed } from "react-icons/io5";
import { Formik } from "formik";
import { AuthContext } from "../../context/AuthContext";
import { meta } from "../../globals";
import { loginSchema } from "../../globals/schemas";
import LayoutAuth from "../../components/Layout/LayoutAuth";
import LinkForm from "../../components/forms/LinkForm";
import { useNotifications } from "../../context/NotificationsContext";
import { useMutation } from "react-query";
import { Button, Input } from "../../components/CustomComponents";
import { HiEye, HiEyeOff, HiMail } from "react-icons/hi";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { redirect, isLoading, signedIn, login } = useContext(AuthContext);
  const [checked, setChecked] = useState(false);
  const { pushNotification } = useNotifications();
  const [passwordType, setPasswordType] = useState("password");

  const handleCheck = () => {
    setChecked(!checked);
    checked ? setPasswordType("text") : setPasswordType("password");
  };

  const loginMutation = useMutation(
    "login",
    async ({ email, password }) => {
      const res = await axios.post("/auth/login", { email, password });
      return res.data;
    },
    {
      onSuccess: (data) => {
        login(data);
      },
      onError: (error) => {
        pushNotification({
          id: Math.random(),
          type: "Error",
          message: error.response.data.error,
        });
      },
    }
  );

  useEffect(() => {
    const { state } = location;

    if (signedIn) {
      if (state?.from) {
        // navigate to the page the user was trying to access before signing in
        navigate(state.from, { replace: true });
      } else {
        // else navigate to the homepage
        navigate(redirect, { replace: true });
      }
    }
  }, [location, navigate, redirect, signedIn]);

  // Hide the login screen when loading.
  if (isLoading) return null;

  return (
    <LayoutAuth title={meta.title} description={meta.description} keywords={meta.keywords}>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={(values) => {
          loginMutation.mutate({
            email: values.email,
            password: values.password,
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <LinkForm authForm={true} onSubmit={handleSubmit}>
            <div className="absolute -top-6 flex items-center justify-center border-b bg-white/60 md:bg-white backdrop-blur-lg p-2 rounded-full">
              <IoLockClosed className="text-3xl rounded-full text-link-green" />
            </div>

            <h1 className="font-opensans-semibold text-2xl text-stone-900">Login</h1>
            <h2 className="font-medium text-sm md:text-lg text-center text-stone-900">
              Get started with Link pharmacy today.
            </h2>

            <div className="w-full px-4 h-fit space-y-2">
              <Input
                name={"email"}
                label={"Email"}
                placeholder={"Email Address"}
                icon={<HiMail className={"text-xl text-link-green"} />}
                error={errors.email}
                touched={touched.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <div className="w-full flex items-center mb-5">
                <Input
                  name={"password"}
                  label={"Password"}
                  placeholder={"Password"}
                  type={passwordType}
                  value={values.password}
                  icon={
                    <label htmlFor="passwordType">
                      {passwordType === "password" ? (
                        <HiEyeOff className="text-xl text-link-green" />
                      ) : (
                        <HiEye className="text-xl text-link-green" />
                      )}
                    </label>
                  }
                  error={errors.password}
                  touched={touched.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <input
                  id="passwordType"
                  type={"checkbox"}
                  value={"type"}
                  onChange={handleCheck}
                  checked={checked}
                  className="sr-only"
                />
              </div>

              <div className="flex flex-row flex-1 justify-between items-center mb-3">
                <Link to="/forgot-password" className="text-green-700 font-opensans text-sm">
                  Forgot Password?
                </Link>

                <Button width={"[110px]"} type="submit">
                  {loginMutation.isLoading ? <Button.Loader label={"Loading..."} /> : "Sign in"}
                </Button>
              </div>

              <div className="w-full font-opensans text-center text-sm">
                Don't have an account?{" "}
                <Link to="/register" className="font-opensans-semibold text-green-700">
                  Register
                </Link>
              </div>
              <div className="font-opensans-semibold w-full text-center">
                <Link to="/" className="text-green-700 text-md">
                  Home
                </Link>
              </div>
            </div>
          </LinkForm>
        )}
      </Formik>
    </LayoutAuth>
  );
};

export default Login;
