const shippingRates = [
  { weight: 0.5, rate: 59.96 },
  { weight: 1.0, rate: 84.59 },
  { weight: 1.5, rate: 93.28 },
  { weight: 2.0, rate: 108.8 },
  { weight: 2.5, rate: 126.54 },
  { weight: 3.0, rate: 142.11 },
  { weight: 3.5, rate: 157.64 },
  { weight: 4.0, rate: 175.4 },
  { weight: 4.5, rate: 190.94 },
  { weight: 5.0, rate: 208.7 },
  { weight: 5.5, rate: 224.24 },
  { weight: 6.0, rate: 239.77 },
  { weight: 6.5, rate: 257.53 },
  { weight: 7.0, rate: 273.1 },
  { weight: 7.5, rate: 290.86 },
  { weight: 8.0, rate: 306.41 },
  { weight: 8.5, rate: 321.93 },
  { weight: 9.0, rate: 337.49 },
  { weight: 9.5, rate: 355.23 },
  { weight: 10.0, rate: 370.79 },
];
export default shippingRates;
