import { Form, Formik } from "formik";
import { IoCard, IoCash, IoCode, IoHome, IoPerson } from "react-icons/io5";
import { continuumSchema } from "../../globals/schemas";
import styles from "../../styles/views/MyHealthInsurance.module.css";
import { FormField } from "../forms";

const Continuum = () => {
  return (
    <Formik
      initialValues={{
        fullname: "",
        employer: "",
        membershipID: "",
        mobileNumber: "",
        residentialAddress: "",
        insuranceCompany: "Continuum",
        patientFullname: "",
        patientContactNumber: "",
        patientAge: "",
        claimForm: "",
      }}
      onSubmit={(values) => console.log(values)}
      validationSchema={continuumSchema}
    >
      {({ isSubmitting, values, handleChange, setFieldValue, errors }) => (
        <Form className="space-y-4 flex flex-col self-center w-full">
          <div className="w-full flex items-center space-x-2 h-11 bg-link-green px-3 rounded-md">
            <span className="bg-stone-900/30 text-white rounded-full w-7 h-7 flex flex-col items-center">
              2
            </span>
            <span>Main Member Details</span>
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4">
            <div className="w-full flex flex-col space-y-4 md:px-12">
              <FormField
                label={"Fullname"}
                type="text"
                name="fullname"
                placeholder="e.g.: John Smith"
                width={"w-full"}
                margin="mb-0"
                icon={<IoPerson className="text-xl ml-2 text-link-green" />}
              />
              <FormField
                label={"Employer"}
                type="text"
                name="employer"
                placeholder="e.g.: Linkpharmacy"
                width={"w-full"}
                margin="mb-0"
                icon={<IoPerson className="text-xl ml-2 text-link-green" />}
              />
            </div>
            <div className="w-full flex flex-col space-y-4 md:px-12">
              <FormField
                label={"Membership No. "}
                type="text"
                name="membershipID"
                placeholder="e.g.: 23453245"
                width={"w-full"}
                margin="mb-0"
                icon={<IoCard className="text-xl ml-2 text-link-green" />}
              />
              <FormField
                label={"Mobile No."}
                type="text"
                name="mobileNumber"
                placeholder="e.g.: 0900 123 456"
                margin="mb-0"
                icon={<IoCash className="text-xl ml-2 text-link-green" />}
              />
            </div>
          </div>
          <div className="space-y-4 w-full flex flex-col md:px-12">
            <FormField
              label={"Residential Address / Area"}
              type="text"
              name="residentialAddress"
              placeholder="e.g.: 122 off Shantumbu road Chalala"
              margin="mb-0"
              icon={<IoHome className="text-xl ml-2 text-link-green" />}
            />
            <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-5">
              <div className="w-full md:w-32 flex flex-1">
                <FormField
                  label={"Patient's Fullname"}
                  type="text"
                  name="patientFullname"
                  placeholder="e.g.: Jane Doe"
                  margin="mb-0"
                  icon={<IoPerson className="text-xl ml-2 text-link-green" />}
                />
              </div>
              <div className="w-full md:w-30 h-14">
                <FormField
                  label={"Contact number"}
                  type="text"
                  name="patientContactNumber"
                  placeholder="e.g.: 0912 345 678"
                  margin="mb-0"
                  icon={<IoHome className="text-xl ml-2 text-link-green" />}
                />
              </div>
              <div className="w-full md:w-24">
                <FormField
                  label={"Age"}
                  type="text"
                  name="patientAge"
                  placeholder="25"
                  margin="mb-0"
                  icon={<IoCode className="text-xl ml-2 text-link-green" />}
                />
              </div>
            </div>
          </div>

          <div className="w-full mt-5 flex items-center space-x-2 h-fit bg-link-green px-3 rounded-md">
            <span className="bg-stone-900/30 text-white rounded-full w-7 h-7 flex flex-grow items-center">
              3
            </span>
            <span className="flex flex-col flex-grow-0">
              {
                "Medical Services: (Consultations, Pathology, Radiology, Medicine, Auxiliary Services, etc.)"
              }
            </span>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={"mt-4"}>
                <div>
                  <h5 className={`font-weight-bold ${styles.label}`}>
                    Upload Copy of Claim Form
                  </h5>

                  <p>
                    Please ensure Patient's signature, Doctor's signature and
                    Medical Services are clearly visible.
                  </p>

                  <input
                    type="file"
                    name="fullNames"
                    className={"form-control"}
                    onChange={(event) => {
                      setFieldValue("claimForm", event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <input
              type={"submit"}
              value="Submit form"
              className="bg-link-green text-stone-900 rounded-full p-3"
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Continuum;
