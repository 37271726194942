import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/components/SearchBar.module.css";
import { HiOutlineSearch } from "react-icons/hi";

const SearchBar = () => {
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const onSearch = (e) => {
    e.preventDefault();
    if (searchValue) {
      navigate(`/store/search/${searchValue.replace(" ", "-")}`);
      setSearchValue("");
    }
  };

  return (
    <div className={styles.form_container}>
      <div className={styles.search_wrapper}>
        <form onSubmit={onSearch} className="flex flex-row md:ml-[20px] align-center">
          <input
            className={`w-full h-[42px] rounded-l border-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-4 focus:ring-link-green-1 text-sm sm:leading-6`}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            type="text"
            placeholder="What are you looking for?"
          />
          <button
            className={
              "w-[50px] h-[40px] mt-[1px] bg-stone-600 rounded-r text-xl text-white font-bold flex justify-center items-center"
            }
            id={"search-button"}
            aria-label="Search Button"
          >
            <HiOutlineSearch />
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchBar;
