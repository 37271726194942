import React, { useState } from "react";
import styles from "../../styles/views/PurchaseHistoryOrderDetail.module.css";
import TransitionUpDialog from "../../components/TransitionUpDialog";
import { ClassNameMap, makeStyles } from "@mui/styles";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import axios from "axios";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getProductOrderApi } from "../../api/orders";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import Box from "@mui/material/Box";
import { meta } from "../../globals";
import { ImageNotSupported } from "@mui/icons-material";
import Layout from "../../components/Layout/Layout";
import BreadCrumbs from "../../components/BreadCrumbs";
import { styled } from "@mui/material/styles";
import { StepConnector, stepConnectorClasses, StepIconProps } from "@mui/material";
import { CallReceivedTwoTone, CheckTwoTone, DeliveryDiningTwoTone } from "@mui/icons-material";
import { Select, TextArea } from "../../components/CustomComponents";
import { HiMinus, HiPlus } from "react-icons/hi";
import { classNames } from "../../utils/utils";
import LinkLoader from "../../components/LinkLoader";

const ColorLibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient(to left top, #15d6cd, #1fdcaf, #59de84, #8edd52, #c4d600)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient(to left top, #15d6cd, #1fdcaf, #59de84, #8edd52, #c4d600)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorLibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }: any) => ({
  // @ts-ignore
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient(to left top, #15d6cd, #1fdcaf, #59de84, #8edd52, #c4d600)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient(to left top, #15d6cd, #1fdcaf, #59de84, #8edd52, #c4d600)",
  }),
}));

function ColorLibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <CallReceivedTwoTone />,
    2: <DeliveryDiningTwoTone />,
    3: <CheckTwoTone />,
  };

  return (
    // @ts-ignore
    <ColorLibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorLibStepIconRoot>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
    padding: "3px",
    "&:hover": {
      borderRadius: "100px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      color: "rgba(0, 0, 0, 0.5)",
    },
  },
  borderless: {
    "& td": {
      border: "none",
    },
    "& th": {
      border: "none",
    },
  },

  input: {
    width: "25em",
  },

  quantityMutators: {
    cursor: "pointer",
    padding: "3px",
    margin: "0",
    "&:hover": {
      borderRadius: "100px",
      backgroundColor: "#c4d600",
      color: "rgba(0, 0, 0, 0.5)",
    },
  },

  quantityContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #c4d600",
    margin: "10px 0px",
    borderRadius: "15px",
    padding: "5px",
    "& div": {
      "& span": {
        marginBottom: "-2px",
      },
      display: "flex",
      padding: "0 20px",
    },
  },

  notAllowed: {
    cursor: "not-allowed",
    padding: "3px",
    margin: "0",
    "&:hover": {
      borderRadius: "100px",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      color: "rgba(0, 0, 0, 0.5)",
    },
  },
}));

function ReturnDialog(props: {
  visible: boolean;
  onClose: () => void;
  loading: boolean;
  onSave: () => void;
  selectedOrder: any;
  classes: ClassNameMap<string>;
  onClick: () => void;
  quantity: number;
  onClick1: () => void;
  value: number;
  onChange: (e) => void;
  o: { "1": string; "2": string; "3": string; "4": string; "5": string; "6": string; "7": string };
  callbackfn: (pair) => JSX.Element;
  value1: string;
  onChange1: (e) => void;
}) {
  const reasons = [
    "Incorrect Product Ordered",
    "Incorrect Size Ordered",
    "Product Does Not Match Description On The Website",
    "Product Does Not Meet Expectations",
    "Incorrect Item Received",
    "Product Damaged On Arrival",
    "Other Specify",
  ];

  return (
    <TransitionUpDialog
      visible={props.visible}
      title="Return Item"
      onClose={props.onClose}
      closeText="Cancel"
      loading={props.loading}
      saveText="Submit"
      onSave={props.onSave}
    >
      <div style={{ textAlign: "center", marginBottom: "30px", fontFamily: "Quicksand" }}>
        {props.selectedOrder && props.selectedOrder.attributes.item && (
          <>
            <div className="w-full h-[300px] mb-2 p-10">
              <img
                className="w-full h-full object-contain"
                src={props.selectedOrder.attributes.item.thumbnail}
                alt={props.selectedOrder.attributes.item.name}
              />
            </div>

            <div className="custom-number-input w-full">
              <label
                htmlFor="custom-input-number"
                className="block text-sm font-opensans-medium leading-6 text-gray-800 mb-1"
              >
                Quantity
              </label>
              <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                <button
                  data-action="decrement"
                  className={classNames(
                    "bg-stone-100 d-flex items-center justify-center text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none",
                    props.quantity === 1 && "opacity-50 cursor-not-allowed"
                  )}
                  onClick={props.onClick}
                >
                  <HiMinus />
                </button>
                <input
                  type="number"
                  className="border-0 focus:ring-0 text-center w-full bg-stone-100 font-opensans-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700"
                  name="custom-input-number"
                  value={props.quantity}
                  readOnly
                  aria-readonly={true}
                />
                <button
                  data-action="increment"
                  className={classNames(
                    "bg-stone-100 d-flex items-center justify-center text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer",
                    props.quantity === props.selectedOrder.attributes.quantity && "opacity-50 cursor-not-allowed"
                  )}
                  onClick={props.onClick1}
                >
                  <HiPlus />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <div style={{ marginBottom: "18px" }}>
        <Select
          label={"Select Reason"}
          options={reasons.map((reason, index) => ({ value: index + 1, label: reason }))}
          onChange={(value) => {
            props.onChange(value);
          }}
          value={props.value}
        />
      </div>
      <div>
        <TextArea
          label={"Additional Information"}
          placeholder={"Tell us more about the problem..."}
          rows={7}
          onChange={props.onChange1}
          value={props.value1}
        />
      </div>
    </TransitionUpDialog>
  );
}

const PurchaseHistoryOrderDetail = ({ location }) => {
  const classes = useStyles();

  const { id } = useParams();
  const navigate = useNavigate();

  const { data: order, isLoading } = useQuery(["orders", { id }], getProductOrderApi, {
    onError: (error) => {
      navigate("/errror404" + location.pathname, { replace: true });
    },
  });

  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [quantity, setQuantity] = useState(1);

  const [returned, setReturned] = useState(-1);

  const [dialogVisible, setDialogVisible] = useState(false);

  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [returning, setReturning] = useState(false);

  const reasons = {
    1: "Incorrect Product Ordered",
    2: "Incorrect Size Ordered",
    3: "Product Does Not Match Description On The Website",
    4: "Product Does Not Meet Expectations",
    5: "Incorrect Item Received",
    6: "Product Damaged On Arrival",
    7: "Other Specify",
  };

  const steps = [
    "Order Received",
    order?.data?.attributes?.delivery_method === "deliver" ? "Order Dispatched" : "Ready For Pickup",
    order?.data?.attributes?.delivery_method === "deliver" ? "Delivered" : "Picked Up",
  ];

  const [reason, setReason] = useState(1);
  const [comment, setComment] = useState("");

  const onReturn = () => {
    setModified(false);
    setLoading(true);
    setReturning(true);

    const data = {
      reason: reasons[reason],
      comment: comment,
      quantity: quantity,
      order_detail_id: selectedOrder.id,
    };

    axios
      .post("v1/website/item-returns", data)
      .then((data) => {
        setReturned(1);
        setReturning(false);

        order.data.attributes.details = order.data.attributes.details.map((detail) => {
          if (detail.id === selectedOrder.id) {
            detail.attributes.returned = true;
            detail.attributes.return_status = "requested";
          }
          return detail;
        });

        setModified(true);
        setTimeout(() => setReturned(0), 5000);
        setSelectedOrder(null);
      })
      .catch((err) => {
        setReturned(0);
      });

    setQuantity(1);
    setReason(1);
    setComment("");
    setLoading(false);
    setDialogVisible(false);
  };

  const onCreateReturn = (detail) => {
    setQuantity(1);
    setSelectedOrder(detail);
    setDialogVisible(true);
  };

  const countTotalPrice = (details) => {
    if (details.length === 0) return 0;
    if (details.length === 1) return details[0].attributes.quantity * details[0].attributes.price;
    return details.reduce((total, item) => item.attributes.quantity * item.attributes.price + total, 0);
  };

  const onIncrement = () => {
    if (quantity < selectedOrder.attributes.quantity) setQuantity((prevState) => ++prevState);
  };

  const onDecrement = () => {
    if (quantity > 1) setQuantity((prevState) => --prevState);
  };

  const getActiveStep = (status: string) => {
    switch (status) {
      case "Received":
        return 0;
      case "Dispatched":
        return 1;
      case "Delivered":
        return 2;
      case "Picked":
        return 2;
      case "Ready":
        return 1;
    }
  };

  const handleOnReasonChange = (value: string) => {
    setReason(Number(value));
  };

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div
        style={{ height: "100%" }}
        className={`container-lg container-md container-sm container p-4 ${styles.purchaseHistoryOrderDetail}`}
      >
        {order && order.data && (
          <>
            <div>
              <BreadCrumbs
                crumbs={[
                  { name: "Home", link: "/" },
                  { name: "Account", link: "/account" },
                  { name: "My Orders", link: "/account?t=my-orders" },
                  { name: `Order ${id}`, link: "/account-home?t=my-orders" },
                ]}
              />
              <h3 className="text-2xl font-opensans-medium mt-[-10px] mb-3">Order Details</h3>
            </div>

            <div className={"my-10"}>
              <Box sx={{ width: "100%" }}>
                <Stepper
                  alternativeLabel
                  activeStep={getActiveStep(order.data.attributes.status)}
                  connector={<ColorLibConnector />}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={ColorLibStepIcon}
                        sx={{
                          // font family
                          "& .MuiStepLabel-label": {
                            fontFamily: "Open Sans SemiBold",
                            fontSize: "14px",
                            color: "#6B6B6B",
                          },
                          // mobile font size
                          "@media (max-width: 600px)": {
                            "& .MuiStepLabel-label": {
                              fontSize: "12px",
                            },
                          },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>

            <div>
              <div className={"card-header"}>
                <p className={`font-opensans`}>Order # : {order.data.attributes.order_number}</p>
              </div>
              <div className={"card-body"}>
                <div className={"row mt-3 mb-3"}>
                  <div className={"col-md-5 col-lg-4 mt-md-0 mt-4"}>
                    <h5 className={"font-opensans-semibold text-dark mb-3"}>Customer Information</h5>

                    <div className="mb-3">
                      <p className={"font-opensans-semibold text-dark text-base"}>Name:</p>
                      <p className="font-opensans text-lg">
                        {order.data.attributes.user.first_name + " " + order.data.attributes.user.last_name}
                      </p>
                    </div>

                    <div className="mb-3">
                      <p className={"font-opensans-semibold text-dark text-base"}>Mobile #:</p>
                      <p className="font-opensans text-lg">
                        {order.data.attributes.contact
                          ? order.data.attributes.contact.phone_number
                          : order.data.attributes.user.phone_number}
                      </p>
                    </div>

                    <div className="mb-3">
                      <p className={"font-opensans-semibold text-dark text-base"}>Email:</p>
                      <p className="font-opensans text-lg">
                        {order.data.attributes.contact
                          ? order.data.attributes.contact.email
                          : order.data.attributes.user.email}
                      </p>
                    </div>
                  </div>
                  <div className={"col-md-3 col-lg-4"}>
                    <h5 className={"font-opensans-semibold text-dark mb-3"}>Order Management</h5>
                    <div className="mb-3">
                      <p className={"font-opensans-semibold text-dark text-base"}>Date:</p>
                      <p className="font-opensans text-lg">
                        {new Date(order.data.attributes.date).toLocaleDateString()}
                      </p>
                    </div>

                    <div className="mb-3">
                      <p className={"font-opensans-semibold text-dark text-base"}>Delivery Method:</p>
                      <p className="font-opensans text-lg" style={{ textTransform: "capitalize" }}>
                        {order.data.attributes.delivery_method}
                      </p>
                    </div>

                    <div className="mb-3">
                      <p className={"font-opensans-semibold text-dark text-base"}>Payment Method:</p>
                      <p className="font-opensans text-lg">{order.data.attributes.payment_method}</p>
                    </div>

                    <div className="mb-3">
                      <p className="font-opensans-semibold text-dark text-base">Status:</p>
                      <p className="font-opensans text-lg">{order.data.attributes.status}</p>
                    </div>
                  </div>

                  {order.data.attributes.delivery_method === "deliver" ? (
                    <div className={"col-md-4 col-lg-4"}>
                      <h5 className={"font-opensans-semibold text-dark mb-3"}>Delivery Address</h5>
                      <div className="mb-3">
                        <p className={"font-opensans-semibold text-dark text-base"}>Address Line 1:</p>
                        <p className="font-opensans text-lg">{order?.data?.address?.attributes?.address1}</p>
                      </div>

                      {order?.data?.address?.attributes?.address2 && (
                        <div className="mb-3">
                          <p className={"font-opensans-semibold text-dark text-base"} style={{ fontWeight: "600" }}>
                            Address Line 2:
                          </p>
                          <p className="font-opensans text-lg">{order?.data?.address?.attributes?.address2}</p>
                        </div>
                      )}

                      <div className="mb-3">
                        <p className={"font-opensans-semibold text-dark text-base"}>City:</p>
                        <p className="font-opensans text-lg">{order?.data?.address?.attributes?.city}</p>
                      </div>

                      <div className="mb-3">
                        <p className={"font-opensans-semibold text-dark text-base"}>Residential Area:</p>
                        <p className="font-opensans text-lg">{order?.data?.address?.attributes?.residential_area}</p>
                      </div>

                      <div className="mb-3">
                        <p className={"font-opensans-semibold text-dark text-base"}>Postal Code:</p>
                        <p className="font-opensans text-lg">{order?.data?.address?.attributes?.postal_code}</p>
                      </div>
                    </div>
                  ) : (
                    <div className={"col-md-4 col-lg-4"}>
                      <h5 className={"font-opensans-semibold text-dark mb-3"}>Pickup Location</h5>
                      <div className="mb-3">
                        <p className={"font-opensans-semibold text-dark text-base"}>Branch Name:</p>
                        <p className="font-opensans text-lg">{order?.data?.pickup_store?.attributes?.name}</p>
                      </div>

                      <div className="mb-3">
                        <p className={"font-opensans-semibold text-dark text-base"} style={{ fontWeight: "600" }}>
                          Address:
                        </p>
                        <p className="font-opensans text-lg">{order?.data?.pickup_store?.attributes?.address}</p>
                      </div>

                      <div className="mb-3">
                        <p className={"font-opensans-semibold text-dark text-base"}>City:</p>
                        <p className="font-opensans text-lg">{order?.data?.pickup_store?.attributes?.city}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {returned === 1 && (
              <div className="alert alert-success text-center rounded">
                Return request sent successfully. Thanks for contacting us, we will get back to you shortly.
              </div>
            )}

            {returned === 0 && (
              <div className="alert alert-danger text-center rounded">
                An error occurred while sending your return request. Try again!
              </div>
            )}

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-stone-100 rounded-tl rounded-tr">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      <p className="text-sm font-opensans-bold">Item Image</p>
                    </th>
                    <th scope="col" className="px-6 py-3">
                      <p className="text-sm font-opensans-bold">Item Name</p>
                    </th>
                    <th scope="col" className="px-6 py-3">
                      <p className="text-sm font-opensans-bold">Quantity</p>
                    </th>
                    <th scope="col" className="px-6 py-3">
                      <p className="text-sm font-opensans-bold">Unit Price</p>
                    </th>
                    <th scope="col" className="px-6 py-3">
                      <p className="text-sm font-opensans-bold">Total Price</p>
                    </th>
                    <th scope="col" className="px-6 py-3">
                      <p className="text-sm font-opensans-bold">Return</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(modified || order.data.attributes.details) &&
                    order.data.attributes.details.map((detail) => (
                      <tr className="bg-white border-b" key={detail.id}>
                        <td className="px-6 py-2">
                          <div className="bg-stone-50 p-1 w-[50px] h-[50px] rounded overflow-hidden border-gray-200 border-1 d-flex items-center justify-center">
                            {detail.attributes.item.thumbnail ? (
                              <img
                                className="w-full h-full object-contain"
                                src={detail.attributes.item.thumbnail}
                                alt={detail.attributes.item.name}
                              />
                            ) : (
                              <ImageNotSupported />
                            )}
                          </div>
                        </td>

                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
                          <p className="text-sm font-opensans">{detail.attributes.item.name}</p>
                        </th>
                        <td className="px-6 py-2">
                          <p className="text-sm font-opensans">{detail.attributes.quantity}</p>
                        </td>
                        <td className="px-6 py-2">
                          <p className="text-sm font-opensans">
                            K{" "}
                            {detail.attributes.price.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </p>
                        </td>
                        <td className="px-6 py-2">
                          <p className="text-sm font-opensans">
                            K{" "}
                            {(detail.attributes.quantity * detail.attributes.price).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </p>
                        </td>
                        <td className="px-6 py-2">
                          <span title="return item">
                            {returning && detail.id === selectedOrder.id && (
                              <div
                                style={{
                                  fontSize: "small",
                                  width: "max-content",
                                  backgroundColor: "rgba(0, 255, 0, 0.3)",
                                  padding: "5px",
                                }}
                              >
                                Requesting...
                              </div>
                            )}

                            {detail.attributes.returned && !(returning && detail.id === selectedOrder.id) && (
                              <div
                                style={{
                                  fontSize: "small",
                                  width: "max-content",
                                  backgroundColor: "rgba(255, 0, 0, 0.3)",
                                  padding: "5px",
                                }}
                              >
                                {detail.attributes.return_status}
                              </div>
                            )}

                            {!detail.attributes.returned && !(returning && detail.id === selectedOrder.id) && (
                              <KeyboardReturnIcon onClick={() => onCreateReturn(detail)} className={classes.icon} />
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                  <tr className="bg-white border-b">
                    <td />
                    <td />
                    <td />
                    <td className="px-6 py-2">
                      <p className="text-sm font-opensans-bold">Total: </p>
                    </td>
                    <td className="px-6 py-2">
                      <p className="text-sm font-opensans-bold">
                        K{" "}
                        {countTotalPrice(order.data.attributes.details).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </p>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}

        {isLoading && (
          <div className="w-full h-full">
            <LinkLoader />
          </div>
        )}

        <ReturnDialog
          visible={dialogVisible}
          onClose={() => setDialogVisible(false)}
          loading={loading}
          onSave={onReturn}
          selectedOrder={selectedOrder}
          classes={classes}
          onClick={onDecrement}
          quantity={quantity}
          onClick1={onIncrement}
          value={reason}
          onChange={handleOnReasonChange}
          o={reasons}
          callbackfn={(pair) => (
            <option value={pair[0]} key={pair[0]}>
              {pair[1]}
            </option>
          )}
          value1={comment}
          onChange1={(e) => setComment(e.target.value)}
        />
      </div>
    </Layout>
  );
};

export default PurchaseHistoryOrderDetail;
