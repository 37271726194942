import React from "react";
import Layout from "../../components/Layout/Layout";
import styles from "../../styles/views/CheckMyMeds.module.css";

const CheckMyMeds = () => {
  return (
    <Layout
      meta={{
        title: "Check My Meds - Medication Reconciliation Service | Link Pharmacy",
        keywords:
          "Check My Meds, medication reconciliation, medication errors, medication safety, medication review, pharmacist service",
        description:
          "Check My Meds is a medication reconciliation service aimed at preventing medication errors such as omissions, duplications, dosing errors, and drug interactions. Our pharmacists review your medications, recommend adjustments, and provide an updated medication list for your healthcare visits.",
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">Check My Meds</h1>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <h3 className="mb-3 font-opensans-semibold">
          Do you feel like you are taking too many medicines and would you like to know in detail what they are for and
          how they work?
        </h3>

        <h3 className="mb-3 font-opensans-semibold">
          Are you experiencing side effects from some of your medication and would you like to know if alternatives are
          available?
        </h3>

        <p className="mb-3">
          Check My Meds is a medicines reconciliation service which aims to assess for medication errors such as
          omissions, duplications, dosing errors or drug interactions.{" "}
        </p>

        <div className="d-flex flex-col items-center my-5 text-center">
          <h2 className="text-2xl md:text-3xl mb-3">Why is it important?</h2>
          <p className="mb-5">
            Medication errors can lead to avoidable illnesses, <br /> hospitalisation and death.
          </p>

          <h2 className="text-2xl md:text-3xl mb-5">How it works</h2>
          <div className="flex justify-center flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-2">
            <div className="flex flex-col items-center md:max-w-[40%]">
              <h3 className="text-xl md:text-2xl mb-3 font-opensans-bold">1</h3>
              <p>
                One of our pharmacists will review your medications, recommend adjustments as needed and answer your
                questions.
              </p>
            </div>
            <div className="flex flex-col items-center md:max-w-[40%]">
              <h3 className="text-xl md:text-2xl mb-3 font-opensans-bold">2</h3>
              <p>
                You will be provided with an updated list for your visits to your doctor, hospital or any healthcare
                professional!
              </p>
            </div>
          </div>
        </div>

        <div>
          <h2 className="font-opensans-semibold text-xl mb-3">Before your Check My Meds appointment</h2>
          <p className="mb-3">
            Gather <span className="font-opensans-bold">ALL</span> your prescription medications, over the counter
            medications, herbal supplements or natural products, vitamins or minerals and any lists of medications and
            vaccinations you have.
          </p>
          <p className="mb-3">Place all the items and information in a bag and take them to your appointment.</p>
        </div>

        <div>
          <h2 className="font-opensans-semibold text-xl mb-3">During your Check My Meds appointment</h2>
          <p className="mb-3">
            Your pharmacist wil ask you about your medical conditions and any symptoms you may be experiencing.
          </p>

          <p className="mb-3">
            They will ask how and when you take each medication and record that on the medication list.
          </p>

          <p className="mb-3">
            Your pharmacist will make sure you are taking the right medication at the right dose and at the right time.
          </p>

          <p className="mb-3">
            They will advise you and/or your caregiver on your medications, how to take them appropriately and how to
            dispose of them properly.
          </p>

          <p className="mb-3">
            Your pharmacist will help you complete an updated medication list to take with you along with recommended
            changes for you to take to your prescriber.
          </p>
        </div>

        <div>
          <h2 className="font-opensans-semibold text-xl mb-3">Is my medication combination safe?</h2>
          <p className="mb-3">
            If you take any medications, including over the counter products there is a chance that your medications may
            interact with each other, with food, or with another medical condition you may have. Check My Meds will help
            solve medication problems and avoid side effects.
          </p>
        </div>

        <div>
          <h2 className="font-opensans-semibold text-xl mb-3">Could my medications be causing my symptoms?</h2>
          <p className="mb-3">
            “Prescribing cascade” is the situation when the side effects of medication are mistaken for the symptoms of
            another condition, resulting in further medications and further side effects. This leads to unanticipated
            medication interactions, which itself may lead to further misdiagnoses and further symptoms. These
            situations may be resolved where our pharmacists evaluate your medications, your symptoms, and look for
            areas to potentially simplify your medication regimen.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default CheckMyMeds;
