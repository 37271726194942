// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/SellYourPharmacy.module.css";
import MoneyIcon from "@mui/icons-material/Money";
import MessageIcon from "@mui/icons-material/Message";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";

const SellYourPharmacy = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={style.s_navbar}>
              <a href="/">Home</a> <span>&#10095;</span>{" "}
              <a href="/pharmacy-services">Pharmacy & Health</a>
            </div>
            <div className={style.s_header}>
              <h1>Pharmacy Acquisitions</h1>
            </div>
            <div className={style.s_stages}>
              <h4>Stages to follow in circle</h4>
              <div className={style.s_flex}>
                <FactCheckIcon /> <p>Seamless information gathering</p>
                <EscalatorWarningIcon /> <p>Seamless business transaction</p>
                <MessageIcon />
                <p>Highly communicative contracting process</p>
                <ListAltIcon /> <p>Data conformation through due deligence</p>
                <MoneyIcon />{" "}
                <p>Non-obligatory offer based on information you provide</p>
                <EqualizerIcon /> <p>Efficient analysis of your business</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className={style.s_interest}>
              <h4>
                Interested in discussing or evaluating a potential exit plan?
              </h4>
              <p>
                It might seem like a daunting activity – to sell the business
                you’ve put time, money and effort into growing. Not to mention,
                reimbursement pressures that continue to oppose the industry,
                making it more difficult than ever to determine the next step.
                Link Pharmacy Pharmacy Acquisitions team is here to help you
                with that.
              </p>
              <p>
                <b>Efficiency:</b> Our acquisition process is designed to get an
                offer into your hands faster than anyone else. It’s a capability
                in which we take incredible pride and is something that
                differentiates us from other prospective buyers. At every step
                of the transition process, we will provide clear direction on
                what’s ahead, assist you in communicating with your customers
                and vendors, and leverage our rock-star acquisition team to make
                the process as seamless as possible for you.
              </p>
              <h3>Where to Start?</h3>
              <ul>
                <li>
                  You aren’t sure if now is the time to explore selling your
                  pharmacies
                </li>
                <li>
                  You’ve decided to sell, but you’re not sure where to start. To
                  whom do you reach out? What information would you need?
                </li>
                <li>
                  How will Link Pharmacy properly transition the care for your
                  patients?
                </li>
              </ul>
              <div className={style.s_access}>
                <p>
                  Access our <b>online</b> questionnaire at the link{" "}
                  <a href="/pharmacy-questionnaire" target="blank">
                    here
                  </a>
                </p>
                <p>
                  For a print copy, download the standard questionnaire{" "}
                  <a href="# target=_blank">here</a>
                </p>
              </div>
              <div className={style.s_conctact}>
                <h3>Contact Our Team</h3>
                <p>
                  <b>Experience:</b> Our team of friendly, experienced and
                  highly-skilled M&A professionals employ the most efficient,
                  value-creating, and user-friendly process in the industry,
                  which enables us to provide an indication of interest very
                  promptly. We understand this is an important decision and want
                  you to be fully informed regarding all possible options,
                  including the potential sale of your pharmacy business, in
                  order to make the best decision for your organization and your
                  patients.
                </p>
                <p>
                  <b>
                    Since 2010, no chain has purchased more pharmacies than Link
                    Pharmacy!*
                  </b>
                </p>
                <br />
                <p className={style.interested}>
                  If you’re interested in discussing your options, here’s how to
                  get ahold of our trained pharmacy M&A professionals...
                </p>
              </div>
            </div>
            <div className="s_lead">
              <p>
                <b>Sean Bauer – Link Pharmacy Acquisitions Lead</b>
              </p>
              <p>Phone: +260 976 255 556</p>
              <p>
                Email:{" "}
                <a href="mailto:customercare@linkpharmacy.co.zm">
                  mailto:customercare@linkpharmacy.co.zm
                </a>
              </p>
              <br />
              <p>
                <b>
                  Please be sure to include the following information in your
                  correspondence:
                </b>
              </p>
              <ol>
                <li>Pharmacy name and address</li>
                <li>Owner's name(s)</li>
                <li>Preferred contact info</li>
                <li>
                  Pharmacy type (i.e. Retail, Health System, Specialty, Etc.)
                </li>
              </ol>
              <p>
                <b>Note:</b> All information obtained during the consideration
                process remains confidential. A Non-Disclosure Agreement is
                available upon request.
              </p>
              <div className={style.s_estate}>
                <p>
                  <b>For all Real Estate related questions, please contact</b>
                </p>
                <p>
                  Email:{" "}
                  <a href="mailto:realestate.inquiries@linkpharmacy.co.zm">
                    realestate.inquiries@linkpharmacy.co.zm
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className={style.s_testmony}>
              <p>
                "I felt that Link Pharmacy had a very patient centered focus.
                From the very beginning, the discussions were framed around
                ensuring customer care and continuity throughout the transition.
                That was the most important factor for both parties and it
                remained intact throughout the entire process. I really
                appreciated that focus and vision as that is what I had built my
                business on."
              </p>
              <br />
              <p>
                <b>Nathan</b>
              </p>
              <p>Former Pharmacy Owner</p>
              <p>Carson City, NV</p>
              <br />
              <p>
                "The whole transaction was well organized and smooth. Link
                Pharmacy informed the customers and so did I, they did end up in
                a better spot, with many more services than I could have ever
                imagined offering. We were very nervous about the process, but
                our M&A agent made it so smooth, that it was unimaginably easy.
                It was a business I had managed for about 10 years, but it was
                seamlessly integrated with the Link Pharmacy system. The process
                involved a very honest representative of the company who really
                did not give any false hope, most things could either be done or
                not. There was no pressure whatsoever to agree to anything,
                which made it an honest transaction."
              </p>
              <p>
                <b>Anil</b>
              </p>
              <p>Pharmacy Seller</p>
              <p>Hamlin, WV</p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SellYourPharmacy;
