import { useRef, useState } from "react";
import styles from "../../styles/views/PrescriptionMedication.module.css";
import { useNavigate } from "react-router-dom";
import prescriptionImage from "../../assets/images/prescription.png";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import FormUtils from "../../utils/utils";
import { useQueryClient, useMutation } from "react-query";
import axios from "axios";
import { useNotifications } from "../../context/NotificationsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import { MdClear } from "react-icons/md";
import BreadCrumbs from "../../components/BreadCrumbs";
import TextArea from "../../components/CustomComponents/components/textArea";

const PrescriptionMedication = () => {
  const [imgSrc, setImgSrc] = useState("");
  const uploadedFile = useRef(null);
  const [file, setFile] = useState("");
  const [additoinalInfo, setAdditoinalInfo] = useState("");

  const [isImage, setIsImage] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [error, setError] = useState("");

  const [disabled, setDisabled] = useState(true);

  const { pushNotification } = useNotifications();
  const navigate = useNavigate();

  const onFileSelected = (event) => {
    setImgSrc("");
    setIsPdf(false);
    setIsImage(false);
    setError("");
    setDisabled(true);

    if (event.target.files && event.target.files[0]) {
      if (FormUtils.isValidImage(event.target.files[0].name) || /\.pdf$/.test(event.target.files[0].name)) {
        const maxSize = 2048 * 1024; /** 2MB **/

        if (event.target.files[0].size <= maxSize) {
          let reader = new FileReader();

          reader.onload = (e) => {
            setImgSrc(e.target.result);
            setFile(event.target.files[0]);
            setIsImage(FormUtils.isValidImage(event.target.files[0].name));
            setIsPdf(/\.pdf$/.test(event.target.files[0].name));
          };

          setDisabled(false);
          reader.readAsDataURL(event.target.files[0]);
        } else {
          setError("Uploaded file size exceeds the allowable limit.");

          setTimeout(() => {
            setError("");
          }, 6000);
        }
      } else {
        setError("Please select a valid file type, image or pdf.");
        setTimeout(() => {
          setError("");
        }, 6000);
      }
    }
  };

  const onClosePreview = (event) => {
    setImgSrc("");
    setIsPdf(false);
    setIsImage(false);
    setDisabled(true);
    setFile("");
    setAdditoinalInfo("");
    uploadedFile.current.value = "";
  };

  // Uploading the prescription using mutation
  const queryClient = useQueryClient();
  const prescription_upload = useMutation(
    "prescription_upload",
    (data) => {
      axios.post("/v1/website/upload-prescription", data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("prescription_upload");

        pushNotification({
          id: Math.random(),
          type: "Success",
          message: "Prescription Uploaded Successfully, You will be redirected shortly",
        });

        setTimeout(() => {
          navigate("/account?t=prescription-history", {
            replace: true,
          });
        }, 5000);
      },
    },
  );

  // Calling the mutation
  const onUploadPrescription = () => {
    /* if upload is successful, close preview */
    onClosePreview();
    let formData = new FormData();
    formData.append("prescription", file);
    formData.append("additional_info", additoinalInfo);
    formData.append("host", window.location.hostname);
    prescription_upload.mutate(formData);
    /* if upload is successful, display success message */

    /* if upload failed, display error message */
  };

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
      bgColor={"#f8f8f8"}
    >
      <div className={`container-lg container-md container-sm container p-4 mt-4 ${styles.prescriptionMedication}`}>
        <BreadCrumbs
          crumbs={[
            { name: "Home", link: "/" },
            { name: `Your Account`, link: "/account" },
            { name: `Upload Prescription`, link: "/#" },
          ]}
        />

        <div className={""}>
          <div className={`col-md-12 text-center mr-md-3 mb-4 ${styles.card}`}>
            {error && (
              <div className={"alert-danger rounded mb-2 p-2"}>
                <p className={"p-2"} style={{ marginBottom: "-10px" }}>
                  <i className="fa fa-exclamation-triangle fa-2x" aria-hidden="true" />
                </p>
                <p>{error}</p>
              </div>
            )}

            {(isPdf || isImage) && (
              <div className={`mb-4 ${styles.preview}`}>
                <div className={styles.close} onClick={onClosePreview}>
                  <MdClear />
                </div>

                {isImage && <img alt="" src={imgSrc} className={styles.previewImage} id={"preview-image"} />}

                {isPdf && (
                  <div
                    style={{
                      display: "table",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Document file={imgSrc} className={styles.pdfViewer}>
                      <Page pageNumber={1} />
                    </Document>
                  </div>
                )}
              </div>
            )}

            {!imgSrc && (
              <div className={`mb-3`}>
                <img
                  alt=""
                  src={prescriptionImage}
                  className={"rounded-circle"}
                  style={{
                    objectFit: "fit",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    padding: "13px",
                    height: "100px",
                    display: "inline",
                  }}
                />
              </div>
            )}
            <div>
              <h6 className={"text-dark mb-7"}>Upload Your Prescription</h6>

              {imgSrc && (
                <div className="mb-4">
                  <TextArea
                    label={"Additional Information"}
                    placeholder={"Enter any additional information you would like to share with your pharmacist."}
                    value={additoinalInfo}
                    onChange={(e) => setAdditoinalInfo(e.target.value)}
                  />
                </div>
              )}

              <div className={"row pl-3 pr-3 align-items-center"}>
                {!file && (
                  <p className="col-md-12 mb-3">
                    <label htmlFor="file-upload" className={styles.uploadButton + " " + styles.roundedAll}>
                      <i className="fa fa-cloud-upload" /> Select File
                    </label>
                  </p>
                )}
                <input
                  id="file-upload"
                  type="file"
                  className={styles.uploadInput}
                  ref={uploadedFile}
                  onChange={onFileSelected}
                />

                <span className={"col-md-12 mb-0 " + styles.proceedContainer}>
                  <span
                    onClick={onUploadPrescription}
                    className={`${styles.proceedButton} ${disabled ? styles.disabled : ""} ${
                      file ? styles.roundedLeft : styles.roundedAll
                    }`}
                  >
                    Proceed
                  </span>
                  {file && (
                    <p className={styles.fileUploadSecondary}>
                      <label htmlFor="file-upload">
                        <FontAwesomeIcon icon={faCloudUploadAlt} /> Choose New File
                      </label>
                    </p>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className={`col-md-12 ${styles.card}`}>
            <div>
              <h1 className="font-opensans-bold text-base">Upload Guide</h1>
              <p className={" mb-3 font-opensans text-sm text-gray-600"}>
                Ensure the following details are clearly visible:
              </p>

              <ul className={`${styles.guides} font-opensans text-sm`}>
                <li className={" mb-3"}>Name of Hospital / Clinic.</li>
                <li className={" mb-3"}>Date Prescribed.</li>
                <li className={" mb-3"}>Name of patient, date of birth, age, sex.</li>
                <li className={" mb-3"}>Patient's Address.</li>
                <li className={" mb-3"}>Medication name, strength, dosage, frequency & formulation of preparation</li>
                <li className={" mb-3"}>Quantity to be dispensed.</li>
                <li className={" mb-3"}>Doctor's name &amp; signature.</li>
                <li className={" mb-3"}>Doctor's HPCZ registration number</li>
                <li className={" mb-3"}>Date Stamp.</li>
                <li className={" mb-3"}>
                  <ul style={{ paddingLeft: "2rem", listStyleType: "circle" }}>
                    <li className={" mb-3"}>Avoid blurred images.</li>
                    <li className={" mb-3"}>Avoid cropping images.</li>
                    <li className={" mb-3"}>Supported file types: jpeg, jpg, png or pdf.</li>
                    <li className={" mb-3"}>Maximum allowed file size: 2MB</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrescriptionMedication;
