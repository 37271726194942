import React from "react";
import styles from "../styles/InsuranceCoverage.module.css";
import { carouselResponsiveness, insuranceCards, meta } from "../../globals";
import Carousel from "react-multi-carousel";
import Layout from "../../components/Layout/Layout";

const Insurance = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">Insurance Coverage</h1>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <h2 className="mb-3 font-opensans-bold text-lg">Are you covered by Health Insurance?</h2>
        <p className="mb-3">We accept the following insurance companies at our mediclinic.</p>

        <div className="my-2">
          <Carousel
            responsive={carouselResponsiveness}
            infinite={false}
            autoPlay={false}
            showDots={false}
            swipeable={true}
            itemClass="md:pr-3"
          >
            {insuranceCards.map((insurance, index) => (
              <a key={index} href={insurance.url ? insurance.url : "/#"} target="_blank" rel="noreferrer">
                <div className="flex flex-col items-center justify-center w-full h-44 p-3 border-stone-200 border-1 rounded-sm">
                  <img
                    src={insurance.img}
                    alt={insurance.imgAlt}
                    className="flex flex-col items-center justify-center object-contain w-fit h-36"
                  />
                </div>
              </a>
            ))}
          </Carousel>
        </div>
      </div>
    </Layout>
  );
};

export default Insurance;
