import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "../../context/cartContext";
import AddToCart from "../AddToCart";
import { classNames } from "../../utils/utils";
import { IoCloseCircleOutline, IoImage } from "react-icons/io5";
import { Button } from "../CustomComponents";

const Product = ({ product, productType, savedForLater }) => {
  const { removeSavedItem, moveToCart, moveLoading } = useCart();
  return (
    <div
      className={classNames(
        "rounded-md p-2 flex flex-col relative border border-stone-200 bg-white cursor-pointer",
        productType === "similar-products" ? "hover:shadow-lg mr-2 h-[370px]" : "w-full",
        productType !== "products" && "h-[400px]",
        productType !== "recently-viewed" && "h-full"
      )}
      role="button"
      tabIndex="0"
      aria-label="Product container"
    >
      {savedForLater && productType !== "recently-viewed" ? (
        <IoCloseCircleOutline
          className="text-base"
          onClick={() =>
            removeSavedItem({
              id: product.id,
              name: product.attributes.name,
              quantity: product.attributes.quantity,
              price: product.attributes.amount,
              thumbnail: product?.thumbnail,
            })
          }
          aria-label="Remove Saved Item"
        />
      ) : null}
      <Link to={`/store/product/${product.id}`} aria-label="Product Details">
        <div className="w-full h-[250px] flex flex-col justify-center items-center p-2.5 border-b border-stone-200 mb-3">
          {product?.attributes?.thumbnail || product?.thumbnail ? (
            <img
              src={product?.attributes?.thumbnail || product?.thumbnail}
              alt={`${product?.attributes?.name || product?.name}`}
              className="w-fit h-full object-contain"
            />
          ) : (
            <IoImage className="text-8xl text-stone-300" aria-hidden="true" />
          )}
        </div>
      </Link>
      <div className="flex flex-col h-full">
        {productType === "products" && (
          <>
            <div className="font-opensans flex flex-col">
              <p className="m-0 text-[green] text-xs mb-0.5 mt-1 font-opensans-medium">
                {product.attributes.brand === "N\\A" ? (
                  <span className="text-sm">&nbsp;</span>
                ) : (
                  <span className="text-sm">{product.attributes.brand}</span>
                )}
              </p>
              <p className="font-opensans-semibold text-base m-0 h-12 text-stone-600">
                {product.attributes.name.substring(0, 35)}
                {product.attributes.name.length > 35 ? "..." : ""}
              </p>
            </div>

            <div className="flex justify-between items-center pt-2 mt-2 border-t border-stone-200">
              {savedForLater ? (
                <Button
                  disabled={moveLoading.includes(product.id)}
                  onClick={() =>
                    moveToCart({
                      id: product.id,
                      name: product.attributes.name,
                      quantity: 1,
                      price: product.attributes.price,
                      thumbnail: product.attributes.thumbnail,
                      quantityOnHand: product.attributes.quantity_in_stock,
                    })
                  }
                >
                  {moveLoading.includes(product.id) ? <Button.Loader label="Loading..." /> : "Move To Cart"}
                </Button>
              ) : product?.category?.name === "Dispensary" || product?.category?.name === "Supervised Sales" ? (
                <Button href="/upload-prescription">Upload Rx</Button>
              ) : (
                <AddToCart
                  rounded={false}
                  cart={{
                    id: product.id,
                    name: product.attributes.name,
                    quantity: 1,
                    price: product.attributes.price,
                    thumbnail: product.attributes.thumbnail,
                    quantityOnHand: product.attributes.quantity_in_stock,
                  }}
                />
              )}

              <div>
                <p className="font-opensans text-lg m-0 text-stone-700">
                  K
                  <span className="font-opensans-extrabold">
                    {Number(product.attributes.price).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </span>
                </p>
              </div>
            </div>
          </>
        )}

        {productType === "recently-viewed" && (
          <h4 className="text-stone-900 font-opensans">
            {product.name && product.name.substring(0, 35)} {product.name && product.name.length > 35 ? "..." : ""}
          </h4>
        )}

        {productType === "similar-products" && (
          <div className="flex flex-col h-full">
            <div className="border-b border-stone-200 pb-2 flex flex-col  h-full">
              <h4 className="text-sm text-green-700 font-opensans">
                {product.attributes.name && product.attributes.name.substring(0, 35)}{" "}
                {product.attributes.name && product.attributes.name.length > 35 ? "..." : ""}
              </h4>
            </div>

            <div className="w-full font-opensans text-stone-800 py-2 text-md flex justify-between items-center">
              {product?.category?.name === "Dispensary" || product?.category?.name === "Category" ? (
                <div className="mt-2">
                  <Button href="/upload-prescription">Upload Rx</Button>
                </div>
              ) : (
                <AddToCart
                  rounded={false}
                  cart={{
                    id: product.id,
                    name: product.attributes.name,
                    quantity: 1,
                    price: product.attributes.price,
                    thumbnail: product.attributes.thumbnail,
                    quantityOnHand: product.attributes.quantity_in_stock,
                  }}
                />
              )}

              <h5>
                K
                <span className="font-opensans-extrabold">
                  {Number(product.attributes.price).toLocaleString("en-GB", {
                    minimumFractionDigits: 2,
                  })}
                </span>
              </h5>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Product;
