import style from "../styles/views/JobSearchForm.module.css";

const JobSearchForm = () => {
  return (
    <div>
      <div className={style.form_cont}>
        <div className={style.job_search_header}>
          <h3>Job search</h3>
        </div>
        <form>
          <div className={style.job_form_flex}>
            <label htmlFor="keyword">Keyword</label>
            <input
              type="text"
              placeholder="Search our opportunities"
              id="keyword"
            />
          </div>
          <div className={style.job_form_flex}>
            <label htmlFor="location">Location</label>
            <input type="text" placeholder="Location" id="location" />
          </div>
          <div className={style.job_form_flex}>
            <label htmlFor="radius">Raduis</label>
            <select name="" id="radius">
              <option value="5mile">5 miles</option>
              <option value="10mile">10 miles</option>
              <option value="15mile">15 miles</option>
              <option value="20mile">20 miles</option>
              <option value="25mile">25 miles</option>
            </select>
          </div>
          <div>
            <input type="submit" value="Search" />
          </div>
        </form>
      </div>
    </div>
  );
};
export default JobSearchForm;
