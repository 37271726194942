import { Form, Formik } from "formik";
import { FaAddressCard } from "react-icons/fa";
import { IoCalendarNumber, IoCode, IoPerson } from "react-icons/io5";
import { mshSchema } from "../../globals/schemas";
import styles from "../../styles/views/MyHealthInsurance.module.css";
import { FormField } from "../forms";

const MSH = () => {
  return (
    <Formik
      initialValues={{
        fullname: "",
        dob: "",
        employer: "",
        mshID: "",
        providerFullname: "",
        address: "",
        claimForm: "",
        insuranceCompany: "MSH",
      }}
      onSubmit={(values) => console.log(values)}
      validationSchema={mshSchema}
    >
      {({ isSubmitting, values, handleChange, setFieldValue, errors }) => (
        <Form className="space-y-4 flex flex-col self-center w-full">
          <div className={styles.stepCounter}>
            <div>2</div> Main Member Details
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Fullnames"}
              type="text"
              name="fullname"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Date of Birth"}
              type="date"
              name="dob"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCalendarNumber className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Employer"}
              type="text"
              name="employer"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"MSH International ID"}
              type="text"
              name="mshID"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className={styles.stepCounter}>
            <div>3</div> Service Provider Details
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Fullname"}
              type="text"
              name="providerFullname"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Address"}
              type="text"
              name="address"
              width={"w-full"}
              margin="mb-0"
              icon={<FaAddressCard className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className={styles.stepCounter}>
            <div>4</div> Claim Details
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={"mt-4"}>
                <div>
                  <h5 className={`font-weight-bold ${styles.label}`}>Upload Copy of Claim Form</h5>

                  <p>Please ensure Patient's signature, Doctor's signature and Medical Services are clearly visible.</p>

                  <input
                    type="file"
                    name="fullNames"
                    className={"form-control"}
                    onChange={(event) => {
                      setFieldValue("claimForm", event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <input
              type={"submit"}
              value="Submit form"
              className="bg-link-green text-stone-900 rounded-full p-3"
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MSH;
