/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { carouselResponsiveness } from "../../globals";
import Product from "./Product";

const RecentlyViewed = ({ id }) => {
  const [recentProducts, setRecentProducts] = useState([]);

  const removeRecentProduct = (product_id) => {
    let newRecentProducts = [];
    recentProducts.map((recentProduct) => {
      if (Number(recentProduct.id) !== Number(product_id)) {
        newRecentProducts.push(recentProduct);
      }
    });

    localStorage.removeItem("linkpharmacy-recent-products");
    setRecentProducts(newRecentProducts);
    localStorage.setItem("linkpharmacy-recent-products", JSON.stringify(newRecentProducts));
  };

  useEffect(() => {
    if (localStorage.getItem("linkpharmacy-recent-products")) {
      setRecentProducts(JSON.parse(localStorage.getItem("linkpharmacy-recent-products")));
    }
  }, []);

  return (
    <div>
      <Carousel
        responsive={carouselResponsiveness}
        infinite={false}
        autoPlay={false}
        showDots={false}
        swipeable={true}
        removeArrowOnDeviceType={["mobile"]}
      >
        {recentProducts.map((product) => {
          if (Number(product.id !== Number(id))) {
            return (
              <div style={{ height: "350px", marginRight: "10px" }}>
                <Product
                  onClose={() => removeRecentProduct(product.id)}
                  key={product.id}
                  product={product}
                  productType="recently-viewed"
                />
              </div>
            );
          }
        })}
      </Carousel>
    </div>
  );
};

export default RecentlyViewed;
