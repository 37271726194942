import * as Yup from "yup";

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
});
const signupSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required").email("Please enter a valid email"),
  phone: Yup.string().required("Phone number is required"),
  password: Yup.string().required("Password is required").min(8),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
const cignaSchema = Yup.object().shape({
  firstname: Yup.string().required().min(3).label("Firstname"),
  lastname: Yup.string().required().min(3).label("Lastname"),
  currency: Yup.string().required().email().label("Currency"),
  cignaCardID: Yup.string().required().label("Cigna Card ID"),
  card: Yup.string().required().label("Insurance Card"),
  dob: Yup.string().required().label("Date of Birth"),
  claimType: Yup.string().required().label("Claim Type"),
  totalInvoiceAmount: Yup.string().required().label("Total Invoice Amount"),
});
const continuumSchema = Yup.object().shape({
  fullname: Yup.string().required().min(3).label("Fullname"),
  employer: Yup.string().required().min(3).label("employer"),
  membershipID: Yup.string().required().email().label("Membership ID"),
  mobileNumber: Yup.string().required().label("Mobile Number"),
  residentialAddress: Yup.string().required().label("Residential Address"),
  patientFullname: Yup.string().required().label("Patient Fullname"),
  patientContactNumber: Yup.string().required().label("Patient Contact Number"),
  patientAge: Yup.string().required().label("Patient Age"),
});
const libertySchema = Yup.object().shape({
  firstname: Yup.string().required().min(3).label("Firstname"),
  dob: Yup.string().required().label("Date of Birth"),
  lastname: Yup.string().required().min(3).label("Lastname"),
  membershipID: Yup.string().required().email().label("Membership ID"),
  depCode: Yup.string().required().min(3).label("Dep Code"),
  gender: Yup.string().required().min(3).label("Gender"),
  employer: Yup.string().required().min(3).label("Employer"),
  email: Yup.string().email("Please enter a valid email").required("Email is required"),
  mobileNum: Yup.string().required().label("Mobile Number"),
  patientFullname: Yup.string().required().label("Patient Fullname"),
  patientMobileNum: Yup.string().required().label("Patient Contact Number"),
  patientAge: Yup.string().required().label("Patient Age"),
  facility: Yup.string().required().label("Facility"),
  physician: Yup.string().required().label("Consulting Physician"),
  providerNum: Yup.string().required().label("Provider Number"),
  specialty: Yup.string().required().label("Specialty"),
  treatmentDate: Yup.string().required().label("Treatment Date"),
  admissionDate: Yup.string().required().label("Addmission Date"),
  dischargeDate: Yup.string().required().label("Discharge Date"),
});
const linkFormSchema = Yup.object().shape({
  fullname: Yup.string().required().min(3).label("Fullname"),
  residentialAddress: Yup.string().required().label("Residential Address"),
  cardNum: Yup.string().required().min(3).label("Card / Serial number"),
  expiryDate: Yup.string().required().label("Expiry Date on Policy Card"),
});
const mshSchema = Yup.object().shape({
  fullname: Yup.string().required().min(3).label("Fullname"),
  dob: Yup.string().required().label("Date of Birth"),
  employer: Yup.string().required().min(3).label("Employer"),
  mshID: Yup.string().required().email().label("MSH ID"),
  providerFullname: Yup.string().required().label("Provider Fullname"),
  address: Yup.string().required().label("Residential Address"),
});
const prudentialSchema = Yup.object().shape({
  fullname: Yup.string().required().min(3).label("Fullname"),
  companyName: Yup.string().required().label("Company Name"),
  membershipID: Yup.string().required().email().label("Membership ID"),
  nrc: Yup.string().required().min(3).label("NRC Number"),
  mobileNum: Yup.string().required().label("Mobile Number"),
  email: Yup.string().email("Please enter a valid email").required("Email is required"),
  patientFullname: Yup.string().required().label("Patient Fullname"),
  patientRelationship: Yup.string().required("Patient Relationship to Principal").min(3).label("Patient Relationship"),
  patientDOB: Yup.string().required().label("Date of Birth"),
  policyID: Yup.string().required().min(3).label("Policy Number"),
  gender: Yup.string().required().min(3).label("Gender"),
  patientMobileNum: Yup.string().required().label("Patient Contact Number"),
  patientEmail: Yup.string().label("Patient Email"),
});
const sesSchema = Yup.object().shape({
  membershipID: Yup.string().required().email().label("Membership ID"),
  title: Yup.string().required().label("Title"),
  firstname: Yup.string().required().min(3).label("Firstname"),
  lastname: Yup.string().required().min(3).label("Lastname"),
  mobileNum: Yup.string().required().label("Mobile Number"),
  patientDOB: Yup.string().required().label("Date of Birth"),
  gender: Yup.string().required().min(3).label("Gender"),
  email: Yup.string().email("Please enter a valid email").required("Email is required"),
});
const ziscSchema = Yup.object().shape({
  providerName: Yup.string().required().label("Provider Name"),
  town: Yup.string().required().min(3).label("Town"),
  claimNum: Yup.string().required().email().label("Claim Number"),
  providerNum: Yup.string().required().label("Provider Number / Code"),
  mobileNumber: Yup.string().required().label("Mobile Number"),
  fullname: Yup.string().required().min(3).label("Fullname"),
  schemeType: Yup.string().required().label("Scheme Type"),
  employer: Yup.string().required().min(3).label("Policy Number"),
  patientFullname: Yup.string().required().min(3).label("Patient Fullname"),
  dob: Yup.string().required().min(3).label("Patient Date of Birth"),
  admissionDate: Yup.string().required().label("Addmission Date"),
  dischargeDate: Yup.string().required().label("Discharge Date"),
});

export {
  forgotPasswordSchema,
  loginSchema,
  signupSchema,
  cignaSchema,
  continuumSchema,
  libertySchema,
  linkFormSchema,
  mshSchema,
  prudentialSchema,
  sesSchema,
  ziscSchema,
};
