import React from "react";
import cities from "../../../../utils/cities";
import { Input, Select } from "../../../../components/CustomComponents";

const DeliveryForm = ({
  selectedAddress,
  addresses,
  onAddressChange,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setTouched,
}) => {
  return (
    <div className="mt-4">
      <h5 className="font-opensans-medium text-xl">Delivery Information</h5>

      <div className={`mt-3 mb-3`}>
        <div className={"mb-2"}>
          <Select
            value={selectedAddress || ""}
            onChange={(value) => onAddressChange(value)}
            options={addresses.map((address) => {
              return { value: address.id, label: address.attributes.address1 };
            })}
          />
        </div>

        <span style={{ paddingLeft: "8px" }} className="text-sm font-opensans-semibold text-gray-500">
          Select an address from your saved addresses.
        </span>
      </div>

      <div className={"mt-3 mb-3"}>
        <Input
          label={"First Name"}
          name={"firstName"}
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.firstName}
          touched={touched.firstName}
        />
      </div>

      <div className={"mt-3 mb-3"}>
        <Input
          label={"Last Name"}
          name={"lastName"}
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.lastName}
          touched={touched.lastName}
        />
      </div>

      <div className={"mt-3 mb-3"}>
        <Input
          label={"Address Line 1"}
          name={"address1"}
          value={values.address1}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.address1}
          touched={touched.address1}
        />
      </div>

      <div className={"mt-3 mb-3"}>
        <Input
          label={"Address Line 2"}
          name={"address2"}
          value={values.address2}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.address2}
          touched={touched.address2}
        />
      </div>

      <div className={`mt-3 mb-3`}>
        <Select
          label={"Town/City"}
          value={values.city}
          onChange={(value) => {
            // emulate change event
            handleChange({ target: { name: "city", value } });
          }}
          onBlur={handleBlur("city")}
          options={cities.map((city) => ({
            value: city,
            label: city,
          }))}
        />
      </div>

      <div className={"mt-3 mb-3"}>
        <Input
          label={"Residential Area"}
          name={"residentialArea"}
          value={values.residentialArea}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.residentialArea}
          touched={touched.residentialArea}
        />
      </div>

      <div className={"mt-3 mb-3"}>
        <Input
          label={"Postal Code"}
          name={"postalCode"}
          value={values.postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.postalCode}
          touched={touched.postalCode}
        />
      </div>
    </div>
  );
};

export default DeliveryForm;
