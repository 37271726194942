import { Link } from "react-router-dom";
// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

import style from "../../styles/views/BecomeASupplier.module.css";

const SupplierRequirements = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="sup_know">
        <div className="row">
          <div className="col-md-2">
            <div className={style.supplier_home}>
              <Link to="/become-a-supplier">
                <i className="fa fa-angle-left"></i> suppliers
              </Link>
            </div>
          </div>
          <div className="col-md-8">
            <div className={style.sup_know_header}>
              <h1>Minimum Requirements</h1>
              <p>
                All Link pharmacy suppliers and their manufacturing facilities,
                including all subcontracting and packaging facilities, will be
                held to these standards. Please be aware that there could be
                more requirements in addition to this list, depending on the
                type of product or service you’re providing.
              </p>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </section>
    </Layout>
  );
};
export default SupplierRequirements;
