import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/ScheduleAnAppointment.module.css";

const AppointmentBooking = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="row">
        <div className="col-md-12">
          <div className={style.s_form_flex}>
            <div className={style.s_form_cont}>
              <h2>We offer comprehensive, community based care.</h2>
              <p className="mb-2">
                Walk-ins are welcome or{" "}
                <Link
                  to={"/schedule-an-appointment"}
                  className="font-opensans-bold"
                >
                  book an appointment
                </Link>{" "}
                to see a nurse at Link mediclinic .
              </p>
              <h2>Your Health, Our Priority</h2>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AppointmentBooking;
