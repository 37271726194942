/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Lottie from "react-lottie";
import { useNavigate, useNavigationType, useParams, useLocation } from "react-router-dom";
import { productsApi } from "../../api/products";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import BreadCrumbs from "../../components/BreadCrumbs";
import Product from "../../components/products/Product";
import Paginate from "../../components/Pagination";
import animationLoading from "../../assets/animations/link-animation.json";
import { SmoothScroll } from "../../helpers/SmoothScroll";
import { Feedback, Select } from "../../components/CustomComponents";
import { HiSortDescending } from "react-icons/hi";
import { toHumanReadableString } from "../../helpers/toHumanReadableString";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Store = () => {
  const [sort, setSort] = useState("name");
  const [pageTitle, setPageTitle] = useState("Store | Link Pharmacy");
  const navigate = useNavigate();
  const location = useLocation();
  const action = useNavigationType();
  const {
    catID,
    catName,
    subCatName,
    subCatID,
    productType,
    valuesToGet,
    productTypeID,
    searchValue,
    pageNo: pageNumber,
  } = useParams();

  const [pageNo, setPageNo] = useState(pageNumber || 1);

  const { data: products, status: productsStatus } = useQuery(
    ["products", sort, productTypeID, searchValue, valuesToGet, subCatID, pageNo],
    () => productsApi(sort, productTypeID, searchValue, valuesToGet, subCatID, pageNo)
  );

  const onPageChange = (page) => {
    setPageNo(page);

    if (page === 1) {
      navigate(`/store/products`);
    } else {
      const pathname = window.location.pathname.split("/page")[0];
      navigate(`${pathname}/page/${page}`);
    }
  };

  // Effect to produce appropriate title
  useEffect(() => {
    catName && subCatName && productType
      ? setPageTitle(
          `${toHumanReadableString(catName)} ${toHumanReadableString(subCatName)} ${toHumanReadableString(
            productType
          )} | Link Pharmacy`
        )
      : setPageTitle("Link Pharmacy | Online Store Zambia");
  }, [catName, productType, subCatName]);

  // Effect for scroll listener
  useEffect(() => {
    if (action !== "POP") {
      SmoothScroll(0, "smooth");
    }
  }, [location]);

  useEffect(() => {
    setPageNo(1);
  }, [searchValue]);

  return (
    <Layout
      meta={{
        title: pageTitle,
        keywords:
          catName && subCatName && productType
            ? `${toHumanReadableString(catName)}, ${toHumanReadableString(subCatName)}, ${toHumanReadableString(
                productType
              )}, Link Pharmacy online store`
            : meta.homeKeywords,
        description:
          catName && subCatName && productType
            ? `${toHumanReadableString(catName)} ${toHumanReadableString(subCatName)} ${toHumanReadableString(
                productType
              )} | Link Pharmacy online store`
            : meta.storeDescription,
      }}
      header
      footer
      bgColor={"bg-stone-100"}
    >
      <div className="bg-white mb-4 py-3">
        <div className="container-lg container-md container-sm container">
          <BreadCrumbs
            crumbs={
              catName
                ? [
                    { name: "Home", link: "/" },
                    { name: `Shop`, link: "/store/catalog" },
                    { name: `All Products`, link: "/#" },
                    {
                      name: toHumanReadableString(catName),
                      link: `/store/catalog/${catName}/${catID}`,
                    },
                    {
                      name: toHumanReadableString(subCatName),
                      link: `/store/catalog/${catName}/${catID}/${subCatName}/${subCatID}`,
                    },
                    { name: toHumanReadableString(productType), link: "/#" },
                  ]
                : [
                    { name: "Home", link: "/" },
                    { name: `Shop`, link: "/store/catalog" },
                    { name: `All Products`, link: "/#" },
                  ]
            }
          />
          <div className="w-full flex items-center md:items-end justify-between mt-2 md:mt-0">
            <h1 className="flex m-0 text-base sm:text-[25px] lg:text-xl font-opensans-medium text-stone-800">
              {catName && subCatName && productType ? toHumanReadableString(productType) : `All Products`}
            </h1>

            <div className="w-55 sm:w-60">
              <Select
                options={[
                  { value: "name", label: "Product Name A - Z" },
                  { value: "-name", label: "Product Name Z - A" },
                  { value: "price", label: "Price Low To High" },
                  { value: "-price", label: "Price High To Low" },
                ]}
                icon={<HiSortDescending className="text-lg" />}
                value={sort}
                onChange={(e) => {
                  setSort(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg container-md container-sm container">
        {productsStatus === "loading" && (
          <div className="w-full h-full flex flex-1 justify-center items-center">
            <div className="">
              <Lottie options={defaultOptions} height={100} width={100} />
            </div>
          </div>
        )}

        {productsStatus === "success" && products?.data?.length === 0 ? (
          <Feedback
            backgroundColor={"#F3F4F6"}
            title={"No products found"}
            message={
              searchValue
                ? `No products found for "${searchValue}". Please try a different search term`
                : `No products found for "${toHumanReadableString(productType)}"`
            }
          />
        ) : (
          <div className="h-full w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 place-content-center mb-3">
            {products?.data?.map((product) => (
              <Product key={product.id} product={product} productType={"products"} />
            ))}
          </div>
        )}

        {productsStatus === "success" && products.meta.total > products.meta.per_page ? (
          <Paginate
            currentPage={products.meta.current_page}
            perPage={products.meta.per_page}
            total={products.meta.total}
            onPageChange={onPageChange}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default Store;
