import React from "react";

const AccountAvatar = ({ onClick, user }) => {
  return (
    <div
      className="w-full md:w-full bg-white hover:bg-stone-50 flex items-center justify-between p-4 cursor-pointer text-lg rounded transition-all duration-150 ease-out"
      onClick={onClick}
    >
      <div className="bg-link-green w-20 h-20 flex items-center justify-center rounded-full text-white text-4xl font-opensans-bold">
        {user?.firstname?.charAt(0)}
        {user?.lastname?.charAt(0)}
      </div>

      <div className="flex flex-col justify-center ml-4 font-opensans">
        <p className="m-0">Hi,</p>
        <h3 className="font-opensans-bold">{user?.firstname + " " + user?.lastname}</h3>
      </div>
    </div>
  );
};

export default AccountAvatar;
