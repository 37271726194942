// noinspection com.haulmont.rcb.ArrayToJSXMapInspection

import React from "react";

interface Props {
  actions?: React.ReactNode | React.ReactNode[];
  errorCode?: string;
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
  backgroundColor?: string;
}

const Feedback = ({ actions, errorCode, title, message, backgroundColor }: Props) => {
  // Added tabIndex and aria-label for better accessibility
  return (
    <main
      className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8"
      style={{ backgroundColor }}
      tabIndex={0}
      aria-label="Feedback area"
    >
      <div className="text-center">
        <p className="text-base font-opensans-semibold text-link-green-1">{errorCode}</p>
        <h1
          className="mt-3 mb-3 text-3xl font-opensans-bold tracking-tight text-gray-900 sm:text-5xl"
          tabIndex={0}
          aria-label="Error title"
        >
          {title}
        </h1>
        <p className="text-sm leading-7 text-gray-600 font-opensans" tabIndex={0} aria-label="Error message">
          {message}
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-3" tabIndex={0} aria-label="Action items">
          {actions}
        </div>
      </div>
    </main>
  );
};

export default Feedback;
