import { useState, createContext } from "react";
import { useQuery } from "react-query";
import { productsApi } from "../api/products";

export const StoreContext = createContext();

const StoreAttributesContext = ({ children }) => {
  const [sort, setSort] = useState("name");
  const [priceRange, setPriceRange] = useState([]);
  const [productTypeId, setProductTypeId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [typeId, setTypeId] = useState("");

  const { data: products, status: productsStatus } = useQuery(
    ["products", { sort, productTypeId, searchValue, priceRange, typeId }],
    () => productsApi(sort, productTypeId, searchValue, priceRange, typeId)
  );

  return (
    <StoreContext.Provider
      value={{
        products: products,
        productsStatus: productsStatus,
        sort: sort,
        priceRange: priceRange,
        setPriceRange: setPriceRange,
        setSort: setSort,
        setProductTypeId: setProductTypeId,
        productTypeId: productTypeId,
        setSearchValue: setSearchValue,
        setTypeId: setTypeId,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreAttributesContext;
