import addressSchema from "./schemas/address";
import contactUsSchema from "./schemas/contactUs";
import checkoutSchema from "./schemas/checkout";
import scheduleAnAppointment from "./schemas/scheduleAnAppointment";

const validationSchemas = {
  address: addressSchema,
  contactUs: contactUsSchema,
  checkout: checkoutSchema,
  scheduleAnAppointment,
};

export default validationSchemas;
