import Layout from "../../components/Layout/Layout";
import styles from "../../styles/views/MedicationCompounding.module.css";
import { Link } from "react-router-dom";

const MedicationCompounding = () => {
  return (
    <Layout
      meta={{
        title: "Medication Compounding | Link Pharmacy",
        keywords:
          "Medication Compounding, Link Pharmacy, Custom Medication, Custom Dosage, Medicine, Health, Zambia, Lusaka, Kitwe, Ndola, Kabwe",
        description:
          "Explore our medication compounding services in Zambia, offering custom strength and dosage to meet unique health needs across Lusaka, Kitwe, Ndola, Kabwe, and more.",
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className={"bg-[#10101061] d-flex flex-col items-center justify-center h-60 md:h-96 p-3"}>
          <h1 className="text-white font-opensans-bold md:text-3xl text-center">Medication Compounding</h1>
        </div>
      </main>

      <section className="container p-3 font-opensans leading-normal">
        <p className="mb-3">
          We have the ability to compound products tailored to suit the needs of our customers in Zambia, giving them
          access to the best medicine for their personal needs.
        </p>

        <p className="mb-3">
          Working with your doctor, compounding allows our pharmacists to customize the strength and dosage of a
          medication according to your individual requirements.
        </p>

        <p>
          <Link to={"/contact-us"} className="font-opensans-semibold text-link-green-1">
            Get in touch
          </Link>{" "}
          with our pharmacy team for more advice and information
        </p>
      </section>
    </Layout>
  );
};

export default MedicationCompounding;
