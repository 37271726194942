import { Link, useParams } from "react-router-dom";

// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import pharmacy from "../../assets/images/Home page 01port.jpg";
import style from "../../styles/views/PharmacyManager.module.css";

const PharmacyManager = () => {
  const { id } = useParams();

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="row">
        <div className="col-md-12">
          <div className="container">
            <div className={style.pharmacy_manager}>
              <div className={style.pharmacy_manager_cont}>
                <Link to={"/store_details/" + id}>Return to Store Details</Link>
              </div>
            </div>

            <div className={style.staff_profile}>
              <img src={pharmacy} alt="staff Manager" />
              <div className={style.staff_profile_cont}>
                <h2>
                  Jamie C.{" "}
                  <span>Pharmacy Manager | Years with Link Pharmacy : 3</span>
                </h2>
                <p>
                  Diabetes Management, Immunizations, Vitamins and Supplements
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default PharmacyManager;
