import { useState } from "react";
import { Link } from "react-router-dom";
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import Cigna from "../InsuranceForms/Cigna";
import LinkForm from "../InsuranceForms/LinkForm";
import MSH from "../InsuranceForms/MSH";
import Prudential from "../InsuranceForms/Prudential";
import Ses from "../InsuranceForms/SES";
import styles from "../../styles/views/MyHealthInsurance.module.css";
import Zisc from "../InsuranceForms/ZISC";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Continuum from "../InsuranceForms/Continuum";
import Liberty from "../InsuranceForms/Liberty";

const InsuranceMachine = createMachine({
  id: "myHealthInsurance",
  initial: "idle",
  states: {
    idle: {
      on: {
        NEW_CLAIM: "newClaim",
        VIEW_CLAIM: "viewClaim",
      },
    },
    newClaim: {
      on: {
        BACK: "idle",
      },
    },
    viewClaim: {
      on: {
        BACK: "idle",
      },
    },
  },
});

const MyHealthInsurance = () => {
  const [current, send] = useMachine(InsuranceMachine);
  const [insuranceCompany, setInsuranceCompany] = useState(null);

  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b px-4 py-3 hidden md:block">
        <h2 className="m-0 font-opensans-medium text-2xl">myHealth Insurance</h2>
      </div>

      <div className="p-6 pb-4">
        {current.matches("idle") && (
          <>
            <div className="w-full flex justify-between items-center">
              <Link
                to={"#"}
                className="w-fit h-fit px-3 py-2 bg-link-green font-opensans-medium text-white rounded-3xl"
                onClick={(e) => {
                  e.preventDefault();
                  send("NEW_CLAIM");
                }}
              >
                New Claim
              </Link>
            </div>

            <div className="mt-4">
              <Table className={"table"}>
                <TableHead>
                  <TableRow>
                    <TableCell>Claim Company</TableCell>
                    <TableCell>Submitted On</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Liberty</TableCell>
                    <TableCell>{new Date().toLocaleDateString()}</TableCell>
                    <TableCell>Pending</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </>
        )}

        {current.matches("newClaim") && (
          <>
            <p>Select the health insurance you are covered with from the options below</p>
            <div className="w-full flex items-center space-x-2 h-11 bg-link-green px-3 rounded-md">
              <span className="bg-stone-900/30 text-white rounded-full w-7 h-7 flex flex-col items-center">1</span>
              <span>Step One</span>
            </div>
            <div className={"my-4 md:px-12 flex flex-col space-y-4"}>
              <div>
                <h5 className={`font-weight-bold ${styles.label}`}>Choose Insurance Provider</h5>
                <p>
                  <select
                    name="insuranceCompany"
                    id="insuranceCompany"
                    className="form-select"
                    defaultValue={"default"}
                    onChange={(e) => {
                      setInsuranceCompany(e.target.value);
                    }}
                  >
                    <option value="default" disabled>
                      -- Select an option --
                    </option>
                    <option value="Cigna">Cigna</option>
                    <option value="Continuum">Continuum</option>
                    <option value="MSH International">MSH International</option>
                    <option value="Liberty">Liberty</option>
                    <option value="ZISC Life">ZISC Life</option>
                    <option value="Prudential">Prudential</option>
                    <option value="SES">SES</option>
                    <option value="MSO International">MSO International</option>
                    <option value="Generali Global">Generali Global</option>
                    <option value="Bupa">Bupa</option>
                    <option value="Aetna International">Aetna International</option>
                    <option value="United Health Global">United Health Global</option>
                    <option value="Met Life">Met Life</option>
                    <option value="Optimum Global">Optimum Global</option>
                    <option value="Discovery Insurance">Discovery Insurance</option>
                  </select>
                </p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <input
                    required
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 default:bg-green-500 default:text-green-800 required:border-red-500 text-blue-600 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label for="default-checkbox" className="ml-2 text-sm font-medium text-stone-700">
                    Please note that orders placed using health insurance may take a little longer to process and be
                    dispatched, we apologise for any inconvenience this may cause.
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    required
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 default:bg-green-500 default:text-green-800 required:border-red-500 text-blue-600 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label for="default-checkbox" className="ml-2 text-sm font-medium text-stone-700">
                    I acknowledge the above is true to the best of my knowledge. I authorise my insurance and or
                    employer scheme benefits to be paid directly to Link pharmacy . I understand that I am financially
                    responsible for any balance.
                  </label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    required
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 default:bg-green-500 default:text-green-800 required:border-red-500 text-blue-600 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label for="default-checkbox" className="ml-2 text-sm font-medium text-stone-700">
                    I also authorise link pharmacy and/or insurance company and / or employer scheme to release any of
                    my medical information required to process my claim.
                  </label>
                </div>
              </div>
            </div>

            {insuranceCompany === "Cigna" && <Cigna />}
            {insuranceCompany === "Continuum" && <Continuum />}
            {insuranceCompany === "Liberty" && <Liberty />}
            {insuranceCompany === "MSH International" && <MSH />}
            {insuranceCompany === "ZISC Life" && <Zisc />}
            {insuranceCompany === "Prudential" && <Prudential />}
            {insuranceCompany === "SES" && <Ses />}

            {insuranceCompany === "MSO International" && <LinkForm />}
            {insuranceCompany === "Generali Global" && <LinkForm />}
            {insuranceCompany === "Bupa" && <LinkForm />}
            {insuranceCompany === "Aetna International" && <LinkForm />}
            {insuranceCompany === "United Health Global" && <LinkForm />}
            {insuranceCompany === "Met Life" && <LinkForm />}
            {insuranceCompany === "Optimum Global" && <LinkForm />}
            {insuranceCompany === "Discovery Insurance" && <LinkForm />}
          </>
        )}
      </div>
    </div>
  );
};

export default MyHealthInsurance;
