/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

import style from "../../styles/views/Hiv.module.css";

const Hiv = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="row">
        <div className="col-md-12">
          <div className={style.h_bg}></div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className={style.h_nav}>
            <a href="#">HIV-Specialized Pharmacy</a>
            <a href="#">Prevention & Testing</a>
            <a href="#">Medications & Financial Assistance</a>
          </div>
        </div>
        <div className="col-md-9">
          <div className={style.h_header}>
            <h1>We're here for your health</h1>
            <p>
              Our specially trained pharmacists are here to support you. From
              testing to finding financial assistance, we can help every step of
              the way.
            </p>
            <hr />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className={style.h_help_flex}>
            <div className={style.h_help}></div>
            <div className={style.h_help_img}></div>
          </div>
          <hr />

          <div className={style.h_take_flex}>
            <div className={style.h_take_img}></div>
            <div className={style.h_take}></div>
          </div>
          <hr />
          <div className={style.h_info_flex}>
            <div className={style.h_info}></div>
            <div className={style.h_info}></div>
            <div className={style.h_info}></div>
            <div className={style.h_info}></div>
            <div className={style.h_info}></div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Hiv;
