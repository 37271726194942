import React from "react";
import styles from "../styles/CompanyInfo.module.css";
import CommunityImage from "../../assets/images/link_extra.jpg";
import Medicines from "../../assets/images/Medicines-&-Treatments.jpg";
import Layout from "../../components/Layout/Layout";

const SocialResponsibility = () => {
  return (
    <Layout
      meta={{
        title: "Social Responsibility | Link Pharmacy",
        keywords: "Social Responsibility, Link Pharmacy",
        description: "Social Responsibility",
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">Social Responsibility</h1>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <div className="flex flex-col md:flex-row space-x-4 mb-10 items-center space-y-3 md:space-y-0">
          <div className="flex-1">
            <h3 className="mb-1 text-2xl font-opensans-semibold">Community</h3>
            <p>Supporting the community through services, resources and outreach.</p>
          </div>
          <div className="flex-1">
            <img src={CommunityImage} alt="" className="w-full h-full object-contain" />
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row space-x-4 items-center space-y-3 md:space-y-0">
          <div className="flex-1">
            <img src={Medicines} alt="" className="w-full h-full object-contain" />
          </div>
          <div className="flex-1">
            <h3 className="mb-1 text-2xl font-opensans-semibold">Integrity</h3>
            <p>Establishing stringent requirements to ensure the quality and safety of the products we sell.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SocialResponsibility;
