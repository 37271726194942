import style from "../styles/views/JobAlertForm.module.css";

const JobAlertForm = () => {
  return (
    <div>
      <div className={style.job_alert_form}>
        <div className={style.job_alert_form_header}>
          <h4>Sign up for Job Alerts</h4>
          <p>Be the first to know of new job opportunities.</p>
        </div>
        <form method="POST" action="{{ route('register') }}">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="first_name">
                First Name <span>*</span>
              </label>
              <input
                id="first_name"
                type="text"
                className="form-control @error('first_name') is-invalid @enderror"
                name="first_name"
                value=""
                required
                autoComplete="name"
                autoFocus
                placeholder="First name"
              />
              <br />
              <span className="invalid-feedback" role="alert"></span>
            </div>
            <div className="col-md-6">
              <label htmlFor="last_name">
                Last Name <span>*</span>
              </label>
              <input
                id="last_name"
                type="text"
                className="form-control"
                name=""
                value=""
                required
                autoComplete=""
                autoFocus
                placeholder="Last name"
              />
              <br />
              <span className="invalid-feedback" role="alert"></span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="email">
                Email Address: <span>*</span>
              </label>
              <input
                id="email"
                type="email"
                className="form-control @error('email') is-invalid @enderror"
                name="email"
                value=""
                required
                autoComplete="email"
                placeholder="Email"
              />
              <br />
              <span className="invalid-feedback" role="alert"></span>
            </div>
            <div className="col-md-6">
              <label htmlFor="phone_number">
                Phone Number: <span>*</span>
              </label>
              <input
                id="phone_number"
                type="text"
                className="form-control @error('phone_number') is-invalid @enderror"
                name="phone_number"
                value=""
                required
                autoComplete="phone_number"
                autoFocus
                placeholder="Phone Number"
              />
              <br />
              <span className="invalid-feedback" role="alert"></span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="job">
                Job category: <span>*</span>
              </label>
              <select id="job" className="form-control">
                <option title="category">Select a Job Category</option>
                <option title="category">Pharmacist</option>
                <option title="category">Marketing</option>
                <option title="category">Information Technology</option>
                <option title="category">Intern</option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="location">
                Location: <span>*</span>
              </label>
              <input
                id="location"
                type="text"
                className="form-control"
                name="location"
                required
                autoComplete="location"
                placeholder="Type to Search Location"
              />
              <br />
              <button
                className="btn btn-secondary"
                style={{ marginBottom: 10 }}
              >
                Add location
              </button>
            </div>
          </div>
          <input type="submit" value="Sign Up" className="btn btn-primary" />
        </form>
      </div>
    </div>
  );
};
export default JobAlertForm;
