import React from "react";
import animationLoading from "../../assets/animations/link-animation.json";
import Lottie from "react-lottie/dist";

interface Props {
  options?: any;
}

const Index = ({ options }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="w-full h-full flex flex-1 justify-center items-center">
      <div className="">
        <Lottie options={defaultOptions || options} height={100} width={100} />
      </div>
    </div>
  );
};

export default Index;
