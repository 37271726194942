import { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { AuthContext } from "../../context/AuthContext";
import MenuLeft from "../MenuLeft.jsx";
import MenuRight from "../MenuRight";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const HeaderRibbon = () => {
  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);

  const { pathname } = useLocation();
  const { user, signedIn } = useContext(AuthContext);

  const toggleLeftMenu = () => {
    setLeftMenuOpen(!leftMenuOpen);
    rightMenuOpen && setRightMenuOpen(!rightMenuOpen);
  };

  const toggleRightMenu = () => {
    setRightMenuOpen(!rightMenuOpen);
    leftMenuOpen && setLeftMenuOpen(!leftMenuOpen);
  };

  // Close sidebars on route change
  useEffect(() => {
    setLeftMenuOpen(false);
    setRightMenuOpen(false);
  }, [pathname]);

  return (
    <>
      <div className="font-opensans flex items-center justify-between md:px-10 lg:px-24 xl:px-40 2xl:px-80 w-full h-[50px] bg-gray-100 dark:bg-stone-700 text-stone-700 dark:text-gray-100 text-xs lg:text-sm">
        <div onClick={toggleLeftMenu} className="ml-2 md:ml-0 cursor-pointer flex items-center">
          Menu {leftMenuOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </div>

        <div className="h-full flex items-center justify-center space-x-2">
          {!signedIn && (
            <>
              <Link to={"/login"}>
                <span>{"Sign In"}</span>
              </Link>

              <span className="mx-2">|</span>
              <Link to={"/register"}>
                <span>{"Register"}</span>
              </Link>

              <span className="mx-2">|</span>
            </>
          )}

          <div onClick={toggleRightMenu} className="flex items-center space-x-1 cursor-pointer">
            {signedIn ? <div>{`Hi, ${user.firstname}`}</div> : <div>{"My Account"}</div>}

            <div>{rightMenuOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {leftMenuOpen && <MenuLeft toggleLeftMenu={toggleLeftMenu} />}
        {rightMenuOpen && <MenuRight toggleRightMenu={toggleRightMenu} />}
      </AnimatePresence>
    </>
  );
};

export default HeaderRibbon;
