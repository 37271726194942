import { Link } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";

const BreadCrumbs = ({ crumbs }) => {
  const totalCrumbs = crumbs.length - 1;
  return (
    <div className="w-full flex flex-wrap items-center pb-3">
      {crumbs.map((crumb, index) =>
        index === 0 ? (
          <p key={index} className="text-sm m-0 font-opensans">
            <Link to={crumb.link}>{crumb.name}</Link>
          </p>
        ) : index === totalCrumbs ? (
          <h3 key={index} className="text-sm m-0 flex items-center font-opensans">
            <MdChevronRight className="text-2xl text-gray-500" />
            <span>{crumb.name}</span>
          </h3>
        ) : (
          <p key={index} className="text-sm m-0 flex items-center font-opensans">
            <MdChevronRight className="text-2xl text-gray-500" />
            <Link to={crumb.link}>{crumb.name}</Link>
          </p>
        )
      )}
    </div>
  );
};

export default BreadCrumbs;
