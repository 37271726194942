import { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";

/**
 * This is a typescript .ts file. Please make sure to define
 * the types of any variables that you create.
 * @see {@link https://www.typescriptlang.org/docs/handbook/2/basic-types.html}
 * **/

// Type Definitions
interface IPrescriptionVariations {
  id: number;
  name: string;
  status: string | null;
  quantity: number;
  price: number;
  thumbnail: string;
  recommended: boolean;
  variationId: number;
}

interface IPrescriptionItem {
  name: string;
  prescriptionId: number;
  beenAdded: boolean;
  items: Array<IPrescriptionVariations>;
}

const usePrescriptionDetails = (id: number) => {
  const [prescriptionItems, setPrescriptionItems] = useState<Array<IPrescriptionItem>>([]);
  const [prescriptionStatus, setPrescriptionStatus] = useState<string>("");
  const [prescriptionNumber, setPrescriptionNumber] = useState<number | undefined>(undefined);
  const [prescriptionImage, setPrescriptionImage] = useState<string>("");
  const [isPrescriptionComplete, setIsPrescriptionComplete] = useState<boolean>(false);

  const prescriptionDetailsQuery = useQuery(
    ["prescriptionDetails", id],
    async () => {
      const { data } = await axios.get(`/v1/website/prescriptions/${id}`);

      return await data.data;
    },
    {
      onSuccess: (data) => {
        // Transform object to give us a more manageable PrescriptionItem object
        const prescriptionItems = data.details.map((item: any) => {
          return {
            name: item.medicine_name,
            prescriptionId: data.id,
            isComplete: data.attributes.is_complete,
            beenAdded:
              item.medicine_variations.filter((p: any) => p.status === "in_cart").length >
              0 /** set this field to true if any variant has been to cart **/,
            items: item.medicine_variations.map((variation: any) => {
              return {
                id: variation.id,
                name: variation.medicine_name,
                quantity: variation.quantity || 1,
                status: variation.status,
                price: variation.price,
                thumbnail: variation.thumbnail,
                recommended: variation.recommended,
                variationId: variation.variation_id,
              };
            }),
          };
        });

        setPrescriptionItems(prescriptionItems);
        setPrescriptionStatus(data.attributes.status);
        setPrescriptionNumber(data.attributes.number);
        setPrescriptionImage(data.attributes.prescription);
        setIsPrescriptionComplete(data.attributes.is_complete);
      },
    }
  );

  return {
    prescriptionItems,
    loading: prescriptionDetailsQuery.isLoading,
    prescriptionStatus,
    prescriptionNumber,
    prescriptionImage,
    isPrescriptionComplete,
  };
};

export default usePrescriptionDetails;
