import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiCheck, HiOutlineSelector } from "react-icons/hi";
import { classNames } from "../../../utils/utils";

export type Option = {
  label: string;
  value: string | number;
};
interface Props {
  value: string | number;
  label?: string;
  onChange: (value: string) => void;
  options: Option[];
  icon?: React.ReactNode;
  border?: boolean;
}

const Select = ({ label, onChange, value, options, icon, border = true }: Props) => {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className="block text-sm font-opensans-medium leading-6 text-gray-800 mb-1">
              {label}
            </Listbox.Label>
          )}
          <div className="relative">
            <Listbox.Button
              className={classNames(
                "relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 text-[14px] sm:text-sm sm:leading-6",
                border
                  ? "ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-link-green bg-white"
                  : ""
              )}
            >
              <span className="flex items-center">
                <div className={classNames(icon ? "h-6 w-5 flex items-center justify-center" : "h-6")}>{icon}</div>
                <span className="ml-2 block truncate font-opensans">
                  {options.find((option) => option.value === value)?.label}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <HiOutlineSelector className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm scrollbar scrollbar-thumb-link-green scrollbar-track-scrollbar-link-grey-2 scrollbar-thin">
                {options?.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-[#d3d600] text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center text-xs sm:text-sm">
                          <div className="h-5" />
                          <span
                            className={classNames(
                              selected ? "font-opensans-semibold" : "font-opensans",
                              "ml-3 block truncate"
                            )}
                          >
                            {option.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-link-green",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <HiCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Select;
