import { Form, Formik } from "formik";
import { IoCard, IoCode, IoMail, IoMale, IoPerson } from "react-icons/io5";
import { genderOptions, titleOptions } from "../../globals";
import { sesSchema } from "../../globals/schemas";
import styles from "../../styles/views/MyHealthInsurance.module.css";
import { FormField, SelectField } from "../forms";

const Ses = () => {
  return (
    <Formik
      initialValues={{
        membershipID: "",
        firstname: "",
        lastname: "",
        title: "",
        mobileNum: "",
        patientDOB: "",
        gender: "",
        email: "",
        claimForm: "",
        insuranceCompany: "SES",
      }}
      onSubmit={(values) => console.log(values)}
      validationSchema={sesSchema}
    >
      {({ isSubmitting, values, handleChange, setFieldValue, errors }) => (
        <Form className="space-y-4 flex flex-col self-center w-full">
          <div className={styles.stepCounter}>
            <div>2</div> Membership Details
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Membership Number"}
              type="text"
              name="membershipID"
              placeholder="e.g.: 43257678"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCard className="text-xl ml-2 text-link-green" />}
            />
            <SelectField
              label={"Title"}
              type="text"
              name="title"
              placeholder="Title"
              options={titleOptions}
              margin="mb-0"
              icon={<IoCode className="text-xl ml-2 text-link-green" />}
            />
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Firstname"}
              type="text"
              name="firstname"
              placeholder="e.g.: John"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Lastname"}
              type="text"
              name="lastname"
              placeholder="e.g.: Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Contact Number"}
              type="text"
              name="mobileNum"
              placeholder="e.g.: XXXX-XXX-XXX"
              width={"w-fit"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Date of Birth"}
              type="date"
              name="patientDOB"
              width={"w-fit"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <div className="w-full md:w-fit">
              <SelectField
                label={"Gender"}
                type="text"
                name="patientGender"
                placeholder="Gender"
                options={genderOptions}
                margin="mb-0"
                icon={<IoMale className="text-xl ml-2 text-link-green" />}
              />
            </div>
          </div>
          <div className="w-full md:px-12">
            <FormField
              label={"Email"}
              type="email"
              name="email"
              placeholder="e.g.: name@domain.com"
              margin="mb-0"
              icon={<IoMail className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className={styles.stepCounter}>
            <div>3</div> Claim Details
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={"mt-4"}>
                <div>
                  <h5 className={`font-weight-bold ${styles.label}`}>{"Upload Copy of Claim Form"}</h5>

                  <p>
                    {"Please ensure Patient's signature, Doctor's signature and Medical Services are clearly visible."}
                  </p>

                  <input
                    type="file"
                    name="fullNames"
                    className={"form-control"}
                    onChange={(event) => {
                      setFieldValue("claimForm", event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <input
              type={"submit"}
              value="Submit form"
              className="bg-link-green text-stone-900 rounded-full p-3"
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Ses;
