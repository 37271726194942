import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../Loader";
import styles from "../../styles/views/AddressBook.module.css";
import { Button } from "../CustomComponents";
import { Label } from "../CustomComponents/components/input";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useNotifications } from "../../context/NotificationsContext";

const AddressBook = () => {
  const { user } = useContext(AuthContext);
  const [dialogState, setDialogState] = useState({ open: false, id: null });
  const { pushNotification } = useNotifications();
  const navigate = useNavigate();

  const handleRemove = (id) => {
    setDialogState({ open: true, id });
  };

  const handleCancel = () => {
    setDialogState({ open: false, id: null });
  };

  const { data: address, isLoading } = useQuery(["addresses", user.id], async () => {
    const { data } = await axios.get("/v1/website/address");
    return data;
  });

  const queryClient = useQueryClient();
  const { mutate: removeAddress, isLoading: isRemoveLoading } = useMutation(
    ["remove-address"],
    async () => {
      const { data } = await axios.delete(`/v1/website/address/${dialogState.id}`);
      return data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["addresses", user.id]);
        pushNotification({
          message: "Address removed successfully",
          type: "Success",
          id: Math.random(),
        });
        setDialogState({ open: false, id: null });
      },
      onError: (error) => {
        pushNotification({
          message: "Something went wrong. Please try again or contact support for help.",
          type: "Error",
          id: Math.random(),
        });
      },
    }
  );

  const AddressBookHeader = () => (
    <div className="w-full flex items-center justify-between border-b px-4 py-3">
      <h2 className="m-0 font-opensans-medium text-2xl hidden md:block">Address Book</h2>

      <Button onClick={() => navigate("/account?t=address-book-add")}>Add Address</Button>
    </div>
  );

  const AddressContent = () => (
    <div className="w-full flex flex-col p-3">
      {isLoading && <Loader />}

      {!isLoading && address.data.length === 0 && <NoAddressesFound />}
      {!isLoading &&
        address.data.length > 0 &&
        address.data.map((address) => <AddressItem key={address.id} address={address} />)}
    </div>
  );

  const NoAddressesFound = () => (
    <div className={`mt-4 w-full text-center`}>
      <h4 style={{ fontWeight: "300" }}>No Addresses Found...</h4>
    </div>
  );

  const AddressItem = ({ address }) => (
    <div className={`${styles.addressItem} shadow-sm`}>
      <AddressInfo address={address} />
      <AddressActions id={address.id} />
    </div>
  );

  const AddressInfo = ({ address }) => (
    <div className={`${styles.addressInfo}`}>
      <ul>
        <li className="mb-1">
          <Label label={"Name"} />
          <p className={"mt-[-7px] font-opensans-semibold text-md"}>{user && user.firstname + " " + user.lastname}</p>
        </li>
        <li className="mb-1">
          <Label label={"Address Line 1"} />
          <p className={"mt-[-7px] font-opensans-semibold text-md"}>{address.attributes.address1}</p>
        </li>
        <li className="mb-1">
          <Label label={"Address Line 2"} />
          <p className={"mt-[-7px] font-opensans-semibold text-md"}>{address.attributes.address2}</p>
        </li>
        <li className="mb-1">
          <Label label={"City"} />
          <p className={"mt-[-7px] font-opensans-semibold text-md"}>{address.attributes.city}</p>
        </li>
        <li className="mb-1">
          <Label label={"Residential Area"} />
          <p className={"mt-[-7px] font-opensans-semibold text-md"}>{address.attributes.residential_area}</p>
        </li>
        <li className="mb-1">
          <Label label={"Postal Code"} />
          <p className={"mt-[-7px] font-opensans-semibold text-md"}>{address.attributes.postal_code}</p>
        </li>
      </ul>
    </div>
  );

  const AddressActions = ({ id }) => (
    <div className={styles.actions}>
      <Link to={`/account?t=address-book-edit&id=${id}`} className={`${styles.btn} font-opensans-semibold text-sm`}>
        Edit
      </Link>
      <button onClick={handleRemove.bind(null, id)} className={`${styles.btn} font-opensans-semibold text-sm`}>
        Remove
      </button>
    </div>
  );

  return (
    <div className="w-full flex flex-col text-lg">
      <AddressBookHeader />
      <AddressContent />

      <Dialog
        open={dialogState.open}
        onClose={handleCancel}
        aria-labelledby="confirm-address-deletion-dialog"
        maxWidth={"xs"}
      >
        <DialogTitle>
          <h3 className="m-0 font-opensans-bold">Confirm Address Deletion</h3>
        </DialogTitle>
        <DialogContent>
          <p className="font-opensans leading-normal text-md">
            Are you sure you want to delete this address? <br /> This action cannot be reversed.
          </p>
        </DialogContent>
        <DialogActions>
          <Button variant={"secondary"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={() => removeAddress()} disabled={isRemoveLoading}>
            {isRemoveLoading ? <Button.Loader label={"Loading..."} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddressBook;
