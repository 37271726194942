import React from "react";
import style from "../../styles/views/PrivacyPolicy.module.css";
import { meta } from "../../globals";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import shippingRates from "../../utils/shippingRates";
import Layout from "../../components/Layout/Layout";

const TermsOfUse = () => {
  return (
    <Layout
      header
      footer
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
    >
      <section id="terms_wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className={style.p_policy}>
              <br />
              <h1>Terms of Use</h1>
              <h2>1. Introduction</h2>
              <p>
                This website is owned and provided by Link pharmacy LTD (Link
                pharmacy), a company incorporated in Zambia. These general terms
                and conditions apply to anyone accessing information viewing
                pages or placing orders utilising any part of the Link pharmacy
                website. By utilising the website you are acknowledging your
                acceptance of these general terms and conditions. Link pharmacy
                retains the right to change the terms and conditions at any time
                without warning or prior notice.
              </p>
              <p>
                The contents of the website are specifically provided for
                informational purposes only. No part of it is intended to
                substitute professional medical advice.
              </p>
              <p>
                <strong>
                  BY CONTINUING TO USE THIS WEBSITE YOU ARE CONFIRMING THAT YOU
                  ACCEPT OUR TERMS AND CONDITIONS OF USE SET OUT BELOW. IF YOU
                  DO NOT ACCEPT THE TERMS AND CONDITIONS, YOU MUST LEAVE THIS
                  WEBSITE.
                </strong>
              </p>
              <h2>2. Definitions</h2>
              <p>
                <strong>&quot;Link pharmacy"</strong> means the company, Link
                pharmacy LTD and its subsidiaries incorporated in the Republic
                of Zambia, having its registered office at 386 Copper Street,
                Roma Park Commercial, Lusaka, Zambia,10101;
              </p>
              <p>
                <strong>&quot;We&quot;, &quot;Us” or &quot;Our&quot;</strong>{" "}
                refers to Link pharmacy;
              </p>
              <p>
                <strong>&quot;User”, “You” or Your&quot;</strong> means a
                natural person accessing or utilizing the website either
                collectively or individually, as the context requires;
              </p>
              <p>
                <strong>“Terms/Agreement”</strong> means the contents and
                conditions stated herein.
              </p>
              <p>
                <strong>“Services”</strong> means prescription medicines sold at
                our store either through our web store or physical store and
                non-prescription items such as toothpaste, body cream, perfumes
                etc.
              </p>
              <p>
                We are not the producer or manufacturer of those items contained
                in the <strong>“Services”</strong> and the respective
                manufacturers are responsible for their own terms and
                conditions.
              </p>
              <h2>3. Access to Website</h2>
              <p>
                This website is the property of Link pharmacy. We reserve the
                right at all times to unilaterally refuse access to this website
                or parts of it.
              </p>
              <p>
                We reserve the right to suspend or terminate access to the
                website with immediate effect if non-authorised human
                intervention, viruses, bugs, or other causes beyond the control
                of Link pharmacy corrupt or jeopardize the administration or
                security of the website portal.
              </p>
              <p>
                Our prescription service is run by qualified pharmacists and
                operates from registered pharmacy premises under approval of
                regulatory control and monitoring authorities of the Republic of
                Zambia.
              </p>
              <h2>4. Privacy</h2>
              <p>
                Your privacy and that of each other person whose information you
                provide to us is important to us. We will treat all your
                Personal Data as confidential (although we reserve the right to
                disclose this information in the circumstances set out below).
                We will keep it on a secure server and we will fully comply with
                all applicable privacy regulations and consumer legislation.
              </p>
              <p>
                By using our website you agree to receive automatically
                generated medication refill reminders.In our privacy notice, you
                can also find out how to stop receiving marketing information.
              </p>
              <p>
                It is important that you understand and are comfortable with
                these terms before using the service and we encourage you to
                take time to read them.
              </p>
              <p>
                We own and license all content contained within the services,
                including without limitation, text, images, icons, software,
                trademarks, and user content which you may access and use solely
                for your own personal, non commercial shopping and information
                purposes. While we work hard to provide a superior customer
                experience, we cannot guarantee that all content we display is
                correct, complete or current including product pricing, images
                and availability.
              </p>
              <h2>5. Registration</h2>
              <p>
                Visitors to this website may be required to register in order to
                use its facilities.
              </p>
              <p>
                We are not under any obligation to accept a request for
                registration and reserve the right to suspend or terminate
                access at any time if your continued use is believed to
                prejudice us or other users. By registering to use this website
                you confirm that the information you provide during the
                registration process is accurate and complete. You agree to
                update your registration details promptly if they change. All
                registration information you provide will be kept secure and
                processed in accordance with our privacy notice.
              </p>
              <p>
                <strong>You warrant that:</strong>
              </p>
              <p>
                The Personal Data which you are required to provide when you
                register as a user is true, accurate, current and complete in
                all respects; and
              </p>
              <p>
                You will notify us immediately of any changes to the Personal
                Data by updating these details on your online account.
              </p>
              <p>
                You agree not to impersonate any other person or entity or to
                use a false name or a name that you are not authorised to use.
              </p>
              <h2>6. Username and Passwords</h2>
              <p>
                During the registration process you may be allocated, or invited
                to choose, your own username and password which will be unique
                to you. You are responsible for keeping your username and
                password confidential and for ensuring that they are not used by
                any other person.
              </p>
              <h2>7. Ownership of Materials and License Terms</h2>
              <p>
                This website, the materials and software on it, or provided to
                you through it are protected by copyright, trademark and other
                intellectual property rights and laws throughout the world and
                are owned by, or are licensed to, Link Pharmacy and/or third
                parties. You are permitted to display the materials on this
                website on a computer screen and, save for restricted access
                documents, to download and print a hard copy for your personal
                use or for obtaining products or services from us, provided you
                do not alter or remove any of the content or any part of the
                website without our express permission to do so and that you do
                not change or delete any copyright, trade mark or other
                proprietary notices.
              </p>
              <p>
                <strong>You agree not to:</strong>
              </p>
              <p>
                Copy, reproduce, store (in any medium or format), distribute,
                transmit, modify, create derivative works from all or any part
                of this website or the materials or software on it, or provided
                to you through it without our prior written consent (which may
                be given or withheld in our absolute discretion)
              </p>
              <p>
                Use this website or any of the materials or software on it, or
                provided to you through it, for:
              </p>
              <p>
                a. Any unlawful purpose or in contravention of applicable law;
              </p>
              <p>
                b. Commercial exploitation without our prior written consent.
              </p>
              <p>
                c. Any purpose or in any manner that may give a false or
                misleading impression of us, our staff or our services
              </p>
              <p>
                <strong>Use, Upload or Transmit:</strong>
              </p>
              <p>
                d. Any material that is defamatory, offensive, obscene or
                otherwise unlawful, or which may cause offence or distress or
                which may affect or infringe the rights of any other person;
              </p>
              <p>
                e. Any device, software, file or mechanism which may interfere
                with the proper operation of this website or our systems;
              </p>
              <p>
                f. Establish a link to this website from any other website,
                intranet or extranet site without our prior written consent;
              </p>
              <p>
                g. Decompile, disassemble or reverse engineer (or attempt to do
                any of them) any of the software or other materials provided on
                or through this website;
              </p>
              <p>
                h. Do anything that may interfere with or disrupt this website
                or our service;
              </p>
              <p>i. Encourage or permit others to do any of the above;</p>
              <p>
                In the event that you do not comply with the above restrictions,
                any person affected by your actions may bring a claim against
                you and/or Link Pharmacy. We will pursue a claim against you for
                any losses and costs (including legal costs) we may suffer as a
                result of your actions.
              </p>
              <h2>8. Suitability of Materials</h2>
              <p>
                We do not give any assurance that the materials provided or
                available to you on or through this website are suitable for
                your requirements or that they will be secure, error or virus
                free and we will have no liability in respect of those
                materials.
              </p>
              <h2>9. Website Availability</h2>
              <p>
                This website is provided free of charge and we make no guarantee
                that it will be uninterrupted or error free. We reserve the
                right to modify, suspend or withdraw the whole or any part of
                the website or any of its content at any time without notice and
                without incurring any liability.
              </p>
              <h2>10. Links from this Website</h2>
              <p>
                To provide increased value to our Users, we may provide links to
                other websites or resources for you to access at your sole
                discretion. You acknowledge and agree that, as you have chosen
                to enter the linked website, we are not responsible for the
                availability of such external sites or resources, and do not
                review or endorse and are not responsible or liable, directly or
                indirectly, for (i) the privacy practices of such websites, (ii)
                the content of such websites, including (without limitation) any
                advertising, content, products, goods or other materials or
                services on or available from such websites or resources or
                (iii) the use to which others make of these websites or
                resources, nor for any damage, loss or offence caused or alleged
                to be caused by, or in connection with, the use of or reliance
                on any such advertising, content, products, goods or other
                materials or services available on such external websites or
                resources.
              </p>
              <h2>11. Monitoring</h2>
              <p>
                We may monitor activity and content on this website and may take
                any action we consider appropriate if we suspect you may be in
                breach of these Terms and Conditions including suspending,
                attaching conditions to or terminating your access and/or
                notifying the authorities or relevant regulators of your
                activities.
              </p>
              <h2>12. Security</h2>
              <p>
                We employ security technology as detailed in our privacy notice,
                however, Internet transmissions are never completely private or
                secure and there is a risk, therefore, that any message or
                information you send to us from this website may be intercepted
                and potentially read by others. We will have no liability in
                respect of any transmissions you send to us and you do so
                entirely at your own risk.
              </p>
              <h2>13. Accuracy of Medical Information</h2>
              <p>
                We take care to ensure that all information available on our
                website about our business, services and any products mentioned
                is accurate. However, these are continually developing and,
                occasionally, the information may be out of date. Medical,
                commercial and legal practice change frequently and the content
                on this website, in any newsletters and in other items offering
                guidance have been prepared for general interest only and are
                not a substitute for specific medical, legal or other
                professional advice and should not be read or used as such. For
                accurate up-to-date information you should contact us and/or
                your GP directly.
              </p>
              <h2>14. Disclaimer</h2>
              <p>
                Link Pharmacy does not warrant or represent that the material on
                this website is accurate, complete or current or that the
                website will be free of defects or viruses.
              </p>
              <p>
                Nothing contained in the pages of this website should be
                construed as medical, commercial, legal or other professional
                advice. Detailed professional advice should be obtained before
                taking or refraining from any action based on any of the
                information or material contained in this website or any
                communications provided to you as a result of your registration.
              </p>
              <h2>15. Use of this Website Outside the Republic of Zambia</h2>
              <p>
                Link Pharmacy makes no claims or representations that any or all
                of the content of this website may be lawfully viewed or
                downloaded outside the Republic of Zambia and, unless otherwise
                specifically stated, the content and this website is directed
                solely at users who access this website from the Republic of
                Zambia.
              </p>
              <p>
                If you choose to access the website from outside the Republic of
                Zambia, you do so at your own risk and are responsible for
                compliance with the laws of your jurisdiction.
              </p>
              <h2>16. Liability for Our Mistakes</h2>
              <p>
                We will not be liable for any loss or damage (in contract,
                negligence or otherwise) where:
              </p>
              <p>
                (a) there is no breach of a legal duty of care owed to you by
                us;
              </p>
              <p>
                (b) the loss or damage is not a reasonably foreseeable result of
                any such breach; or
              </p>
              <p>
                (c) any loss or damage or increase in loss or damage results
                from a breach by you of these terms and conditions.
              </p>
              <p>
                Nothing in these terms and conditions excludes or limits our
                liability for fraudulent misrepresentation or for death or
                personal injury caused by our negligence. Nothing in these terms
                and conditions shall affect your statutory rights.
              </p>
              <h2>17. Third Party Rights</h2>
              <p>
                Nothing in these Terms and Conditions is intended to nor shall
                it confer a benefit on any third party and a person who is not a
                party to these Terms and Conditions has no rights to enforce
                them.
              </p>
              <h2>18. Waiver</h2>
              <p>
                No delay or decision not to enforce rights under these Terms and
                Conditions will constitute a waiver of the right to do so and
                will not affect rights in relation to any subsequent breach.
              </p>
              <h2>19. Governing Law</h2>
              <p>
                These Terms and Conditions are subject to the laws of the
                Republic of Zambia and each of us hereby submits to the
                non-exclusive jurisdiction of the courts.
              </p>
              <p>
                We reserve the right to change these terms and conditions at any
                time. The new version will be posted on this website and will
                take effect immediately upon posting.
              </p>
              <p>
                If you use the website after the new terms and conditions have
                come into effect, you will be indicating your agreement to be
                bound by the new terms and conditions.
              </p>
              <h2>20. Online Pharmacy (Prescription Ordering)</h2>
              <p>
                The following terms apply to your use of the Link Pharmacy
                prescription ordering service. These are additional to and
                supplement our general website terms and conditions which also
                apply.
              </p>
              <p>
                If you have any questions concerning this service, please
                contact us.
              </p>
              <h2>21. Register</h2>
              <p>
                Registration to use our web services will take place on the Link
                Pharmacy’s website{" "}
                <a href="https://www.linkpharmacy.co.zm">
                  www.linkpharmacy.co.zm
                </a>
                .
              </p>
              <h2>22. Prescriptions for Persons Other than Yourself</h2>
              <p>
                If the prescription is for a person other than yourself, you
                will need to have the authority of the person whose prescription
                it is to have it dispensed by us. By placing an order to
                dispense another person’s prescription you confirm that you have
                their authority to do so.
              </p>
              <h2>23. Age Restrictions</h2>
              <p>
                We only accept prescription orders from users who are 18 years
                old or over although they may order prescriptions for persons
                who are under 18. By placing an order, you confirm that you are
                at least 18 years of age.
              </p>
              <h2>24. Residency</h2>
              <p>
                We only accept prescription orders from and dispatch
                prescriptions to addresses in the Republic of Zambia. Whilst we
                are happy to dispense prescriptions to users outside of Zambia,
                we currently do not have objectives to do so.
              </p>
              <h2>25. Call Charges</h2>
              <p>
                Calls to our customer care line are charged at local rates. Your
                mobile operator&#39;s normal charges will apply to calls from
                mobiles.
              </p>
              <h2>26. Binding Agreement</h2>
              <p>
                No Prescription Order placed by you will be binding on us until
                we have confirmed the order to you by email or in writing. We
                reserve the right to reject any order.
              </p>
              <h2>27. Verification of Prescription</h2>
              <p>
                When we receive a prescription from you we will verify it
                against the information provided to us at the time the order was
                placed. In the event that the information does not match with
                your original order, we may try to contact you using the
                information in your registration. If we cannot contact you and
                we are unable to dispense the item(s) on your Prescription Order
                we reserve the right to return your prescription to you.
              </p>
              <h2>28. Prescriptions Charges</h2>
              <p>
                For Medical prescriptions, you will be required to confirm
                eligibility. It is your responsibility to give accurate
                information and to not make a false declaration.
              </p>
              <h2>29. Postage and Packaging Costs</h2>
              <p>
                Medical prescriptions are delivered by courier agency accredited
                by Link Pharmacy for a fee. Delivery of any order regardless of
                weight within Lusaka (Capital City) is K50. For delivery of your
                packages or orders outside of Lusaka, see the table below.
              </p>
              <Table style={{ maxWidth: "500px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Weight (KG)</TableCell>
                    <TableCell>Rate (ZMW)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shippingRates.map((rate) => (
                    <TableRow key={rate.weight}>
                      <TableCell size={"small"}>{rate.weight}</TableCell>
                      <TableCell size={"small"}>{rate.rate}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell size={"small"}>Additional 0.5KG</TableCell>
                    <TableCell size={"small"}>17.76</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <p>
                Postage and packing costs will be added to the cost of your
                order and will depend on the total cost of the items you order
                and, where delivery options are provided, the option you choose.
              </p>
              <p>
                In providing payment card details, you confirm that you are
                authorised to use the card and authorise us to take payment in
                full for the applicable prescription charges, postage and
                packing charges and any other charges that become due to us
                under these terms.
              </p>
              <h2>30. Non-Receipt of Prescription</h2>
              <p>
                If you place a prescription order and we do not receive the
                relevant prescription, we will not have any liability to you and
                it will be your responsibility to contact us within 20 days
                regarding your prescription order.
              </p>
              <h2>31. Non-Availability/Suitability of Products</h2>
              <p>
                If any of the items on your prescription is not available, or is
                not suitable for dispensing through this service, we will try to
                contact you using the information in your registration and refer
                you accordingly.
              </p>
              <h2>32. Notification of Errors</h2>
              <p>
                You should check the items dispensed to you carefully and
                promptly upon receipt. If you believe there may have been a
                dispensing error, you should contact us immediately and should
                not take or use any of the items.
              </p>
              <h2>33. Warning</h2>
              <p>
                You must check all items dispensed to you and should not take
                any medication that appears to have been tampered with or which
                may have been dispensed in error.
              </p>
              <h2>34. Merchandising - Purchase of Goods</h2>
              <p>
                The following terms apply to the purchase of goods and services
                from Link Pharmacy. These are additional to and supplement our
                general website terms and conditions which also apply. These
                terms and conditions may be amended at any time and the terms
                and conditions that apply for each order will be those on the
                website at the time you place your order.
              </p>
              <p>
                If you have any questions concerning the service, please contact
                us. These terms and conditions contain important information
                about your rights and are legally binding. You should print and
                keep a copy of them for your records.
              </p>
              <h2>35. Placing an Order</h2>
              <p>
                It is your responsibility to check that your order is accurate
                before submitting it to us.
              </p>
              <h2>36. Pricing</h2>
              <p>
                All prices on this website are in Zambian kwacha and include VAT
                unless expressly stated otherwise. We take care to ensure that
                all pricing information on our website is accurate but
                occasionally there may be an error. Online pricing and
                promotions may differ to in-store and will be marked as online
                exclusive.
              </p>
              <h2>37. Returns, Refunds and Exchanges</h2>
              <p>
                We hope you will be happy with any items you’ve ordered from us,
                but if you’re not entirely satisfied, you’ll find details of our
                returns policy here and below. We strongly advise all our
                clients to thoroughly check their products before making their
                purchase and before leaving the store to ensure that they have
                the right products. We accept return and refund requests for
                products purchased in store and online.
              </p>
              <p>
                All items subject to this policy must be returned in its
                original packaging and in an unused condition. All seals must be
                in place. The Original receipt must be produced as proof of
                purchase from the store of all items being returned or
                exchanged. Return and exchange requests have to be made within 7
                days of purchase. If 7 days have gone by since your purchase,
                unfortunately we cannot offer you a refund or exchange.All
                returns, refunds and exchanges can only be processed in store.
                Please do not send your purchase back to the manufacturer.
              </p>
              <p>
                Once your return is received and inspected, our store manager
                will submit the item to our headquarters whereby it will be
                determined if your refund has been approved or rejected, based
                on our company policy. Returned items will undergo a strict
                process of assessment of its condition before a refund or
                exchange will take place. If your refund is approved, it will be
                processed, and a credit will automatically be applied to your
                credit card or original method of payment, within 5-10 business
                days. Accepted returns will be processed and paid via the
                original payment platform or to the card originally used for
                payment.
              </p>
              <p>
                Note that, if you encounter delay in receiving your refund
                within the above provided period, it may be an issue from your
                card issuer or bank, over which we have no control.
              </p>
              <p>
                Contact our customer care line along with a proof of purchase to
                request your refund.
              </p>
              <h2>38. Refund, Return &amp; Exchange Exclusions</h2>
              <p>
                Unfortunately, we do not under any condition accept return and
                refund requests on medication and prescription items or any item
                that has a seal because there is no mechanism to confirm the
                items have been handled or stored according to specified
                instructions.
              </p>
              <p>
                Several types of goods are also exempt from being returned.
                Perishable goods such as food, newspapers or magazines cannot be
                returned. We also do not accept sanitary or intimate products,
                hazardous materials, or flammable liquids. Additional
                non-returnable items include gift cards and some health and
                personal care items.
              </p>
              <p>
                Only regular priced items may be refunded, unfortunately sale
                items cannot be refunded.
              </p>
              <p>
                We only replace items if they are defective or damaged at the
                time of purchase or if they were sent in error by staff. Items
                that are unavailable for replacement will be automatically
                refunded.
              </p>
              <h2>39. Cancellation of Orders</h2>
              <p>
                Once you make a payment through your credit or debit card, you
                no longer can cancel the order. Please contact our customer care
                line and we will advise you on how to proceed.
              </p>
              <p>Special orders</p>
              <p>
                Requests for special orders made by you from us will be deemed
                valid after 50% of the total cost has been paid. You agree to
                bear any cost that may arise from the price change and quantity
                change in accordance with regulation of Zambia. We will not be
                responsible for refunds of the 50% payment made prior to your
                order in a situation where you cancel the order.
              </p>
              <h2>40. Accuracy of Product Information</h2>
              <p>
                We try to ensure that all information and colours are correct at
                the time of their publication. However, the representation of
                colours on your browser may differ from the actual colours of
                products supplied. Any typographical, clerical or other error is
                subject to correction without any liability on our part. Any
                advice or recommendation given on this website or otherwise
                given to you by us, or any of our employees or agents, is
                followed or acted upon entirely at your own risk and we are not
                liable for such advice or recommendation.
              </p>
              <h2>41. Concluding a Binding Contract</h2>
              <p>
                When we receive an order from you to purchase items from us, we
                will confirm that we have received the order by email to the
                address provided by you in your order. Your order is an offer to
                purchase an item and will not be binding on us until you have
                received an email to confirm that we have dispatched that item
                to you.
              </p>
              <p>
                Our professional codes of conduct and legal restrictions may
                limit the number of any item we are permitted to supply to you
                and, as such, we have no obligation to supply quantities
                exceeding the relevant permitted maximum and will charge only
                for those we do supply. We reserve the right to reject any
                order.
              </p>
              <p>
                The postage and packaging charge you will pay will be displayed
                on screen before you confirm your order to us. Where multiple
                items are included in one order, we will attempt to deliver your
                items together.
              </p>
              <h2>42. Order Delivery</h2>
              <p>
                Pharmacy Medicines will need to be signed for by someone aged 18
                or over. If there isn&#39;t anyone in who can sign for your
                order, kindly contact us to arrange a suitable delivery time and
                date.
              </p>
              <p>
                Unfortunately, if your order can&#39;t be left in a safe place
                or with a neighbour. The courier may attempt to deliver your
                parcel twice, before contacting you to arrange for collection
                from their depot or return it to us.
              </p>
              <h2>43. Payment</h2>
              <p>
                In providing payment card details, you confirm that you are
                authorised to use the card and authorise us or our payment
                service provider to take payment in full for the items in your
                order, postage and packing charges and any other charges that
                become due to us under these terms.
              </p>
              <p>
                Our payment service providers are secure and easy to use for
                making payment, verification and authentication. We accept
                purchase payments via:{" "}
                <strong>
                  VISA, MasterCard, Airtel Mobile Money, MTN Mobile Money,
                  Zamtel Mobile Money and PayPal.
                </strong>
              </p>
              <p>
                Refunds, if applicable, will only be made to using the payment
                gateway and/or card originally used for payment.
              </p>
              <h2>44. In-store</h2>
              <p>
                When making payments at our store, you may be asked to insert
                your card and key in your PIN. If you are using chip and PIN,
                this type of payments will remain the same. When shopping with
                us, please make sure that you know your PIN code or have an
                alternative payment method available so you can continue with
                your transaction.
              </p>
              <h2>45. Non-Availability/Substitutes</h2>
              <p>
                We reserve the right to change the specification of items which
                are required to conform to any applicable standards or Zambian
                Law requirements. We try to have sufficient stocks of goods to
                meet demand but, occasionally, items may be out of stock or
                unavailable from our suppliers. All items sold through this
                website are subject to availability. Medication will be
                dispensed as per prescription submitted. If any of the items on
                your order is not available, we may send a substitute of similar
                specification.
              </p>
              <h2>46. Liability</h2>
              <p>
                Our maximum liability for our failure to fulfill an order that
                we are legally bound to fulfill will be limited to the price
                paid by you for that order.
              </p>
              <h2>47. Complaints</h2>
              <p>
                If you have any complaint with any aspect of our services, we
                enjoin you to raise this in the first instance through our
                customer care channel
              </p>
              <h2>48. Force Majeure</h2>
              <p>
                Neither party shall be entitled to damages from the other party,
                or to terminate this Agreement where the other party acts in
                default or material breach of this agreement where that default
                or breach was caused by conditions or events beyond its control
                including, but not limited to, strike, lockout or other labour
                dispute affecting the employees of Link Pharmacy, acts of God,
                natural disasters, acts of war or terrorism, power or utilities
                supply failure, act or omission of government, highway
                authorities or telecommunications carrier, operator or
                administrator, delay in manufacture, production or supply by
                third parties of equipment or services required for the
                performance of the services or production and supply of the
                goods;
              </p>
              <p>
                The party in breach of default shall be entitled to a reasonable
                extension of time to perform its obligations under this
                Agreement after notifying the other party.
              </p>
              <h2>49. Assignment</h2>
              <p>
                Link Pharmacy may assign its obligations under this Agreement to
                a third party without the user’s consent.
              </p>
              <p>
                Nothing in the preceding sub-clause shall, however, prevent the
                user from enforcing its rights under this Agreement against Link
                Pharmacy.
              </p>
              <p>
                The User may not assign any of its rights or obligations under
                this Agreement without the written consent of a Director of Link
                Pharmacy.
              </p>
              <h2>50. Severability</h2>
              <p>
                If any provision or provisions of this Agreement shall be held
                to be invalid, illegal, unenforceable or in conflict with the
                law of any jurisdiction, the validity, legality and
                enforceability of the remaining provisions shall not in any way
                be affected or impaired thereby.
              </p>
              <h2>51. Miscellaneous</h2>
              <p>
                You acknowledge and agree that this Agreement constitutes the
                complete and exclusive agreement between you and Link pharmacy
                concerning your use of the website and supersedes and governs
                all prior proposals, agreements or other communications.
              </p>
              <p>
                Nothing contained in this agreement can be construed as creating
                any agency, partnership or other form of joint enterprise
                between us. Our failure to require your performance of any
                provision hereof will not affect our full right to require such
                performance at any time thereafter, nor may our waiver of a
                breach of any provision hereof be taken or held to be a waiver
                of the provision itself. You may not assign any rights granted
                to you hereunder, and any such attempts are void.
              </p>
              <h2>52. Waiver</h2>
              <p>
                The failure of any party at any time to require performance of
                any provision or to resort to any remedy provided under this
                Agreement shall in no way affect the right of that party to
                require performance or to resort to a remedy at any time
                thereafter, nor shall the waiver by any party of a breach be
                deemed to be a waiver of any subsequent breach. A waiver shall
                not be effective unless it is in writing and signed by the party
                against whom the waiver is being enforced.
              </p>
              <h2>53. Notices</h2>
              <p>
                All notices and other communications provided for in this
                Agreement and any associated document shall be in writing and
                shall be delivered by post, fax, email or hand to an authorised
                representative, to the address, fax or email detailed on the
                website. Any notices served shall be deemed to be effective on
                actual receipt by the receiving Party, who shall acknowledge
                receipt within two working days of the date of receipt.
              </p>
              <h2>54. Applicable Law and Venue</h2>
              <p>
                This Agreement and the relationship between you and us are
                governed according to the laws of the Republic of Zambia,
                without regard to the conflicts of law provisions thereof. Any
                disputes must be brought exclusively in the state or federal
                courts located in Zambia, and the parties hereby consent to the
                jurisdiction of such courts.
              </p>
              <h2>55. Entire Agreement</h2>
              <p>
                This Agreement constitutes the entire agreement of the parties
                and supersedes all prior communications, understandings and
                agreements relating to the subject matter hereof, whether oral
                or written.
              </p>

              <h2>56. Changes or Updates</h2>
              <p>
                It is in our absolute rights to modify, change, alter, delete or
                review this Agreement at any given point in time without
                recourse to your consent. Such updates will be effective
                immediately we post it on the website.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TermsOfUse;
