import styles from "../styles/views/GiftCards.module.css";
import LinkLogo from "../assets/images/logo-green.svg";

const GiftCards = () => {
  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b px-4 py-3">
        <h2 className="m-0 font-opensans-medium text-2xl">
          Gift Cards & Vouchers
        </h2>
      </div>

      <div className="p-6 overflow-y-hidden overflow-x-auto whitespace-nowrap pb-4">
        <div className={styles.giftCardItem}>
          <div className={styles.card}>
            <img src={LinkLogo} alt="" />
            <div className={styles.strip} />
            <div className={styles.amountSection}>
              <span className={styles.currentValue}>CURRENT VALUE</span>
              <span>
                K<strong>0.00</strong>
              </span>
            </div>
          </div>
          <div className={styles.cardInfo}>
            <span className={styles.redeemedAt}>Redeemed on 21 Oct, 2021</span>
            <hr />

            <div className={styles.value}>
              <h5>Original Value</h5>
              <span>
                K<strong>5.00</strong>
              </span>
            </div>

            <div className={styles.value}>
              <h5>Voucher Code</h5>
              <span>HSKFAJHDUSI</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCards;
