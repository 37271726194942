// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

import SpecialtySideBar from "../../components/SpecialtySideBar";
import style from "../../styles/views/SupportAndServices.module.css";
import care_giver from "../../assets/images/care_giver.jpg";

const SupportAndServices = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="row">
          <div className="clo-md-12">
            <div className={style.s_nav}>
              <a href="/">Home</a>
              <span>&#10095;</span>
              <a href="/pharmacy-services">Pharmacy & Health</a>
              <span>&#10095;</span>
              <a href="/specialty-pharmacy">Specialty Pharmacy</a>
              <h1>Our Support and Services</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <SpecialtySideBar />
            </div>
            <div className="col-md-9">
              <div className={style.s_flex}>
                <div className={style.s_flex_img}>
                  <img src={care_giver} alt="" />
                </div>
                <div className={style.s_flex_cont}>
                  <h2>Expert care and specialized support</h2>
                </div>
              </div>
              <div className={style.s_cont}>
                <br />
                <p>
                  We're here to help you with your specialty medication and
                  provide ongoing, individualized support.
                </p>
                <h3>
                  Ongoing support from the link Pharmacy Specialty Pharmacy Care
                  Team
                </h3>
                <p>
                  We offer customized programs to help you manage your
                  condition. Our experienced, specially trained pharmacists,
                  nurses and care coordinators understand the complexities of
                  your condition and are available to offer one-on-one support
                  including:
                </p>
                <ul>
                  <li>Injection training coordination</li>
                  <li>Medication side effect management</li>
                  <li>
                    Monitoring your symptoms and response to your medication
                  </li>
                  <li>
                    Working closely with your doctor to monitor your progress
                  </li>
                  <li>Assisting when you need a new prescription or refill</li>
                </ul>
                <h5>Insurance coordination</h5>
                <p>
                  Most specialty prescriptions require a prior authorization.
                  Our expert insurance specialists coordinate with your doctor
                  and insurance company to facilitate this process. We will
                  conduct a complete review of benefits with your primary and
                  any secondary insurance providers.
                </p>
                <h3>Get started</h3>
                <p>
                  Call us today at +260 211 255 556. Our expert care team will
                  direct you to the pharmacy location that best meets your
                  needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default SupportAndServices;
