import style from "../../styles/views/PharmacyInsurance.module.css";
// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

const PharmacyInsurance = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="row">
        <div className="col-md-12">
          <div className={style.p_bg}>
            <div className={style.p_header}>
              <div className="container">
                <h1>Insurance Coverage</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className={style.p_cont}>
                  <h4>Insurance we cover</h4>
                  <p>
                    Link Pharmacy offers insurance products that can be tailor
                    made. We also offer managed fund care administration
                    schemes.
                  </p>
                  <h4>Insurance Schemes</h4>
                  <p>
                    Link Pharmacy insurance covers international evacuation,
                    repatriation and overseas travel insurance. Our products
                    offer specified benefits for each member that cover the
                    medical expenses of members incurred within Zambia.
                  </p>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default PharmacyInsurance;
