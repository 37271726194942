import { classNames } from "../utils/utils";

const Input = ({
  className,
  icon,
  leftIcon,
  placeholder,
  margin,
  width,
  bgColor,
  color,
  ...props
}) => {
  return (
    <p
      className={classNames(
        "rounded-md flex items-center px-3 py-2 mb-0 border border-stone-200",
        className ? className : "",
        bgColor ? bgColor : "bg-stone-600",
        width ? width : "w-full",
        margin ? margin : ""
      )}
    >
      {leftIcon && leftIcon}
      <input
        className={classNames(
          "w-full flex flex-1 text-base md:text-lg font-opensans placeholder-stone-400 text-stone-700 border-none p-0 m-0 outline-none focus:border-none focus:outline-none active:outline-none",
          className ? className : "",
          bgColor ? bgColor : "bg-stone-600",
          color ? color : "text-stone-900"
        )}
        placeholder={placeholder}
        {...props}
      />
      {icon && icon}
    </p>
  );
};

export default Input;
