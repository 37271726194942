import Dialog from "@mui/material/Dialog/Dialog";
import React from "react";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "../../../components/CustomComponents";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const PrescriptionsGuidelinesDialog = ({ isOpen, onClose }: Props) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>
        <h2 className="m-0 font-opensans-bold">Prescription Submission Guidelines</h2>
      </DialogTitle>
      <DialogContent>
        <p className="font-opensans leading-normal text-sm">
          This product is classified as a <strong className="font-opensans-semibold">prescription-only item</strong>.
          During the checkout process, you will be asked to upload a valid prescription. Orders lacking a valid
          prescription or containing an invalid one may be declined.
        </p>

        <h4 className={"font-opensans-semibold leading-normal mt-4 mb-2"}>Prescription Upload Guide</h4>
        <p className="font-opensans leading-normal text-sm">
          To successfully upload your prescription, please ensure the following details are clearly visible:
        </p>

        <ol className={"font-opensans leading-normal text-sm list-decimal list-inside mt-2 mb-3"}>
          <li>Name of Hospital / Clinic.</li>
          <li>Date the prescription was issued.</li>
          <li>Patient details: name, date of birth, age, sex, and address.</li>
          <li>Medication details: name, strength, dosage, frequency, and formulation.</li>
          <li>Quantity to be dispensed.</li>
          <li>Prescribing doctor's details: name, signature, and HPCZ registration number.</li>
          <li>Prescription date stamp.</li>
        </ol>

        <p className="font-opensans leading-normal text-sm">
          We recommend avoiding blurred or cropped images to prevent any delays or cancellations of your order.
        </p>

        <h4 className={"font-opensans-semibold leading-normal mt-4 mb-2"}>Supported File Types and Size</h4>

        <p className="font-opensans leading-normal text-sm mb-2">
          The prescription can be uploaded as a .jpeg, .jpg, .png, or .pdf file. Please ensure that the file size does
          not exceed 2MB.
        </p>

        <p className="font-opensans leading-normal text-sm">
          By proceeding with your purchase, you acknowledge and accept these terms. If you have any questions or need
          further assistance, don't hesitate to contact our customer service. We appreciate your understanding and
          cooperation.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} aria-label="Close guidelines dialog">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrescriptionsGuidelinesDialog;
