import React from "react";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import useFormErrors from "../../../hooks/useFormErrors";

interface Props {
  label?: string;
  adornment?: string | React.ReactNode;
  name?: string;
  placeholder?: string;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  touched?: boolean | FormikTouched<FormikValues> | FormikTouched<FormikValues[string][number]>[];
  required?: boolean;
}
const TextArea = ({
  label,
  adornment,
  name,
  placeholder,
  id,
  value,
  onBlur,
  onChange,
  rows,
  error,
  touched,
  required,
}: Props) => {
  const { displayErrors } = useFormErrors();
  const errorId = `error-${id}`;

  return (
    <div className="flex flex-col items-start w-full">
      {label && (
        <label htmlFor={id || label} className="block text-sm font-opensans-medium leading-6 text-gray-800 mb-1">
          {label} {required && <span className="text-link-green-1 text-base leading-sm font-opensans-bold">*</span>}
        </label>
      )}
      <div className="relative rounded-sm shadow-xs w-full">
        {adornment && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">{adornment}</span>
          </div>
        )}
        <textarea
          name={name}
          id={id || label}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-link-green sm:text-sm sm:leading-6"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          rows={rows}
          aria-labelledby={id || label} // add aria-labelledby for assistive technologies
          aria-required={required} // denote if the field is required
          aria-invalid={!!error && touched ? "true" : "false"} // denote if the field is in an error state
          aria-describedby={errorId} // link to the error message
        />
        {error && touched && <div id={errorId}>{displayErrors(error)}</div>}
      </div>
    </div>
  );
};

export default TextArea;
