import { useState } from "react";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useCart } from "../context/cartContext";

const AddToCart = ({ cart, rounded, disabled, callBack, customStyles }) => {
  const [quantity, setQuantity] = useState(1);
  const { addToCart, addLoading } = useCart();

  return (
    <>
      {rounded === true && (
        <div
          className="w-fit text-base font-opensans-medium p-2 hover:shadow-lg bg-link-green-1 text-white rounded-md"
          onClick={() => addToCart(cart)}
        >
          Add to cart
        </div>
      )}

      {rounded === false && (
        <button
          className="text-sm font-opensans-semibold py-2 px-3 hover:opacity-90 bg-link-green-1 text-white rounded"
          onClick={() => addToCart(cart, callBack)}
          disabled={disabled}
          style={customStyles}
        >
          {addLoading.includes(cart.id) ? (
            <>
              <FontAwesomeIcon icon={faCircleNotch} style={{ marginRight: "10px" }} className={"fa fa-spin"} /> Adding
            </>
          ) : (
            "Add To Cart"
          )}
        </button>
      )}

      {rounded === "split rounded" && (
        <div className="flex">
          <select
            onChange={(e) => setQuantity(Number(e.target.value))}
            className="w-12 text-white bg-link-green-1 text-sm rounded-tl-3xl rounded-bl-3xl p-2 border-0"
            disabled={addLoading.includes(cart.id)}
          >
            {[...Array(10).keys()].map((i) => {
              if (cart.quantityOnHand && cart.quantityOnHand < i + 1) {
                return null;
              }

              return (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              );
            })}
          </select>

          <button
            className="bg-link-green-1 cursor-pointer text-white ml-px font-opensans-medium rounded-tr-3xl rounded-br-3xl py-2 px-3"
            disabled={addLoading.includes(cart.id)}
            onClick={() =>
              addToCart({
                id: cart.id,
                name: cart.name,
                price: cart.price,
                med_variation_id: cart.med_variation_id || null,
                prescription_id: cart.prescription_id || null,
                thumbnail: cart.thumbnail,
                queryId: cart.queryId || undefined,
                quantity: quantity,
                quantityOnHand: cart.quantityOnHand || 0,
              })
            }
          >
            {addLoading.includes(cart.id) ? (
              <>
                <FontAwesomeIcon icon={faCircleNotch} className="fa fa-spin" /> Adding
              </>
            ) : (
              "Add to cart"
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default AddToCart;
