import { Link } from "react-router-dom";
import {
  ChevronRight,
  Check,
  Person,
  LocalShipping,
  DirectionsWalk,
} from "@mui/icons-material";

// Components
import Layout from "../Layout/Layout";
import { meta } from "../../globals";
import styles from "../../styles/views/OrderStatus.module.css";

const OrderStatus = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div
        className={`container-lg container-md container-sm container p-4 ${styles.orderStatus}`}
      >
        <div className={"row-12"}>
          <p style={{ fontSize: "14px" }}>
            <Link to={"/home"}>Home</Link>
            <ChevronRight style={{ color: "gray", transform: "scale(1.1)" }} />
            <Link to={"/account"}>Your Account</Link>
          </p>
          <h3 style={{ marginTop: "-10px" }}>Order Status</h3>
        </div>

        <div>
          <div className={"card-header"}>
            <p className={`font-weight-bold ${styles.id}`}>
              Order # : OD45345345435
            </p>
          </div>
          <div className={"card-body"}>
            <div className={"row"}>
              <div className={"col-lg-3"}>
                <h6 className={"font-weight-bold text-dark"}>
                  Estimated Delivery Time:
                </h6>
                <p>29 Nov 2021</p>
              </div>

              <div className={"col-lg-3"}>
                <h6 className={"font-weight-bold text-dark"}>Shipping By:</h6>
                <p>BLUEDART +1598675986</p>
              </div>

              <div className={"col-lg-3"}>
                <h6 className={"font-weight-bold text-dark"}>Status:</h6>
                <p>Picked By Courier</p>
              </div>

              <div className={"col-lg-3"}>
                <h6 className={"font-weight-bold text-dark"}>Tracking #:</h6>
                <p>BD045903594059</p>
              </div>
            </div>

            <div>
              <p className={"mb-3 mt-3"}>
                <span
                  className={styles.icons}
                  style={{ backgroundColor: "#c4d600" }}
                >
                  <Check style={{ color: "#fff" }} />
                </span>{" "}
                Order Confirmed
              </p>

              <p className={"mb-3"}>
                <span className={styles.icons}>
                  <Person />
                </span>{" "}
                Picked By Courier
              </p>

              <p className={"mb-3"}>
                <span className={styles.icons}>
                  <LocalShipping />
                </span>{" "}
                On The Way
              </p>

              <p className={"mb-3"}>
                <span className={styles.icons}>
                  <DirectionsWalk />
                </span>{" "}
                Ready For Pickup
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OrderStatus;
