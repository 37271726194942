const MedicationReminders = () => {
  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b p-2">
        <h2 className="m-0 text-md text-stone-600">My Orders</h2>
      </div>

      <div className="p-6 overflow-y-hidden overflow-x-auto whitespace-nowrap pb-4">
        <p>Download the Linkpharmacy app for this feature</p>
      </div>
    </div>
  );
};

export default MedicationReminders;
