import HelpCenterSidebar from "../../components/HelpCenterSidebar";
import { VolunteerActivism, VerifiedUser, Wysiwyg } from "@mui/icons-material";
// Components
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/OnlinePrivacy.module.css";

const OnlinePolicy = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={style.o_nav}>
              <a href="/">Home</a> <span>&#10095;</span>{" "}
              <a href="/help">Help Center</a>
              <h1>Online Privacy and Security Policy</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
              <HelpCenterSidebar />
            </div>
            <div className="col-md-8">
              <div className={style.o_header}>
                <h2>Health and privacy. We protect both.</h2>
              </div>
              <div className={style.o_trust}>
                <h5>
                  You trust us with your health and wellness needs, and we take
                  that responsibility seriously. That includes making sure your
                  data is safe and secure, and that you have control.
                </h5>
                <div className={style.o_flex}>
                  <div className={style.o_flex_cont}>
                    <VolunteerActivism />
                    <p>
                      <b>Build trust</b>
                    </p>
                    <p>We care for your wellbeing and your needs</p>
                  </div>
                  <div className={style.o_flex_cont}>
                    <VerifiedUser />
                    <p>
                      <b>Protect privacy</b>
                    </p>
                    <p>We don't sell your pharmacy information.</p>
                  </div>
                  <div className={style.o_flex_cont}>
                    <Wysiwyg />
                    <p>
                      <b>Enhance experience</b>
                    </p>
                    <p>
                      We use data to bring you personalized offers and deals.
                    </p>
                  </div>
                </div>
                <p className={style.o_contact}>
                  The Privacy Policy covers the type of data we collect and how
                  we use it, as well as your rights as a consumer. Please don’t
                  hesitate to <a href="/contact">contact us</a> with any
                  questions.
                </p>
                <hr />
              </div>
              <div className={style.p_security}>
                <div className={style.p_header}>
                  <h4>Link Pharmacy Privacy & Security Policy</h4>
                  <p>Last Updated: September 25, 2021</p>
                  <p>
                    This Privacy Policy lets you know how we collect, use,
                    share, and protect information about you. By interacting
                    with Link Pharmacy through our stores, websites, mobile
                    applications, and any other Link Pharmacy affiliate websites
                    where you may interact with Link Pharmacy, you consent to
                    the practices described in this Privacy Policy. Any changes
                    in our Privacy Policy will appear on this page so that you
                    are aware of the data we collect and how we use it. We will
                    notify you of material changes to this Privacy Policy by
                    posting on our websites notification that the Privacy Policy
                    has been revised and by updating the effective date of the
                    Privacy Policy. For California residents, there is an
                    "Information for California Consumers" section in this
                    Privacy Policy.
                  </p>
                  <p>
                    In some circumstances, Link Pharmacy' use of your
                    information will be subject to the requirements of the
                    Health Information Portability and Accountability Act
                    (commonly known as "HIPAA"). For example, if you log in to
                    your pharmacy account and submit information regarding a
                    prescription order, that information is subject to HIPAA's
                    requirements. In those circumstances, the Link Pharmacy
                    Notice of Privacy Practices and not this Privacy Policy will
                    apply. If you have questions about which policy applies to
                    the information you have submitted, please do not hesitate
                    to Contact Us.
                  </p>
                  <h5>Our Privacy Program</h5>
                  <p>
                    At Link Pharmacy, we are committed to maintaining our
                    customers' privacy. We take great care to safeguard the
                    information that we collect to maintain our customers'
                    privacy. We have provided this Privacy Policy to you to
                    describe information collection and use practices at Link
                    Pharmacy. This Privacy Policy describes the choices you can
                    make about the way your information is collected and used.
                  </p>
                  <hr />
                  <h5>Information We Collect</h5>
                  <p>
                    We want you to understand how information you provide to us
                    is collected and used. When you interact with Link Pharmacy
                    or use our services, we collect certain information about
                    you and the services you use. An example of using our
                    "services" would be when you visit one of our stores or
                    websites. We may collect and store information from you in
                    the following situations:
                    <b>
                      Information you provide to us directly, including when
                      you:
                    </b>
                    <ul>
                      <li>
                        Make an in-store or online purchase, or other
                        transaction with us;
                      </li>
                      <li>
                        Create an account on one of our websites or mobile
                        applicatio
                      </li>
                      <li>Participate in our loyalty programs;</li>
                      <li>
                        Participate in a contest, sweepstake, promotion, or
                        survey;
                      </li>
                      <li>
                        Correspond directly with us, such as through our
                        websites, postal mail, customer service, or dispute
                        resolution mechanisms; or
                      </li>
                      <li>
                        Post a review or comment on one of our websites, or post
                        other user-generated content on one of our websites or
                        mobile application
                      </li>
                    </ul>
                    <b>
                      Information we collect when you use our services,
                      including:
                    </b>
                    <ul>
                      <li>
                        We may collect device-specific information when you
                        visit our websites or use our mobile applications or
                        services. This includes information such as Internet
                        Protocol (IP) address, hardware model, operating system,
                        unique device identifiers, mobile network information,
                        location data such as zip code, the address of referring
                        websites, the path you take through our websites, and
                        other information about your session on our websites. We
                        may also associate the information we collect from your
                        different devices, which helps us provide consistent
                        services across your devices.
                      </li>
                    </ul>
                    <b>Log Information</b>
                    <ul>
                      <li>
                        This includes details of how you used our websites or
                        mobile applications including clicks and page
                        information such as the address (or URL) of the website
                        or mobile application you came from before visiting our
                        website or mobile application, which pages you visit on
                        our website or mobile application, which browser you
                        used to view our website or mobile application, and any
                        search terms entered.
                      </li>
                      <li>
                        Other information from your interaction with our
                        websites, services, content and advertising, including
                        computer and connection information, statistics on page
                        views, traffic to and from the websites, ad data and
                        other standard weblog information.
                      </li>
                    </ul>
                    <b>Precise Location Information</b>
                    <ul>
                      <li>
                        When you use our services on your mobile phone or device
                        and enable location services on your mobile phone or
                        device browser, we may collect information about your
                        physical location through satellite, cell phone tower,
                        WiFi signal, beacons, Bluetooth and near field
                        communication protocols ("precise location
                        information"). If you use our mobile application, your
                        device may share precise location information when you
                        enable location services for our application. To learn
                        how to opt-out of sharing your precise location
                        information with Link Pharmacy, go to the "Your Choices"
                        section of this Privacy Policy. When you use our mobile
                        application, we may also request access to your
                        Bluetooth signal from your device. If you enable our
                        mobile application to use your Bluetooth signal, we may
                        be able to determine your device's in-store location. To
                        learn how to opt-out of sharing your device's Bluetooth
                        signal with Link Pharmacy, go to the "Your Choices"
                        section of this Privacy Policy.
                      </li>
                    </ul>
                    <b>In-Store Cameras</b>
                    <ul>
                      <li>
                        We use in-store cameras for security purposes and for
                        operational purposes such as measuring traffic patterns
                        and tracking in-stock levels.
                      </li>
                    </ul>
                  </p>
                  <b>Cookies, Web Beacons and Similar Technologies</b>
                  <ul>
                    <li>
                      "Cookies" are small data files that are sent from a
                      website's server and are stored on your device's hard
                      drive either for only the duration of your visit ("session
                      cookies") or for a fixed period of time ("persistent
                      cookies"). Cookies may store user preferences and other
                      types of information. We use cookies to provide features
                      and services, such as:
                      <ul>
                        <li>
                          Remembering your preferences and allowing you to enter
                          your username less frequently;
                        </li>
                        <li>
                          Presenting information that's targeted to your
                          interests, including Link Pharmacy content presented
                          on another website;
                        </li>
                        <li>
                          Measuring the effectiveness of our websites, services,
                          content and advertising; and
                        </li>
                        <li>
                          Providing other services and features that are
                          available only through the use of cookies.
                        </li>
                      </ul>
                    </li>
                    <li>
                      The Options/Settings section of most internet browsers
                      will tell you how to manage cookies and other technologies
                      that may be transferred to your device, including how to
                      disable these technologies. You can disable our cookies or
                      all cookies through your browser setting, but please note
                      that disabling cookies may impact some of our website's
                      features and prevent the website from operating properly.
                    </li>
                    <li>
                      A "Web Beacon" is an electronic image placed in the code
                      of a webpage, application, or email. We use web beacons to
                      monitor the traffic patterns of users from one page to
                      another and to improve website performance, and in our
                      emails to understand when our email communications are
                      opened or discarde
                    </li>
                  </ul>
                  <b>Flash Cookies</b>
                  <ul>
                    <li>
                      We may use or engage a third party that uses Local Stored
                      Objects, sometimes referred to as "Flash Cookies," and
                      other technologies to collect and store information about
                      the use of our services. A Flash cookie is a small data
                      file placed on your computer or device using Adobe Flash
                      technology. Flash cookies are different from the cookies
                      discussed above because cookie management tools provided
                      by your browser will not remove Flash cookies. To limit
                      the websites that can store information in Flash cookies
                      on your device, you must visit the Adobe website:{" "}
                      <a href="/" target="_blank">
                        https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.
                        html
                      </a>
                    </li>
                  </ul>
                  <b>Information We Collect From Other Sources</b>
                  <p>
                    We may collect information that is publicly available. For
                    example, we may collect information you submit to a blog,
                    chat room, or social network. We also may collect
                    information from other companies, organizations, or
                    third-party partners. For example, we may receive
                    information about you from a partner when we jointly offer
                    services or from an advertiser about your experiences with
                    them. By gathering additional information about you, we can
                    correct inaccurate information and give you product
                    recommendations and special offers more likely to interest
                    you.
                  </p>
                  <hr />
                  <h5>How We Use Your Information</h5>
                  <p>
                    Our primary purpose in collecting information is to provide
                    you with a safe, smooth, efficient, and customized
                    experience. We may use your information in a variety of
                    ways, including for the following purposes:
                  </p>
                  <b>Product and Service Fulfillment</b>
                  <ul>
                    <li>
                      We use your contact information to respond to your
                      inquiries, fulfill your requests and improve your
                      experience.
                    </li>
                  </ul>
                  <b>Our Marketing Purposes</b>
                  <ul>
                    <li>
                      We use your information to deliver coupons, mobile
                      coupons, newsletters, emails, mobile messages, and social
                      media notifications about our brands, products, events or
                      other promotional purposes.
                    </li>
                    <li>
                      We use your information for reporting and analysis
                      purposes. We examine metrics such as how you are shopping
                      on our website, in our stores, and on our mobile
                      applications, the performance of our marketing efforts,
                      and your response to those marketing efforts.
                    </li>
                    <li>
                      We use your information to administer promotions, surveys,
                      and focus groups.
                    </li>
                    <li>
                      We use your information to enable Online Tracking and
                      Interest Based Advertising as further described in this
                      Privacy Policy.
                    </li>
                    <li>
                      We use your information to improve your experiences when
                      you interact with us.
                    </li>
                    <li>
                      We use your Precise Location Information to provide
                      location based services for these marketing purposes. For
                      example, using the Store Locator feature in our mobile
                      application can allow you to quickly find the nearest Link
                      Pharmacy location.
                    </li>
                  </ul>
                  <b>Internal Operations</b>
                  <ul>
                    <li>
                      We use your information to improve the effectiveness of
                      our services, conduct research and analysis, or to perform
                      other business activities as needed.
                    </li>
                  </ul>
                  <b>Prevention of Fraud and other Harm</b>
                  <ul>
                    <li>
                      We use your information to detect, prevent or investigate
                      potential security breaches, fraudulent transactions and
                      monitor against theft.
                    </li>
                  </ul>
                  <b>
                    Legal Compliance and As Necessary or Appropriate Under
                    Applicable Law
                  </b>
                  <ul>
                    <li>
                      We may use your information as necessary or appropriate
                      under applicable law, to comply with legal process, to
                      respond to such requests from public and government
                      authorities (including law enforcement), to enforce our
                      terms and conditions, including investigations of
                      potential violations, to detect, prevent or otherwise
                      address fraud, security or technical issues, to protect
                      our rights, privacy, safety or property, and to allow us
                      to pursue available remedies to limit the damages that we
                      may sustain. In matters involving claims of personal or
                      public safety or in litigation where the data is
                      pertinent, we may use personal information without your
                      consent or court process.
                    </li>
                  </ul>
                  <hr />
                  <h5>How We Share Your Information:</h5>
                  <p>
                    While we do not directly sell, rent, or loan your personally
                    identifiable information, we may share your information with
                    companies, organizations and individuals outside of Link
                    Pharmacy as described below. Please note that certain state
                    laws have adopted a broad definition of a "sale" and may
                    treat certain of these disclosures as sales under their
                    definitions. Please see the "Information for California
                    Consumers" for additional information.
                  </p>
                  <b>Internally</b>
                  <ul>
                    <li>
                      We may share your information with our parent company or
                      affiliates, including but not limited to, Link Pharmacy
                      Boots Alliance Inc., Boots Retail USA Inc., Link Pharmacy
                      Specialty Pharmacy Holdings, LLC, and Link Pharmacy
                      Specialty Pharmacy, LLC DBA Alliance Rx Link Pharmacy
                      Prime.
                    </li>
                  </ul>
                  <b>When We Work With Third Parties</b>
                  <ul>
                    <li>
                      We may share your information with affiliated and
                      unaffiliated companies that perform tasks on our behalf
                      related to our business. Such tasks include analyzing
                      website usage data, customer service, electronic and
                      postal mail service, and social and other media services.
                    </li>
                    <li>
                      We may work with other companies who place cookies, tags,
                      and web beacons on our websites. These companies help
                      operate our websites and provide you with additional
                      products and services.
                    </li>
                    <li>
                      We may also use third-party advertising networks to serve
                      advertisements on our behalf. The cookies received with
                      the banner advertisements served by these networks may be
                      used to collect and build behavioral profiles by these
                      companies to deliver targeted advertisements on our
                      website and unaffiliated websites
                    </li>
                    <li>
                      We may work with other companies who operate their own
                      websites or mobile applications, to allow them to offer
                      Link Pharmacy customers the ability to connect to Link
                      Pharmacy services. These services give you more options
                      for ways to use Link Pharmacy services from websites or
                      applications that are not owned or operated by Link
                      Pharmacy.
                    </li>
                    <li>
                      We contract with partner companies to allow Link Pharmacy
                      customers to access one of their loyalty program accounts
                      from those partners' websites and/or mobile applications.
                      This may include the ability to join a loyalty program,
                      add an existing membership, and/or access your account
                      balance.
                    </li>
                    <li>
                      If you participate in a loyalty program component which
                      permits you to connect your loyalty program accounts with
                      websites, application and devices, including non-Link
                      Pharmacy partners and their websites, application and
                      devices. Notwithstanding the uses and disclosures set
                      forth above, Link Pharmacy may use or disclose personally
                      identifiable information gathered under one of its loyalty
                      programs for improving health, health research, or with
                      your consent.
                    </li>
                    <li>
                      Social Media: Our online services may use social media
                      plugins (e.g., the Facebook "Like" button, "Share to
                      Twitter" button) to enable you to easily interact with
                      certain social media websites (e.g., Facebook, Twitter,
                      Instagram) and share information with others. When you
                      visit our services, the operators of the available social
                      media plugins can place a cookie on your device enabling
                      such operators to recognize individuals who have
                      previously visited our services. If you are logged in to
                      these social media websites while visiting our services,
                      the social media plugins allow the relevant social media
                      websites to receive information that you have visited our
                      services or other information. The social media plugins
                      also allow the applicable social media websites to share
                      information about your activities on our services with
                      other users of the social media website. We do not control
                      any of the content from the social media plugins. We may
                      also interact with you on social media platforms. If you
                      contact us on one of our social media platforms, request
                      services, or otherwise communicate directly with us on
                      social media, we may contact you to interact with you. For
                      more information about social media advertising and social
                      media plugins from other social media websites, please
                      refer to those websites' privacy and data sharing
                      statements.
                    </li>
                    <li>
                      We may share your information with business partners who
                      may offer services or products to you. For example, in
                      connection with the Link Pharmacy credit card, we share
                      some information with our partner so it can prescreen
                      individuals to see if they qualify for offers. You can
                      learn about your rights relating to prescreened offers
                      including how to opt out by calling +260 211 255 556 or by
                      going to{" "}
                      <a href="/" target="_blank">
                        {" "}
                        https://www.optoutprescreen.com/
                      </a>
                      .
                    </li>
                  </ul>
                  <b>
                    When Sharing is Required by Law or Helps Us Protect Our
                    Interests
                  </b>
                  <ul>
                    <li>
                      We will disclose your information as necessary or
                      appropriate under applicable law, to comply with legal
                      process, to respond to such requests from public and
                      government authorities, to enforce our terms and
                      conditions, including investigations of potential
                      violations, to detect, prevent or otherwise address fraud,
                      security or technical issues, to protect our rights,
                      privacy, safety or property, and to allow us to pursue
                      available remedies to limit the damages that we may
                      sustain. In matters involving claims of personal or public
                      safety or in litigation where the data is pertinent, we
                      may use or disclose personal information without your
                      consent or court process.
                    </li>
                  </ul>
                  <b>When We Work On Business Transactions</b>
                  <ul>
                    <li>
                      As we continue to develop our business, we might sell or
                      buy stores or assets, or engage in mergers, acquisitions
                      or sale of company assets. Personal information may be
                      disclosed in connection with the evaluation of or entry
                      into such transactions or related business arrangements,
                      or in the course of providing transition of services to
                      another entity as permitted by law. In such transactions,
                      customer information generally is one of the transferred
                      business assets. Additionally, in the event that Link
                      Pharmacy or substantially all of its assets are acquired,
                      customer information will likely be one of the transferred
                      assets as is permissible under law.
                    </li>
                  </ul>
                  <b>
                    Non-identifiable or Aggregate information with Third Parties
                  </b>
                  <ul>
                    <li>
                      We may share non-identifiable or aggregate information
                      with third parties for lawful purposes.
                    </li>
                  </ul>
                  <b>With Your Consent</b>
                  <ul>
                    <li>
                      At your direction or request, or when you otherwise
                      consent, we may share your information.
                    </li>
                  </ul>
                  <hr />
                  <h5>Your Choices</h5>
                  <p>
                    By interacting with any of the Link Pharmacy family of
                    companies as described herein, you consent to the practices
                    described in this Privacy Policy. However, we want you to
                    know that you do have certain choices with regard to how
                    your information is collected and used.
                  </p>
                  <b>Email and Mobile Communications</b>
                  <ul>
                    <li>
                      You may choose to stop receiving email and mobile
                      marketing communications by changing your preferences
                      online. If you have an online account, log in to Your
                      Account and click on Communication Preferences. If you do
                      not have an online account, you may click Unsubscribe from
                      the bottom of Link Pharmacy emails, and reply STOP to our
                      text messages to stop receiving emails and SMS text
                      messages. To request unsubscribing by email, contact{" "}
                      <a href="mailto:customercare@linkpharmacy.co.zm">
                        {" "}
                        Customercare@linkpharmacy.co.zm
                      </a>
                      .
                    </li>
                  </ul>
                  <b>Mobile Application</b>
                  <ul>
                    <li>
                      <b>Push Notifications</b>
                    </li>
                    <p>
                      You can opt out from further allowing Link Pharmacy to
                      send you push notifications by adjusting the permissions
                      in your mobile device or from the Link Pharmacy mobile
                      application.
                    </p>

                    <li>
                      <b>Precise Location Data</b>
                    </li>
                    <p>
                      You can opt out from further allowing Link Pharmacy to
                      access precise location data by adjusting the permissions
                      in your mobile device.
                    </p>

                    <li>
                      <b>In-Store Location</b>
                    </li>
                    <p>
                      You can opt out from further allowing Link Pharmacy to
                      access your Bluetooth signal in our stores by adjusting
                      the location permissions in your mobile device or from the
                      Link Pharmacy mobile application.
                    </p>
                  </ul>
                  <b>Online Tracking and Interest-Based Advertisements</b>
                  <ul>
                    <li>
                      Link Pharmacy engages third-party advertisers to provide
                      interest-based advertising on our website, as well as
                      other third-party websites in order to display advertising
                      that is relevant to you. These third parties may collect
                      information about your use of our services over time and
                      that information may be combined with information
                      collected on different websites and online services. These
                      third-party advertisers may be participants in the Network
                      Advertising Initiative and/or the Self-Regulatory Program
                      for Online Behavioral Advertising, which allow users to
                      opt out of ad targeting from participating companies. To
                      learn more about interest-based advertising, or to opt out
                      of having your web browsing information used by certain
                      third-party advertisers for behavioral advertising
                      purposes, go to:{" "}
                      <a href="/" target="_blank">
                        www.aboutads.info/choices
                      </a>{" "}
                      or{" "}
                      <a href="/" target="_blank">
                        {" "}
                        https://www.networkadvertising.org/choices/
                      </a>
                      .
                      <br />
                      <br />
                      <b>Please Note:</b> When you "opt out" of receiving
                      interest-based advertisements, this does not mean you will
                      no longer see advertisements from Link Pharmacy. It means
                      that the online ads that you do see will not be tailored
                      for you based on your particular interests. We may still
                      collect information about you for any purpose permitted
                      under the Policy, including for analytics and fraud
                      prevention.
                    </li>
                  </ul>
                  <b>Our "Do Not Track" Policy</b>
                  <ul>
                    <li>
                      Link Pharmacy respects enhanced user privacy controls. We
                      support the development and implementation of a standard
                      "do not track" browser feature, which signals to websites
                      that you visit that you do not want to have your online
                      activity tracked. Please note that at this time Link
                      Pharmacy.com does not interpret or respond to "do not
                      track" signals. However, you may set your Web browser to
                      not accept new cookies or web beacons, be notified when
                      you receive a new cookie, or disable cookies altogether.
                      Please note that by disabling these features, your
                      experience on Link Pharmacy.com will not be as smooth and
                      you will not be able to take full advantage of our
                      website's features. Please see the Help section of your
                      browser for instructions on managing security preferences.
                    </li>
                  </ul>
                  <b>Minors</b>
                  <ul>
                    <li>
                      If you are under 18 years old and a registered user, you
                      can request that we remove content or information that you
                      have posted to our website or other online services. Note
                      that fulfilment of the request may not ensure complete or
                      comprehensive removal (e.g., if the content or information
                      has been reposted by another user). To request removal of
                      content or information, please{" "}
                      <a href="/contact-us"> contact us</a>.
                    </li>
                  </ul>
                  <b>
                    Accessing and Updating Your Personally Identifiable
                    Information
                  </b>
                  <ul>
                    <li>
                      You can review and update any of the information you have
                      given us by emailing us or calling 877-924-4472. Our
                      customer care staff will update your information. If you
                      have a Link Pharmacy.com account, you can log in and
                      update your information.
                    </li>
                  </ul>
                  <b>Links to Third-Party Websites</b>
                  <ul>
                    <li>
                      Our services may contain links to, or otherwise make
                      available, social media websites and other third-party
                      services, websites and mobile applications that are
                      operated and controlled by third parties. We do not take
                      responsibility for the content or the privacy practices
                      employed by other websites. Unless otherwise stated, any
                      Information you provide to any such third-party website
                      will be collected by that party and not by Link Pharmacy,
                      and will be subject to that party's privacy policy (if
                      any), rather than this Privacy Policy. In such a
                      situation, we will have no control over, and shall not be
                      responsible for, that party's use of the Information you
                      provide to them.
                    </li>
                  </ul>
                  <hr />
                  <h5>How We Protect Your Information</h5>
                  <b>Security Measures</b>
                  <ul>
                    <li>
                      Link Pharmacy recognizes the importance of maintaining the
                      security of your information. Whether you are shopping on
                      our website, through our mobile services, or in our
                      stores, we use reasonable security measures, including
                      administrative, technical, and physical safeguards.
                    </li>
                  </ul>
                  <b>Email Security</b>
                  <ul>
                    <li>
                      "Phishing" is a common email scam where your email address
                      is used to contact you and ask for personally identifiable
                      or sensitive information. Always be cautious when opening
                      links or attachments from unsolicited third parties. Also
                      know that Link Pharmacy will not send you emails asking
                      for your credit card number, social security number or
                      other personally identifiable information. If you are ever
                      asked for this information, you can be confident it is not
                      from Link Pharmacy.
                    </li>
                  </ul>
                  <b>Children's Personal Information</b>
                  <ul>
                    <li>
                      We recognize the importance of protecting children's
                      online privacy. Our website and mobile services are
                      intended for a general audience and are not directed to
                      children. We do not knowingly collect personal information
                      online from children under the age of 13
                    </li>
                  </ul>
                  <hr />
                  <h5>Information for California Consumers</h5>
                  <p>
                    If you are a California resident, we are required to provide
                    additional information to you about how we collect, use and
                    disclose your information that may be considered "Personal
                    Information" under California Law ("CA Personal
                    Information"), and you may have additional rights with
                    regard to how we use and disclose your CA Personal
                    Information. We have included this California-specific
                    information below.
                  </p>
                  <ul>
                    <li>
                      <b>Collection.</b> Consistent with the "Information We
                      Collect" section above, we collect certain categories and
                      specific pieces of CA Personal Information about
                      individuals who reside in California. In the 12 months
                      prior to the date of this Privacy Policy, we collected the
                      following types of categories of CA Personal Information,
                      which we will continue to collect:
                      <ul>
                        <li>
                          <b>Identifiers:</b> such as name, address, telephone
                          number, email address, age, date of birth, username
                          and password for our websites, online identifiers, IP
                          address;
                        </li>
                        <li>
                          <b>
                            Characteristics of protected classifications under
                            California or federal law: such as sex, gender, age
                            (40 or older);{" "}
                          </b>
                        </li>
                        <li>
                          <b>Commercial information:</b> such as products or
                          services purchased, obtained or considered, other
                          purchasing or consuming histories or tendencies,
                          payment information, health and medical information,
                          health insurance information, loyalty program
                          participation information;
                        </li>
                        <li>
                          <b>
                            Internet or other electronic network activity
                            information:
                          </b>{" "}
                          such as computer and connection information,
                          statistics on page views, traffic to and from the
                          websites, ad data and other standard weblog
                          information;
                        </li>
                        <li>
                          <b>Geolocation information:</b> including location
                          data and precise location data such as physical
                          location information through the use of our services
                          on your mobile phone or device by, for example using
                          satellite, cell phone tower, WiFi signal, beacons,
                          Bluetooth and near field communication protocols, when
                          you are in or near a Link Pharmacy store;
                        </li>
                        <li>
                          <b>Audio, visual, or similar information:</b> such as
                          photographs you share, in-store security video,
                          customer service audio recordings; and
                        </li>
                        <li>
                          <b>
                            Inferences drawn from the above categories of CA
                            Personal Information:
                          </b>{" "}
                          such as consumer preferences, characteristics,
                          predispositions, and behavior.
                        </li>
                      </ul>
                    </li>

                    <li>
                      <b>Sources.</b> We may collect certain categories of CA
                      Personal Information from you and third parties as
                      described in the "Information We Collect" section above.
                      The categories of sources from which we collected CA
                      Personal Information in the 12 months prior to the date of
                      this Privacy Policy include the following:
                      <ul>
                        <li>
                          In our stores (parent, subsidiary and affiliate
                          brands)
                        </li>
                        <li>
                          On our websites and mobile applications (parent,
                          subsidiary and affiliate brands)
                        </li>
                        <li>
                          When you communicate with our Customer Care Center
                        </li>
                        <li>Through participation in loyalty programs</li>
                        <li>
                          Third party websites and mobile applications (e.g.,
                          websites and applications that share information with
                          us or our advertising partners regarding your online
                          activities)
                        </li>
                        <li>
                          Data Suppliers (e.g., companies that provide
                          demographics and other information regarding
                          consumers)
                        </li>
                        <li>
                          Joint marketing or other commercial business partners
                        </li>
                        <li>Online advertising networks</li>
                        <li>Delivery partners/carriers</li>
                        <li>Social media companies</li>
                        <li>Other service providers</li>
                        <li>Survey providers</li>
                      </ul>
                      <p>
                        We will continue to collect CA Personal Information from
                        these same sources.
                      </p>
                    </li>

                    <li>
                      <b>Purposes.</b> We collect CA Personal Information for
                      the business and commercial purposes described in the "How
                      We Use Your Information" section as described above.
                      Specifically, we collect CA Personal Information to
                      respond to your inquiries, fulfill your requests and
                      improve your experience; for marketing, advertising and
                      promotional purposes; for reporting and analytics; to
                      improve the effectiveness of our services, conduct
                      research and analysis, or for other internal operations
                      purposes; to detect, prevent or investigate potential
                      security breaches, fraudulent transactions and monitor
                      against theft.
                      <p>
                        <b>
                          Sharing your CA Personal Information for business
                          purposes:
                        </b>{" "}
                        As described above in the "How We Share Your
                        Information" section, we share information for business
                        purposes. In the 12 months prior to the date of this
                        Privacy Policy, we shared and we may continue to share
                        the following categories of CA Personal Information with
                        third parties who are considered "service providers" as
                        defined under California law since we disclose CA
                        Personal Information to them for our business purposes.
                      </p>
                      <ul>
                        <li>
                          <b>Identifiers:</b> such as name, address, telephone
                          number, email address, age, date of birth, username
                          and password for our websites, online identifiers, IP
                          address;
                        </li>
                        <li>
                          <b>
                            Characteristics of protected classifications under
                            California or Federal Law:
                          </b>{" "}
                          such as sex, gender, age (40 or older);
                        </li>
                        <li>
                          <b>Commercial information:</b> such as products or
                          services purchased, obtained or considered, other
                          purchasing or consuming histories or tendencies,
                          payment information, health and medical information,
                          health insurance information, and loyalty program
                          participation information;
                        </li>
                        <li>
                          <b>
                            Internet or other electronic network activity
                            information:
                          </b>{" "}
                          such as computer and connection information,
                          statistics on page views, traffic to and from the
                          websites, ad data and other standard weblog
                          information;
                        </li>
                        <li>
                          <b>Geolocation information:</b> including location
                          data and precise location data, such as physical
                          location information through the use of our services
                          on your mobile phone or device by, for example using
                          satellite, cell phone tower, WiFi signal, beacons,
                          Bluetooth and near field communication protocols. If
                          you use our mobile application, your device may share
                          location information when you enable location
                          services. We may be able to recognize the location of
                          a mobile device in stores through use of Bluetooth
                          technology;
                        </li>
                        <li>
                          <b>Audio, visual, or similar information:</b> such as
                          photographs you share, in-store security video,
                          customer service audio recordings; and
                        </li>
                        <li>
                          <b>
                            Inferences drawn from the above categories of CA
                            Personal Information:
                          </b>{" "}
                          such as consumer preferences, characteristics,
                          predispositions, and behavior.
                        </li>
                      </ul>
                      <p>
                        As described above, examples of business purposes
                        include product and service fulfillment, internal
                        operations, prevention of fraud and other harm, and
                        legal compliance.
                      </p>
                      <p>
                        The categories of third party service providers to which
                        we may share the above described categories include
                        Payment Processing Companies, Data Analytics Providers,
                        Fraud Prevention Providers, Cloud Storage Providers, IT
                        Service Providers, Professional Service Providers,
                        Delivery Partners, and Marketing Companies.
                      </p>
                      <p>
                        In addition, we may share the aforementioned categories
                        of CA Personal Information with third parties involved
                        in the evaluation of or entry into the sale or purchase
                        of stores or company assets, mergers, or acquisitions.
                        The categories of third parties to which we may share
                        the above described categories of CA Personal
                        Information include potential Business Partners or
                        Purchasers, Professional Service Providers (e.g.,
                        consultants, lawyers, accountants), and Data Analytics
                        Providers. In the event of sale, merger, or acquisition,
                        customer information (including CA Personal Information)
                        generally is one of the transferred business assets, as
                        is permissible under law.
                      </p>
                      <p>
                        <b>Sale of CA Personal Information.</b> As described
                        above in the "How We Share Your Information" section, we
                        may share the following categories of CA Personal
                        Information with third parties who are considered "third
                        parties" as defined under California law since we
                        disclose CA Personal Information to them which they may
                        use for secondary purposes. Our disclosure of CA
                        Personal Information to the third parties who use the
                        information for secondary purposes may constitute a
                        "sale" of CA Personal Information as defined under
                        California law.
                      </p>
                      <p>
                        In the 12 months prior to the date of this Privacy
                        Policy, we shared for secondary purposes (which may
                        constitute a "sale" of CA Personal Information under
                        California law), and may continue to share, the
                        following categories of CA Personal Information:
                      </p>
                      <ul>
                        <li>
                          <b>Identifiers:</b> such as online identifiers, IP
                          address;
                        </li>
                        <li>
                          <b>Commercial information:</b> such as products or
                          services purchased, obtained or considered, other
                          purchasing or consuming histories or tendencies;
                        </li>
                        <li>
                          <b>
                            Internet or other electronic network activity
                            information:
                          </b>{" "}
                          such as computer and connection information,
                          statistics on page views, traffic to and from the
                          websites, ad data and other standard weblog
                          information;
                        </li>
                        <li>
                          <b>Geolocation information:</b> including location
                          data and precise location data, such as physical
                          location information through the use of our services
                          on your mobile phone or device by, for example using
                          satellite, cell phone tower, WiFi signal, beacons,
                          Bluetooth and near field communication protocols. If
                          you use our mobile application, your device may share
                          location information when you enable location
                          services. We may be able to recognize the location of
                          a mobile device in stores through use of Bluetooth
                          technology; and
                        </li>
                        <li>
                          <b>Inferences drawn from CA Personal Information:</b>{" "}
                          such as consumer preferences, characteristics,
                          predispositions, and behavior.
                        </li>
                      </ul>
                      <p>
                        The categories of third parties to which we may sell (as
                        defined by California law) the above described
                        categories of CA Personal Information include online
                        Advertising Networks, Marketing Companies, Financial
                        Services Partners and Social Media Companies.
                      </p>
                      <p>
                        <b>
                          Opting Out of the Sale of CA Personal Information.
                        </b>{" "}
                        You may stop our disclosure of your CA Personal
                        Information to these entities for their use for
                        secondary purposes by opting-out of the sale of your CA
                        Personal Information. You can do so by submitting an
                        opt-out request through this Do Not Sell My Personal
                        Information link or by contacting us at 800-925-4733.
                      </p>
                      <ul>
                        <li>
                          <b>Notice of Financial Incentive.</b> We may provide
                          price discounts, coupons, services and other perks for
                          members of our loyalty programs such as myLink
                          Pharmacy. Through these offerings, consumers may
                          provide us with any or all of the categories of CA
                          Personal Information set out above in the "Collection"
                          section depending on how they choose to interact with
                          us when and after they opt-in to our programs. There
                          is no obligation to opt-in and consumers may opt-out
                          at any time. The details of each program are contained
                          in the program offering. We offer these programs,
                          among other things, to enhance our relationship with
                          you so you can enjoy more of our products/services at
                          a lower price. We invest heavily in our marketing and
                          brands, in part, so we can provide programs to our
                          customers. Consumer data is more valuable to our
                          business when it is combined with a sufficient amount
                          of other consumer data and after it is enhanced by our
                          efforts described in this Privacy Policy. The value to
                          our business of any individual consumer's data is
                          dependent on a number of factors, including, for
                          example, whether and to what extent you take advantage
                          of any offerings, whether and to what extent you opt
                          out of any offerings, and whether we are able to
                          enhance the data through our efforts described in this
                          Privacy Policy. We do not calculate the value of
                          consumer data in our accounting statements. We make
                          this good faith estimate for California residents. To
                          the extent we create overall value from our programs
                          to our business that could be directly or reasonably
                          related to the value of customer data, the method for
                          calculating the value would include: (1) costs related
                          to maintaining the program including but not limited
                          to IT infrastructure, delivery of offers, and skilled
                          marketing teams with the appropriate knowledge to
                          enhance customer data; (2) whether the sales generated
                          by the program exceeds the cost to us of offering the
                          program including value of discounts to customer; and
                          (3) value of the insights we are able to create based
                          upon aggregate data.
                        </li>

                        <li>
                          <b>Deidentified Patient Information. </b>We may also
                          disclose information that does not identify an
                          individual and cannot reasonably be used to identify
                          an individual which is derived from CA Personal
                          Information, as well as deidentified protected health
                          information that has been modified to remove
                          individually identifiable information in accordance
                          with HIPAA's expert determination (also known as
                          "statistician's method") or safe harbor
                          deidentification standards.
                        </li>
                      </ul>
                      <br />
                      <p>
                        <b>California Consumer Rights.</b> As a California
                        resident, you have the right to ask us for any or all of
                        following types of information regarding the CA Personal
                        Information we have collected about you in the 12 months
                        prior to our receipt of your request:
                      </p>
                      <ul>
                        <li>
                          Specific pieces of CA Personal Information we have
                          collected about you;
                        </li>
                        <li>
                          Categories of CA Personal Information we have
                          collected about you
                        </li>
                        <li>
                          Categories of sources from which such CA Personal
                          Information was collected;
                        </li>
                        <li>
                          Categories of CA Personal Information we sold or
                          disclosed for a business purpose about you; and
                        </li>
                        <li>
                          The business or commercial purpose for collecting or
                          selling your CA Personal Information.
                        </li>
                      </ul>
                      <p>
                        You also have the right to request deletion of your CA
                        Personal Information and opt out of the sale of your CA
                        Personal Information.
                      </p>
                      <ul>
                        <li>
                          <b>Exercising California Consumer Rights.</b> You or
                          your authorized agent may submit a request to exercise
                          your California Consumer Rights by using one of the
                          following specifically designated methods:
                        </li>
                        <li>
                          Self-service on our digital properties by logging on
                          to your account and using the Customer Preference
                          Center
                          <ul>
                            <li>
                              Click the following links and confirm your
                              choices:
                              <p>Do Not Sell My Personal Information</p>
                              <p>Exercise California Consumer Privacy Rights</p>
                            </li>
                            <li>
                              Contacting our Customer Care Center at +260 211
                              255 556
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>Responding to Requests.</b> For requests for access
                          or deletion, we will first acknowledge receipt of your
                          request within 10 business days of receipt of your
                          request. We provide a substantive response to your
                          request as soon as we can, generally within 45 days
                          from when we receive your request, although we may be
                          allowed to take longer to process your request under
                          certain circumstances. If we expect your request is
                          going to take us longer than normal to fulfill, we
                          will let you know.
                          <br />
                          For requests to stop the sale of your CA Personal
                          Information, we will comply within 15 business days
                          after receipt of your request.
                          <br />/ We usually act on requests and provide
                          information free of charge, but we may charge a
                          reasonable fee to cover our administrative costs of
                          providing the information in certain situations. In
                          some cases, the law may allow us to refuse to act on
                          certain requests. When this is the case, we will
                          endeavor to provide you with an explanation as to why.
                        </li>
                        <li>
                          <b>Requests By Authorized Agents.</b> You may
                          designate an agent to submit requests on your behalf.
                          The agent must be a natural person or a business
                          entity that is registered with the California
                          Secretary of State.
                          <br />
                          If you would like to designate an agent to act on your
                          behalf, you and the agent will be required to provide
                          us with proof of the agent’s identity and proof that
                          you gave the agent signed permission to submit a
                          request on your behalf. Additionally, you will be
                          required to verify your identity by providing us with
                          certain CA Personal Information as described above or
                          provide us with written confirmation that you have
                          authorized the agent to act on your behalf.
                          <br />
                          Please note that this subsection does not apply when
                          an agent is authorized to act on your behalf pursuant
                          to a valid power of attorney. Any such requests will
                          be processed in accordance with California law
                          pertaining to powers of attorney.
                        </li>
                        <li>
                          <b>Requests for Household Information.</b> There may
                          be some types of CA Personal Information that can be
                          associated with a household (a group of people living
                          together in a single dwelling). Requests for access or
                          deletion of household CA Personal Information must be
                          made by each member of the household. We will verify
                          the identity of each member of the household using the
                          verification criteria explained above and will also
                          verify that each household member is currently a
                          member of the household.
                        </li>
                        <br />
                        <li>
                          <b>Verification of Requests. </b>{" "}
                        </li>
                        After you submit a request to exercise a California
                        Consumer Right as described above (other than a request
                        to stop the sale of your CA Personal Information), you
                        will enter into a two-part verification process.
                        <br />
                        For part one, you must verify your identity by correctly
                        answering demographic questions powered through Lexis
                        Nexis®, and/or confirming control over the phone number
                        and/or email or postal address you provide in the
                        request form.
                        <br />
                        If you successfully complete the Lexis Nexis® identity
                        verification and/or the phone and/or email or postal
                        address verification, you will proceed to part two of
                        the process in which we will attempt to match the data
                        provided in the request form to the data we maintain.
                        Depending on the type of request submitted, if you are
                        matched to a high degree of certainty your request will
                        be processed as follows:
                        <br />
                        (a) Access request: Your access report will include the
                        specific pieces of CA Personal Information not otherwise
                        subject to an exception pursuant to law that we match to
                        you.
                        <br />
                        (b) Deletion request: All data matched to you and not
                        otherwise subject to an exception pursuant to law will
                        be deleted.
                        <br />
                        If you fail Lexis Nexis® identity verification, but
                        successfully complete phone and/or email or postal
                        address verification you proceed to part two of the
                        process in which we will attempt to match the data
                        provided in the request form to the data we maintain.
                        Depending on the type of request submitted, if you are
                        matched to a reasonable degree of certainty you request
                        will be processed as follows:
                        <br />
                        (a) Access request: Your access report will include the
                        categories of CA Personal Information we match to you
                        unless otherwise subject to an exception pursuant to law
                        <br />
                        (b) Deletion request: Certain limited data we associate
                        to you will be deleted.
                        <br />
                        If you fail Lexis Nexis® identity verification as well
                        as phone and/or email and postal address verification,
                        your request will be cancelled and you will be notified.
                      </ul>
                    </li>
                    <li>
                      <b>Nondiscrimination.</b> Should you wish to request the
                      exercise of your rights as detailed above with regard to
                      your CA Personal Information, we will not discriminate
                      against you. To the extent you provide CA Personal
                      Information in connection with programs such as myLink
                      Pharmacy, additional information about the terms
                      applicable to those programs, including our use of CA
                      Personal Information in exchange for the applicable
                      financial incentives, can be found in the terms and
                      conditions for those programs and in the Notice of
                      Financial Incentive section above.
                    </li>
                    <li>
                      <b>No Sale of Minors' Personal Information.</b>{" "}
                      Additionally, California law requires California residents
                      under the age of 16 to opt-in to the sale of CA Personal
                      Information. We have protections in place to prevent the
                      sale of, and do not intend to and have no actual knowledge
                      that we sell the CA Personal Information of California
                      residents under the age of 16. As a result, certain
                      programs and services may be unavailable to California
                      residents under the age of 16.
                    </li>
                    <br />

                    <li>
                      <b>Metrics.</b> California law requires recording of
                      metrics regarding Access requests, Deletion Requests, and
                      Opt-out requests submitted by California residents
                      pursuant to the California Consumer Privacy Act. The
                      metrics below reflect the time period from January 1, 2020
                      to December 31, 2020.
                      <ul>
                        <li>
                          Access Requests
                          <ul>
                            <li>Request Received: 315</li>
                            <li>Completed: 104</li>
                            <li>Expired/Rejected: 211</li>
                            <li>Median Days to Fulfill: 34</li>
                          </ul>
                        </li>
                        <li>
                          Deletion Requests
                          <ul>
                            <li>Requests Received: 349</li>
                            <li>Completed: 161</li>
                            <li>Expired/Rejected: 188</li>
                            <li>Median Days to Fulfill: 26</li>
                          </ul>
                        </li>
                        <li>
                          Opt-out Requests
                          <ul>
                            <li>Requests Received: 13,776</li>
                            <li>Completed: 12,609</li>
                            <li>Expired/Rejected: 1,167</li>
                            <li>Median Days to Fulfill: 0</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <hr />
                  <h5>How To Contact Us:</h5>
                  If you have questions about our Privacy Policy, or to exercise
                  your rights as detailed above. Feel free to{" "}
                  <a href="/contact-us">contact us.</a>
                  <br />
                  <address>
                    <b>Phone:</b> +260 211 255 556 <br />
                    <b>E-mail:</b>{" "}
                    <a href="mailto:customercare@linkpharmacy.co.zm">
                      Customercare@linkpharmacy.co.zm
                    </a>
                    <br />
                    <p>
                      To request this Privacy Policy in an alternative
                      accessible format, call our Customer Care Center at +260
                      211 255 556.
                    </p>
                    <p>
                      <b>Effective Date:</b> 10/21/2016;{" "}
                      <b>Last Updated: 9/25/2021</b>
                    </p>
                  </address>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default OnlinePolicy;
