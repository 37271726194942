/**
 * Converts a slug to a human-readable string
 *
 * @param {string} str - The slug to convert
 * */
export const toHumanReadableString = (str: string) => {
  let readableString = str.replace(/[-_]+/g, " ");

  readableString = readableString.replace(/\b\w/g, (match) => match.toUpperCase());

  return readableString;
};
