import { Helmet } from "react-helmet";
import { classNames } from "../../utils/utils";

const LayoutAuth = ({ children, className, title, description, keywords }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <main
        className={classNames(
          className
            ? className
            : "font-opensans w-screen h-screen flex flex-col justify-center items-center bg-link-green bg-img-auth bg-center bg-fixed bg-cover bg-no-repeat"
        )}
      >
        {children}
      </main>
    </>
  );
};
export default LayoutAuth;
