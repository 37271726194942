import { MdError } from "react-icons/md";
import { Link } from "react-router-dom";

import { classNames } from "../utils/utils";

const LinkPharmacyStore = ({ color, store, marker, index, ...props }) => {
  return (
    <Link to={`/store-details/${store.id}`} className="w-full bg-white overflow-x-hidden rounded-2xl p-2 flex flex-col">
      <div className="flex flex-col w-full justify-between">
        <div className="flex items-center py-2 pl-2">
          <span
            className={classNames(
              "flex justify-center items-center font-opensans-semibold w-8 h-8 rounded-full mr-3",
              store.id === marker ? `${color} text-white` : "bg-link-green text-white"
            )}
          >
            {index + 1}
          </span>

          <div>
            <h1 className="flex font-opensans-medium text-lg text-green-800">{store.name}</h1>
          </div>
        </div>
      </div>
      <div className="pl-10">
        <p className="text-base text-black font-opensans">{store.address}</p>
        <hr className="border-stone-300 border-none border-t my-2" />
        <p className="-ml-9 flex items-center text-orange-500 font-opensans text-sm">
          <MdError className="text-2xl mr-3" />
          <strong> Pickup & delivery available</strong>
        </p>
      </div>
    </Link>
  );
};
export default LinkPharmacyStore;
