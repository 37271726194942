import React from "react";
import { Pagination } from "@mui/material";

const Paginate = ({ currentPage, perPage, total, onPageChange }) => {
  return (
    <div>
      <Pagination
        count={Math.ceil(total / perPage)}
        onChange={(e, value) => onPageChange(value)}
        page={currentPage}
        shape="rounded"
      />
    </div>
  );
};

export default Paginate;
