import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import BreadCrumbs from "../../components/BreadCrumbs";
import StoreContent from "./StoreContent";
import StoreSideBar from "../../components/StoreSideBar";
import { meta } from "../../globals";
import { getMenusApi } from "../../api/menus";
import { getSubCategoriesApi } from "../../api/subCategories";
import Layout from "../../components/Layout/Layout";
import { toHumanReadableString } from "../../helpers/toHumanReadableString";
import { usePagination } from "../../hooks/usePagination";

const SubCategories = () => {
  const { catName, catID, subCatName } = useParams();
  const [sidebarList, setSidebarList] = useState([]);
  const [pageTitle, setPageTitle] = useState("Link Pharmacy | Online Store Zambia");
  const [page, onPageChange] = usePagination(1);

  const { data: menu, isLoading: isMenuLoading } = useQuery(["menu-categories"], getMenusApi);
  const {
    data: products,
    status: productStatus,
    isLoading: isProductsLoading,
  } = useQuery([catName, page], async () => getSubCategoriesApi(catID, page));

  useEffect(() => {
    if (catName && subCatName) {
      setPageTitle(`${catName} ${subCatName} | Link Pharmacy`);
    } else {
      setPageTitle("Link Pharmacy | Online Store Zambia");
    }
  }, [catName, subCatName]);

  useEffect(() => {
    if (menu) {
      const category = menu.find((cat) => cat.category_id === Number(catID));
      setSidebarList(category?.subcategories || []);
    }
  }, [menu, catID]);

  return (
    <Layout
      meta={{
        title: pageTitle,
        keywords: catName && subCatName ? `${catName}, ${subCatName}, Link Pharmacy online store` : meta.homeKeywords,
        description:
          catName && subCatName
            ? `${catName} ${subCatName} | Link Pharmacy online store Zambia`
            : meta.storeDescription,
      }}
      header
      footer
      bgColor={"bg-stone-100"}
    >
      <div className="container mx-auto mt-2">
        <BreadCrumbs
          crumbs={[
            { name: "Home", link: "/home" },
            { name: `Shop`, link: "/store/catalog" },
            { name: `All Products`, link: "/store/products" },
            catName && {
              name: toHumanReadableString(catName),
              link: `/store/catalog/${catName}/${catID}`,
            },
          ]}
        />

        <div className="flex flex-col md:flex-row mt-1 space-x-0 md:space-x-10">
          <div className="w-full md:w-3/12">
            <StoreSideBar type="category" list={sidebarList} value={subCatName} valuesStatus={isMenuLoading} />
          </div>
          <div className="w-full md:w-9/12">
            <StoreContent
              type="category"
              productsStatus={productStatus}
              isLoading={isProductsLoading}
              products={products}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SubCategories;
