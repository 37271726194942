import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import styles from "../../styles/views/AddressBook.module.css";
import { Button, Input, Select } from "../CustomComponents";
import cities from "../../utils/cities";
import { Formik, FormikValues } from "formik";
import validationSchemas from "../../utils/validationSchemas";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useNotifications } from "../../context/NotificationsContext";
import { useNavigate } from "react-router-dom";

const AddressBookAdd = () => {
  const { user } = useContext(AuthContext);
  const { pushNotification } = useNotifications();

  const initialValues = {
    addressLine1: "",
    addressLine2: "",
    city: "",
    residentialArea: "",
    postalCode: "",
  };

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    ["add-address"],
    async (values: FormikValues) => {
      const { data } = await axios.post("/v1/website/address", {
        address1: values.addressLine1,
        address2: values.addressLine2,
        city: values.city,
        residential_area: values.residentialArea,
        postal_code: values.postalCode,
      });
      return data;
    },
    {
      onSuccess: async (data, variables) => {
        variables?.formikHelpers?.resetForm();
        await queryClient.invalidateQueries("addresses");
        pushNotification({
          message: "Your address has been added successfully.",
          type: "Success",
          id: Math.random(),
        });
        navigate("/account?t=address-book");
      },
      onError: (error, variables) => {
        pushNotification({
          message: "Something went wrong. Please try again or contact support for help.",
          type: "Error",
          id: Math.random(),
        });
      },
    }
  );

  return (
    <div className="w-full flex flex-col text-base">
      <div className="w-full flex items-center justify-between border-b px-4 py-3 hidden md:block">
        <h2 className="m-0 font-opensans-medium text-2xl">Add New Address</h2>
      </div>

      <div className="w-full flex flex-col p-3 text-sm md:text-base  ">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas.address}
          onSubmit={(values, formikHelpers) => {
            mutate({ ...values, formikHelpers });
          }}
        >
          {({ values, handleChange, handleBlur, setFieldValue, errors, touched, handleSubmit }) => (
            <form
              className={styles.form}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className={"mb-3"}>
                <div>
                  <Input readOnly={true} label={"Full Name"} value={user ? `${user.firstname} ${user.lastname}` : ""} />
                </div>
              </div>

              <div className={"mb-3"}>
                <div>
                  <Input
                    name="addressLine1"
                    label={"Address Line 1"}
                    value={values.addressLine1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.addressLine1}
                    touched={!!touched.addressLine1}
                  />
                </div>
              </div>

              <div className={"mb-3"}>
                <div>
                  <Input
                    name="addressLine2"
                    label={"Address Line 2"}
                    value={values.addressLine2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.addressLine2}
                    touched={!!touched.addressLine2}
                  />
                </div>
              </div>

              <div className={"mb-3"}>
                <div>
                  <Select
                    label={"Town/City"}
                    value={values.city}
                    onChange={(value) => setFieldValue("city", value)}
                    options={cities.map((city) => ({
                      value: city,
                      label: city,
                    }))}
                  />
                </div>
              </div>

              <div className={"mb-3"}>
                <div>
                  <Input
                    name="residentialArea"
                    label={"Residential Area"}
                    value={values.residentialArea}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.residentialArea}
                    touched={!!touched.residentialArea}
                  />
                </div>
              </div>

              <div className={"mb-3"}>
                <div>
                  <Input
                    name={"postalCode"}
                    label={"Postal Code"}
                    value={values.postalCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.postalCode}
                    touched={!!touched.postalCode}
                  />
                </div>
              </div>

              <div>
                <div className="flex flex-row items-center space-x-4">
                  <Button variant={"secondary"} onClick={() => navigate("/account?t=address-book")}>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? <Button.Loader label={"Saving..."} /> : "Save Changes"}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddressBookAdd;
