import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import FeaturedCategory from "../../components/FeaturedCategory";
import Product from "../../components/products/Product";
import StorePros from "../../components/StorePros";
import { getFeaturedCategoriesApi } from "../../api/categories";
import LinkLoader from "../../components/LinkLoader";
import { toHumanReadableString } from "../../helpers/toHumanReadableString";
import Pagination from "../../components/CustomComponents/components/pagination";

const StoreContent = ({ products, productsStatus, type, isLoading, onPageChange }) => {
  const { catName, subCatName } = useParams();
  const { data: featuredCategories, isSuccess } = useQuery(
    ["featured-categories", catName, subCatName],
    getFeaturedCategoriesApi
  );

  return (
    <div className="w-full flex flex-col flex-1">
      <div className="w-full flex justify-around bg-white py-4 px-2 rounded-xl">
        <StorePros />
      </div>

      <div className="mt-3">
        <div className="w-full flex items-center justify-between">
          <h1 className="font-opensans-bold text-2xl m-0 mb-2 p-2">
            {catName && subCatName
              ? toHumanReadableString(subCatName)
              : catName && !subCatName
              ? toHumanReadableString(catName)
              : null}
          </h1>
        </div>

        {(subCatName || catName) && (
          <div className="w-full flex flex-1 flex-wrap justify-evenly">
            {isLoading ? (
              <LinkLoader />
            ) : products && productsStatus === "success" ? (
              <>
                <div className="h-full w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 place-content-center mb-3">
                  {products.data.map((product) => (
                    <Product key={product.id} product={product} productType="products" />
                  ))}
                </div>
                {products.meta.last_page > 1 && (
                  <div className="w-full">
                    <Pagination meta={products.meta} changePage={onPageChange} />
                  </div>
                )}
              </>
            ) : (
              <p className="font-opensans-bold">No products to display</p>
            )}
          </div>
        )}

        {!subCatName && !catName && (
          <>
            <h4 className="font-opensans-bold mb-4">Featured Categories</h4>

            <div className="w-full flex flex-1 flex-wrap justify-between">
              {isSuccess &&
                featuredCategories.data.map((category, index) => {
                  return (
                    <FeaturedCategory
                      key={index}
                      category={category}
                      link={`/store/catalog/${category.attributes.name && category.attributes.name.replace(" ", "-")}/${
                        category.id
                      }`}
                    />
                  );
                })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StoreContent;
