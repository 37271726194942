import React from "react";
import { classNames } from "../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface Props {
  title?: string;
  type?: "button" | "submit" | "reset";
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  children?: React.ReactNode;
  disabled?: boolean;
  variant?: "primary" | "secondary";
  size?: "sm" | "md" | "lg" | "xl";
  width?: string;
  onHover?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  href?: string;
  ariaLabel?: string;
  external?: boolean;
  rounded?: boolean;
}

const sizeClasses = {
  sm: "py-1 px-2 text-xs",
  md: "py-2 px-3 text-sm",
  lg: "py-2.5 px-3.5 text-[15px]",
  xl: "py-4 px-5 text-lg",
};

const getClassNames = (props: Props) => {
  return classNames(
    "font-opensans-semibold transition-all text-center",
    props.rounded ? "rounded-full" : "rounded",
    sizeClasses[props.size || "md"],
    props.variant === "secondary" && "bg-stone-500 hover:bg-stone-400 text-white",
    props.variant !== "secondary" && "bg-link-green-1 hover:bg-link-green-2 text-white",
    props.disabled && "opacity-50 cursor-not-allowed",
    props.width && `w-${props.width}`
  );
};

const renderChildren = (props: Props) => {
  const content = props.title || props.children;

  if (props.href) {
    if (props.external) {
      return (
        <a
          href={props.href}
          className={getClassNames(props)}
          onMouseEnter={props.onHover}
          aria-disabled={props.disabled ? "true" : "false"}
          aria-label={props.ariaLabel}
        >
          {content}
        </a>
      );
    } else {
      return (
        <Link
          to={props.href}
          className={getClassNames(props)}
          onMouseEnter={props.onHover}
          aria-label={props.ariaLabel}
        >
          {content}
        </Link>
      );
    }
  }

  return (
    <button
      className={getClassNames(props)}
      disabled={props.disabled}
      type={props.type}
      onClick={props.onClick}
      onMouseEnter={props.onHover}
      aria-label={props.ariaLabel}
    >
      {content}
    </button>
  );
};

const Button = (props: Props) => {
  return renderChildren(props);
};

Button.Loader = ({ label }: { label: string }) => (
  <>
    <FontAwesomeIcon icon={faCircleNotch} style={{ marginRight: "10px" }} className={"fa fa-spin"} />
    {label}
  </>
);

Button.Rounded = (props: Props) => {
  return renderChildren({ ...props, rounded: true });
};

export default Button;
