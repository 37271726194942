import { Form, Formik } from "formik";
import { FaAddressCard } from "react-icons/fa";
import { IoCalendarNumber, IoMail, IoMale, IoPerson } from "react-icons/io5";
import { genderOptions } from "../../globals";
import { prudentialSchema } from "../../globals/schemas";
import styles from "../../styles/views/MyHealthInsurance.module.css";
import { FormField, SelectField } from "../forms";

const Prudential = () => {
  return (
    <Formik
      initialValues={{
        fullname: "",
        companyName: "",
        membershipID: "",
        nrc: "",
        email: "",
        mobileNum: "",
        patientFullname: "",
        patientRelationship: "",
        policyID: "",
        patientDOB: "",
        gender: "",
        patientMobileNum: "",
        patientEmail: "",
        claimForm: "",
        insuranceCompany: "Prudential",
      }}
      onSubmit={(values) => console.log(values)}
      validationSchema={prudentialSchema}
    >
      {({ isSubmitting, values, handleChange, setFieldValue, errors }) => (
        <Form className="space-y-4 flex flex-col self-center w-full">
          <div className={styles.stepCounter}>
            <div>2</div> Principle Member Details
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Fullname"}
              type="text"
              name="fullname"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Company Name"}
              type="text"
              name="companyName"
              width={"w-full"}
              margin="mb-0"
              icon={<FaAddressCard className="text-xl ml-2 text-link-green" />}
            />
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Membership Number"}
              type="text"
              name="membershipID"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"NRC Number"}
              type="text"
              name="nrc"
              width={"w-full"}
              margin="mb-0"
              icon={<FaAddressCard className="text-xl ml-2 text-link-green" />}
            />
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Phone Number"}
              type="text"
              name="mobileNum"
              placeholder="e.g.: XXXX-XXX-XXX"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Email"}
              type="email"
              name="email"
              placeholder="e.g.: name@domain.com"
              width={"w-full"}
              margin="mb-0"
              icon={<IoMail className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className={styles.stepCounter}>
            <div>3</div> Patient's Details
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Patient Fullname"}
              type="text"
              name="patientFullname"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Relationship to the principal"}
              type="text"
              name="patientRelationship"
              placeholder="e.g.: -------"
              width={"w-full"}
              margin="mb-0"
              icon={<IoMail className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Membership Number"}
              type="text"
              name="membershipID"
              placeholder="e.g.: 43257678"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Policy Number"}
              type="text"
              name="policyID"
              placeholder="e.g.: XXXXXXX"
              width={"w-full"}
              margin="mb-0"
              icon={<IoMail className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-5 md:px-12">
            <div className="w-full md:w-fit h-14">
              <FormField
                label={"Patient Date of Birth"}
                type="text"
                name="patientDOB"
                margin="mb-0"
                icon={<IoCalendarNumber className="text-xl ml-2 text-link-green" />}
              />
            </div>
            <div className="w-full md:w-fit">
              <SelectField
                label={"Gender"}
                type="text"
                name="patientGender"
                placeholder="Gender"
                options={genderOptions}
                margin="mb-0"
                icon={<IoMale className="text-xl ml-2 text-link-green" />}
              />
            </div>
            <div className="w-full md:w-fit">
              <FormField
                label={"Patient Mobile Number"}
                type="text"
                name="patientMobileNum"
                placeholder="e.g.: 0912 345 678"
                margin="mb-0"
                icon={<IoCalendarNumber className="text-xl ml-2 text-link-green" />}
              />
            </div>
          </div>
          <div className="w-full md:px-12">
            <FormField
              label={"Email"}
              type="email"
              name="email"
              placeholder="e.g.: name@domain.com"
              margin="mb-0"
              icon={<IoMail className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className={styles.stepCounter}>
            <div>4</div> Claim Details
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={"mt-4"}>
                <div>
                  <h5 className={`font-weight-bold ${styles.label}`}>Upload Copy of Claim Form</h5>

                  <p>Please ensure Patient's signature, Doctor's signature and Medical Services are clearly visible.</p>

                  <input
                    type="file"
                    name="fullNames"
                    className={"form-control"}
                    onChange={(event) => {
                      setFieldValue("claimForm", event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <input
              type={"submit"}
              value="Submit form"
              className="bg-link-green text-stone-900 rounded-full p-3"
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Prudential;
