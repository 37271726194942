import { memo } from "react";

const CartIcon = ({ quantity }) => {
  // Number of digits for the quantity, i.e 5 = 1 digit, 10 = 2 digits
  let length = (Math.log(quantity) * Math.LOG10E + 1) | 0;

  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <defs>
        <style>
          {`       .cls-1 {
                fill: none;
                stroke: #fff;
                stroke-linecap: round;
                stroke-miterlimit: 10;
                stroke-width: 6px;
            }

                .cls-2 {
                fill: #fff;
            }

                .cls-3 {
                font-size: 16px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 600;
            }`}
        </style>
      </defs>
      <path className="cls-1" d="M4,6H7a4.94,4.94,0,0,1,3,1c1,1,5,22,5,22s.5,3.5,3,4H36c1,0,8-13.5,8-13.5" />
      <circle className="cls-2" cx="20.25" cy="42.25" r="4.75" />
      <circle className="cls-2" cx="35.25" cy="42.25" r="4.75" />
      <circle className="cls-2" cx="28.5" cy="14.5" r="12.5" />
      <text className="font-opensans-semibold" transform={length <= 1 ? "translate(24 20.55)" : "translate(20 20.55)"}>
        {quantity || 0}
      </text>
    </svg>
  );
};

export default memo(CartIcon, (props, nextProps) => {
  if (props.quantity === nextProps.quantity) {
    // Don't rerender/update
    return true;
  }
});
