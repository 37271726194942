/* eslint-disable react-hooks/exhaustive-deps */
import { FaChevronLeft } from "react-icons/fa";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMachine } from "@xstate/react";

// Components
import { AccountMachine } from "../../utils/stateMachines/accountHomeMachine";

const AccountTopBar = () => {
  const [current, send] = useMachine(AccountMachine);
  const location = useLocation();
  const navigate = useNavigate();
  const section = new URLSearchParams(location.search).get("t");
  const id = new URLSearchParams(location.search).get("id");

  // If a query param in the form of ?t=my-details
  // send an event to state machine so that section is displayed on mount
  // and whenever the query param changes

  useEffect(() => {
    if (section) {
      let event = (() => {
        // Split kebab case string coming from query params e.g my-details
        // into array
        const words = section.split("-");

        // Capitalize array words
        const upperCased = words.map((word) => word.toUpperCase());

        // Return event name with correct casing
        // e.g MY_DETAILS
        return upperCased.join("_");
      })();

      send({ type: event, id: id });
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    } else {
      // send event to go to idle state if no section
      send("BACK");
    }
  }, [section]);

  // Change the visible section
  const handleSectionChange = (sectionName) => {
    if (sectionName) {
      let newPath = location.pathname + `?t=${sectionName}`;
      navigate(newPath, { replace: true });
    } else {
      navigate(location.pathname, { replace: true });
    }
  };

  return (
    !current.matches("idle") && (
      <div className="w-full bg-white px-4 py-3 font-opensans mb-4 rounded-lg text-stone-600 flex justify-between sm:hidden">
        <div className="flex items-center cursor-pointer" onClick={() => handleSectionChange("")}>
          <FaChevronLeft className="mr-2" /> {"Back"}
        </div>
        <div className="flex items-center p-2 rounded-lg cursor-default">
          <h1 className="m-0 font-opensans-bold text-base md:text-lg text-stone-600">
            {current.value.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
              return str.toUpperCase();
            })}
          </h1>
        </div>
      </div>
    )
  );
};

export default AccountTopBar;
