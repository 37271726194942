import { useEffect } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";

// AUTH PAGES
import ForgotPassword from "../views/auth/ForgotPassword";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import ResetPassword from "../views/auth/ResetPassword";
// WEB APP PAGES
import Account from "../views/Account";
import Cart from "../views/shop/Cart";
import Checkout from "../views/shop/Checkout/Checkout";
import ContactUs from "../views/ContactUs";
import FailedPayment from "../views/shop/FailedPayment";
import FindAStore from "../views/FindAStore";
import FindAMediclinic from "../views/FindAMediclinic";
import Home from "../views/Home";
import Mediclinic from "../views/Mediclinic";
import Order from "../views/shop/Order";
import ErrorScreen from "../views/ErrorScreen";
import PersonalInfo from "../components/accounts/PersonalInfo";
import PersonalInfoEdit from "../components/accounts/PersonalInfoEdit";
import PrescriptionMedication from "../views/prescriptions/PrescriptionMedication";
import PrescriptionStatus from "../views/prescriptions/PrescriptionStatus";
import PrescriptionDetails from "../views/prescriptions/PrescriptionDetails";
import ProductDetails from "../views/shop/ProductDetails";
import ProductTypes from "../views/shop/ProductTypes";
import ReturnsExclusions from "../views/ReturnsExclusions";
import RequestAQuotation from "../views/RequestAQuotation";
import Store from "../views/shop/Store";
import StoreDetails from "../views/shop/StoreDetails";
import Categories from "../views/shop/Categories";
import ScheduleAnAppointment from "../views/ScheduleAnAppointment";
import ThankYou from "../views/shop/Thankyou";
// DOC PAGES
import BloodPressure from "../views/docs/BloodPressure";
import BecomeASupplier from "../views/docs/BecomeASupplier";
import Cancer from "../views/docs/Cancer";
import CareerCorporate from "../views/docs/CareerCorporate";
import CareerDistribution from "../views/docs/CareerDistribution";
import CareerInstore from "../views/docs/CareerInstore";
import CareerPharmacy from "../views/docs/CareerPharmacy";
import Careers from "../views/docs/Careers";
import CheckMyMeds from "../views/docs/CheckMyMeds";
import CookiePolicy from "../views/docs/CookiePolicy";
import CompanyInfo from "../views/docs/CompanyInfo";
import Disclaimer from "../views/docs/Disclaimer";
import EarPiercing from "../views/docs/EarPiercing";
import Fertility from "../views/docs/Fertility";
import FinancialAssistance from "../views/docs/FinancialAssistance";
import Faq from "../views/docs/Faq";
import Help from "../views/docs/Help";
import HelpAccount from "../views/docs/HelpAccount";
import HelpBalance from "../views/docs/HelpBalance";
import HelpCompanyInfo from "../views/docs/HelpCompanyInfo";
import HelpCoupon from "../views/docs/HelpCoupon";
import HelpCenterReturns from "../views/docs/HelpCenterReturns";
import HelpCheckout from "../views/docs/HelpCheckout";
import HelpCustomer from "../views/docs/HelpCustomer";
import HelpMyHealth from "../views/docs/HelpMyHealth";
import HelpMyHealthGoals from "../views/docs/HelpMyHealthGoals";
import HelpShopOnline from "../views/docs/HelpShopOnline";
import HelpStorePickup from "../views/docs/HelpStorePickup";
import HelpGiftCards from "../views/docs/HelpGiftCards";
import HelpShipping from "../views/docs/HelpShipping";
import HelpShipToStore from "../views/docs/HelpShipToStore";
import HelpPreOrder from "../views/docs/HelpPreOrder";
import HelpPromotion from "../views/docs/HelpPromotion";
import HelpPaymentMethod from "../views/docs/HelpPaymentMethod";
import HelpPharmacy from "../views/docs/HelpPharmacy";
import HelpPharmacyResources from "../views/docs/HelpPharmacyResources";
import HelpPharmacyServices from "../views/docs/HelpPharmacyServices";
import HelpLinkpharmacyStores from "../views/docs/HelpLinkpharmacyStores";
import HelpMobileSocial from "../views/docs/HelpMobileSocial";
import HelpReturns from "../views/docs/HelpReturns";
import Shipping from "../views/docs/Shipping";
import SocialResponsibility from "../views/docs/SocialResponsibility";
import Services from "../views/docs/Services";
import SpecialtyFaq from "../views/docs/SpecialtyFaq";
import Hiv from "../views/docs/Hiv";
import Insurance from "../views/docs/Insurance";
import Immunisation from "../views/docs/Immunisation";
import InStoreClinics from "../views/docs/InStoreClinics";
import MedicationCompounding from "../views/docs/MedicationCompounding";
import NoticePrivacy from "../views/docs/NoticePrivacy";
import OnlinePolicy from "../views/docs/OnlinePrivacy";
import ProductRecalls from "../views/docs/ProductRecalls";
import PackMyMeds from "../views/docs/PackMyMeds";
import ProgramDetails from "../views/docs/ProgramDetails";
import PharmacyQuestionnaire from "../views/docs/PharmacyQuestionnaire";
import PharmacyInsurance from "../views/docs/PharmacyInsurance";
import PharmacyManager from "../views/docs/PharmacyManager";
import PrivacyPolicy from "../views/docs/PrivacyPolicy";
import PharmacyServices from "../views/docs/PharmacyServices";
import RequestMedicalRecords from "../views/docs/RequestMedicalRecords";
import SellYourPharmacy from "../views/docs/SellYourPharmacy";
import SavingsAndDeals from "../views/docs/SavingsAndDeals";
import SpecialtyPharmacy from "../views/docs/SpecialtyPharmacy";
import SupplierKnowUs from "../views/docs/SupplierKnowUs";
import SupplierApply from "../views/docs/SupplierApply";
import SupplierRequirements from "../views/docs/SupplierRequirements";
import SupplierInclusion from "../views/docs/SupplierInclusion";
import SupplierSourcing from "../views/docs/SuppilerSourcing";
import Returns from "../views/docs/Returns";
import SupportAndServices from "../views/docs/SupportAndServices";
import SecurityPolicy from "../views/docs/SecurityPolicy";
import TermsOfUse from "../views/docs/TermsOfUse";
import TermsAndConditions from "../views/docs/TermsAndConditions";
import FluShots from "../views/docs/Flushots";
import LancetLaboratories from "../views/docs/LancetLaboratories";
import AppointmentBooking from "../views/docs/AppointmentBooking";

import StoreAttributesContext from "../context/StoreAttributesContext";
import ProtectedRoutes from "./protection/ProtectedRoutes";
import RefillsAndAutoRefills from "../views/docs/RefillsAndAutoRefills";
import SubCategories from "../views/shop/SubCategories";
import OrderStatus from "../components/accounts/OrderStatus";
import OnlineDoctor from "../views/docs/OnlineDoctor";
import SpecialOrders from "../views/docs/SpecialOrders";
import VerifiedRoutes from "./protection/VerifiedRoutes";
import VerifyEmail from "../views/partials/verifyEmail";
import PurchaseHistoryOrderDetail from "../views/shop/PurchaseHistoryOrderDetail";

const Routing = () => {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <Routes>
      {/** AUTH ROUTES */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/password-reset/:email" element={<ResetPassword />} />
      {/* MAIN ROUTES */}
      <Route path="/" element={<Outlet />}>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route
          path="/cart"
          element={
            <StoreAttributesContext>
              <Cart />
            </StoreAttributesContext>
          }
        />
        <Route
          path="/store/catalog"
          element={
            <StoreAttributesContext>
              <Categories />
            </StoreAttributesContext>
          }
        />
        <Route
          path="/store/products"
          element={
            <StoreAttributesContext>
              <Store />
            </StoreAttributesContext>
          }
        />
        <Route
          path="/store/products/page/:pageNo"
          element={
            <StoreAttributesContext>
              <Store />
            </StoreAttributesContext>
          }
        />
        <Route
          path={`/store/:catName/:catID/:subCatName/:subCatID/:valuesToGet`}
          element={
            <StoreAttributesContext>
              <Store />
            </StoreAttributesContext>
          }
        />
        <Route
          path={`/store/:catName/:catID/:subCatName/:subCatID/:valuesToGet/page/:pageNo`}
          element={
            <StoreAttributesContext>
              <Store />
            </StoreAttributesContext>
          }
        />
        <Route
          path="/store/catalog/:catName/:catID/:subCatName/:subCatID/:productType/:productTypeID"
          element={
            <StoreAttributesContext>
              <Store />
            </StoreAttributesContext>
          }
        />
        <Route
          path="/store/catalog/:catName/:catID/:subCatName/:subCatID/:productType/:productTypeID/page/:pageNo"
          element={
            <StoreAttributesContext>
              <Store />
            </StoreAttributesContext>
          }
        />
        <Route
          path="/store/catalog/:catName/:catID"
          element={
            <StoreAttributesContext>
              <SubCategories />
            </StoreAttributesContext>
          }
        />
        <Route
          path="/store/catalog/:catName/:catID/:subCatName/:subCatID"
          element={
            <StoreAttributesContext>
              <ProductTypes />
            </StoreAttributesContext>
          }
        />
        <Route
          path="/store/search/:searchValue"
          element={
            <StoreAttributesContext>
              <Store />
            </StoreAttributesContext>
          }
        />
        <Route
          path="/store/search/:searchValue/page/:pageNo"
          element={
            <StoreAttributesContext>
              <Store />
            </StoreAttributesContext>
          }
        />
        <Route
          path="/store/product/:id"
          element={
            <StoreAttributesContext>
              <ProductDetails />
            </StoreAttributesContext>
          }
        />
        <Route path="/store-details/:id" element={<StoreDetails />} />
        <Route path="/find-a-store" element={<FindAStore />} />
        <Route path="/find-a-mediclinic" element={<FindAMediclinic />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/pharmacy" element={<PharmacyServices />} />
        {/* PROTECTED ROUTES */}
        <Route element={<ProtectedRoutes />}>
          <Route path="/appointment-booking" element={<AppointmentBooking />} />
          <Route path="/schedule-an-appointment" element={<ScheduleAnAppointment />} />
          <Route path="/request-medical-records" element={<RequestMedicalRecords />} />
          <Route path="/shop/prescription-medication" element={<PrescriptionMedication />} />
          <Route path="/pharmacy-manager/:id" element={<PharmacyManager />} />
          <Route path="/failed-payment" element={<FailedPayment />} />
          <Route path="/verify-email" element={<VerifyEmail />} />

          <Route element={<VerifiedRoutes />}>
            <Route path="/account" element={<Account />} />
            <Route path="/upload-prescription" element={<PrescriptionMedication />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/order" element={<Order />} />
            <Route path="/order-status" element={<OrderStatus />} />
            <Route path="/prescription/:id" element={<PrescriptionDetails />} />
            <Route path="/prescription-status" element={<PrescriptionStatus />} />
            <Route path="/personal-information" element={<PersonalInfo />} />
            <Route path="/personal-information/edit" element={<PersonalInfoEdit />} />
            <Route path="/purchase-history-order-detail/:id" element={<PurchaseHistoryOrderDetail />} />
          </Route>
        </Route>

        {/** DOCS ROUTES START */}
        <Route path="/mediclinic" element={<Mediclinic />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/returns" element={<Returns />} />
        <Route path="/product-recalls" element={<ProductRecalls />} />
        <Route path="/request-quotation" element={<RequestAQuotation />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/help" element={<Help />} />
        <Route path="/help-returns" element={<HelpReturns />} />
        <Route path="/help-special-orders" element={<SpecialOrders />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/career/pharmacy" element={<CareerPharmacy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/returns-excluions" element={<ReturnsExclusions />} />
        <Route path="/corporate" element={<CareerCorporate />} />
        <Route path="/in-store" element={<CareerInstore />} />
        <Route path="/distribution" element={<CareerDistribution />} />
        <Route path="/company-info" element={<CompanyInfo />} />
        <Route path="/social-responsibility" element={<SocialResponsibility />} />
        <Route path="/become-a-supplier" element={<BecomeASupplier />} />
        <Route path="/sell-your-pharmacy" element={<SellYourPharmacy />} />
        <Route path="/pharmacy-insurance" element={<PharmacyInsurance />} />
        <Route path="/savings-and-deals" element={<SavingsAndDeals />} />
        <Route path="/flushots" element={<FluShots />} />
        <Route path="/immunisation" element={<Immunisation />} />
        <Route path="/in-store-clinics" element={<InStoreClinics />} />
        <Route path="/insurance-coverage" element={<Insurance />} />
        <Route path="/pack-my-meds" element={<PackMyMeds />} />
        <Route path="/specialty-pharmacy" element={<SpecialtyPharmacy />} />
        <Route path="/financial-assistance" element={<FinancialAssistance />} />
        <Route path="/specialty-faq" element={<SpecialtyFaq />} />
        <Route path="/cancer" element={<Cancer />} />
        <Route path="/fertility" element={<Fertility />} />
        <Route path="/hiv" element={<Hiv />} />
        <Route path="/support-and-services" element={<SupportAndServices />} />
        <Route path="/medication-compounding" element={<MedicationCompounding />} />
        <Route path="/check-my-meds" element={<CheckMyMeds />} />
        <Route path="/blood-pressure-screening" element={<BloodPressure />} />
        <Route path="/ear-piercing" element={<EarPiercing />} />
        <Route path="/services" element={<Services />} />
        <Route path="/lancet-labs" element={<LancetLaboratories />} />
        <Route path="/refills-and-auto-refills" element={<RefillsAndAutoRefills />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/program-details" element={<ProgramDetails />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/security-policy" element={<SecurityPolicy />} />
        <Route path="/online-policy" element={<OnlinePolicy />} />
        <Route path="/online-doctor" element={<OnlineDoctor />} />
        <Route path="/become-a-supplier" element={<BecomeASupplier />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/notice-privacy" element={<NoticePrivacy />} />
        <Route path="/pharmacy-services" element={<PharmacyServices />} />
        <Route path="/help-account" element={<HelpAccount />} />
        <Route path="/help-balance" element={<HelpBalance />} />
        <Route path="/help-myhealth" element={<HelpMyHealth />} />
        <Route path="/help-myhealth-goals" element={<HelpMyHealthGoals />} />
        <Route path="/help-shopping-online" element={<HelpShopOnline />} />
        <Route path="/help-store-pickup" element={<HelpStorePickup />} />
        <Route path="/help-pre-order" element={<HelpPreOrder />} />
        <Route path="/help-promotions" element={<HelpPromotion />} />
        <Route path="/help-coupon" element={<HelpCoupon />} />
        <Route path="/help-checkout" element={<HelpCheckout />} />
        <Route path="/help-payment" element={<HelpPaymentMethod />} />
        <Route path="/help-giftcards" element={<HelpGiftCards />} />
        <Route path="/help-shipping" element={<HelpShipping />} />
        <Route path="/help-center-returns" element={<HelpCenterReturns />} />
        <Route path="/help-ship-to-store" element={<HelpShipToStore />} />
        <Route path="/help-pharmacy" element={<HelpPharmacy />} />
        <Route path="/help-pharmacy-resources" element={<HelpPharmacyResources />} />
        <Route path="/help-linkpharmacy" element={<HelpPharmacyServices />} />
        <Route path="/help-customer" element={<HelpCustomer />} />
        <Route path="/help-link-stores" element={<HelpLinkpharmacyStores />} />
        <Route path="/help-company-info" element={<HelpCompanyInfo />} />
        <Route path="/help-mobile-social" element={<HelpMobileSocial />} />
        <Route path="/supplier-know-us" element={<SupplierKnowUs />} />
        <Route path="/supplier-apply" element={<SupplierApply />} />
        <Route path="/supplier-requirements" element={<SupplierRequirements />} />
        <Route path="/supplier-inclusion" element={<SupplierInclusion />} />
        <Route path="/supplier-sourcing" element={<SupplierSourcing />} />
        <Route path="/pharmacy-questionnaire" element={<PharmacyQuestionnaire />} />
        <Route path="*" element={<ErrorScreen />} />
      </Route>
    </Routes>
  );
};

export default Routing;
