import Layout from "../../components/Layout/Layout";
import styles from "../../styles/views/SpecialtyPharmacy.module.css";
import { Link } from "react-router-dom";

const SpecialtyPharmacy = () => {
  return (
    <Layout
      meta={{
        title: "Speciality Pharmacy | Link Pharmacy",
        keywords:
          "Speciality Pharmacy Zambia, Health Conditions Zambia, Complex Therapies Zambia, Lusaka, Kitwe, Ndola",
        description:
          "Our speciality pharmacy in Zambia provides complex therapies for serious health conditions. We offer medicines for rare health issues that would not typically be stocked by local pharmacies in Lusaka, Kitwe, Ndola, and across the country.",
      }}
      header
      footer
    >
      <main className={styles.mainHeader} role="main">
        <section className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">Speciality Pharmacy</h1>
        </section>
      </main>

      <section className="container p-3 font-opensans leading-normal">
        <p className="mb-3">
          We are able to assist people with serious health conditions requiring complex therapies by providing medicine
          that treats rare and complex health problems which would not normally be kept in stock by your local pharmacy.{" "}
        </p>

        <p className="mb-3">
          Our speciality pharmacy provides access to medication which may require special storage, handling or which may
          only be available through restricted or limited distribution.{" "}
        </p>

        <p>
          <Link to={"/contact-us"} className="font-opensans-semibold text-link-green-1">
            Get in touch
          </Link>{" "}
          with our pharmacy team for more advice and information
        </p>
      </section>
    </Layout>
  );
};

export default SpecialtyPharmacy;
