import BreadCrumbs from "../../components/BreadCrumbs";
import Layout from "../../components/Layout/Layout";
import StoreContent from "./StoreContent";
import StoreSideBar from "../../components/StoreSideBar";
import { meta } from "../../globals";

const Categories = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.storeDescription,
      }}
      header
      footer
      bgColor={"bg-stone-100"} // Make sure this color is available in your tailwind config
    >
      <div className="container mx-auto mt-2">
        <BreadCrumbs
          crumbs={[
            { name: "Home", link: "/" },
            { name: `Shop`, link: "/#" },
          ]}
        />

        <div className="flex flex-col md:flex-row mt-1 space-x-0 md:space-x-10">
          <div className="w-full md:w-3/12">
            <StoreSideBar />
          </div>
          <div className="w-full md:w-9/12">
            <StoreContent />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Categories;
