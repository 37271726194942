import HelpCenterSidebar from "../../components/HelpCenterSidebar";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/HelpCenter.module.css";
import React from "react";

const Help = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="shipping_help_main">
        <section>
          <div className={style.shipping_help_bg}>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className={style.shipping_help_header}>
                  <span>Help Center</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <HelpCenterSidebar />
            </div>
            <div className="col-md-6">
              <div className={style.shipping_help}>
                <p className="font-opensans-bold mb-3">Prescriptions for Persons Other than Yourself</p>
                <p className="font-opensans text-sm mb-3">
                  If the prescription is for a person other than yourself, you will need to have the authority of the
                  person whose prescription it is to have it dispensed by us. By placing an order to dispense another
                  person’s prescription you confirm that you have their authority to do so.
                </p>

                <p className="font-opensans-bold mb-3">Non-Availability/Suitability of Products</p>
                <p className="font-opensans text-sm mb-3">
                  If any of the items on your prescription is not available, or is not suitable for dispensing through
                  this service, we will try to contact using the information in your registration and refer you
                  accordingly.
                </p>
                <p className="font-opensans-bold mb-3">Cancellation of Orders</p>
                <p className="font-opensans text-sm mb-3">
                  Once you make a payment through your credit or debit card, you no longer can cancel the order. Please
                  contact our customer care line and we will advise you on how to proceed.
                </p>

                <p className="font-opensans-bold mb-3">Order Delivery</p>
                <p className="font-opensans text-sm mb-3">
                  Pharmacy Medicines will need to be signed for by someone aged 18 or over. If there isn't anyone in who
                  can sign for your order, you can change your delivery date or redirect it to a local parcel shop using
                  the courier's website or app.
                </p>
                <p className="font-opensans text-sm mb-3">
                  Unfortunately, your order can't be left in a safe place or with a neighbour. The courier may attempt
                  to deliver your parcel twice, before contacting you to arrange for collection from their depot or
                  return it to us.
                </p>
                <p className="font-opensans-bold mb-3">Payment</p>
                <p className="font-opensans text-sm mb-3">
                  In providing payment card details, you confirm that you are authorised to use the card and authorise
                  us or our payment service provider to take payment in full for the items in your order, postage and
                  packing charges and any other charges that become due to us under these terms.
                </p>
                <p className="font-opensans text-sm mb-3">
                  Our payment service providers are secure and easy to use for making payment, verification and
                  authentication. We accept purchase payments via: VISA, MasterCard, Mobile Money – Airtel, Mobile Money
                  – MTN, Mobile Money – Zamtel and PayPal.
                </p>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default Help;
