import { Container, Paper } from "@mui/material";
import React, { useContext } from "react";
import Layout from "../../../components/Layout/Layout";
import { meta } from "../../../globals";
import { AuthContext } from "../../../context/AuthContext";
import styles from "./styles.module.css";
import { useMutation } from "react-query";
import axios from "axios";
import { useNotifications } from "../../../context/NotificationsContext";

const Index = () => {
  const { user } = useContext(AuthContext);
  const { pushNotification } = useNotifications();

  const { isLoading, mutate } = useMutation(
    ["resend-email"],
    async () => {
      const { data } = await axios.post("/auth/email/verification-notification", {
        email: user.email,
      });
      return data;
    },
    {
      onSuccess: () => {
        pushNotification({
          id: Math.random(),
          type: "Success",
          message: "Email sent successfully",
        });
      },
      onError: (error) => {
        pushNotification({
          id: Math.random(),
          type: "Error",
          message: "An error occurred while sending email, If the problem persists contact support",
        });
      },
    }
  );

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
      bgColor={"#fff"}
    >
      <Container className="py-3 flex items-center justify-center">
        <Paper elevation={1} className={styles.card}>
          <h1 className="font-opensans-semibold text-[18px] text-center mb-4">
            We've sent a verification email to: <br /> {user.email}
          </h1>

          <p className="font-opensans text-sm text-center mb-2">
            To Continue using Link Pharmacy, please verify your email address.
          </p>

          <p className="font-opensans text-sm text-center">
            Click the email in your email to verify your account. If you can't find the email check your spam folder or{" "}
            <button
              disabled={isLoading}
              className="text-lime-700 font-opensans-semibold cursor-pointer hover:text-lime-800 disabled:text-lime-800 disabled:cursor-not-allowed transition-all ease-in"
              onClick={() => mutate()}
            >
              Click here to resend.
            </button>
          </p>
        </Paper>
      </Container>
    </Layout>
  );
};

export default Index;
