import React from "react";

interface Props {
  meta: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
  changePage: (page: number) => void;
}

const Pagination: React.FC<Props> = ({ meta, changePage }) => {
  const { current_page, last_page } = meta;
  const numberOfPagesToShowOnEitherSide = 1;

  let startPage = 1;
  let endPage = last_page;
  let pages: any = [];

  if (last_page <= 2 * numberOfPagesToShowOnEitherSide + 3) {
    // No need for ellipsis
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
  } else if (current_page <= numberOfPagesToShowOnEitherSide + 2) {
    // Show ellipsis only at the end
    for (let i = startPage; i <= numberOfPagesToShowOnEitherSide + 2; i++) {
      pages.push(i);
    }
    pages.push("...");
    pages.push(endPage);
  } else if (current_page >= last_page - numberOfPagesToShowOnEitherSide - 1) {
    // Show ellipsis only at the start
    pages.push(startPage);
    pages.push("...");
    for (let i = last_page - numberOfPagesToShowOnEitherSide - 1; i <= endPage; i++) {
      pages.push(i);
    }
  } else {
    // Show ellipsis at both ends
    pages.push(startPage);
    pages.push("...");
    for (
      let i = current_page - numberOfPagesToShowOnEitherSide;
      i <= current_page + numberOfPagesToShowOnEitherSide;
      i++
    ) {
      pages.push(i);
    }
    pages.push("...");
    pages.push(endPage);
  }

  return (
    <nav className="isolate inline-flex -space-x-px rounded-md shadow-xs" aria-label="Pagination">
      <button
        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        onClick={() => changePage(Math.max(current_page - 1, 1))}
        disabled={current_page === 1}
      >
        <span className="sr-only">Previous</span>
        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      {pages.map((page, index) =>
        page === "..." ? (
          <span
            key={index}
            className="relative inline-flex items-center px-3 text-sm font-opensans-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
          >
            ...
          </span>
        ) : (
          <button
            key={page}
            onClick={() => changePage(page)}
            className={`relative inline-flex items-center px-3 text-sm font-opensans-semibold ring-1 ring-inset ring-gray-300 hover:opacity-90 focus:z-20 focus:outline-offset-0 ${
              page === current_page ? "bg-link-green-1 text-white" : "text-gray-900"
            }`}
          >
            {page}
          </button>
        )
      )}

      <button
        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        onClick={() => changePage(Math.min(current_page + 1, last_page))}
        disabled={current_page === last_page}
      >
        <span className="sr-only">Next</span>
        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </nav>
  );
};

export default Pagination;
