import { useRef } from "react";
import { Link } from "react-router-dom";
import { NoiseControlOff, LocationOnOutlined, CallOutlined, WhatsApp, DraftsOutlined } from "@mui/icons-material";
import Layout from "../components/Layout/Layout";
import mediclinic_logo from "../assets/images/mediclinic-logo-transparent.png";
import styles from "../styles/views/Mediclinic.module.css";
import { Formik } from "formik";
import { Button, Input, TextArea } from "../components/CustomComponents";
import { useMutation } from "react-query";
import axios from "axios";
import { useNotifications } from "../context/NotificationsContext";
import validationSchemas from "../utils/validationSchemas";

const Mediclinic = () => {
  const { pushNotification } = useNotifications();

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const appointRef = useRef();

  const { mutate, isLoading } = useMutation(
    ["make-appointment"],
    async (values) => {
      const { data } = await axios.post("v1/website/emails/wellness-clinic", {
        name: values.name,
        email: values.email,
        message: values.message,
        phone_number: values.phoneNumber,
      });
      return data;
    },
    {
      onSuccess: (data, variables) => {
        pushNotification({
          type: "Success",
          message: "Thank you for your message. We will get back to you shortly.",
          id: Math.random(),
        });

        variables?.resetForm?.();
      },
      onError: (error, variables) => {
        pushNotification({
          type: "Error",
          message: "An error occurred. Please try again. If the problem persists, please contact customer care.",
          id: Math.random(),
        });
      },
    }
  );

  return (
    <Layout
      meta={{
        title: "Mediclinic | Link Pharmacy",
        keywords:
          "Link Pharmacy, Mediclinic, Wellness Clinic, Link Mediclinic, Link Pharmacy Mediclinic, Link Pharmacy Wellness Clinic, Link Pharmacy Mediclinic Lusaka, Link Pharmacy Wellness Clinic Lusaka, Link Pharmacy Mediclinic Zambia, Link Pharmacy Wellness Clinic Zambia, Link Pharmacy Mediclinic Pinnacle Mall, Link Pharmacy Wellness Clinic Pinnacle Mall, Link Pharmacy Mediclinic Chindo Road, Link Pharmacy Wellness Clinic Chindo Road, Link Pharmacy Mediclinic Mutandwa Road, Link Pharmacy Wellness Clinic Mutandwa Road, Link Pharmacy Mediclinic Lusaka Zambia, Link Pharmacy Wellness Clinic Lusaka Zambia, Link Pharmacy Mediclinic Pinnacle Mall Lusaka, Link Pharmacy Wellness Clinic Pinnacle Mall Lusaka, Link Pharmacy Mediclinic Chindo Road Lusaka, Link Pharmacy Wellness Clinic Chindo Road Lusaka, Link Pharmacy Mediclinic Mutandwa Road Lusaka, Link Pharmacy Wellness Clinic Mutandwa Road Lusaka, Link Pharmacy Mediclinic Zambia, Link Pharmacy Wellness Clinic Zambia",
        description: "Link Pharmacy Mediclinic, Lusaka, Zambia, Pinnacle Mall, Chindo Road, Mutandwa Road",
      }}
      header
      footer
    >
      <div className={styles.mediclinic_header_container}>
        <div className="col-md-12">
          <div className={styles.linkmed_bg_img}>
            <div className={"bg-[#10101061]"}>
              <div className={styles.top_contact_nav}>
                <p>
                  <CallOutlined /> <span>+260 211 261 026</span>
                </p>
                <p>
                  <LocationOnOutlined /> <span>Shop 28, Pinnacle Mall, Lusaka</span>
                </p>
              </div>
              <div className="d-flex flex-col items-center py-[100px] md:py-[200px] mb-[100px]">
                <div className={"w-80 md:w-[400px] mb-3"}>
                  <img src={mediclinic_logo} alt="Link Mediclinic" />
                </div>
                <p className="font-opensans text-xl md:text-3xl">Your Health. Our Priority</p>
                <div className={"d-flex items-center space-x-2 mt-2"}>
                  <Button href={"/schedule-an-appointment"}>Make an appointment</Button>
                  <Button href={"#book_whatsapp"}>Book on WhatsApp</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={styles.wellness_serv_wrapper}>
              <div className="p-4 flex-1 bg-link-green">
                <h5 className={"font-opensans-semibold mb-2"}>About us</h5>
                <p className="font-opensans leading-normal text-sm">
                  Our general outpatient clinic, Link mediclinic is attached to Link pharmacy and is conveniently
                  accessible to the community it serves.
                </p>
              </div>
              <div className="p-4 flex-1 bg-[#32CD32]">
                <h5 className={"font-opensans-semibold mb-2"}>Opening Hours</h5>

                <ul className={"font-opensans leading-normal text-sm"}>
                  <li>Mon - Fri 9am - 7pm</li>
                  <li>Saturdays 9am - 6pm</li>
                  <li>Sun & Public holidays 10am - 3pm</li>
                </ul>
              </div>
              <div className="p-4 flex-1 bg-[#98FF98]">
                <h5 className={"font-opensans-semibold mb-2"}>Contact Us</h5>
                <ul className="font-opensans leading-normal text-sm mb-2">
                  <li>
                    <DraftsOutlined />{" "}
                    <Link to="mailto:mediclinic.pinnacle@linkpharmacy.co.zm">
                      mediclinic.pinnacle@linkpharmacy.co.zm
                    </Link>
                  </li>
                  <li id={"book_whatsapp"}>
                    <WhatsApp /> +260976 255 557
                  </li>
                  <li>
                    <CallOutlined /> +260 211 261 026
                  </li>
                </ul>

                <h5 className={"font-opensans-semibold mb-2"}>Address</h5>
                <p className="font-opensans leading-normal text-sm">
                  <LocationOnOutlined /> Shop 28, Pinnacle Mall, Corner Chindo & Mutandwa roads, Lusaka, Zambia
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="mediclinic_services">
          <div className={styles.health_service_header}>
            <h2 className={"font-opensans-bold mb-1 text-3xl"}>Our Services</h2>
            <p className="font-opensans text-gray-500 text-sm">Here are the services we offer</p>
          </div>
          <div className={styles.service_cont}>
            <div className={styles.service_cont_1}>
              <div className="service_cont_1_cont">
                <div className={styles.service_flex}>
                  <div>
                    <NoiseControlOff />
                  </div>
                  <div>
                    <h5 className="font-opensans-semibold mb-1">Blood pressure monitoring</h5>
                    <p className={"font-opensans leading-normal"}>
                      We will assist you with any health concerns and in room procedures including ear wax irrigation &
                      ECGs.
                    </p>
                  </div>
                </div>
                <div className={styles.service_flex}>
                  <div>
                    <NoiseControlOff />
                  </div>
                  <div>
                    <h5 className="font-opensans-semibold mb-1">Telehealth consultations</h5>
                    <p className={"font-opensans leading-normal"}>
                      Consult with a doctor or nurse virtually, via telephone or video, and receive tailored medical
                      advice for your health concerns.
                    </p>
                  </div>
                </div>
                <div className={styles.service_flex}>
                  <div>
                    <NoiseControlOff />
                  </div>
                  <div>
                    <h5 className="font-opensans-semibold mb-1">Preventative care, health education & advice</h5>
                    <p className={"font-opensans leading-normal"}>
                      Receive tailored advice on stopping smoking, weight management and more.
                    </p>
                  </div>
                </div>
                <div className={styles.service_flex}>
                  <div>
                    <NoiseControlOff />
                  </div>
                  <div>
                    <h5 className="font-opensans-semibold mb-1">Disease Counselling & Health Education</h5>
                    <p className={"font-opensans leading-normal"}>
                      Receive advice on long-term management of your asthma, diabetes, high blood pressure, cholesterol,
                      and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="clinics">
          <div className={styles.health_service_header}>
            <h2 className={"font-opensans-bold text-3xl"}>Clinics</h2>
          </div>
          <div className={styles.service_cont}>
            <div className={styles.service_cont_1}>
              <div className="service_cont_1_cont">
                <div className={styles.service_flex}>
                  <div>
                    <NoiseControlOff />
                  </div>
                  <div>
                    <h5 className="font-opensans-semibold mb-1">Medicals & physical health checks Get a physical</h5>
                    <p className={"font-opensans leading-normal"}>
                      assessment for employment, insurance policy or other purposes.
                    </p>
                  </div>
                </div>
                <div className={styles.service_flex}>
                  <div>
                    {/* <div className="row">
                      <h2>Appointment Booking</h2>
                      <p>We offer comprehensive, community based care.</p>
                      <p>
                        Walk - ins welcome or{" "}
                        <Link to={"#book_an_appointment"}>
                          {"book an appointment"}
                        </Link>{" "}
                        to see a doctor or nurse at link medicliic
                      </p>
                    </div> */}
                    <NoiseControlOff />
                  </div>
                  <div>
                    <h5 className="font-opensans-semibold mb-1">Travel</h5>
                    <p className={"font-opensans leading-normal"}>
                      When you travel abroad, you may be exposed to diseases you haven’t come across before. Our travel
                      clinic helps assess your risk of contracting these diseases and will advise on precautions to take
                      as well as which vaccinations and medications will assist in protecting you.{" "}
                    </p>
                  </div>
                </div>
                <div className={styles.service_flex}>
                  <div>
                    <NoiseControlOff />
                  </div>
                  <div>
                    <h5 className="font-opensans-semibold mb-1">Stop Smoking </h5>
                    <p className={"font-opensans leading-normal"}>
                      Have your blood sugar tested to allow you to manage your diabetes. Find out what foods to eat to
                      control high blood sugar levels.
                    </p>
                  </div>
                </div>
                <div className={styles.service_flex}>
                  <div>
                    <NoiseControlOff />
                  </div>
                  <div>
                    <h5 className="font-opensans-semibold mb-1">Weight Counselling </h5>
                    <p className={"font-opensans leading-normal"}>
                      We offer female health assessments including breast examinations, pregnancy tests, cervical smears
                      and other health screening tests. We also offer administration of contraceptive injections.
                    </p>
                  </div>
                </div>
                <div className={styles.service_flex}>
                  <div>
                    <NoiseControlOff />
                  </div>
                  <div>
                    <h5 className="font-opensans-semibold mb-1">Blood pressure monitoring</h5>
                    <p className={"font-opensans leading-normal"}>
                      You will be able to manage arterial blood pressure levels and understand your readings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="immunization">
          <div className={styles.health_service_header}>
            <h2 className={"font-opensans-bold text-3xl"}>Immunisation</h2>
          </div>
          <div className={styles.service_cont}>
            <div className={styles.service_cont_1}>
              <div className="service_cont_1_cont">
                <div className={styles.service_flex}>
                  <div>
                    <NoiseControlOff />
                  </div>
                  <div>
                    <h5 className="font-opensans-semibold mb-1">Wound care services</h5>
                    <p>We will assist you in managing simple burns and wounds (including suture removal).</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="book_appointment_wrapper" ref={appointRef}>
          <div id="form">
            <div className={styles.book_appointment_flex}>
              <div className={styles.book_appointment_wrapper}>
                <h3 className="font-opensans-semibold text-xl mb-3 mt-3">
                  Book an appointment to see a doctor or nurse today!
                </h3>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, formikHelpers) => {
                    mutate({ ...values, formikHelpers });
                  }}
                  validationSchema={validationSchemas.contactUs}
                >
                  {({ values, handleChange, handleBlur, errors, touched, handleSubmit }) => (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      className="shadow-sm m-2 p-2"
                    >
                      <div className={styles.book_appoint_cont}>
                        <Input
                          label={"Full Name"}
                          name={"name"}
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.name}
                          touched={touched.name}
                          required={true}
                        />
                      </div>
                      <div className={styles.book_appoint_cont}>
                        <Input
                          label={"Email"}
                          name={"email"}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.email}
                          touched={touched.email}
                          required={true}
                        />
                      </div>
                      <div className={styles.book_appoint_cont}>
                        <Input
                          label={"Phone Number"}
                          name={"phoneNumber"}
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.phoneNumber}
                          touched={touched.phoneNumber}
                          required={true}
                        />
                      </div>
                      <div className={styles.book_appoint_cont}>
                        <TextArea
                          label={"Message"}
                          name={"message"}
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.message}
                          touched={touched.email}
                          rows={5}
                          required={true}
                        />
                      </div>
                      <div className={styles.book_appoint_cont}>
                        <Button disabled={isLoading} type="submit" width={"full"}>
                          {isLoading ? <Button.Loader label={"Loading..."} /> : "Make Appointment"}
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Mediclinic;
