import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { meta } from "../globals";
import { Button, Feedback } from "../components/CustomComponents";

const ErrorScreen = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <Feedback
        errorCode={"404"}
        title={"Page Not Found"}
        message={"Sorry, we can't find the page you're looking for. Please check the URL or return to the homepage"}
        actions={[
          <Button variant="secondary" width={"[150px]"} onClick={handleBackClick}>
            Go Back
          </Button>,
          <Button width={"[150px]"} onClick={() => navigate("/")}>
            Go Home
          </Button>,
        ]}
      />
    </Layout>
  );
};

export default ErrorScreen;
