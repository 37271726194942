import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";

const RefillsAndAutoRefills = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      {/*<h1>Refills and Auto Refills</h1>*/}
      {/*<h2>What is ZAMRA stance on this?</h2>*/}
    </Layout>
  );
};

export default RefillsAndAutoRefills;
