import React from "react";

const PackMyMedsTab = () => {
  return (
    <div>
      <div className="w-full flex border-b px-4 py-3">
        <h2 className="m-0 font-opensans-medium text-2xl">Pack My Meds</h2>
      </div>

      <div className="p-6 overflow-y-hidden  pb-4">
        <p>Do you need help managing your medicines.</p>
        <p>
          With the pack my meds service your medication is arranged in weekly blister packs and the tablets are
          separated into compartments based on the day of the week
        </p>
        <p>
          Sign up for the pack my meds service
          <input type={"checkbox"} />
        </p>
      </div>
    </div>
  );
};

export default PackMyMedsTab;
