import * as Yup from "yup";

const contactUsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  email: Yup.string().email("Please enter a valid email address.").required("Email is required."),
  message: Yup.string().required("Message is required."),
  phoneNumber: Yup.string().required("Phone Number is required."),
});

export default contactUsSchema;
