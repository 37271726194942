import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { IoChevronUp } from "react-icons/io5";

// Components
import Layout from "../../components/Layout/Layout";
import { meta, programDetailsDisclosure } from "../../globals";
import style from "../../styles/views/programDetails.module.css";
import grandpa from "../../assets/images/grandpa.png";
import stayHealth from "../../assets/images/stay_health.jpg";
import healthKids from "../../assets/images/Healthy_Kids.jpg";
import trophy from "../../assets/images/free_trophy_1.jpg";
import smile from "../../assets/images/smile.jpg";
import gift from "../../assets/images/gift_1.png";

const ProgramDetails = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div style={{ padding: "0", marginTop: "-1px" }}>
        <div
          className={"container-fluid pt-1 pb-5"}
          style={{ margin: "0", padding: "0" }}
        >
          <div
            className={"container-md container-sm container-xs container mt-5"}
          >
            <div
              className={"row mb-3 align-items-center justify-content-center"}
            >
              <div className={"col-md-6 text-center"}>
                <h2
                  className={style.title}
                  style={{ fontFamily: "cookie", color: "red" }}
                >
                  <span className={"text-dark"}>my</span>Linkpharmacy
                  <sup>TM</sup>
                </h2>
              </div>
              <div className={"col-md-6 text-center"}>
                <div>
                  <button
                    className={"btn btn-danger mt-3 mb-3"}
                    style={{ borderRadius: "100px", padding: "9px 19px" }}
                  >
                    Join FREE now
                  </button>
                </div>
                <div>
                  <p>
                    Already a myLinkpharmacy member?{" "}
                    <strong>
                      <Link to={"/login"}>Sign in</Link>
                    </strong>
                  </p>
                </div>
              </div>
            </div>

            <div className={"row mb-4 align-items-center"}>
              <div className={"col-md-6"}>
                <p
                  style={{
                    fontFamily: "cookie",
                    fontSize: "3.2em",
                    marginBottom: "-43px",
                    color: "red",
                  }}
                >
                  Your
                </p>
                <h2 className={style.personalizedExperienceText}>
                  personalized <br />
                  experience <br />
                  starts here!
                </h2>
              </div>
              <div className={"col-md-6"}>
                <div style={{ height: "max-content", textAlign: "center" }}>
                  <img
                    src={grandpa}
                    alt={""}
                    style={{
                      objectFit: "fill",
                      width: "100%",
                      display: "block",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={"row text-md-center mt-sm-4 pt-sm-4 "}>
              <div className={"offset-md-2 col-md-8"}>
                <div
                  style={{ fontWeight: "700", fontSize: "1.2em" }}
                  className={"mb-2"}
                >
                  We waved goodbye to Balance® Rewards to bring you a more
                  rewarding experience: myLinkpharmacy is an easier way to save,
                  shop and stay well.*
                </div>
                <div
                  style={{ fontWeight: "700", fontSize: "1.2em", color: "red" }}
                >
                  <Link to={"#"} style={{ color: "red" }}>
                    See all the benefits ›
                  </Link>
                </div>
              </div>
            </div>

            <div className={"row text-md-center mt-4"}>
              <div style={{ fontWeight: "700", fontSize: "1.1em" }}>
                <span>Questions about myLinkpharmacy?</span>&nbsp;
                <p
                  className={"d-md-inline d-sm-block"}
                  style={{ color: "red" }}
                >
                  <Link to={"#"} style={{ color: "red" }}>
                    Get answers ›
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={"container-fluid"}
          style={{ paddingTop: "30px", paddingBottom: "30px" }}
        >
          <div
            className={
              "container-md container-sm container-xs container pt-2 pb-2 text-md-center"
            }
            style={{ color: "#000", fontWeight: "500" }}
          >
            <div className={"row align-items-center"}>
              <div>
                <div
                  className={style.connectingYouText}
                  style={{ fontWeight: "500", margin: "0", padding: "0" }}
                >
                  We're connecting
                </div>
                <div
                  className={style.connectingYouSubText}
                  style={{ fontWeight: "500", marginTop: "0", padding: "0" }}
                >
                  you to more
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"container-fluid"}>
          <div
            className={
              "container-md container-sm container-xs container pt-5 pb-5"
            }
          >
            <div className={"row align-items-center"}>
              <div
                className={"col-md-6  order-sm-1 order-2 mt-sm-3 mt-xs-3 mt-3"}
              >
                <h1>More rewards, more savings</h1>
                <p>Start earning</p>
                <div className={"d-md-flex"}>
                  <div className={"pb-sm-0 mb-sm-0"}>
                    <h1
                      style={{
                        margin: "0",
                        padding: "0",
                        fontWeight: "500",
                        paddingRight: "15px",
                      }}
                    >
                      1<sup>%</sup>
                    </h1>
                  </div>
                  <div className={"pt-sm-0 mt-sm-0"}>
                    Linkpharmacy Cash rewards storewide, even at the pharmacy!
                  </div>
                </div>

                <div className={"d-md-flex mt-3"}>
                  <div className={"pb-sm-0 mb-sm-0"}>
                    <h1
                      style={{
                        margin: "0",
                        padding: "0",
                        fontWeight: "500",
                        paddingRight: "15px",
                      }}
                    >
                      5<sup>%</sup>
                    </h1>
                  </div>
                  <div className={"pt-sm-0 mt-sm-0"}>
                    on Linkpharmacy branded products
                  </div>
                </div>

                <div className={"mt-3"}>
                  <p>Plus, you'll automatically unlock sale prices!</p>
                </div>
              </div>

              <div className={"col-md-6 order-md-1"}>
                <div style={{ height: "max-content" }}>
                  <img
                    src={stayHealth}
                    style={{ objectFit: "fill", width: "100%" }}
                    alt={""}
                  />
                </div>
              </div>
            </div>

            <div className={"row pt-5 pb-1"}>
              <div className={"col-md-5 offset-md-7"}>
                <h2>More time back</h2>
                <p>Try Pickup or Same Day Delivery today!</p>
                <ul>
                  <li>
                    Pickup orders ready in as little as 30 minutes curbside,
                    drive-thru or in store
                  </li>
                  <li>Delivery in as little as 1 hour</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={"container-fluid"}>
          <div
            className={
              "container-md container-sm container-xs container pt-5 pb-5"
            }
          >
            <div className="row mb-5">
              <div className={"offset-md-1 col-md-10 text-center"}>
                <h1 style={{ fontWeight: "700", fontSize: "1.9em" }}>
                  You'll love it even more
                  <br />
                  because we'll help{" "}
                  <span
                    style={{
                      fontFamily: "cookie",
                      fontSize: "2.em",
                      color: "red",
                    }}
                  >
                    you
                  </span>
                </h1>
              </div>
            </div>

            <div className="row pt-md-5 pb-md-5 align-items-center">
              <div className={"col-md-6"}>
                <div style={{ height: "max-content" }}>
                  <img
                    src={healthKids}
                    alt={""}
                    style={{ objectFit: "fill", width: "100%" }}
                  />
                </div>
              </div>

              <div className={"col-md-6 "}>
                <div className={"d-md-flex flex-column align-items-center"}>
                  <div>
                    <h2 style={{ fontSize: "2.3em" }}>
                      <strong>
                        Stay
                        <br />
                        healthy
                      </strong>
                    </h2>
                    <p
                      className={"mb-sm-4"}
                      style={{ fontSize: "1.2em", fontWeight: "500" }}
                    >
                      We'll keep you informed
                      <br />
                      with real-time environmental
                      <br />
                      and health forecasts
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-md-5 pb-md-5 mt-5 mb-5 align-items-center">
              <div className={"col-md-6 order-md-1 order-2 mt-3"}>
                <h2 className={"mt-sm-4"} style={{ fontSize: "2.3em" }}>
                  <strong>
                    Achieve
                    <br />
                    goals
                  </strong>
                </h2>
                <p
                  className={"mb-sm-4"}
                  style={{ fontSize: "1.2em", fontWeight: "500" }}
                >
                  Earn bonus Linkpharmacy Cash rewards
                  <br /> for reaching your health goals
                </p>
              </div>

              <div className={"col-md-6 order-md-2 order-1"}>
                <div
                  style={{ height: "max-content" }}
                  className={"text-md-center"}
                >
                  <img
                    src={trophy}
                    alt={""}
                    style={{ objectFit: "fill", height: "150px" }}
                  />
                </div>
              </div>
            </div>

            <div className="row pt-md-5 pb-md-5 mt-5 mb-5 align-items-center">
              <div className={"col-md-6"}>
                <div
                  style={{ height: "max-content", marginBottom: "9px" }}
                  className={"text-md-center"}
                >
                  <img
                    src={gift}
                    alt={""}
                    style={{ objectFit: "fill", height: "150px" }}
                  />
                </div>
              </div>
              <div className={"col-md-6"}>
                <div className={"p-md-5"}>
                  <h2 className={"mt-sm-4"} style={{ fontSize: "2.3em" }}>
                    <strong>Give back</strong>
                  </h2>
                  <p
                    className={"mb-sm-4"}
                    style={{ fontSize: "1.2em", fontWeight: "500" }}
                  >
                    Donate your Linkpharmacy Cash
                    <br /> rewards to causes you care about
                  </p>
                </div>
              </div>
            </div>

            <div className="row pt-md-5 pb-md-5 align-items-center">
              <div className={"col-md-6 order-md-1 order-2"}>
                <h2 className={"mt-sm-4"} style={{ fontSize: "2.3em" }}>
                  <strong>
                    Make it
                    <br />
                    your own
                  </strong>
                </h2>
                <p
                  className={"mb-sm-4"}
                  style={{ fontSize: "1.2em", fontWeight: "500" }}
                >
                  Get personalized deals,
                  <br />
                  product recommendations
                  <br />
                  and more—for the one and
                  <br />
                  only you.
                </p>
              </div>
              <div className={"col-md-6 order-md-2 order-1 mb-3"}>
                <div style={{ height: "max-content" }}>
                  <img
                    src={smile}
                    alt={""}
                    style={{ objectFit: "fill", width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"container-fluid"}>
          <div
            className={
              "container-md container-sm container-xs container pt-5 pb-5"
            }
          >
            <h1 style={{ fontSize: "3.8em" }}>
              Even
              <br />
              better,
              <br />
              it's free
              <br />
              to join!
            </h1>
          </div>
        </div>

        <div className={"container-fluid"}>
          <div
            className={
              "container-md container-sm container-xs container pt-5 pb-5"
            }
          >
            <div className="row text-center">
              <h2 style={{ fontWeight: "700" }}>
                Join myLinkpharmacy FREE in seconds
              </h2>
              <h5>Discover an easier way to save, shop and stay well.</h5>
              <p>
                <Link
                  to={"#"}
                  className={"btn btn-danger mt-3 mb-3"}
                  style={{ borderRadius: "100px", padding: "9px 19px" }}
                >
                  Get started
                </Link>
              </p>
              <p>
                Already a member?{" "}
                <Link to={"#"}>
                  <strong>Sign in</strong>
                </Link>
              </p>
            </div>
          </div>
        </div>

        <section id="myhealth_faq">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className={style.faq_header}>
                  <h4>Frequently Asked Question</h4>
                </div>
                <div className={style.faq_cont}>
                  {programDetailsDisclosure.map((item, index) => (
                    <Disclosure as="div" className="mt-2" key={index}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                            <span>{item.header}</span>
                            <IoChevronUp
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-green-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-green-500">
                            <div className={style.faq_answer}>{item.panel}</div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="terms_and_condions">
          <div className="row">
            <div className="col-md-12">
              <div className="container">
                <div className={style.myhealth_terms_and_condition}>
                  <h4>myHealth rewards Terms & Conditions</h4>
                  <p>
                    2021. You must enroll in myHealth by January 31, 2021, in
                    order not to lose any Link pharmacy Cash rewards and have
                    your account deactivated. Balance Rewards terms and
                    conditions still apply to current members who do not enroll
                    with myHealth.
                  </p>
                  <ol>
                    <li>
                      Must be a myHealth<sup>™</sup> member. Link pharmacy Cash
                      rewards are not legal tender. No cash back Link pharmacy
                      Cash rewards good on future purchases. Rewards cannot be
                      earned on photo orders not picked up in store, alcohol,
                      dairy, tobacco, gift cards, sales tax and shipping, or
                      items or services sold by third-party partners. Rewards on
                      prescriptions and other pharmacy items and services cannot
                      be earned in AR, NJ or NY. Only prescriptions picked up in
                      store are eligible to earn rewards. Link pharmacy Cash
                      rewards applied after all discounts, percentages off and
                      manufacturers' coupons. Other exclusions apply. For
                      complete details, see terms and conditions.
                    </li>
                    <li>
                      {" "}
                      Must be a myHealth™ member. Link pharmacy Cash rewards are
                      not legal tender. No cash back. Link pharmacy Cash rewards
                      good on future purchases.
                    </li>
                    <li>
                      Some in-app features may not be available for launch.
                      Update your app to get the latest.
                    </li>
                    <li>
                      Limitations and restrictions apply, including earning Link
                      pharmacy Cash rewards. Information provided to Walgreens
                      for myHealth health goals is subject to the terms and
                      conditions of myHealth and is protected in accordance with
                      the Online Privacy and Security Policy. Please allow up to
                      48 hours for Link pharamcy Cash rewards to appear in your
                      myHealth account.
                    </li>
                    <li>
                      This service is available to all Link pharmacy customers.
                      For Link pharmacy store locations that are not open 24
                      hours, orders must be placed at least one hour prior to
                      store closing in order to be eligible.
                    </li>
                    <li>
                      Link pharmacy Cash rewards can be redeemed as a donation
                      to designated charities as shown in your myHealth account.
                    </li>
                    <li>
                      This service is available to all Link pharmacy customers.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ProgramDetails;
