import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import Spinner from "react-spinkit";
import { Image } from "@mui/icons-material";
import { AnimatePresence, motion } from "framer-motion";
import { AuthContext } from "../../context/AuthContext";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import { useCart } from "../../context/cartContext";
import SavedForLater from "../../components/products/SavedForLater";
import styles from "../../styles/views/shop/Cart.module.css";
import { Button, Feedback } from "../../components/CustomComponents";
import IncrementDecrement from "../../components/CustomComponents/components/incrementDecrement";

const Cart = () => {
  const { signedIn, user } = useContext(AuthContext);
  const [shipping_cost] = useState(0);
  const [errors, setErrors] = useState({});
  const [quantities, setQuantities] = useState({});
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    cart: { cart, total },
    removeFromCart,
    decrementItem,
    incrementItem,
    saveForLater,
    addLoading,
    saveLoading,
  } = useCart();

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    cart.map((item) => {
      setQuantities((prevState) => ({
        ...prevState,
        [item.id]: item.quantity,
      }));
    });
  }, [cart]);

  const { data: all_pres_items_added, status: all_pres_items_added_status } = useQuery(
    "prescriptions_added",
    async () => {
      const res = await axios.get("/v1/website/cart");
      return res.data.data.attributes.all_presc_items_added;
    }
  );

  const handleHover = async () => {
    if (signedIn) {
      await queryClient.prefetchQuery(["addresses", user?.id]);
    }
  };

  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
      bgColor={"bg-[#fafafa]"}
    >
      <div className={styles.frontend_cart}>
        <div className="container">
          {cart.length > 0 && Object.keys(quantities).length > 0 && (
            <>
              <br />
              <Link to="/store/products" style={{ color: "green", textDecoration: "none" }} className="text-sm m-0">
                <i className="fa fa-angle-left" /> Continue Shopping
              </Link>
              <h4 className="text-2xl font-opensans text-black mb-[12px]">Shopping Cart</h4>
              <br />

              <div className="row">
                {/* Code for the cart container */}
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                  <div style={{ minHeight: "40vh" }}>
                    <AnimatePresence initial={false}>
                      {cart.map((cartItem) => (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: "auto" }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ type: "tween", duration: 0.5 }}
                          className={styles.cartItemContainer}
                          key={cartItem.id}
                        >
                          <AnimatePresence initial={false}>
                            {(addLoading.includes(cartItem.id) || saveLoading.includes(cartItem.id)) && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ type: "tween", duration: 0.3 }}
                                className={styles.loadingIndicator}
                              >
                                <Spinner name="ball-pulse-sync" color="#c4d600" fadeIn={"0"} />
                              </motion.div>
                            )}
                          </AnimatePresence>

                          <div className={styles.cartItemDetails}>
                            <div className={styles.cartImageContainer}>
                              {cartItem.thumbnail ? (
                                <img src={cartItem.thumbnail} alt={cartItem.name} />
                              ) : (
                                <Image fontSize="large" />
                              )}
                            </div>

                            <div className={styles.cartInfoContainer}>
                              <Link
                                to={`/store/product/${cartItem.id}`}
                                onClick={(e) => {
                                  if (cartItem.prescriptionItem) {
                                    e.preventDefault();
                                  }
                                }}
                              >
                                <h6 className="text-sm text-green-800 font-opensans-semibold mb-[0.5em]">
                                  {cartItem.name}
                                </h6>
                              </Link>
                              <p className="text-sm mb-1">
                                Quantity: <span className="font-opensans-bold">{cartItem.quantity}</span>
                              </p>

                              <p className="text-sm">
                                K
                                <span className="font-opensans-bold text-stone-600">
                                  {cartItem.price &&
                                    Number(cartItem.price).toLocaleString("en-GB", { minimumFractionDigits: 2 })}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className={styles.cartActionsContainer}>
                            <div className={styles.saveRemove}>
                              {!cartItem.prescriptionItem ? (
                                <>
                                  <span
                                    onClick={() => saveForLater(cartItem)}
                                    className="text-sm text-green-800 font-opensans cursor-pointer"
                                  >
                                    Save For Later
                                  </span>
                                  {" | "}
                                </>
                              ) : null}
                              <span
                                onClick={() => removeFromCart(cartItem)}
                                className="text-sm text-green-800 font-opensans cursor-pointer"
                              >
                                Remove
                              </span>
                            </div>

                            {cartItem.prescriptionItem ? (
                              <>
                                <Link
                                  to={`/prescription/${cartItem.prescription_id}`}
                                  style={{
                                    color: "green",
                                    textAlign: "right",
                                  }}
                                >
                                  View Prescription {cartItem.prescription_number}
                                </Link>
                              </>
                            ) : null}

                            {!cartItem.prescriptionItem && (
                              <div className="w-32 my-1">
                                <IncrementDecrement
                                  value={quantities[cartItem.id]}
                                  onIncrement={async () => {
                                    if (cartItem.quantity === 12) {
                                      setErrors((prevState) => ({
                                        ...prevState,
                                        [cartItem.id]: {
                                          quantity: "Limit is 12",
                                        },
                                      }));
                                      return;
                                    }
                                    await incrementItem(cartItem);
                                  }}
                                  onDecrement={() => decrementItem(cartItem)}
                                  onChange={(e) => {
                                    if (Number(e.target.value) >= 1) {
                                      setErrors((prevState) => {
                                        const newData = { ...prevState };
                                        delete newData[cartItem.id]?.quantity;
                                        return newData;
                                      });
                                    }

                                    if (Number(e.target.value) > 12) {
                                      setErrors((prevState) => ({
                                        ...prevState,
                                        [cartItem.id]: {
                                          quantity: "Limit is 12",
                                        },
                                      }));
                                      setQuantities((prevState) => ({
                                        ...prevState,
                                        [cartItem.id]: 12,
                                      }));
                                      return;
                                    }

                                    setQuantities((prevState) => ({
                                      ...prevState,
                                      [cartItem.id]: e.target.value,
                                    }));
                                  }}
                                  onBlur={async (e) => {
                                    if (Number(quantities[cartItem.id]) < 1) {
                                      setErrors((prevState) => ({
                                        ...prevState,
                                        [cartItem.id]: {
                                          quantity: "Quantity must be more than zero",
                                        },
                                      }));
                                      e.target.value = cartItem.quantity.toString();
                                      return;
                                    }

                                    if (quantities[cartItem.id] !== cartItem.quantity) {
                                      await incrementItem(cartItem, quantities[cartItem.id]);
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === ".") {
                                      e.preventDefault();
                                    }
                                  }}
                                  onKeyUp={async (e) => {
                                    if (e.key === "Enter") {
                                      if (Number(quantities[cartItem.id]) < 1) {
                                        setErrors((prevState) => ({
                                          ...prevState,
                                          [cartItem.id]: {
                                            quantity: "Quantity must be more than zero",
                                          },
                                        }));
                                        return;
                                      }

                                      if (quantities[cartItem.id] !== cartItem.quantity) {
                                        await incrementItem(cartItem, quantities[cartItem.id]);
                                      }
                                    }
                                  }}
                                />
                              </div>
                            )}

                            {errors[cartItem.id]?.quantity && (
                              <div
                                style={{
                                  color: "#ee6055",
                                }}
                              >
                                <p className="font-opensans text-sm my-1.5">{errors[cartItem.id].quantity}</p>
                              </div>
                            )}

                            <div className={styles.cartItemTotal}>
                              <h6 className="font-opensans" style={{ color: "black" }}>
                                K
                                <span className="font-opensans-bold">
                                  {cartItem.price &&
                                    (cartItem.price * cartItem.quantity).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                    })}
                                </span>
                              </h6>
                            </div>
                          </div>
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </div>

                  {all_pres_items_added_status === "success" && (
                    <span>
                      {all_pres_items_added === false && (
                        <>
                          <p
                            style={{
                              color: "red",
                              fontSize: 18,
                              fontWeight: 400,
                            }}
                          >
                            You have not added all medicine(s) on your prescription(s).{" "}
                            <Link to="/account?t=prescription-history">
                              <b>Would you like to add?</b>
                            </Link>
                          </p>
                        </>
                      )}
                    </span>
                  )}
                  <br />
                </div>
                {/* End of code for the cart container */}

                {/* Code for the order summary */}
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <h4 className="text-2xl font-opensans mb-[0.5em]">Order Summary</h4>
                  <div className={styles.order_summary_container}>
                    <div className={styles.order_summary_left}>
                      <p className="text-sm mb-[14px]">
                        Items:
                        <br />
                        Shopping bag fee
                        <br />
                      </p>
                    </div>

                    <div className={styles.order_summary_right}>
                      <p className="text-sm mb-[14px]">
                        K{" "}
                        {total &&
                          Number(total).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                      </p>
                    </div>
                  </div>

                  <div className={styles.order_summary_container}>
                    <div className={styles.order_summary_left}>
                      <p className="text-sm mb-[14px]">Pickup</p>
                    </div>

                    <div className={styles.order_summary_right}>
                      <p className="text-sm mb-[14px]">FREE</p>
                    </div>
                  </div>

                  <div className={styles.order_summary_container}>
                    <div className={styles.order_summary_left}>
                      <p className="text-sm mb-[14px]">Shipping &amp; Tax calculated at checkout</p>
                    </div>
                  </div>
                  <hr />

                  <div className={`${styles.order_summary_container} mt-3`}>
                    <div className={styles.order_summary_left}>
                      <p className="text-sm font-opensans-bold">Estimated total</p>
                    </div>

                    <div className={styles.order_summary_right}>
                      <p className="text-sm font-opensans">
                        K
                        <span className="font-opensans-bold">
                          {total && Number(total + shipping_cost).toLocaleString("en-GB", { minimumFractionDigits: 2 })}
                        </span>
                      </p>
                    </div>
                  </div>
                  <br />

                  <Button.Rounded
                    onClick={() => navigate("/checkout")}
                    onHover={handleHover}
                    size={"lg"}
                    width={"full"}
                  >
                    Proceed to checkout
                  </Button.Rounded>
                  <br />
                </div>
                {/* End of code for the order summary */}
              </div>
              <br />
              <br />

              <Link to="/store/products" style={{ color: "green", textDecoration: "none" }} className="text-sm">
                <i className="fa fa-angle-left" /> Continue Shopping
              </Link>
              <br />
              <br />
            </>
          )}

          {!cart.length > 0 && (
            <>
              <div className="container">
                <div className={styles.cart_paper}>
                  <Feedback
                    backgroundColor={"#fafafa"}
                    title={"Your cart is empty"}
                    message={"You have no items in your cart. Click the button below to continue shopping."}
                    actions={
                      <Button.Rounded onClick={() => navigate("/store/products")} size={"lg"}>
                        Continue Shopping
                      </Button.Rounded>
                    }
                  />
                </div>
              </div>
            </>
          )}

          <h4 className="text-2xl mb-[0.5em] font-opensans">Saved for later</h4>
          <hr className="my-[20px]" />
          {signedIn === false && (
            <>
              <center>
                <p className="font-opensans text-sm mb-3">Sign in to see your saved items</p>
                <Link
                  to="/login"
                  className={`btn btn-primary`}
                  style={{
                    borderRadius: 50,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  Sign In
                </Link>
              </center>
            </>
          )}

          {signedIn === true && <SavedForLater />}
          <br />
          <br />
        </div>
      </div>
    </Layout>
  );
};

export default Cart;
