import { useNavigate } from "react-router-dom";
import styles from "../styles/views/Security.module.css";
import React, { useContext } from "react";
import { Button, Input } from "./CustomComponents";
import { Formik } from "formik";
import { useMutation } from "react-query";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useNotifications } from "../context/NotificationsContext";
import * as Yup from "yup";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Security = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pushNotification } = useNotifications();

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const { mutate, isLoading } = useMutation(
    ["change-password"],
    async (values) => {
      const { data } = await axios.put(`/users/${user.id}`, {
        first_name: user.firstname,
        last_name: user.lastname,
        email: user.email,
        phone_number: user.phoneNumber,
        password: values.newPassword,
        password_confirmation: values.confirmPassword,
      });

      return data;
    },
    {
      onSuccess: (data, variables) => {
        pushNotification({
          message: "Password changed successfully.",
          type: "Success",
          id: Math.random(),
        });

        variables?.formikHelpers?.resetForm();
      },
      onError: (error, variables) => {
        pushNotification({
          message: "Something went wrong. Please try again.",
          type: "Error",
          id: Math.random(),
        });
      },
    }
  );

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required("New password is required."),
    confirmPassword: Yup.string()
      .required("Confirm password is required.")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
  });

  return (
    <div className="w-full flex flex-col text-lg">
      <div className="w-full flex border-b px-4 py-3 hidden md:block">
        <h2 className="m-0 font-opensans-medium text-2xl">Change Password</h2>
      </div>

      <div className="p-6 overflow-y-hidden overflow-x-auto whitespace-nowrap pb-4">
        <div className={styles.form}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers) => {
              mutate({ ...values, formikHelpers });
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className={"mb-3"}>
                  <Input
                    label={"New Password"}
                    name={"newPassword"}
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.newPassword ? errors.newPassword : ""}
                    touched={touched.newPassword}
                    type={"password"}
                  />
                </div>

                <div className={"mb-3"}>
                  <Input
                    label={"Confirm Password"}
                    name={"confirmPassword"}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.confirmPassword ? errors.confirmPassword : ""}
                    touched={touched.confirmPassword}
                    type={"password"}
                  />
                </div>

                <div className="flex flex-row items-center space-x-4">
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      navigate("/account");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type={"submit"} disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <FontAwesomeIcon
                          icon={faCircleNotch}
                          style={{ marginRight: "10px" }}
                          className={"fa fa-spin"}
                        />
                        Loading
                      </>
                    ) : (
                      "Change Password"
                    )}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Security;
