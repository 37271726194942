import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import styles from "../../styles/views/SavingsAndDeals.module.css";
import promo_9 from "../../assets/images/promo_9.jpg";

const SavingsAndDeals = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className={styles.promo_section_wrapper}>
        <div className={styles.promo_section_bg}>
          <div className="row">
            <div className="col-md-6">
              <div className={styles.promo_img}>
                <img src={promo_9} alt="Best Deals" />
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.promo_form}>
                <h4>Sign up now to be the first to find out about savings and deals at Link pharmacy!</h4>
                <Link to="/register">Shop our sale</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SavingsAndDeals;
