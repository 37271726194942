import React from "react";
import Layout from "../../components/Layout/Layout";
import { meta } from "../../globals";
import style from "../../styles/views/HelpCenter.module.css";
import HelpCenterSidebar from "../../components/HelpCenterSidebar";

const SpecialOrders = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <section id="shipping_help_main">
        <section>
          <div className={style.shipping_help_bg}>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className={style.shipping_help_header}>
                  <span>Help Center</span>
                  <h1>Special Orders</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <HelpCenterSidebar />
            </div>
            <div className="col-md-6">
              <div className={style.shipping_help}>
                <p className="font-opensans mb-3">
                  Requests for special orders made by you from us will be deemed
                  valid after 50% of the total cost has been paid. You agree to
                  bear any cost that may arise from the price change and
                  quantity change in accordance with regulation of Zambia. We
                  will not be responsible for refunds of the 50% payment made
                  prior to your order in a situation where you cancel the order.
                </p>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default SpecialOrders;
