import { Form, Formik } from "formik";
import { IoCalendarNumber, IoPerson } from "react-icons/io5";
import { linkFormSchema } from "../../globals/schemas";

import styles from "../../styles/views/MyHealthInsurance.module.css";
import { FormField } from "../forms";

const LinkForm = () => {
  return (
    <Formik
      initialValues={{
        fullname: "",
        residentialAddress: "",
        cardNum: "",
        dob: "",
        expiryDate: "",
        claimForm: "",
        insuranceCompany: "LinkForm",
      }}
      onSubmit={(values) => console.log(values)}
      validationSchema={linkFormSchema}
    >
      {({ isSubmitting, values, handleChange, setFieldValue, errors }) => (
        <Form className="space-y-4 flex flex-col self-center w-full">
          <div className={styles.stepCounter}>
            <div>2</div> Main Member Details
          </div>

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Fullnames"}
              type="text"
              name="fullnames"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Date of Birth"}
              type="date"
              name="dob"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCalendarNumber className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <FormField
            label={"Residential Address"}
            type="text"
            name="residentialAddress"
            placeholder="e.g.: 122 off Shantumbu road"
            width={"w-full"}
            margin="mb-0"
            icon={<IoPerson className="text-xl ml-2 text-link-green" />}
          />

          <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:px-12">
            <FormField
              label={"Card Number / Serial Number"}
              type="text"
              name="cardNum"
              placeholder="e.g.: John Smith"
              width={"w-full"}
              margin="mb-0"
              icon={<IoPerson className="text-xl ml-2 text-link-green" />}
            />
            <FormField
              label={"Expiry Date on Policy Card"}
              type="date"
              name="dob"
              width={"w-full"}
              margin="mb-0"
              icon={<IoCalendarNumber className="text-xl ml-2 text-link-green" />}
            />
          </div>

          <div className={styles.stepCounter}>
            <div>4</div> Claim Details
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={"mt-4"}>
                <div>
                  <h5 className={`font-weight-bold ${styles.label}`}>Upload Copy of Claim Form</h5>

                  <p>Please ensure Patient's signature, Doctor's signature and Medical Services are clearly visible.</p>

                  <input
                    type="file"
                    name="fullNames"
                    className={"form-control"}
                    onChange={(event) => {
                      setFieldValue("claimForm", event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <input
              type={"submit"}
              value="Submit form"
              className="bg-link-green text-stone-900 rounded-full p-3"
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LinkForm;
