import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const VerifiedRoutes = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  return !user?.verified ? <Navigate to="/verify-email" state={{ from: location }} replace /> : <Outlet />;
};

export default VerifiedRoutes;
