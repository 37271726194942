import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { meta } from "../../globals";
import { IoLockClosed, IoMail } from "react-icons/io5";
import { FormField, SubmitButton } from "../../components/forms";
import { Formik } from "formik";
import { forgotPasswordSchema } from "../../globals/schemas";
import LayoutAuth from "../../components/Layout/LayoutAuth";
import LinkForm from "../../components/forms/LinkForm";
import { useMutation } from "react-query";
import { useNotifications } from "../../context/NotificationsContext";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { pushNotification } = useNotifications();

  const forgotMutation = useMutation(
    ["forgot-password"],
    async ({ email }) => {
      const res = await axios.post("/auth/forgot-password", {
        email,
        host: window.location.hostname,
      });

      return res.data;
    },
    {
      onSuccess: (data) => {
        pushNotification({
          type: "Success",
          message: "We have sent you an email, follow the link in order to reset your password",
          id: Math.random(),
        });
      },
      onError: (error) => {
        pushNotification({
          type: "Error",
          message: "That email does not exist in our system",
          id: Math.random(),
        });
      },
    }
  );

  return (
    <LayoutAuth title={meta.title} description={meta.description} keywords={meta.keywords}>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values) => {
          forgotMutation.mutate({ email: values.email });
        }}
        validationSchema={forgotPasswordSchema}
      >
        {({ isSubmitting, values, handleChange }) => (
          <LinkForm authForm={true}>
            <div className="absolute -top-6 flex items-center justify-center border-b bg-white backdrop-blur-lg p-2 rounded-full">
              <IoLockClosed className="text-3xl text-link-green" />
            </div>

            <h1 className="font-opensans-semibold text-2xl text-stone-900">Forgot Password</h1>
            <h2 className="font-medium text-lg text-center text-stone-900">Recover your password</h2>
            <div className="w-full px-4 h-fit">
              <FormField
                name="email"
                type="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                icon={<IoMail className="text-xl ml-2 text-link-green" />}
              />
              <div className="w-full px-3 flex flex-1 justify-between items-center ">
                <button
                  className="text-stone-800 font-opensans-semibold"
                  onClick={() => {
                    navigate("/", { replace: true });
                  }}
                >
                  Cancel
                </button>

                <SubmitButton type={"submit"} disabled={forgotMutation.isLoading}>
                  {forgotMutation.isLoading ? "Loading..." : "Proceed"}
                </SubmitButton>
              </div>
              <div className="font-opensans-semibold w-full text-center">
                Go&nbsp;
                <Link to="/" className="text-blue-500 text-md">
                  <b>Home.</b>
                </Link>
              </div>
            </div>
          </LinkForm>
        )}
      </Formik>
    </LayoutAuth>
  );
};

export default ForgotPassword;
