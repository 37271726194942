import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faListAlt, faSignOutAlt, faSync } from "@fortawesome/free-solid-svg-icons";

import styles from "../styles/components/RightMenuContent.module.css";
import { AuthContext } from "../context/AuthContext";
import Overlay from "./Overlay";

const MenuRight = ({ toggleRightMenu }) => {
  const [clicked] = useState(0);
  const { signedIn, logout } = useContext(AuthContext);

  return (
    <Overlay handleClose={toggleRightMenu}>
      <motion.div
        initial={{ height: "0px" }}
        animate={{ height: "100%" }}
        transition={{ duration: 0.3 }}
        exit={{ height: 0 }}
        className={`${styles.left_content} font-opensans text-sm`}
        onClick={(e) => e.stopPropagation()}
      >
        {clicked === 0 && (
          <ul className="list-group">
            <Link to={"/account"}>
              <li className={`list-group-item ${styles.list_group_item}`}>
                <FontAwesomeIcon icon={faHome} className={styles.icon} /> My Account
              </li>
            </Link>

            <Link to="/account?t=buy-again">
              <li className={`list-group-item ${styles.list_group_item}`}>
                <FontAwesomeIcon icon={faSync} className={styles.icon} /> Buy Again
              </li>
            </Link>

            <Link to="/account?t=shopping-list">
              <li className={`list-group-item ${styles.list_group_item}`}>
                <FontAwesomeIcon icon={faListAlt} className={styles.icon} /> Shopping List
              </li>
            </Link>

            {signedIn && (
              <li className={`list-group-item ${styles.list_group_item}`} onClick={logout}>
                <FontAwesomeIcon icon={faSignOutAlt} className={styles.icon} />
                Logout
              </li>
            )}
          </ul>
        )}
      </motion.div>
    </Overlay>
  );
};

export default MenuRight;
