import React from "react";
import styles from "../../styles/views/Career.module.css";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";

const Careers = () => {
  return (
    <Layout
      meta={{
        title: "Careers | Link Pharmacy",
        keywords:
          "Careers, Link Pharmacy, Pharmacy, Pharmacist, Pharmacy Assistant, Pharmacy Technician, Pharmacy Jobs, Pharmacy Vacancies",
        description:
          "Our people are at the heart of everything we do. We’re always looking for passionate people to join the team. Want to be part of a team that makes a difference? Get in touch",
      }}
      header
      footer
    >
      <main className={styles.mainHeader}>
        <div className="d-flex flex-col items-center justify-center bg-[#10101061] h-60 md:h-96">
          <h1 className="text-white font-opensans-bold md:text-3xl">Careers</h1>
        </div>
      </main>

      <div className="container p-3 font-opensans leading-normal">
        <h2 className="mb-3 font-opensans-semibold text-lg">Our Team</h2>
        <p className="mb-3">
          Our people are at the heart of everything we do. We’re always looking for passionate people to join the team.
          Want to be part of a team that makes a difference?{" "}
          <Link to="/contact-us" className="font-opensans-semibold text-link-green-1">
            Get in touch
          </Link>
        </p>
        <p className="mb-3">
          Remember to include your full name, position you are interested in, preferred location, contact details and
          resume. We’ll contact you directly if you are suitable for any positions which may arise.
        </p>
        <p className="mb-3">
          Our staff go through a rigorous requirement process to ensure only the best are left to deal with your health.
        </p>

        <h2 className="mb-3 font-opensans-semibold text-lg">Continuous Professional Development</h2>
        <p className="mb-3">
          We are committed to ensuring our team are up to date on best practices. Our in house CPD programme facilitates
          this.
        </p>
        <a
          href="https://linkpharmacy.moodlecloud.com"
          target="_blank"
          rel="noreferrer"
          className="font-opensans-semibold text-link-green-1 mb-3"
        >
          Staff log in for eLearning
        </a>
      </div>
    </Layout>
  );
};

export default Careers;
